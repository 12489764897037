import React, { useEffect, useLayoutEffect, useState } from 'react';
import { MapContainer, TileLayer, ZoomControl, useMap, GeoJSON } from 'react-leaflet';
import { blueDrawStyle, customDrawStyle, defaultDrawStyle } from '../functions/defaultDrawStyle';

export function MyGeoMan(props) {
  const { actionDraw, payload, setShowParameters, setShapeGeoJson } = props;
  const [shapeLayer, setshapeLayer] = useState(null);
  const map = useMap();

  useEffect(() => {
    console.log('MAP_EFFECT');
    map.pm.setPathOptions(defaultDrawStyle());
    map.pm.addControls({
      position: 'topright',
      drawCircle: true,
      drawCircleMarker: false,
      drawPolyline: false,
      drawRectangle: true,
      drawPolygon: true,
      editMode: true,
      dragMode: true,
      cutPolygon: false,
      removalMode: false,
      drawMarker: false,
    });
    map.pm.setLang('es');
    map.on('pm:drawstart', (e) => {
      console.log(e);
    });
    map.on('pm:drawend', (e) => {
      console.log(e);
    });
    map.on('pm:create', (e) => {
      console.log(e);
      const { shape } = e;
      const currentLayer = e.layer;
      const geoJsonInfo = getGeoJsonInfo(currentLayer, shape);

      currentLayer.on('pm:edit', (e) => {
        console.info(e);
        const currentLayer = e.layer;
        const { shape } = e;
        console.log('edit');
        const geoJsonInfo = getGeoJsonInfo(currentLayer, shape);
        setshapeLayer(currentLayer);
        setShapeGeoJson(geoJsonInfo);
        //console.log(e);
      });

      toolBarControl(map, shape);
      setshapeLayer(currentLayer);
      setShapeGeoJson(geoJsonInfo);
      setShowParameters(true);
    });

    return () => {
      console.log('***CLEANING UP MY GEOMAN');
      map.pm.addControls({
        position: 'topright',
        drawCircle: false,
        drawCircleMarker: false,
        drawPolyline: false,
        drawRectangle: false,
        drawPolygon: false,
        editMode: false,
        dragMode: false,
        cutPolygon: false,
        removalMode: false,
        drawMarker: false,
      });
      map.off('pm:drawstart', (e) => {
        console.log(e);
      });
      map.off('pm:drawend', (e) => {
        console.log(e);
      });
      map.off('pm:create', (e) => {});
      const layers = map.pm.getGeomanDrawLayers(true);
      layers.remove();
    };
  }, [map]);

  useLayoutEffect(() => {
    const layers = map.pm.getGeomanDrawLayers(true);
    //THIS IS AN ARRAY OF ALL DRAWN LAYERS
    console.log('actionDraw.action', actionDraw.action);
    switch (actionDraw.action) {
      case 'CREATE':
        toolBarControl(map, 'default');
        break;
      case 'GET_SHAPES':
        console.info(layers);
        console.info(layers.toGeoJSON());
        layers.setStyle(blueDrawStyle());
        break;
      case 'UPDATE_OPTIONS':
        // const layers = map.pm.getGeomanDrawLayers(true);
        // const {options} = shapege
        const updateOptions = customDrawStyle(actionDraw.payload);
        console.info(layers);
        shapeLayer.setStyle(updateOptions);
        // setShapeGeoJson((item) => {
        //   const options = item.properties;
        //   return { ...item, options: { ...options, ...actionDraw.payload } };
        // });
        break;
      case 'CANCEL':
        console.log('cancel swith');
        console.info(shapeLayer);
        shapeLayer.remove();
        toolBarControl(map, 'default');
        // layers.clearLayers();

        break;
      default:
        map.eachLayer(function (layer) {
          map.removeLayer(layer);
        });
        map.locate({ setView: true });
        break;
    }
    // return () => {
    //     cleanup
    // }
  }, [actionDraw]);

  const toolBarControl = (map, shape) => {
    switch (shape) {
      case 'Circle':
        map.pm.addControls({
          position: 'topright',
          drawCircle: false,
          drawCircleMarker: false,
          drawPolyline: false,
          drawRectangle: false,
          drawPolygon: false,
          editMode: true,
          dragMode: true,
          cutPolygon: false,
          removalMode: false,
          drawMarker: false,
        });

        break;

      default:
        map.pm.addControls({
          position: 'topright',
          drawCircle: true,
          drawCircleMarker: false,
          drawPolyline: false,
          drawRectangle: true,
          drawPolygon: true,
          editMode: true,
          dragMode: true,
          cutPolygon: false,
          removalMode: false,
          drawMarker: false,
        });
        break;
    }
  };

  const getGeoJsonInfo = (layer, shape) => {
    const { options, _mRadius, _latlng, _latlngs, _leaflet_id: idLayer } = layer;
    const geoJsonInfo = layer.toGeoJSON();
    if (shape === 'Circle') {
      geoJsonInfo.properties.radius = _mRadius;
    }
    // geoJsonInfo.radius = _mRadius;
    geoJsonInfo.properties.options = options;
    geoJsonInfo.properties.shape = shape;
    geoJsonInfo.properties.idLayer = idLayer;
    return geoJsonInfo;
  };

  return null;
}
