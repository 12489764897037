import 'jspdf-autotable';

const jsPDF = typeof window !== 'undefined' ? require('jspdf').jsPDF : null;
const unit = 'pt';
const size = 'A4';
const orientation = 'landscape';
const fontSize = 15;
const xNumber = 25;

const exportPDF =
  (title = 'default') =>
  (columns, data) => {
    const fields = columns.map((columnDef) => columnDef.field).slice(1);
    const headers = [columns.map((columnDef) => columnDef.title).slice(1)];

    /* eslint-disable-next-line */
    const doc = new jsPDF(orientation, unit, size);
    //   console.info(dataToArray);
    let firstTime = true;
    if (jsPDF !== null) {
      doc.setFontSize(18);
      // doc.text("Macros Control", xNumber, 22);
      doc.setFontSize(fontSize);
      doc.setTextColor(100);

      data.forEach((element) => {
        const driver = element.value;
        const driversData = element.data;
        const dataToArray = driversData.map((item) => {
          const newItem = fields.map((field) => item[field]);
          return Object.values(newItem);
        });
        //   console.log(`doc.lastAutoTable.finalY`, doc.lastAutoTable.finalY);
        // if (!firstTime) doc.text(driver, xNumber, 50);
        // console.log(doc.lastAutoTable.finalY);
        const content = {
          // startX: xNumber,
          startY: firstTime ? 40 : 600 + 100,
          head: headers,
          body: dataToArray,
          // showHead: "firstPage",
          // pageBreak: "always",
          // margin: { top: 50 },
          didDrawPage: function (data) {
            doc.setFontSize(fontSize);
            doc.setTextColor(100);
            doc.text(driver, xNumber, 30);
          },
          // rowPageBreak: "auto",
          // bodyStyles: { valign: "left" },
          // pageBreak: "always",
        };

        doc.autoTable(content);

        firstTime = false;
      });

      doc.save(title + '.pdf');

      // let content = {
      //   startY: 25,
      //   head: headers,
      //   body: dataToArray,
      // };
      // doc.text(driver1, xNumber, 20);
      // doc.setFontSize(fontSize);
      // // doc.text(this.props.exportFileName || this.props.title, 40, 40);
      // /* eslint-disable-next-line */
      // doc.autoTable(content);

      // const driver2 = data[1].value;
      // const driver2Data = data[1].data;
      // const dataToArray2 = driver2Data.map((item) => {
      //   const newItem = fields.map((field) => item[field]);
      //   return Object.values(newItem);
      // });

      // /* eslint-disable-next-line */
      // doc.text(driver2, xNumber, doc.lastAutoTable.finalY + 10);
      // doc.setFontSize(fontSize);
      // // doc.text(this.props.exportFileName || this.props.title, 40, 40);
      // content = {
      //   startY: doc.lastAutoTable.finalY + 15,
      //   head: headers,
      //   body: dataToArray2,
      //   rowPageBreak: "auto",
      //   bodyStyles: { valign: "top" },
      // };
      // /* eslint-disable-next-line */
      // doc.autoTable(content);

      // doc.save("data" + ".pdf");
    }

    //  const fields = columns.map((columnDef) => columnDef.field);
    //  console.info({ fields });
    //  console.info({ data });
    //  const dataToArray = data.map((item) => {
    //    const newItem = fields.map((field) => item[field]);
    //    return Object.values(newItem);
    //  });
    //  const headers = data.map((item) => item.value);
    //  console.info(dataToArray);
    //  if (jsPDF !== null) {
    //    const content = {
    //      startY: 50,
    //      head: [columns.map((columnDef) => columnDef.title)],
    //      body: dataToArray,
    //    };

    //    const unit = "pt";
    //    const size = "A4";
    //    const orientation = "landscape";
    //    /* eslint-disable-next-line */
    //    const doc = new jsPDF(orientation, unit, size);
    //    doc.setFontSize(15);
    //    // doc.text(this.props.exportFileName || this.props.title, 40, 40);
    //    /* eslint-disable-next-line */
    //    doc.autoTable(content);
    //    doc.save("data" + ".pdf");
    //  }
  };
export default exportPDF;
