import { useState, useLayoutEffect, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useMap } from 'react-leaflet';
import PropTypes from 'prop-types';
import L, { control } from 'leaflet';

const CustomControlLY = (props) => {
  const { children, modeMap, position = 'topright', showControl = false, shapeGeoJson } = props;
  const [control, setControl] = useState(null);
  const map = useMap();
  const [customControl, setCustomControl] = useState(null);

  useLayoutEffect(() => {
    const legend = L.control({ position });
    // console.info(shapeGeoJson);
    legend.onAdd = () => {
      const div = L.DomUtil.create('div', 'legend');
      ReactDOM.render(children, div);
      setCustomControl(div);

      return div;
    };
    legend.onRemove = () => {
      return null;
    };
    legend.addTo(map);
    // setControl(legend);
    return () => {
      if (legend) legend.remove();
    };
  }, [shapeGeoJson]);

  return null;
};

CustomControlLY.propTypes = {
  children: PropTypes.element,
  modeMap: PropTypes.string,
  position: PropTypes.string,
  shapeGeoJson: PropTypes.object,
  showControl: PropTypes.bool,
};

export default CustomControlLY;
