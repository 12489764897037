import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
//utils
import { useIntl } from '../../../lang/context/intlContext'; //addjust path reference
import { v4 as uuidv4 } from 'uuid';

import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import CustomCard from '../../../components/CustomCard/Card';
import CustomCheck from '../../../components/CustomCheck/CustomCheck';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { useStoreActions, useStoreState } from 'easy-peasy';
const useStyle = makeStyles((theme) => ({
  root: {
    display: 'flex',
    // justifyContent: 'center',
    // alignContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  text: {
    // justifySelf: 'center',
  },
  grid: {
    paddingTop: theme.spacing(4),
  },
  cardStyle: { maxWidth: theme.spacing(30) },
  content: {},
}));

//Task
//get JC template from store
//build list
const SelectTemplate = (props) => {
  const { i18 } = useIntl();
  const classes = useStyle();
  const { history } = props;
  // const [state, setState] = React.useState(false);
  useEffect(() => {
    setNoButtons();
  }, []);

  const journeyControl = useStoreState((state) => state.templates.journeyControl);
  const setTemplate = useStoreActions((actions) => actions.journeyControl.setTemplate);
  const setNoButtons = useStoreActions((actions) => actions.globalState.setNoButtons);

  // const handleChange = (event) => {
  //   setState((prev) => !prev);
  // };

  const title = i18('Blank');
  const finalText = i18('Create_BLANK_JC');
  const buttonText = i18('Select');
  const onClickBaseTemplate = (data) => {
    setTemplate(data);
    history.push('/mfis/control/add/' + data.UUID);
  };

  const getJCTemplates = (items) => {
    if (!items) return null;
    return items.map((item) => {
      const UUID = uuidv4();
      return (
        <GridItem key={UUID} item xs={12} md={6} lg={6}>
          <CustomCard
            className={classes.cardStyle}
            title={item.name}
            align={'center'}
            contentText={item.description}
            hasAvatar
            onClick={() => onClickBaseTemplate({ ...item, UUID })}
            buttonText={buttonText}
            // isLoading={eloading === item.spokenRouteId}
          ></CustomCard>
        </GridItem>
      );
    });
  };

  return (
    <div className={classes.root}>
      <Typography component="h3" variant="h3" noWrap className={classes.text}>
        {i18('SELECT_TEMPLATE')}
      </Typography>
      <GridContainer spacing={4} className={classes.grid}>
        {getJCTemplates(journeyControl)}
        <GridItem item xs={12} md={6} lg={6}>
          <CustomCard
            className={classes.cardStyle}
            key={uuidv4()}
            title={title}
            disabledButton={true}
            // onSelect={onSelectCard}
            // selected={selected}
            // value={item}
            align={'center'}
            contentText={finalText}
            hasAvatar
            onClick={() => {}}
            buttonText={buttonText}
            // isLoading={eloading === item.spokenRouteId}
          ></CustomCard>
        </GridItem>
      </GridContainer>
    </div>
  );
};
SelectTemplate.propTypes = {
  // title: PropTypes.string,
  // numSelected: PropTypes.number.isRequired,
  // children: PropTypes.object,
};

SelectTemplate.defaultProps = {
  //icon: null,
};
export default SelectTemplate;
