import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { ItemIcon } from './ItemIcon';
import { v4 as uuidv4 } from 'uuid';
import CollapseMenu from './CollapseMenu';

//borderRadius 36 balnc
const useStyles = makeStyles((theme) => ({
  listButton: {
    marginTop: theme.spacing(4),
    // '&:hover': {
    //   backgroundColor: '#1b1344',
    //   fontWeight: '500',
    // },
  },
  listButtonPrev: {
    //this apply on listItem
    marginTop: theme.spacing(4),
    backgroundColor: theme.palette.primary.dark,
    borderBottomRightRadius: theme.spacing(3),
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  listItemStyle: {
    fontSize: '14px',
    marginLeft: theme.spacing(1),
    color: theme.palette.primary.contrastText,
    letterSpacing: '0.42px',
    // '&:hover': {
    //   backgroundColor: '#1b1344',
    //   fontWeight: '500',
    // },
  },
  listStyle: {
    backgroundColor: theme.palette.primary.dark,
    // '&:hover': {
    //   backgroundColor: '#1b1344',
    //   fontWeight: '500',
    // },
  },
  listItemStyleBackPrev: { backgroundColor: '#ffffff' },
  listItemStyleprevSelected: {
    fontSize: '14px',
    marginLeft: theme.spacing(1),
    color: theme.palette.primary.contrastText,
    letterSpacing: '0.42px',
    backgroundColor: theme.palette.primary.dark,
    borderBottomRightRadius: theme.spacing(3),
    // '&:hover': {
    //   backgroundColor: '#1b1344',
    //   fontWeight: '500',
    // },
  },

  listIconStyle: {
    // fontSize: '14px',
    minWidth: '0px',
    color: theme.palette.secondary.main,
  },
}));

const setStyles = (subMenu, subRoutes, currentPath) => {
  if (!subMenu.length) return subMenu;
  const affectedItems = subMenu.filter((item) => item.path === currentPath);
  if (!affectedItems.length) return [[], subMenu];
  const styleSubMenu = subMenu.map((item) => {
    //aca ponemos el status al SubMenu
    let status = 'normal';
    if (item.id === affectedItems[0].id) status = 'selected';
    if (item.id === affectedItems[0].prev) status = 'prev';
    if (item.id === affectedItems[0].next) status = 'next';

    return { ...item, status };
  });

  return [affectedItems, styleSubMenu];
};

const SubItem = (props) => {
  const classes = useStyles();
  const { name, currentPath, icon, id, path, subMenus, level, onToggleClick } = props;
  const handleClick = (e) => {
    e.preventDefault();
    setOpen(!opened);
  };
  const subRoutes = currentPath.split('/');
  const isSubRoute = subRoutes.length > 2 ? subRoutes[2] === path : false;
  const [opened, setOpen] = React.useState(isSubRoute);
  const [affectedItems, styleSubMenu] = setStyles(subMenus, subRoutes, currentPath);
  let itemListStyleClass = classes.listStyle;
  let itemStyleClass = classes.listButton;
  if (affectedItems && opened)
    if (affectedItems.length)
      if (affectedItems[0].prev === id) {
        itemListStyleClass = classes.listItemStyleBackPrev;
        itemStyleClass = classes.listButtonPrev;
      }

  return (
    <div key={uuidv4()} className={itemListStyleClass}>
      <ListItem key={uuidv4()} onClick={handleClick} className={itemStyleClass} button>
        {icon && <ListItemIcon className={classes.listIconStyle}>{ItemIcon(icon)}</ListItemIcon>}
        <ListItemText primary={name} className={classes.listItemStyleprevSelected}></ListItemText>
        {/* {hasCollapsed && opened ? <ExpandLess /> : <ExpandMore />} */}
        {/* <ExpandMore /> */}
      </ListItem>
      {subMenus && CollapseMenu({ subMenus: styleSubMenu, opened, key: id + level, currentPath, onToggleClick })}
    </div>
  );
};
export default SubItem;
