import React from 'react';
// import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
// import CssBaseline from '@material-ui/core/CssBaseline';
import { Avatar, TextField, InputAdornment } from '@material-ui/core';
import { BuscarIcon } from '../../assets/icons/index';
// import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  title: {
    // flexGrow: 1,
    // color: theme.palette.text.secondary,
    // marginTop: '30px',
    fontWeight: 500,
  },
  profileIcon: {
    backgroundColor: theme.palette.primary.main,
    color: '#fffff',
    // paddingRight: theme.spacing(5),
    justifyContent: 'center',
    padding: 0,
    width: '34px',
    height: '34px',
  },
  input: {
    color: theme.palette.primary.main,
    background: 'transparent',
    // backgroundColor: 'white',
    // border: 'solid 1px #f1f1f1',
    boxShadow: '0 10px 20px 0 rgba(92, 116, 129, 0.08)',
    width: '345px',
  },
  inputText: {
    color: theme.palette.text.secondary,
    backgroundColor: 'white',
    // '&.MuiOutlinedInput-input ': {
    //   paddingTop: '8px',
    //   paddingBottom: '8px',
    // },
    // height: theme.spacing(5),
  },

  sizeIcon: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));

export default function Searcher(props) {
  const classes = useStyles();
  const { label } = props;
  return (
    <TextField
      InputProps={{
        className: classes.inputText,
        endAdornment: (
          <InputAdornment position="end">
            <Avatar variant="rounded" className={classes.profileIcon} color="inherit">
              <BuscarIcon />
            </Avatar>
          </InputAdornment>
        ),
      }}
      className={classes.input}
      variant="outlined"
      // margin="normal"
      //   autoComplete="search"
      label={label}
      id="search"
      name="search"
      type="search"
    />
  );
}
