import { makeStyles } from '@material-ui/core/styles';
import { blackColor } from '../../components/styles/constValues';

export const useMacroSettingStyle = makeStyles((theme) => ({
  // root: {
  //   // margin: theme.spacing(4),
  // },

  // content: {
  //   display: 'flex',
  //   justifyContent: 'center',
  //   // height: theme.spacing(80),
  // },
  content: {
    display: 'flex',
    // justifyContent: 'flex-end',
    flexDirection: 'column',
    justifyContent: 'end',
    // padding: theme.spacing(4),
  },
  // formStyle: { width: '100%' },
  // inDiv: {
  //   padding: theme.spacing(4),
  //   position: 'relative',
  // },
  // inSmallDiv: { padding: theme.spacing(2), position: 'relative' },
  // remover: {
  //   display: 'flex',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  // },
  // buttonProgress: {
  //   color: theme.palette.primary.dark[500],
  //   position: 'absolute',
  //   top: '50%',
  //   left: '50%',
  //   marginTop: -12,
  //   marginLeft: -12,
  // },
  // alert: { margin: theme.spacing(2) },
  // listLabel: {
  //   color: theme.palette.primary.dark,
  // },

  // labelFilter: {
  //   position: 'absolute',
  //   top: '18.5%',
  //   marginLeft: '4px',
  //   color: theme.palette.primary.dark,
  //   fontSize: '16px',
  // },
  secondaryContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: theme.spacing(50),
    paddingLeft: theme.spacing(6),
  },
  secondaryContentText: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: theme.spacing(25),
    paddingLeft: theme.spacing(6),
  },
  secondaryContentSlider: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: theme.spacing(50),
    paddingLeft: theme.spacing(6),
  },
  // paper: {
  //   padding: theme.spacing(1),
  //   textAlign: 'center',
  //   color: theme.palette.text.secondary,
  // },
  // messageFails: {
  //   display: 'flex',
  //   flexDirection: 'row',
  //   minWidth: theme.spacing(12),
  //   justifyContent: 'center',
  //   marginTop: theme.spacing(2),
  //   paddingLeft: theme.spacing(1),
  //   // alignItems: 'center',
  // },
  // inputSpeedStyle: {
  //   minWidth: theme.spacing(10),
  //   width: theme.spacing(12),
  // },
  // paperStyle: { backgroundColor: blackColor },
  // formBoxControl: {
  //   // margin: theme.spacing(1),
  //   minWidth: 80,
  //   maxWidth: 250,
  //   '& .MuiOutlinedInput-root': {
  //     '& fieldset': {
  //       borderColor: theme.palette.primary.main,
  //     },
  //     '&:hover fieldset': {
  //       borderColor: theme.palette.secondary.main,
  //     },
  //     '&.Mui-focused fieldset': {
  //       borderColor: theme.palette.secondary.main,
  //     },
  //   },
  // },
  paperMula: {
    width: '100%',
    maxHeight: 950,
  },
}));
