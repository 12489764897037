import React, { useState, useEffect, useLayoutEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import Portlet from '../../../components/Portlet/Portlet';
import { useForm } from 'react-hook-form';
import { FormNameIcon, FormDescriptionIcon } from '../../../../assets/icons/formIcons';
import { Grid, CardContent, CircularProgress, Typography, InputLabel } from '@material-ui/core';
import { INPUT_BACKGROUND, whiteColor, blackColor } from '../../../components/styles/constValues';
import InputText from '../../../components/InputText/InputText';
import PortletActions from '../../../components/Portlet/PorletActions';
import Button from '../../../components/CustomButton/Button';
import { useIntl } from '../../../lang/context/intlContext';
import { SketchPicker } from 'react-color';
//ApiMedhots
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { Alert } from '@material-ui/lab';
import { Label } from '@material-ui/icons';
import ComboBox from '../../../components/ComboBox/ComboBox';
import Popover from '@material-ui/core/Popover';
//state
import { getRegionNameAvailability } from '../api/getMethods';
import { postCreateRegion } from '../api/postMethods';
import { useStoreActions } from 'easy-peasy';

const useStyle = makeStyles((theme) => ({
  root: {
    // margin: theme.spacing(4),
  },

  content: {
    display: 'flex',
    justifyContent: 'center',
    // height: theme.spacing(60),
    width: '100%',
  },
  div: {
    display: 'flex',
    // justifyContent: 'flex-end',
    flexDirection: 'row',
    justifyContent: 'end',
    // padding: theme.spacing(4),
  },
  formStyle: { width: '100%' },
  inDiv: {
    padding: theme.spacing(2),
    position: 'relative',
  },
  buttonProgress: {
    color: theme.palette.primary.dark[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  alert: { margin: theme.spacing(2) },
  label: {
    marginLeft: '4px',
    color: theme.palette.primary.dark,
    fontSize: '12px',
  },
}));
const schema = yup.object().shape({
  name: yup.string().required(),
  // age: yup.number().positive().integer().required(),
});
export function RegionOptions({ i18, onUpdate, regionTypes, shapeGeoJson, cancelCreateRegion, clearRegions }) {
  const classes = useStyle();
  const [color, setColor] = React.useState('#f01111');
  const [strokecolor, setStrokeColor] = React.useState('#f01111');
  const [isLoading, setisLoading] = useState(false);
  const [free, setfree] = useState(undefined);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const { register, handleSubmit, errors, setValue } = useForm({ resolver: yupResolver(schema) });

  // const { clearRegions } = useStoreActions((actions) => actions.regions);
  let radius = 0;
  let latitude = 0.0;
  let longitude = 0.0;
  let options = {};
  let isCircle = false;
  let points = [];
  useEffect(() => {
    register({ name: 'fillColor' }, { required: false });
    register({ name: 'strokeColor' }, { required: false });
    register({ name: 'type' }, { required: false });
    // setValue('fillColor', '#f01111');
    // setValue('strokeColor', '#f01111');
    setValue('type', 1);

    // setColor(options.fillColor);
    // setStrokeColor(options.color);
  }, []);
  useLayoutEffect(() => {
    if (!options) return;
    console.log('LEFFECT REGION OPTIONS');
    setValue('fillColor', options.fillColor);
    setValue('strokeColor', options.color);
  }, [options]);

  console.log('region options');
  console.info(shapeGeoJson);
  //#region handlers
  const onChangeFillColor = (e) => {
    console.info(e);
    // if (!onUpdate) return null;
    setColor(e.hex);
    const payload = {
      fillColor: e.hex,
    };
    setValue('fillColor', e.hex, {
      shouldValidate: true,
      shouldDirty: true,
    });
    onUpdate(payload);
  };
  const onChangeStrokeColor = (e) => {
    setStrokeColor(e.hex);
    const payload = {
      color: e.hex,
    };
    setValue('strokeColor', e.hex, {
      shouldValidate: true,
      shouldDirty: true,
    });
    onUpdate(payload);
  };
  const handleTypeChange = (e) => {
    setValue('type', e.target.value, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };
  const onSubmit = async (data) => {
    try {
      // updateData(data);
      // console.info(data);
      const { name, description, fillColor, strokeColor, type } = data;
      setisLoading(true);
      // console.info('all ok');
      const { data: body } = await getRegionNameAvailability(data.name);

      if (body.isFree) {
        const { shape, radius, options } = shapeGeoJson.properties;
        const intRadius = Math.trunc(+shapeGeoJson.properties.radius);
        const latitude = shapeGeoJson.geometry.coordinates[1];
        const longitude = shapeGeoJson.geometry.coordinates[0];

        const { data: region } = await postCreateRegion(
          shape,
          name,
          description,
          type,
          fillColor,
          strokeColor,
          '0.4',
          intRadius,
          latitude,
          longitude,
          points
        );
        // console.log('regionid', region.regionId);
        console.log('CLEARE REGIONS');
        clearRegions();
        // createdNew(rotograma);
      } else setfree(data.name);
      setisLoading(false);
      cancelCreateRegion();
      // history.push(`/mfis/rotograma/add/select-regions`);
    } catch (error) {
      console.error(error);
      setisLoading(false);
    }
  };
  //#endregion

  //#region Popover Handlers

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  //#endregion
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const open2 = Boolean(anchorEl2);
  const id2 = open ? 'simple-popover' : undefined;

  if (!shapeGeoJson) return null;

  // const description = shapeGeoJson.properties.shape + ' - ';
  if (shapeGeoJson) {
    options = shapeGeoJson.properties.options;
    const { shape } = shapeGeoJson.properties;
    isCircle = shape === 'Circle';
    // console.log('shape', shape);
    if (shape === 'Circle') {
      latitude = shapeGeoJson.geometry.coordinates[1];
      longitude = shapeGeoJson.geometry.coordinates[0];
      radius = Math.trunc(+shapeGeoJson.properties.radius);
    } else {
      points = shapeGeoJson.geometry.coordinates[0].map((point) => ({ latitude: point[1], longitude: point[0] }));
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <GridContainer className={classes.content}>
        <GridItem item xs={12} md={6} lg={12} className={classes.content}>
          <Portlet className={classes.formStyle} title={i18('PARAMETERS')} subTitle={i18('PARAMETERS_SUB')}>
            <CardContent>
              <GridContainer direction="column" spacing={2}>
                <GridItem item xs={12} md={12}>
                  <InputText
                    className={classes.label}
                    id="name"
                    placeholder={i18('name') + ' (' + i18('required') + ')'}
                    name="name"
                    label={i18('name')}
                    icon={FormNameIcon}
                    inputRef={register({ required: true })}
                    fullWidth
                  ></InputText>
                  {free && (
                    <Typography color="secondary" style={{ maxWidth: '200px' }}>{`${free} ${i18('NOTAVAI2')}`}</Typography>
                  )}
                  {errors.name && <Typography color="secondary">{i18('REQ')}</Typography>}
                </GridItem>
                <GridItem item xs={12} md={12}>
                  <InputText
                    id="description"
                    name="description"
                    label={i18('Description')}
                    placeholder={i18('description') + ' (' + i18('optional') + ')'}
                    inputRef={register({ required: false })}
                    icon={FormDescriptionIcon}
                    multiline
                    rows={4}
                  ></InputText>
                </GridItem>
                <GridItem item xs={12} md={12}>
                  <ComboBox
                    label={i18('TYPE_REGION')}
                    icon={FormNameIcon}
                    name="typeRegion"
                    defaultValue={1}
                    onChange={handleTypeChange}
                    options={regionTypes}
                    placeholder={i18('Groups')}
                  ></ComboBox>
                </GridItem>
                <GridItem item container direction="row" xs={12} md={12}>
                  <GridItem item xs={6} md={6}>
                    <div>
                      {/* <InputLabel className={classes.label} style={{ paddingBottom: '4px' }}>
                        {i18('FILL_COLOR')}
                      </InputLabel> */}
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Button size="sm" aria-describedby={id} color="add" onClick={handleClick}>
                          Fill Color
                        </Button>
                        <div
                          style={{
                            width: 24,
                            height: 24,
                            marginTop: 6,
                            marginLeft: 10,
                            backgroundColor: options.fillColor || color,
                          }}
                        />
                      </div>
                      <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                      >
                        <SketchPicker disableAlpha={true} color={color} onChangeComplete={onChangeFillColor} />
                      </Popover>
                    </div>
                  </GridItem>
                  <GridItem item xs={6} md={6} style={{ paddingLeft: '16px' }}>
                    <div>
                      {/* <InputLabel className={classes.label} style={{ paddingBottom: '4px' }}>
                        {i18('FILL_COLOR')}
                      </InputLabel> */}
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Button size="sm" aria-describedby={id2} color="add" onClick={handleClick2}>
                          Stroke Color
                        </Button>
                        <div
                          style={{
                            width: 24,
                            height: 24,
                            marginTop: 6,
                            marginLeft: 10,
                            backgroundColor: options.color || strokecolor,
                          }}
                        />
                      </div>
                      <Popover
                        id={id2}
                        open={open2}
                        anchorEl={anchorEl2}
                        onClose={handleClose2}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                      >
                        <SketchPicker disableAlpha={true} color={strokecolor} onChangeComplete={onChangeStrokeColor} />
                      </Popover>
                    </div>
                  </GridItem>
                </GridItem>
                <GridItem item xs={12} md={12}>
                  <InputText
                    id="latitude"
                    disabled={!isCircle}
                    readOnly={true}
                    placeholder={'(' + i18('required') + ')'}
                    name="latitude"
                    label={i18('latitude')}
                    icon={FormNameIcon}
                    defaultValue={latitude}
                    fullWidth
                  ></InputText>
                </GridItem>
                <GridItem item xs={12} md={12}>
                  <InputText
                    id="longitude"
                    readOnly={true}
                    disabled={!isCircle}
                    // className={classes.meterStyle}
                    placeholder={'(' + i18('required') + ')'}
                    name="longitude"
                    label={i18('longitude')}
                    icon={FormNameIcon}
                    defaultValue={longitude}
                    fullWidth
                    // inputRef={register({ required: true })}
                  ></InputText>
                </GridItem>
                <GridItem item xs={12} md={12}>
                  <InputText
                    id="radius"
                    disabled={!isCircle}
                    readOnly={true}
                    // className={classes.meterStyle}
                    placeholder={'(' + i18('required') + ')'}
                    name="radius"
                    label={i18('radius')}
                    icon={FormNameIcon}
                    defaultValue={radius}
                    fullWidth
                    // inputRef={register({ required: true })}
                  ></InputText>
                  {/* {free && <Typography color="secondary">{`${free} ${i18('NOTAVAI')}`}</Typography>}

                  {errors.name && <Typography color="secondary">{i18('Name_Req')}</Typography>} */}
                </GridItem>
              </GridContainer>
            </CardContent>
            <PortletActions>
              <div className={classes.div}>
                <div className={classes.inDiv}>
                  <Button variant="contained" disabled={isLoading} color={'cancel'} onClick={() => cancelCreateRegion()}>
                    {i18('Cancel')}
                  </Button>
                </div>
                <div className={classes.inDiv}>
                  <Button type="submit" disabled={isLoading} variant="contained" color={'primary'}>
                    {i18('Create')}
                  </Button>
                  {/* <CircularProgress size={24} className={classes.buttonProgress} /> */}
                  {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
              </div>
            </PortletActions>
            {/* {error.length > 0 && (
              <Alert className={classes.alert} severity="error">
                {i18('API_ERR')}
              </Alert>
            )} */}
          </Portlet>
        </GridItem>
      </GridContainer>
    </form>
  );
}
