import axios from 'axios';

export const ADD_ROTOGRAMA = '/rotograma/add';
export const ADD_ROTOGRAMA_REGIONS = '/rotograma/addRegions';
export const PUBLISH_ROTOGRAMA = '/rotograma/publish';
export const DELETE_CONFIGURATION = '/rotograma/deleteConfiguration';
export const ROLL_OUT = '/rotograma/rollOut';
export const ENABLE_DISABLE = '/rotograma/disableEnable';
const ADD_CONFIGURATIONS_ROTOGRAMA = '/rotograma/addConfigurations';
const UPDATE_CONFIGURATIONS_ROTOGRAMA = '/rotograma/updateConfigurations';

const putConfiguration = (url) => [{ url, method: 'POST' }, { manual: true }];

export const addRotograma = putConfiguration(ADD_ROTOGRAMA);

export function postDeleteConfiguration(configurationId) {
  return axios.post(DELETE_CONFIGURATION, { configurationId });
}
export function postRollOut(spokenRouteId) {
  return axios.post(ROLL_OUT, { spokenRouteId });
}

export function postEnableDisable(spokenRouteId, isDisabled) {
  return axios.post(ENABLE_DISABLE, { spokenRouteId, isDisabled });
}
export const postConfigurations = (configurations) => {
  console.info(configurations);
  const { configurationId } = configurations;
  if (configurationId === 0) return axios.post(ADD_CONFIGURATIONS_ROTOGRAMA, { ...configurations });
  return axios.post(UPDATE_CONFIGURATIONS_ROTOGRAMA, { ...configurations });
};
export function postPublish(body) {
  return axios.post(PUBLISH_ROTOGRAMA, { ...body });
}

export function postRotograma(name, description) {
  return axios.post(ADD_ROTOGRAMA, { name, description });
}
