import React from 'react';
// @material-ui/core components
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  grid: {
    // margin: theme.spacing(4),
    width: 'unset',

    // height: `calc(100vh - ${theme.spacing(24)}px)`
  },
  full: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    minHeight: `calc(100vh - ${theme.spacing(32)}px)`,
  },
}));

function GridContainer({ ...props }) {
  const { className, children, full, ...rest } = props;

  const classes = useStyles();
  const gridClasses = clsx(
    {
      [classes.grid]: true,
      [classes.full]: full,
    },
    className
  );
  return (
    <Grid container {...rest} className={gridClasses}>
      {children}
    </Grid>
  );
}

export default GridContainer;
