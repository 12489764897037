import axios from 'axios';

const ADD_MFIS_EVENT = '/events/addMFISEvent';
const ADD_MFIS_EVENT_CONFIGURATION = '/events/addMFISEventConfiguration';
const PUBLISH_MFIS_CONFIGURATION = '/events/publishMFISConfiguration';
const DELETE_MSFIS_EVENTS = (eventId) => `/events/deleteMFISEvent?eventId=${eventId}`;
const DELETE_MSFIS_EVENTS_CONFIGURATION = (tabletEventSettingId) => `/events/deleteMFISEventConfiguration?tabletEventSettingId=${tabletEventSettingId}`;

export function postAddMFISEvent(body) {
  return axios.post(ADD_MFIS_EVENT, body);
}
export function postAddMFISEventConfiguration(body) {
  return axios.post(ADD_MFIS_EVENT_CONFIGURATION, body);
}
export function postPublish(body) {
  return axios.post(PUBLISH_MFIS_CONFIGURATION, body);
}
export function deleteMFISEventAPI(eventId) {
  return axios.delete(DELETE_MSFIS_EVENTS(eventId));
}
export function deleteMFISEventConfigurationAPI(tabletEventSettingId) {
  return axios.delete(DELETE_MSFIS_EVENTS_CONFIGURATION(tabletEventSettingId));
}
