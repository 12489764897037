import React, { useEffect, useState } from 'react';
import Splash from '../../Splash';
import { getJCTemplates, getRegionTypes } from '../api/getMethods';
import { useStoreActions } from 'easy-peasy';
import { Redirect } from 'react-router-dom';
import { getAllClient } from '../modules/administration/api/getMethods';
import { getGroups } from '../modules/Reports/api/getMethods';
import { getAssets } from '../api/postMethods';

const Loader = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const addTypes = useStoreActions((actions) => actions.region.addTypes);
  const addJourneyControlTemplates = useStoreActions((actions) => actions.templates.addJourneyControlTemplates);
  const addrptNGData = useStoreActions((actions) => actions.templates.getNGData);

  const addUsersClients = useStoreActions((actions) => actions.user.addUsersClients);
  const addAssets = useStoreActions((actions) => actions.asset.addAssets);
  const addGroups = useStoreActions((actions) => actions.user.addGroups);
  const addContext = useStoreActions((actions) => actions.user.addContext);

  const addDeviceModels = useStoreActions((actions) => actions.user.addDeviceModels);
  const addDeviceMakes = useStoreActions((actions) => actions.user.addDeviceMakes);
  const ref = React.useRef('');
  // React.useEffect(() => {
  //   ref.current += 1;
  // });
  const fetchData = async () => {
    const { data: assets } = await getAssets();
    addAssets(assets.assets);

    //  const { data: ngdata } = await getGroupsNG();
    //    addrptNGData(ngdata.ngdata);

    const { data: types } = await getRegionTypes();
    addTypes(types.types);

    const { data: templates } = await getJCTemplates();
    addJourneyControlTemplates(templates.journeyControl);
    const { data: companies } = await getAllClient();



    addUsersClients(companies);
    const { data: resGroups } = await getGroups(); // get groups and assets (general information)
    addGroups(resGroups.data.groups);
    addDeviceModels(resGroups.data.deviceModels);
    addDeviceMakes(resGroups.data.deviceMakes);
    addContext(resGroups.data);


    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);
  if (isLoading)
    return (
      <div className="container-fluid">
        <Splash>{ref.current}</Splash>
      </div>
    );

  return <Redirect to="/" />;
};

Loader.propTypes = {
  // title: PropTypes.string,
  // numSelected: PropTypes.number.isRequired,
  // children: PropTypes.object,
};

Loader.defaultProps = {
  //icon: null,
};
export default Loader;
