import axios from 'axios';

const CREATE_ASSETS_GROUP = '/vehicle/group/create';
const CREATE_ASSETS_SUBGROUP = '/vehicle/group/createSub';
const REMOVE_ASSETS_GROUP = '/vehicle/group/remove';
const REMOVE_ASSETS_SUBGROUP = '/vehicle/group/removeSubGroup';

const CREATE_REGIONS_GROUP = '/region/group/create';
const CREATE_REGIONS_SUBGROUP = '/region/group/createSub';
const REMOVE_REGIONS_GROUP = '/region/group/remove';
const REMOVE_REGIONS_SUBGROUP = '/region/group/removeSubGroup';

const CREATE_CIRCLE = '/region/circle/create';
const CREATE_POLYGON = '/region/polygon/create';
const CREATE_RECTANGLE = '/region/rectangle/create';
export const GET_ALL_LNP = '/assets/LNP';

export function postLNPs(serials) {
  return axios.post(GET_ALL_LNP, { serials });
}

export function postCreateRegionGroup(name, regionIds) {
  return axios.post(CREATE_REGIONS_GROUP, { name, regionIds });
}

export function postCreateRegionSubGroup(name, regionIds, groupId) {
  return axios.post(CREATE_REGIONS_SUBGROUP, { name, regionIds, groupId });
}
export function postRemoveRegionGroup(groupId) {
  return axios.post(REMOVE_REGIONS_GROUP, { groupId });
}
export function postRemoveRegionSubGroup(groupId) {
  return axios.post(REMOVE_REGIONS_SUBGROUP, { groupId });
}

export function postCreateAssetGroup(name, devicesId) {
  return axios.post(CREATE_ASSETS_GROUP, { name, devicesId });
}

export function postCreateAssetSubGroup(name, devicesId, groupId) {
  return axios.post(CREATE_ASSETS_SUBGROUP, { name, devicesId, groupId });
}
export function postRemoveAssetGroup(groupId) {
  return axios.post(REMOVE_ASSETS_GROUP, { groupId });
}
export function postRemoveAssetSubGroup(groupId) {
  return axios.post(REMOVE_ASSETS_SUBGROUP, { groupId });
}

export function postCreateRegion(shape, name, description, typeRegion, fillColor, strokeColor, transparency, radius, latitude, longitude, points) {
  let body = { name, description, typeRegion, fillColor, strokeColor, transparency };
  if (shape === 'Circle') {
    body = { ...body, radius, latitude, longitude };
  } else body = { ...body, points };
  console.log('body');
  console.info(body);

  switch (shape) {
    case 'Circle':
      return axios.post(CREATE_CIRCLE, { ...body });
    case 'Rectangle':
      return axios.post(CREATE_RECTANGLE, { ...body });
    case 'Polygon':
      return axios.post(CREATE_POLYGON, { ...body });
    default:
      break;
  }
}

// export const postConfigurations = (configurations) => {
//   console.info(configurations);
//   const { configurationId } = configurations;
//   if (configurationId === 0) return axios.post(ADD_CONFIGURATIONS_ROTOGRAMA, { ...configurations });
//   return axios.post(UPDATE_CONFIGURATIONS_ROTOGRAMA, { ...configurations });
// };
// export function postPublish(body){
//   return axios.post(PUBLISH_ROTOGRAMA,{...body})
// }

// export function postRotograma(name, description) {
//   return axios.post(ADD_ROTOGRAMA, { name, description });
// }
