import React, { useState, useEffect } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { CardContent } from '@material-ui/core';
import PortletIcon from '../../../components/Portlet/PortletIcon';
import PortletActions from '../../../components/Portlet/PorletActions';
import Button from '../../../components/CustomButton/Button';
import Table from '../../../components/Table/Table';

import { useForm } from 'react-hook-form';
import { useIntl } from '../../../lang/context/intlContext';

import { ADD_ROTOGRAMA_REGIONS } from './api/postMethods';
import { GET_ALL_REGIONS, GET_ALL_USERS_REGIONS } from './api/getMethods';
import { Alert } from '@material-ui/lab';
import useAxios from 'axios-hooks';
import { TableProvider } from '../../../components/Table/TableContext';
import TablePaper from '../../../components/Table/TablePaper';
import { formatMasterDetailRegions, getOptionGroups } from '../../../components/Table/helper';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Loading } from '../../../../Splash';
import { filterRegion, getSelectedRegions } from './helper';

const ICON_COLOR = 'linear-gradient(to bottom, #c5b1f9, #5978e4)';

const useStyleGrid = makeStyles((theme) => ({
  content: {
    display: 'flex',
    justifyContent: 'center',
    height: theme.spacing(45),
  },
  alert: { margin: theme.spacing(2) },
}));

function SelectRegions(props) {
  const { i18 } = useIntl();
  const { error, isLoading, currentNew, spokenRouteId, name } = useStoreState((state) => state.rotograma);
  const [apiError, setApiError] = useState(false);
  const { register, handleSubmit, errors } = useForm();
  const [tableApi, setTableApi] = useState(null);

  const setNoButtons = useStoreActions((actions) => actions.globalState.setNoButtons);
  const setPrimaryButton = useStoreActions((actions) => actions.globalState.setPrimaryButton);

  const regions = useStoreState((state) => state.region.regions);
  const configurations = useStoreState((state) => state.rotograma.configurations);
  const { setCurrentRegions } = useStoreActions((actions) => actions.rotograma);

  const selectedRegions = React.useCallback(getSelectedRegions(configurations), [configurations]);
  console.log('selectedRegions');
  console.info(selectedRegions);
  const filteredRegion = React.useCallback(filterRegion(regions, selectedRegions), [regions]);
  const optionsGroup = React.useCallback(getOptionGroups(filteredRegion, true), [filteredRegion]);

  const classes = useStyleGrid();
  const history = useHistory();
  const getCheckedRows = () => {
    const { regionIds, checkedItems } = tableApi.getCheckedRows();

    if (!regionIds.length) {
      alert('no regiones selected');
      return;
    }
    setCurrentRegions({ checkedRegionIds: checkedItems, regionIds });
    history.push(`/mfis/rotograma/add/region-configuration`);
    // return <Redirect to={`/mfis/rotograma/region-configuration`} />;
  };

  useEffect(() => {
    setPrimaryButton({ text: i18('Next'), isEnabled: true, fnc: getCheckedRows, disabled: false });
    return () => setNoButtons();
  }, [tableApi]);

  if (spokenRouteId < 1) return <Redirect to={`/mfis/rotograma/add`} />;

  const headCellsRegions = [
    {
      id: 'rootField',
      numeric: false,
      align: 'left',
      disablePadding: true,
      label: i18('GROUP/SUBGROUP/REGION'),
      width: '350px',
      defaultOrder: true,
    },
    { id: 'description', numeric: true, align: 'left', disablePadding: false, label: i18('Description'), width: '150px' },
    { id: 'typeShape', numeric: true, align: 'left', disablePadding: false, label: i18('typeShape'), width: '150px' },
    { id: 'typeRegionName', numeric: true, align: 'left', disablePadding: false, label: i18('typeRegionName'), width: '50px' },
    { id: 'radius', numeric: true, align: 'left', disablePadding: false, label: i18('radius'), width: '250px' },
    { id: 'OwnerName', numeric: true, align: 'left', disablePadding: false, label: i18('Client'), width: 'auto' },
  ];

  const filterInputs = [{ label: 'name', field: 'rootField' }];
  console.log('filteredRegion', filteredRegion);

  function onTableReady(params) {
    const { api } = params;
    // console.log('ready');

    // console.info(regionIds);
    // const { regionIds, checkedItems } = api.getCheckedRows();

    // if (!regionIds.length) {
    //   alert('no regiones selected');
    //   return;
    // }
    // setCurrentRegions({ checkedRegionIds: checkedItems, regionIds });
    setTableApi(api);
  }
  const onSubmit = () => {};
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <GridContainer className={classes.content}>
          <GridItem item xs={12} md={12} lg={12}>
            <PortletIcon title={i18('SELECT_REGIONS')} subTitle={i18('SELECT_REGIONS_SUBHEADER') + name}>
              <CardContent style={{ paddingTop: '0px' }}>
                <GridContainer direction="column" spacing={2}>
                  <GridItem item xs={12} md={12}>
                    <TableProvider
                      loadData={filteredRegion}
                      columns={headCellsRegions}
                      withPagination={true}
                      checkedItems={currentNew.tempRegionIds || []}
                      initRowsPerPage={50}
                      onContextReady={onTableReady}
                      useV2={true}
                    >
                      <TablePaper filterInputs={filterInputs} hasCheckBox={true} hasGroups={true} optionsGroup={optionsGroup} hasSearchAllFields={true}></TablePaper>
                    </TableProvider>
                  </GridItem>
                </GridContainer>
              </CardContent>
              <PortletActions>
                {/* <div className={classes.div}>
                  <div className={classes.inDiv}>
                    <Button variant="contained" disabled={putLoading} color={'cancel'}>
                      {i18('Cancel')}
                    </Button>
                  </div>
                  <div className={classes.inDiv}>
                    <Button type="submit" disabled={putLoading} variant="contained" color={'primary'}>
                      {i18('Create')}
                    </Button>
                    {putLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </div>
                </div> */}
              </PortletActions>
              {apiError && (
                <Alert className={classes.alert} severity="error">
                  {i18('API_ERR')}
                </Alert>
              )}
            </PortletIcon>
          </GridItem>
        </GridContainer>
      </form>
    </div>
  );
}

export default SelectRegions;
