//Main Icon Color
//0:Amarillo 1:Rojo 2: Azul

export const menu = [
  {
    id: '1',
    level: 1,
    path: '',
    name: 'Home',
    label: 'H',
    icon: 0,
    color: '',
    selected: false,
    isVisible: true,
  },
  {
    id: '2',
    level: 1,
    path: '',
    name: 'MFIS',
    label: 'M',
    icon: 2,
    color: '',
    selected: false,
    isVisible: true,
  },
  {
    id: '3',
    level: 1,
    path: '',
    name: 'Administration',
    label: 'A',
    icon: 1,
    color: '',
    selected: false,
    isVisible: true,
  },
  {
    id: '4',
    level: 1,
    path: '',
    name: 'Reports',
    label: 'R',
    icon: 2,
    color: '',
    selected: false,
    isVisible: true,
  },
];
