//#region IMPORT
import React, { useEffect, useState } from 'react';
import { AnimateSharedLayout } from 'framer-motion';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import Button from '../../../components/CustomButton/Button';
import CustomizedSnackbars from '../../../components/CustomSnackBar/CustomSnackBar';
import { whiteColor } from '../../../components/styles/constValues';

//#region MUI
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';
import AddIcon from '@material-ui/icons/Add';
import { Fab } from '@material-ui/core';
//#endregion
//context

import { useIntl } from '../../../lang/context/intlContext';

import { useStoreActions, useStoreState } from 'easy-peasy';
import { SalvarIcon } from '../../../../assets/icons';

import JourneyTime from './Configuration/JourneyTime';
import ContinousTime from './Configuration/ContinuousTime';
import LunchTime from './Configuration/LunchTime';
import RestTime from './Configuration/RestTime';
import { Loading } from '../../../../Splash';

import CreateMacro from './Configuration/CreateMacro';

import { postAddMacroConfiguration, postCreateMacro, postUpdateMulaMacro, postUpdateNextMacros, postUpdateRenameMacro, postUpdateTagMacro } from './api/postMethods';
import getMacros from './getMacros';
import TabPanel from './TabPanel';
import { getNewMacrosNext, reduceMacros } from './helper';

//#endregion
const useStyle = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
  rightMenu: {
    maxWidth: theme.spacing(37),
  },
  marginButton: {
    marginTop: theme.spacing(2),
    letterSpacing: '0.42px',
    fontSize: '16px',
    fontFamily: 'Roboto',
  },
  nextButton: {
    // marginBottom: '20px',
  },
  alarmIconStyle: {
    fill: '#5978e4',
    borderRadius: '4px',
  },
  alarmIconStyleSelected: {
    fill: whiteColor,
    borderRadius: '4px',
  },
  doneIconStyle: {
    color: '#6be48d',
  },
  macroGrid: { paddingBottom: theme.spacing(2), paddingRight: theme.spacing(1), justifyContent: 'center' },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(4),
    right: theme.spacing(8),
  },
}));

const Configuration = ({ history, match }) => {
  const classes = useStyle();
  const { i18 } = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const [macros, setMacros] = useState([]);
  const [value, setValue] = React.useState(0);
  const [openSnack, setOpenSnack] = React.useState({ open: false, title: '', severity: 'success' });
  const [openCreateMacro, setOpenCreateMacro] = React.useState(false);
  //#region store
  const journeyControl = useStoreState((state) => state.journeyControl);
  const actions = useStoreState((state) => state.journeyControl.actions);
  const setSubHeaderText = useStoreActions((actions) => actions.globalState.setSubHeaderText);
  const setPrimaryButton = useStoreActions((actions) => actions.globalState.setPrimaryButton);
  const setSecondaryButton = useStoreActions((actions) => actions.globalState.setSecondaryButton);
  const cleanUpSubHeader = useStoreActions((actions) => actions.globalState.cleanUpSubHeader);
  const saveConfiguration = useStoreActions((actions) => actions.journeyControl.saveConfiguration);
  const addNextMacro = useStoreActions((actions) => actions.journeyControl.addNextMacro);
  const addPrevMacro = useStoreActions((actions) => actions.journeyControl.addPrevMacro);
  const addActions = useStoreActions((actions) => actions.journeyControl.addActions);
  const updatedNextMacros = useStoreActions((actions) => actions.journeyControl.updatedNextMacros);
  const deleteNextMacro = useStoreActions((actions) => actions.journeyControl.deleteNextMacro);
  const addNewMacro = useStoreActions((actions) => actions.journeyControl.addNewMacro);
  const addRenameMacro = useStoreActions((actions) => actions.journeyControl.addRenameMacro);
  const addTag = useStoreActions((actions) => actions.journeyControl.addTag);
  const addMula = useStoreActions((actions) => actions.journeyControl.addMula);

  //#endregion
  //#region useEffect

  const isReadOnly = journeyControl.status >= 2;
  useEffect(() => {
    const { template, macros } = journeyControl;
    if (macros) return;
    const templateMacros = reduceMacros([], template.macros);
    saveToAPI(templateMacros);
  }, []);
  useEffect(() => {
    setSubHeaderText(journeyControl.name);
    setPrimaryButton({
      text: i18('review'),
      isEnabled: true,
      fnc: async () => {
        //await rollOut();
        history.push(`/mfis/control/configuration-review`);
      },
      disabled: !journeyControl.macros || isReadOnly,
    });
    setSecondaryButton({
      text: i18('SAVE_MACROS').toString().toUpperCase(),
      isEnabled: value === 0,
      fnc: async () => {
        const newMacros = getNewMacrosNext(journeyControl.macros);
        await postUpdateNextMacros({ addNextMacros: newMacros, deleteNextMacros: journeyControl.nextMacrosToDelete });
        updatedNextMacros();
        setOpenSnack((prev) => ({ ...prev, open: true, title: i18('Saved_Succesful'), severity: 'success' }));
      },
      disabled: isReadOnly,
      Icon: SalvarIcon,
    });

    return () => {
      cleanUpSubHeader();
    };
  }, [journeyControl.macros, macros, value, journeyControl.nextMacrosToDelete, journeyControl.status]);
  //#endregion
  //#region APICalls

  const saveMacros = async (macrosToStore) => {
    try {
      const res = await postAddMacroConfiguration({ journeyControlId: journeyControl.journeyControlId, macros: macrosToStore });
      const { data } = res;
      return data.dbMacros;
    } catch (error) {
      setOpenSnack((prev) => ({ ...prev, open: true, title: i18('ErrorOnComm'), severity: 'error' }));
    }
  };
  const saveToAPI = async (macros) => {
    if (!macros) return;
    setIsLoading(true);
    const macrosDB = await saveMacros(macros);
    saveConfiguration({ macros: macrosDB });
    setOpenSnack((prev) => ({ ...prev, open: true, title: i18('Saved_Succesful'), severity: 'success' }));
    setIsLoading(false);
  };

  const createMacro = async (props) => {
    setIsLoading(true);
    const { data } = await postCreateMacro({ ...props, journeyControlId: journeyControl.journeyControlId });
    addNewMacro(data.newMacro);
    addPrevMacro(data.newMacro);
    setIsLoading(false);
    setOpenSnack((prev) => ({ ...prev, open: true, title: i18('MACRO_CREATED_SUCCESFUL'), severity: 'success' }));
  };
  const updateMacroTag = async (props) => {
    await postUpdateTagMacro(props);
    addTag(props);
    setOpenSnack((prev) => ({ ...prev, open: true, title: i18('MACRO_TAG_UPDATED_SUCCESFUL'), severity: 'success' }));
  };
  const updateRenameMacro = async (props) => {
    await postUpdateRenameMacro(props);
    addRenameMacro(props);
    setOpenSnack((prev) => ({ ...prev, open: true, title: i18('MACRO_RENAME_UPDATED_SUCCESFUL'), severity: 'success' }));
  };
  const updateMacroMula = async (props) => {
    // console.info({ mula: props });
    await postUpdateMulaMacro(props);
    addMula(props);
    setOpenSnack((prev) => ({ ...prev, open: true, title: i18('MACRO_MULA_UPDATED_SUCCESFUL'), severity: 'success' }));
  };
  //#endregion
  //#region handlers
  const handleChange = (id) => {
    setValue(id);
  };

  const onClickDeleteNextMacro = (macro) => {
    deleteNextMacro(macro);
  };

  const selectMacros = journeyControl.macros
    ? journeyControl.macros.map((item) => {
        const { macroJourneyControlId: value, name: text } = item;
        return {
          value,
          text,
        };
      })
    : [];
  //#endregion

  if (isLoading)
    return (
      <GridContainer>
        <GridItem item xs={12} md={12} lg={12}>
          <Loading message={i18('SAVING')}></Loading>
        </GridItem>{' '}
      </GridContainer>
    );

  return (
    <div className={classes.root}>
      <div className={classes.rightMenu}>
        <GridContainer direction="row">
          <GridItem item xs={12} md={3} lg={3}>
            <GridContainer direction="row">
              <GridItem item xs={12} md={12} lg={12} className={classes.nextButton}>
                <Button
                  variant="contained"
                  color={value !== 0 ? 'next' : 'empty'}
                  seleccionado={value === 0}
                  value={value}
                  onClick={() => handleChange(0)}
                  className={classes.marginButton}
                  startIcon={<AccountTreeOutlinedIcon className={classes.alarmIconStyle} />}
                >
                  {i18('MACRO_LINK')}
                </Button>
              </GridItem>
              <GridItem item xs={12} md={12} lg={12} className={classes.nextButton}>
                <Button
                  variant="contained"
                  color={value !== 1 ? 'next' : 'empty'}
                  seleccionado={value === 1}
                  value={value}
                  onClick={() => handleChange(1)}
                  className={classes.marginButton}
                  startIcon={<AccessAlarmIcon className={classes.alarmIconStyle} />}
                >
                  {i18('JOURNEY_TIME')}
                </Button>
              </GridItem>
              <GridItem item xs={12} md={12} lg={12} className={classes.nextButton}>
                <Button
                  variant="contained"
                  color={value !== 2 ? 'next' : 'empty'}
                  seleccionado={value === 2}
                  value={value}
                  onClick={() => handleChange(2)}
                  className={classes.marginButton}
                  startIcon={<AccessAlarmIcon className={classes.alarmIconStyle} />}
                >
                  {i18('CONT_JOURNEY')}
                </Button>
              </GridItem>
              <GridItem item xs={12} md={12} lg={12} className={classes.nextButton}>
                <Button
                  variant="contained"
                  color={value !== 3 ? 'next' : 'empty'}
                  seleccionado={value === 3}
                  value={value}
                  onClick={() => handleChange(3)}
                  className={classes.marginButton}
                  startIcon={<AccessAlarmIcon className={classes.alarmIconStyle} />}
                >
                  {i18('LUNCH_TIME')}
                </Button>
              </GridItem>
              <GridItem item xs={12} md={12} lg={12} className={classes.nextButton}>
                <Button
                  variant="contained"
                  color={value !== 4 ? 'next' : 'empty'}
                  seleccionado={value === 4}
                  value={value}
                  onClick={() => handleChange(4)}
                  className={classes.marginButton}
                  startIcon={<AccessAlarmIcon className={classes.alarmIconStyle} />}
                >
                  {i18('REST_TIME')}
                </Button>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
      <TabPanel value={value} index={0}>
        <GridContainer>
          <AnimateSharedLayout>
            {getMacros(
              { macros: journeyControl.macros, classes: classes.macroGrid, actions },
              i18,
              addNextMacro,
              onClickDeleteNextMacro,
              isReadOnly,
              addActions,
              updateRenameMacro,
              updateMacroTag,
              updateMacroMula
            )}
          </AnimateSharedLayout>
        </GridContainer>
        <Fab color="primary" disabled={isReadOnly} aria-label="add" className={classes.fab} onClick={() => setOpenCreateMacro(true)}>
          <AddIcon />
        </Fab>
        <CreateMacro openGroup={openCreateMacro} setOpenGroup={setOpenCreateMacro} macros={selectMacros} onOk={createMacro}></CreateMacro>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <JourneyTime handleNextChange={handleChange} isReadOnly={isReadOnly} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ContinousTime handleNextChange={handleChange} isReadOnly={isReadOnly} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <LunchTime handleNextChange={handleChange} isVisible={value === 3} isReadOnly={isReadOnly} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <RestTime handleNextChange={handleChange} isReadOnly={isReadOnly} />
      </TabPanel>
      <CustomizedSnackbars open={openSnack.open} setOpen={setOpenSnack} title={openSnack.title} severity={openSnack.severity}></CustomizedSnackbars>
    </div>
  );
};

export default Configuration;
