import React, { useState } from 'react';
import { useStoreState } from 'easy-peasy';

import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { CardContent, Checkbox, CircularProgress } from '@material-ui/core';

import { postRole } from '../../api/postMethods';
import { useIntl } from '../../../../lang/context/intlContext';
import { useFormStyle } from '../../../../common/styles/formStyle';

import Button from '../../../../components/CustomButton/Button';
import GridContainer from '../../../../components/Grid/GridContainer';
import GridItem from '../../../../components/Grid/GridItem';
import Portlet from '../../../../components/Portlet/Portlet';
import PortletActions from '../../../../components/Portlet/PorletActions';
import AutoCompleteBox from '../../../../components/ComboBox/AutoCompleteBox';
import { Loading } from '../../../../../Splash';
import CustomizedSnackbars from '../../../../components/CustomSnackBar/CustomSnackBar';
import TableReport from '../../table/TableReport';
import { initSnack } from '../../table/stylesReport';

import { columnsRole } from './dataRole';
import { useLayoutUIContext } from '../../../../layout/context/layoutContext';

const Role = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [openSnack, setOpenSnack] = React.useState(initSnack);
  const classes = useFormStyle();
  const { i18 } = useIntl();
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  // Table
  const [columns, setColumns] = useState([]);
  const [table, setTable] = useState([]);
  // Roles
  const [selectedRole, setSelectedRole] = useState([]);
  const roles = useStoreState((state) => state.user.roles);

  const { titles } = useLayoutUIContext();
  const handleSubmitForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      setTable([]);
      setColumns(
        columnsRole.map((data) => {
          return { ...data, title: i18(data.title) };
        })
      );
      const body = {
        rolIds: selectedRole.map((rol) => rol.rolId),
      };

      await loadRole(body);
      setIsLoading(false);
    } catch (error) {
      setOpenSnack((prev) => ({
        ...prev,
        open: true,
        title: i18('ERROR_API'),
        severity: 'error',
      }));
    }
  };

  const handleReset = (e) => {
    setSelectedRole([]);
  };

  const loadRole = async (url) => {
    try {
      const { data: restRole } = await postRole(url);
      setTable(restRole.roles);
    } catch (error) {
      setOpenSnack((prev) => ({
        ...prev,
        open: true,
        title: i18('ERROR_API'),
        severity: 'error',
      }));
    }
  };
  return (
    <div>
      <form onSubmit={handleSubmitForm}>
        <GridContainer className={classes.content}>
          <GridItem item xs={12} md={12} lg={12} className={classes.content}>
            <Portlet className={classes.formStyle}>
              <CardContent>
                <div>
                  <GridContainer justify="center" spacing={4}>
                    <GridItem justify="center" container>
                      <GridItem item xs={4} md={4} lg={4}>
                        <AutoCompleteBox
                          label={i18('ROLES')}
                          multiple
                          id="checkboxes-tags-demo"
                          options={roles}
                          value={selectedRole}
                          disableCloseOnSelect
                          getOptionLabel={(option) => option.name}
                          onChange={(prev, newValue) => setSelectedRole(newValue)}
                          limitTags={5}
                          renderOption={(option, { selected }) => (
                            <React.Fragment>
                              <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                              {option.name}
                            </React.Fragment>
                          )}
                          style={{
                            maxHeight: '230px',
                            marginRight: 15,
                          }}
                        />
                      </GridItem>
                    </GridItem>
                    <GridItem item md={3} lg={3}>
                      <div className={classes.div}>
                        <div className={classes.inDiv}>
                          <Button onClick={handleReset} type="button" variant="contained" color={'cancel'}>
                            {i18('CLEAR')}
                          </Button>
                        </div>
                        <div className={classes.inDiv}>
                          <Button type="submit" variant="contained" color={'primary'} disabled={isLoading}>
                            {i18('SEARCH')}
                          </Button>
                          {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                      </div>
                    </GridItem>
                  </GridContainer>
                  {isLoading ? <Loading /> : columns.length > 0 && <TableReport columns={columns} dataTable={table} />}
                  <CustomizedSnackbars open={openSnack.open} setOpen={setOpenSnack} title={openSnack.title} severity={openSnack.severity}></CustomizedSnackbars>
                </div>
              </CardContent>
              <PortletActions></PortletActions>
            </Portlet>
          </GridItem>
        </GridContainer>
      </form>
    </div>
  );
};
export default Role;
