import React, { useState } from 'react';
import { useStoreState } from 'easy-peasy';

import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { CardContent, Checkbox, CircularProgress, FormControl } from '@material-ui/core';
import { postStatusSpokenRoutes } from '../../api/postMethods';
import { useIntl } from '../../../../lang/context/intlContext';
import { useFormStyle } from '../../../../common/styles/formStyle';

import Button from '../../../../components/CustomButton/Button';
import GridContainer from '../../../../components/Grid/GridContainer';
import GridItem from '../../../../components/Grid/GridItem';
import Portlet from '../../../../components/Portlet/Portlet';
import PortletActions from '../../../../components/Portlet/PorletActions';
import AutoCompleteBox from '../../../../components/ComboBox/AutoCompleteBox';
import { Loading } from '../../../../../Splash';
import CustomizedSnackbars from '../../../../components/CustomSnackBar/CustomSnackBar';
import TableReport from '../../table/TableReport';

import { columnsStatusSpoken, optionsSituations, initFilter } from './dataStatusSpokenRoute';
import { initSnack } from '../../table/stylesReport';
import SelectComboBox from '../../../../components/ComboBox/SelectComboBox';

const StatusSpokenRoute = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [openSnack, setOpenSnack] = React.useState(initSnack);
  const classes = useFormStyle();
  const { i18 } = useIntl();
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  // Form
  const [formValues, setFormValues] = useState(initFilter);
  const { situation } = formValues;

  // Table
  const [columns, setColumns] = useState([]);
  const [table, setTable] = useState([]);
  // Spoken routes
  const [selectedSpokenRoute, setSelectedSpokenRoute] = useState([]);
  const SpokenRoute = useStoreState((state) => state.user.spokenRoutes);
  // Status
  const [selectedStatus, setSelectedStatus] = useState([]);
  const status = useStoreState((state) => state.user.spokenRoutesStatus);

  const handleInputChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      setTable([]);
      setColumns(columnsStatusSpoken.map((data) => ({ ...data, title: i18(data.title) })));
      const body = {
        spokenRouteIds: selectedSpokenRoute.map((SpokenRoute) => SpokenRoute.journeyControlId),
        statusRF: selectedStatus.map((status) => status.statusId),
      };
      if (situation !== '0') {
        body.situationRF = situation;
      }
      await loadStatusSpokenRoute(body);
      setIsLoading(false);
    } catch (error) {
      setOpenSnack((prev) => ({
        ...prev,
        open: true,
        title: i18('ERROR_API'),
        severity: 'error',
      }));
    }
  };

  const handleReset = (e) => {
    setFormValues(initFilter);
    setSelectedSpokenRoute([]);
    setSelectedStatus([]);
  };

  const loadStatusSpokenRoute = async (url) => {
    try {
      const { data: restStatusSpokenRoute } = await postStatusSpokenRoutes(url);
      setTable(restStatusSpokenRoute.spokenRoutes);
    } catch (error) {
      setOpenSnack((prev) => ({
        ...prev,
        open: true,
        title: i18('ERROR_API'),
        severity: 'error',
      }));
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmitForm}>
        <GridContainer className={classes.content}>
          <GridItem item xs={12} md={12} lg={12} className={classes.content}>
            <Portlet className={classes.formStyle}>
              <CardContent>
                <div>
                  <GridContainer justify="center" spacing={4}>
                    <GridItem justify="center" container>
                      <GridItem item xs={3} md={3} lg={3}>
                        <AutoCompleteBox
                          label={i18('SPOKENREGIONS')}
                          multiple
                          id="checkboxes-tags-demo"
                          options={SpokenRoute}
                          value={selectedSpokenRoute}
                          disableCloseOnSelect
                          getOptionLabel={(option) => option.name}
                          onChange={(prev, newValue) => setSelectedSpokenRoute(newValue)}
                          limitTags={10}
                          renderOption={(option, { selected }) => (
                            <React.Fragment>
                              <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                              {option.name}
                            </React.Fragment>
                          )}
                          style={{ marginRight: 15 }}
                        />
                      </GridItem>
                      <GridItem item xs={3} md={3} lg={3}>
                        <AutoCompleteBox
                          label={i18('STATUS')}
                          multiple
                          id="checkboxes-tags-demo"
                          options={status}
                          value={selectedStatus}
                          disableCloseOnSelect
                          getOptionLabel={(option) => option.name}
                          onChange={(prev, newValue) => setSelectedStatus(newValue)}
                          limitTags={10}
                          renderOption={(option, { selected }) => (
                            <React.Fragment>
                              <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                              {option.name}
                            </React.Fragment>
                          )}
                          style={{ marginRight: 15 }}
                        />
                      </GridItem>
                      <GridItem item xs={3} md={3} lg={3}>
                        <FormControl variant="outlined" className={classes.formControlSituation}>
                          <SelectComboBox
                            name="situation"
                            value={situation}
                            onChange={handleInputChange}
                            label={'situation'}
                            options={optionsSituations.map((option) => ({
                              ...option,
                              text: i18(option.text),
                            }))}
                            placeholder={i18('SITUATION')}
                          ></SelectComboBox>
                        </FormControl>
                      </GridItem>
                    </GridItem>
                    <GridItem item md={3} lg={3}>
                      <div className={classes.div}>
                        <div className={classes.inDiv}>
                          <Button onClick={handleReset} type="button" variant="contained" color={'cancel'}>
                            {i18('CLEAR')}
                          </Button>
                        </div>
                        <div className={classes.inDiv}>
                          <Button type="submit" variant="contained" color={'primary'} disabled={isLoading}>
                            {i18('SEARCH')}
                          </Button>
                          {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                      </div>
                    </GridItem>
                  </GridContainer>
                  {isLoading ? <Loading /> : columns.length > 0 && <TableReport columns={columns} dataTable={table} />}
                  <CustomizedSnackbars
                    open={openSnack.open}
                    setOpen={setOpenSnack}
                    title={openSnack.title}
                    severity={openSnack.severity}
                  ></CustomizedSnackbars>
                </div>
              </CardContent>
              <PortletActions></PortletActions>
            </Portlet>
          </GridItem>
        </GridContainer>
      </form>
    </div>
  );
};
export default StatusSpokenRoute;
