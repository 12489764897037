export const getWeekDays = (i18) => {
  return [
    { value: 'MON', text: i18('MON') },
    { value: 'TUE', text: i18('TUE') },
    { value: 'WEN', text: i18('WEN') },
    { value: 'THU', text: i18('THU') },
    { value: 'FRI', text: i18('FRI') },
    { value: 'SAT', text: i18('SAT') },
    { value: 'SUN', text: i18('SUN') },
  ];
};
