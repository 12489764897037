import { administrationSubMenu } from './subMenus/administration';
import { homeSubMenu } from './subMenus/home';
import { mfisSubMenu } from './subMenus/mfis';
import { reportSubMenu } from './subMenus/report';

export const subMenuData = [
  {
    name: 'Home',
    label: 'H',
    path: 'home',
    isVisible: true,
    subMenu: homeSubMenu,
  },
  {
    name: 'MFIS',
    path: 'mfis',
    label: 'M',
    isVisible: true,
    subMenu: mfisSubMenu,
  },
  {
    name: 'Administration',
    path: 'administration',
    label: 'A',
    isVisible: true,
    subMenu: administrationSubMenu,
  },
  {
    name: 'Reports',
    path: 'reports',
    label: 'R',
    isVisible: false,
    subMenu: reportSubMenu,
  },

  {
    name: 'Configuration',
    path: 'configuration',
    label: 'C',
    isVisible: false,
    subMenu: [
      {
        parent: 'R',
        id: '9',
        level: 2,
        path: 'security',
        name: 'Security',
        label: 'H',
        icon: 'SecurityIcon',
        color: '',
        hasCollapsed: true,
        status: 'normal',
        isVisible: false,
        subMenus: [
          {
            status: 'normal',
            id: '9.1',
            level: 3,
            path: '/configuration/security/roles',
            name: 'Rol',
            label: 'H',
            icon: 'AssignmentIcon',
            prev: '9',
            next: '9.2',
            isVisible: true,
          },
          {
            status: 'normal',
            id: '9.2',
            level: 3,
            path: '/configuration/security/users',
            name: 'Users',
            label: 'H',
            icon: 'AccountBoxIcon',
            prev: '9.1',
            next: '9.3',
            isVisible: true,
          },
          {
            status: 'blind',
            id: '9.3',
            level: 3,
            path: '#',
            name: '',
            label: 'H',
            icon: undefined,
          },
        ],
        selected: false,
      },
    ],
  },
];
