//@ts-nocheck
import { action } from 'easy-peasy';
//primary: {text,}
const NO_BUTTONS = {
  primaryButton: { disabled: false, isEnabled: false, text: '', func: () => {} },
  secondaryButton: { disabled: false, isEnabled: false, text: '', func: () => {}, Icon: null },
};
//IsEnabled:If it's visible
const primaryButton = { disabled: false, isEnabled: false, text: '', func: () => {} };
const secondaryButton = { disabled: false, isEnabled: false, text: '', func: () => {} };
const initValues = {
  openSidebar: false,
  token: '',
  headerMenu: [],
  subHeader: { primaryButton, secondaryButton },
  userName: '',
  clientName: '',
  email: '',
  subHeaderText: '',
  hasSubHeaderText: false,
  isAuthorized: false,
};
const globalState = {
  ...initValues,
  setNoButtons: action((state) => {
    state.headerMenu = [];
    state.subHeader = { ...NO_BUTTONS };
  }),
  onLogin: action((global, payload) => {
    const { token, userName, email, clientName } = payload;
    global.tokengridContainer = token;
    global.userName = userName;
    global.email = email;
    global.clientName = clientName;
    global.isAuthorized = true;
  }),
  onFailLogin: action((global) => {
    global.token = undefined;
  }),
  setOpenSidebar: action((global, state) => {
    global.openSidebar = state;
  }),
  setSecondaryButton: action((state, secondaryButton) => (state.subHeader.secondaryButton = secondaryButton)),
  setPrimaryButton: action((state, primaryButton) => (state.subHeader.primaryButton = primaryButton)),
  setHeaderMenu: action((global, headerMenu) => {
    global.headerMenu = headerMenu;
  }),
  setSubHeaderText: action((global, subHeaderText) => {
    global.subHeaderText = subHeaderText;
    global.hasSubHeaderText = subHeaderText.length > 0;
  }),
  //this functions allows set buttons value in one function
  setButtons: action((global, payload) => {
    const { headerMenu, primaryButton, secondaryButton } = payload;
    global.headerMenu = headerMenu || [];
    global.subHeader = { primaryButton, secondaryButton };
  }),
  cleanUpSubHeader: action((global) => {
    global.subHeaderText = '';
    global.hasSubHeaderText = false;
    global.headerMenu = [];
    global.subHeader = { primaryButton, secondaryButton };
  }),
};

export default globalState;
