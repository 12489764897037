export const driversType = [
  { value: '-1', text: '' },
  { value: '0', text: 'Motorista' },
  { value: '1', text: 'Manutencao' },
  { value: '2', text: 'Treinador' },
  { value: '3', text: 'Administrador' },
];

export const getDriverType = (type) => driversType.find((item) => item.value === type.toString()).text;

export const CAN_BUS = [
  { value: '0', text: 'OBD 1' },
  { value: '1', text: 'j1939' },
  { value: '2', text: 'j1708' },
];
export const COUNTRIES = [
  { value: '1', text: 'Brasil' },
  { value: '2', text: 'Mexico' },
  { value: '3', text: 'Venezuela' },
];
export const YEARS = () => {
  const currentYear = new Date().getFullYear();
  const lastYear = 1950;
  const years = [];

  for (let index = currentYear; index >= lastYear; index--) {
    years.push({ value: index.toString(), text: index.toString() });
  }
  return years;
};

export const COLOR = [
  { value: '1', text: 'Azul' },
  { value: '2', text: 'Amarillo' },
  { value: '3', text: 'Negro' },
  { value: '4', text: 'Rojo' },
  { value: '5', text: 'Blanco' },
  { value: '6', text: 'Plata' },
  { value: '7', text: 'Verde' },
  { value: '8', text: 'Purpura' },
  { value: '9', text: 'Gris' },
  { value: '10', text: 'Cafe' },
  { value: '11', text: 'Celeste' },
  { value: '12', text: 'Variado' },
  { value: '13', text: 'Vinotinto' },
  { value: '14', text: 'Beige' },
];
export const POWER_SUPPLY = [
  { value: '0', text: '12v' },
  { value: '1', text: '24v' },
];
export const FUEL = [
  { value: '0', text: 'NA' },
  { value: '1', text: 'Bencina' },
  { value: '2', text: 'Gas' },
  { value: '3', text: 'Diesel' },
  { value: '4', text: 'Electrical' },
];
