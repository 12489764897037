import { makeStyles, Typography } from '@material-ui/core';
import { useStoreActions, useStoreState } from 'easy-peasy';
import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import swal from 'sweetalert';
import { FormDescriptionIcon, FormNameIcon } from '../../../../assets/icons/formIcons';
import { Loading } from '../../../../Splash';
import { useFormStyle } from '../../../common/styles/formStyle';
import AutoCompleteBox from '../../../components/ComboBox/AutoCompleteBox';
import ComboBox from '../../../components/ComboBox/ComboBox';
import CustomizedSnackbars from '../../../components/CustomSnackBar/CustomSnackBar';
import SuperStepper from '../../../components/CustomStepper/SuperStepper';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import InputText from '../../../components/InputText/InputText';
import { useIntl } from '../../../lang/context/intlContext';
import MapSimple from '../../../pages/MapSimple';
import { getDriversNFC } from '../../administration/api/getMethods';
import { getInvalidEvidence } from './api/getMethods';
import { postPublishReconcile, postUpdateReconcile } from './api/postMethods';
import { evidenceStatus, evidenceType, getBaseURL, getEvidenceStatus, reconcileTypes } from './helper';
import ListEmail from './ListEmail';
import './sweetalert.css';

// steps definition
const getSteps = () => {
  return ['Analyze', 'Reconcile', 'CHECK_AND_PUBLISH'];
};
const useLocalStyle = makeStyles((theme) => ({
  stepper: {
    padding: theme.spacing(2),
  },
  level: {
    width: '150px',
  },
  evidence: {
    width: '250px',
  },
}));

const Reconcile = (props) => {
  const classesForm = useFormStyle();
  const { i18 } = useIntl();
  const { location } = props;
  const { match, history } = props;
  const { trackingEvidenceId = 0 } = match.params;
  const { reconcileId = 0 } = match.params;
  const modePage = reconcileId > 0 ? 'edit' : 'new';
  const urlToReturn = modePage === 'new' ? '/home/evidence/map' : '/mfis/reconcile/management?reload=true';
  const [isLoading, setIsLoading] = useState(false);
  const [openSnack, setOpenSnack] = React.useState({ open: false, title: '', severity: 'success' });
  const baseURL = getBaseURL(window);

  const evidences = useStoreState((state) => state.evidences.toGridMap);
  const reconciles = useStoreState((state) => state.evidences.reconciles); // this must be checked
  const clearReconciles = useStoreActions((actions) => actions.evidences.clearReconciles);
  const addDrivers = useStoreActions((actions) => actions.driver.addDrivers);
  const updateEvidenceStatus = useStoreActions((actions) => actions.evidences.updateEvidenceStatus);
  const drivers = useStoreState((state) => state.driver.drivers);
  const user = useStoreState((state) => state.user);

  const comboDrivers = drivers.length ? [{ value: '0', text: 'Selecione motorista' }, ...drivers] : [];
  const evidence = evidences.find((item) => +item.trackingEvidenceId === +trackingEvidenceId);
  const reconcile = reconciles.find((item) => +item.reconcileId === +reconcileId);

  const [tratativa, setTratativa] = useState({
    evidenceTypeId: modePage === 'new' ? evidence.evidenceType.toString() : reconcile.evidenceType.toString(),
    driverId: modePage === 'new' ? evidence.driverId.toString() : reconcile.driverId.toString(),
    tratativaId: modePage === 'new' ? '0' : reconcile.reconcileType,
    description: modePage === 'new' ? '' : reconcile.description,
    emails: modePage === 'new' ? [] : reconcile.emails,
    name: modePage === 'new' ? evidence.name : reconcile.name,
    driverName: modePage === 'new' ? evidence.driverName : reconcile.driverName,
    asset: modePage === 'new' ? evidence.asset : reconcile.asset,
    dateTime: modePage === 'new' ? evidence.endDateTime : reconcile.created_at,
    levelName: modePage === 'new' ? evidence.levelName : reconcile.levelName,
    latitude: modePage === 'new' ? evidence.latitude : reconcile.latitude,
    longitude: modePage === 'new' ? evidence.longitude : reconcile.longitude,
    address: modePage === 'new' ? evidence.address : reconcile.address,
    urlVideoFile: modePage === 'new' ? evidence.urlVideoFile : reconcile.urlVideoFile,
    errors: { email: undefined },
    mode: modePage,
    reconcileId: reconcileId,
    trackingEvidenceId: trackingEvidenceId,
    baseURL,
  });

  const steps = getSteps().map((item) => i18(item));

  const handleComboGroupsChange = (e) => {
    setTratativa((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const handleOnChangeDescription = (value) => {
    setTratativa((prev) => ({ ...prev, description: value }));
  };
  const handleOnKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (e.target.value.length === 0) return;
      const email = e.target.value;
      if (!validateEmail(email)) {
        setTratativa((prev) => ({ ...prev, errors: { email: 'Email inválido' } }));
        return;
      }
      setTratativa((prev) => ({ ...prev, emails: [...prev.emails, { title: email }] }));
      e.target.value = '';
    }
  };
  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };
  const onClickDeleteEmail = (email) => {
    setTratativa((prev) => ({
      ...prev,
      emails: prev.emails.filter((item) => item.title !== email),
    }));
  };
  const onClickInvalidEvidence = async () => {
    try {
      const userSelection = await swal({
        title: 'Invalidar evidência',
        text: i18('CONFIRM_INVALID_EVIDENCE'),
        icon: 'warning',
        buttons: {
          cancel: 'Cancelar',
          ok: 'Confirmar',
        },
        dangerMode: true,
      });

      if (userSelection) {
        await getInvalidEvidence(tratativa.trackingEvidenceId); // this invalid evidence in DB
        const statusName = getEvidenceStatus[3];
        updateEvidenceStatus({ trackingEvidenceId: tratativa.trackingEvidenceId, status: 3, statusName });
        await swal(i18('INVALID_EVIDENCE_DONE'));
        if (modePage === 'new') window.location.assign(`/home/evidence/map?remove=${evidence.trackingEvidenceId}`);
        else window.location.assign(urlToReturn);
      }

      // if (window.confirm(i18('CONFIRM_INVALID_EVIDENCE'))) {
      //   await getInvalidEvidence(evidence.trackingEvidenceId);
      //   window.location.assign(`/home/evidence/map?remove=${evidence.trackingEvidenceId}`);
      // }
    } catch (error) {}
  };
  console.table(comboDrivers);
  console.info(tratativa);
  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <GridItem container direction="row">
            <GridContainer spacing={1} item xs={6} md={6} lg={6}>
              <GridItem item xs={9} md={9} lg={9}>
                <InputText id="name" value={tratativa.name} name="name" smallInput icon={FormNameIcon}></InputText>
              </GridItem>
              <GridItem item xs={3} md={3} lg={3}>
                <InputText id="level" value={tratativa.levelName} name="level" smallInput icon={FormNameIcon}></InputText>
              </GridItem>
              <GridItem item xs={6} md={6} lg={6}>
                <InputText id="asset" value={tratativa.asset} name="asset" icon={FormNameIcon} smallInput></InputText>
              </GridItem>
              <GridItem item xs={6} md={6} lg={6}>
                <InputText id="dateTime" value={tratativa.dateTime} smallInput name="dateTime" icon={FormNameIcon}></InputText>
              </GridItem>
              <GridItem item md={12} lg={12}>
                <InputText id="driverName" value={tratativa.driverName} smallInput name="driverName" icon={FormNameIcon}></InputText>
              </GridItem>
              <GridItem item md={12} lg={12}>
                <InputText id="address" value={tratativa.address} smallInput name="address" icon={FormNameIcon}></InputText>
              </GridItem>
              <GridItem item xs={12} md={12}>
                <MapSimple item={tratativa} />
              </GridItem>
            </GridContainer>
            {/* VIDEOS */}
            <GridContainer item xs={6} md={6} lg={6}>
              <GridItem item xs={11} md={11} lg={11}>
                <ReactPlayer url={tratativa.urlVideoFile} width="100%" height="420px" controls volumen={0.8} />
              </GridItem>
            </GridContainer>
          </GridItem>
        );
      case 1:
        return (
          <GridItem container direction="row">
            <GridContainer spacing={1} item xs={6} md={6} lg={6}>
              <GridItem item xs={9} md={9} lg={9}>
                <ComboBox icon={FormNameIcon} name="evidenceTypeId" value={tratativa.evidenceTypeId} onChange={handleComboGroupsChange} options={evidenceType} placeholder={i18('Groups')} smallInput></ComboBox>
              </GridItem>
              <GridItem item xs={3} md={3} lg={3}>
                <InputText id="level" value={evidenceType.find((item) => item.value === tratativa.evidenceTypeId).levelName} name="level" smallInput icon={FormNameIcon}></InputText>
              </GridItem>
              <GridItem item xs={6} md={6} lg={6}>
                <InputText id="asset" value={tratativa.asset} name="asset" icon={FormNameIcon} smallInput></InputText>
              </GridItem>
              <GridItem item xs={6} md={6} lg={6}>
                <InputText id="startDateTime" value={tratativa.dateTime} smallInput name="startDateTime" icon={FormNameIcon}></InputText>
              </GridItem>
              <GridItem item md={12} lg={12}>
                <ComboBox icon={FormNameIcon} name="driverId" value={tratativa.driverId} onChange={handleComboGroupsChange} options={comboDrivers} placeholder={i18('Groups')} smallInput></ComboBox>
              </GridItem>
              <GridItem item md={12} lg={12}>
                <InputText id="address" value={tratativa.address} smallInput name="address" icon={FormNameIcon}></InputText>
              </GridItem>
              <GridItem item xs={12} md={12}>
                <ComboBox icon={FormNameIcon} name="tratativaId" value={tratativa.tratativaId.toString()} onChange={handleComboGroupsChange} options={reconcileTypes} placeholder={i18('Groups')} smallInput></ComboBox>
              </GridItem>
              <GridItem item xs={12} md={12}>
                <InputText id="description" name="description" placeholder={i18('description') + ' (' + i18('optional') + ')'} value={tratativa.description} onChange={(e) => handleOnChangeDescription(e.target.value)} icon={FormDescriptionIcon} multiline rows={4}></InputText>
              </GridItem>
            </GridContainer>
            {/* VIDEOS */}
            <GridContainer item xs={6} md={6} lg={6}>
              <GridItem item xs={11} md={11} lg={11}>
                <ReactPlayer
                  url={tratativa.urlVideoFile} // local
                  width="100%"
                  height="420px"
                  controls
                  volumen={0.8}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
        );
      case 2:
        return (
          <GridItem container direction="row">
            <GridContainer spacing={1} item xs={11} md={11} lg={11}>
              <GridItem item xs={5} md={5} lg={5}>
                <InputText id="name" value={evidenceType.find((item) => +item.value === +tratativa.evidenceTypeId).text} name="name" smallInput icon={FormNameIcon}></InputText>
              </GridItem>
              <GridItem item xs={1} md={1} lg={1}>
                <InputText id="level" value={evidenceType.find((item) => item.value === tratativa.evidenceTypeId).levelName} name="level" smallInput icon={FormNameIcon}></InputText>
              </GridItem>
              <GridItem item xs={3} md={3} lg={3}>
                <InputText id="asset" value={tratativa.asset} name="asset" icon={FormNameIcon} smallInput></InputText>
              </GridItem>
              <GridItem item xs={3} md={3} lg={3}>
                <InputText id="startDateTime" value={tratativa.dateTime} smallInput name="startDateTime" icon={FormNameIcon}></InputText>
              </GridItem>
              <GridItem item md={4} lg={4}>
                <InputText id="driverName" value={drivers.find((item) => +item.value === +tratativa.driverId).text} smallInput name="driverName" icon={FormNameIcon}></InputText>
              </GridItem>
              <GridItem item md={4} lg={4}>
                <InputText id="address" value={tratativa.address} smallInput name="address" icon={FormNameIcon}></InputText>
              </GridItem>
              <GridItem item md={4} lg={4}>
                <InputText id="tratativa" value={reconcileTypes.find((item) => +item.value === +tratativa.tratativaId).text} smallInput name="tratativa" icon={FormNameIcon}></InputText>
              </GridItem>
              <GridItem item md={6} lg={6}>
                <InputText id="createBy" value={user.userName + ' - ' + user.clientName} smallInput name="createBy" icon={FormNameIcon}></InputText>
              </GridItem>
              <GridItem item xs={6} md={6} lg={6}>
                <InputText id="email" smallInput placeholder={i18('INSIRA_EMAIL_NOTIF')} onChange={(e) => setTratativa({ ...tratativa, errors: { email: undefined } })} onKeyPress={handleOnKeyPress} name="email" icon={FormNameIcon}></InputText>
                {tratativa.errors.email && <Typography color="secondary">{i18('INVALID_EMAIL')}</Typography>}
              </GridItem>{' '}
              <GridItem item xs={6} md={6}>
                <InputText id="description" name="description" placeholder={i18('description') + ' (' + i18('optional') + ')'} defaultValue={tratativa.description} readOnly icon={FormDescriptionIcon} multiline rows={4}></InputText>
              </GridItem>
              <GridItem item xs={6} md={6} lg={6}>
                <ListEmail items={tratativa.emails} hasDelete={true} onClickDelete={onClickDeleteEmail}></ListEmail>
              </GridItem>
            </GridContainer>
            {/* VIDEOS */}
            {/* <GridContainer item xs={6} md={6} lg={6}>
              <GridItem item xs={11} md={11} lg={11}>
                <InputText
                  id="driverName"
                  smallInput
                  placeholder={i18('INSIRA_EMAIL')}
                  name="driverName"
                  icon={FormNameIcon}
                ></InputText>
              </GridItem>
              <GridItem item xs={11} md={11} lg={11}>
                <ListEmail items={[{ title: 'email1' }, { title: 'email2' }, { title: 'email3' }]} hasDelete={true} onClickDelete={() => {}}></ListEmail>
              </GridItem>
            </GridContainer> */}
          </GridItem>
        );
      case 3:
        return (
          <GridItem justify="center" container spacing={2}>
            <GridItem item xs={10} md={8} lg={8}>
              <GridContainer spacing={2}>
                <GridItem item xs={12} md={6} lg={6}>
                  {/* <ListWrap
                    title={i18('SELECTED_EVENTS')}
                    classes={classes}
                    customStyle={{
                      marginTop: '4px',
                      maxWidth: '100%',
                      height: '400px',
                      backgroundColor: '#f8f9fd',
                      maxHeight: '400px',
                      overflow: 'auto',
                    }}
                  >
                    {getSelectedEventsList(mfisEvents, selectedEvents)}
                  </ListWrap> */}
                </GridItem>
                <GridItem item xs={12} md={6} lg={6}>
                  {/* <ListWrap
                    title={i18('SELECTED_TABLETS')}
                    classes={classes}
                    customStyle={{
                      marginTop: '4px',
                      maxWidth: '100%',
                      height: '400px',
                      backgroundColor: '#f8f9fd',
                      maxHeight: '400px',
                      overflow: 'auto',
                    }}
                  >
                    {getSelectedTabletsList(selectedTabletsAsset)}
                  </ListWrap> */}
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridItem>
        );
      default:
        return i18('UPDATE_SUCESSFUL');
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    if (!drivers.length) {
      const res = await getDriversNFC();
      addDrivers(res.data.driversDetails);
    }
    setIsLoading(false);
  };
  const onSubmit = async () => {
    try {
      const body = { tratativa };
      if (modePage === 'new') return postPublishReconcile(body);
      else {
        return postUpdateReconcile(body);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const onResetSubmit = async () => {
    window.location.assign(urlToReturn);
  };

  const stepsValidation = [true, +tratativa.driverId > 0 && +tratativa.evidenceTypeId > 0 && +tratativa.tratativaId > 0, true];
  const actionButtons = [{ step: 0, label: 'INVALID', color: 'danger', onClick: onClickInvalidEvidence }];
  if (isLoading)
    return (
      <GridContainer className={classesForm.content}>
        <GridItem item xs={12} md={12} lg={12}>
          <Loading></Loading>
        </GridItem>{' '}
      </GridContainer>
    );
  return (
    <div>
      <SuperStepper title={'reconcile'} subTitle={'CREATE_SUBHEADER_SR'} validators={stepsValidation} labelFinalStep={'Save'} steps={steps} getStepContent={getStepContent} onSubmit={onSubmit} onResetSubmit={onResetSubmit} stepperSize={'lg'} actionButtons={actionButtons} returnFirstStep={urlToReturn}></SuperStepper>
      <CustomizedSnackbars open={openSnack.open} setOpen={setOpenSnack} title={openSnack.title} severity={openSnack.severity}></CustomizedSnackbars>
    </div>
  );
};
export default Reconcile;
