import React from 'react';
import clsx from 'clsx';
import { InputBase, Paper, InputLabel } from '@material-ui/core';
import { useInputStyle } from '../../common/styles/inputStyle';

function InputText(props) {
  const { className, inputRef = undefined, label, icon: FormIcon, outLine, smallInput, ...rest } = props;
  const classes = useInputStyle();
  const { multiline, disabled, required } = rest;

  const rootClasses = clsx({
    [classes.rootDiv]: true,
    [className]: className,
  });
  const paperClasses = clsx({
    [classes.root]: true,
    [classes.multiline]: multiline,
    [classes.rootOutline]: outLine,
    [classes.smallRoot]: smallInput,
    [classes.disabled]: disabled,

  });
  const formClasses = clsx({
    [classes.input]: true,
    [classes.disablerInput]: disabled,
    [classes.smallInput]: smallInput,
  });

  return (
    <div className={rootClasses}>
      {label && <InputLabel className={classes.label}>{label}{required && <span className={classes.required}>*</span>}</InputLabel>}
      <Paper elevation={0} className={paperClasses}>
        {FormIcon ? !multiline && <FormIcon small={smallInput} /> : null}
        <InputBase className={formClasses} {...rest} inputRef={inputRef} />
      </Paper>
    </div>
  );
}
InputText.defaultProps = {
  icon: null,
  smallInput: false,
};
export default InputText;
