import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import { useIntl } from '../../lang/context/intlContext'; //addjust path reference
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import GridContainer from '../components/Grid/GridContainer';
import GridItem from '../components/Grid/GridItem';
import Portlet from '../components/Portlet/Portlet';
import PortletActions from '../components/Portlet/PorletActions';
import { useFormStyle } from '../common/styles/formStyle';
import CustomCard from '../components/CustomCard/Card';
import CustomCheck from '../components/CustomCheck/CustomCheck';
import NodePaper from '../components/CustomPaper/NodePaper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Macro from '../components/Macro/Macro';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import InputTime from '../components/InputTime/InputTime';
import { FormAlarmShortTime, FormNameIcon } from '../../assets/icons/formIcons';
import InputMaskText from '../components/InputMaskText/InputMaskText';
import CreateMacro from '../modules/mfis/journeyControl/Configuration/CreateMacro';

import { motion } from 'framer-motion';
import SelectActions from '../components/Actions/ListActions';
import ListConditions from '../components/Conditions/ListConditions';
import { useIntl } from '../lang/context/intlContext';
import { macroStyles } from '../common/styles/macroStyle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { useTheme } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
const useStyle = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },

  content: {},
}));

const useTabStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 600,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));
function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}
const defaultActions = [
  {
    id: 1,
    name: 'Logout',

    isChecked: true,
    hasStatus: false,
    statusValue: false,
    statusLabelTrue: 'Inactive',
    statusLabelFalse: 'Inactive',
  },
  {
    id: 2,
    name: 'Output 1',
    isChecked: true,
    hasStatus: true,
    statusValue: false,
    statusLabelTrue: 'Ligado',
    statusLabelFalse: 'Desligado',
  },
  {
    id: 3,
    name: 'Output e2',
    isChecked: true,
    hasStatus: true,
    statusValue: false,
    statusLabelTrue: 'Ligado',
    statusLabelFalse: 'Desligado',
  },
];

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const MyPage = (props) => {
  const [stateCheck, setStateCheck] = React.useState([]);
  const [stateSwitch, setStateSwitch] = React.useState([]);
  const [actions, setStateActions] = React.useState(defaultActions);
  // const classes = useTabStyles();
  const [value, setValue] = React.useState(0);
  const handleStatusSwitch = (event, id) => {
    setStateActions((actions) => {
      return actions.map((item) => {
        if (+item.id === +id) {
          item.statusValue = !item.statusValue;
          return item;
        }
        return item;
      });
    });
  };
  const handleCheckAction = (event, id) => {
    console.log(`id`, id);
    setStateActions((actions) => {
      return actions.map((item) => {
        if (+item.id === +id) {
          item.isChecked = !item.isChecked;
          return item;
        }
        return item;
      });
    });
  };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOk = () => {
    // onClose(openDefinedActions);
  };

  const [valueDate, setValueDate] = useState(new Date(0, 0, 0, 10, 0));
  const { i18 } = useIntl();
  const classes = useFormStyle();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <div>
      {/* <Button> Click</Button>
      <SelectActions
        i18={i18}
        actions={actions}
        classes={{
          paper: classes.paperMula,
        }}
        handleStatusSwitch={handleStatusSwitch}
        handleCheckAction={handleCheckAction}
      ></SelectActions> */}

      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open dialog
      </Button>
      <Dialog
        fullScreen={fullScreen}
        disableBackdropClick
        disableEscapeKeyDown
        onClose={handleClose}
        aria-labelledby="confirmation-dialog-title"
        open={open}
        classes={{
          paper: classes.paperMula,
        }}
      >
        <GridContainer className={classes.content}>
          <GridItem item xs={12} md={12} lg={12} className={classes.content}>
            <Portlet className={classes.formStyle} title={i18('INPUTS')} subTitle={''}>
              <ListConditions actions={actions} handleStatusSwitch={handleStatusSwitch} handleCheckAction={handleCheckAction} i18={i18}></ListConditions>
              <SelectActions
                i18={i18}
                actions={actions}
                classes={{
                  paper: classes.paperMula,
                }}
                handleStatusSwitch={handleStatusSwitch}
                handleCheckAction={handleCheckAction}
              ></SelectActions>
              <PortletActions>
                <div className={classes.div}>
                  <div className={classes.inDiv}>
                    <Button variant="contained" disabled={false} color={'cancel'} onClick={() => handleClose()}>
                      {i18('Cancel')}
                    </Button>
                  </div>
                  <div className={classes.inDiv}>
                    {/* <Button type="submit" disabled={isLoading || isReadOnly} variant="contained" color={'primary'}> */}
                    <Button type="submit" disabled={false} variant="contained" color={'primary'}>
                      {i18('save')}
                    </Button>
                    {/* {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />} */}
                  </div>
                </div>
              </PortletActions>
            </Portlet>
          </GridItem>
        </GridContainer>
        {/*
        <DialogContent>
          <Typography gutterBottom>
            Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi
            leo risus, porta ac consectetur ac, vestibulum at eros.
          </Typography>
          <Typography gutterBottom>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum
            faucibus dolor auctor.
          </Typography>
          <Typography gutterBottom>
            Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et.
            Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Save changes
          </Button>
        </DialogActions> */}
      </Dialog>

      {/* <motion.div whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.8 }} />; */}
      {/* <NodePaper></NodePaper> */}

      {/* <InputMaskText
        id="name"
        placeholder={'name'}
        name="name"
        label={'name'}
        icon={FormNameIcon}
        format={'##:##'}
        value={'10:00'}
        // inputRef={register({ required: true })}
        // fullWidth
      ></InputMaskText> */}
    </div>
  );
};
MyPage.propTypes = {
  // title: PropTypes.string,
  // numSelected: PropTypes.number.isRequired,
  // children: PropTypes.object,
};

MyPage.defaultProps = {
  //icon: null,
};
export default MyPage;
