import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { FormRol, CustomListIcon } from '../../../../assets/icons/formIcons';
import {
  Typography,
  IconButton,
  ListItemIcon,
  ListItemSecondaryAction,
  List,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { ICON_PINK_COLOR } from '../../../components/styles/constValues';

export const getRoleList = (items, rest, handleListItemClick, selectedRolId, onRemoveRol) => {
  return items.map((item) => (
    <ListItem
      {...rest}
      key={'R' + item.rolId}
      selected={selectedRolId === item.rolId}
      onClick={(event) => handleListItemClick(event, item.rolId)}
    >
      <ListItemIcon>
        <FormRol color={item.color} />
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography variant="body2" style={{ color: '#181c32' }}>
            {item.name}
          </Typography>
        }
        secondary={
          <Typography variant="body2" style={{ fontSize: '15px', color: '#b5b9d7' }}>
            {item.level + ' - ' + item.clientName}
          </Typography>
        }
      />{' '}
      <ListItemSecondaryAction>
        <IconButton onClick={() => onRemoveRol(1, item.rolId)} edge="end" aria-label="delete" style={{ color: ICON_PINK_COLOR }}>
          <DeleteIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  ));
};

export const getSectionList = (items, { i18, ...rest }, handleChange, selectedRolSections) => {
  const joinSections = items.map((item) => {
    const rolSection = selectedRolSections.find((item2) => item2.sectionId === item.sectionId);
    return { ...item, rightsAllowed: rolSection ? rolSection.rightsAllowed : 0 };
  });

  return joinSections.map((item, index) => (
    <ListItem {...rest} key={'S' + item.sectionId} style={index % 2 ? { backgroundColor: '#f7f9fd' } : {}}>
      <ListItemIcon>
        <CustomListIcon color={item.color} TheIcon={item.icon} />
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography variant="body2" style={{ color: '#181c32' }}>
            {item.name}
          </Typography>
        }
      />
      <ListItemSecondaryAction onClick={() => {}}>
        <FormControlLabel
          control={
            <Checkbox
              disabled={false}
              checked={(item.rightsAllowed & 1) > 0}
              name="chkNone"
              onChange={(e) => handleChange(e, item.sectionId, 1)}
            />
          }
          label={i18('None')}
        />
        <FormControlLabel
          control={
            <Checkbox
              disabled={item.rights < 2 || item.rights === 4}
              checked={(item.rightsAllowed & 2) > 0}
              name="chkView"
              onChange={(e) => handleChange(e, item.sectionId, 2)}
            />
          }
          label={i18('View')}
        />
        <FormControlLabel
          control={
            <Checkbox
              disabled={item.rights < 4}
              checked={(item.rightsAllowed & 4) > 0}
              name="chkWrite"
              onChange={(e) => handleChange(e, item.sectionId, 4)}
            />
          }
          label={i18('Write')}
        />
        <FormControlLabel
          control={
            <Checkbox
              disabled={item.rights < 8}
              checked={item.rightsAllowed === 8}
              name="chkFull"
              onChange={(e) => handleChange(e, item.sectionId, 8)}
            />
          }
          label={i18('Full')}
        />
      </ListItemSecondaryAction>
    </ListItem>
  ));
};
export const getUserList = (items, rest, handleListItemClick, selectedRolId, onRemoveUser) => {
  items.sort((a, b) => compareStrings(a.userName, b.userName));
  return items.map((item) => (
    <ListItem
      {...rest}
      key={'U' + item.userId}
      // selected={selectedRolId === item.userId}
      // onClick={(event) => handleListItemClick(event, item.userId)}
    >
      <ListItemIcon>
        <CustomListIcon color={'green'} TheIcon={'PersonIcon'} />
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography variant="body2" style={{ color: '#181c32' }}>
            {item.userName}
          </Typography>
        }
        secondary={
          <Typography variant="body2" style={{ fontSize: '15px', color: '#b5b9d7' }}>
            {item.clientName}
          </Typography>
        }
      />{' '}
      <ListItemSecondaryAction>
        <IconButton
          onClick={() => onRemoveUser(2, item.userId)}
          edge="end"
          aria-label="delete"
          style={{ color: ICON_PINK_COLOR }}
        >
          <DeleteIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  ));
};

export function compareStrings(a, b) {
  // Assuming you want case-insensitive comparison
  a = a.toLowerCase();
  b = b.toLowerCase();

  return a < b ? -1 : a > b ? 1 : 0;
}
