import React, { useState, useMemo } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import HeaderMap from './components/Header/HeaderMap';
import Navigator from './components/Navigator/Navigator';
import SubNavigator from './components/Navigator/SubNavigator';
import Content from './components/Content/Content';
import { subMenuData } from './components/Navigator/MainMenu/subMenu';
import { routesTitle } from './components/Navigator/MainMenu/routesTitle';
import { sumDrawers, drawerWidth } from './constants';
import { useLayoutUIContext } from './context/layoutContext';
import { useIntl } from '../lang/context/intlContext';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#f2f5f9',
  },
  wraper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    // height: '100vh',
    overflow: 'auto',
    width: `calc(100% - ${drawerWidth}px)`,
  },
  wraperShift: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    // height: '100vh',
    overflow: 'auto',
    width: `calc(100% - ${sumDrawers}px)`,
    // width: `calc(100% - ${drawerWidth}px)`,
  },
  headerStyle: {
    height: theme.spacing(10),
  },
  contentStyle: {
    display: 'flex',
    flexDirection: 'column',
    // marginTop: theme.spacing(13),
    // height: `calc(100%-${theme.spacing(13)})`,
  },
  sideStyle: {
    display: 'flex',
    // height: '100vh',
    width: drawerWidth,
  },
  sideStyleShift: {
    display: 'flex',
    width: `calc(100% - ${sumDrawers}px)`,
    // height: '100vh',
    // width: drawerWidth,
  },
  // paper: {
  //   padding: theme.spacing(2),
  //   display: 'flex',
  //   overflow: 'auto',
  //   flexDirection: 'column',
  // },
  // fixedHeight: {
  //   height: 240,
  // },
}));

const headerTitle = (location) => {
  const titles = routesTitle.find((item) => {
    const cleanLocation =
      '/' +
      location.pathname
        .split('/')
        .filter((item) => isNaN(item))
        .join('/');
    return cleanLocation.indexOf(item.path) >= 0;
  });
  // const titles = routesTitle.find((item) => location.pathname.indexOf(item.path) >= 0);
  if (!titles) return { title: '', subTitle: '' };
  return titles;
};

export default function Layout({ ...props }) {
  const { children, location } = props;
  const { i18 } = useIntl();
  const classes = useStyles();
  const { menuData, setMenuData, subMenu, setSubMenu, setTitles } = useLayoutUIContext(); // useLocalStorage('menu', menu);
  const rawTitles = useMemo(() => {
    return headerTitle(location);
  }, [location]);
  const titles = { title: i18(rawTitles.title), subTitle: i18(rawTitles.subTitle) };
  const [openSidebar, setOpenSidebar] = useState(false);
  const [variant, setVariant] = useState('temporary');
  const i18Menu = menuData.map((item) => ({ ...item, name: i18(item.name) }));
  const handleSidebarOpen = () => {
    const status = variant === 'temporary' ? 'permanent' : 'temporary';
    setVariant(status);
    if (status === 'permanent') setOpenSidebar(true);
    else setOpenSidebar(false);
  };
  const shouldOpenSidebar = openSidebar;
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    if (variant === 'temporary') setOpenSidebar(open);
  };
  const handleMainClick = (optionSelected) => {
    setOpenSidebar(true);
    //aplicamos al menu ppal seleccionado, para marcarlo como seleccionado
    const applySelection = menuData.map((item) => ({
      ...item,
      selected: item.label === optionSelected,
    }));
    setMenuData(applySelection);

    //aqui saco el subMenu a mostrar
    const filterSubMenu = subMenuData.find((item) => item.label === optionSelected).subMenu;
    const i18SubMenu = filterSubMenu.map((item) => {
      const i18SubMenus = item.subMenus.map((item2) => ({ ...item2, name: item2.name ? i18(item2.name) : '' }));
      return { ...item, name: i18(item.name), subMenus: i18SubMenus };
    });
    setSubMenu(i18SubMenu);
  };
  return (
    <div className={classes.root}>
      {/* <CssBaseline /> */}
      <div className={classes.sideStyle}>
        <Navigator handleMainClick={handleMainClick} menu={i18Menu} onSidebarOpen={handleSidebarOpen} />
        <SubNavigator subMenu={subMenu} location={location} open={openSidebar} toggleDrawer={toggleDrawer} variant={variant} />
      </div>
      <div className={clsx(classes.wraper, openSidebar && classes.wraperShift)}>
        <div className={classes.headerStyle}>
          <HeaderMap open={openSidebar} {...titles} {...props}></HeaderMap>
        </div>
        <div className={classes.contentStyle}>
          <Content open={openSidebar} subTitle={titles.subTitle} withSubHeader={true}>
            {children}
          </Content>
        </div>
      </div>
    </div>
  );
}
