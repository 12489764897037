export const getNewMacrosNext = (macros) => {
  return macros.reduce((acc, macro) => {
    const { next } = macro;
    const newNext = next.filter((item) => item.mustBeSentToDB);
    return [...acc, ...newNext];
  }, []);
};

export const reduceMacros = (state, payload) => {
  return payload.map((item) => {
    const { next, prev, name, description, type, isMandatory, idm, tag = '' } = item;
    const newNext = next.map((item2) => ({
      ...item2,
      title: item2.title,
      description: item2.description,
      parentId: item2.macroTemplateId,
      nextId: item2.macroTemplateIdNextId,
    }));
    const newPrev = prev.map((item2) => ({
      ...item2,
      title: item2.title,
      description: item2.description,
      parentId: item2.macroTemplateId,
      prevId: item2.macroTemplateIdPrev,
    }));
    return {
      // ...item,
      isPrimitiveType: false,
      id: item.macroTemplateId,
      macroId: 0,
      isMandatory,
      tag,
      name,
      description,
      type,
      idm,
      next: newNext,
      prev: newPrev,
    };
  });
};
