import axios from 'axios';

const ADD_ROTOGRAMA = '/rotograma/add';
const ADD_CONFIGURATIONS_ROTOGRAMA = '/rotograma/addConfigurations';
const UPDATE_CONFIGURATIONS_ROTOGRAMA = '/rotograma/updateConfigurations';

export function postRotograma(name, description) {
  return axios.post(ADD_ROTOGRAMA, { name, description });
}

export const postConfigurations = (configurations) => {
  const { configurationId } = configurations;
  if (configurationId === 0) return axios.post(ADD_CONFIGURATIONS_ROTOGRAMA, { ...configurations });
  return axios.post(UPDATE_CONFIGURATIONS_ROTOGRAMA, { ...configurations });
};

// export function login(email, password) {
//   return axios.post(LOGIN_URL, { username: email, email, password });
// }

// export function register(email, fullname, username, password) {
//   return axios.post(REGISTER_URL, { email, fullname, username, password });
// }

// export function requestPassword(email) {
//   return axios.post(REQUEST_PASSWORD_URL, { email });
// }

// export function getUserByToken() {
//   // Authorization head should be fulfilled in interceptor.
//   return axios.get(ME_URL);
// }
