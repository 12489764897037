import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import { Divider, Typography } from '@material-ui/core';
import { motion, AnimateSharedLayout } from 'framer-motion';
import NodeMacro from '../NodeMacro/NodeMacro';
import NextNodeMacro from '../NodeMacro/NextNodeMacro';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
  rightMenu: {
    maxWidth: theme.spacing(37),
    width: theme.spacing(37),
  },
  rootFlow: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'flex-start',

    paddingLeft: theme.spacing(4),
    // marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: '100vh',
  },
  gridStyle: { width: 'auto' },
  paperStyle: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(10),
      height: theme.spacing(10),
    },
  },
  verticalStyle: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(12),
      height: theme.spacing(10),
    },
  },
  nodeStyle: {
    margin: theme.spacing(0),
    '&:hover': {
      width: theme.spacing(36),
    },
  },
  verticalLine: {
    margin: theme.spacing(0),
    width: '1px' /* Line width */,
    backgroundColor: 'black' /* Line color */,
    height: theme.spacing(2) /* Override in-line if you want specific height. */,
    // alignSelf: 'center',
    // justifySelf: 'center',
    marginLeft: theme.spacing(4),
    float: 'left' /* Causes the line to float to left of content. 
        You can instead use position:absolute or display:inline-block
        if this fits better with your design */,
  },
}));

const macros = [
  { id: 0, name: 'Inicio de Jornada' },
  { id: 1, name: 'Inicio de Viaje' },
  { id: 2, name: 'Reinicio de Viaje' },
  { id: 3, name: 'Parada Descanso' },
  { id: 4, name: 'Parada Refrigerio' },
  { id: 5, name: 'Parada Espera' },
  { id: 6, name: 'Fin de Viaje' },
  { id: 7, name: 'Fin de Jornada' },
];
const nodeMacro = (items, selected, onClick, isNext = false) => {
  const length = items.length - 1;
  return items.map((item, index) => (
    <NodeMacro
      position={index}
      key={'dddd' + index}
      selected={index === selected}
      onClick={onClick}
      item={item}
      showLine={index < length}
      isNext={isNext}
    />
  ));
};
const nodeNextMacro = (items, selected, onClick, isNext = false) => {
  const length = items.length - 1;
  return items.map((item, index) => (
    <NextNodeMacro
      position={index}
      key={'dddd' + index}
      selected={index === selected}
      onClick={onClick}
      item={item}
      showLine={index < length}
      isNext={isNext}
    />
  ));
};
// const nextMacros = (items) => {
//   const length = items.length - 1;
//   return items.map((item, index) => <NodeMacro position={index} key={'dddd' + index} item={item} showLine={index < length} />);
// };

export default function NodePaper() {
  const classes = useStyles();
  const [nextMacros, setNextMacros] = React.useState([]);
  const [marginTop, setMarginTop] = React.useState(0);
  const [nodeSelected, setNodeSelected] = React.useState(-1);
  const clickOnMacro = (e) => {
    console.info(e);
    setNodeSelected(e);
    const tempMacros = macros.slice(0, 3);
    const splitNext = Math.floor(tempMacros.length / 2);
    const offSetNodes = splitNext <= e ? splitNext * 12 * 8 : 0; // Math.abs( macros.length - nextMacros.length)
    setMarginTop(13 * e * 8 - offSetNodes);
    setNextMacros(tempMacros);
  };

  return (
    <div className={classes.root}>
      <div className={classes.rightMenu}>
        {/* <Paper elevation={0} /> */}
        {/* <Paper /> */}
        <GridContainer direction="column" spacing={0} className={classes.gridStyle}>
          {nodeMacro(macros, nodeSelected, clickOnMacro)}
        </GridContainer>
      </div>
      <div style={{ marginTop: marginTop + 'px' }}>
        <GridContainer direction="column" spacing={0} className={classes.gridStyle}>
          {nodeNextMacro(nextMacros, false, undefined, true)}
        </GridContainer>
      </div>
    </div>
  );
}
