import React from 'react';
import ContentLoader from 'react-content-loader';

const GetLines = (lineNumber) => {
  const x = '25';
  const y = 15;
  const rx = '5';
  const ry = '5';
  const width = '100%';
  const height = '10';
  const lines = [];
  for (let i = 0; i < lineNumber; i++) lines.push(i);

  const toReturn = lines.map((item, index) => {
    const stringY = (y + index * 30).toString();
    return <rect key={'l' + index} x={x} y={stringY} rx={rx} ry={ry} width={width} height={height} />;
  });

  return toReturn;
};

const ContentLineLoader = ({ lineNumber = 8, height }) => (
  <ContentLoader speed={2} style={{ width: '97%', padding: '0 0 0 0', margin: '0 0 0 0' }} height={height} backgroundColor="#f3f3f3" foregroundColor="#7f7a7a">
    {GetLines(lineNumber)}
  </ContentLoader>
);

export default ContentLineLoader;
