import React, { useState, useEffect, useCallback } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Button, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';

import { useIntl } from '../../../lang/context/intlContext';

import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import { TableProvider } from '../../../components/Table/TableContext';
import TablePaper from '../../../components/Table/TablePaper';
import PortletIcon from '../../../components/Portlet/PortletIcon';
import { Loading } from '../../../../Splash';
import { getAllDrivers } from '../api/getMethods';
import { postIsDeleteDriver } from '../api/postMethods';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { ActionControlPanel, ActionEdit, ActionRemove, ActionSettings } from '../../../../assets/icons';

const useStyleGrid = makeStyles((theme) => ({
  proto: { maxHeight: 'calc(100vh-600px)' },
  content: {
    display: 'flex',
    justifyContent: 'center',
    height: theme.spacing(45),
  },
  alert: { margin: theme.spacing(2) },
}));

const Drivers = ({ history, match }) => {
  const { i18 } = useIntl();
  const classes = useStyleGrid();
  const [isLoading, setisLoading] = useState(true);
  const { driverId = 0 } = match.params;
  //#region store
  const addDrivers = useStoreActions((actions) => actions.driver.addDrivers);
  const deleteDriver = useStoreActions((actions) => actions.driver.deleteDriver);
  const drivers = useStoreState((state) => state.driver.drivers);
  //#endregion
  const handleClose = async (yesOption) => {
    try {
      if (yesOption) {
        await postIsDeleteDriver(driverId, 1);
        deleteDriver(driverId);
      }
      history.push('/administration/drivers/management');
    } catch (error) {}
  };
  const onTableReady = () => {};

  const fetchData = async () => {
    const res = await getAllDrivers();
    addDrivers(res.data.drivers);
    setisLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  //#region Table and Definitions
  const headCellsTablets = [
    { id: 'driverId', numeric: true, align: 'left', disablePadding: false, label: i18('DriverId'), width: '50px' },

    {
      id: 'name',
      numeric: false,
      align: 'left',
      disablePadding: true,
      label: i18('name'),
      width: '150px',
      defaultOrder: true,
    },

    { id: 'lastname', numeric: true, align: 'left', disablePadding: false, label: i18('lastname'), width: '150px' },
    { id: 'clientName', numeric: true, align: 'left', disablePadding: false, label: i18('client'), width: '150px' },
    { id: 'CPF', numeric: true, align: 'left', disablePadding: false, label: i18('CPF'), width: '120px' },
    { id: 'RG', numeric: false, align: 'left', disablePadding: false, label: i18('rg'), width: '120px' },
    { id: 'CNH', numeric: false, align: 'left', disablePadding: false, label: i18('cnh'), width: '120px' },
    { id: 'funcNumber', numeric: false, align: 'left', disablePadding: false, label: i18('fncNumber'), width: '120px' },
    { id: 'phoneNumber', numeric: false, align: 'left', disablePadding: false, label: i18('phoneNumber'), width: '120px' },
    { id: 'actions', numeric: true, align: 'left', disablePadding: false, label: i18('actions'), width: '100px' },
  ];

  const filterInputs = [{ label: 'name', field: 'name' }];

  const ActionsRender = useCallback((keyCellId) => {
    return (
      <>
        {/* <ActionSettings /> */}
        <ActionEdit onClick={() => history.push(`/administration/drivers/${keyCellId.split('|')[1]}/edit`)} />
        <ActionRemove onClick={() => history.push(`/administration/drivers/management/${keyCellId.split('|')[1]}/delete`)} />
      </>
    );
  }, []);

  if (isLoading)
    return (
      <GridContainer className={classes.content}>
        <GridItem item xs={12} md={12} lg={12}>
          <Loading></Loading>
        </GridItem>{' '}
      </GridContainer>
    );

  //#endregion
  return (
    <div>
      <GridContainer className={classes.content}>
        <GridItem item xs={12} md={12} lg={12}>
          <PortletIcon
            hasTitle={true}
            title={i18('DRIVERS')}
            subTitle={i18('LIST_OF_DRIVER')}
            withIcon={false}
            hasCustomStartIcon={false}
            StartIcon={PermIdentityIcon}
            withButton
            buttonTitle={i18('NEW_DRIVER')}
            onClickButton={() => history.push('/administration/drivers/new')}
          >
            <CardContent style={{ paddingTop: '0px' }}>
              <GridContainer direction="column" spacing={2}>
                <GridItem item xs={12} md={12}>
                  <TableProvider
                    loadData={drivers}
                    columns={headCellsTablets}
                    checkedItems={[]}
                    initRowsPerPage={50}
                    withPagination={true}
                    onContextReady={onTableReady}
                    useV2={true}
                    actions={ActionsRender}
                  >
                    <TablePaper maxHeight={'450px'} filterInputs={filterInputs} hasCheckBox={false} hasGroups={false} hasSearchAllFields={true}></TablePaper>
                  </TableProvider>
                </GridItem>
              </GridContainer>
            </CardContent>
            <></>
          </PortletIcon>
        </GridItem>
      </GridContainer>
      {driverId > 0 && (
        <Dialog open={true} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" disableBackdropClick disableEscapeKeyDown>
          <DialogTitle id="alert-dialog-title">{i18('DELETE_DRIVER')}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{i18('CONF_DELET_DRIVER')}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleClose(false)} color="primary">
              {i18('NOCancel')}
            </Button>
            <Button onClick={() => handleClose(true)} color="primary" autoFocus>
              {i18('YesDELETE')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};
export default Drivers;
