//@ts-nocheck
import { action, thunk } from 'easy-peasy';
import * as api from '../services/index';

const AXIOS_INIT = 'INIT';
const AXIOS_INPROCESS = 'INPROCESS';

const initValues = {
  spokenRouteId: 0,
  lastCompiled: 0,
  toPublish: 0,
  name: '',
  description: '',
  currentNew: {
    configurations: {
      preAlert: { isEnabled: false, audio: '', meters: 0 },
      alert: { isEnabled: false, speed: 0, alarmAudio: '', playOnExit: false },
      violation: { isEnabled: false, violationAudio: '', sendAlarm: false },
    },
    tempRegionIds: [],
    regionIds: [],
    inEdition: -1,
    configurationId: 0,
  },
  status: 0,
  configurations: [],
  error: '',
  isLoading: false,
  axiosState: AXIOS_INIT,
  audios: { preAlertAudios: [], alertAudios: [], violationsAudios: [] },
  speedsToShow: comboSpeeds(),
};

const rotograma = {
  /*Store definition*/
  ...initValues,
  /*Actions definitions*/
  initRotograma: action((rotograma) => {
    rotograma = { ...initValues };

    rotograma.spokenRouteId = 0;
    rotograma.name = '';
    rotograma.description = '';
    rotograma.currentNew = { ...initValues.currentNew };
    rotograma.error = '';
    rotograma.isLoading = false;
    rotograma.axiosState = AXIOS_INIT;
    return rotograma;
  }),
  compile: action((rotograma, spokenRouteId) => {
    rotograma.toPublish = spokenRouteId;
    rotograma.spokenRouteId = 0;
    rotograma.currentNew = { ...initValues.currentNew };
    rotograma.error = '';
    rotograma.isLoading = false;
    rotograma.axiosState = AXIOS_INIT;
    return rotograma;
  }),
  publish: action((rotograma, spokenRoute) => {
    rotograma.toPublish = spokenRoute.spokenRouteId;
    rotograma.name = spokenRoute.name;
    rotograma.description = spokenRoute.description;
  }),
  //#region currentConfiguration
  createdNew: action((rotograma, payload) => {
    const { spokenRouteId, name, description, audios } = payload;
    const newRotograma = { ...initValues, spokenRouteId, name, description, audios };
    rotograma = { ...newRotograma };
    return rotograma;
  }),
  continueEdition: action((rotograma, payload) => {
    const { spokenRouteId, name, description, audios, configurations, status } = payload;
    const newRotograma = { ...initValues, spokenRouteId, name, description, audios, status };
    rotograma = { ...newRotograma };
    rotograma.configurations = configurations;
    return rotograma;
  }),
  createdNewConfigurations: action((rotograma, newConfigurations) => {
    // rotograma.axiosState = AXIOS_DONE;
    // rotograma.isLoading = false;
    rotograma.currentNew = { ...initValues.currentNew };
    rotograma.configurations = rotograma.configurations.filter(
      (item) => item.configurationId !== newConfigurations.configurationId
    );
    rotograma.configurations.push(newConfigurations);
    return rotograma;
    // return rotograma;
    // rotograma.spokenRouteId = spokenRouteId;
    // // console.log('creatednew');
    // // console.info(rotograma);
    // // rotograma.spokenRouteId = spokenRouteId;
    // rotograma.name = name;
    // rotograma.description = description;
    // rotograma.audios = { ...audios };
  }),
  deleteConfiguration: action((rotograma, configurationId) => {
    rotograma.configurations = rotograma.configurations.filter((item) => item.configurationId !== configurationId);
    return rotograma;
  }),
  editConfiuration: action((rotograma, configurationId) => {
    const configuration = rotograma.configurations.find((item) => item.configurationId === configurationId);
    rotograma.currentNew = { ...rotograma.currentNew };
    rotograma.currentNew.configurationId = configuration.configurationId;
    rotograma.currentNew.tempRegionIds = configuration.tempRegionIds;
    rotograma.currentNew.regionIds = configuration.regionIds;
    rotograma.currentNew.configurations.preAlert = configuration.preAlert;
    rotograma.currentNew.configurations.alert = configuration.alert;
    rotograma.currentNew.configurations.violation = configuration.violation;

    return rotograma;
  }),
  // updateSpokenRoute: action((rotograma, isDisabled) => {
  //   rotograma.configurations = rotograma.configurations.filter((item) => item.configurationId !== configurationId);
  //   return rotograma;
  // }),
  setCurrentEditing: action((rotograma, id) => {
    rotograma.currentNew.inEdition = id;
  }),
  setPreAlert: action((rotograma, preAlert) => {
    console.info(preAlert);
    rotograma.currentNew.inEdition = -1;
    return (rotograma.currentNew.configurations.preAlert = preAlert);
  }),
  setAlert: action((rotograma, alert) => {
    rotograma.currentNew.inEdition = -1;
    rotograma.currentNew.configurations.alert = alert;
  }),
  setSpeed: action((rotograma, speed) => {
    try {
      rotograma.currentNew.configurations.alert.speed = speed;
    } catch (error) {
      console.error(error);
    }
  }),
  setViolation: action((rotograma, violation) => {
    rotograma.currentNew.inEdition = -1;
    rotograma.currentNew.configurations.violation = violation;
  }),
  setCurrentRegions: action((rotograma, { checkedRegionIds, regionIds }) => {
    rotograma.axiosState = AXIOS_INIT;
    rotograma.currentNew.tempRegionIds = checkedRegionIds;
    rotograma.currentNew.regionIds = regionIds;
    return rotograma;
  }),
  removeConfiguration: action((rotograma, id) => {
    switch (id) {
      case 0:
        rotograma.currentNew.inEdition = -1;
        rotograma.currentNew.configurations.preAlert = { isEnabled: false, audio: '', meters: 0 };
        break;
      case 1:
        rotograma.currentNew.inEdition = -1;
        rotograma.currentNew.configurations.alert = { isEnabled: false, speed: 0, alarmAudio: '', playOnExit: false };
        break;
      case 2:
        rotograma.currentNew.inEdition = -1;
        rotograma.currentNew.configurations.violation = { isEnabled: false, violationAudio: '', sendAlarm: false };
        break;
      default:
        break;
    }
    return rotograma;
  }),
  setCancel: action((rotograma) => {
    rotograma.currentNew.inEdition = -1;
  }),
  //#endregion
  setError: action((rotograma, error) => {
    rotograma.error = error.message;
    console.error(error);
    return rotograma;
  }),
  setIsLoading: action((rotograma, axiosState = AXIOS_INPROCESS) => {
    rotograma.isLoading = axiosState = AXIOS_INPROCESS;
    rotograma.axiosState = axiosState;
    return rotograma;
  }),
  /*API Services - thunks*/
  putRotograma: thunk(async (actions, newRotograma) => {
    try {
      const { name, description } = newRotograma;
      const { data: rotograma } = await api.postRotograma(name, description);
      actions.createdNew(rotograma);
      return Promise.resolve('ok');
    } catch (error) {
      actions.setError(error);
    }
  }),
  postAddConfigurations: thunk(async (actions, isUpdate, { getState }) => {
    try {
      const rotograma = getState();
      const { spokenRouteId, currentNew } = rotograma;
      actions.setIsLoading();
      const { data } = await api.postConfigurations({ spokenRouteId, ...currentNew });
      actions.createdNewConfigurations(data.body.configurations);
    } catch (error) {
      actions.setError(error);
      console.error(error);
    }
  }),
};

function comboSpeeds() {
  const speeds = [];
  const maxSpeed = 140;

  for (let index = 5; index <= maxSpeed; index = index + 5) {
    speeds.push(index);
  }
  return speeds.map((item) => ({ value: item, text: item.toString() }));
}

export default rotograma;
