//#region imports
import React, { useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
//#region MUI
import { CardContent, CircularProgress, IconButton, ListItemIcon, ListItemSecondaryAction, Typography } from '@material-ui/core';
//#endregion

//utils
import { useIntl } from '../../../lang/context/intlContext'; //addjust path reference
import { useForm } from 'react-hook-form';
//#region components

import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import Portlet from '../../../components/Portlet/Portlet';
import InputText from '../../../components/InputText/InputText';
import PortletActions from '../../../components/Portlet/PorletActions';
import Button from '../../../components/CustomButton/Button';
import { FormNameIcon, FormDescriptionIcon } from '../../../../assets/icons/formIcons';
//#endregion

//ApiMedhots

//state
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useFormStyle } from '../../../common/styles/formStyle';
import ComboBox from '../../../components/ComboBox/ComboBox';
import { Alert } from '@material-ui/lab';
import CustomizedSnackbars from '../../../components/CustomSnackBar/CustomSnackBar';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import PaymentIcon from '@material-ui/icons/Payment';
import DeleteIcon from '@material-ui/icons/Delete';

//api
import { postInsertNFCA } from '../api/postMethods';
import { getAllClients } from '../api/getMethods';
import { ListWrap } from '../../../layout/components/ListWrap/ListWrap';
//#endregion
//these styles are used in Add Rotograma Falado and Here

//todo:validate NFC is not already in db

const New = ({ history, match }) => {
  const [isLoading, setisLoading] = useState(false);
  const [validErrors, setValidErros] = useState({});
  const [openSnack, setOpenSnack] = React.useState({ open: false, title: '', severity: 'success' });
  const classes = useFormStyle();
  const { i18 } = useIntl();
  const [nfcList, setNfcList] = useState([]);
  const [nfcKey, setNfcKey] = useState('');
  const [selectedClientId, setSelectedClientId] = React.useState(0);
  const [filterClients, setFilterClients] = React.useState([]);
  const { driverId = 0 } = match.params;
  const modePage = driverId > 0 ? 'EDIT' : 'NEW';

  const { handleSubmit, errors } = useForm();
  //#region easypeasy
  const setNoButtons = useStoreActions((actions) => actions.globalState.setNoButtons);
  const setPrimaryButton = useStoreActions((actions) => actions.globalState.setPrimaryButton);

  const { clientId, clients, tsp } = useStoreState((state) => state.user);
  //#endregion

  useEffect(() => {
    setPrimaryButton({
      text: i18('BACK'),
      isEnabled: true,
      fnc: () => {
        history.push(`/administration/nfc/management`);
      },
    });
    // setSelectedClientId(clientId);
    setFilterClients(clients.filter((item) => +item.ParentId === +clientId || +item.value === +clientId));
    return () => setNoButtons();
    // initRotograma();
  }, []);

  const handleComboTSP = (e) => {
    if (!e.target) return;
    setSelectedClientId(0);
    setFilterClients(clients.filter((item) => +item.ParentId === +e.target.value));
  };
  const handleComboChange = (e) => {
    if (!e.target) return;
    setSelectedClientId(e.target.value);
  };
  const handleOnKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (e.target.value.length === 0 || +selectedClientId === 0) return;
      const NFC = e.target.value;
      if (isNFCOnList(NFC)) {
        setOpenSnack((prev) => ({ ...prev, open: true, title: i18('NFC_IN_LIST'), severity: 'warning' }));
        return;
      }
      const clientName = clients.find((item) => +item.value === +selectedClientId).text;
      const rawSerial = uuid();
      const serial = rawSerial.substr(rawSerial.lastIndexOf('-') + 1);
      const newNFC = { NFC, serial, clientName, clientId: selectedClientId };
      setNfcList((prev) => [newNFC, ...prev]);
      setNfcKey('');
      // write your functionality here
    }
  };
  const isNFCOnList = (nfc) => nfcList.find((item) => item.NFC === nfc);
  //181.548.108-08
  const setDuplicatedNFC = (nfcs) => {
    setNfcList((list) => {
      return list.map((item) => {
        const block = nfcs.find((item2) => item2.nfc === item.NFC);
        return { ...item, block };
      });
    });
  };
  const onSubmit = async (datos) => {
    try {
      setisLoading(true);
      // const validation = driverValidation(datos);
      // setValidErros(validation);
      console.log('datos', nfcList);
      const res = await postInsertNFCA(nfcList);

      const { data } = res;
      console.log('data', data);
      if (data.error !== 0) {
        switch (+data.error) {
          case 1:
            setOpenSnack((prev) => ({ ...prev, open: true, title: i18('NFC_ALRE_REGISTERED'), severity: 'warning' }));
            setDuplicatedNFC(data.nfcs);
            break;

          default:
            setOpenSnack((prev) => ({ ...prev, open: true, title: i18('ERROR_API'), severity: 'error' }));
            break;
        }
      } else {
        history.push(`/administration/nfc/management?r=true`);
      }
      setisLoading(false);
    } catch (error) {
      setisLoading(false);
      setOpenSnack((prev) => ({ ...prev, open: true, title: i18('ERROR_API'), severity: 'error' }));
    }
  };

  const removeNFC = (id) => {
    setNfcList((prev) => prev.filter((item) => item.NFC !== id));
  };
  const getNFCSimple = (items) => {
    return items.map((item, index) => (
      <ListItem key={index}>
        <ListItemIcon key={'I' + index} style={item.block && { color: 'red' }}>
          <PaymentIcon />
        </ListItemIcon>
        <ListItemText primary={item.NFC + '-' + item.serial} secondary={item.clientName} />
        <ListItemSecondaryAction onClick={() => removeNFC(item.NFC)}>
          <IconButton edge="end" aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    ));
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <GridContainer className={classes.content}>
          <GridItem item xs={12} md={9} lg={9} className={classes.content}>
            <Portlet
              className={classes.formStyle}
              title={modePage === 'NEW' ? i18('NEW_NFC') : i18('EDIT_NFC')}
              subTitle={i18('CREATE_SUBHEADER_JC')}
            >
              <CardContent>
                <GridContainer spacing={2}>
                  <GridItem container spacing={2}>
                    <GridItem item xs={12} md={6} lg={6}>
                      <GridItem item xs={12} md={12} lg={12}>
                        <ComboBox
                          label={i18('TSP')}
                          icon={FormNameIcon}
                          name="tspId"
                          defaultValue={clientId}
                          onChange={handleComboTSP}
                          options={tsp}
                        ></ComboBox>
                      </GridItem>
                      <GridItem item md={12} lg={12}>
                        <ComboBox
                          label={i18('Client')}
                          icon={FormNameIcon}
                          name="client"
                          defaultValue={clientId}
                          onChange={handleComboChange}
                          options={filterClients}
                          placeholder={i18('Groups')}
                        ></ComboBox>
                      </GridItem>
                      <GridItem item md={12} lg={12}>
                        <InputText
                          id="nfc"
                          name="nfc"
                          defaultValue={nfcKey}
                          value={nfcKey}
                          label={i18('nfc')}
                          onChange={(e) => setNfcKey(e.target.value)}
                          onKeyPress={handleOnKeyPress}
                          icon={FormNameIcon}
                          fullWidth
                        ></InputText>
                        {(errors.nfc || validErrors.nfc) && <Typography color="secondary">{i18('Invalid_CPF')}</Typography>}
                      </GridItem>
                    </GridItem>
                    <GridItem item xs={12} md={6} lg={6}>
                      <ListWrap title={i18('NFC_Cards_to_register')} classes={classes}>
                        {getNFCSimple(nfcList)}
                      </ListWrap>
                    </GridItem>
                  </GridItem>
                </GridContainer>
                <GridItem container></GridItem>
                {/* <div style={{ paddingTop: '32px' }}>
                  <Divider variant="fullWidth" />
                </div> */}
              </CardContent>

              <PortletActions>
                <div className={classes.div}>
                  <div className={classes.inDiv}>
                    <Button variant="contained" disabled={isLoading} color={'cancel'} onClick={() => setNfcList([])}>
                      {i18('Cancel')}
                    </Button>
                  </div>
                  <div className={classes.inDiv}>
                    <Button type="submit" disabled={isLoading || nfcList.length === 0} variant="contained" color={'primary'}>
                      {i18('REGISTER')}
                    </Button>
                    {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </div>
                </div>
              </PortletActions>
            </Portlet>
          </GridItem>
        </GridContainer>
      </form>
      <CustomizedSnackbars
        open={openSnack.open}
        setOpen={setOpenSnack}
        title={openSnack.title}
        severity={openSnack.severity}
      ></CustomizedSnackbars>
    </div>
  );
};
New.propTypes = {
  // title: PropTypes.string,
  // numSelected: PropTypes.number.isRequired,
  // children: PropTypes.object,
};

New.defaultProps = {
  //icon: null,
};
export default New;
