import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import Fade from '@material-ui/core/Fade';
import { v4 as uuidv4 } from 'uuid';

const useStyles = makeStyles((theme) => ({
  whiteBack: {
    boxShadow: '2px 0 18px 0 rgba(248, 236, 215, 0.71)',
    backgroundColor: '#ffffff',
    width: '3px',
    height: theme.spacing(7),
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
  nowhiteBack: {
    //  boxShadow: '2px 0 18px 0 rgba(248, 236, 215, 0.71)',
    // backgroundColor: '#1b1344',
    width: '3px',
    height: theme.spacing(7),
  },
  menuButton: {
    display: 'flex',
  },
  menuItems: {
    marginTop: theme.spacing(2),
    // paddingTop: theme.spacing(2),
  },
  menuItem: {
    marginTop: theme.spacing(2),
    marginLeft: '14px',
    borderRadius: '4px',
    width: '24px',
    height: theme.spacing(3),
    textAlign: 'center',
    justifyContent: 'center',
    fontSize: '18px',
    color: theme.palette.primary.contrastText,
  },
  menuItem0: {
    background: 'linear-gradient(to top, #ffb12a, #ffd263)',
  },
  menuItem1: {
    background: 'linear-gradient(to top, #f46572, #fca391)',
  },
  menuItem2: {
    background: 'linear-gradient(to top, #c5b1f9, #5978e4)',
  },
}));

const getBackground = (key, classes) => {
  switch (key) {
    case 0:
      return clsx(classes.menuItem, classes.menuItem0);
    case 1:
      return clsx(classes.menuItem, classes.menuItem1);
    case 2:
      return clsx(classes.menuItem, classes.menuItem2);

    default:
      return clsx(classes.menuItem, classes.menuItem0);
  }
};

const itemMenu = (props) => {
  const { classes, name, label, icon, selected, handleClick } = props;
  return (
    <Link key={uuidv4()} color="inherit" href="#">
      <Tooltip TransitionComponent={Fade} placement="right" arrow TransitionProps={{ timeout: 300 }} title={name}>
        <div className={classes.menuButton}>
          <div className={clsx(classes.nowhiteBack, selected && classes.whiteBack)}></div>
          <div onClick={handleClick} className={getBackground(icon, classes)}>
            {label}
          </div>
        </div>
      </Tooltip>
    </Link>
  );
};

const MainMenu = (props) => {
  const { menu, handleMainClick } = props;
  const handleClick = (event) => {
    event.preventDefault();
    const optionSelected = event.target.textContent;
    handleMainClick(optionSelected);
  };
  const classes = useStyles();
  const listItems = (menu) => menu.map((item) => itemMenu({ ...item, classes, handleClick }));
  return <div className={classes.menuItems}>{listItems(menu)}</div>;
};

export default MainMenu;
