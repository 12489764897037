//@ts-nocheck
import { action } from 'easy-peasy';
//primary: {text,}
const initValues = {
  allSections: [],
  clientId: '0',
  clientName: '',
  clients: [],
  deviceModels: [],
  deviceMakes: [],
  drivers: [],
  email: '',
  evidences: { status: [], types: [] },
  groups: [],
  isAuthorized: false,
  isTSP: false,
  languageId: 0,
  others: [],
  primitiveMacros: [],
  roles: {},
  security: [],
  tabletGroups: [],
  timeZone: '',
  token: '',
  tsp: [],
  userName: '',
  users: [],
  usersAvailable: [], // this is used in report filters
};
const user = {
  ...initValues,
  onLogin: action((state, payload) => {
    const { token, userName, email, timeZone, clientName, clientId, level, maxRolLevel, allSections, languageId } = payload;
    state.token = token;
    state.userName = userName;
    state.email = email;
    state.languageId = languageId;
    state.timeZone = timeZone;
    state.clientName = clientName;
    state.isAuthorized = true;
    state.clientId = clientId.toString();
    state.level = level;
    state.maxRolLevel = +maxRolLevel;
    state.isTSP = maxRolLevel <= 2;
    state.allSections = allSections;
  }),
  onFailLogin: action((state) => {
    state.token = undefined;
  }),
  addUsersClients: action((state, { tsp, clients, security, others }) => {
    state.tsp = tsp.map((item) => {
      const { Name, ClientId, ...rest } = item;
      return { text: Name, value: ClientId, ...rest };
    });
    state.clients = clients.map((item) => {
      const { Name, ClientId, ...rest } = item;
      return { text: Name, value: ClientId, ...rest };
    });

    state.security = security.map((item) => {
      const { Name, ClientId, ...rest } = item;
      return { text: Name, value: ClientId, ...rest };
    });
    state.others = others.map((item) => {
      const { Name, ClientId, ...rest } = item;
      return { text: Name, value: ClientId, ...rest };
    });
  }),
  addUsers: action((state, newUsers) => {
    state.users = newUsers;
  }),
  addGroups: action((state, groups) => {
    state.groups = groups;
    state.tabletGroups = groups.filter((item) => item.hasTablets);
  }),
  addDeviceModels: action((state, deviceModels) => {
    state.deviceModels = deviceModels;
  }),
  addDeviceMakes: action((state, deviceMakes) => {
    state.deviceMakes = deviceMakes;
  }),
  onAddDeviceToAsset: action((state, deviceId) => {
    state.devices = state.devices.map((item) => {
      if (+item.value === +deviceId) {
        return { ...item, inAsset: true };
      }
      return item;
    });
  }),
  onDetachedDeviceFromAsset: action((state, deviceId) => {
    state.devices = state.devices.map((item) => {
      if (+item.value === +deviceId) {
        return { ...item, inAsset: false };
      }
      return item;
    });
  }),
  addContext: action((state, userContext) => {
    const { primitiveMacros, drivers, functions, spokenRoute, garages, nfc, objectStates, spokenRoutes, spokenRoutesStatus, journeyControl, journeyControlStatus, roles, devices, users, evidences } = userContext;
    state.devices = devices;
    state.drivers = drivers;
    state.evidences = evidences;
    state.functions = functions;
    state.garages = garages;
    state.journeyControl = journeyControl;
    state.journeyControlStatus = journeyControlStatus;
    state.nfc = nfc;
    state.objectStates = objectStates;
    state.primitiveMacros = primitiveMacros;
    state.roles = roles;
    state.spokenRoutes = spokenRoutes;
    state.spokenRouteEvents = spokenRoute;
    state.spokenRoutesStatus = spokenRoutesStatus;
    state.usersAvailable = users;
  }),
};

export default user;
