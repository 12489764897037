import React, { useState } from 'react';
import { Dialog } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/styles';

import { useModalWindowStyle } from '../styles/modalWindowStyle';
import Portlet from '../Portlet/Portlet';
import PortletActions from '../Portlet/PorletActions';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import Button from '../../components/CustomButton/Button';
// modal window

function ModalPortlet({ i18, title, subTitle, handleOk, fullWidth, maxWidth, disabledSaveButton = false, handleCancel = undefined, handleSave = undefined, open, typeButtons = 'onlyOK', children, fullScreen = undefined, onlyOkName = undefined, onlyCancelName = undefined, onlySaveName = undefined, ...other }) {
  const theme = useTheme();
  const localFullScreen = fullScreen || useMediaQuery(theme.breakpoints.down('lg'));
  const classes = useModalWindowStyle();

  return (
    <Dialog fullWidth={fullWidth} maxWidth={maxWidth} fullScreen={localFullScreen} disableBackdropClick disableEscapeKeyDown aria-labelledby="confirmation-dialog-title" open={open} {...other}>
      <GridContainer className={classes.content}>
        <GridItem item xs={12} md={12} lg={12} className={classes.content}>
          <Portlet title={title} subTitle={subTitle} fullWidth={fullWidth}>
            <div>{children}</div>
            <PortletActions>
              {typeButtons === 'onlyOK' && (
                <div className={classes.div}>
                  <div className={classes.inDiv}>
                    <Button onClick={handleOk} type="submit" disabled={false} variant="contained" color={'primary'}>
                      {onlyOkName || i18('OK')}
                    </Button>
                  </div>
                </div>
              )}
              {typeButtons === 'CancelSave' && (
                <div className={classes.div}>
                  <div className={classes.inDiv}>
                    <Button variant="contained" disabled={false} type="button" color={'cancel'} onClick={handleCancel}>
                      {onlyCancelName || i18('Cancel')}
                    </Button>
                  </div>
                  <div className={classes.inDiv}>
                    <Button onClick={handleSave} type="submit" disabled={disabledSaveButton} variant="contained" color={'primary'}>
                      {onlySaveName || i18('save')}
                    </Button>
                  </div>
                </div>
              )}
            </PortletActions>
          </Portlet>
        </GridItem>
      </GridContainer>
    </Dialog>
  );
}

export default ModalPortlet;
