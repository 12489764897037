import { patterHeaderStyle, patternCellStyle } from '../../table/stylesReport';

export const columnsTableAssetsDrivers = [
  {
    title: 'ASSETS',
    defaultGroupOrder: 0,
    field: 'vehicleSerial',
    cellStyle: {
      minWidth: '8%',
      maxWidth: '10%',
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
      textAlign: 'center',
      // width: '80px',
    },
  },
  {
    title: 'DRIVER_ID',
    field: 'driverId',
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'NAME',
    field: 'name',
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'LASTNAME',
    field: 'lastname',
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'NFC',
    field: 'NFC',
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
];
