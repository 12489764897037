// import { colors } from '@material-ui/core';

const white = '#FFFFFF';
// const black = '#000000';

export default {
  text: {
    // primary: white,
    // secondary: '#18425e',f7f9fd
    primary: '#18425e',
    secondary: '#6e7f8c',
  },
  primary: {
    // light: will be calculated from palette.primary.main,
    //   main: "#5c7ae5",
    //   main: "#ffffff",f2f5f9
    //main: '#32295e', //usado en innerSideBar
    main: '#5c7ae5',
    dark: '#32295e',

    // dark: will be calculated from palette.primary.main,
    contrastText: white,
  },
  secondary: {
    //   light: "#0066ff",
    main: '#ffb12a',
    // main: '#5c7ae5',
    // dark: will be calculated from palette.secondary.main,
    contrastText: white,
  },

  // Used by `getContrastText()` to maximize the contrast between
  // the background and the text.
  contrastThreshold: 3,
  // Used by the functions below to shift a color's luminance by approximately
  // two indexes within its tonal palette.
  // E.g., shift from Red 500 to Red 300 or Red 700.
  tonalOffset: 0.2,
};
