export const reportTitle = [
  {
    path: '/reports/communication/lastPositions',
    title: 'REPORT',
    subTitle: 'LastPositions',
  },
  { path: '/reports/communication/positions', title: 'REPORT', subTitle: 'Positions' },
  { path: '/reports/communication/ngreports', title: 'REPORT', subTitle: 'EVE_TELEMETRIA' },
  {
    path: '/reports/journey/tabletEvents',
    title: 'REPORT',
    subTitle: 'Tablet_Events',
  },
  {
    path: '/reports/journey/jcMacros',
    title: 'REPORT',
    subTitle: 'SUB_MACROS',
  },
  {
    path: '/reports/communication/sensors',
    title: 'REPORT',
    subTitle: 'SENSORS',
  },
  {
    path: '/reports/communication/alarms',
    title: 'REPORT',
    subTitle: 'ALARMS',
  },
  {
    path: '/reports/communication/latitudelongitude',
    title: 'REPORT',
    subTitle: 'LATITUDE_LONGITUDE',
  },
  {
    path: '/reports/communication/movPerDriver',
    title: 'REPORT',
    subTitle: 'MOV_PER_DRIVER',
  },
  {
    path: '/reports/communication/movPerAsset',
    title: 'REPORT',
    subTitle: 'MOV_PER_ASSET',
  },
  {
    path: '/reports/evidence/groupByDrivers',
    title: 'REPORT',
    subTitle: 'GROUP_BY_DRIVERS',
  },
  {
    path: '/reports/evidence/groupByAssets',
    title: 'REPORT',
    subTitle: 'GROUP_BY_ASSETS',
  },
  {
    path: '/reports/evidence/byDrivers',
    title: 'REPORT',
    subTitle: 'EVIDENCES_BY_DRIVERS',
  },
  {
    path: '/reports/evidence/byAssets',
    title: 'REPORT',
    subTitle: 'EVIDENCES_BY_ASSETS',
  },
  {
    path: '/reports/evidence/byUsers',
    title: 'REPORT',
    subTitle: 'EVIDENCES_BY_USERS',
  },
  {
    path: '/reports/journey/spokenRoute',
    title: 'REPORT',
    subTitle: 'SUB_SPOKEN_ROUTE',
  },
  {
    path: '/reports/journey/driverLogin',
    title: 'REPORT',
    subTitle: 'DRIVER_LOGIN',
  },

  {
    path: '/reports/journey/macrosPerAsset',
    title: 'REPORT',
    subTitle: 'MACRO_PER_ASSET',
  },
  {
    path: '/reports/list/assets',
    title: 'asset',
    subTitle: 'Asset',
  },
  {
    path: '/reports/list/devices',
    title: 'devices',
    subTitle: 'Devices',
  },
  {
    path: '/reports/list/nfc',
    title: 'REPORT',
    subTitle: 'NFC',
  },
  {
    path: '/reports/drivers/assetsDrivers',
    title: 'REPORT',
    subTitle: 'DRIV_PER_ASSET',
  },
  {
    path: '/reports/drivers/drivers',
    title: 'REPORT',
    subTitle: 'DRIVER',
  },
  {
    path: '/reports/drivers/assetsPerDrivers',
    title: 'REPORT',
    subTitle: 'ASSET_PER_DRIVERS',
  },
  {
    path: '/reports/drivers/driverWOAssets',
    title: 'REPORT',
    subTitle: 'DRIVER_NO_ASSETS',
  },
  {
    path: '/reports/drivers/driverWONFC',
    title: 'REPORT',
    subTitle: 'DRIVER_NO_NFC',
  },
  {
    path: '/reports/drivers/driverWithNFC',
    title: 'REPORT',
    subTitle: 'DRIVER_NFC',
  },
  {
    path: '/reports/drivers/tachometer',
    title: 'REPORT',
    subTitle: 'TACHOMETER',
  },
  {
    path: '/reports/mfis/statusJourneyControl',
    title: 'REPORT',
    subTitle: 'CJ_FULL_STATUS',
  },
  {
    path: '/reports/mfis/situationJourneyControl',
    title: 'REPORT',
    subTitle: 'CJ_FULL_SITUATION',
  },
  {
    path: '/reports/mfis/statusSpokenRoute',
    title: 'REPORT',
    subTitle: 'RF_FULL_STATUS',
  },
  {
    path: '/reports/mfis/situationSpokenRoute',
    title: 'REPORT',
    subTitle: 'RF_FULL_SITUATION',
  },
  {
    path: '/reports/security/roles',
    title: 'REPORT',
    subTitle: 'ROLES',
  },
  {
    path: '/reports/security/usersPerRol',
    title: 'REPORT',
    subTitle: 'USERS_PER_ROL',
  },
  {
    path: '/reports/users/usersPerCompany',
    title: 'REPORT',
    subTitle: 'USERS_PER_COMPANY',
  },
  {
    path: '/reports/users/assets',
    title: 'REPORT',
    subTitle: 'ASSETS_PER_USER',
  },
  {
    path: '/reports/assets/messages',
    title: 'REPORT',
    subTitle: 'MESSAGE',
  },
  {
    path: '/reports/assets/list',
    title: 'asset',
    subTitle: 'Asset',
  },
  {
    path: '/reports/assets/atlasMacros',
    title: 'REPORT',
    subTitle: 'ATLAS_MACROS',
  },
  {
    path: '/reports/assets/atlasEvents',
    title: 'REPORT',
    subTitle: 'ATLAS_EVENTOS',
  },
  {
    path: '/reports/assets/atlas',
    title: 'REPORT',
    subTitle: 'ATLAS',
  },
  {
    path: '/reports/devices/list',
    title: 'devices',
    subTitle: 'Devices',
  },
  {
    path: '/reports/nfc/list',
    title: 'REPORT',
    subTitle: 'NFC',
  },
  {
    path: '/reports/drivers/assetsDrivers',
    title: 'REPORT',
    subTitle: 'DRIV_PER_ASSET',
  },
  {
    path: '/reports/drivers/drivers',
    title: 'REPORT',
    subTitle: 'DRIVER',
  },
  {
    path: '/reports/drivers/assetsPerDrivers',
    title: 'REPORT',
    subTitle: 'ASSET_PER_DRIVERS',
  },
  {
    path: '/reports/drivers/driverWOAssets',
    title: 'REPORT',
    subTitle: 'DRIVER_NO_ASSETS',
  },
  {
    path: '/reports/drivers/driverWONFC',
    title: 'REPORT',
    subTitle: 'DRIVER_NO_NFC',
  },
  {
    path: '/reports/drivers/driverWithNFC',
    title: 'REPORT',
    subTitle: 'DRIVER_NFC',
  },
  {
    path: '/reports/mfis/statusJourneyControl',
    title: 'REPORT',
    subTitle: 'CJ_FULL_STATUS',
  },
  {
    path: '/reports/mfis/situationJourneyControl',
    title: 'REPORT',
    subTitle: 'CJ_FULL_SITUATION',
  },
  {
    path: '/reports/mfis/statusSpokenRoute',
    title: 'REPORT',
    subTitle: 'RF_FULL_STATUS',
  },
  {
    path: '/reports/mfis/situationSpokenRoute',
    title: 'REPORT',
    subTitle: 'RF_FULL_SITUATION',
  },
  {
    path: '/reports/security/roles',
    title: 'REPORT',
    subTitle: 'ROLES',
  },
  {
    path: '/reports/security/usersPerRol',
    title: 'REPORT',
    subTitle: 'USERS_PER_ROL',
  },
  {
    path: '/reports/users/usersPerCompany',
    title: 'REPORT',
    subTitle: 'USERS_PER_COMPANY',
  },
  {
    path: '/reports/assets/messages',
    title: 'REPORT',
    subTitle: 'MESSAGE',
  },
];
