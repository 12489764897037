import { makeStyles } from '@material-ui/core/styles';

export const regionConfigurationStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  text: { fontSize: '20px', fontWeight: 'normal' },
  content: {},
  portletStyle: {
    maxWidth: theme.spacing(70),
  },
  detailsStyle: {
    width: '100%',
  },
  gridContainer: {
    paddingBottom: theme.spacing(0),
  },
  rotogramaCard: {
    width: '100%',
  },
  centerContent: { justifyContent: 'center' },
  detailsCardContent: { display: 'flex' },
  detailsAction: { paddingBottom: theme.spacing(4) },
  meterStyle: { width: theme.spacing(20) },
  audioStyle: { width: theme.spacing(15) },
  poeStyle: { width: theme.spacing(15) },
}));
