import { format, addMonths } from 'date-fns';
import { patterHeaderStyle, patternCellStyle } from '../../table/stylesReport';

const date = new Date();
const now1Month = format(addMonths(date, -1), "yyyy-MM-dd'T'HH:MM");
const now24 = format(date, "yyyy-MM-dd'T'HH:MM");

export const initFilterJourneyControl = {
  startDateTime: now1Month,
  endDateTime: now24,
};

export const columnsJourneyControl = [
  {
    title: 'ASSETS',
    field: 'plates',
    defaultGroupOrder: 0,
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
      paddingLeft: '8px',
    },
  },
  {
    title: 'SUBJECT',
    field: 'subject',
    cellStyle: {
      width: null,
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },

  {
    title: 'MESSAGE',
    field: 'message',
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'STATUS',
    field: 'status',
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'SENT',
    field: 'created_at',
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'DELIVERED',
    field: 'delivered_at',
    filtering: false,
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: { ...patterHeaderStyle },
  },
  {
    title: 'READ',
    field: 'read_at',
    filtering: false,
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: { ...patterHeaderStyle },
  },
];
