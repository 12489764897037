import React from 'react';
// nodejs library that concatenates classes
import clsx from 'clsx';
// nodejs library to set properties for components
import PropTypes from 'prop-types';

// material-ui components
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { makeStyles } from '@material-ui/core/styles';
import { INPUT_BACKGROUND } from '../styles/constValues';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: INPUT_BACKGROUND,
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.primary.main,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.secondary.main,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.secondary.main,
      },
    },
  },
  smallRoot: {
    height: 'auto',
    marginTop: '0px',
    paddingLeft: '0px',
  },
  label: {
    marginLeft: '4px',
    color: theme.palette.primary.dark,
    fontSize: '12px',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    color: theme.palette.text.secondary,
    backgroundColor: INPUT_BACKGROUND,
  },
}));
function AutoCompleteBox({ ...props }) {
  const { options, className, seleccionado, cancel, size, color, children, label, labelPlace, ...rest } = props;

  const classes = useStyles();
  const autoClasses = clsx({
    [classes.root]: true,
    [classes.smallRoot]: true,
  });
  return <Autocomplete {...rest} options={options} renderInput={(params) => <TextField {...params} className={autoClasses} label={label} variant="outlined" inputProps={{ ...params.inputProps, autoComplete: 'new-password' }} />} />;
}

AutoCompleteBox.propTypes = {
  options: PropTypes.array,
};
AutoCompleteBox.defaultProps = {
  options: [],
};

export default AutoCompleteBox;
