import calcCenter from './calcCenter';
import { DEFAULT_COORDS } from './constants';

//set multiple points
export function mapAttributes(markers = null) {
  const zoom = 11;
  const boundsOptions = { padding: [20, 20] };
  const defaultAttributes = {
    zoom,
    // bounds: undefined,
    center: [DEFAULT_COORDS.latitude, DEFAULT_COORDS.longitude],
    // boundsOptions,
  };

  if (!markers) return defaultAttributes;

  if (!markers.length) return defaultAttributes;

  if (markers) {
    const [centerPoint, boundsMap] = calcCenter(markers);
    return {
      center: centerPoint,
      bounds: boundsMap,
      // boundsOptions,
      zoom: undefined,
    };
  }
}
//set one point
export default function mapPositioning(position, def = false, vehicles = null, defCenter = null) {
  const zoom = def ? 11 : 16;
  let bounds;
  let calCenterPoint = def ? defCenter : [position.latitude, position.longitude];
  if (def && vehicles.length) {
    const [centerPoint, boundsMap] = calcCenter(vehicles);
    calCenterPoint = centerPoint;
    bounds = boundsMap;
  }
  // const calCenterPoint  vehicles ? calcCenter(vehicles) : [position.latitude, position.longitude];
  const center = !def ? calCenterPoint : undefined;
  return {
    zoom,
    bounds,
    center,
    boundsOptions: { padding: [20, 20] },
  };
}
