//#region IMPORT
import React, { useState, useEffect } from 'react';

import { motion } from 'framer-motion';

import { useIntl } from '../../../lang/context/intlContext';

import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import CustomCard from '../../../components/CustomCard/Card';
import { v4 as uuidv4 } from 'uuid';
//state
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Loading } from '../../../../Splash';
import { getAllJourneyControl, getJourneyControlById } from './api/getMethods';
import { postEnableDisable } from './api/postMethods';

//#endregion
const useStyle = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    // marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  text: { fontSize: '20px', fontWeight: 'normal' },
  cardStyle: { maxWidth: theme.spacing(30) },
  content: {},
  gridContainer: {
    marginTop: theme.spacing(3),
  },
}));

const buttonSelectText = (status) => {
  if (status === 0) return 'Continue';
  if (status === 1) return 'View';
  if (status === 2) return 'locked';
  return 'Disabled';
};
const Management = ({ history, match }) => {
  const classes = useStyle();
  const { i18 } = useIntl();
  const [eloading, setEloading] = useState(0);

  const [isLoading, setisLoading] = useState(true);

  const [selectedCard, setSelectedCard] = useState(undefined);
  const [journeyControl, setJourneyControl] = useState([]);
  //#region store
  const setButtons = useStoreActions((actions) => actions.globalState.setButtons);
  const cleanUpSubHeader = useStoreActions((actions) => actions.globalState.cleanUpSubHeader);
  const publish = useStoreActions((actions) => actions.journeyControl.publish);
  const continueEdition = useStoreActions((actions) => actions.journeyControl.continueEdition);

  //#endregion

  const headerMenuWithCard = (selectedCard) => {
    if (!selectedCard) return [];
    // console.info(selectedCard);
    return [
      {
        name: 'DUplicate',
        push: '/mfis/control/management/all/duplication',
      },
      {
        name: 'Archive',
        push: '/mfis/control/management/',
        post: () => postEnableDisable(selectedCard?.journeyControlId, 1),
        action: 'post',
      },
    ];
  };
  const buttonText = i18('RELEASE');
  const param = match.params.id;

  const fetchData = async () => {
    const res = await getAllJourneyControl();
    setJourneyControl(res.data.journeyControls);
    setisLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [param]);

  useEffect(() => {
    let isDisabled = true;
    if (selectedCard) isDisabled = selectedCard.status < 1;
    const headerMenu = headerMenuWithCard(selectedCard);
    const primaryButton = { text: buttonText, isEnabled: true, fnc: () => execPublish(selectedCard), disabled: isDisabled };
    const secondaryButton = {
      text: i18('ADD'),
      isEnabled: true,
      disabled: false,
      fnc: () => history.push(`/mfis/control/selecttemplate`),
    };
    const payload = { headerMenu, primaryButton, secondaryButton };
    setButtons(payload);

    return () => {
      cleanUpSubHeader();
    };
  }, [selectedCard]);

  const onSelectCard = (e, selectedCard) => {
    e.preventDefault();
    setSelectedCard(selectedCard);
  };
  /**
   * This methods get details about JC and redirects to configuration page, to either save or roll out
   * @param {any} e
   * @param {any} journeyControl
   */
  const onClickJC = async (e, jc) => {
    setEloading(jc.journeyControlId);
    e.preventDefault();
    const res = await getJourneyControlById(jc.journeyControlId);
    const { data } = res;
    setEloading(0);
    continueEdition(data.journeyControl);

    history.push(`/mfis/control/configuration`);
  };

  const execPublish = (journeyControl) => {
    const { journeyControlId } = journeyControl;
    if (journeyControlId > 0) {
      console.log('toPublish', journeyControl);
      publish(journeyControl);
      history.push(`/mfis/control/publish`);
    }
  };

  if (isLoading)
    return (
      <GridContainer className={classes.content}>
        <GridItem item xs={12} md={12} lg={12}>
          <Loading></Loading>
        </GridItem>{' '}
      </GridContainer>
    );

  const subText = i18('template');
  return (
    <div className={classes.root}>
      <Typography component="h3" variant="h5" noWrap className={classes.text}>
        {i18('JC_LIST')}
      </Typography>
      <GridContainer className={classes.gridContainer} spacing={2}>
        {journeyControl
          .filter((item) => !item.isDisabled)
          .map((item) => {
            const finalText = `${subText}: ${item.templateName}`;
            const texto = item.name;
            const selected = item.journeyControlId === selectedCard?.journeyControlId;
            const buttonText = i18(buttonSelectText(item.status));
            return (
              <GridItem key={uuidv4()} item>
                <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.9 }}>
                  <CustomCard className={classes.cardStyle} key={uuidv4()} title={texto} onSelect={onSelectCard} selected={selected} value={item} align={'center'} contentText={finalText} hasAvatar onClick={onClickJC} buttonText={buttonText} isLoading={eloading === item.journeyControlId}></CustomCard>
                </motion.div>
              </GridItem>
            );
          })}
      </GridContainer>
    </div>
  );
};

export default Management;
