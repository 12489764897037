import React, { useCallback } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import { Badge, IconButton } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

//components
import Profile from './components/Profile';
import Searcher from '../../../components/Searcher';
import { whiteColor } from '../../../components/styles/constValues';
//store
import { useStoreActions, useStoreState } from 'easy-peasy';
import { drawerWidth, sumDrawers } from '../../constants';
import { useIntl } from '../../../lang/context/intlContext';
import { useHistory } from 'react-router-dom';
//tuil
import { v4 as uuid } from 'uuid';
import InputSearch from '../../../components/InputSearch/InputSearch';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    display: 'flex',

    paddingRight: 24,
    height: '100%', // keep right padding when drawer closed
    //    width: '100%',
  },
  appBar: {
    height: theme.spacing(13),
    backgroundColor: '#ffffff',
    // height: theme.spacing(12),
    width: `calc(100% - ${drawerWidth}px)`,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    backgroundColor: '#ffffff',
    // height: '120px',
    // marginLeft: sumDrawers,
    width: `calc(100% - ${sumDrawers}px)`,
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  title: {
    fontWeight: 500,
  },
  flexGrow: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    paddingLeft: theme.spacing(1),
    flexDirection: 'column',
    width: 'auto',
  },
  notifButton: {
    backgroundColor: '#fffff',
    color: 'black',
    marginRight: theme.spacing(3),
    // paddingRight: theme.spacing(5),
    '&:hover': {
      color: whiteColor,
      backgroundColor: theme.palette.secondary.light,
      // fontWeight: '500',
    },
  },
  menuButton: {
    backgroundColor: '#fffff',
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(3),
    // paddingRight: theme.spacing(5),
    '&:hover': {
      color: whiteColor,
      backgroundColor: theme.palette.secondary.light,
      // fontWeight: '500',
    },
  },
  userProfile: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
  },
  input: {
    color: theme.palette.text.secondary,
    backgroundColor: 'white',
  },
  spacer: {
    flexGrow: 1,
  },
}));

export default function HeaderMap({ ...props }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { headerMenu } = useStoreState((state) => state.globalState);
  const { userName, clientName, email } = useStoreState((state) => state.user);
  const { open, title, subTitle } = props;
  const history = useHistory();
  const { i18 } = useIntl();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = async (item) => {
    setAnchorEl(null);
    switch (item.action) {
      case 'post':
        await item.post();
        history.push(item.push + uuid());
        break;
      case 'dialogConfirm':
        history.push(item.push);
        break;
      default:
        break;
    }
  };

  const updateProfile = useCallback(() => <Profile userName={userName} clientName={clientName} />, [userName, clientName]);
  const menuItems = (menus) =>
    menus.map((item, index) => (
      <MenuItem key={index} onClick={() => handleClose(item)}>
        {i18(item.name)}
      </MenuItem>
    ));

  return (
    <AppBar position="absolute" elevation={0} className={clsx(classes.appBar, open && classes.appBarShift)}>
      <Toolbar className={classes.toolbar}>
        <Typography component="h1" variant="h3" noWrap>
          {title}
        </Typography>
        <IconButton className={classes.menuButton} onClick={handleClick} aria-label="display more actions" edge="end" color="inherit">
          <MoreVertIcon />
        </IconButton>
        <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
          {headerMenu && menuItems(headerMenu)}
          {/* <MenuItem onClick={handleClose}>{i18('Duplicate')}</MenuItem>
          <MenuItem onClick={handleClose}>{i18('Delete')}</MenuItem> */}
          {/* <MenuItem onClick={handleClose}>Logout</MenuItem> */}
        </Menu>
        {/* <span className={classes.spacer} /> */}
        <div className={classes.flexGrow}>
          {/* <Searcher label="Buscar" /> */}
          {/* <InputSearch
            id="search"
            placeholder={i18('search')}
            name="search"
            label={i18('search')}
            // inputRef={register({ required: true })}
          ></InputSearch> */}
        </div>
        <div className={classes.userProfile}>
          <IconButton className={classes.notifButton} color="inherit">
            <Badge color="primary" variant="dot">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          {/* <Profile userName={userName} clientName={clientName} />
           */}
          {updateProfile()}
        </div>
      </Toolbar>
    </AppBar>
  );
}
