import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { v4 as uuidv4 } from 'uuid';
import { Avatar, Chip, IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Typography } from '@material-ui/core';

import { getIcon } from './helper';
import { ActionDelete, ActionRemove } from '../../../assets/icons';

import { AnimatePresence, motion } from 'framer-motion';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 400,
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
}));

function getItems(props) {
  const { items, classes, hasDelete = false, onClickDelete = undefined } = props;

  return items.map((item) => <ListItemMacro key={item.macroJourneyControlId + '|' + item.macroJourneyControlNextId} {...item} classes={classes} hasDelete={hasDelete} onClickDelete={onClickDelete} />);
}

const ListMacro = (props) => {
  const classes = useStyles();
  return (
    <List className={classes.root} dense={true}>
      {getItems(props)}
    </List>
  );
};

/**
 * Pure Componente
 * @param {object} props
 */
const ListItemMacro = (props) => {
  const { macroJourneyControlId, macroJourneyControlNextId, classes, title, color = 'primary', chipLabel = undefined, chipColor = 'primary', type = 1, hasDelete, onClickDelete } = props;
  const classNameIcon = color === 'primary' ? classes.listItemAvatarSizePrimary : classes.listItemAvatarSizeSecondary;

  return (
    <ListItem classes={{ gutters: classes.listItemGutters }} button>
      <ListItemAvatar classes={{ root: classes.listItemAvatarRoot }}>
        <Avatar className={classes.listItemAvatarWrap}>{getIcon(type, color, classNameIcon)}</Avatar>
      </ListItemAvatar>
      <ListItemText primary={<Typography className={classes.listItemText}>{title}</Typography>} />
      {chipLabel && <Chip label={chipLabel} color={chipColor} size="small" />}
      <ListItemSecondaryAction>{hasDelete && <ActionDelete onClick={() => onClickDelete({ macroJourneyControlId, macroJourneyControlNextId })}></ActionDelete>}</ListItemSecondaryAction>
    </ListItem>
  );
};

/*
1) Inicio de Jornada
2) Inicio de Viaje
3) Reinicio de Viaje
4) Parada Espera
5) Parada Refrecao
6) Parada Descanso
7) Fim de Viaje
8) Fin de Jornada
 */

export default ListMacro;
