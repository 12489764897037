import { format, utcToZonedTime } from 'date-fns-tz';

export const baseDateTimeMS = 946684800; //1/ene 2000 00:00 Z
export const baseHourMS = 36000;
export const baseMinMS = 600;
export const formatValue = (value) => {
  let formattedValue = value;
  if (value.length < 4) {
    const size = 4;
    formattedValue = value.padEnd(size, '0');
  }
  return formattedValue;
};

export const getTimeinMS = (hour, min) => {
  const hours = baseHourMS * hour;
  const mins = baseMinMS * min;
  const total = baseDateTimeMS + hours + mins;
  return total * 1000;
};

export const getDateByHours = (hour, min) => {
  return new Date(Date.UTC(getTimeinMS(hour, min)));
};
/**
 * GET User's DateTime from the given dateTime
 * @param {*} dateTimeToConvert UTS datetime
 * @param {*} userTimeZone User's TimeZone
 * @returns
 */
export const getGMTtoUserTimeZone = (dateTimeToConvert, userTimeZone) => {
  const userDate = utcToZonedTime(dateTimeToConvert, userTimeZone);
  return format(userDate, 'yyy-MM-dd HH:mm:ss', { timeZone: userTimeZone });
};
