import React from 'react';
// @material-ui/core components
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(() => ({
  grid: {
    margin: '0 -15px !important',
  },
}));

function GridItem({ ...props }) {
  const { className, children, ...rest } = props;

  const classes = useStyles();
  const gridClasses = clsx(
    {
      [classes.grid]: false,
    },
    className
  );
  return (
    <Grid item {...rest} className={gridClasses}>
      {children}
    </Grid>
  );
}

export default GridItem;
