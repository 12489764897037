//@ts-nocheck
import { action, computed } from 'easy-peasy';

// vehicles used in admin modules
// assets used in combo list

const initValue = { vehicles: [], vehiclesWODevices: [], assets: [] };

const vehicle = {
  ...initValue,

  addAssets: action((state, data) => (state.assets = data)),
  clearAssets: action((state) => (state.assets = [])),
  addVehicles: action((state, data) => (state.vehicles = data)),
  addVehiclesWODevices: action((state, data) => (state.vehiclesWODevices = data)),
  clearVehicles: action((state) => (state.vehicles = [])),
  clearVehiclesWODevices: action((state) => (state.vehiclesWODevices = [])),
  getVehicle: computed((state) => (id) => state.vehicles.find((item) => item.nfcId === id)),
  getVehicleWODevices: computed((state) => (id) => state.vehiclesWODevices.find((item) => item.nfcId === id)),
  deleteVehicle: action((state, deviceAgentId) => {
    state.vehicles = state.vehicles.filter((item) => item.deviceAgentId !== +deviceAgentId);
    state.vehiclesWODevices = [];
  }),
  deleteVehicleWODevices: action((state, agentId) => {
    state.vehiclesWODevices = state.vehiclesWODevices.filter((item) => item.agentId !== +agentId);
  }),
  updateVehicle: action((state, data) => {}),
};

export default vehicle;
