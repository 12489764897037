import React, { useEffect } from 'react';

import { useIntl } from '../../../lang/context/intlContext';

import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
//state
import { useStoreActions, useStoreState } from 'easy-peasy';
import NodeMacro from '../../../components/NodeMacro/NodeMacro';
import NextNodeMacro from '../../../components/NodeMacro/NextNodeMacro';
import { BackIcon } from '../../../../assets/icons';
import { postRollOut } from './api/postMethods';

const useStyle = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  rootFlow: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(1),
    width: 'auto',
  },
  rightMenu: {
    maxWidth: theme.spacing(37),
    width: theme.spacing(37),
  },
  gridStyle: { width: 'auto' },
  paperStyle: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(10),
      height: theme.spacing(10),
    },
  },
  verticalStyle: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(12),
      height: theme.spacing(10),
    },
  },
  nodeStyle: {
    margin: theme.spacing(0),
    '&:hover': {
      width: theme.spacing(36),
    },
  },
  verticalLine: {
    margin: theme.spacing(0),
    width: '1px' /* Line width */,
    backgroundColor: 'black' /* Line color */,
    height: theme.spacing(2) /* Override in-line if you want specific height. */,
    // alignSelf: 'center',
    // justifySelf: 'center',
    marginLeft: theme.spacing(4),
    float: 'left' /* Causes the line to float to left of content. 
        You can instead use position:absolute or display:inline-block
        if this fits better with your design */,
  },
  text: { fontSize: '20px', fontWeight: 'normal' },
}));

const ConfigurationReview = (props) => {
  const { history } = props;
  const { i18 } = useIntl();
  const classes = useStyle();

  const [nextMacros, setNextMacros] = React.useState([]);
  const [marginTop, setMarginTop] = React.useState(0);
  const [nodeSelected, setNodeSelected] = React.useState(-1);
  const clickOnMacro = (e, position) => {
    setNodeSelected(e);
    const tempNextMacros = journeyControl.macros.find((item) => item.macroJourneyControlId === +e).next;
    const splitNext = Math.floor(tempNextMacros.length / 2);
    const offSetNodes = splitNext <= position ? splitNext * 12 * 8 : 0; // Math.abs( macros.length - nextMacros.length)
    setMarginTop(13 * position * 8 - offSetNodes);
    setNextMacros(tempNextMacros);
  };

  const journeyControl = useStoreState((state) => state.journeyControl);

  const setNoButtons = useStoreActions((actions) => actions.globalState.setNoButtons);
  const setPrimaryButton = useStoreActions((actions) => actions.globalState.setPrimaryButton);
  const setSecondaryButton = useStoreActions((actions) => actions.globalState.setSecondaryButton);

  useEffect(() => {
    setPrimaryButton({
      text: i18('ROLLOUT'),
      isEnabled: true,
      fnc: async () => {
        await rollOut();
        history.push(`/mfis/control/management/all`);
      },
      disabled: !journeyControl.macros,
    });
    setSecondaryButton({
      text: i18('Back'),
      isEnabled: true,
      fnc: () => {
        history.push(`/mfis/control/configuration`);
      },
      disabled: false,
      Icon: BackIcon,
    });
    return () => {
      setNoButtons();
    };
  }, []);

  const rollOut = async () => {
    const res = await postRollOut(journeyControl.journeyControlId);
    return res;
  };

  return (
    <div className={classes.root}>
      {/* <div>
        <Typography component="h3" variant="h5" noWrap className={classes.text}>
          {i18('FLOW_MAP')}
        </Typography>
        <Typography color="secondary">{i18('ERROR_MACRO_RED')}</Typography>
      </div> */}
      <div className={classes.rootFlow}>
        <GridContainer style={{ width: '100%' }} spacing={2}>
          <GridItem item xs={12} md={4} lg={4}>
            <GridContainer direction="column" spacing={0} className={classes.gridStyle}>
              {nodeMacro(journeyControl.macros, nodeSelected, clickOnMacro)}
            </GridContainer>
          </GridItem>
          <GridItem item xs={12} md={3} lg={3}>
            <div style={{ marginTop: marginTop + 'px' }}>
              <GridContainer direction="column" spacing={0} className={classes.gridStyle}>
                {nodeNextMacro(nextMacros)}
              </GridContainer>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
};

const nodeMacro = (items, selected, onClick) => {
  const length = items.length - 1;
  const allNext = items.map((item) => item.next).flat();
  const idsNext = new Set(allNext.map((item) => item.macroJourneyControlNextId));

  return items.map((item, index) => <NodeMacro position={index} key={'dddd' + index} selected={selected < 0 && index === 0 ? true : item.macroJourneyControlId === selected} withError={!idsNext.has(item.macroJourneyControlId) || !item.next.length} onClick={onClick} item={item} showLine={index < length} />);
};
const nodeNextMacro = (items) => {
  const length = items.length - 1;
  return items.map((item, index) => <NextNodeMacro position={index} key={'dddd' + index} item={item} showLine={index < length} />);
};
export default ConfigurationReview;
