import axios from 'axios';
export const GET_ALL_REGIONS = '/region/getAll';
export const GET_ALL_TABLETS = '/assets/getTablets';
export const GET_ALL_ASSETS = '/assets/getAll';

const GET_REGION_NAME_AVAILABILITY = '/region/checkNameAvailability';

export function getRegionNameAvailability(name) {
  return axios.get(`${GET_REGION_NAME_AVAILABILITY}?name=${name}`);
}
