//#region IMPORTS
import React, { useState, useEffect } from 'react';
import { useIntl } from '../../../lang/context/intlContext'; //addjust path reference
//#region MUI Components
import { makeStyles } from '@material-ui/core/styles';
import { List } from '@material-ui/core';
//#endregion
//#region CustomComponents
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import Canva from '../../../components/Canva/Canva';
import { getAllRoles, getUserEntities } from '../api/getMethods';
import { Loading } from '../../../../Splash';
import CustomizedSnackbars from '../../../components/CustomSnackBar/CustomSnackBar';
import AddUsers from './AddUsers';
import AddRol from './AddRol';
import { getRoleList, getSectionList, getUserList, compareStrings } from './helper';
import { useStoreActions, useStoreState } from 'easy-peasy';
import {
  postAddRoleSections,
  postAddRoleUsers,
  postAddNewRole,
  postRemoveRole,
  postRemoveUserFromRole,
} from '../api/postMethods';
import YesOrNot from '../../../components/YesOrNot/YesOrNot';
import { ItemCustomIcon } from '../../../layout/components/Navigator/SubMenu/ItemIcon';

//#endregion
//#endregion
const useStyle = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  rootWhite: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 600,
  },
  content: {},
  inDiv: {
    padding: theme.spacing(4),
    position: 'relative',
  },
  buttonProgress: {
    color: theme.palette.primary.dark[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const Roles = () => {
  const classes = useStyle();

  //#region easy-peasy
  const sections = useStoreState((state) => state.user.allSections);
  const users = useStoreState((state) => state.user.users);
  const maxRolLevel = useStoreState((state) => state.user.maxRolLevel);
  const addUsers = useStoreActions((state) => state.user.addUsers);

  //#endregion
  const [openSnack, setOpenSnack] = React.useState({ open: false, title: '', severity: 'success' });
  const [openUsers, setOpenUsers] = React.useState(false);
  const [openYesOrNot, setopenYesOrNot] = useState(false);
  const [confirmContentText, setconfirmContentText] = useState('');

  const [openRol, setOpenRol] = React.useState(false);
  const [rolName, setrolName] = React.useState('');
  const [rolDescription, setrolDescription] = React.useState('');
  const [rolLevel, setRolLevel] = React.useState(0);
  const [isInherit, setIsInherit] = React.useState(false);
  const [roles, setRoles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingCP, setIsLoadingCP] = useState(false);

  const [selectedRolId, setselectedRolId] = React.useState(0);
  const [selectedRemoveUserId, setSelectedRemoveUserId] = React.useState(0);
  const [selectedRemoveRolId, setSelectedRemoveRolId] = React.useState(0);
  const [selectedRemoveAction, setSelectedRemoveAction] = React.useState(0);
  const [selectedRoleSections, setselectedRoleSections] = useState([]);
  const [selectedRoleUsers, setselectedRoleUsers] = useState([]);
  const [newRoleUsers, setnewRoleUsers] = useState([]);
  const [disableUpdate, setDisableUpdate] = useState(true);
  const [disableAddUsers, setDisableAddUsers] = useState(true);
  const { i18 } = useIntl();
  const subHeader = '';
  // #region HANDLERS
  const handleListItemClick = (event, index) => {
    setselectedRolId(index);
    const { sections, users } = roles.find((item) => item.rolId === index);
    setselectedRoleSections(sections);
    setselectedRoleUsers(users);
    setDisableAddUsers(false);
    setDisableUpdate(true);
  };
  const handleCheckChange = (e, sectionId, right) => {
    const { checked } = e.target;
    if (disableUpdate && selectedRolId > 0) setDisableUpdate(false);
    if (!selectedRoleSections.find((item) => item.sectionId === sectionId)) {
      setselectedRoleSections((prev) => [...prev, { sectionId, rightsAllowed: right }]);
    } else {
      setselectedRoleSections((prev) => {
        return prev.map((item) => {
          if (item.sectionId === sectionId) {
            let newRightsAllowed = 0;
            if (checked) {
              if (right === 1) newRightsAllowed = 1;
              if (right === 8) newRightsAllowed = 8;
              if (right > 1 && right < 8) newRightsAllowed = item.rightsAllowed + right;
              if (right > 1 && item.rightsAllowed & 1 && right < 8) newRightsAllowed--;
            } else {
              newRightsAllowed = item.rightsAllowed - right;
              if (newRightsAllowed === 0) newRightsAllowed = 1;
            }
            return { ...item, rightsAllowed: newRightsAllowed };
          }
          return item;
        });
      });
    }
  };
  const handleAddUsers = async () => {
    setOpenUsers(true);
  };
  const handleCloseUsers = () => {
    setOpenUsers(false);
  };
  const handleCloseRol = () => {
    setOpenRol(false);
  };
  const handleComboLevelChange = (e) => {
    setRolLevel(e.target.value);
  };
  const handleCheckIsInherit = () => setIsInherit((prev) => !prev);
  const handleCloseRemoveUser = async (yesOption) => {
    try {
      if (yesOption) {
        console.info({ selectedRemoveRolId, selectedRemoveUserId, selectedRemoveAction });
        switch (selectedRemoveAction) {
          case 1:
            await postRemoveRole({ rolId: selectedRemoveRolId });
            removeRole(selectedRemoveRolId);
            break;
          case 2:
            await postRemoveUserFromRole({ rolId: selectedRolId, userId: selectedRemoveUserId });
            removeUserFromRole(selectedRolId, selectedRemoveUserId);
            break;
        }
      }
    } catch (error) {}
    setopenYesOrNot(false);
  };
  const removeUserFromRole = (rolId, userId) => {
    setRoles((prev) => {
      return prev.map((item) => {
        if (item.rolId !== rolId) return item;
        return { ...item, users: item.users.filter((user) => user.userId !== userId) };
      });
    });
    setselectedRoleUsers((prev) => prev.filter((user) => user.userId !== userId));
  };
  const removeRole = (rolId) => {
    setRoles((prev) => prev.filter((rol) => rol.rolId !== rolId));
    setselectedRoleSections((prev) => prev.filter((section) => section.rolId !== rolId));
    setselectedRoleUsers((prev) => prev.filter((user) => user.rolId !== rolId));
  };
  // #endregion

  // #region OnClickButton

  const onClickUpdateSections = async () => {
    try {
      const rolWebSections = selectedRoleSections.map((item) => ({ rolId: selectedRolId, ...item }));
      const resPost = await postAddRoleSections(rolWebSections);
      const { sections } = resPost.data;
      setselectedRoleSections(sections);
      setRoles((prev) => {
        return prev.map((item) => {
          return item.rolId === selectedRolId ? { ...item, sections: sections } : item;
        });
      });
      setDisableUpdate(true);
      setOpenSnack((prev) => ({ ...prev, open: true, title: i18('ROL_SAVED'), severity: 'success' }));
    } catch (error) {
      setOpenSnack((prev) => ({ ...prev, open: true, title: i18('ErrorOnComm'), severity: 'error' }));
    }
  };
  const onClickAddUsers = async () => {
    try {
      setIsLoadingCP(true);
      const rolWebUsers = newRoleUsers.map((user) => ({ rolId: selectedRolId, userId: user.userId }));
      const resPost = await postAddRoleUsers(rolWebUsers);
      const { users: newUsers } = resPost.data;
      const formatedUsers = newUsers.map((user) => {
        const theUser = users.find((item) => item.userId === user.userId);
        const { userName, clientId, clientName, email } = theUser;
        return { ...user, userName, clientId, clientName, email };
      });
      setRoles((prev) => {
        return prev.map((item) => {
          return item.rolId === selectedRolId ? { ...item, users: [...item.users, ...formatedUsers] } : item;
        });
      });
      setselectedRoleUsers((prev) => [...prev, ...formatedUsers]);
      setOpenSnack((prev) => ({ ...prev, open: true, title: i18('USERES_ADDRED'), severity: 'success' }));
      setOpenUsers(false);
    } catch (error) {
      setOpenSnack((prev) => ({ ...prev, open: true, title: error, severity: 'error' }));
    }
    setIsLoadingCP(false);
  };
  const onClickAddRol = async () => {
    try {
      setIsLoadingCP(true);
      const rol = { name: rolName, description: rolDescription, rolLevel };
      const resNewRol = await postAddNewRole({ rol });
      setRoles((prev) => [...prev, resNewRol.data.rol]);
      setOpenSnack((prev) => ({ ...prev, open: true, title: i18('NEW_ROL_ADDED'), severity: 'success' }));
    } catch (error) {
      setOpenSnack((prev) => ({ ...prev, open: true, title: error, severity: 'error' }));
    }
    setIsLoadingCP(false);
    setOpenRol(false);
  };
  const onClickNewRol = () => {
    setOpenRol(true);
  };
  const onClickRemoveUser = (action = 1, param) => {
    setSelectedRemoveAction(action);
    switch (action) {
      case 1:
        setconfirmContentText(i18('CONFIRM_REMOVE_ROL'));
        setSelectedRemoveRolId(param);
        break;
      case 2:
        setconfirmContentText(i18('CONFIRM_REMOVE_USER'));
        setSelectedRemoveUserId(param);
        break;
      default:
        break;
    }

    setopenYesOrNot(true);
  };
  // #endregion
  const fetchData = async () => {
    try {
      const res = await getAllRoles();
      setRoles(res.data.roles);
      const fetchUserEntities = await getUserEntities();
      addUsers(fetchUserEntities.data.users.sort((a, b) => compareStrings(a.userName, b.userName)));
      setIsLoading(false);
    } catch (error) {
      setRoles([]);
      console.error(error);
      setIsLoading(false);
      setOpenSnack((prev) => ({ ...prev, open: true, title: i18('ErrorOnComm'), severity: 'error' }));
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  if (isLoading)
    return (
      <GridContainer className={classes.content}>
        <GridItem item xs={12} md={12} lg={12}>
          <Loading></Loading>
        </GridItem>{' '}
      </GridContainer>
    );

  return (
    <>
      <GridContainer spacing={4}>
        <GridItem item xs={12} md={3} lg={3}>
          <Canva
            hasTitle={true}
            title={i18('Roles')}
            subHeader={subHeader}
            withButton
            buttonTitle={i18('New')}
            onClickButton={onClickNewRol}
            isLoading={isLoading}
          >
            <List component="nav" dense={true} style={defStyle}>
              {getRoleList(roles, { button: true }, handleListItemClick, selectedRolId, onClickRemoveUser)}
            </List>
          </Canva>
        </GridItem>
        <GridItem item xs={12} md={6} lg={6}>
          <Canva
            hasTitle={true}
            title={i18('Sections')}
            subHeader={subHeader}
            isLoading={isLoading}
            withButton
            buttonTitle={i18('update')}
            isButtonDisabled={disableUpdate}
            onClickButton={onClickUpdateSections}
          >
            <List dense={false} style={defStyle}>
              {selectedRolId > 0 ? getSectionList(sections, { i18 }, handleCheckChange, selectedRoleSections) : null}
            </List>
          </Canva>
        </GridItem>{' '}
        <GridItem item xs={12} md={3} lg={3}>
          <Canva
            hasTitle={true}
            isLoading={isLoading}
            title={i18('Users')}
            subHeader={subHeader}
            withButton
            buttonTitle={i18('Add')}
            onClickButton={handleAddUsers}
            isButtonDisabled={disableAddUsers}
          >
            <List component="nav" dense={true} style={defStyle}>
              {getUserList(selectedRoleUsers, { button: true }, handleListItemClick, selectedRolId, onClickRemoveUser)}
            </List>
          </Canva>
        </GridItem>
      </GridContainer>
      <CustomizedSnackbars
        open={openSnack.open}
        setOpen={setOpenSnack}
        title={openSnack.title}
        severity={openSnack.severity}
      ></CustomizedSnackbars>
      {AddUsers({
        openUsers,
        handleCloseUsers,
        i18,
        users,
        selectedRoleUsers,
        setnewRoleUsers,
        onClickAddUsers,
        isLoading: isLoadingCP,
        classes,
      })}
      {AddRol({
        openRol,
        handleCloseRol,
        i18,
        onClickAddRol,
        maxRolLevel,
        rolDescription,
        setrolDescription,
        rolName,
        setrolName,
        handleComboLevelChange,
        handleCheckIsInherit,
        rolLevel,
        isInherit,
        isLoading: isLoadingCP,
        classes,
      })}
      <YesOrNot
        open={openYesOrNot}
        handleClose={handleCloseRemoveUser}
        titleText={i18('CONFIRM_ACTION')}
        contentText={confirmContentText}
        yesLabel={i18('YES_REMOVE')}
        notLabel={i18('CANCEL')}
      ></YesOrNot>
    </>
  );
};
export default Roles;

const defStyle = {
  marginTop: '4px',
  maxWidth: '100%',
  height: '400px',
  backgroundColor: '#ffff',
  maxHeight: '400px',
  overflow: 'auto',
};
