import React from 'react';
import PrivateRoute from '../auth/PrivateRoute';

import Positions from '../modules/Reports/communication/positions/Positions';
import NGEvents from '../modules/Reports/communication/ngreports/EventsReport';
import LastPositions from '../modules/Reports/communication/lastPositions/LastPositions';
import CJMacros from '../modules/Reports/journey/cjMacros/CJMacros';
import SpokenRoute from '../modules/Reports/journey/spokenRoute/spokenRoute';
import DriverLogin from '../modules/Reports/journey/driverLogin/DriverLogin';
import MacrosPerAsset from '../modules/Reports/journey/macrosPerAsset/MacrosPerAsset';
import AssetsPerDrivers from '../modules/Reports/list/assetsPerDrivers/AssetsPerDrivers';
import AssetsDrivers from '../modules/Reports/list/assetsDrivers/AssetsDrivers';
import Driver from '../modules/Reports/list/driver/Driver';
import Asset from '../modules/Reports/list/asset/Asset';
import Device from '../modules/Reports/list/device/Device';
import Nfc from '../modules/Reports/list/nfc/Nfc';
import DriverWOAssets from '../modules/Reports/list/driverWOAssets/DriverWOAssets';
import DriverWithNFC from '../modules/Reports/list/driverWithNFC/DriverWithNFC';
import DriverWONFC from '../modules/Reports/list/driverWONFC/DriverWONFC';
import StatusJourneyControl from '../modules/Reports/mfis/statusJourneyControl/StatusJourneyControl';
import SituationJourneyControl from '../modules/Reports/mfis/situationJourneyControl/SituationJourneyControl';
import SituationSpokenRoute from '../modules/Reports/mfis/situationSpokenRoute/SituationSpokenRoute';
import StatusSpokenRoute from '../modules/Reports/mfis/statusSpokenRoute/StatusSpokenRoute';
import Role from '../modules/Reports/security/role/Role';
import UsersPerRol from '../modules/Reports/security/usersPerRol/UsersPerRol';
import UsersPerCompany from '../modules/Reports/users/usersPerCompany/UsersPerCompany';
import Assets from '../modules/Reports/users/assets/Assets';
import Message from '../modules/Reports/assets/message/Message';
import Atlas from '../modules/Reports/assets/atlas/Atlas';
import Sensors from '../modules/Reports/communication/sensors/Sensors';
import Alarms from '../modules/Reports/communication/alarms/Alarms';
import Tachometer from '../modules/Reports/motorista/tachometer/Tachometer';
import LatitudeLongitude from '../modules/Reports/communication/latitudeLongitude/LatitudeLongitude';
import MovPerDriver from '../modules/Reports/communication/movPerDriver/MovPerDriver';
import MovPerAsset from '../modules/Reports/communication/movPerAsset/MovPerAsset';
import AtlasEvents from '../modules/Reports/assets/atlasEvents/AtlasEvents';
import AtlasMacros from '../modules/Reports/assets/atlasMacros/AtlasMacros';
import TabletEvents from '../modules/Reports/journey/tabletEvents/TabletEvents';
import GroupByDrivers from '../modules/Reports/evidence/groupByDrivers/GroupByDrivers';
import GroupByAssets from '../modules/Reports/evidence/groupByAssets/GroupByAssets';
import ByDrivers from '../modules/Reports/evidence/byDrivers/ByDrivers';
import ByAssets from '../modules/Reports/evidence/byAssets/ByAssets';
import ByUsers from '../modules/Reports/evidence/byUsers/ByUsers';

const ReportRoutes = {
  data: [
    { sectionId: 100, path: '/reports/communication/positions', component: Positions },
    { sectionId: 100, path: '/reports/communication/ngreports', component: NGEvents },
    { sectionId: 100, path: '/reports/communication/lastPositions', component: LastPositions },
    { sectionId: 100, path: '/reports/mfis/statusJourneyControl', component: StatusJourneyControl },
    { sectionId: 100, path: '/reports/mfis/situationJourneyControl', component: SituationJourneyControl },
    { sectionId: 100, path: '/reports/mfis/statusSpokenRoute', component: StatusSpokenRoute },
    { sectionId: 100, path: '/reports/mfis/situationSpokenRoute', component: SituationSpokenRoute },
    { sectionId: 11, path: '/reports/journey/tabletEvents', component: TabletEvents },
    { sectionId: 100, path: '/reports/journey/jcMacros', component: CJMacros },
    { sectionId: 100, path: '/reports/journey/spokenRoute', component: SpokenRoute },
    { sectionId: 100, path: '/reports/journey/driverLogin', component: DriverLogin },
    { sectionId: 100, path: '/reports/journey/macrosPerAsset', component: MacrosPerAsset },
    { sectionId: 100, path: '/reports/drivers/assetsDrivers', component: AssetsDrivers },
    { sectionId: 100, path: '/reports/drivers/assetsPerDrivers', component: AssetsPerDrivers },
    { sectionId: 100, path: '/reports/drivers/drivers', component: Driver },
    { sectionId: 100, path: '/reports/drivers/driverWOAssets', component: DriverWOAssets },
    { sectionId: 100, path: '/reports/drivers/driverWONFC', component: DriverWONFC },
    { sectionId: 100, path: '/reports/drivers/driverWithNFC', component: DriverWithNFC },
    { sectionId: 100, path: '/reports/drivers/tachometer', component: Tachometer },
    { sectionId: 100, path: '/reports/nfc/list', component: Nfc },
    { sectionId: 100, path: '/reports/assets/list', component: Asset },
    { sectionId: 100, path: '/reports/assets/messages', component: Message },
    { sectionId: 100, path: '/reports/assets/atlas', component: Atlas },
    { sectionId: 100, path: '/reports/assets/atlasEvents', component: AtlasEvents },
    { sectionId: 100, path: '/reports/assets/atlasMacros', component: AtlasMacros },

    { sectionId: 100, path: '/reports/devices/list', component: Device },
    { sectionId: 100, path: '/reports/users/usersPerCompany', component: UsersPerCompany },
    { sectionId: 100, path: '/reports/users/assets', component: Assets },
    { sectionId: 100, path: '/reports/security/roles', component: Role },
    { sectionId: 100, path: '/reports/security/usersPerRol', component: UsersPerRol },
    { sectionId: 100, path: '/reports/communication/sensors', component: Sensors },
    { sectionId: 100, path: '/reports/communication/alarms', component: Alarms },
    { sectionId: 100, path: '/reports/communication/latitudelongitude', component: LatitudeLongitude },
    { sectionId: 100, path: '/reports/communication/movPerDriver', component: MovPerDriver },
    { sectionId: 100, path: '/reports/communication/movPerAsset', component: MovPerAsset },
    { sectionId: 100, path: '/reports/evidence/groupByDrivers', component: GroupByDrivers },
    { sectionId: 100, path: '/reports/evidence/groupByAssets', component: GroupByAssets },
    { sectionId: 100, path: '/reports/evidence/byDrivers', component: ByDrivers },
    { sectionId: 100, path: '/reports/evidence/byAssets', component: ByAssets },
    { sectionId: 100, path: '/reports/evidence/byUsers', component: ByUsers },
  ],
};

export default ReportRoutes;
