import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import { TableProvider } from '../../../components/Table/TableContext';
import PortletIcon from '../../../components/Portlet/PortletIcon';
import TablePaper from '../../../components/Table/TablePaper';
import { Loading } from '../../../../Splash';

import { CardContent } from '@material-ui/core';
import TabletMacIcon from '@material-ui/icons/TabletMac';

import { useIntl } from '../../../lang/context/intlContext';

import { getAllJourneyControlStatus } from './api/getMethods';
import { useStoreState, useStoreActions } from 'easy-peasy';

const useStyleGrid = makeStyles((theme) => ({
  proto: { maxHeight: 'calc(100vh-600px)' },
  content: {
    display: 'flex',
    justifyContent: 'center',
    height: theme.spacing(45),
  },
  alert: { margin: theme.spacing(2) },
}));

function Status(props) {
  const { i18 } = useIntl();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [dataTablets, setDataTablets] = useState([]);
  const classes = useStyleGrid();

  const { name } = useStoreState((state) => state.journeyControl);

  const { cleanUpSubHeader, setPrimaryButton } = useStoreActions((actions) => actions.globalState);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const res = await getAllJourneyControlStatus();
      const { tabletsStatus, error } = res.data;
      if (error > 0) setError(true);
      setDataTablets(tabletsStatus);
      setIsLoading(false);
    } catch (error) {
      setError(true);
    }
  };
  //#region Effects
  useEffect(() => {
    setPrimaryButton({
      text: i18('refresh'),
      isEnabled: true,
      fnc: fetchData,
      disabled: false,
    });
    fetchData();
    return () => {
      cleanUpSubHeader();
    };
  }, []);

  //#endregion
  //#region Error and Redirect
  if (isLoading)
    return (
      <GridContainer className={classes.content}>
        <GridItem item xs={12} md={12} lg={12}>
          <Loading></Loading>
        </GridItem>{' '}
      </GridContainer>
    );
  if (error) return <p>Error!</p>;

  //#endregion
  //#region TableDefinitions

  const headCellsTablets = [
    {
      id: 'rootField',
      numeric: false,
      align: 'left',
      disablePadding: true,
      label: i18('Plate'),
      width: '100px',
    },
    { id: 'tablet', numeric: true, align: 'left', disablePadding: false, label: i18('tablet'), width: '100px' },
    { id: 'name', numeric: true, align: 'left', disablePadding: false, label: i18('JOURNEYCONTROL'), width: '200px' },
    { id: 'status', numeric: true, align: 'left', disablePadding: false, label: i18('status'), width: '100px' },
    { id: 'userName', numeric: true, align: 'left', disablePadding: false, label: i18('User'), width: '100px' },
    {
      id: 'published_at',
      numeric: true,
      align: 'left',
      disablePadding: false,
      label: i18('PUBLISHED_AT'),
      width: '180px',
      defaultOrder: true,
      sort: 'Desc',
    },
    { id: 'updated_at', numeric: true, align: 'left', disablePadding: false, label: i18('UPDATED_AT'), width: '180px' },
    { id: 'client', numeric: true, align: 'left', disablePadding: false, label: i18('Client'), width: 'auto' },
  ];

  const filterInputs = [
    { label: 'plate', field: 'rootField' },
    { label: 'tablet', field: 'tablet' },
    { label: 'JOURNEYCONTROL', field: 'name' },
  ];
  function onTableReady(params) {
    const { api } = params;
  }

  //#endregion
  return (
    <GridContainer className={classes.content}>
      <GridItem item xs={12} md={12} lg={12}>
        <PortletIcon title={i18('TABLETS_STATUS_JC')} subTitle={i18('TABLETS_STATUS_JC_SUBHEADER') + ': ' + name} hasCustomStartIcon={true} StartIcon={TabletMacIcon}>
          <CardContent style={{ paddingTop: '0px' }}>
            <GridContainer direction="column" spacing={2}>
              <GridItem item xs={12} md={12}>
                <TableProvider loadData={dataTablets} columns={headCellsTablets} title="Status" checkedItems={[]} initRowsPerPage={30} withPagination={true} onContextReady={onTableReady} useV2={true}>
                  <TablePaper maxHeight={'450px'} filterInputs={filterInputs} hasCheckBox={false} hasGroups={true} hasSearchAllFields={true}></TablePaper>
                </TableProvider>
              </GridItem>
            </GridContainer>
          </CardContent>
          <></>
          {/*<PortletActions> Si se activa portleet icons, eliminar la linea anterios
               <div className={classes.div}>
                  <div className={classes.inDiv}>
                    <Button variant="contained" disabled={putLoading} color={'cancel'}>
                      {i18('Cancel')}
                    </Button>
                  </div>
                  <div className={classes.inDiv}>
                    <Button type="submit" disabled={putLoading} variant="contained" color={'primary'}>
                      {i18('Create')}
                    </Button>
                    {putLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </div>
                </div> 
              </PortletActions>*/}
          {/* {(putError || apiError) && (
                <Alert className={classes.alert} severity="error">
                  {i18('API_ERR')}
                </Alert>
              )} */}
        </PortletIcon>
      </GridItem>
    </GridContainer>
  );
}

export default Status;
