//#region imports
import React, { useState, useEffect } from 'react';
//#region MUI
import { CardContent, CircularProgress, Typography } from '@material-ui/core';
//#endregion

//utils
import { useIntl } from '../../../lang/context/intlContext'; //addjust path reference
import { useForm } from 'react-hook-form';
//#region components

import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import Portlet from '../../../components/Portlet/Portlet';
import InputText from '../../../components/InputText/InputText';
import PortletActions from '../../../components/Portlet/PorletActions';
import Button from '../../../components/CustomButton/Button';
import { FormNameIcon } from '../../../../assets/icons/formIcons';
//#endregion

//ApiMethods
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';

//state
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useFormStyle } from '../../../common/styles/formStyle';
import ComboBox from '../../../components/ComboBox/ComboBox';
import CustomizedSnackbars from '../../../components/CustomSnackBar/CustomSnackBar';

import { postAddVehicle, postUpdateVehicle } from '../api/postMethods';
import { CAN_BUS, COLOR, COUNTRIES, driversType, FUEL, POWER_SUPPLY, YEARS } from './helper';
//#endregion

const schema = yup.object().shape({
  plates: yup.string().required(),
  vin: yup.string().required(),
  carMake: yup.string().required(),
  carModel: yup.string().required(),
  deviceId: yup.string().required(),
  function: yup.string().required(),
  canBus: yup.string().required(),
  year: yup.string().required(),
  color: yup.string().required(),
  power: yup.string().required(),
  gas: yup.string().required(),
  countryId: yup.string().required(),
});

const getModePage = ({ path }) => {
  if (path.indexOf('edit') > -1) return 'EDIT';
  if (path.indexOf('add-device') > -1) return 'ADD_DEVICE';
  return 'NEW';
};
const New = ({ history, match }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [openSnack, setOpenSnack] = React.useState({ open: false, title: '', severity: 'success' });
  const [devicesByClient, setDevicesByClient] = useState([]);
  const classes = useFormStyle();
  const { i18 } = useIntl();
  const { deviceAgentId = 0, agentId = 0 } = match.params;
  const modePage = getModePage(match);

  const { register, handleSubmit, errors, setValue } = useForm({ resolver: yupResolver(schema) });
  //#region easypeasy
  const setNoButtons = useStoreActions((actions) => actions.globalState.setNoButtons);
  const setPrimaryButton = useStoreActions((actions) => actions.globalState.setPrimaryButton);
  const onAddDeviceToAsset = useStoreActions((actions) => actions.user.onAddDeviceToAsset);
  const vehicles = useStoreState((state) => state.vehicle.vehicles);
  const { clientId, clients, security, devices: allDevices, functions } = useStoreState((state) => state.user);

  //#endregion

  const vehicle = modePage === 'NEW' ? undefined : vehicles.find((item) => item.deviceAgentId === +deviceAgentId);
  let deviceFunction = [];
  let devices = [];

  if (vehicle && modePage === 'ADD_DEVICE') {
    // not new vehicle
    devices = allDevices.filter((item) => !item.inAsset);
    const { agentId: vehicleAgentId } = vehicle;
    const vehicleFunctions = vehicles.filter((item) => item.agentId === vehicleAgentId).map((item) => item.functionId);
    deviceFunction = functions.map((item) => ({ value: item.functionId, text: item.name })).filter((item) => !vehicleFunctions.some((item2) => +item2 === +item.value));
  } else {
    // new vehicle
    deviceFunction = functions.map((item) => ({ value: item.functionId, text: item.name }));
    devices = allDevices.filter((item) => !item.inAsset);
  }
  // Rules: when is no add_device mode, get all the device and function attached with current vehicle and  not show in function combo

  // store combo values
  useEffect(() => {
    register({ name: 'clientId' }, { required: false });
    register({ name: 'deviceId' }, { required: true });
    register({ name: 'function' }, { required: true });
    register({ name: 'canBus' }, { required: true });
    register({ name: 'year' }, { required: true });
    register({ name: 'color' }, { required: true });
    register({ name: 'power' }, { required: true });
    register({ name: 'gas' }, { required: true });
    register({ name: 'countryId' }, { required: true });
    register({ name: 'secCompany' }, { required: false });
    register({ name: 'riskCompany' }, { required: false });

    if (vehicle) {
      // this is Edit Vehicle mode
      setValue('clientId', +vehicle.clientId);
      if (modePage === 'EDIT') setValue('deviceId', +vehicle.deviceId);
      if (modePage === 'EDIT') setValue('function', +vehicle.functionId);
      setValue('canBus', +vehicle.canBusId);
      +vehicle.year > 0 && setValue('year', +vehicle.year);
      +vehicle.color > 0 && setValue('color', +vehicle.color);
      setValue('power', +vehicle.powerId);
      setValue('gas', +vehicle.fuelId);
      setValue('countryId', +vehicle.countryId);
      setValue('secCompany', +vehicle.secCompanyId);
      setValue('riskCompany', +vehicle.riskCompanyId);

      setDevicesByClient(devices.filter((item) => item.clientId === +vehicle.clientId));
    } else {
      setValue('clientId', vehicle ? vehicle.clientId.toString() : clientId);
      setDevicesByClient(devices.filter((item) => item.clientId === +clientId));
    }

    setPrimaryButton({
      text: i18('BACK'),
      isEnabled: true,
      fnc: () => {
        history.push(`/administration/vehicles/management`);
      },
    });
    return () => setNoButtons();
  }, [modePage, vehicle]);

  const handleComboChange = (id) => {
    return (e) => {
      if (id === 'clientId') {
        setDevicesByClient(devices.filter((item) => item.clientId === +e.target.value));
      }
      setValue(id, e.target.value, {
        shouldValidate: true,
        shouldDirty: true,
      });
    };
  };
  const onSubmit = async (datos) => {
    try {
      setIsLoading(true);
      let res;
      let data;
      if (modePage === 'NEW') {
        res = await postAddVehicle(datos);
        data = res.data;
      }

      if (modePage === 'EDIT' || modePage === 'ADD_DEVICE') {
        const { deviceAgentId, agentId, transportId } = vehicle;
        const body = { ...datos, modePage, deviceAgentId, agentId, transportId };
        res = await postUpdateVehicle(body);
        data = res.data;
        if (data.error === 0 && modePage === 'ADD_DEVICE') {
          //in this case, remove device
          onAddDeviceToAsset(vehicle.deviceId);
        }
      }
      if (data.error !== 0) setOpenSnack((prev) => ({ ...prev, open: true, title: i18('ERROR_API'), severity: 'error' }));
      setIsLoading(false);
      history.push(`/administration/vehicles/management/reload`);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setOpenSnack((prev) => ({ ...prev, open: true, title: i18('ERROR_API'), severity: 'error' }));
    }
  };

  const getTitle = (modePage, i18) => {
    switch (modePage) {
      case 'NEW':
        return i18('NEW_VEHICLE');
      case 'EDIT':
        return i18('EDIT_VEHICLE');
      case 'CHANGE_DEVICE':
        return i18('CHANGE_DEVICE');
      case 'ADD_DEVICE':
        return i18('ADD_DEVICE');
      default:
        return '';
    }
  };
  const isDisabled = (modePages, modePage) => modePages.filter((item) => modePage === item).length;

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <GridContainer className={classes.content}>
          <GridItem item xs={12} md={9} lg={9} className={classes.content}>
            <Portlet className={classes.formStyle} title={getTitle(modePage, i18)} subTitle={i18('CREATE_SUBHEADER_JC')}>
              <CardContent>
                <GridContainer spacing={2}>
                  {/* Line 1 */}
                  <GridItem item xs={4} md={4} lg={4}>
                    <ComboBox label={i18('Client')} icon={FormNameIcon} disabled={isDisabled(['EDIT'], modePage)} name="clientId" defaultValue={vehicle ? vehicle?.clientId.toString() : clientId} onChange={handleComboChange('clientId')} options={clients}></ComboBox>
                  </GridItem>
                  <GridItem item xs={4} md={4} lg={4}>
                    <ComboBox label={i18('device')} icon={FormNameIcon} name="deviceId" disabled={isDisabled(['EDIT'], modePage)} defaultValue={vehicle ? vehicle?.deviceId : '0'} onChange={handleComboChange('deviceId')} options={devicesByClient}></ComboBox>

                    {errors.deviceId && <Typography color="secondary">{i18('required')}</Typography>}
                  </GridItem>
                  <GridItem item md={4} lg={4}>
                    <ComboBox label={i18('function')} icon={FormNameIcon} name="function" disabled={isDisabled(['EDIT'], modePage)} defaultValue={vehicle ? vehicle?.functionId.toString() : '0'} onChange={handleComboChange('function')} options={deviceFunction}></ComboBox>
                    {errors.function && <Typography color="secondary">{i18('required')}</Typography>}
                  </GridItem>
                  {/* Line 2  */}
                  <GridItem item md={4} lg={4}>
                    <InputText id="plates" name="plates" disabled={isDisabled(['CHANGE_DEVICE', 'ADD_DEVICE'], modePage)} defaultValue={vehicle?.plates} label={i18('plate')} inputRef={register({ required: true })} icon={FormNameIcon} fullWidth></InputText>
                    {errors.plates && <Typography color="secondary">{i18('REQuired')}</Typography>}
                  </GridItem>
                  <GridItem item xs={12} md={4} lg={4}>
                    <ComboBox label={i18('CAN_TYPE')} icon={FormNameIcon} name="canBus" disabled={isDisabled(['CHANGE_DEVICE', 'ADD_DEVICE'], modePage)} defaultValue={vehicle ? vehicle?.canBusId.toString() : '-1'} onChange={handleComboChange('canBus')} options={CAN_BUS}></ComboBox>
                    {errors.canBus && <Typography color="secondary">{i18('required')}</Typography>}
                  </GridItem>
                  <GridItem item xs={12} md={4} lg={4}>
                    <ComboBox label={i18('country')} icon={FormNameIcon} name="countryId" disabled={isDisabled(['CHANGE_DEVICE', 'ADD_DEVICE'], modePage)} defaultValue={vehicle ? vehicle?.countryId.toString() : '-1'} onChange={handleComboChange('countryId')} options={COUNTRIES}></ComboBox>
                    {errors.countryId && <Typography color="secondary">{i18('required')}</Typography>}
                  </GridItem>
                  <GridItem item md={4} lg={4}>
                    <InputText id="vin" name="vin" defaultValue={vehicle?.vin} disabled={isDisabled(['CHANGE_DEVICE', 'ADD_DEVICE'], modePage)} inputRef={register({ required: true })} label={i18('vin')} icon={FormNameIcon} fullWidth></InputText>
                    {errors.vin && <Typography color="secondary">{i18('REQuired')}</Typography>}
                  </GridItem>
                  {/* Line 3  */}
                  <GridItem item md={4} lg={4}>
                    <InputText id="carMake" defaultValue={vehicle?.brand} name="carMake" disabled={isDisabled(['CHANGE_DEVICE', 'ADD_DEVICE'], modePage)} inputRef={register({ required: true })} label={i18('carMake')} icon={FormNameIcon} fullWidth></InputText>
                    {errors.carMake && <Typography color="secondary">{i18('REQuired')}</Typography>}
                  </GridItem>
                  <GridItem item md={4} lg={4}>
                    <InputText id="carModel" name="carModel" disabled={isDisabled(['CHANGE_DEVICE', 'ADD_DEVICE'], modePage)} inputRef={register({ required: true })} defaultValue={vehicle?.modelVehicle} label={i18('carModel')} icon={FormNameIcon} fullWidth></InputText>
                    {errors.carModel && <Typography color="secondary">{i18('REQuired')}</Typography>}
                  </GridItem>
                  <GridItem item md={4} lg={4}>
                    <ComboBox label={i18('year')} icon={FormNameIcon} name="year" disabled={isDisabled(['CHANGE_DEVICE', 'ADD_DEVICE'], modePage)} defaultValue={vehicle ? vehicle?.year.toString() : '-1'} onChange={handleComboChange('year')} options={YEARS()} placeholder={i18('year')}></ComboBox>
                    {errors.year && <Typography color="secondary">{i18('required')}</Typography>}
                  </GridItem>

                  {/* Line 4  */}
                  <GridItem item md={4} lg={4}>
                    <ComboBox label={i18('color')} icon={FormNameIcon} name="color" disabled={isDisabled(['CHANGE_DEVICE', 'ADD_DEVICE'], modePage)} defaultValue={vehicle ? vehicle?.color.toString() : '0'} onChange={handleComboChange('color')} options={COLOR} placeholder={i18('color')}></ComboBox>
                    {errors.color && <Typography color="secondary">{i18('required')}</Typography>}
                  </GridItem>
                  <GridItem item md={4} lg={4}>
                    <ComboBox label={i18('power')} icon={FormNameIcon} name="power" disabled={isDisabled(['CHANGE_DEVICE', 'ADD_DEVICE'], modePage)} defaultValue={vehicle ? vehicle?.powerId.toString() : '-1'} onChange={handleComboChange('power')} options={POWER_SUPPLY} placeholder={i18('power')}></ComboBox>
                    {errors.power && <Typography color="secondary">{i18('required')}</Typography>}
                  </GridItem>
                  <GridItem item xs={12} md={4} lg={4}>
                    <ComboBox label={i18('gas')} icon={FormNameIcon} name="gas" disabled={isDisabled(['CHANGE_DEVICE', 'ADD_DEVICE'], modePage)} defaultValue={vehicle ? vehicle?.fuelId.toString() : '-1'} onChange={handleComboChange('gas')} options={FUEL} placeholder={i18('gas')}></ComboBox>
                    {errors.gas && <Typography color="secondary">{i18('required')}</Typography>}
                  </GridItem>

                  {/* Line 5  */}

                  {/* Line 6  */}

                  <GridItem item xs={12} md={4} lg={4}>
                    <InputText id="comments" name="comments" disabled={isDisabled(['CHANGE_DEVICE', 'ADD_DEVICE'], modePage)} inputRef={register({ required: false })} defaultValue={vehicle?.comments} label={i18('comments')} icon={FormNameIcon} fullWidth></InputText>
                  </GridItem>
                  <GridItem item md={4} lg={4}>
                    <InputText id="alias" name="alias" disabled={isDisabled(['CHANGE_DEVICE', 'ADD_DEVICE'], modePage)} inputRef={register({ required: false })} defaultValue={vehicle?.vehicleName} label={i18('SOBRENOMBRE')} icon={FormNameIcon} fullWidth></InputText>
                  </GridItem>
                  <GridItem item xs={12} md={6} lg={6}>
                    <ComboBox
                      label={i18('secCompany')}
                      icon={FormNameIcon}
                      name="secCompany"
                      disabled={isDisabled(['CHANGE_DEVICE', 'ADD_DEVICE'], modePage)}
                      defaultValue={vehicle ? vehicle?.secCompanyId.toString() : '-1'}
                      onChange={handleComboChange('secCompany')}
                      options={security}
                      placeholder={i18('secCompany')}
                    ></ComboBox>
                  </GridItem>
                  <GridItem item xs={12} md={6} lg={6}>
                    <ComboBox
                      label={i18('riskCompany')}
                      icon={FormNameIcon}
                      name="riskCompany"
                      disabled={isDisabled(['CHANGE_DEVICE', 'ADD_DEVICE'], modePage)}
                      defaultValue={vehicle ? vehicle?.riskCompanyId.toString() : '-1'}
                      onChange={handleComboChange('riskCompany')}
                      options={clients}
                      placeholder={i18('riskCompany')}
                    ></ComboBox>
                  </GridItem>
                </GridContainer>
              </CardContent>
              <PortletActions>
                <div className={classes.div}>
                  <div className={classes.inDiv}>
                    <Button variant="contained" disabled={isLoading} color={'cancel'} onClick={() => history.push(`/administration/vehicles/management`)}>
                      {i18('Cancel')}
                    </Button>
                  </div>
                  <div className={classes.inDiv}>
                    <Button type="submit" disabled={isLoading} variant="contained" color={'primary'}>
                      {modePage === 'NEW' ? i18('Create') : i18('Save')}
                    </Button>
                    {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </div>
                </div>
              </PortletActions>
            </Portlet>
          </GridItem>
        </GridContainer>
      </form>
      <CustomizedSnackbars open={openSnack.open} setOpen={setOpenSnack} title={openSnack.title} severity={openSnack.severity}></CustomizedSnackbars>
    </div>
  );
};

export default New;
