import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { AgregaIcon } from '../../../../../assets/icons/index';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { drawerWidth, sumDrawers } from '../../../constants';
import Button from '../../../../components/CustomButton/Button';

//State
import { useStoreState } from 'easy-peasy';
import { WHITE } from '../../../../components/styles/constValues';
import { CircularProgress, LinearProgress } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#fffff',
    height: theme.spacing(11),
    flexDirection: 'column',
  },
  rootShift: {
    display: 'flex',
    width: `calc(100% - ${sumDrawers}px)`,
    backgroundColor: '#fffff',
    height: theme.spacing(11),
  },
  subH: {
    width: '100%',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  subHShift: {
    width: '100%',
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  linearWidth: {
    width: `100%`,
  },
  content: {
    padding: theme.spacing(1),

    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    // width: '100%',
  },
  toolbar: {
    // paddingRight: 24,
    width: '100%',
  },
  subTitle: {
    paddingLeft: '4px',
  },
  spacer: {
    flexGrow: 1,
  },
  marginButton: {
    marginRight: theme.spacing(2),
  },
  secondaryButton: { marginRight: theme.spacing(2), justifyContent: 'center', paddingLeft: theme.spacing(1) },
  addIconStyle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: '4px',
    marginLeft: '4px',
  },
  buttonLoading: {
    display: 'flex',
    // justifyContent: 'flex-end',
    flexDirection: 'row',
    justifyContent: 'end',
    // padding: theme.spacing(4),
  },
  buttonNormal: {
    padding: theme.spacing(4),
    position: 'relative',
  },
  buttonLoader: {
    color: theme.palette.primary.dark[500],
    position: 'absolute',
    // top: '50%',
    // left: '50%',
    // marginTop: -12,
    // marginLeft: -12,
  },
}));

export default function SubHeader(props) {
  const classes = useStyles();
  const { open, subTitle } = props;
  const [isLoading, setIsLoading] = React.useState(false);
  const { globalState } = useStoreState((state) => state);
  const {
    subHeader: { primaryButton, secondaryButton },
  } = globalState;
  const { Icon } = secondaryButton;
  const handleClickSecondaryButton = async () => {
    if (!secondaryButton) return;
    if (typeof secondaryButton.fnc !== 'function') return;
    setIsLoading(true);
    setTimeout(async () => {
      await secondaryButton.fnc();
      setIsLoading(false);
    }, 600);
  };
  return (
    // <div className={clsx(classes.root, classes.subH, open && classes.subHShift)}>
    <div>
      <div className={clsx(classes.root, open && classes.rootShift)}>
        <Toolbar className={classes.toolbar}>
          {' '}
          <Typography component="h1" variant="h5" noWrap className={classes.subTitle}>
            {globalState.hasSubHeaderText ? globalState.subHeaderText : subTitle}
          </Typography>
          <span className={classes.spacer} />
          {secondaryButton.isEnabled && (
            <Button
              // variant="contained"
              color="secondary"
              className={classes.secondaryButton}
              onClick={handleClickSecondaryButton}
              startIcon={Icon ? <Icon className={classes.addIconStyle} /> : <AgregaIcon className={classes.addIconStyle} />}
              // onClick={secondaryButton.fnc}
              disabled={secondaryButton.disabled || false || isLoading === true}
            >
              {secondaryButton.text}
            </Button>
          )}
          {primaryButton.isEnabled && (
            <div className={classes.buttonLoading}>
              <div className={classes.buttonNormal}>
                <Button
                  onClick={primaryButton.fnc}
                  color="primary"
                  className={classes.marginButton}
                  disabled={primaryButton.disabled || false}
                >
                  {primaryButton.text}
                </Button>
              </div>
            </div>
          )}
        </Toolbar>
        {/* <LinearProgress className={classes.linearWidth} /> */}
        {isLoading && <LinearProgress className={classes.linearWidth} />}
      </div>
    </div>
  );
}
