import { format, addDays } from 'date-fns';
import { patterHeaderStyle, patternCellStyle } from '../../table/stylesReport';

const date = new Date();
const now = format(addDays(date, -1), "yyyy-MM-dd'T'HH:MM");
const now24 = format(date, "yyyy-MM-dd'T'HH:MM");
export const maxWeeks = 7;

export const initFilterTabletEvents = {
  startDateTime: now,
  endDateTime: now24,
};

export const listEvents = [
  { id: 0, name: 'TODOS' },
  { id: 1, name: 'LOGIN_TABLET' },
  { id: 2, name: 'GENERAL' },
  { id: 3, name: 'CONTROL_DE_JORNADA' },
  { id: 4, name: 'SEQUENCYMACRO' },
  { id: 5, name: 'SPOKENREGIONS' },
  { id: 10, name: 'IGNITION_ON' },
  { id: 11, name: 'IGNITION_OFF' },
  { id: 1001, name: 'POSITIONS' },
];

export const columnsTabletEvents = [
  {
    title: 'ASSETS',
    field: 'plates',
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'DATE_HOUR_DEV',
    field: 'gpsDateTime',
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'DATE_HOUR_RECEIVED',
    field: 'rcvDateTime',
    cellStyle: {
      ...patternCellStyle,
      width: '11%',
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'VALID_GPS',
    field: 'isValidGPS',
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'IGNITION',
    field: 'ignition',
    width: null,
    filtering: false,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'DRIVER_ID',
    field: 'driverIdDriver',
    cellStyle: {
      width: null,
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'LATITUDE',
    field: 'latitude',
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'LONGITUDE',
    field: 'longitude',
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'DETALLES',
    field: 'details',
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
];
