import axios from 'axios';
const INVALID_EVIDENCE = '/evidences/invalidEvidence?trackingEvidenceId=';
const GET_RECONCILE = '/evidences/getReconcile';
const GET_TABLE_RECONCILES = '/evidences/getTableReconciles';

interface Params {
  startDateTime: string;
  endDateTime: string;
  limit?: number;
  type?: [];
  level?: [];
  assets?: [];
  status?: [];
}
interface ApiRes {
  error: number;
}
interface Reconcile {
  reconcileId: number | null;
  trackingEvidenceId: number;
  driverId: number;
  evidenceType: number;
  driverFullName: string;
  driverName: string;
  status: number;
  created_at: string;
  asset: string;
  created_by: number | null;
  userName: string;
  plates: string;
  urlVideoFile: string;
  evidenceId: number;
  name: string;
  idLevel: number;
  levelName: string;
  gpsSpeed: number;
  statusName: string;
  isVideoAvailable: number;
  reconcileType: number;
  latitude: number;
  longitude: number;
  address: string;
  description: string;
}
interface ReconcileResponse extends Reconcile {
  keyId: number;
  keyParentId: number;
  keyCellId: string;
  rootField: number;
  hasChilds: false;
  isFinal: true;
  level: number;
  nextLevel: [];
}
interface TableReconcileResponse {
  apiRes: ApiRes;
  reconciles: ReconcileResponse[];
}
interface TableReconcile extends Reconcile {
  fields: Reconcile;
  keyId: number;
  keyParentId: number;
  keyCellId: string;
  rootField: number;
  hasChilds: false;
  isFinal: true;
  level: number;
  nextLevel: [];
}

export async function getReconciles(body: Params): Promise<TableReconcile[]> {
  // const query = `?limit=${limit}&type=${type.join(',')}&level=${level.join(',')}&assets=${assets.join(',')}&status=${status.join(',')}&startDateTime=${startDateTime}&endDateTime=${endDateTime}`;

  const { data } = await axios.post<TableReconcileResponse>(`${GET_TABLE_RECONCILES}`, body);
  return formatReconciles(data);
}

function formatReconciles(data: TableReconcileResponse): TableReconcile[] {
  return data.reconciles.map((item) => {
    const newItem: TableReconcile = {
      ...item,
      fields: item,
    };
    return newItem;
  });
}
