//#region Imports
import React, { useState, useEffect } from 'react';

import { motion } from 'framer-motion';

import { useIntl } from '../../../lang/context/intlContext';

import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import CustomCard from '../../../components/CustomCard/Card';
import { v4 as uuidv4 } from 'uuid';
//state
import { useStoreActions, useStoreState } from 'easy-peasy';
import { GET_SPOKEN_ROUTES_BYUSERID, getSpokenRouteById } from './api/getMethods';
import { Loading } from '../../../../Splash';
import useAxios from 'axios-hooks';
import { postEnableDisable } from './api/postMethods';

//#endregion
const useStyle = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  text: { fontSize: '20px', fontWeight: 'normal' },
  cardStyle: { maxWidth: theme.spacing(30) },
  content: {},
  gridContainer: {
    marginTop: theme.spacing(3),
  },
}));

const buttonSelectText = (status) => {
  if (status === 0) return 'Continue';
  if (status === 1) return 'Editable';
  if (status === 2) return 'locked';
  return 'Disabled';
};
const Management = ({ history, match }) => {
  const classes = useStyle();
  const { i18 } = useIntl();
  const [eloading, setEloading] = useState(0);
  const { setButtons, publish, continueEdition } = useStoreActions((actions) => {
    const { rotograma, globalState } = actions;
    return { ...rotograma, ...globalState };
  });
  const [{ data: getData, loading: getLoading, error: getError }, refetch] = useAxios(GET_SPOKEN_ROUTES_BYUSERID);

  const [selectedCard, setSelectedCard] = useState(undefined);

  const headerMenuWithCard = (selectedCard) => {
    if (!selectedCard) return [];
    return [
      {
        name: 'DUplicate',
        push: '/mfis/rotograma/management/all/duplication',
      },
      {
        name: 'Archive',
        push: '/mfis/rotograma/management/',
        post: () => postEnableDisable(selectedCard?.spokenRouteId, 1),
        action: 'post',
      },
    ];
  };
  const param = match.params.id;
  const buttonText = i18('RELEASE');

  useEffect(() => {
    refetch();
  }, [param]);

  useEffect(() => {
    let isDisabled = true;
    if (selectedCard) isDisabled = selectedCard.status < 1;
    const headerMenu = headerMenuWithCard(selectedCard);
    const primaryButton = { text: buttonText, isEnabled: true, fnc: () => execPublish(selectedCard), disabled: isDisabled };
    const secondaryButton = {
      text: i18('ADD_SPOKENROUTE'),
      isEnabled: true,
      disabled: false,
      fnc: () => history.push(`/mfis/rotograma/add`),
      // func: async () => {
      //   return new Promise((res, rej) => {
      //     setTimeout(() => {
      //       return res();
      //     }, 5000);
      //   });
      // },
    };
    const payload = { headerMenu, primaryButton, secondaryButton };
    setButtons(payload);

    // setHeaderMenu(headerMenu(selectedCard));
    // setPrimaryButton();
    // setSecondaryButton();
  }, [selectedCard]);

  const onSelectCard = (e, selectedCard) => {
    e.preventDefault();
    setSelectedCard(selectedCard);
  };

  const onClickSR = async (e, spokenRoute) => {
    setEloading(spokenRoute.spokenRouteId);
    e.preventDefault();
    const res = await getSpokenRouteById(spokenRoute.spokenRouteId);
    const { data } = res;
    setEloading(0);
    //update store
    continueEdition(data.spokenRoute);

    history.push(`/mfis/rotograma/configurations`);
    //redirect to select regions
  };

  const execPublish = (spokenRoute) => {
    const { spokenRouteId } = spokenRoute;
    if (spokenRouteId > 0) {
      console.log('toPublish', spokenRoute);
      publish(spokenRoute);
      history.push(`/mfis/rotograma/publish`);
    }
  };

  if (getLoading || !getData)
    return (
      <GridContainer className={classes.content}>
        <GridItem item xs={12} md={12} lg={12}>
          <Loading></Loading>
        </GridItem>{' '}
      </GridContainer>
    ); // <p>Loading...</p>;
  if (getError) return <p>getError</p>;

  const spokenRoutes = getData.data;
  const subText = i18('NUMBER_GROUPS');
  //if (add)
  // if (spokenRouteId === 0) return <Redirect to={`/mfis/rotograma/add`} />;
  return (
    <div className={classes.root}>
      <Typography component="h3" variant="h5" noWrap className={classes.text}>
        {i18('SPOKEN_ROUTES_LIST')}
      </Typography>
      <GridContainer className={classes.gridContainer} spacing={1}>
        {spokenRoutes
          .filter((item) => !item.isDisabled)
          .map((item, index) => {
            // const { withPreAlert, withAlert, withViolation } = item;
            // const preTexto = `${withPreAlert ? i18('pre_Alert') : ''} ${withAlert ? i18('Alert') : ''} ${
            //   withViolation ? i18('violation') : ''
            // }`;
            const finalText = `${subText}: ${item.cantidad}`;
            const texto = item.name;
            const selected = item.spokenRouteId === selectedCard?.spokenRouteId;
            const buttonText = i18(buttonSelectText(item.status));
            return (
              <GridItem key={uuidv4()} item xs>
                <motion.div whileHover={{ scale: 1.005 }} whileTap={{ scale: 0.9 }}>
                  <CustomCard
                    className={classes.cardStyle}
                    key={uuidv4()}
                    title={texto}
                    onSelect={onSelectCard}
                    selected={selected}
                    value={item}
                    align={'center'}
                    contentText={finalText}
                    hasAvatar
                    onClick={onClickSR}
                    buttonText={buttonText}
                    isLoading={eloading === item.spokenRouteId}
                  ></CustomCard>
                </motion.div>
              </GridItem>
            );
          })}
      </GridContainer>
    </div>
  );
};

export default Management;
