import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import Avatar from '@material-ui/core/Avatar';
import { IconButton, Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { drawerWidth } from '../../constants';
//Main Menu
import MainMenu from './MainMenu/MainMenu';
//store
import { useStoreActions, useStore } from 'easy-peasy';
const useStyles = makeStyles((theme) => ({
  menuButton: {
    backgroundColor: '#1b1344',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ...theme.mixins.toolbar,
  },
  menuLogout: {
    backgroundColor: '#1b1344',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    ...theme.mixins.toolbar,
  },
  menuIcon: {
    color: '#ffffff',
  },
  drawerBar: {
    height: '100%',
    backgroundColor: '#1b1344',
    width: drawerWidth,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
    // padding: theme.spacing(2),
  },
  drawerIcon: {
    backgroundColor: '#1b1344',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 0px',
    ...theme.mixins.toolbar,
    height: '119px',
  },
  drawerPaper: {
    height: '100vh',
    backgroundColor: '#1b1344',
    borderRight: 'none',
    flexShrink: 0,
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    alignItems: 'center',
    justifyContent: 'center',
  },
  avatar: {
    paddingTop: '30px',
    width: '49px',
    height: '119px',
  },
  avatarMenu: {
    backgroundColor: theme.palette.primary.dark,
    marginTop: theme.spacing(3),
  },
  avatarLogout: {
    backgroundColor: theme.palette.primary.dark,
    // marginTop: theme.spacing(3),
  },
}));

export default function Navigator(props) {
  const { onFailLogin: logout } = useStoreActions((actions) => actions.globalState);
  const store = useStore();
  const onClick = async (e) => {
    await store.persist.clear();
    window.location.href = '/Login';
  };
  const { onSidebarOpen } = props;
  const classes = useStyles();
  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(classes.drawerPaper),
      }}
      open={false}
    >
      <div className={classes.menuButton}>
        <IconButton onClick={onSidebarOpen} className={classes.avatarMenu}>
          <MenuIcon className={classes.menuIcon}>{/* <LockOutlinedIcon /> */}</MenuIcon>
        </IconButton>
      </div>
      {/* <div> */}
      <div className={classes.drawerIcon}>
        <Avatar className={classes.avatar} variant="square" src="/assets/images/logos/toolBar/MILLENIUM_T1.png">
          <LockOutlinedIcon />
        </Avatar>
        {/* <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton> */}
      </div>
      <div className={classes.drawerBar}>
        <MainMenu {...props} />
      </div>
      {/* </div> */}
      <div className={classes.menuLogout}>
        {/* <Typography>Logout</Typography> */}
        <IconButton
          // color="inherit"
          // onClick={onSidebarOpen}
          onClick={onClick}
          className={classes.avatarLogout}
        >
          <ExitToAppIcon className={classes.menuIcon}>{/* <LockOutlinedIcon /> */}</ExitToAppIcon>
        </IconButton>
      </div>

      {/* <Divider />
        <Divider />
        <List>{secondaryListItems}</List> */}
      {/* <div className={classes.menuLogout}>
        <IconButton
          className={classes.avatarMenu}
        >
          <MenuIcon className={classes.menuIcon}></MenuIcon>
        </IconButton>
      </div> */}
    </Drawer>
  );
}
