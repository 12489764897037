import React from 'react';
import PrivateRoute from '../auth/PrivateRoute';
import layoutMap from '../../app/layout/LayoutMap';
import layoutMapRegion from '../../app/layout/LayoutMapRegion';
import LayoutMapEvidence from '../layout/LayoutMapEvidence';

const HomeRoutes = () => {
  const toReturn = {
    data: [
      { sectionId: 1, path: '/home/asset/map', layout: layoutMap, component: null },
      { sectionId: 1, path: '/home/evidence/map', layout: LayoutMapEvidence, component: null },
      { sectionId: 2, path: '/home/region/map', layout: layoutMapRegion, component: null },
      { sectionId: 3, path: '/home/region/create', layout: layoutMapRegion, component: null },
    ],
  };

  return toReturn;
};

export default HomeRoutes;
