import { patterHeaderStyle, patternCellStyle } from '../../table/stylesReport';

export const columnsAssetsPerDrivers = [
  {
    title: 'NAME',
    defaultGroupOrder: 0,
    field: 'driverFullName',
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'ASSETS',
    field: 'plates',
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'SERIAL',
    field: 'serial',
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
];
