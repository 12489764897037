import React, { useState, useEffect } from 'react';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import { makeStyles } from '@material-ui/core/styles';
import { CardContent, Typography } from '@material-ui/core';

import { useIntl } from '../../lang/context/intlContext';

import useAxios from 'axios-hooks';
import Button from '../../components/CustomButton/Button';
import { TableProvider } from '../../components/Table/TableContext';
import TablePaper from '../../components/Table/TablePaper';
import { getOptionGroups } from '../../components/Table/helper';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Loading } from '../../../Splash';
import { getGJSONRegions, GET_ALL_USERS_REGIONS } from '../../api/getMethods';

import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { whiteColor } from '../../components/styles/constValues';

const useStyleGrid = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor: whiteColor,
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    height: theme.spacing(45),
  },
  alert: { margin: theme.spacing(2) },
  titleFilter: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  assetButton: {
    marginRight: theme.spacing(1),
  },
}));
//todo:load regions that are displayed on map
//todo: add circular loading in loadtomap button
function Regions({ maxHeight, openFilter, handleClose, Transition, handleDeleteGroup, handleCreateGroup, setopenFilter }) {
  console.time('getData');
  const { i18 } = useIntl();
  const [selectedRegionIds, setSelectedRegionIds] = useState([]);
  const [isLoadingRegions, setIsLoadingRegions] = useState(false);

  const classes = useStyleGrid();

  const regions = useStoreState((state) => state.region.regions);
  const addRegions = useStoreActions((actions) => actions.region.addRegions);
  const loadToGridMap = useStoreActions((actions) => actions.regionmap.loadToGridMap);
  const clearGrid = useStoreActions((actions) => actions.regionmap.clearGrid);

  const [{ data: getData, loading: getLoading }, getRegions] = useAxios(GET_ALL_USERS_REGIONS, { manual: true });

  useEffect(() => {
    console.log('useEffectMap');
    if (!regions.length) {
      console.log('no data');
      getRegions();
    }
  }, [regions]);
  useEffect(() => {
    if (getLoading) return;
    if (getData) {
      addRegions(getData.regions);
    }
  }, [getData, getLoading]);
  //#region Effects

  //#endregion

  //#region Error and Redirect

  if (getLoading || isLoadingRegions)
    return (
      <GridContainer className={classes.content}>
        <GridItem item xs={12} md={12} lg={12}>
          {/* <Loading></Loading> */}
        </GridItem>
      </GridContainer>
    );

  //#endregion
  //#region TableDefinitions
  const headCellsRegions = [
    {
      id: 'rootField',
      numeric: false,
      align: 'left',
      disablePadding: true,
      label: i18('GROUP/SUBGROUP/REGION'),
      width: '350px',
      defaultOrder: true,
    },
    { id: 'description', numeric: true, align: 'left', disablePadding: false, label: i18('Description'), width: '150px' },
    { id: 'typeShape', numeric: true, align: 'left', disablePadding: false, label: i18('typeShape'), width: '150px' },
    { id: 'typeRegionName', numeric: true, align: 'left', disablePadding: false, label: i18('typeRegionName'), width: '50px' },
    { id: 'radius', numeric: true, align: 'left', disablePadding: false, label: i18('radius'), width: '250px' },
    { id: 'OwnerName', numeric: true, align: 'left', disablePadding: false, label: i18('Client'), width: 'auto' },
    // { id: 'address', numeric: true, align: 'left', disablePadding: false, label: i18('address'), width: 'auto' },
  ];
  const optionsGroup = getOptionGroups(regions, true);

  const filterInputs = [{ label: 'name', field: 'rootField' }];

  function onTableReady(params) {
    const { api } = params;
    const { checkedRow } = api.getCheckedRowsWithFieldEval();
    setSelectedRegionIds(checkedRow);
  }

  //#endregion

  const handleLoadToGrid = async (checkedRow) => {
    const selectedIds = checkedRow;
    const regionIds = checkedRow.map((item) => item.regionId);
    if (regionIds.length > 0) {
      const res = await getGJSONRegions(regionIds);
      const gjsonRegions = res.data.GJSON;
      loadToGridMap({ selectedIds, gjsonRegions });
    } else {
      clearGrid();
    }
    setopenFilter(false);
  };

  return (
    <Dialog fullScreen open={openFilter} onClose={handleClose} TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="primary" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.titleFilter}>
            {i18('SELECT_REGIONS')}
          </Typography>
          <Button autoFocus color="primary" className={classes.assetButton} onClick={handleDeleteGroup}>
            {i18('Delete_GROUP')}
          </Button>

          <Button autoFocus color="primary" className={classes.assetButton} onClick={() => handleCreateGroup(selectedRegionIds)}>
            {i18('CREATE_GROUP')}
          </Button>
          <Button autoFocus color="primary" onClick={() => handleLoadToGrid(selectedRegionIds)}>
            {i18('LOAD_TO_MAP')}
          </Button>
        </Toolbar>
      </AppBar>
      <GridContainer className={classes.content}>
        <GridItem item xs={12} md={12} lg={12}>
          <CardContent style={{ width: '100%' }}>
            <GridContainer direction="column" spacing={2} style={{ paddingTop: '0px' }}>
              <GridItem item xs={12} md={12}>
                <TableProvider loadData={regions} columns={headCellsRegions} withPagination={true} title={i18('Regions')} checkedItems={[]} initRowsPerPage={50} onContextReady={onTableReady} useV2={true}>
                  <TablePaper maxHeight={maxHeight} filterInputs={filterInputs} hasCheckBox={true} hasGroups={true} optionsGroup={optionsGroup} hasSearchAllFields={true}></TablePaper>
                </TableProvider>
              </GridItem>
            </GridContainer>
          </CardContent>
        </GridItem>
      </GridContainer>
    </Dialog>
  );
}

export default Regions;
