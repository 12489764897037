import axios from 'axios';

const PUBLISH_RECONCILE = '/evidences/publishReconcile';
const UPDATE_RECONCILE = '/evidences/postUpdateReconcile';
const SEND_RECONCILE_NOTIFICATION = '/email/sendReconcile';

export function postPublishReconcile(body) {
  return axios.post(PUBLISH_RECONCILE, body);
}
export function postUpdateReconcile(body) {
  return axios.post(UPDATE_RECONCILE, body);
}
export function postSendReconcileNotification(body) {
  return axios.post(SEND_RECONCILE_NOTIFICATION, body);
}
