import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CardContent, CircularProgress, Tooltip, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Slider from '@material-ui/core/Slider';

import GridContainer from '../../../../components/Grid/GridContainer';
import GridItem from '../../../../components/Grid/GridItem';
import Button from '../../../../components/CustomButton/Button';
import Portlet from '../../../../components/Portlet/Portlet';
import PortletActions from '../../../../components/Portlet/PorletActions';
import { useFormStyle } from '../../../../common/styles/formStyle';
import { FormNameIcon, FormAlarmShortTime } from '../../../../../assets/icons/formIcons';

import { useForm } from 'react-hook-form';

import { useIntl } from '../../../../lang/context/intlContext';
import CustomCheck from '../../../../components/CustomCheck/CustomCheck';
import ComboBox from '../../../../components/ComboBox/ComboBox';
import { useStoreActions, useStoreState } from 'easy-peasy';
import InputMaskText from '../../../../components/InputMaskText/InputMaskText';
import { postConfiguration } from '../api/postMethods';
import { formatValue } from '../../../../../utils';

//TODO: Icons required:Time,Schedule/Day
//TODO: Slider control

const JourneyTime = ({ error = [], handleNextChange, isReadOnly = true }) => {
  const classes = useFormStyle();
  const { i18, getWeekDays } = useIntl();

  const [free, setfree] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, errors, setValue } = useForm();

  const [values, setValues] = useState({});
  const [breakingDay, setBreakingDay] = useState('MON');
  const [detectBeginJourney, setDetectBeginJourney] = useState(0);
  const [detectBeginJourneyChecks, setDetectBeginJourneyChecks] = useState({
    sendPos: false,
    sendAlertCabina: false,
    sendBlockEngine: false,
  });

  const journeyControlId = useStoreState((state) => state.journeyControl.journeyControlId);
  const durationTime = useStoreState((state) => state.journeyControl.durationTime);
  const setJourneyTime = useStoreActions((actions) => actions.journeyControl.setJourneyTime);
  useEffect(() => {
    setValues((values) => ({ ...durationTime }));
    setBreakingDay(durationTime.breakingDay);
    setDetectBeginJourney(durationTime.detectBeginJourney);
    setDetectBeginJourneyChecks(durationTime.detectBeginJourneyChecks);
  }, [durationTime]);
  const days = getWeekDays;

  //#region handlers
  const handleOnChanges = (e) => {
    const value = e.target.value;

    setValues((values) => ({
      ...values,
      [e.target.name]: value,
    }));
  };
  const handleDetectSlider = (e, newValue) => {
    setDetectBeginJourney(newValue);
  };
  const handleChecks = (e) => {
    const { name, value } = e.target;
    setDetectBeginJourneyChecks((values) => ({ ...values, [name]: !values[name] || false }));
  };
  //#endregion

  const onSave = async (data) => {
    try {
      console.log('onSave');
      setIsLoading(true);
      const { maxDailyJourney, maxExtraHours, maxWeeklyJourney, beginNigthyHour, endNigthyHour, minExtraHours } = values;
      const durationTime = {
        ...values,
        maxDailyJourney: formatValue(maxDailyJourney),
        maxExtraHours: formatValue(maxExtraHours),
        maxWeeklyJourney: formatValue(maxWeeklyJourney),
        beginNigthyHour: formatValue(beginNigthyHour),
        endNigthyHour: formatValue(endNigthyHour),
        minExtraHours: formatValue(minExtraHours),
        breakingDay: breakingDay,
        detectBeginJourney: detectBeginJourney,
        detectBeginJourneyChecks: detectBeginJourneyChecks,
      };
      await postConfiguration({ journeyControlId: journeyControlId, durationTime });
      setJourneyTime({ durationTime });
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      console.log('error on save');
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSave)}>
      <GridContainer className={classes.content}>
        <GridItem item xs={12} md={12} lg={10} className={classes.content}>
          <Portlet className={classes.formStyle} title={i18('JOURNEY_TIME')} subTitle={i18('JOURNEY_TIME_DETAIL')}>
            <CardContent>
              <GridContainer direction="column" spacing={2}>
                <GridItem item xs={12} md={12} lg={12}>
                  <GridContainer direction="row" spacing={2}>
                    <GridItem item xs={6} md={6}>
                      <InputMaskText
                        id="maxDailyJourney"
                        name="maxDailyJourney"
                        label={i18('Max_time')}
                        icon={FormAlarmShortTime}
                        value={values.maxDailyJourney}
                        format={'##:##'}
                        mask="_"
                        onChange={handleOnChanges}
                      ></InputMaskText>
                      {free && <Typography color="secondary">{`${free} ${i18('NOTAVAI')}`}</Typography>}
                      {errors.name && <Typography color="secondary">{i18('Name_Req')}</Typography>}
                    </GridItem>
                    <GridItem item xs={6} md={6}>
                      <ComboBox
                        label={i18('Breaking_Day')}
                        icon={FormNameIcon}
                        id="breakingDay"
                        name="breakingDay"
                        value={breakingDay}
                        onChange={(e) => setBreakingDay(e.target.value)}
                        options={days || []}
                      ></ComboBox>
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem item xs={12} md={12} lg={12}>
                  <GridContainer direction="row" spacing={2}>
                    <GridItem item xs={6} md={6}>
                      <InputMaskText
                        id="maxExtraHours"
                        name="maxExtraHours"
                        label={i18('MAX_EXTRA_HOURS')}
                        icon={FormAlarmShortTime}
                        value={values.maxExtraHours}
                        format={'##:##'}
                        mask="_"
                        onChange={handleOnChanges}
                      ></InputMaskText>
                    </GridItem>
                    <GridItem item xs={6} md={6}>
                      <InputMaskText
                        id="maxWeeklyJourney"
                        name="maxWeeklyJourney"
                        label={i18('maxWeeklyJourney')}
                        value={values.maxWeeklyJourney}
                        format={'##:##'}
                        mask="_"
                        onChange={handleOnChanges}
                        icon={FormAlarmShortTime}
                      ></InputMaskText>
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem item xs={12} md={12} lg={12}>
                  <GridContainer direction="row" spacing={2}>
                    <GridItem item xs={6} md={6}>
                      <InputMaskText
                        id="beginNigthyHour"
                        name="beginNigthyHour"
                        label={i18('startNightSchedule')}
                        icon={FormAlarmShortTime}
                        value={values.beginNigthyHour}
                        format={'##:##'}
                        mask="_"
                        onChange={handleOnChanges}
                      ></InputMaskText>
                    </GridItem>
                    <GridItem item xs={6} md={6}>
                      <InputMaskText
                        id="endNigthyHour"
                        name="endNigthyHour"
                        label={i18('endNightSchedule')}
                        icon={FormAlarmShortTime}
                        value={values.endNigthyHour}
                        format={'##:##'}
                        mask="_"
                        onChange={handleOnChanges}
                      ></InputMaskText>
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem item xs={12} md={12} lg={12}>
                  <GridContainer direction="row" spacing={2}>
                    <GridItem item xs={6} md={6}>
                      <InputMaskText
                        id="minExtraHours"
                        name="minExtraHours"
                        label={i18('minExtraHour')}
                        icon={FormAlarmShortTime}
                        value={values.minExtraHours}
                        format={'##:##'}
                        mask="_"
                        onChange={handleOnChanges}
                      ></InputMaskText>
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem item xs={12} md={12} lg={12}>
                  <GridContainer direction="row" spacing={2}>
                    <GridItem item xs={6} md={6}>
                      <Typography gutterBottom>{i18('DETECT_BEGIN_Journey')}</Typography>
                      <Slider
                        id="detectBeginJourney"
                        name="detectBeginJourney"
                        ValueLabelComponent={ValueLabelComponent}
                        aria-label="custom thumb label"
                        value={detectBeginJourney}
                        onChange={handleDetectSlider}
                        min={0}
                        max={60}
                      />
                    </GridItem>
                    <GridItem container direction="row" item xs={6} md={6}>
                      <GridItem item xs={3} md={3}>
                        <CustomCheck
                          label={i18('SEND_POS')}
                          checked={detectBeginJourneyChecks.sendPos}
                          onChange={handleChecks}
                          name="sendPos"
                        />
                      </GridItem>
                      <GridItem item xs={3} md={3}>
                        <CustomCheck
                          label={i18('SEND_ALERT_CABINA')}
                          checked={detectBeginJourneyChecks.sendAlertCabina}
                          onChange={handleChecks}
                          name="sendAlertCabina"
                        />
                      </GridItem>
                      <GridItem item xs={3} md={3}>
                        <CustomCheck
                          label={i18('BLOCK_ENGINE')}
                          checked={detectBeginJourneyChecks.sendBlockEngine}
                          onChange={handleChecks}
                          name="sendBlockEngine"
                        />
                      </GridItem>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </CardContent>
            <PortletActions>
              <div className={classes.div}>
                <div className={classes.inDiv}>
                  <Button
                    variant="contained"
                    disabled={isLoading || isReadOnly}
                    color={'cancel'}
                    onClick={() => handleNextChange(0)}
                  >
                    {i18('Cancel')}isReadOnly
                  </Button>
                </div>
                <div className={classes.inDiv}>
                  <Button type="submit" disabled={isLoading || isReadOnly} variant="contained" color={'primary'}>
                    {i18('save')}
                  </Button>
                  {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
              </div>
            </PortletActions>
            {error.length > 0 && (
              <Alert className={classes.alert} severity="error">
                {i18('API_ERR')}
              </Alert>
            )}
          </Portlet>
        </GridItem>
      </GridContainer>
    </form>
  );
};

function ValueLabelComponent(props) {
  const { children, open, value } = props;

  return (
    <Tooltip title={<h1 style={{ fontSize: '14px' }}>{value}</h1>} open={true} enterTouchDelay={0} placement="top">
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
};
export default JourneyTime;
