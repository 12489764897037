import { format, addDays } from 'date-fns';

const date = new Date();
const now = format(addDays(date, -1), "yyyy-MM-dd'T'HH:MM");
const now24 = format(date, "yyyy-MM-dd'T'HH:MM");

export const initFilter = {
  drivers: [],
  evidenceStatus: [],
  evidenceTypes: [],
  evidenceLevel: [],
  tablets: [],
  startDateTime: now,
  endDateTime: now24,
};
const nowDate = format(addDays(date, -1), 'yyyy-MM-dd');
const nowDate24 = format(date, 'yyyy-MM-dd');

export const initDateFilter = {
  startDateTime: nowDate,
  endDateTime: nowDate24,
};

export const evidenceLevel = [
  {
    value: 1,
    text: 'Baixo',
  },
  {
    value: 2,
    text: 'Médio',
  },
  {
    value: 3,
    text: 'Alto',
  },
];
