import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
// import { useHistory } from 'react-router-dom';

import { useIntl } from '../../../lang/context/intlContext';

import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import CustomCard from '../../../components/CustomCard/Card';
import { v4 as uuidv4 } from 'uuid';
//state
import { useStoreActions, useStoreState } from 'easy-peasy';
import { GET_SPOKEN_ROUTES_BYUSERID, getSpokenRouteById } from './api/getMethods';
import { Loading } from '../../../../Splash';
import useAxios from 'axios-hooks';
import { postEnableDisable } from './api/postMethods';

const useStyle = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  text: { fontSize: '20px' },
  cardStyle: { maxWidth: theme.spacing(30) },
  content: {},
  gridContainer: {
    marginTop: theme.spacing(3),
  },
}));

const buttonSelectText = (status) => {
  // item.state === 0 ? i18('Continue') : item.state === 1 ? i18('Editable') : i18('locked');
  if (status === 0) return 'Continue';
  if (status === 1) return 'Editable';
  if (status === 2) return 'locked';
  return 'Disabled';
};
const Archive = ({ history, match }) => {
  const classes = useStyle();
  const { i18 } = useIntl();
  // const history = useHistory();
  const [eloading, setEloading] = useState(0);
  const [open, setOpen] = useState(false);
  const { toPublish } = useStoreState((state) => state.rotograma);
  const { setNoButtons, setPrimaryButton, setSecondaryButton, publish, continueEdition } = useStoreActions((actions) => {
    const { rotograma, globalState } = actions;
    return { ...rotograma, ...globalState };
  });

  const [{ data: getData, loading: getLoading, error: getError }, refetchData] = useAxios({ url: GET_SPOKEN_ROUTES_BYUSERID, method: 'GET' }, { manual: true });

  const [selectedCard, setSelectedCard] = useState(undefined);
  useEffect(() => {
    refetchData();
  }, []);
  useEffect(() => {
    setNoButtons();
    const buttonText = i18('RELEASE'); //spokenRouteId === 0 ? i18('RELEASE') : i18('COMPILE');
    let isDisabled = true;
    if (selectedCard) isDisabled = selectedCard.status < 1;
    setPrimaryButton({ text: buttonText, isEnabled: true, fnc: () => execPublish(selectedCard), disabled: isDisabled });
    setSecondaryButton({
      text: i18('ADD_SPOKENROUTE'),
      isEnabled: true,
      fnc: () => history.push(`/mfis/rotograma/add`),
    });
  }, [selectedCard]);
  const onDialog = (e, { configurationId }) => {
    e.preventDefault();
    console.log('configurationId', configurationId);
    handleClickOpen(configurationId);
  };

  const handleClickOpen = (configurationId) => {
    setEloading(configurationId);
    setOpen(true);
  };

  const handleClose = (exec) => {
    setOpen(false);
    if (exec) {
      onDelete(eloading);
    } else {
      setEloading(0);
    }
  };
  const onDelete = async (configurationId) => {
    setEloading(configurationId);
    window.location.reload();
    // setEloading(0);
    // const res = await postEnableDisable(configurationId);
    // const deletedConfigurationId = res.data.configurationId;

    // deleteConfiguration(deletedConfigurationId);
  };
  const onSelectCard = (e, selectedCard) => {
    // console.log('selectedCard', selectedCard);
    e.preventDefault();
    setSelectedCard(selectedCard);
    // publish(selectedCard);
  };

  const onClickSR = async (e, spokenRoute) => {
    setEloading(spokenRoute.spokenRouteId);
    e.preventDefault();
    const isDisabled = spokenRoute.isDisabled ? 0 : 1;
    await postEnableDisable(spokenRoute.spokenRouteId, isDisabled);
    setEloading(0);
    refetchData();
    // window.location.reload();
    // const { data } = res;
    // console.info(res);
    // setEloading(0);
    // //update store
    // continueEdition(data.spokenRoute);

    // history.push(`/mfis/rotograma/configurations`);
    //redirect to select regions
  };

  const execPublish = (spokenRoute) => {
    const { spokenRouteId } = spokenRoute;
    if (spokenRouteId > 0) {
      console.log('toPublish', spokenRoute);
      publish(spokenRoute);
      history.push(`/mfis/rotograma/publish`);
    }
  };

  if (getLoading || !getData)
    return (
      <GridContainer className={classes.content}>
        <GridItem item xs={12} md={12} lg={12}>
          <Loading></Loading>
        </GridItem>{' '}
      </GridContainer>
    ); // <p>Loading...</p>;
  if (getError) return <p>getError</p>;

  const spokenRoutes = getData.data;
  const subText = i18('NUMBER_GROUPS');

  //if (add)
  // if (spokenRouteId === 0) return <Redirect to={`/mfis/rotograma/add`} />;
  return (
    <div className={classes.root}>
      <Typography component="h3" variant="h5" noWrap className={classes.text}>
        {i18('SPOKEN_ROUTES_LIST_ARCHIVE')}
      </Typography>
      <GridContainer className={classes.gridContainer} spacing={1}>
        {spokenRoutes
          .filter((item) => item.isDisabled)
          .map((item, index) => {
            // const { withPreAlert, withAlert, withViolation } = item;
            // const preTexto = `${withPreAlert ? i18('pre_Alert') : ''} ${withAlert ? i18('Alert') : ''} ${
            //   withViolation ? i18('violation') : ''
            // }`;
            const finalText = `${subText}: ${item.cantidad}`;
            const texto = item.name;
            const selected = item.spokenRouteId === selectedCard?.spokenRouteId;
            const buttonText = i18('restore'); //item.isDisabled ? i18('Enable') : i18('Disable');
            return (
              <GridItem key={uuidv4()} item xs>
                <CustomCard className={classes.cardStyle} key={uuidv4()} title={texto} onSelect={onSelectCard} selected={selected} value={item} align={'center'} contentText={finalText} hasAvatar onClick={onClickSR} buttonText={buttonText} isLoading={eloading === item.spokenRouteId}></CustomCard>
              </GridItem>
            );
          })}
      </GridContainer>{' '}
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" disableBackdropClick disableEscapeKeyDown>
        <DialogTitle id="alert-dialog-title">{i18('DISABLE_SR')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{i18('CONF_DELET_CONF')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)} color="primary">
            {i18('NOCancel')}
          </Button>
          <Button onClick={() => handleClose(true)} color="primary" autoFocus>
            {i18('YesDELETE')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Archive;
