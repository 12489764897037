import React, { useState, useEffect, useCallback } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Button, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';

import { useIntl } from '../../../lang/context/intlContext';

import { Loading } from '../../../../Splash';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import { TableProvider } from '../../../components/Table/TableContext';
import TablePaper from '../../../components/Table/TablePaper';
import PortletIcon from '../../../components/Portlet/PortletIcon';
import { getAllDrivers, getAllVehicles, getAllVehiclesWODevices } from '../api/getMethods';
import { postDetachedDevice, postIsDeleteDriver } from '../api/postMethods';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { ActionAdd, ActionChange, ActionEdit, ActionRemove } from '../../../../assets/icons';

// todo: Send an aditional parameter, to indicate to NewPage what's the source of the vehicle (vehicles or vehiclesWODevices)
const useStyleGrid = makeStyles((theme) => ({
  proto: { maxHeight: 'calc(100vh-600px)' },
  content: {
    display: 'flex',
    justifyContent: 'center',
    height: theme.spacing(45),
  },
  alert: { margin: theme.spacing(2) },
}));
// tod
const VehiclesWODevices = ({ history, match }) => {
  const { i18 } = useIntl();
  const classes = useStyleGrid();
  const [isLoading, setisLoading] = useState(true);
  const [open, setOpen] = useState(true);
  // const { driverId = 0 } = match.params;
  const { deviceAgentId = 0 } = match.params;
  const { path } = match;
  //#region store
  const addVehiclesWODevices = useStoreActions((actions) => actions.vehicle.addVehiclesWODevices);
  const deleteVehicleWODevices = useStoreActions((actions) => actions.vehicle.deleteVehicleWODevices);
  const vehicles = useStoreState((state) => state.vehicle.vehiclesWODevices);
  //#endregion
  const handleClose = async (yesOption) => {
    try {
      if (yesOption) {
        await postDetachedDevice(deviceAgentId);
        deleteVehicleWODevices(deviceAgentId);
      }
      setOpen(false);
      // history.push('/administration/vehicles/management/reload');
    } catch (error) {}
  };
  const onTableReady = () => {};

  const fetchData = async () => {
    if (path.indexOf('reload') > -1 || !vehicles.length) {
      const res = await getAllVehiclesWODevices();
      addVehiclesWODevices(res.data.vehiclesWODevices);
    }
    setisLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  //#region Table and Definitions
  const headCellsTablets = [
    {
      id: 'plates',
      numeric: true,
      align: 'left',
      disablePadding: false,
      label: i18('plates'),
      width: '50px',
      defaultOrder: true,
    },
    { id: 'brand', numeric: false, align: 'left', disablePadding: false, label: i18('BRAND'), width: '70px' },
    { id: 'modelVehicle', numeric: false, align: 'left', disablePadding: false, label: i18('MODEL'), width: '120px' },
    { id: 'yearVehicle', numeric: false, align: 'left', disablePadding: false, label: i18('YEAR'), width: '50px' },
    { id: 'vin', numeric: false, align: 'left', disablePadding: false, label: i18('VIN'), width: '120px' },
    { id: 'color', numeric: false, align: 'left', disablePadding: false, label: i18('COLOR'), width: '70px' },
    { id: 'canBusType', numeric: false, align: 'left', disablePadding: false, label: i18('CAN_TYPE'), width: '120px' },
    { id: 'clientName', numeric: true, align: 'left', disablePadding: false, label: i18('client'), width: '150px' },
    { id: 'actions', numeric: true, align: 'left', disablePadding: false, label: i18('actions'), width: '100px' },
  ];

  const filterInputs = [{ label: 'plates', field: 'plates' }];

  const ActionsRender = useCallback((keyCellId) => {
    return (
      <>
        <ActionAdd title={i18('ADD_DEVICE')} onClick={() => history.push(`/administration/vehicles/${keyCellId.split('|')[1]}/add-device`)} />
        {/* <ActionEdit
          title={i18('EDIT')}
          onClick={() => history.push(`/administration/vehicles/${keyCellId.split('|')[1]}/edit`)}
        />
        <ActionRemove
          title={i18('DETACHED_DEVICE')}
          onClick={() => history.push(`/administration/vehicles/management/${keyCellId.split('|')[1]}/detached`)}
        /> */}
      </>
    );
  }, []);

  if (isLoading)
    return (
      <GridContainer className={classes.content}>
        <GridItem item xs={12} md={12} lg={12}>
          <Loading></Loading>
        </GridItem>{' '}
      </GridContainer>
    );

  //#endregion
  return (
    <div>
      <GridContainer className={classes.content}>
        <GridItem item xs={12} md={12} lg={12}>
          <PortletIcon
            hasTitle={true}
            title={i18('vehicles_wo_devices')}
            subTitle={i18('LIST_OF_vehicles_wo_devices')}
            withIcon={false}
            hasCustomStartIcon={false}
            StartIcon={PermIdentityIcon}
            withButton
            buttonTitle={i18('NEW_vehicle')}
            onClickButton={() => history.push('/administration/vehicles/new')}
          >
            <CardContent style={{ paddingTop: '0px' }}>
              <GridContainer direction="column" spacing={2}>
                <GridItem item xs={12} md={12}>
                  <TableProvider
                    loadData={vehicles}
                    columns={headCellsTablets}
                    checkedItems={[]}
                    initRowsPerPage={50}
                    withPagination={true}
                    onContextReady={onTableReady}
                    useV2={true}
                    actions={ActionsRender}
                  >
                    <TablePaper maxHeight={'450px'} filterInputs={filterInputs} hasCheckBox={false} hasGroups={false} hasSearchAllFields={true}></TablePaper>
                  </TableProvider>
                </GridItem>
              </GridContainer>
            </CardContent>
            <></>
          </PortletIcon>
        </GridItem>
      </GridContainer>
      {deviceAgentId > 0 && (
        <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" disableBackdropClick disableEscapeKeyDown>
          <DialogTitle id="alert-dialog-title">{i18('DETACHED_DEVICE')}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{i18('CONF_DETACHED_DEVICE')}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleClose(false)} color="primary">
              {i18('NOCancel')}
            </Button>
            <Button onClick={() => handleClose(true)} color="primary" autoFocus>
              {i18('YesDELETE')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};
export default VehiclesWODevices;
