/**
 *
 * @param {array} regions
 * @param {array} selected
 */

export const filterRegion = (regions, selected) => {
  if (selected.length === 0) return regions;
  return regions.reduce((acc, region) => {
    if (region.nextLevel.length > 0) {
      const newNextLevel = filterRegion(region.nextLevel, selected);
      const newRegion = { ...region, nextLevel: newNextLevel };
      acc.push(newRegion);
      //   acc.push({ ...region, nextLevel: newNextLevel });
    } else {
      if (selected.indexOf(region.keyId) < 0) acc.push(region);
    }
    return acc;
  }, []);
};

export const getSelectedRegions = (configurations) => {
  if (configurations.length === 0) return [];
  return configurations.map((item) => item.regionIds).flat();
};

//   return regions.filter((item) => {
//     return !selected.find((item2) => item2 === item);
//   });
// };
