import React, { useCallback } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import { Badge, IconButton } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

//components
import Profile from './components/Profile';
import Searcher from '../../../components/Searcher';
import { whiteColor } from '../../../components/styles/constValues';
//store
import { useStoreState } from 'easy-peasy';
import { drawerWidth, sumDrawers } from '../../constants';
import { useIntl } from '../../../lang/context/intlContext';
import { useHistory } from 'react-router-dom';
//tuil
import { v4 as uuid } from 'uuid';
import { fade } from '@material-ui/core/styles/colorManipulator';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    // display: 'flex',

    paddingRight: 24,
    height: '100%', // keep right padding when drawer closed
    //    width: '100%',
  },
  appBar: {
    height: theme.spacing(15),
    background: 'transparent',
    // backgroundColor: fade(whiteColor, 0.6),
    backgroundImage: ' linear-gradient(to bottom, #ffffff, rgba(255, 255, 255, 0));',
    // backgroundColor: '#ffffff',
    // height: theme.spacing(12),
    // width: `calc(100% - ${drawerWidth}px)`,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    background: 'transparent',
    // backgroundColor: '#ffffff',
    // height: '120px',
    // marginLeft: sumDrawers,
    width: `calc(100% - ${sumDrawers}px)`,
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  title: {
    flexGrow: 1,
    alignSelf: 'flex-end',
  },
  flexGrow: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: 'auto',
  },
  notifButton: {
    backgroundColor: '#fffff',
    color: 'black',
    marginRight: theme.spacing(3),
    // paddingRight: theme.spacing(5),
    '&:hover': {
      color: whiteColor,
      backgroundColor: theme.palette.secondary.light,
      // fontWeight: '500',
    },
  },
  menuButton: {
    backgroundColor: '#fffff',
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(3),
    // paddingRight: theme.spacing(5),
    '&:hover': {
      color: whiteColor,
      backgroundColor: theme.palette.secondary.light,
      // fontWeight: '500',
    },
  },
  userProfile: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: theme.spacing(4),
  },
  input: {
    color: theme.palette.text.secondary,
    backgroundColor: 'white',
  },
  spacer: {
    flexGrow: 1,
  },
}));

// const HeaderMenu = () => (
//   <div>
//     <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
//       Open Menu
//     </Button>
//   </div>
// );

export default function Header({ ...props }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { email, headerMenu } = useStoreState((state) => state.globalState);
  const { userName, clientName } = useStoreState((state) => state.user);
  const { open, title } = props;
  const history = useHistory();
  const { i18 } = useIntl();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = async (item) => {
    setAnchorEl(null);
    // history.push(push);
    switch (item.action) {
      case 'post':
        await item.post();
        // window.location.reload();
        history.push(item.push + uuid());
        break;

      default:
        break;
    }
  };

  const updateProfile = useCallback(() => <Profile userName={userName} clientName={clientName} />, [userName, clientName]);
  const menuItems = (menus) =>
    menus.map((item, index) => (
      <MenuItem key={index} onClick={() => handleClose(item)}>
        {i18(item.name)}
      </MenuItem>
    ));

  return (
    <AppBar position="absolute" elevation={0} className={clsx(classes.appBar, open && classes.appBarShift)}>
      <Toolbar className={classes.toolbar}>
        <Typography component="h1" variant="h3" noWrap>
          {title}
        </Typography>

        <IconButton
          onClick={handleClick}
          className={classes.menuButton}
          aria-label="display more actions"
          edge="end"
          color="inherit"
        >
          <MoreVertIcon />
        </IconButton>
        <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
          {headerMenu && menuItems(headerMenu)}
          {/* <MenuItem onClick={handleClose}>{i18('Duplicate')}</MenuItem>
          <MenuItem onClick={handleClose}>{i18('Delete')}</MenuItem> */}
          {/* <MenuItem onClick={handleClose}>Logout</MenuItem> */}
        </Menu>

        {/* <span className={classes.spacer} /> */}
        <div className={classes.flexGrow}>{/* <Searcher label="Buscar" /> */}</div>

        <div className={classes.userProfile}>
          <IconButton className={classes.notifButton} color="inherit">
            <Badge color="primary" variant="dot">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          {/* <Profile userName={userName} clientName={clientName} />
           */}
          {updateProfile()}
        </div>
      </Toolbar>
    </AppBar>
  );
}
