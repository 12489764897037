import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import EventNoteOutlinedIcon from '@material-ui/icons/EventNoteOutlined';
import SearchIcon from '@material-ui/icons/Search';
import { ICON_BACKGROUND, ICON_COLOR, INPUT_BACKGROUND, WHITE } from '../styles/constValues';
import { FormNameIcon } from '../../../assets/icons/formIcons';
import { InputBase, Paper, InputLabel, InputAdornment, Avatar } from '@material-ui/core';
const HOVER_COLOR = 'linear-gradient(to bottom, #c5b1f9, #5978e4)';
const useStyle = makeStyles((theme) => ({
  rootDiv: { width: '450px' },
  root: {
    marginTop: '4px',
    padding: '4px',
    display: 'flex',
    backgroundColor: INPUT_BACKGROUND,
    alignItems: 'center',
    // width: '350px',
    // border: '0',
    border: '1px solid',
    lineHeight: 1.5,
    height: '50px',
    borderColor: INPUT_BACKGROUND,
    '&:hover': {
      color: theme.palette.primary.contrastText,
      borderColor: theme.palette.primary.main,
      //   boxShadow: 'none',
      //   border: '1px solid',
      //   lineHeight: 1.5,
    },
  },
  rootOutline: {
    marginTop: '0px',
    color: theme.palette.primary.contrastText,
    borderColor: theme.palette.primary.main,
    height: '56px',
  },
  label: {
    marginLeft: '4px',
    color: theme.palette.primary.dark,
    fontSize: '12px',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    color: theme.palette.text.secondary,
    backgroundColor: INPUT_BACKGROUND,
  },
  iconButton: {
    padding: theme.spacing(1),
  },
  multiline: {
    height: theme.spacing(12),
  },
  rootIcon: { padding: 4 },
  content: {
    backgroundColor: ICON_BACKGROUND,
    color: ICON_COLOR,
  },
  rounded: {
    color: '#fff',
    backgroundColor: ICON_BACKGROUND,
  },
}));

function InputSearch(props) {
  const { className, inputRef, label, icon: FormIcon, outLine, ...rest } = props;
  const classes = useStyle();
  const { multiline } = rest;

  const rootClasses = clsx({
    [classes.rootDiv]: true,
    [className]: className,
  });
  const paperClasses = clsx({
    [classes.root]: true,
    [classes.multiline]: multiline,
    [classes.rootOutline]: outLine,
    // [className]: className,
  });
  const formClasses = clsx({
    [classes.input]: true,
  });

  return (
    <div className={rootClasses}>
      {/* {label && <InputLabel className={classes.label}>{label}</InputLabel>} */}
      <Paper elevation={0} className={paperClasses}>
        <InputBase className={formClasses} {...rest} inputRef={inputRef} />
        <InputAdornment position="start" className={classes.rootIcon}>
          <Avatar variant="rounded" className={classes.rounded}>
            <SearchIcon className={classes.content} />
          </Avatar>
        </InputAdornment>
      </Paper>
    </div>
  );
}
InputSearch.defaultProps = {
  icon: null,
};
export default InputSearch;
