//@ts-nocheck
import { action, thunk } from 'easy-peasy';

const initValues = { checked: [] };

const gridState = {
  ...initValues,
  addCheck: action((gridState, checkCell) => {
    gridState.checked.push(checkCell);
  }),
  removeCheck: action((gridState, checkCell) => {
    const withoutCheckCell = gridState.checked.filter((item) => item != checkCell);
    gridState.checked = withoutCheckCell;
  }),
};

export default gridState;
