//#region imports
import React, { useState, useEffect } from 'react';
//#region MUI
import { CardContent, CircularProgress, IconButton, ListItemIcon, ListItemSecondaryAction, Step, StepLabel, Stepper } from '@material-ui/core';
//#endregion

//utils
import { useIntl } from '../../../lang/context/intlContext'; //addjust path reference
import { useForm } from 'react-hook-form';
//#region components

import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import Portlet from '../../../components/Portlet/Portlet';
import InputText from '../../../components/InputText/InputText';
import PortletActions from '../../../components/Portlet/PorletActions';
import Button from '../../../components/CustomButton/Button';
import { FormNameIcon } from '../../../../assets/icons/formIcons';
//#endregion

//ApiMedhots

//state
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useStepStyle } from '../../../common/styles/stepStyle';
import ComboBox from '../../../components/ComboBox/ComboBox';
import CustomizedSnackbars from '../../../components/CustomSnackBar/CustomSnackBar';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import PaymentIcon from '@material-ui/icons/Payment';
import DeleteIcon from '@material-ui/icons/Delete';

//api
import { postUpdateNFCAChangeOwner } from '../api/postMethods';
import { getTableNFCS } from '../api/getMethods';
import { useFormStyle } from '../../../common/styles/formStyle';
import { ListWrap } from '../../../layout/components/ListWrap/ListWrap';
//#endregion
//these styles are used in Add Rotograma Falado and Here

//todo:validate NFC is not already in db

const ChangeOwner = ({ history, match }) => {
  const classes = useFormStyle();
  const { i18 } = useIntl();

  //#region useStates
  const [isLoading, setisLoading] = useState(true);
  const [openSnack, setOpenSnack] = React.useState({ open: false, title: '', severity: 'success' });
  const [nfcList, setNfcList] = useState([]);
  const [nfcKey, setNfcKey] = useState('');
  const [selectedClientId, setSelectedClientId] = React.useState(0);
  const [filterClients, setFilterClients] = React.useState([]);

  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps().map((item) => i18(item));
  //#endregion

  const { handleSubmit, errors } = useForm();
  //#region easypeasy
  const setNoButtons = useStoreActions((actions) => actions.globalState.setNoButtons);
  const setPrimaryButton = useStoreActions((actions) => actions.globalState.setPrimaryButton);
  const addUsersClients = useStoreActions((actions) => actions.user.addUsersClients);
  const nfcs = useStoreState((state) => state.nfc.nfcs);
  const addNFCS = useStoreActions((actions) => actions.nfc.addNFCS);

  const { clientId, clientName, clients, tsp } = useStoreState((state) => state.user);
  //#endregion

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <GridItem justify="center" container spacing={2}>
            <GridItem item xs={12} md={5} lg={5}>
              <InputText id="nfc" name="nfc" value={nfcKey} label={i18('nfc')} onChange={(e) => setNfcKey(e.target.value)} onKeyPress={handleOnKeyPress} icon={FormNameIcon} fullWidth></InputText>
            </GridItem>
            <GridItem item xs={12} md={5} lg={5}>
              <ListWrap title={i18('NFC_Cards_to_update')} classes={classes}>
                {getNFCSimple(nfcList)}
              </ListWrap>
            </GridItem>
          </GridItem>
        );
      case 1:
        return (
          <GridItem justify="center" container>
            <GridItem item xs={12} md={5} lg={5}>
              <ComboBox
                label={i18('TSP')}
                icon={FormNameIcon}
                name="tspId"
                defaultValue={clientId}
                // value={selectedClientId}
                onChange={handleComboTSP}
                options={tsp}
                // placeholder={i18('type')}
              ></ComboBox>
            </GridItem>
            <GridItem item md={5} lg={5}>
              <ComboBox label={i18('Client')} icon={FormNameIcon} name="client" defaultValue={clientId} onChange={handleComboChange} options={filterClients} placeholder={i18('Groups')}></ComboBox>
            </GridItem>
          </GridItem>
        );
      case 2:
        return (
          <GridItem justify="center" container spacing={2}>
            <GridItem item xs={12} md={5} lg={5}>
              <ListWrap title={i18('NFC_Cards_to_update')} classes={classes}>
                {getNFCSimple(nfcList)}
              </ListWrap>
            </GridItem>
            <GridItem item md={5} lg={5}>
              <InputText id="client" name="client" readOnly={true} defaultValue={filterClients.find((item) => item.value === selectedClientId).text} label={i18('Client')} icon={FormNameIcon} fullWidth></InputText>
            </GridItem>
          </GridItem>
        );
      default:
        return i18('UPDATE_SUCESSFUL');
    }
  };
  const fetchData = async () => {
    const res = await getTableNFCS();
    addNFCS(res.data.nfcs);
    setisLoading(false);
    setNfcList([]);
    setSelectedClientId(0);
    setActiveStep(0);
  };
  useEffect(() => {
    setPrimaryButton({
      text: i18('management'),
      isEnabled: true,
      fnc: () => {
        history.push(`/administration/nfc/management`);
      },
    });
    setFilterClients(clients.filter((item) => +item.ParentId === +clientId || +item.value === +clientId));
    fetchData();
    return () => setNoButtons();
  }, []);

  //#region handlers
  const handleComboTSP = (e) => {
    if (!e.target) return;
    setSelectedClientId(0);
    setFilterClients(clients.filter((item) => +item.ParentId === +e.target.value));
  };
  const handleComboChange = (e) => {
    console.log('e.target.value', e.target.value);
    setSelectedClientId(e.target.value);
  };
  const handleOnKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (e.target.value.length === 0) return;

      const NFC = e.target.value;
      if (isNFCOnList(NFC)) {
        setOpenSnack((prev) => ({ ...prev, open: true, title: i18('NFC_IN_LIST'), severity: 'warning' }));
        return;
      }
      const validNFC = isValidNFC(NFC);
      if (!validNFC) {
        setOpenSnack((prev) => ({ ...prev, open: true, title: i18('NFC_CARD_NOT_REG_OR_USER_CAN_CHANGE'), severity: 'warning' }));
        return;
      }
      const { ClientName: clientName, serial, nfcId } = validNFC;
      const newNFC = { NFC, serial, clientName, clientId: selectedClientId, nfcId };
      setNfcList((prev) => [newNFC, ...prev]);
      setNfcKey('');
      // write your functionality here
    }
  };
  const handleNext = () => {
    const isUpdate = activeStep === steps.length - 1;
    if (isUpdate) onSubmit();
    else setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = async () => {
    const res = await getTableNFCS();
    addNFCS(res.data.nfcs);
    setisLoading(false);
    setNfcList([]);
    setSelectedClientId(0);
    setActiveStep(0);
  };
  //#endregion

  const isValidNFC = (nfc) => nfcs.find((item) => item.NFC === nfc);
  const isNFCOnList = (nfc) => nfcList.find((item) => item.NFC === nfc);
  //181.548.108-08
  const onSubmit = async () => {
    try {
      setisLoading(true);
      const nfcsToUpdate = nfcList.map((item) => item.nfcId);
      const res = await postUpdateNFCAChangeOwner(nfcsToUpdate, +selectedClientId);
      const { data } = res;
      if (data.error !== 0) setOpenSnack((prev) => ({ ...prev, open: true, title: i18('ERROR_API'), severity: 'error' }));
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setisLoading(false);
    } catch (error) {
      setisLoading(false);
      setOpenSnack((prev) => ({ ...prev, open: true, title: i18('ERROR_API'), severity: 'error' }));
    }
  };

  const removeNFC = (id) => {
    setNfcList((prev) => prev.filter((item) => item.NFC !== id));
  };
  const getNFCSimple = (items) => {
    return items.map((item) => (
      <ListItem key={item.serial}>
        <ListItemIcon>
          <PaymentIcon />
        </ListItemIcon>
        <ListItemText primary={item.NFC + '-' + item.serial} secondary={item.clientName} />
        <ListItemSecondaryAction onClick={() => removeNFC(item.NFC)}>
          <IconButton edge="end" aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    ));
  };
  return (
    <div>
      <GridContainer className={classes.content}>
        <GridItem item xs={12} md={9} lg={9} className={classes.content}>
          <Portlet className={classes.formStyle} title={i18('NFC_CARD') + ' - ' + i18('CHANGE_OWNER')} subTitle={i18('FOLLOW_INSTRUCTIONS')}>
            <CardContent>
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <GridContainer justify="center" spacing={2} style={{ height: '400px' }}>
                {getStepContent(activeStep)}
              </GridContainer>
            </CardContent>

            <PortletActions>
              {activeStep === steps.length ? (
                <div>
                  <Button variant="contained" color="primary" onClick={handleReset}>
                    {i18('RESET_OWNER')}
                  </Button>
                </div>
              ) : (
                <div className={classes.div}>
                  <div className={classes.inDiv}>
                    <Button disabled={activeStep === 0 || activeStep === steps.length} onClick={handleBack} color={'cancel'}>
                      {i18('BACK')}
                    </Button>
                  </div>
                  <div className={classes.inDiv}>
                    <Button variant="contained" disabled={isLoading || activeStep === steps.length || nfcList.length === 0 || (activeStep === 1 && selectedClientId === 0)} color="primary" onClick={handleNext}>
                      {activeStep === steps.length - 1 ? i18('UPDATE') : i18('Next')}
                    </Button>
                    {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </div>
                </div>
              )}
            </PortletActions>
          </Portlet>
        </GridItem>
      </GridContainer>
      <CustomizedSnackbars open={openSnack.open} setOpen={setOpenSnack} title={openSnack.title} severity={openSnack.severity}></CustomizedSnackbars>
    </div>
  );
};
ChangeOwner.propTypes = {
  // title: PropTypes.string,
  // numSelected: PropTypes.number.isRequired,
  // children: PropTypes.object,
};

ChangeOwner.defaultProps = {
  //icon: null,
};
export default ChangeOwner;
function getSteps() {
  return ['Insert NFC Keys', 'Select New Owner', 'Review and Update'];
}
