/* eslint-disable react/display-name */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import GridItem from '../Grid/GridItem';
import { getIcon } from '../Macro/helper';
import { HOVER_COLOR, whiteColor } from '../styles/constValues';

import { motion } from 'framer-motion';

const nodeStyles = makeStyles((theme) => ({
  paperStyle: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(12),
      height: theme.spacing(12),
    },
  },
  cardContentStyle: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardContentStyleSelected: {
    display: 'flex',
    flexDirection: 'row',
  },
  nodeStyle: {
    margin: theme.spacing(0),
  },
  nodeStyleSelected: { width: '100%', background: HOVER_COLOR, color: whiteColor },
  verticalLine: {
    margin: theme.spacing(0),
    width: '1px' /* Line width */,
    backgroundColor: 'black' /* Line color */,
    height: theme.spacing(1) /* Override in-line if you want specific height. */,
    marginLeft: theme.spacing(6),
    float: 'left' /* Causes the line to float to left of content. 
        You can instead use position:absolute or display:inline-block
        if this fits better with your design */,
  },
  listItemAvatarIconBlue: { fill: theme.palette.primary.main },
  listItemAvatarIconWhite: { fill: whiteColor },
  fontStyle: {
    font: 'Roboto',
    fontSize: '14px',
    fontWeight: 'bold',
    color: 'inherit',
  },
  fontErrorStyle: {
    font: 'Roboto',
    fontSize: '14px',
    fontWeight: 'bold',
    color: 'Red',
  },
}));

const NodeMacro = React.memo(({ position = 0, item, showLine = true, onClick, selected = false, withError = false }) => {
  const classes = nodeStyles();
  const nodeSyles = clsx({
    [classes.nodeStyle]: true,
    [classes.nodeStyleSelected]: selected,
  });
  const iconStyle = clsx({
    [classes.listItemAvatarIconBlue]: true,
    [classes.listItemAvatarIconWhite]: selected,
  });

  const contentStyle = clsx({
    [classes.cardContentStyle]: true,
    [classes.cardContentStyleSelected]: selected,
  });

  const type = item.id;
  return (
    <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.9 }}>
      <GridItem item xs={12} md={12} lg={12} className={classes.paperStyle}>
        <Card className={nodeSyles}>
          <CardActionArea onClick={(e) => onClick(+item.macroJourneyControlId, position)}>
            <CardContent className={contentStyle}>
              {getIcon(type, 'primary', iconStyle)}
              <Typography className={withError ? classes.fontErrorStyle : classes.fontStyle} component="p">
                {item.name}
              </Typography>
            </CardContent>
          </CardActionArea>
          {/* <CardActions>
          <Button size="small" color="primary">
            Share
          </Button>
          <Button size="small" color="primary">
            Learn More
          </Button>
        </CardActions> */}
        </Card>
        {showLine && <div className={classes.verticalLine}></div>}
      </GridItem>
    </motion.div>
  );
});
export default NodeMacro;
