//@ts-nocheck
import { action, computed } from 'easy-peasy';

const initValue = { nfcs: [] };

const nfc = {
  ...initValue,

  addNFCS: action((state, data) => (state.nfcs = data)),
  clearNFCS: action((state) => (state.nfcs = [])),
  getNFC: computed((state) => (id) => state.nfcs.find((item) => item.nfcId === id)),
  deleteNFC: action((state, nfcId) => {
    state.nfcs = state.nfcs.filter((item) => item.nfcId !== +nfcId);
  }),
};

export default nfc;
