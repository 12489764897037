export const initSnack = {
  open: false,
  title: '',
  severity: 'error',
};

// Data for table
const patterHeaderStyle = {
  paddingLeft: '0px',
  paddingRight: '0px',
  paddingTop: '8px',
  paddingBottom: '8px',
  textAlign: 'left',
};
const patternCellStyle = {
  paddingLeft: '0px',
  paddingRight: '0px',
  paddingTop: '10px',
  paddingBottom: '10px',
  textAlign: 'left',
};

export const columnsDrivers = [
  {
    title: 'DRIVER_ID',
    field: 'driverId',
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'NAME',
    field: 'name',
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'LASTNAME',
    field: 'lastname',
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'CPF',
    field: 'CPF',
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'NFC',
    field: 'NFC',
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'NAME_CLIENT',
    field: 'clientName',
    filtering: false,
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: { ...patterHeaderStyle },
  },
  {
    title: 'DATA',
    field: 'created_at',
    filtering: false,
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
];
