import React from 'react';
import GridContainer from '../components/Grid/GridContainer';
import GridItem from '../components/Grid/GridItem';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CustomCard from '../components/CustomCard/Card';
import english from '../lang/translations/en.json';
import { createIntl, createIntlCache } from 'react-intl';
import Table from '../components/Table/Table';
import { useIntl } from '../lang/context/intlContext';

// import rawData from '../mock/positions.json';
import rawAssets from '../mock/vehicleGoups.json';
import rawRegions from '../mock/regions.json';
// import rawGroup from '../mock/groupSubGroup.json';
import { formatMasterDetail, formatMasterDetailRegions } from '../components/Table/helper';
import { TableProvider } from '../components/Table/TableContext';
import TablePaper from '../components/Table/TablePaper';
const ICON_COLOR = 'linear-gradient(to bottom, #c5b1f9, #5978e4)';

const useStyle = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  marginButton: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  nextButton: {},
  alarmIconStyle: {
    fill: '#5978e4',
    borderRadius: '4px',
  },
  alarmIconStyleSelected: {
    fill: theme.palette.text.primary,
    borderRadius: '4px',
  },
  doneIconStyle: {
    color: '#6be48d',
  },
  badgeStyle: {},
}));

const useStyleCard = makeStyles((theme) => ({
  card: {
    maxWidth: 345,
  },
  headerStyle: {
    backgroundColor: theme.palette.primary.main,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));
const useStyleGrid = makeStyles((theme) => ({
  root: { height: '100%' },
  content: {
    display: 'flex',
    justifyContent: 'center',
    height: theme.spacing(80),
  },
}));

const { groupsDevices, vehicles: assets } = rawAssets;
const { groups, regions } = rawRegions;

const formatGroups = (groupsDevices) => {
  return groupsDevices.map((item) => {
    const { groupId, groupName, subGroups } = item;
    return {
      groupId,
      groupName,
      subGroups,
    };
  });
};

function ViewAssets(props) {
  const { i18 } = useIntl();
  const headCells2 = [
    { id: 'plates', numeric: false, align: 'left', disablePadding: true, label: i18('Asset'), width: '90px' },
    { id: 'device', numeric: false, align: 'left', disablePadding: false, label: i18('Device'), width: '90px' },
    { id: 'driverId', numeric: true, align: 'left', disablePadding: false, label: i18('DriverId'), width: '50px' },
    {
      id: 'trackingId',
      numeric: true,
      align: 'left',
      disablePadding: false,
      label: i18('trackingId'),
      defaultOrder: 'true',
      width: '120px',
    },
    { id: 'latitude', numeric: true, align: 'left', disablePadding: false, label: i18('Latitude'), width: '150px' },
    { id: 'longitude', numeric: true, align: 'left', disablePadding: false, label: i18('Longitude'), width: '150px' },
    { id: 'vel', numeric: true, align: 'left', disablePadding: false, label: i18('Vel'), width: '50px' },
    { id: 'address', numeric: true, align: 'left', disablePadding: false, label: i18('address'), width: 'auto' },
  ];
  const headCells = [
    {
      id: 'rootField',
      numeric: false,
      align: 'left',
      disablePadding: true,
      label: i18('GROUP/SUBGROUP/ASSET'),
      width: '350px',
      defaultOrder: true,
    },
    { id: 'device', numeric: false, align: 'left', disablePadding: false, label: i18('Device'), width: '150px' },
    { id: 'latitude', numeric: true, align: 'left', disablePadding: false, label: i18('Latitude'), width: '150px' },
    { id: 'longitude', numeric: true, align: 'left', disablePadding: false, label: i18('Longitude'), width: '150px' },
    { id: 'gpsSpeed', numeric: true, align: 'left', disablePadding: false, label: i18('Vel'), width: '50px' },
    { id: 'client', numeric: true, align: 'left', disablePadding: false, label: i18('Client'), width: '250px' },
    { id: 'address', numeric: true, align: 'left', disablePadding: false, label: i18('Address'), width: 'auto' },
    // { id: 'address', numeric: true, align: 'left', disablePadding: false, label: i18('address'), width: 'auto' },
  ];
  const headCellsRegions = [
    {
      id: 'rootField',
      numeric: false,
      align: 'left',
      disablePadding: true,
      label: i18('GROUP/SUBGROUP/ASSET'),
      width: '350px',
      defaultOrder: true,
    },
    { id: 'description', numeric: true, align: 'left', disablePadding: false, label: i18('Description'), width: '150px' },
    { id: 'typeShape', numeric: true, align: 'left', disablePadding: false, label: i18('typeShape'), width: '150px' },
    { id: 'typeRegionName', numeric: true, align: 'left', disablePadding: false, label: i18('typeRegionName'), width: '50px' },
    { id: 'radius', numeric: true, align: 'left', disablePadding: false, label: i18('radius'), width: '250px' },
    { id: 'OwnerName', numeric: true, align: 'left', disablePadding: false, label: i18('Client'), width: 'auto' },
    // { id: 'address', numeric: true, align: 'left', disablePadding: false, label: i18('address'), width: 'auto' },
  ];
  // const devices = raw
  const data = formatMasterDetail(groupsDevices, assets);
  const dataRegion = formatMasterDetailRegions(groups.groupsRegions, regions);
  // console.info({ data });
  const classes = useStyleGrid();
  // console.log('home');
  return (
    <div className={classes.root}>
      <GridContainer>
        <GridItem item xs={12} md={12} lg={12} className={classes.content}>
          {/* <CustomCard title={title} /> */}
          {/* <Table tableHeaderColor="primary" tableHead={headCells} tableData={rows} /> */}
          {console.log('viewAssets')}
          <TableProvider loadData={data} initRowsPerPage={10} columns={headCells}>
            <TablePaper hasCheckBox={true} hasGroups={true} />
          </TableProvider>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default ViewAssets;
