import { format, addMonths } from 'date-fns';
import { patterHeaderStyle, patternCellStyle } from '../../table/stylesReport';

const date = new Date();
const now1Month = format(addMonths(date, -1), "yyyy-MM-dd'T'HH:MM");
const now24 = format(date, "yyyy-MM-dd'T'HH:MM");
export const maxMonth = 6;

export const initFilterByUsers = {
  startDateTime: now1Month,
  endDateTime: now24,
};

export const columnsTableByUsers = [
  {
    title: 'EVIDENCE',
    field: 'evidence',
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'RISK_LEVEL',
    field: 'levelName',
    width: null,
    cellStyle: {
      minWidth: '30px',
      maxWidth: '35px',
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },

  {
    title: 'CREATED_AT',
    field: 'created_at',
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'USER',
    field: 'userName',
    cellStyle: {
      minWidth: '8%',
      maxWidth: '10%',
      ...patternCellStyle,
      paddingLeft: '8px',
    },
    headerStyle: {
      ...patterHeaderStyle,
      paddingLeft: '8px',
    },
  },
  {
    title: 'ASSET',
    field: 'asset',
    cellStyle: {
      minWidth: '8%',
      maxWidth: '10%',
      ...patternCellStyle,
      paddingLeft: '8px',
    },
    headerStyle: {
      ...patterHeaderStyle,
      paddingLeft: '8px',
    },
  },
  {
    title: 'NAME',
    field: 'driverName',
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'STATUS',
    field: 'statusName',
    filtering: false,
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: { ...patterHeaderStyle },
  },
];
