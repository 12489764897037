// 20 - 23
// 30: Devices
// 31: Clients

export const administrationSubMenu = [
  {
    parent: 'A',
    id: '30',
    level: 2,
    path: 'devices',
    name: 'Devices',
    label: 'H',
    icon: 'RouterOutlinedIcon',
    color: '',
    hasCollapsed: false,
    isVisible: false,
    subMenus: [
      {
        status: 'normal',
        id: '30.1',
        level: 3,
        path: '/administration/devices/management',
        name: 'Management',
        label: 'H',
        icon: 'ViewQuiltOutlinedIcon',
        prev: '2',
        next: '30.2',
        isVisible: true,
      },
      {
        status: 'normal',
        level: 3,
        path: '/administration/devices/new',
        name: 'New',
        label: 'H',
        icon: 'AddToPhotosOutlinedIcon',
        prev: '30.1',
        id: '30.2',
        next: '30.3',
        isVisible: true,
      },
      {
        status: 'blind',
        id: '30.4',
        level: 3,
        path: '#',
        name: '',
        label: 'H',
        icon: undefined,
        isVisible: true,
      },
    ],
    selected: false,
    status: 'normal',
  },
  {
    parent: 'A',
    id: '23',
    level: 2,
    path: 'vehicles',
    name: 'Vehicles',
    label: 'H',
    icon: 'CommuteOutlinedIcon',
    color: '',
    hasCollapsed: false,
    isVisible: true,
    subMenus: [
      {
        status: 'normal',
        id: '23.1',
        level: 3,
        path: '/administration/vehicles/management',
        name: 'Management',
        label: 'H',
        icon: 'ViewQuiltOutlinedIcon',
        prev: '2',
        next: '23.2',
        isVisible: true,
      },
      {
        status: 'normal',
        level: 3,
        path: '/administration/vehicles/new',
        name: 'New',
        label: 'H',
        icon: 'AddToPhotosOutlinedIcon',
        prev: '23.1',
        id: '23.2',
        next: '23.3',
        isVisible: true,
      },
      {
        status: 'normal',
        level: 3,
        path: '/administration/vehicles/recovery',
        name: 'Recovery',
        label: 'H',
        icon: 'AddToPhotosOutlinedIcon',
        prev: '23.2',
        id: '23.3',
        next: '23.4',
        isVisible: true,
      },
      {
        status: 'blind',
        id: '23.4',
        level: 3,
        path: '#',
        name: '',
        label: 'H',
        icon: undefined,
        isVisible: true,
      },
    ],
    selected: false,
    status: 'normal',
  },
  {
    parent: 'A',
    id: '20',
    level: 2,
    path: 'drivers',
    name: 'Drivers',
    label: 'H',
    icon: 'PermContactCalendarIcon',
    color: '',
    hasCollapsed: false,
    isVisible: true,
    subMenus: [
      {
        status: 'normal',
        id: '20.1',
        level: 3,
        path: '/administration/drivers/management',
        name: 'Management',
        label: 'H',
        icon: 'ViewQuiltOutlinedIcon',
        prev: '2',
        next: '20.2',
        isVisible: true,
      },
      {
        status: 'normal',
        id: '20.2',
        level: 3,
        path: '/administration/drivers/new',
        name: 'New',
        label: 'H',
        icon: 'AddToPhotosOutlinedIcon',
        prev: '20.1',
        next: '20.3',
        isVisible: true,
      },
      {
        status: 'normal',
        id: '20.3',
        level: 3,
        path: '/administration/drivers/nfc-setup',
        name: 'NFC SETUP',
        label: 'H',
        icon: 'ContactMailIcon',
        prev: '20.2',
        next: '20.4',
        isVisible: true,
      },
      {
        status: 'blind',
        id: '20.4',
        level: 3,
        path: '#',
        name: '',
        label: 'H',
        icon: undefined,
        isVisible: true,
      },
    ],
    selected: false,
    status: 'normal',
  },
  {
    parent: 'A',
    id: '31',
    level: 2,
    path: 'clients',
    name: 'clients',
    label: 'H',
    icon: 'RouterOutlinedIcon',
    color: '',
    hasCollapsed: false,
    isVisible: false,
    subMenus: [
      {
        status: 'normal',
        id: '31.1',
        level: 3,
        path: '/administration/clients/management',
        name: 'Management',
        label: 'H',
        icon: 'ViewQuiltOutlinedIcon',
        prev: '2',
        next: '31.2',
        isVisible: true,
      },
      {
        status: 'normal',
        level: 3,
        path: '/administration/clients/new',
        name: 'New',
        label: 'H',
        icon: 'AddToPhotosOutlinedIcon',
        prev: '31.1',
        id: '31.2',
        next: '31.3',
        isVisible: true,
      },
      {
        status: 'blind',
        id: '31.4',
        level: 3,
        path: '#',
        name: '',
        label: 'H',
        icon: undefined,
        isVisible: true,
      },
    ],
    selected: false,
    status: 'normal',
  },
  {
    parent: 'A',
    id: '21',
    level: 2,
    path: 'nfc',
    name: 'NFC',
    label: 'H',
    icon: 'CardTravelIcon',
    color: '',
    hasCollapsed: false,
    isVisible: true,
    subMenus: [
      {
        status: 'normal',
        id: '21.1',
        level: 3,
        path: '/administration/nfc/management',
        name: 'Management',
        label: 'H',
        icon: 'ViewQuiltOutlinedIcon',
        prev: '2',
        next: '21.2',
        isVisible: true,
      },
      {
        status: 'normal',
        id: '21.2',
        level: 3,
        path: '/administration/nfc/new',
        name: 'New',
        label: 'H',
        icon: 'AddToPhotosOutlinedIcon',
        prev: '21.1',
        next: '21.3',
        isVisible: true,
      },
      {
        status: 'normal',
        id: '21.3',
        level: 3,
        path: '/administration/nfc/change-owner',
        name: 'Change Owner',
        label: 'H',
        icon: 'SwapCallsIcon',
        prev: '21.2',
        next: '21.4',
        isVisible: true,
      },
      {
        status: 'blind',
        id: '21.4',
        level: 3,
        path: '#',
        name: '',
        label: 'H',
        icon: undefined,
        isVisible: true,
      },
    ],
    selected: false,
    status: 'normal',
  },
  {
    parent: 'A',
    id: '22',
    level: 2,
    path: 'security',
    name: 'Security',
    label: 'H',
    icon: 'SecurityIcon',
    color: '',
    hasCollapsed: true,
    status: 'normal',
    isVisible: true,
    subMenus: [
      {
        status: 'normal',
        id: '22.1',
        level: 3,
        path: '/administration/security/roles',
        name: 'Rol',
        label: 'H',
        icon: 'AssignmentIcon',
        prev: '22',
        next: '22.2',
        isVisible: true,
      },
      {
        status: 'normal',
        id: '22.2',
        level: 3,
        path: '/administration/security/users',
        name: 'Users',
        label: 'H',
        icon: 'AccountBoxIcon',
        prev: '22.1',
        next: '22.3',
        isVisible: true,
      },
      {
        status: 'blind',
        id: '22.3',
        level: 3,
        path: '#',
        name: '',
        label: 'H',
        icon: undefined,
      },
    ],
    selected: false,
  },
];
