import { format, addDays } from 'date-fns';
import { patterHeaderStyle, patternCellStyle } from '../../table/stylesReport';

const date = new Date();
const now = format(addDays(date, -1), "yyyy-MM-dd'T'HH:MM");
const now24 = format(date, "yyyy-MM-dd'T'HH:MM");

export const initFilter = {
  speed: 0,
  speedCondition: '0',
  startDateTime: now,
  endDateTime: now24,
};
const nowDate = format(addDays(date, -1), 'yyyy-MM-dd');
const nowDate24 = format(date, 'yyyy-MM-dd');

export const initDateFilter = {
  startDateTime: nowDate,
  endDateTime: nowDate24,
};
export const listEvents = [
  { id: 18, name: 'Positions' },
  { id: 2, name: 'stops' },
  { id: 3, name: 'Ignition' },
  { id: 4, name: 'Sensors' },
  { id: 5, name: 'Regions' },
  { id: 6, name: 'Alarms' },
  { id: 7, name: 'SerialPort' },
  { id: 8, name: 'TabletEvents' },
];

export const optionsSpeedCondition = [
  { value: '0', text: 'Speed' },
  { value: 'gto', text: '>=' },
  { value: 'lwo', text: '<=' },
  { value: 'equ', text: '=' },
];

export const columnsTable = [
  {
    title: 'ASSETS',
    field: 'plates',
    cellStyle: {
      minWidth: '8%',
      maxWidth: '10%',

      ...patternCellStyle,
      paddingLeft: '8px',
    },
    headerStyle: {
      ...patterHeaderStyle,
      paddingLeft: '8px',
      // width: '80px',
    },
  },
  {
    title: 'MODEL',
    field: 'deviceModel',
    cellStyle: {
      minWidth: '7%',
      maxWidth: '8%',
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
      // width: '50px',
      // maxWidth: '120px',
    },
  },
  {
    title: 'function',
    field: 'description',
    cellStyle: {
      minWidth: '7%',
      maxWidth: '8%',
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
      // width: '50px',
      // maxWidth: '120px',
    },
  },
  {
    title: 'EVENTS',
    field: 'event',
    cellStyle: {
      minWidth: '8%',
      maxWidth: '12%',
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'FECHA_HORA_GPS',
    field: 'gpsDateTime',
    cellStyle: {
      ...patternCellStyle,
      width: '11%',
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'DATE_HOUR_RECEIVED',
    field: 'rcvDateTime',
    cellStyle: {
      ...patternCellStyle,
      width: '11%',
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'SPEED_FILTER',
    field: 'speedDirection',
    filtering: false,
    cellStyle: {
      // maxWidth: '10%',
      width: '3%',
      ...patternCellStyle,
    },
    headerStyle: { ...patterHeaderStyle },
  },
  {
    title: 'ODOMETER',
    field: 'odometer',
    filtering: false,
    cellStyle: {
      width: '6%',
      maxWidth: '7%',
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'IGNITION',
    field: 'ignition',
    // type: 'numeric',
    cellStyle: {
      width: '8%',
      maxWidth: '9%',
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'ADDRESS',
    field: 'address',
    filtering: false,
    cellStyle: {
      ...patternCellStyle,
      minWidth: '25%',
      width: '30%',
      maxWidth: '35%',
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
];
