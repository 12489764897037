import React, { useState, useEffect } from 'react';
import { useIntl } from '../../../lang/context/intlContext'; //addjust path reference

//#region components

import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import Portlet from '../../../components/Portlet/Portlet';
import InputText from '../../../components/InputText/InputText';
import PortletActions from '../../../components/Portlet/PorletActions';
import Button from '../../../components/CustomButton/Button';
import { FormNameIcon } from '../../../../assets/icons/formIcons';
import CustomizedSnackbars from '../../../components/CustomSnackBar/CustomSnackBar';
import { useFormStyle } from '../../../common/styles/formStyle';
import {
  CardContent,
  Checkbox,
  CircularProgress,
  InputLabel,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Step,
  StepLabel,
  Stepper,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PaymentIcon from '@material-ui/icons/Payment';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import SuperStepper from '../../../components/CustomStepper/SuperStepper';
import AutoCompleteBox from '../../../components/ComboBox/AutoCompleteBox';
import { useStoreActions, useStoreState } from 'easy-peasy';

import { getAllDrivers, getDriversNFC, getTableNFCS, getAssets, getTablets, getAllTablets } from '../api/getMethods';
import { postSetupNFC } from '../api/postMethods';
import { Loading } from '../../../../Splash';
import { getDriverType } from './helper';
import { ListWrap } from '../../../layout/components/ListWrap/ListWrap';
//#endregion

//Stepper helspers
const getSteps = () => {
  return ['Select Driver', 'Select NFC Card', 'Select Tablets', 'Review and Update'];
};

const LinkDriver = (props) => {
  const classes = useFormStyle();
  const { i18 } = useIntl();

  const [isLoading, setisLoading] = useState(false);
  const [openSnack, setOpenSnack] = React.useState({ open: false, title: '', severity: 'success' });
  const [activeStep, setActiveStep] = React.useState(0);

  const [driver, setDriver] = useState(null);
  const [selectedDriver, setSelectedDriver] = useState({
    driverId: '',
    CPF: '',
    typeDriver: -1,
    garageName: '',
    funcNumber: '',
    CNH: '',
    phoneNumber: '',
    clientName: '',
    RG: '',
    currentNFC: '',
  });
  const [nfc, setNFC] = useState(null);
  const [selectedTablets, setSelectedTablets] = useState([]);

  const [tablets, setTablets] = useState([]);
  const addDrivers = useStoreActions((actions) => actions.driver.addDrivers);
  const drivers = useStoreState((state) => state.driver.drivers);
  const addNFCS = useStoreActions((actions) => actions.nfc.addNFCS);
  const nfcs = useStoreState((state) => state.nfc.nfcs);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const getNFCSimple = (items) => {
    return items.map((item) => (
      <ListItem key={item.keyId}>
        <ListItemIcon>
          <PaymentIcon />
        </ListItemIcon>
        <ListItemText primary={item.vehicleName} secondary={item.client} />
        {/* <ListItemSecondaryAction onClick={() => removeNFC(item.NFC)}>
            <IconButton edge="end" aria-label="delete">
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction> */}
      </ListItem>
    ));
  };

  const steps = getSteps().map((item) => i18(item));

  //data operations
  const fetchData = async () => {
    setisLoading(true);
    const res = await getDriversNFC();
    addDrivers(res.data.driversDetails);
    const resData = await getTableNFCS();
    addNFCS(resData.data.nfcs);
    // if (!tablets.length) {
    // console.log('no data');
    // console.log('api');
    const resTablets = await getAllTablets();
    setTablets(resTablets.data.tablets.filter((item) => item.agentId > 0));
    setisLoading(false);
    // }
  };
  const onSubmit = async () => {
    const agentIds = selectedTablets.map((item) => item.agentId);
    // console.info({ nfc.nfcId, agentIds, driver });
    return postSetupNFC(nfc.nfcId, agentIds, driver.driverId);
  };
  useEffect(() => {
    fetchData();
  }, []);
  if (isLoading)
    return (
      <GridContainer className={classes.content}>
        <GridItem item xs={12} md={12} lg={12}>
          <Loading></Loading>
        </GridItem>{' '}
      </GridContainer>
    );
  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <GridItem container justify="center">
            <GridItem item xs={12} md={10} lg={10}>
              <InputLabel className={classes.listLabel}>{i18('Drivers')}</InputLabel>
              <AutoCompleteBox
                style={{ marginTop: '4px' }}
                // options={drivers.map((item) => item.name + ' ' + item.lastname + ' (' + item.driverId + ')')}
                options={drivers}
                getOptionLabel={(item) => item.name + ' ' + item.lastname + ' (' + item.driverId + ')'}
                // renderOption={(option) => <React.Fragment>{option.name}</React.Fragment>}
                value={driver}
                onChange={(e, selected) => {
                  // console.log(selected);
                  const currentNFC = selected.NFC.length
                    ? { ...selected.NFC[0], isCurrent: true }
                    : { NFC: '', isCurrent: false, fullName: '' };
                  if (currentNFC.isCurrent) {
                    setNFC(currentNFC);
                  } else setNFC(null);
                  setDriver({ ...selected, currentNFC: currentNFC.fullName });
                  setSelectedDriver({ ...selected, currentNFC: currentNFC.fullName });
                }}
              ></AutoCompleteBox>
            </GridItem>
            <GridItem container xs={12} md={10} lg={10} spacing={2}>
              <GridItem item xs={12} md={4} lg={4}>
                <InputText
                  id="driverNFC"
                  name="driverNFC"
                  // defaultValue={driver ? driver.driverId : undefined}
                  value={selectedDriver.currentNFC}
                  readOnly={true}
                  label={i18('CURRENT_NFC')}
                  icon={FormNameIcon}
                  fullWidth
                ></InputText>
                {/* <InputText
                  id="driverId"
                  name="driverId"
                  // defaultValue={driver ? driver.driverId : undefined}
                  value={selectedDriver.driverId}
                  readOnly={true}
                  label={i18('DRIVER_ID')}
                  icon={FormNameIcon}
                  fullWidth
                ></InputText> */}
              </GridItem>
              <GridItem item md={4} lg={4}>
                <InputText
                  id="cpf"
                  name="cpf"
                  // value={driver?.CPF}
                  value={selectedDriver.CPF}
                  //   defaultValue={'181.548.108-08'}
                  label={i18('cpf')}
                  icon={FormNameIcon}
                  fullWidth
                ></InputText>
              </GridItem>
              <GridItem item xs={12} md={4} lg={4}>
                <InputText label={i18('Client')} icon={FormNameIcon} value={selectedDriver.clientName} name="client"></InputText>
              </GridItem>
              <GridItem item xs={12} md={4} lg={4}>
                <InputText
                  id="type"
                  value={getDriverType(selectedDriver.typeDriver)}
                  name="type"
                  label={i18('type')}
                  icon={FormNameIcon}
                  fullWidth
                ></InputText>
              </GridItem>
              <GridItem item xs={12} md={4} lg={4}>
                <InputText
                  id="garage"
                  name="garage"
                  value={selectedDriver.garageName}
                  label={i18('garage')}
                  icon={FormNameIcon}
                  fullWidth
                ></InputText>
              </GridItem>
              <GridItem item xs={12} md={4} lg={4}>
                <InputText
                  id="rg"
                  name="rg"
                  value={selectedDriver.RG}
                  label={i18('rg')}
                  icon={FormNameIcon}
                  fullWidth
                ></InputText>
              </GridItem>
              <GridItem item md={4} lg={4}>
                <InputText
                  id="funcNumber"
                  name="funcNumber"
                  value={selectedDriver.funcNumber}
                  label={i18('funcNumber')}
                  icon={FormNameIcon}
                  fullWidth
                ></InputText>
              </GridItem>
              <GridItem item md={4} lg={4}>
                <InputText
                  id="cnh"
                  value={selectedDriver.CNH}
                  name="cnh"
                  label={i18('cnh_numero')}
                  icon={FormNameIcon}
                  fullWidth
                ></InputText>
              </GridItem>{' '}
              <GridItem item md={4} lg={4}>
                <InputText
                  id="celullar"
                  name="celullar"
                  value={selectedDriver.phoneNumber}
                  label={i18('celullar')}
                  icon={FormNameIcon}
                  fullWidth
                ></InputText>
              </GridItem>
            </GridItem>
          </GridItem>
        );
      case 1:
        return (
          <GridItem justify="center" container>
            <GridItem item xs={12} md={10} lg={10}>
              <InputLabel className={classes.listLabel}>{i18('NFC')}</InputLabel>
              <AutoCompleteBox
                style={{ marginTop: '4px' }}
                options={nfcs.filter((item) => item.status === 0 || item.driverId === driver.driverId)}
                // options={nfcs}
                getOptionLabel={(item) => (item.isCurrent ? item.fullName + ' (' + i18('CURRENT_NFC') + ')' : item.fullName)}
                value={nfc}
                onChange={(e, newValue) => setNFC(newValue)}
              ></AutoCompleteBox>
            </GridItem>
            <GridItem container xs={12} md={10} lg={10} spacing={2}></GridItem>
          </GridItem>
        );
      case 2:
        return (
          <GridItem justify="center" container spacing={2}>
            <GridItem item xs={12} md={5} lg={5}>
              <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                // options={tablets.map((item) => ({ title: item.vehicleName }))}
                options={tablets}
                disableCloseOnSelect
                getOptionLabel={(option) => option.vehicleName}
                onChange={(prev, newValue) => setSelectedTablets(newValue)}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                    {option.vehicleName + '-' + option.device}
                  </React.Fragment>
                )}
                style={{ width: 500 }}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label={i18('tablets')} placeholder={i18('SELECTED_TABLETS')} />
                )}
              />
            </GridItem>
            <GridItem container xs={12} md={10} lg={10} spacing={2}></GridItem>
          </GridItem>
        );
      case 3:
        return (
          <GridItem justify="center" container spacing={2}>
            <GridItem item xs={12} md={5} lg={5}>
              <InputText
                id="driver"
                name="driver"
                value={driver?.name + ' ' + driver?.lastname + ' (' + driver?.driverId + ')'}
                readOnly={true}
                label={i18('Driver')}
                icon={FormNameIcon}
                fullWidth
              ></InputText>
            </GridItem>
            <GridItem item xs={12} md={5} lg={5}>
              <InputText
                id="nfc"
                name="nfc"
                value={nfc?.fullName}
                //item.isCurrent ? item.NFC + ' (' + i18('CURRENT_NFC') + ')' : item.serial + ' - ' + item.NFC
                readOnly={true}
                label={i18('nfc')}
                icon={FormNameIcon}
                fullWidth
              ></InputText>
            </GridItem>
            <GridItem item xs={12} md={5} lg={5} style={{ height: '400px' }}>
              <ListWrap
                title={i18('SELECTED_TABLETS')}
                classes={classes}
                customStyle={{
                  marginTop: '4px',
                  maxWidth: '100%',
                  height: '300px',
                  backgroundColor: '#f8f9fd',
                  maxHeight: '300px',
                  overflow: 'auto',
                }}
              >
                {getNFCSimple(selectedTablets)}
              </ListWrap>
            </GridItem>
            <GridItem item xs={12} md={5} lg={5}></GridItem>
          </GridItem>
        );
      default:
        return i18('UPDATE_SUCESSFUL');
    }
  };

  return (
    <div>
      <SuperStepper
        validators={[driver, nfc, selectedTablets, driver && nfc && selectedTablets]}
        labelFinalStep={'Save'}
        steps={steps}
        getStepContent={getStepContent}
        onSubmit={onSubmit}
        onResetSubmit={() => window.location.reload()}
      ></SuperStepper>
      <CustomizedSnackbars
        open={openSnack.open}
        setOpen={setOpenSnack}
        title={openSnack.title}
        severity={openSnack.severity}
      ></CustomizedSnackbars>
    </div>
  );
};

export default LinkDriver;
