import React, { useState, useEffect } from 'react';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import { makeStyles } from '@material-ui/core/styles';
import { CardContent } from '@material-ui/core';
import TabletMacIcon from '@material-ui/icons/TabletMac';
import PortletIcon from '../../../components/Portlet/PortletIcon';

import { useForm } from 'react-hook-form';
import { useIntl } from '../../../lang/context/intlContext';

import { GET_SPOKEN_ROUTES_STATUS } from './api/getMethods';
import useAxios from 'axios-hooks';
import { TableProvider } from '../../../components/Table/TableContext';
import TablePaper from '../../../components/Table/TablePaper';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Loading } from '../../../../Splash';

const useStyleGrid = makeStyles((theme) => ({
  proto: { maxHeight: 'calc(100vh-600px)' },
  content: {
    display: 'flex',
    justifyContent: 'center',
    height: theme.spacing(45),
  },
  alert: { margin: theme.spacing(2) },
}));

function Status({ history, match, location }) {
  const { i18 } = useIntl();
  const { setPrimaryButton, setCurrentRegions, setNoButtons, initRotograma } = useStoreActions((actions) => {
    const { rotograma, globalState } = actions;
    return { ...rotograma, ...globalState };
  });
  const [tableApi, setTableApi] = useState(null);
  const { register, handleSubmit, errors } = useForm();
  const classes = useStyleGrid();

  const token = match?.params?.id || 'all';
  // console.log('token', token);

  const { isLoading, name } = useStoreState((state) => state.rotograma);
  const [{ data: getData, loading: getLoading, error: getError }, fetchData] = useAxios(GET_SPOKEN_ROUTES_STATUS);
  //#region Effects
  useEffect(() => {
    //siez
    console.log('size butt');
    setNoButtons();
    setPrimaryButton({
      text: i18('refresh'),
      isEnabled: true,
      fnc: () => window.location.reload(),
      disabled: false,
    });
    fetchData();
    //Clean compile and pusbli
  }, []);
  // useEffect(() => {
  //   setTimeout(() => {
  //     console.log('iniinininini');
  //     initRotograma();
  //   }, 2000);
  // }, []);
  // useEffect(() => {
  //   console.log('location effect ');
  //   console.log('location.pathname :>> ', location.pathname);
  //   console.log('putData', putData);
  //   if (location.pathname === '/mfis/rotograma/publish/post') {
  //     if (!isLoading && !putData) {
  //       console.log('lastCompiled', lastCompiled);
  //       const body = { tablets: getCheckedRowsWithField(), lastCompiled };
  //       console.info('updating');
  //       updateData(body);
  //     }
  //   }
  // }, [location]);

  //#endregionS

  // console.log('data');
  // console.info(getData);
  //#region Error and Redirect
  if (getLoading || isLoading)
    return (
      <GridContainer className={classes.content}>
        <GridItem item xs={12} md={12} lg={12}>
          <Loading></Loading>
        </GridItem>{' '}
      </GridContainer>
    ); // <p>Loading...</p>;

  if (getError) return <p>redirect</p>;
  // if (!isLoading && putData) {
  //   console.info(putData);
  //   return <Redirect to={`/mfis/rotograma/management`} />;
  // }

  //#endregion
  //#region TableDefinitions
  const { data: dataTablets } = getData;
  //Clean complie an to publish id

  const headCellsTablets = [
    {
      id: 'rootField',
      numeric: false,
      align: 'left',
      disablePadding: true,
      label: i18('Plate'),
      width: '100px',
    },
    // { id: 'plates', numeric: true, align: 'left', disablePadding: false, label: i18('Plate'), width: '150px' },
    // { id: 'vehicleModel', numeric: true, align: 'left', disablePadding: false, label: i18('vehicleModel'), width: '150px' },
    { id: 'tablet', numeric: true, align: 'left', disablePadding: false, label: i18('tablet'), width: '100px' },
    { id: 'name', numeric: true, align: 'left', disablePadding: false, label: i18('SPOKENROUTE'), width: '200px' },
    { id: 'status', numeric: true, align: 'left', disablePadding: false, label: i18('status'), width: '100px' },
    { id: 'userName', numeric: true, align: 'left', disablePadding: false, label: i18('User'), width: '100px' },
    {
      id: 'published_at',
      numeric: true,
      align: 'left',
      disablePadding: false,
      label: i18('PUBLISHED_AT'),
      width: '180px',
      defaultOrder: true,
      sort: 'Desc',
    },
    { id: 'updated_at', numeric: true, align: 'left', disablePadding: false, label: i18('UPDATED_AT'), width: '180px' },
    { id: 'client', numeric: true, align: 'left', disablePadding: false, label: i18('Client'), width: 'auto' },
    // { id: 'radius', numeric: true, align: 'left', disablePadding: false, label: i18('radius'), width: '250px' },
    // { id: 'address', numeric: true, align: 'left', disablePadding: false, label: i18('address'), width: 'auto' },
  ];
  // const optionsGroup = getOptionGroups(dataTablets, true);
  // const filterOptions = {
  //   // group: { field: 'rootField', value: [] },
  //   tablet: { field: 'tablet', value: [] },
  //   all: { field: 'all', value: [] },
  // };
  const filterInputs = [
    { label: 'plate', field: 'rootField' },
    { label: 'tablet', field: 'tablet' },
    { label: 'SPOKENROUTE', field: 'name' },
    // { label: 'status', field: 'status' },
    // { label: 'client', field: 'client' },
  ];
  function onTableReady(params) {
    const { api } = params;
    // setTableApi(api);
  }

  //#endregion
  const onSubmit = () => {};
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <GridContainer className={classes.content}>
          <GridItem item xs={12} md={12} lg={12}>
            <PortletIcon title={i18('TABLETS_STATUS')} subTitle={i18('TABLETS_STATUS_SUBHEADER') + name} hasCustomStartIcon={true} StartIcon={TabletMacIcon}>
              <CardContent style={{ paddingTop: '0px' }}>
                <GridContainer direction="column" spacing={2}>
                  <GridItem item xs={12} md={12}>
                    <TableProvider loadData={dataTablets} columns={headCellsTablets} checkedItems={[]} initRowsPerPage={30} withPagination={true} onContextReady={onTableReady} useV2={true}>
                      <TablePaper maxHeight={'450px'} filterInputs={filterInputs} hasCheckBox={false} hasGroups={true} hasSearchAllFields={true}></TablePaper>
                    </TableProvider>
                  </GridItem>
                </GridContainer>
              </CardContent>
              <></>
              {/*<PortletActions> Si se activa portleet icons, eliminar la linea anterios
               <div className={classes.div}>
                  <div className={classes.inDiv}>
                    <Button variant="contained" disabled={putLoading} color={'cancel'}>
                      {i18('Cancel')}
                    </Button>
                  </div>
                  <div className={classes.inDiv}>
                    <Button type="submit" disabled={putLoading} variant="contained" color={'primary'}>
                      {i18('Create')}
                    </Button>
                    {putLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </div>
                </div> 
              </PortletActions>*/}
              {/* {(putError || apiError) && (
                <Alert className={classes.alert} severity="error">
                  {i18('API_ERR')}
                </Alert>
              )} */}
            </PortletIcon>
          </GridItem>
        </GridContainer>
      </form>
    </div>
  );
}

export default Status;
