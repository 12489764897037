import React from 'react';
// nodejs library that concatenates classes
import clsx from 'clsx';
// nodejs library to set properties for components
import PropTypes from 'prop-types';

// material-ui components
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import InputBase from '@material-ui/core/InputBase';
import InputText from '../InputText/InputText';
import { FormNameIcon, FormDescriptionIcon } from '../../../assets/icons/formIcons';
import { v4 as uuidv4 } from 'uuid';
import { useIntl } from '../../lang/context/intlContext';
import { useForm } from 'react-hook-form';

import { makeStyles } from '@material-ui/core/styles';
import { WHITE, CANCEL_COLOR, HOVER_COLOR, INPUT_BACKGROUND } from '../styles/constValues';

//array options: value and text

/**
 * Consumer should add id, name, value,onChange and defaultValue if needed
 * @param {any} param0
 */
function ComboBox({ ...props }) {
  const {
    disabled = false,
    className,
    id,
    forRef,
    name,
    seleccionado,
    cancel,
    defaultValue,
    size,
    color,
    children,
    options,
    inputRef,
    onChange,
    value,
    ...rest
  } = props;
  return (
    <Select
      disabled={disabled}
      className={className}
      id={id}
      name={name}
      inputRef={forRef}
      defaultValue={defaultValue}
      onChange={onChange}
      style={{ width: '100%' }}
      value={value}
      input={<InputText {...rest} fullWidth></InputText>}
    >
      {/* {children} */}
      {options.map((item) => (
        <MenuItem key={uuidv4()} value={item.value}>
          {item.text}
        </MenuItem>
      ))}
    </Select>
  );
}

export default ComboBox;
