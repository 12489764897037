import axios from 'axios';

export const LOGIN_URL = 'https://api3.itsmil.com:2017/auth/login';
export const REGISTER_URL = 'https://api3.itsmil.com:2017/auth/register';
export const REQUEST_PASSWORD_URL = 'auth/forgot-password';

export const ME_URL = 'auth/me';

export function login(email, password) {
  return axios.post(LOGIN_URL, { username: email, email, password });
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}
