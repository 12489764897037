import React, { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Router from './app/Router';
import { AuthContext } from './app/auth/context/auth';
import { IntlProvider } from './app/lang/context/intlContext';
import { StoreProvider, useStoreRehydrated } from 'easy-peasy';
import store from './state/index';
import Splash from './Splash';
import { useDebounce } from 'use-debounce';
function App() {
  const WithSplash = () => {
    const isRehydrated = useStoreRehydrated();
    return (
      <div>
        {isRehydrated ? (
          <Router />
        ) : (
          <div className="container-fluid">
            <Splash />
          </div>
        )}
      </div>
    );
  };

  return (
    <BrowserRouter>
      <StoreProvider store={store}>
        <IntlProvider>
          <WithSplash />
        </IntlProvider>
      </StoreProvider>
    </BrowserRouter>
  );
}

export default App;
