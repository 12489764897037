export const administrationTitle = [
  { path: '/administration/clients/management', title: 'Clients', subTitle: 'Management' },
  { path: '/administration/clients/new', title: 'Client', subTitle: 'New' },
  { path: '/administration/clients/edit', title: 'Client', subTitle: 'Edit' },
  { path: '/administration/devices/management', title: 'Devices', subTitle: 'Management' },
  { path: '/administration/devices/new', title: 'Device', subTitle: 'New' },
  { path: '/administration/devices/edit', title: 'Device', subTitle: 'Edit' },
  { path: '/administration/vehicles/add-device', title: 'Vehicles', subTitle: 'ADD_DEVICE' },
  { path: '/administration/vehicles/management', title: 'Vehicles', subTitle: 'Management' },
  { path: '/administration/vehicles/recovery', title: 'Vehicles', subTitle: 'Recovery' },
  { path: '/administration/vehicles/new', title: 'Vehicles', subTitle: 'New' },
  { path: '/administration/vehicles/edit', title: 'Vehicles', subTitle: 'Edit' },
  { path: '/administration/vehicles/add-device', title: 'Vehicles', subTitle: 'ADD_DEVICE' },
  { path: '/administration/drivers/management', title: 'Drivers', subTitle: 'Management' },
  { path: '/administration/drivers/new', title: 'Drivers', subTitle: 'New' },
  { path: '/administration/drivers/edit', title: 'Drivers', subTitle: 'Edit' },
  { path: '/administration/drivers/nfc-setup', title: 'Drivers', subTitle: 'NFC Setup' },
  { path: '/administration/nfc/new', title: 'NFC Cards', subTitle: 'Registration' },
  { path: '/administration/nfc/management', title: 'NFC Cards', subTitle: 'Management' },
  { path: '/administration/nfc/change-owner', title: 'NFC Cards', subTitle: 'Change Owner' },
  { path: '/administration/security/roles', title: 'Security', subTitle: 'Roles' },
  { path: '/administration/security/users', title: 'Security', subTitle: 'Users' },
];
