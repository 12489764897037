import { whiteColor } from '../components/styles/constValues';
import palette from './palette';

export default {
  subtitle1: {
    colorPrimary: palette.text.primary,
  },
  subtitle2: { color: palette.secondary.contrastText, fontSize: '24px', fontWeight: 500 },
  h3: {
    color: palette.text.primary,
    fontSize: '30px',
  },
  h4: {
    color: palette.text.primary,
    fontSize: '14px',
    fontFamily: 'Roboto',
    fontWeight: 'Bold',
    letterSpacing: '0.42px',
    textAlign: 'left',
  },
  h5: {
    color: palette.text.primary,
    fontWeight: 500,
  },
  h6: {
    color: palette.text.primary,
  },

  body1: {
    color: palette.text.secondary,
  },
  body2: { color: palette.secondary.contrastText, fontSize: '16px' },

  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    'Roboto-Bold',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
};
