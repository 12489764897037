import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Chip, IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Typography } from '@material-ui/core';

import { AnimatePresence, motion } from 'framer-motion';
import { getIcon } from '../../../components/Macro/helper';
import { ActionDelete } from '../../../../assets/icons';
import { v4 as uuidv4 } from 'uuid';
import { macroStyles } from '../../../common/styles/macroStyle';
import { INPUT_BACKGROUND } from '../../../components/styles/constValues';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    // maxWidth: 360,
    backgroundColor: INPUT_BACKGROUND,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 250,
    height: 250,
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
}));

function getItems(props) {
  const { items, classes, hasDelete = false, onClickDelete = undefined } = props;

  return items.map((item, index) => <ListItemEmail key={'email' + index} {...item} classes={classes} hasDelete={hasDelete} onClickDelete={onClickDelete} />);
}

const ListEmail = (props) => {
  const classes = useStyles();
  const classesMacro = macroStyles();
  return (
    <List className={classes.root} dense={true}>
      {getItems({ ...props, classes: classesMacro })}
    </List>
  );
};

/**
 * Pure Componente
 * @param {object} props
 */
const ListItemEmail = (props) => {
  const { classes, title, color = 'primary', chipLabel = undefined, chipColor = 'primary', type = 9, hasDelete, onClickDelete } = props;
  const classNameIcon = color === 'primary' ? classes.listItemAvatarSizePrimary : classes.listItemAvatarSizeSecondary;

  return (
    <ListItem classes={{ gutters: classes.listItemGutters }} button>
      <ListItemAvatar classes={{ root: classes.listItemAvatarRoot }}>
        <Avatar className={classes.listItemAvatarWrap}>{getIcon(type, color, classNameIcon)}</Avatar>
      </ListItemAvatar>
      <ListItemText primary={<Typography className={classes.listItemText}>{title}</Typography>} />
      {chipLabel && <Chip label={chipLabel} color={chipColor} size="small" />}
      <ListItemSecondaryAction>{hasDelete && <ActionDelete onClick={(e) => onClickDelete(title)}></ActionDelete>}</ListItemSecondaryAction>
    </ListItem>
  );
};

export default ListEmail;
