import socketClient from 'socket.io-client';

let socket;
const { REACT_APP_REAL_TIME_ENDPOINT } = process.env; //'https://evasoft.cl:8091'; //process.env;

export const initiateSocket = (deviceIds, devicesOnMap) => {
  console.log('REACT_APP_REAL_TIME_ENDPOINT');
  console.log(REACT_APP_REAL_TIME_ENDPOINT);
  // socket = socketClient('https://api3.itsmil.com:8071');
  socket = socketClient(REACT_APP_REAL_TIME_ENDPOINT);

  // socket = socketClient('http://localhost:8081');
  // socket = socketClient(REACT_APP_REAL_TIME_ENDPOINT);
  if (socket && devicesOnMap.length) {
    console.log('registering device Ids');
    registerDevices(devicesOnMap);
  }
  console.log(`Connecting socket...`);
};
export const registerDevices = (deviceIds) => {
  if (socket) {
    socket.emit('registerbyid', deviceIds);
  }
};
export const disconnectSocket = () => {
  console.log('Disconnecting socket...');
  if (socket) socket.disconnect();
};
export const subscribeToChat = (cb) => {
  if (!socket) return true;
  socket.on('txMessage', (msg) => {
    return cb(null, msg);
  });
};
export const sendMessage = (room, message) => {
  if (socket) socket.emit('chat', { message, room });
};
