import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox, Typography } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { useIntl } from '../../../lang/context/intlContext';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import { useStoreActions, useStoreState } from 'easy-peasy';

import Button from '../../../components/CustomButton/Button';

import AutoCompleteBox from '../../../components/ComboBox/AutoCompleteBox';
import { getAllDVR } from '../../administration/api/getMethods';
import InputTimeDate from '../../../components/InputTimeDate/InputTimeDate';
import { FormNameIcon } from '../../../../assets/icons/formIcons';
import { compareAsc, differenceInMonths, parseISO } from 'date-fns';
import { initFilter, evidenceLevel } from './filterData';

const useStyleGrid = makeStyles((theme) => ({
  proto: { maxHeight: 'calc(100vh-600px)' },
  content: {
    display: 'flex',
    justifyContent: 'center',
    height: theme.spacing(45),
  },
  alert: { margin: theme.spacing(2) },
  div: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    // justifyContent: 'end',
    // padding: theme.spacing(4),
  },
  formStyle: { width: '100%' },
  inDiv: {
    padding: theme.spacing(2),
    position: 'relative',
  },
}));

const Filter = (props) => {
  const { onSubmit, filters, bodyDefinition } = props;
  const { i18 } = useIntl();
  const classes = useStyleGrid();
  const [isLoading, setIsLoading] = useState(false);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const addDVRS = useStoreActions((actions) => actions.asset.addDVRS);
  const dvrs = useStoreState((state) => state.asset.DVRS);

  // Assets
  const [selectedTabletsAsset, setSelectedTabletsAsset] = useState([]);
  const [TabletsAsset, setTabletsAsset] = useState([]);
  // Drivers
  const [selectedDriverId, setSelectedDriverId] = useState([]);
  const drivers = useStoreState((state) => state.user.drivers);
  // Evidence level
  const [selectedEvidenceLevel, setSelectedEvidenceLevel] = useState([]);
  // Type Reconcile
  const [selectedEvidenceType, setSelectedEvidenceType] = useState([]);
  const typeEvidence = useStoreState((state) => state.user.evidences.types);
  // status
  const [selectedEvidenceStatus, setSelectedEvidenceStatus] = useState([]);
  const status = useStoreState((state) => state.user.evidences.status);

  const [formValues, setFormValues] = useState(initFilter);
  const { startDateTime, endDateTime } = formValues;
  const [monthError, setMonthError] = useState(false);
  const [dateError, setDateError] = useState(false);

  const handleInputChange = ({ target }) => {
    switch (target.name) {
      case 'startDateTime':
        setFormValues({
          ...formValues,
          [target.name]: target.value,
        });
        const months = differenceInMonths(parseISO(endDateTime), parseISO(target.value));
        if (months > 6) {
          setMonthError(true);
        } else {
          setMonthError(false);
        }
        break;
      case 'endDateTime':
        setFormValues({
          ...formValues,
          [target.name]: target.value,
        });
        const result = compareAsc(parseISO(startDateTime), parseISO(target.value));
        if (result > 0) {
          setDateError(true);
        } else {
          setDateError(false);
        }
        const months1 = differenceInMonths(parseISO(target.value), parseISO(startDateTime));
        if (months1 > 6) {
          setMonthError(true);
        } else {
          setMonthError(false);
        }
        break;
      default:
        setFormValues({
          ...formValues,
          [target.name]: target.value,
        });
        break;
    }
  };
  const handleReset = (e) => {
    setTabletsAsset([]);
    setSelectedTabletsAsset([]);
  };
  const handleSubmitForm = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const { startDateTime, endDateTime } = formValues;
      const data = {
        startDateTime,
        endDateTime,
        dvrs: selectedTabletsAsset,
        drivers: selectedDriverId,
        evidenceLevel: selectedEvidenceLevel,
        evidenceStatus: selectedEvidenceStatus,
        evidenceType: selectedEvidenceType,
      };
      await onSubmit(data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const fetchData = async () => {
    if (dvrs.length) return;
    const { data } = await getAllDVR();
    addDVRS(data.dvr);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <form onSubmit={handleSubmitForm}>
      <GridContainer direction="column">
        <GridItem container xs={12} md={12} spacing={2}>
          {/* FILTER */}
          <GridItem item xs={3} md={3}>
            <AutoCompleteBox
              label={i18('dvrs')}
              multiple
              name="dvrs"
              id="checkboxes-tags-demo"
              options={dvrs}
              disableCloseOnSelect
              value={selectedTabletsAsset}
              getOptionLabel={(option) => option.asset}
              onChange={(prev, newValue) => {
                setSelectedTabletsAsset(newValue);
              }}
              limitTags={10}
              noOptionsText={i18('SELECT_A_TABLET')}
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                  {option.asset}
                </React.Fragment>
              )}
              style={{
                maxHeight: '230px',
                marginRight: 15,
              }}
            />
          </GridItem>
          <GridItem item xs={3} md={3} lg={3}>
            <AutoCompleteBox
              label={i18('Driver')}
              multiple
              id="checkboxes-tags-demo"
              options={drivers}
              value={selectedDriverId}
              disableCloseOnSelect
              getOptionLabel={(option) => option.fullNameDriverId}
              onChange={(prev, newValue) => setSelectedDriverId(newValue)}
              limitTags={10}
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                  {option.fullNameDriverId}
                </React.Fragment>
              )}
              style={{ marginRight: 15 }}
            />
          </GridItem>
          <GridItem item xs={2} md={2} lg={2}>
            <AutoCompleteBox
              label={i18('NIVEL_EVIDENCE')}
              multiple
              options={evidenceLevel}
              disableCloseOnSelect
              value={selectedEvidenceLevel}
              getOptionLabel={(option) => option.text}
              onChange={(prev, newValue) => setSelectedEvidenceLevel(newValue)}
              limitTags={10}
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                  {option.text}
                </React.Fragment>
              )}
              style={{ marginRight: 15 }}
            />
          </GridItem>
          <GridItem item xs={2} md={2} lg={2}>
            <AutoCompleteBox
              multiple
              label={i18('TYPE_EVIDENCE')}
              options={typeEvidence}
              value={selectedEvidenceType}
              disableCloseOnSelect
              getOptionLabel={(option) => option.text.substring(0, 4)}
              onChange={(prev, newValue) => setSelectedEvidenceType(newValue)}
              limitTags={10}
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                  {option.text}
                </React.Fragment>
              )}
              style={{ marginRight: 15 }}
            />
          </GridItem>
          <GridItem item xs={2} md={2} lg={2}>
            <AutoCompleteBox
              label={i18('STATUS')}
              multiple
              options={status}
              disableCloseOnSelect
              value={selectedEvidenceStatus}
              getOptionLabel={(option) => option.text}
              onChange={(prev, newValue) => setSelectedEvidenceStatus(newValue)}
              limitTags={10}
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                  {option.text}
                </React.Fragment>
              )}
              style={{ marginRight: 15 }}
            />
          </GridItem>
          <GridItem item xs={3} md={3}>
            <InputTimeDate
              id="startDateTime"
              name="startDateTime"
              label={i18('START_DATE_TIME')}
              // style={{ paddingRight: '0px' }}
              icon={FormNameIcon}
              value={startDateTime}
              onChange={handleInputChange}
            />
          </GridItem>
          <GridItem item xs={3} md={3}>
            <InputTimeDate id="endDateTime" name="endDateTime" label={i18('END_DATE_TIME')} icon={FormNameIcon} value={endDateTime} onChange={handleInputChange} />
            {dateError && <Typography color="secondary">{i18('ERROR_DATE')}</Typography>}
            {monthError && <Typography color="secondary">{i18('ERROR_MONTH')}</Typography>}
          </GridItem>
          <GridItem item md={6} lg={6}>
            <div className={classes.div}>
              <div className={classes.inDiv}>
                <Button onClick={handleReset} type="button" variant="contained" color={'cancel'}>
                  {i18('CLEAR')}
                </Button>
              </div>
              <div className={classes.inDiv}>
                <Button type="submit" variant="contained" color={'primary'} disabled={isLoading}>
                  {i18('SEARCH')}
                </Button>
              </div>
            </div>
          </GridItem>
        </GridItem>
      </GridContainer>
    </form>
  );
};

export default Filter;
