import { format, addMonths } from 'date-fns';
import { patterHeaderStyle, patternCellStyle } from '../../table/stylesReport';

const date = new Date();
const now1Month = format(addMonths(date, -1), "yyyy-MM-dd'T'HH:MM");
const now24 = format(date, "yyyy-MM-dd'T'HH:MM");

export const initFilterDriverLogin = {
  startDateTime: now1Month,
  endDateTime: now24,
};

export const columnsTableDriverLogin = [
  {
    title: 'NAME',
    defaultGroupOrder: 0,
    field: 'driverIdDriver',
    cellStyle: {
      minWidth: '8%',
      maxWidth: '10%',
      ...patternCellStyle,
      paddingLeft: '8px',
    },
    headerStyle: {
      ...patterHeaderStyle,
      paddingLeft: '8px',
    },
  },
  {
    title: 'ASSETS',
    field: 'plates',
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'MODEL',
    field: 'deviceModel',
    width: null,
    cellStyle: {
      minWidth: '30px',
      maxWidth: '35px',
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'FECHA_HORA_GPS',
    field: 'gpsDateTime',
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'DATE_HOUR_DEV',
    field: 'rcvDateTime',
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'VALID_GPS',
    field: 'isValidGPS',
    filtering: false,
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: { ...patterHeaderStyle },
  },
  {
    title: 'ODOMETER',
    field: 'odometer',
    width: null,
    filtering: false,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'IGNITION',
    field: 'ignition',
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'PHOTO',
    field: 'urlPhoto',
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
];
