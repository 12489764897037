import React, { useState } from 'react';
import { useStoreState } from 'easy-peasy';

import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { CardContent, Checkbox, CircularProgress } from '@material-ui/core';

import { postLastPosition } from '../../api/postMethods';
import { useIntl } from '../../../../lang/context/intlContext';
import { useFormStyle } from '../../../../common/styles/formStyle';

import Button from '../../../../components/CustomButton/Button';
import GridContainer from '../../../../components/Grid/GridContainer';
import GridItem from '../../../../components/Grid/GridItem';
import Portlet from '../../../../components/Portlet/Portlet';
import PortletActions from '../../../../components/Portlet/PorletActions';
import CustomizedSnackbars from '../../../../components/CustomSnackBar/CustomSnackBar';
import AutoCompleteBox from '../../../../components/ComboBox/AutoCompleteBox';
import { Loading } from '../../../../../Splash';
import TableReport from '../../table/TableReport';
import { initSnack } from '../../table/stylesReport';

import { columnsTable } from './dataLastPositions';

const LastPosition = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [openSnack, setOpenSnack] = React.useState(initSnack);
  const classes = useFormStyle();
  const { i18 } = useIntl();
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  // Groups
  const [selectedTabletsGroups, setSelectedTabletsGroups] = useState([]);
  const TabletsGroups = useStoreState((state) => state.user.groups);
  // Assets
  const [selectedTabletsAsset, setSelectedTabletsAsset] = useState([]);
  const [TabletsAsset, setTabletsAsset] = useState([]);
  // Models
  const [selectedTabletsModel, setSelectedTabletsModel] = useState([]);
  const deviceModels = useStoreState((state) => state.user.deviceModels);
  // Company
  const [selectedTabletsCompany, setSelectedTabletsCompany] = useState([]);
  const clients = useStoreState((state) => state.user.clients);
  // Groups
  const [columnsLastPosition, setColumnsLastPosition] = useState([]);
  const [tablePosition, setTablePosition] = useState([]);
  // Tsp user

  const isTSP = useStoreState((state) => state.user.isTSP);
  // functions
  const [selectedFunctions, setSelectedFunctions] = useState([]);
  const functions = useStoreState((state) => state.user.functions);

  const handleOnchangeGroups = (selectGroup) => {
    setSelectedTabletsGroups(selectGroup);
    const listAssets = selectGroup.map((group) => group.assets).flat();
    setTabletsAsset(listAssets);
    setSelectedTabletsAsset(listAssets);
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      setColumnsLastPosition(columnsTable.filter((column) => column.field !== 'ignition').map((data) => ({ ...data, title: i18(data.title) })));
      const body = {
        clients: selectedTabletsCompany.map((company) => company.value),
        deviceModels: selectedTabletsModel.map((model) => model.id),
        deviceIds: selectedTabletsAsset.map((asset) => asset.deviceId),
        functions: selectedFunctions.map((func) => func.functionId),
      };

      await loadLastPosition(body);
      setIsLoading(false);
    } catch (error) {
      setOpenSnack((prev) => ({
        ...prev,
        open: true,
        title: i18('ERROR_API'),
        severity: 'error',
      }));
    }
  };

  const handleReset = (e) => {
    setTabletsAsset([]);
    setSelectedTabletsAsset([]);
    setSelectedTabletsGroups([]);
    setSelectedTabletsModel([]);
    setSelectedTabletsCompany([]);
    setSelectedFunctions([]);
  };

  const loadLastPosition = async (body) => {
    try {
      const { data: resLastPosition } = await postLastPosition(body);
      setTablePosition(resLastPosition.positions);
    } catch (error) {
      setOpenSnack((prev) => ({
        ...prev,
        open: true,
        title: i18('ERROR_API'),
        severity: 'error',
      }));
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmitForm}>
        <GridContainer className={classes.content}>
          <GridItem item xs={12} md={12} lg={12} className={classes.content}>
            <Portlet className={classes.formStyle}>
              <CardContent>
                <div>
                  <GridContainer justify="center" spacing={4}>
                    <GridItem justify="center" container>
                      <GridItem item xs={4} md={2} lg={2}>
                        <AutoCompleteBox
                          placeholder="Filter for Group"
                          multiple
                          label={i18('GROUPS')}
                          id="checkboxes-tags-demo"
                          options={TabletsGroups}
                          value={selectedTabletsGroups}
                          disableCloseOnSelect
                          getOptionLabel={(option) => option.fullName}
                          onChange={(prev, newValue) => handleOnchangeGroups(newValue)}
                          limitTags={10}
                          renderOption={(option, { selected }) => (
                            <React.Fragment>
                              <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                              {option.fullName}
                            </React.Fragment>
                          )}
                          style={{ marginRight: 15 }}
                        />
                      </GridItem>
                      <GridItem item xs={4} md={4} lg={4}>
                        <AutoCompleteBox
                          label={i18('ASSETS')}
                          multiple
                          id="checkboxes-tags-demo"
                          options={TabletsAsset}
                          disableCloseOnSelect
                          value={selectedTabletsAsset}
                          getOptionLabel={(option) => option.asset}
                          onChange={(prev, newValue) => setSelectedTabletsAsset(newValue)}
                          limitTags={10}
                          noOptionsText={i18('SELECT_A_GROUP')}
                          renderOption={(option, { selected }) => (
                            <React.Fragment>
                              <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                              {option.asset}
                            </React.Fragment>
                          )}
                          style={{
                            maxHeight: '230px',
                            marginRight: 15,
                          }}
                        />
                      </GridItem>
                      <GridItem item xs={4} md={3} lg={2}>
                        <AutoCompleteBox
                          label={i18('MODELS')}
                          multiple
                          id="checkboxes-tags-demo"
                          options={deviceModels}
                          value={selectedTabletsModel}
                          disableCloseOnSelect
                          getOptionLabel={(option) => option.name}
                          onChange={(prev, newValue) => setSelectedTabletsModel(newValue)}
                          limitTags={8}
                          renderOption={(option, { selected }) => (
                            <React.Fragment>
                              <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                              {option.name}
                            </React.Fragment>
                          )}
                          style={{
                            maxHeight: '230px',
                            marginRight: 15,
                          }}
                        />
                      </GridItem>
                      <GridItem item xs={4} md={3} lg={2}>
                        <AutoCompleteBox
                          label={i18('function')}
                          multiple
                          id="checkboxes-tags-demo2"
                          options={functions}
                          value={selectedFunctions}
                          disableCloseOnSelect
                          getOptionLabel={(option) => option.name}
                          onChange={(prev, newValue) => setSelectedFunctions(newValue)}
                          limitTags={8}
                          renderOption={(option, { selected }) => (
                            <React.Fragment>
                              <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                              {option.name}
                            </React.Fragment>
                          )}
                          style={{
                            maxHeight: '230px',
                            marginRight: 15,
                          }}
                        />
                      </GridItem>
                      {isTSP && (
                        <GridItem item xs={4} md={3} lg={2}>
                          <AutoCompleteBox
                            label={i18('COMPANY')}
                            multiple
                            id="checkboxes-tags-demo"
                            options={clients}
                            value={selectedTabletsCompany}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option.text}
                            onChange={(prev, newValue) => setSelectedTabletsCompany(newValue)}
                            limitTags={5}
                            renderOption={(option, { selected }) => (
                              <React.Fragment>
                                <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                                {option.text}
                              </React.Fragment>
                            )}
                            style={{
                              maxHeight: '230px',
                              marginRight: 15,
                            }}
                          />
                        </GridItem>
                      )}
                    </GridItem>
                    <GridItem item md={3} lg={3}>
                      <div className={classes.div}>
                        <div className={classes.inDiv}>
                          <Button onClick={handleReset} type="button" variant="contained" color={'cancel'}>
                            {i18('CLEAR')}
                          </Button>
                        </div>
                        <div className={classes.inDiv}>
                          <Button type="submit" variant="contained" color={'primary'} disabled={isLoading}>
                            {i18('SEARCH')}
                          </Button>
                          {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                      </div>
                    </GridItem>
                  </GridContainer>

                  {isLoading ? <Loading /> : columnsLastPosition.length > 0 && <TableReport columns={columnsLastPosition} dataTable={tablePosition} />}
                  <CustomizedSnackbars open={openSnack.open} setOpen={setOpenSnack} title={openSnack.title} severity={openSnack.severity}></CustomizedSnackbars>
                </div>
              </CardContent>
              <PortletActions></PortletActions>
            </Portlet>
          </GridItem>
        </GridContainer>
      </form>
    </div>
  );
};
export default LastPosition;
