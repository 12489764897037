import React, { Fragment, useRef, useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// nodejs library to set properties for components
import PropTypes from 'prop-types';

import L from 'leaflet';
import { MapContainer, TileLayer, ZoomControl, useMap } from 'react-leaflet';
import './custom.css';
import 'leaflet/dist/leaflet.css';
import { useStoreState, useStoreActions } from 'easy-peasy';
import createMarker from '../modules/map/functions/marker';
import CustomControl from '../modules/map/CustomControl';

import Button from '../components/CustomButton/Button';
import { FiltroIcon } from '../../assets/icons';
import mapPositioning, { mapAttributes } from '../modules/map/functions/calcMapAttributes';

import { useIntl } from '../lang/context/intlContext';
//Functions needed
//todo focus
//todo fouces and view

const useStyle = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  mapMenu: { display: 'flex' },
  mapButtons: {
    paddingTop: theme.spacing(14),
    paddingRight: theme.spacing(8),
  },
  mapViewAll: {
    paddingTop: theme.spacing(14),
    paddingRight: theme.spacing(2),
  },
  content: {},
}));

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});
const validateAssetsPosition = (assets, focusedAsset) => {
  const validPositions = assets.filter((item) => {
    let isValid = true;
    if (isNaN(item.latitude) || isNaN(item.longitude) || isNaN(item.gpsSpeed)) {
      isValid = false;
    }
    return isValid;
  });
  if (!focusedAsset) return validPositions;

  return validPositions.map((item) => {
    const isFocussed = focusedAsset.deviceId === item.deviceId;
    if (!isFocussed) return { ...item, icon: 0 };
    return { ...item, icon: 1 };
  });
};
function MapPage(props) {
  const { onClickFilter } = props;
  const classes = useStyle();
  const { i18 } = useIntl();

  //#region easy peasy
  const toGridMap = useStoreState((state) => state.map.toGridMap);
  const selectedItem = useStoreState((state) => state.map.selectedItem);
  const focusedAsset = useStoreState((state) => state.map.focusedAsset);
  const isViewAll = useStoreState((state) => state.map.isViewAll);

  const onViewAll = useStoreActions((actions) => actions.map.onViewAll);
  const onFocus = useStoreActions((actions) => actions.map.onFocus);
  //#endregion

  const validAssets = validateAssetsPosition(toGridMap, focusedAsset);
  const assetsOnMap = validAssets.map((item) => createMarker(item, false));
  const mapPos = mapAttributes(validAssets);

  // const { i18 } = useIntl();
  // const mapRef = useRef();
  // useEffect(() => {
  //   const { current = {} } = mapRef;
  //   const { leafletElement: map } = current;
  //   if (!map) return;
  //   // bluemap.flyTo()
  // }, [mapRef]);
  //center={[-33.441809, -70.649966]}
  // useEffect(() => {
  //   effect
  //   return () => {
  //     cleanup
  //   }
  // }, [input])

  return (
    <div id="container" className="contentmap">
      <MapContainer center={[-33.441809, -70.649966]} zoom={15} zoomControl={false} maxZoom={18} scrollWheelZoom={true}>
        <ZoomControl position="bottomright" />
        <TileLayer attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' url="http://{s}.tile.osm.org/{z}/{x}/{y}.png" />
        <MyComponent {...mapPos} countItems={assetsOnMap ? assetsOnMap.length : 0} selectedItem={selectedItem} isViewAll={isViewAll} focusedAsset={focusedAsset} />
        {assetsOnMap && <Fragment>{assetsOnMap}</Fragment>}
        <CustomControl>
          <div className={classes.mapMenu}>
            <div className={classes.mapViewAll}>
              <Button
                // disabled={selectedAsset === undefined}
                color="map"
                size="sm"
                startIcon={<FiltroIcon />}
                onClick={() => onFocus(true)}
              >
                {i18('Focus')}
              </Button>
            </div>
            <div className={classes.mapViewAll}>
              <Button color="map" size="sm" startIcon={<FiltroIcon />} onClick={() => onViewAll()}>
                {i18('ViewAll')}
              </Button>
            </div>
            <div className={classes.mapButtons}>
              <Button color="map" size="sm" startIcon={<FiltroIcon />} onClick={onClickFilter}>
                {i18('Filter')}
              </Button>
            </div>
          </div>
        </CustomControl>
      </MapContainer>
    </div>
  );
}
MapPage.propTypes = {
  onClickFilter: PropTypes.func,
  // title: PropTypes.string,
  // numSelected: PropTypes.number.isRequired,
  // children: PropTypes.object,
};

MapPage.defaultProps = {
  //icon: null,
};
export default MapPage;

function MyComponent(props) {
  const { bounds, selectedItem, isViewAll, focusedAsset, countItems } = props;

  const map = useMap();
  if (countItems === 0) {
    map.locate({ setView: true });
    return null;
  }
  const boundsOptions = { paddingBottomRight: L.point(0, 210) };
  // console.log('selectedItem', selectedItem);
  // console.log('isViewAll', isViewAll);
  let isInBound = true;
  if (focusedAsset) {
    console.info(focusedAsset);
    const latLong = { lat: focusedAsset.latitude, lng: focusedAsset.longitude };
    isInBound = map.getBounds().contains(latLong);
    // console.log('isInBound', isInBound);
    // console.log('bounds', map.getBounds().contains());
  }
  if (isViewAll) {
    map.fitBounds(bounds, boundsOptions);
  }
  if (selectedItem.lat) {
    map.flyTo(selectedItem, 18, { duration: 2 });
  }

  if (!isViewAll && !selectedItem.lat && isInBound === false) {
    console.log('bounding focused');
    const latLong = { lat: focusedAsset.latitude, lng: focusedAsset.longitude };
    map.setView(latLong, map.getZoom());
    // map.fitBounds(bounds, boundsOptions);
  }
  // console.log('bounds', bounds);

  // console.log('bound', map.getBounds());
  return null;
}
