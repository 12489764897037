import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  page: { backgroundColor: 'white' },
  label: {
    fontSize: 5,
    paddingTop: '1px',
    paddingLeft: '3px',
    textAlign: 'left',
  },
  labelValue: {
    paddingTop: '2px',
    fontSize: 9,
    textAlign: 'center',
  },
  labelData: {
    paddingTop: '1px',
    fontSize: 9,
    textAlign: 'left',
    marginLeft: '5px',
  },
  section: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '50px',
    alignItems: 'stretch',
    paddingTop: '0px',
    margin: '0px',
  },
  viewHeader: {
    width: '100px',

    margin: '10px',
    textAlign: 'center',
    fontSize: '10px',
    border: '1px solid #e4e6ea',
  },
  headerLogo: {
    width: '100px',
    height: '100px',
    margin: '0px',
    textAlign: 'center',
    fontSize: '10px',
  },
  viewHeaderTitle: {
    flexGrow: 7,
    paddingTop: '3px',
    paddingHorizontal: '5px',
    width: '120px',
    margin: '10px',
    textAlign: 'center',
    fontSize: '8px',
    fontWeight: 'bold',
    border: '1px solid #e4e6ea',
  },
  section2: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '27%',
    alignItems: 'stretch',
    margin: '10px',
    border: '1px solid #e4e6ea',
  },
  data: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  data1: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '10px',
    marginTop: '5px',
  },
  data3: {
    display: 'flex',
    flexDirection: 'row',
    // justifyContent: 'flex-end',
    marginLeft: '10px',
    marginTop: '5px',
  },
  panel: {
    width: '90px',
    marginRight: '10px',
    height: '25px',
    border: '1px solid #e4e6ea',
  },
  panel1: {
    width: '160px',
    marginRight: '10px',
    height: '25px',
    border: '1px solid #e4e6ea',
  },
  panel2: {
    width: '100px',
    marginRight: '10px',
    height: '25px',
    border: '1px solid #e4e6ea',
  },
  data2: {
    height: '25px',
    width: '282px',
    marginTop: '5px',
    marginHorizontal: '10px',
    border: '1px solid #e4e6ea',
  },
  foto: {
    marginTop: '10px',
    width: '500px',
    height: '210px',
    margin: '4px',
    textAlign: 'center',
    fontSize: '30px',
    border: '1px solid #e4e6ea',
    backgroundColor: '#7B9FF2',
  },
  section3: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '10%',
    alignItems: 'stretch',
    margin: '10px',
    border: '1px solid #e4e6ea',
  },
  viewSection3: {
    width: '600px',
    margin: '4px',
    textAlign: 'center',
    fontSize: '30px',
    border: '1px solid #e4e6ea',
    height: '30px',
  },
  viewMessage: {
    width: '700px',
    margin: '4px',
    textAlign: 'center',
    fontSize: '30px',
    border: '1px solid #e4e6ea',
  },
  section4: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: 0,
    height: '70px',
  },
  text: {
    margin: '20px',
    marginBottom: 0,
    textAlign: 'justify',
    fontSize: '10px',
  },
  section5: {
    display: 'flex',
    justifyContent: 'center',
    height: '70px',
  },
  date: {
    textAlign: 'center',
    fontSize: '11px',
  },
  section6: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    height: '5px',
    margin: '10px',
  },
  firm: {
    width: '80%',
    textAlign: 'center',
    height: '18px',
    fontSize: '11px',
  },
  section61: {
    marginTop: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: '40px',
  },
  section7: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    height: '20px',
    margin: '0 10px',
    border: '1px solid #e4e6ea',
  },
  viewUser: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'stretch',
    width: '200px',
    height: '18px',
  },
  user: {
    fontSize: 9,
    paddingTop: '3px',
  },
  viewContact: {
    paddingTop: '2px',
    marginRight: '4px',
    width: '250px',
    textAlign: 'right',
    height: '18px',
    fontSize: '10px',
  },
  section8: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '180px',
  },
  footer: {
    backgroundColor: 'blue',
    margin: '10px',
    textAlign: 'center',
    fontSize: '30px',
    height: '50px',
  },
});
