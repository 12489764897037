export function getFilterOptions(columns) {
  const groupAndall = { group: { field: 'rootField', value: [] }, all: { field: 'all', value: [] } };

  const optionsFromFields = columns.reduce(
    (filters, item) => {
      return {
        ...filters,
        [item.id]: { field: item.id, value: [] },
      };
    },
    { ...groupAndall },
  );

  return optionsFromFields;
}

const getArrayFilter = (filters, filter) => filters.map((item) => [filter[item].field, filter[item].value]).filter((item) => item[1].length);

export function applyFilter(filter, state) {
  const filters = Object.keys(filter);
  const filterAsArray = getArrayFilter(filters, filter);
  if (!filterAsArray.length) return state;

  return filterAsArray.reduce((newState, option) => {
    const [field, value] = option;
    const data = newState.reduce((acc, item) => {
      const isItem = item[field] ? findValue(value, item, field) : undefined; // find value
      if (isItem) acc.push(item);
      const deepOne = item.hasChilds
        ? item.nextLevel.filter((item2) => findValue(value, item2, field)) //fidn value in children
        : [];

      //no ntiene hijos y no es el campo. Se usa en all fields
      const allFields = !item.hasChilds && !isItem ? findValue(value, item, field) : undefined;
      if (allFields) acc.push(allFields);
      return [...acc, ...deepOne];
    }, []);
    return data;
  }, state);
}

const findValue = (value, item, field) => {
  if (field != 'all') return value.find((ele) => ele === item[field]);
  let hasValue = item.rootField ? item.rootField.toString().indexOf(value) : -1;
  if (hasValue === -1 && !item.hasChilds) {
    //si no hay, buscar en los fields
    const fields = Object.keys(item.fields);
    hasValue = fields.filter((field2) => item.fields[field2] && item.fields[field2].toString().toLowerCase().indexOf(value) >= 0).length > 0 ? 1 : -1;
  }
  return hasValue >= 0 ? item : undefined;
  //const fields = Object.keys(item.fields)
};
// export const getOptionGroups = (row) => {
//   return row
//     .filter((item) => item.hasChilds)
//     .reduce((acc, item) => {
//       const { rootField } = item;
//       acc.push(rootField);
//       const deepOne = item.nextLevel.filter((item) => item.hasChilds).map((item) => item.rootField);
//       return [...acc, ...deepOne];
//     }, []);
// };
