//#region imports
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CardContent, CircularProgress, Tooltip, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Slider from '@material-ui/core/Slider';

import GridContainer from '../../../../components/Grid/GridContainer';
import GridItem from '../../../../components/Grid/GridItem';
import Button from '../../../../components/CustomButton/Button';
import Portlet from '../../../../components/Portlet/Portlet';
import PortletActions from '../../../../components/Portlet/PorletActions';
import { useFormStyle } from '../../../../common/styles/formStyle';
import { FormNameIcon, FormAlarmShortTime } from '../../../../../assets/icons/formIcons';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';

import { useIntl } from '../../../../lang/context/intlContext';
import CustomCheck from '../../../../components/CustomCheck/CustomCheck';
import { useStoreActions, useStoreState } from 'easy-peasy';
import InputMaskText from '../../../../components/InputMaskText/InputMaskText';
import { postConfiguration } from '../api/postMethods';
import { formatValue } from '../../../../../utils';

//#endregion
//TODO: Icons required:Time,Schedule/Day

const RestTime = ({ error = [], handleNextChange, isReadOnly = true }) => {
  const classes = useFormStyle();
  const { i18, getWeekDays } = useIntl();

  const [free, setfree] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const { handleSubmit, errors } = useForm();

  const [values, setValues] = useState({
    minRestAfterContDriving: undefined,
    minDailyRest: undefined,
    regularDailyRest: undefined,
    weeklyRest: undefined,
    regularWeeklyRest: undefined,
    detectExcessRestTime: 10,
    detectExcessRestTimeChecks: { sendPos: false, sendAlertCabina: false },
    detectAntRestTime: 10,
    detectAntRestTimeChecks: { sendPos: false, sendAlertCabina: false, blockStopVehicle: false },
    detectMoving: 10,
    detectMovingChecks: { sendPos: false, sendAlertCabina: false, blockStartVehicle: false },
  });

  const journeyControlId = useStoreState((state) => state.journeyControl.journeyControlId);
  const restTime = useStoreState((state) => state.journeyControl.restTime);
  const setJourneyTime = useStoreActions((actions) => actions.journeyControl.setJourneyTime);
  useEffect(() => {
    // if (!restTime) return;
    setValues((values) => ({ ...values, ...restTime }));
  }, [restTime]);

  //#region handlers
  const handleOnChanges = (e) => {
    const value = e.target.value;
    const field = e.target.name;
    setValues((values) => ({
      ...values,
      [field]: value,
    }));
  };
  const handleSliders = (field, newValue) => {
    setValues((prev) => ({ ...prev, [field]: +newValue }));
  };

  /**
   *
   * @param {number} id
   * @param {string} field
   */
  const handleChecks = (id, field) => {
    switch (id) {
      case 0:
        const { detectExcessRestTimeChecks } = values;
        setValues((prev) => ({
          ...prev,
          detectExcessRestTimeChecks: { ...detectExcessRestTimeChecks, [field]: !prev.detectExcessRestTimeChecks[field] },
        }));
        break;
      case 1:
        const { detectAntRestTimeChecks } = values;
        setValues((prev) => ({
          ...prev,
          detectAntRestTimeChecks: { ...detectAntRestTimeChecks, [field]: !prev.detectAntRestTimeChecks[field] },
        }));
        break;
      case 2:
        const { detectMovingChecks } = values;
        setValues((prev) => ({
          ...prev,
          detectMovingChecks: { ...detectMovingChecks, [field]: !prev.detectMovingChecks[field] },
        }));
        break;
      default:
        break;
    }
    // setDetectBeginJourneyChecks((values) => ({ ...values, [name]: !values[name] || false }));
  };
  //#endregion

  const onSave = async (data) => {
    try {
      console.log('onSave');
      setIsLoading(true);
      const { minRestAfterContDriving, minDailyRest, regularDailyRest, weeklyRest, regularWeeklyRest } = values;
      const restTime = {
        ...values,
        minRestAfterContDriving: formatValue(minRestAfterContDriving),
        minDailyRest: formatValue(minDailyRest),
        regularDailyRest: formatValue(regularDailyRest),
        weeklyRest: formatValue(weeklyRest),
        regularWeeklyRest: formatValue(regularWeeklyRest),
      };
      await postConfiguration({ journeyControlId: journeyControlId, restTime });
      setJourneyTime({ restTime });
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      console.log('error on save');
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSave)}>
      <GridContainer className={classes.content}>
        <GridItem item xs={12} md={12} lg={10} className={classes.content}>
          <Portlet className={classes.formStyle} title={i18('restTime')} subTitle={i18('JOURNEY_TIME_DETAIL')}>
            <CardContent>
              <GridContainer direction="column" spacing={2}>
                <GridItem item xs={12} md={12} lg={12}>
                  <GridContainer direction="row" spacing={2}>
                    <GridItem item xs={6} md={6}>
                      <InputMaskText
                        id="minRestAfterContDriving"
                        name="minRestAfterContDriving"
                        label={i18('minRestAfterContDriving')}
                        icon={FormAlarmShortTime}
                        value={values.minRestAfterContDriving}
                        format={'##:##'}
                        mask="_"
                        onChange={handleOnChanges}
                      ></InputMaskText>
                      {free && <Typography color="secondary">{`${free} ${i18('NOTAVAI')}`}</Typography>}
                      {errors.name && <Typography color="secondary">{i18('Name_Req')}</Typography>}
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem item xs={12} md={12} lg={12}>
                  <GridContainer direction="row" spacing={2}>
                    <GridItem item xs={6} md={6}>
                      <InputMaskText
                        id="minDailyRest"
                        name="minDailyRest"
                        label={i18('minDailyRest')}
                        icon={FormAlarmShortTime}
                        value={values.minDailyRest}
                        format={'##:##'}
                        mask="_"
                        onChange={handleOnChanges}
                      ></InputMaskText>
                    </GridItem>
                    <GridItem item xs={6} md={6}>
                      <InputMaskText
                        id="regularDailyRest"
                        name="regularDailyRest"
                        label={i18('regularDailyRest')}
                        icon={FormAlarmShortTime}
                        value={values.regularDailyRest}
                        format={'##:##'}
                        mask="_"
                        onChange={handleOnChanges}
                      ></InputMaskText>
                    </GridItem>
                    <GridItem item xs={6} md={6}></GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem item xs={12} md={12} lg={12}>
                  <GridContainer direction="row" spacing={2}>
                    <GridItem item xs={6} md={6}>
                      <InputMaskText
                        id="weeklyRest"
                        name="weeklyRest"
                        label={i18('weeklyRest')}
                        icon={FormAlarmShortTime}
                        value={values.weeklyRest}
                        format={'##:##'}
                        mask="_"
                        onChange={handleOnChanges}
                      ></InputMaskText>
                    </GridItem>
                    <GridItem item xs={6} md={6}>
                      <InputMaskText
                        id="regularWeeklyRest"
                        name="regularWeeklyRest"
                        label={i18('regularWeeklyRest')}
                        icon={FormAlarmShortTime}
                        value={values.regularWeeklyRest}
                        format={'##:##'}
                        mask="_"
                        onChange={handleOnChanges}
                      ></InputMaskText>
                    </GridItem>
                    <GridItem item xs={6} md={6}></GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem item xs={12} md={12} lg={12}>
                  <GridContainer direction="row" spacing={2}>
                    <GridItem item xs={6} md={6}>
                      <Typography gutterBottom>{i18('detectExcessRestTime')}</Typography>
                      <Slider
                        id="detectExcessRestTime"
                        name="detectExcessRestTime"
                        ValueLabelComponent={ValueLabelComponent}
                        aria-label="custom thumb label"
                        value={values.detectExcessRestTime}
                        onChange={(_, value) => {
                          handleSliders('detectExcessRestTime', value);
                        }}
                        min={5}
                        max={60}
                      />
                    </GridItem>
                    <GridItem container direction="row" item xs={6} md={6}>
                      <GridItem item xs={3} md={3}>
                        <CustomCheck
                          label={i18('SEND_POS')}
                          checked={values.detectExcessRestTimeChecks.sendPos}
                          onChange={() => handleChecks(0, 'sendPos')}
                          name="sendPos"
                        />
                      </GridItem>
                      <GridItem item xs={3} md={3}>
                        <CustomCheck
                          label={i18('SEND_ALERT_CABINA')}
                          checked={values.detectExcessRestTimeChecks.sendAlertCabina}
                          onChange={() => handleChecks(0, 'sendAlertCabina')}
                          name="sendAlertCabina"
                        />
                      </GridItem>
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem item xs={12} md={12} lg={12}>
                  <GridContainer direction="row" spacing={2}>
                    <GridItem item xs={6} md={6}>
                      <Typography gutterBottom>{i18('detectAntRestTime')}</Typography>
                      <Slider
                        id="detectAntRestTime"
                        name="detectAntRestTime"
                        ValueLabelComponent={ValueLabelComponent}
                        aria-label="custom thumb label"
                        value={values.detectAntRestTime}
                        onChange={(_, value) => {
                          handleSliders('detectAntRestTime', value);
                        }}
                        min={5}
                        max={60}
                      />
                    </GridItem>
                    <GridItem container direction="row" item xs={6} md={6}>
                      <GridItem item xs={3} md={3}>
                        <CustomCheck
                          label={i18('SEND_POS')}
                          checked={values.detectAntRestTimeChecks.sendPos}
                          onChange={() => handleChecks(1, 'sendPos')}
                          name="sendPos"
                        />
                      </GridItem>
                      <GridItem item xs={3} md={3}>
                        <CustomCheck
                          label={i18('SEND_ALERT_CABINA')}
                          checked={values.detectAntRestTimeChecks.sendAlertCabina}
                          onChange={() => handleChecks(1, 'sendAlertCabina')}
                          name="sendAlertCabina"
                        />
                      </GridItem>{' '}
                      <GridItem item xs={3} md={3}>
                        <CustomCheck
                          label={i18('blockStopVehicle')}
                          checked={values.detectAntRestTimeChecks.blockStopVehicle}
                          onChange={() => handleChecks(1, 'blockStopVehicle')}
                          name="blockStopVehicle"
                        />
                      </GridItem>
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem item xs={12} md={12} lg={12}>
                  <GridContainer direction="row" spacing={2}>
                    <GridItem item xs={6} md={6}>
                      <Typography gutterBottom>{i18('detectMoving')}</Typography>
                      <Slider
                        id="detectMoving"
                        name="detectMoving"
                        ValueLabelComponent={ValueLabelComponent}
                        aria-label="custom thumb label"
                        value={values.detectMoving}
                        onChange={(_, value) => {
                          handleSliders('detectMoving', value);
                        }}
                        min={5}
                        max={60}
                      />
                    </GridItem>
                    <GridItem container direction="row" item xs={6} md={6}>
                      <GridItem item xs={3} md={3}>
                        <CustomCheck
                          label={i18('SEND_POS')}
                          checked={values.detectMovingChecks.sendPos}
                          onChange={() => handleChecks(2, 'sendPos')}
                          name="sendPos"
                        />
                      </GridItem>
                      <GridItem item xs={3} md={3}>
                        <CustomCheck
                          label={i18('SEND_ALERT_CABINA')}
                          checked={values.detectMovingChecks.sendAlertCabina}
                          onChange={() => handleChecks(2, 'sendAlertCabina')}
                          name="sendAlertCabina"
                        />
                      </GridItem>
                      <GridItem item xs={3} md={3}>
                        <CustomCheck
                          label={i18('blockStartVehicle')}
                          checked={values.detectMovingChecks.blockStartVehicle}
                          onChange={() => handleChecks(2, 'blockStartVehicle')}
                          name="blockStartVehicle"
                        />
                      </GridItem>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </CardContent>
            <PortletActions>
              <div className={classes.div}>
                <div className={classes.inDiv}>
                  <Button
                    variant="contained"
                    disabled={isLoading || isReadOnly}
                    color={'cancel'}
                    onClick={() => handleNextChange(0)}
                  >
                    {i18('Cancel')}
                  </Button>
                </div>
                <div className={classes.inDiv}>
                  <Button type="submit" disabled={isLoading || isReadOnly} variant="contained" color={'primary'}>
                    {i18('save')}
                  </Button>
                  {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
              </div>
            </PortletActions>
            {error.length > 0 && (
              <Alert className={classes.alert} severity="error">
                {i18('API_ERR')}
              </Alert>
            )}
          </Portlet>
        </GridItem>
      </GridContainer>
    </form>
  );
};

function ValueLabelComponent(props) {
  const { children, open, value } = props;

  return (
    <Tooltip title={<h1 style={{ fontSize: '14px' }}>{value}</h1>} open={true} enterTouchDelay={0} placement="top">
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
};
export default RestTime;
