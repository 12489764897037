import axios from 'axios';

const POST_POSITION = '/reports/positions';
const POST_LAST_POSITION = '/reports/lastKnownPositions';
const POST_MACROS = '/reports/macros';
const POST_SPOKEN_ROUTER = '/reports/spokenRoute';
const POST_ASSETS_DRIVERS = '/reports/driversAssets';
const POST_DRIVER_LOGIN = '/reports/driversLogin';
const POST_ASSETS_PER_DRIVERS = 'reports/assetsPerDriver';
const POST_DRIVERS = 'driver';
const POST_ASSETS = 'assets';
const POST_DEVICES = 'devices';
const POST_NFC = 'nfc/view';
const POST_DRIVER_WO_ASSETS = '/driver/driversWOAssets';
const POST_DRIVER_WITH_NFC = '/driver/driversWithNFC';
const POST_DRIVER_WO_NFC = '/driver/driversWONFC';
const POST_CONTROL_JORNADA = '/journeyControl/viewStatusAll';
const POST_STATUS_CONTROL_JORNADA = '/journeyControl/view';
const POST_SPOKEN_ROUTES = '/rotograma/viewStatusAll';
const POST_STATUS_SPOKEN_ROUTES = '/rotograma/view';
const POST_ROLE = 'security/getRol';
const POST_USERS_PER_ROL = '/security/getRolWebUsers';
const POST_USERS_PER_COMPANY = '/user/view';
const POST_USERS_ASSETS = '/user/assets';
const POST_ASSETS_MESSAGE = '/messages/view';
const POST_USERS = '/user/view';
const POST_ATLAS = '/assets/atlas';
const POST_SENSORS = '/communication/sensors';
const POST_ALARMS = '/communication/alarms';
const POST_TACHOMETER = '/reports/tachometer';
const POST_TABLET_EVENTS = '/reports/allTabletEvents';
const POST_GROUP_BY_DRIVERS = '/reports/evidence/groupByDrivers';
const POST_GROUP_BY_ASSETS = '/reports/evidence/groupByAssets';
const POST_BY_DRIVERS = '/reports/evidence/byParams';
const POST_BY_USERS = '/reports/evidence/byUsers';

export const postPosition = (filter) => axios.post(POST_POSITION, filter);
export const postLastPosition = (filter) => axios.post(POST_LAST_POSITION, filter);
export const postMacros = (filter) => axios.post(POST_MACROS, filter);
export const postSpokenRouter = (filter) => axios.post(POST_SPOKEN_ROUTER, filter);
export const postAssetsDrivers = (filter) => axios.post(POST_ASSETS_DRIVERS, filter);
export const postDriverLogin = (filter) => axios.post(POST_DRIVER_LOGIN, filter);
export const postAssetsPerDrivers = (filter) => axios.post(POST_ASSETS_PER_DRIVERS, filter);
export const postDrivers = (filter) => axios.post(POST_DRIVERS, filter);
export const postAssets = (filter) => axios.post(POST_ASSETS, filter);
export const postDevices = (filter) => axios.post(POST_DEVICES, filter);
export const postNfc = (filter) => axios.post(POST_NFC, filter);
export const postDriverWOAssets = (filter) => axios.post(POST_DRIVER_WO_ASSETS, filter);
export const postDriverWithNFC = (filter) => axios.post(POST_DRIVER_WITH_NFC, filter);
export const postDriverWONFC = (filter) => axios.post(POST_DRIVER_WO_NFC, filter);
export const postCtJornada = (filter) => axios.post(POST_CONTROL_JORNADA, filter);
export const postStatusCtJornada = (filter) => axios.post(POST_STATUS_CONTROL_JORNADA, filter);
export const postSpokenRoutes = (filter) => axios.post(POST_SPOKEN_ROUTES, filter);
export const postStatusSpokenRoutes = (filter) => axios.post(POST_STATUS_SPOKEN_ROUTES, filter);
export const postRole = (filter) => axios.post(POST_ROLE, filter);
export const postUsersPerRol = (filter) => axios.post(POST_USERS_PER_ROL, filter);
export const postUsersPerCompany = (filter) => axios.post(POST_USERS_PER_COMPANY, filter);
export const postUserAssets = (filter) => axios.post(POST_USERS_ASSETS, filter);
export const postAssetsMessage = (filter) => axios.post(POST_ASSETS_MESSAGE, filter);
export const postUsers = (filter) => axios.post(POST_USERS, filter);
export const postAtlas = (filter) => axios.post(POST_ATLAS, filter);
export const postSensors = (filter) => axios.post(POST_SENSORS, filter);
export const postAlarms = (filter) => axios.post(POST_ALARMS, filter);
export const postTachometer = (filter) => axios.post(POST_TACHOMETER, filter);
export const postTabletEvents = (filter) => axios.post(POST_TABLET_EVENTS, filter);
export const postGroupByDrivers = (filter) => axios.post(POST_GROUP_BY_DRIVERS, filter);
export const postGroupByAssets = (filter) => axios.post(POST_GROUP_BY_ASSETS, filter);
export const postByDrivers = (filter) => axios.post(POST_BY_DRIVERS, filter);
export const postByUsers = (filter) => axios.post(POST_BY_USERS, filter);
