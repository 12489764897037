import React, { useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { innerDrawerWidth } from '../../constants';
import List from '@material-ui/core/List';
// import { ItemIcon } from './SubMenu/ItemIcon';

// const innerDrawerWidth = 200;
import { v4 as uuidv4 } from 'uuid';
import SubItem from './SubMenu/SubItem';
//borderRadius 33 morad

//borderRadius 36 balnc
const useStyles = makeStyles((theme) => ({
  root: {
    // width: '100%',
    backgroundColor: theme.palette.primary.dark,
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    borderTopRightRadius: 32,
    borderBottomRightRadius: 32,
  },
  innerDrawerBar: {
    flexShrink: 0,
    position: 'relative',
    borderRight: 'none',
    backgroundColor: theme.palette.primary.dark,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  innerDrawerOpened: {
    width: innerDrawerWidth,
  },

  subNavigatorStyle: {
    marginTop: theme.spacing(10),
    marginLeft: theme.spacing(1),
  },
}));

export default function SubNavigator({ ...props }) {
  const { subMenu } = props;
  // const handleClick = (e) => {
  //   e.preventDefault();
  //   setOpen(!opened);
  // };

  // const [opened, setOpen] = React.useState(false);
  const { variant, open, location, anchor, toggleDrawer } = props;
  // const [isOpen, setIsOpen] = useState(open);
  const { pathname: currentPath } = location;
  // const toggleDrawer = (open) => (event) => {
  //   console.log('open',open)
  //   if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
  //     return;
  //   }

  //   setIsOpen(open);
  // };
  const classes = useStyles();
  const subMenuList = subMenu.filter((item) => item.isVisible).map((item) => <SubItem currentPath={currentPath} key={uuidv4()} {...item} onToggleClick={toggleDrawer} />);
  return (
    <Drawer
      anchor={anchor}
      // elevation={0}
      variant={variant}
      open={open}
      onClose={toggleDrawer(false)}
      classes={{
        paper: clsx(classes.root, classes.innerDrawerBar, open && classes.innerDrawerOpened),
      }}
    >
      <List dense component="nav" aria-labelledby="nested-list-subheader" className={classes.subNavigatorStyle}>
        {subMenuList}
      </List>
    </Drawer>
  );
}
