import React, { createContext, useContext } from 'react';
import { createIntl, createIntlCache } from 'react-intl';
import useLocalStorage from '../../../hooks/useLocalStorage';
import english from '../translations/en.json';
import spanish from '../translations/es.json';
import portugese from '../translations/pt.json';

import { getWeekDays } from './utils';
// This is optional but highly recommended
// since it prevents memory leak
// const cache = createIntlCache();

//creamoes context
const initValues = { lang: undefined, setLang: undefined };
const IntlContext = createContext(initValues);

//exportamos a los usuarios
export function useIntl() {
  return useContext(IntlContext);
}

//exporta un consumer para poder setear las funciones y valores en forma automtica.
// e integrado al setup de este componente utilizado para darle 'vida' al contexto
//Este componenete es el wraper de la app
export const IntlConsumer = IntlContext.Consumer;

//Setup de las funciones y valores del Context

//1:English;2:Spanish:3:Portu
export function IntlProvider({ children }) {
  const [lang, setLang] = useLocalStorage('location', 'en');
  const cache = createIntlCache();
  const langs = [
    ['en', english],
    ['es', spanish],
    ['pt', portugese],
  ];
  const mapLanguages = new Map(langs);
  const messages = mapLanguages.get(lang);
  const intl = createIntl(
    {
      // Locale of the application
      locale: lang,
      // Locale of the fallback defaultMessage
      defaultLocale: 'en',
      messages,
    },
    cache
  );
  /**
   *
   * @param {string} id .
   * @param {string} defaultLabel optional.
   * @return {string} Message in the user's language .
   */
  const i18 = (id, defaultLabel = undefined) => {
    if (!id) return 'undefined';
    return intl.formatMessage({ id: id.toUpperCase(), defaultMessage: defaultLabel || 'add: ' + id });
  };

  const value = {
    lang,
    setLang: (id) => {
      switch (id) {
        case 1:
          setLang('en');
          break;
        case 2:
          setLang('es');
          break;
        case 3:
          setLang('pt');
          break;
      }
    },
    intl,
    i18,
    getWeekDays: getWeekDays(i18),
  };

  return <IntlContext.Provider value={value}>{children}</IntlContext.Provider>;
}
