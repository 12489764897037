//9-18, 25

export const reportSubMenu = [
  {
    parent: 'R',
    id: '9',
    level: 2,
    path: 'communication',
    name: 'Communication',
    label: 'H',
    icon: 'SettingsOutlined',
    color: '',
    hasCollapsed: true,
    status: 'normal',
    subMenus: [
      {
        status: 'normal',
        id: '9.1',
        level: 3,
        path: '/reports/communication/positions',
        name: 'Positions',
        label: 'H',
        icon: 'ViewQuiltOutlinedIcon',
        prev: '9',
        next: '9.2',
        isVisible: true,
      },
      {
        status: 'normal',
        id: '9.11',
        level: 3,
        path: '/reports/communication/ngreports',
        name: 'Telemetry',
        label: 'H',
        icon: 'ViewQuiltOutlinedIcon',
        prev: '9.10',
        isVisible: true,
      },
      {
        status: 'normal',
        id: '9.2',
        level: 3,
        path: '/reports/communication/lastPositions',
        name: 'Last_Known_Pos',
        label: 'H',
        icon: 'ViewQuiltOutlinedIcon',
        prev: '9.1',
        next: '9.3',
        isVisible: false,
      },
      {
        status: 'normal',
        id: '9.3',
        level: 3,
        path: '/reports/communication/status',
        name: 'STATUS',
        label: 'H',
        icon: 'ViewQuiltOutlinedIcon',
        prev: '9.2',
        next: '9.4',
        isVisible: true,
      },
      {
        status: 'normal',
        level: 3,
        path: '/reports/communication/gpioCommands',
        name: 'GPIO_COMMANDS',
        label: 'H',
        icon: 'ViewQuiltOutlinedIcon',
        prev: '9.3',
        id: '9.4',
        next: '9.5',
        isVisible: true,
      },
      {
        status: 'normal',
        level: 3,
        path: '/reports/communication/sensors',
        name: 'sensors',
        label: 'H',
        icon: 'ViewQuiltOutlinedIcon',
        prev: '9.4',
        id: '9.5',
        next: '9.6',
        isVisible: true,
      },
      {
        status: 'normal',
        level: 3,
        path: '/reports/communication/alarms',
        name: 'alarms',
        label: 'H',
        icon: 'ViewQuiltOutlinedIcon',
        prev: '9.5',
        id: '9.6',
        next: '9.7',
        isVisible: true,
      },
      {
        status: 'normal',
        level: 3,
        path: '/reports/communication/latitudelongitude',
        name: 'LATITUDE_LONGITUDE',
        label: 'H',
        icon: 'ViewQuiltOutlinedIcon',
        prev: '9.6',
        id: '9.7',
        next: '9.8',
        isVisible: true,
      },
      {
        status: 'normal',
        level: 3,
        path: '/reports/communication/movPerDriver',
        name: 'MOV_PER_DRIVER',
        label: 'H',
        icon: 'ViewQuiltOutlinedIcon',
        prev: '9.7',
        id: '9.8',
        next: '9.9',
        isVisible: true,
      },
      {
        status: 'normal',
        level: 3,
        path: '/reports/communication/movPerAsset',
        name: 'MOV_PER_ASSET',
        label: 'H',
        icon: 'ViewQuiltOutlinedIcon',
        prev: '9.8',
        id: '9.9',
        next: '9.10',
        isVisible: true,
      },
      {
        status: 'blind',
        id: '9.10',
        level: 3,
        path: '#',
        name: '',
        label: 'H',
        icon: undefined,
        isVisible: true,
      },
    ],
    selected: false,
    isVisible: true,
  },
  {
    parent: 'R',
    id: '11',
    level: 2,
    path: 'mfis',
    name: 'mfis',
    label: 'H',
    icon: 'SettingsOutlined',
    color: '',
    hasCollapsed: true,
    status: 'normal',
    subMenus: [
      {
        status: 'normal',
        id: '11.2',
        level: 3,
        path: '/reports/mfis/statusJourneyControl',
        name: 'CJ_STATUS',
        label: 'H',
        icon: 'ViewQuiltOutlinedIcon',
        prev: '11.1',
        next: '11.3',
        isVisible: true,
      },
      {
        status: 'normal',
        level: 3,
        path: '/reports/mfis/situationJourneyControl',
        name: 'CJ_SITUATION',
        label: 'H',
        icon: 'ViewQuiltOutlinedIcon',
        prev: '11.2',
        id: '11.3',
        next: '11.4',
        isVisible: true,
      },
      {
        status: 'normal',
        level: 3,
        path: '/reports/mfis/statusSpokenRoute',
        name: 'RF_STATUS',
        label: 'H',
        icon: 'ViewQuiltOutlinedIcon',
        prev: '11.3',
        id: '11.4',
        next: '11.5',
        isVisible: true,
      },
      {
        status: 'normal',
        level: 3,
        path: '/reports/mfis/situationSpokenRoute',
        name: 'RF_SITUATION',
        label: 'H',
        icon: 'ViewQuiltOutlinedIcon',
        prev: '11.4',
        id: '11.5',
        next: '11.6',
        isVisible: true,
      },
      {
        status: 'blind',
        id: '11.6',
        level: 3,
        path: '#',
        name: '',
        label: 'H',
        icon: undefined,
        isVisible: true,
      },
    ],
    selected: false,
    isVisible: true,
  },
  {
    parent: 'R',
    id: '10',
    level: 2,
    path: 'journey',
    name: 'Journey',
    label: 'H',
    icon: 'SettingsOutlined',
    color: '',
    hasCollapsed: true,
    status: 'normal',
    subMenus: [
      {
        status: 'normal',
        level: 3,
        path: '/reports/journey/tabletEvents',
        name: 'Tablet_Events',
        label: 'H',
        icon: 'ViewQuiltOutlinedIcon',
        prev: '10.0',
        id: '10.1',
        next: '10.2',
        isVisible: true,
      },
      {
        status: 'normal',
        level: 3,
        path: '/reports/journey/spokenRoute',
        name: 'Spoken_Route',
        label: 'H',
        icon: 'ViewQuiltOutlinedIcon',
        prev: '10.1',
        id: '10.2',
        next: '10.3',
        isVisible: true,
      },
      {
        status: 'normal',
        level: 3,
        path: '/reports/journey/jcMacros',
        name: 'MACROS_PER_DRIVER',
        label: 'H',
        icon: 'ViewQuiltOutlinedIcon',
        id: '10.3',
        prev: '10.2',
        next: '10.4',
        isVisible: true,
      },
      {
        status: 'normal',
        level: 3,
        path: '/reports/journey/macrosPerAsset',
        name: 'MACROS_PER_ASSETS',
        label: 'H',
        icon: 'ViewQuiltOutlinedIcon',
        id: '10.4',
        prev: '10.3',
        next: '10.5',
        isVisible: true,
      },
      {
        status: 'normal',
        level: 3,
        path: '/reports/journey/driverLogin',
        name: 'LOGINS',
        label: 'H',
        icon: 'ViewQuiltOutlinedIcon',
        id: '10.5',
        prev: '10.4',
        next: '10.6',
        isVisible: true,
      },
      {
        status: 'blind',
        id: '10.6',
        level: 3,
        path: '#',
        name: '',
        label: 'H',
        icon: undefined,
        isVisible: true,
      },
    ],
    selected: false,
    isVisible: true,
  },
  {
    parent: 'R',
    id: '13',
    level: 2,
    path: 'drivers',
    name: 'MOTORISTA',
    label: 'H',
    icon: 'SettingsOutlined',
    color: '',
    hasCollapsed: true,
    status: 'normal',
    isVisible: true,
    subMenus: [
      {
        status: 'normal',
        level: 3,
        path: '/reports/drivers/drivers',
        name: 'drivers',
        label: 'H',
        icon: 'ViewQuiltOutlinedIcon',
        prev: '13.3',
        id: '13.4',
        next: '13.5',
        isVisible: true,
      },
      {
        status: 'normal',
        level: 3,
        path: '/reports/drivers/assetsDrivers',
        name: 'DRIV_PER_ASSET',
        label: 'H',
        icon: 'ViewQuiltOutlinedIcon',
        prev: '13.4',
        id: '13.5',
        next: '13.6',
        isVisible: true,
      },
      {
        status: 'normal',
        level: 3,
        path: '/reports/drivers/assetsPerDrivers',
        name: 'ASSET_PER_DRIVERS',
        label: 'H',
        icon: 'ViewQuiltOutlinedIcon',
        prev: '13.5',
        id: '13.6',
        next: '13.7',
        isVisible: true,
      },
      {
        status: 'normal',
        level: 3,
        path: '/reports/drivers/driversPerClient',
        name: 'DRIVERS_PER_CLIENT',
        label: 'H',
        icon: 'ViewQuiltOutlinedIcon',
        prev: '13.6',
        id: '13.7',
        next: '13.8',
        isVisible: true,
      },
      {
        status: 'normal',
        level: 3,
        path: '/reports/drivers/driversPerDevice',
        name: 'DRIVERS_PER_DEVICE',
        label: 'H',
        icon: 'ViewQuiltOutlinedIcon',
        prev: '13.7',
        id: '13.8',
        next: '13.9',
        isVisible: true,
      },
      {
        status: 'normal',
        prev: '13.8',
        id: '13.9',
        next: '13.10',
        level: 3,
        path: '/reports/drivers/driverWOAssets',
        name: 'DRIVER_NO_ASSETS',
        label: 'H',
        icon: 'ViewQuiltOutlinedIcon',
        isVisible: true,
      },
      {
        status: 'normal',
        prev: '13.9',
        id: '13.10',
        next: '13.11',
        level: 3,
        path: '/reports/drivers/driverWONFC',
        name: 'DRIVER_NO_NFC',
        label: 'H',
        icon: 'ViewQuiltOutlinedIcon',
        isVisible: true,
      },
      {
        status: 'normal',
        prev: '13.10',
        id: '13.11',
        next: '13.12',
        level: 3,
        path: '/reports/drivers/driverWithNFC',
        name: 'DRIVER_NFC',
        label: 'H',
        icon: 'ViewQuiltOutlinedIcon',
        isVisible: true,
      },
      {
        status: 'normal',
        prev: '13.11',
        id: '13.12',
        next: '13.13',
        level: 3,
        path: '/reports/drivers/tachometer',
        name: 'TACHOMETER',
        label: 'H',
        icon: 'ViewQuiltOutlinedIcon',
        isVisible: true,
      },
      {
        status: 'blind',
        id: '13.13',
        level: 3,
        path: '#',
        name: '',
        label: 'H',
        icon: undefined,
        isVisible: true,
      },
    ],
    selected: false,
  },
  {
    parent: 'R',
    id: '25',
    level: 2,
    path: 'evidence',
    name: 'evidence',
    label: 'H',
    icon: 'SettingsOutlined',
    color: '',
    hasCollapsed: true,
    status: 'normal',
    subMenus: [
      {
        status: 'normal',
        level: 3,
        path: '/reports/evidence/byAssets',
        name: 'EVIDENCES_BY_ASSETS',
        label: 'H',
        icon: 'ViewQuiltOutlinedIcon',
        prev: '25.0',
        id: '25.1',
        next: '25.2',
        isVisible: true,
      },
      {
        status: 'normal',
        level: 3,
        path: '/reports/evidence/byDrivers',
        name: 'EVIDENCES_BY_DRIVERS',
        label: 'H',
        icon: 'ViewQuiltOutlinedIcon',
        prev: '25.1',
        id: '25.2',
        next: '25.3',
        isVisible: true,
      },
      {
        status: 'normal',
        level: 3,
        path: '/reports/evidence/byUsers',
        name: 'EVIDENCES_BY_USERS',
        label: 'H',
        icon: 'ViewQuiltOutlinedIcon',
        id: '25.3',
        prev: '25.2',
        next: '25.4',
        isVisible: true,
      },
      {
        status: 'normal',
        level: 3,
        path: '/reports/evidence/groupByAssets',
        name: 'GROUP_BY_ASSETS',
        label: 'H',
        icon: 'ViewQuiltOutlinedIcon',
        id: '25.4',
        prev: '25.3',
        next: '25.5',
        isVisible: true,
      },
      {
        status: 'normal',
        level: 3,
        path: '/reports/evidence/groupByDrivers',
        name: 'GROUP_BY_DRIVERS',
        label: 'H',
        icon: 'ViewQuiltOutlinedIcon',
        id: '25.5',
        prev: '25.4',
        next: '25.6',
        isVisible: true,
      },
      {
        status: 'blind',
        id: '25.6',
        level: 3,
        path: '#',
        name: '',
        label: 'H',
        icon: undefined,
        isVisible: true,
      },
    ],
    selected: false,
    isVisible: true,
  },
  {
    parent: 'R',
    id: '14',
    level: 2,
    path: 'nfcs',
    name: 'NFC',
    label: 'H',
    icon: 'SettingsOutlined',
    color: '',
    hasCollapsed: true,
    status: 'normal',
    isVisible: true,
    subMenus: [
      {
        status: 'normal',
        prev: '14.10',
        id: '14.11',
        next: '14.12',
        level: 3,
        path: '/reports/nfc/list',
        name: 'NFC',
        label: 'H',
        icon: 'ViewQuiltOutlinedIcon',
        isVisible: true,
      },

      {
        status: 'blind',
        id: '14.12',
        level: 3,
        path: '#',
        name: '',
        label: 'H',
        icon: undefined,
        isVisible: true,
      },
    ],
    selected: false,
  },
  {
    parent: 'R',
    id: '15',
    level: 2,
    path: 'assets',
    name: 'asset',
    label: 'H',
    icon: 'SettingsOutlined',
    color: '',
    hasCollapsed: true,
    status: 'normal',
    isVisible: true,

    subMenus: [
      {
        status: 'normal',
        prev: '15.10',
        id: '15.11',
        next: '15.12',
        level: 3,
        path: '/reports/assets/list',
        name: 'Assets',
        label: 'H',
        icon: 'ViewQuiltOutlinedIcon',
        isVisible: true,
      },
      {
        status: 'normal',
        prev: '15.11',
        id: '15.12',
        next: '15.13',
        level: 3,
        path: '/reports/assets/messages',
        name: 'Messages',
        label: 'H',
        icon: 'ViewQuiltOutlinedIcon',
        isVisible: true,
      },
      {
        status: 'normal',
        prev: '15.12',
        id: '15.13',
        next: '15.14',
        level: 3,
        path: '/reports/assets/atlas',
        name: 'Atlas',
        label: 'H',
        icon: 'ViewQuiltOutlinedIcon',
        isVisible: true,
      },
      {
        status: 'normal',
        prev: '15.13',
        id: '15.14',
        next: '15.15',
        level: 3,
        path: '/reports/assets/atlasEvents',
        name: 'Atlas_Eventos',
        label: 'H',
        icon: 'ViewQuiltOutlinedIcon',
        isVisible: true,
      },
      {
        status: 'normal',
        prev: '15.14',
        id: '15.15',
        next: '15.16',
        level: 3,
        path: '/reports/assets/atlasMacros',
        name: 'Atlas_Macros',
        label: 'H',
        icon: 'ViewQuiltOutlinedIcon',
        isVisible: true,
      },
      {
        status: 'blind',
        id: '15.16',
        level: 3,
        path: '#',
        name: '',
        label: 'H',
        icon: undefined,
        isVisible: true,
      },
    ],
    selected: false,
  },
  {
    parent: 'R',
    id: '16',
    level: 2,
    path: 'devices',
    name: 'device',
    label: 'H',
    icon: 'SettingsOutlined',
    color: '',
    hasCollapsed: true,
    status: 'normal',
    isVisible: true,
    subMenus: [
      {
        status: 'normal',
        prev: '16.10',
        id: '16.11',
        next: '16.12',
        level: 3,
        path: '/reports/devices/list',
        name: 'Devices',
        label: 'H',
        icon: 'ViewQuiltOutlinedIcon',
        isVisible: true,
      },

      {
        status: 'blind',
        id: '16.12',
        level: 3,
        path: '#',
        name: '',
        label: 'H',
        icon: undefined,
        isVisible: true,
      },
    ],
    selected: false,
  },
  {
    parent: 'R',
    id: '17',
    level: 2,
    path: 'users',
    name: 'user',
    label: 'H',
    icon: 'SettingsOutlined',
    color: '',
    hasCollapsed: true,
    status: 'normal',
    isVisible: true,
    subMenus: [
      {
        status: 'normal',
        prev: '17.10',
        id: '17.11',
        next: '17.12',
        level: 3,
        path: '/reports/users/list',
        name: 'Users',
        label: 'H',
        icon: 'ViewQuiltOutlinedIcon',
        isVisible: true,
      },
      {
        status: 'normal',
        prev: '17.11',
        id: '17.12',
        next: '17.13',
        level: 3,
        path: '/reports/users/usersPerCompany',
        name: 'Users_PER_COMPANY',
        label: 'H',
        icon: 'ViewQuiltOutlinedIcon',
        isVisible: true,
      },
      {
        status: 'normal',
        prev: '17.12',
        id: '17.13',
        next: '17.14',
        level: 3,
        path: '/reports/users/assets',
        name: 'assets',
        label: 'H',
        icon: 'ViewQuiltOutlinedIcon',
        isVisible: true,
      },
      {
        status: 'blind',
        id: '17.14',
        level: 3,
        path: '#',
        name: '',
        label: 'H',
        icon: undefined,
        isVisible: true,
      },
    ],
    selected: false,
  },
  {
    parent: 'R',
    id: '18',
    level: 2,
    path: 'clients',
    name: 'client',
    label: 'H',
    icon: 'SettingsOutlined',
    color: '',
    hasCollapsed: true,
    status: 'normal',
    isVisible: true,
    subMenus: [],
    selected: false,
  },
  {
    parent: 'R',
    id: '12',
    level: 2,
    path: 'security',
    name: 'security',
    label: 'H',
    icon: 'SettingsOutlined',
    color: '',
    hasCollapsed: true,
    status: 'normal',
    subMenus: [
      {
        status: 'normal',
        level: 3,
        path: '/reports/security/roles',
        name: 'ROLES',
        label: 'H',
        icon: 'ViewQuiltOutlinedIcon',
        prev: '12',
        id: '12.1',
        next: '12.2',
        isVisible: true,
      },
      {
        status: 'normal',
        level: 3,
        path: '/reports/security/usersPerRol',
        name: 'USERS_PER_ROL',
        label: 'H',
        icon: 'ViewQuiltOutlinedIcon',
        prev: '12.1',
        id: '12.2',
        next: '12.3',
        isVisible: true,
      },
      {
        status: 'normal',
        level: 3,
        path: '/reports/security/devicesPerRol',
        name: 'DEVICES_PER_ROL',
        label: 'H',
        icon: 'ViewQuiltOutlinedIcon',
        prev: '12.2',
        id: '12.3',
        next: '12.4',
        isVisible: true,
      },
      {
        status: 'normal',
        level: 3,
        path: '/reports/security/gpioCommands',
        name: 'GPIO_COMMANDS',
        label: 'H',
        icon: 'ViewQuiltOutlinedIcon',
        prev: '12.3',
        id: '12.4',
        next: '12.5',
        isVisible: true,
      },
      {
        status: 'normal',
        level: 3,
        path: '/reports/security/nfcError',
        name: 'NFC_ERROR',
        label: 'H',
        icon: 'ViewQuiltOutlinedIcon',
        prev: '12.4',
        id: '12.5',
        next: '12.6',
        isVisible: true,
      },
      {
        status: 'blind',
        id: '12.6',
        level: 3,
        path: '#',
        name: '',
        label: 'H',
        icon: undefined,
        isVisible: true,
      },
    ],
    selected: false,
    isVisible: true,
  },
];
