//@ts-nocheck
import { action, computed } from 'easy-peasy';

const initValue = {
  evidences: [],
  reconciles: [],
  toGridMap: [],
  selectedItem: { lat: undefined, lng: undefined },
  selectedAsset: undefined,
  focusedAsset: undefined,
  devicesOnMap: [],
  isViewAll: false,
  getEvidence: undefined,
  filterCache: {},
};

const evidences = {
  ...initValue,

  addEvidences: action((state, data) => (state.evidences = data)),
  addReconciles: action((state, data) => (state.reconciles = data)),
  clearReconciles: action((state, data) => (state.reconciles = [])),
  setFilter: action((state, data) => (state.filterCache = data)),
  clearEvidences: action((state) => {
    state.evidences = [];
    state.selectedItem = { lat: undefined, lng: undefined };
    state.selectedAsset = undefined;
    state.focusedAsset = undefined;
    state.devicesOnMap = [];
    state.isViewAll = false;
  }),
  getEvidence: computed((state) => (id) => state.evidences.find((item) => +item.trackingEvidenceId === +id)),
  deleteEvidence: action((state, trackingEvidenceId) => {
    state.evidences = state.evidences.filter((item) => item.trackingEvidenceId !== +trackingEvidenceId);
  }),
  updateEvidenceStatus: action((state, { trackingEvidenceId, ...rest }) => {
    let index = state.evidences.findIndex((item) => +item.trackingEvidenceId === +trackingEvidenceId);
    state.evidences[index] = { ...state.evidences[index], ...rest };

    index = state.toGridMap.findIndex((item) => +item.trackingEvidenceId === +trackingEvidenceId);
    state.toGridMap[index] = { ...state.toGridMap[index], ...rest };
  }),
  loadToGridMap: action((state, data) => {
    state.toGridMap = data;
    // then a variable to show data on Map
  }),
  clearGridMap: action((state) => {
    state.toGridMap = [];
  }),
  onView: action((state, selectedId) => {
    const item = state.toGridMap.find((rowId) => +rowId.trackingEvidenceId === +selectedId);
    if (!item) {
      state.selectedItem = { lat: undefined, lng: undefined };
      state.selectedAsset = undefined;
      return;
    }
    state.selectedItem = { lat: item.latitude, lng: item.longitude };
    state.selectedAsset = item;
  }),
  onRemove: action((state, keyCellId) => (state.toGridMap = state.toGridMap.filter((item) => item.keyCellId != keyCellId))),
  onRemoveByTrackingEvidenceId: action((state, trackingEvidenceId) => (state.toGridMap = state.toGridMap.filter((item) => item.trackingEvidenceId != trackingEvidenceId))),
};

export default evidences;
