import { makeStyles } from '@material-ui/core/styles';
import { INPUT_BACKGROUND, whiteColor } from '../../components/styles/constValues';

export const useInputBoxStyle = makeStyles((theme) => ({
  rootDiv: {
    width: '100%',
    // borderColor: whiteColor,
    // '& .MuiOutlinedInput-root': {
    // 	'& fieldset': {
    // 		borderColor: theme.palette.primary.main,
    // 	},
    // 	'&:hover fieldset': {
    // 		borderColor: theme.palette.secondary.main,
    // 	},
    // 	'&.Mui-focused fieldset': {
    // 		borderColor: theme.palette.secondary.main,
    // 	},
    // },
  },
  root: {
    marginTop: '4px',
    padding: '4px',
    display: 'flex',
    backgroundColor: INPUT_BACKGROUND,
    alignItems: 'center',
    width: 'auto',
    border: '1px solid',
    lineHeight: 1.5,
    height: '50px',
    borderColor: INPUT_BACKGROUND,
    '&:hover': {
      color: theme.palette.primary.contrastText,
      borderColor: theme.palette.primary.main,
    },
  },
  rootOutline: {
    marginTop: '0px',
    color: theme.palette.primary.contrastText,
    borderColor: theme.palette.primary.main,
    height: '56px',
  },
  label: {
    marginLeft: '4px',
    color: theme.palette.primary.dark,
    fontSize: '16px',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    color: theme.palette.primary.main,
    // backgroundColor: INPUT_BACKGROUND,
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.primary.main,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.secondary.main,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.secondary.main,
      },
    },
  },
  iconButton: {
    padding: theme.spacing(1),
  },
  multiline: {
    height: theme.spacing(12),
  },
}));
