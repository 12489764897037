//#region imports
import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
//#region MUI
import { CardContent, CircularProgress, Typography } from '@material-ui/core';
//#endregion

//utils
import { useIntl } from '../../../lang/context/intlContext'; //addjust path reference
import { useForm } from 'react-hook-form';
//#region components

import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import Portlet from '../../../components/Portlet/Portlet';
import InputText from '../../../components/InputText/InputText';
import PortletActions from '../../../components/Portlet/PorletActions';
import Button from '../../../components/CustomButton/Button';
import { FormNameIcon, FormDescriptionIcon } from '../../../../assets/icons/formIcons';
import CustomSwitch from '../../../components/CustomSwitch/CustomSwitch';
//#endregion

//ApiMedhots
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';

//state
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useFormStyle } from '../../../common/styles/formStyle';
import ComboBox from '../../../components/ComboBox/ComboBox';
import { Alert } from '@material-ui/lab';
import CustomizedSnackbars from '../../../components/CustomSnackBar/CustomSnackBar';

import { postAddMFISEvent } from './api/postMethods';
import { getAllMasterMFISEvents, getUsersMFISEvents } from './api/getMethods';
import InputMaskText from '../../../components/InputMaskText/InputMaskText';
import CustomCheck from '../../../components/CustomCheck/CustomCheck';
// import { driversType } from './helper';
//#endregion

const schema = yup.object().shape({
  name: yup.string().required(),
});

const driverValidation = (data) => {
  //   return {};
  const errors = {};
  const { cpf, password, confirmPassword, emergencyPassword, confirmEmergencyPassword } = data;
  //allow blank cpf
  //password
  //   if (password !== confirmPassword) errors.validPassword = true;
  //   console.log('password', password);
  //   console.log('confirmPassword', confirmPassword);
  //   //emergencypassword
  //   if (emergencyPassword !== confirmEmergencyPassword) errors.validEmergencyPassword = true;

  return errors;
};

// todo: render either master mfis events or events template
// todo: load user's events and template events
// both should render the same attributes based on eventId

const New = ({ history, match, location }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [validErrors, setValidErros] = useState({});
  const [openSnack, setOpenSnack] = React.useState({ open: false, title: '', severity: 'success' });
  const [masterEventType, setMasterEventType] = useState(undefined);
  // const [masterEventType, setMasterEventType] = useState({});
  const classes = useFormStyle();
  const { i18 } = useIntl();
  const { eventId = 0, type } = match.params;
  const modePage = 'NEW'; //eventId > 0 ? 'EDIT' : 'NEW';

  const { register, handleSubmit, errors, setValue } = useForm();
  //#region easypeasy
  const setNoButtons = useStoreActions((actions) => actions.globalState.setNoButtons);
  const setPrimaryButton = useStoreActions((actions) => actions.globalState.setPrimaryButton);
  const createdNew = useStoreActions((actions) => actions.journeyControl.createdNew);
  const { clientId, clientName } = useStoreState((state) => state.user);

  const { masterMFISEventTypes, mfisEvents } = useStoreState((state) => state.events);
  const { addMasterMFISEventTypes, addMFISEvents, clearMFISEventsTable } = useStoreActions((actions) => actions.events);
  const mfisEvent = undefined; // modePage === 'EDIT' ? mfisEvents.find((item) => item.eventId === +eventId) : undefined;
  //#endregion

  const fetchData = async () => {
    try {
      setIsLoading(true);
      if (!masterMFISEventTypes.length) {
        const { data } = await getAllMasterMFISEvents();
        addMasterMFISEventTypes(data.eventTypes);
      }
      if (!mfisEvents.length) {
        const { data } = await getUsersMFISEvents();
        addMFISEvents(data.mfisEvents);
      }
      setIsLoading(false);
    } catch (error) {
      setOpenSnack((prev) => ({
        ...prev,
        open: true,
        title: i18('ERROR_API'),
        severity: 'error',
      }));
    }
  };

  useEffect(() => {
    // register({ name: 'client' }, { required: false });
    // setValue('client', driver ? driver.clientId.toString() : clientId);
    // register({ name: 'garage' }, { required: false });
    // setValue('garage', driver ? driver.garageId.toString() : '1');
    // register({ name: 'type' }, { required: false });
    // setValue('type', driver ? driver.typeDriver.toString() : '0');

    setPrimaryButton({
      text: i18('BACK'),
      isEnabled: true,
      fnc: () => {
        history.push(`/mfis/events/selecttemplate`);
      },
    });
    fetchData();
    return () => setNoButtons();
  }, []);

  const handleComboChange = (type) => (e) => {
    if (type === 'template') {
      setMasterEventType((prev) => masterMFISEventTypes.find((item) => item.messageTypeId === e.target.value));
    } else {
      setMasterEventType((prev) => mfisEvents.find((item) => item.eventId === e.target.value));
    }
  };

  const handleOnChanges = (e) => {
    const value = e.target.value;
    const field = e.target.name;
    setMasterEventType((values) => {
      const newValue = values.settings[field];
      const { settings } = values;
      return {
        ...values,
        settings: { ...settings, [field]: { ...newValue, value } },
      };
    });
  };

  const handleOnChangesCheck = (e) => {
    const field = e.target.name;
    console.info({ field });

    setMasterEventType((values) => {
      const newValue = values.settings[field];
      const { settings } = values;
      return {
        ...values,
        settings: { ...settings, [field]: { ...newValue, value: !newValue.value } },
      };
    });
  };

  const onSubmit = async (formData) => {
    try {
      setIsLoading(true);
      const body = { ...masterEventType, eventSettings: masterEventType.settings, ...formData, isBaseTemplate: type === 'template' };
      const { data } = await postAddMFISEvent(body);
      if (data.error !== 0) setOpenSnack((prev) => ({ ...prev, open: true, title: i18('ERROR_API'), severity: 'error' }));
      clearMFISEventsTable();
      setIsLoading(false);
      history.push(`/mfis/events/management-library`);
    } catch (error) {
      setIsLoading(false);
      setOpenSnack((prev) => ({ ...prev, open: true, title: i18('ERROR_API'), severity: 'error' }));
    }
  };
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <GridContainer className={classes.content}>
          <GridItem item xs={12} md={9} lg={9} className={classes.content}>
            <Portlet className={classes.formStyle} title={modePage === 'NEW' ? i18('NEW_EVENT') : i18('EDIT_EVENT')} subTitle={i18('CREATE_SUBHEADER_JC')}>
              <CardContent>
                <GridContainer spacing={2}>
                  <GridItem container>
                    <GridItem item md={4} lg={4}>
                      {type === 'template' ? (
                        <ComboBox
                          label={i18('EVENT_TYPE')}
                          icon={FormNameIcon}
                          name="client"
                          defaultValue={masterMFISEventTypes}
                          onChange={handleComboChange(type)}
                          options={masterMFISEventTypes.map((item) => ({ value: item.messageTypeId, text: item.name }))}
                          placeholder={i18('Event_TYPE')}
                        ></ComboBox>
                      ) : (
                        <ComboBox
                          label={i18('TEMPLATE')}
                          icon={FormNameIcon}
                          name="client"
                          defaultValue={mfisEvents}
                          onChange={handleComboChange(type)}
                          options={mfisEvents.map((item) => ({ value: item.eventId, text: item.name }))}
                          placeholder={i18('TEMPLATE')}
                        ></ComboBox>
                      )}
                    </GridItem>
                  </GridItem>
                  <GridItem container>
                    <GridItem item xs={12} md={4} lg={4}>
                      <InputText id="name" name="name" inputRef={register({ required: true })} defaultValue={mfisEvent?.name} label={i18('NAME')} icon={FormNameIcon} fullWidth></InputText>
                    </GridItem>
                    <GridItem item xs={12} md={8} lg={8}>
                      <InputText
                        id="description"
                        name="description"
                        inputRef={register()}
                        defaultValue={mfisEvent?.eventDescription}
                        label={i18('Description')}
                        icon={FormNameIcon}
                        fullWidth
                      ></InputText>
                    </GridItem>
                  </GridItem>
                  <GridItem container spacing={2}>
                    {masterEventType?.settings?.maxVelocity?.isEnabled && (
                      <GridItem item xs={12} md={3} lg={3}>
                        <InputMaskText
                          id="maxVelocity"
                          name="maxVelocity"
                          readOnly={!masterEventType?.settings?.maxVelocity?.isEnabled}
                          // defaultValue={masterEventType?.settings?.maxVelocity.value}
                          value={masterEventType?.settings?.maxVelocity.value}
                          label={i18('maxVelocity') + ` (${masterEventType?.settings?.maxVelocity.measure})`}
                          icon={FormNameIcon}
                          onChange={handleOnChanges}
                        ></InputMaskText>
                      </GridItem>
                    )}
                    {masterEventType?.settings?.minVelocity?.isEnabled && (
                      <GridItem item xs={12} md={3} lg={3}>
                        <InputMaskText
                          id="minVelocity"
                          name="minVelocity"
                          readOnly={!masterEventType?.settings?.minVelocity?.isEnabled}
                          // defaultValue={masterEventType?.settings?.minVelocity.value}
                          value={masterEventType?.settings?.minVelocity.value}
                          label={i18('minVelocity') + ` (${masterEventType?.settings?.minVelocity.measure})`}
                          icon={FormNameIcon}
                          onChange={handleOnChanges}
                        ></InputMaskText>
                      </GridItem>
                    )}
                    {masterEventType?.settings?.maxRPM?.isEnabled && (
                      <GridItem item xs={12} md={3} lg={3}>
                        <InputMaskText
                          id="maxRPM"
                          name="maxRPM"
                          readOnly={!masterEventType?.settings?.maxRPM?.isEnabled}
                          // defaultValue={masterEventType?.settings?.maxRPM.value}
                          value={masterEventType?.settings?.maxRPM.value}
                          label={i18('maxRPM')}
                          icon={FormNameIcon}
                          onChange={handleOnChanges}
                        ></InputMaskText>
                      </GridItem>
                    )}
                    {masterEventType?.settings?.minRPM?.isEnabled && (
                      <GridItem item xs={12} md={3} lg={3}>
                        <InputMaskText
                          id="minRPM"
                          name="minRPM"
                          readOnly={!masterEventType?.settings?.minRPM?.isEnabled}
                          // defaultValue={masterEventType?.settings?.minRPM.value}
                          value={masterEventType?.settings?.minRPM.value}
                          label={i18('minRPM')}
                          icon={FormNameIcon}
                          onChange={handleOnChanges}
                        ></InputMaskText>
                      </GridItem>
                    )}
                    {masterEventType?.settings?.maxAccelerationAxisY?.isEnabled && (
                      <GridItem item xs={12} md={3} lg={3}>
                        <InputMaskText
                          id="maxAccelerationAxisY"
                          name="maxAccelerationAxisY"
                          readOnly={!masterEventType?.settings?.maxAccelerationAxisY?.isEnabled}
                          // defaultValue={masterEventType?.settings?.maxAccelerationAxisY.value}
                          value={masterEventType?.settings?.maxAccelerationAxisY.value}
                          label={i18('maxAccelerationAxisY') + ` (${masterEventType?.settings?.maxAccelerationAxisY.measure})`}
                          icon={FormNameIcon}
                          onChange={handleOnChanges}
                        ></InputMaskText>
                      </GridItem>
                    )}
                    {masterEventType?.settings?.minAccelerationAxisY?.isEnabled && (
                      <GridItem item xs={12} md={3} lg={3}>
                        <InputMaskText
                          id="minAccelerationAxisY"
                          name="minAccelerationAxisY"
                          readOnly={!masterEventType?.settings?.minAccelerationAxisY?.isEnabled}
                          // defaultValue={masterEventType?.settings?.minAccelerationAxisY.value}
                          value={masterEventType?.settings?.minAccelerationAxisY.value}
                          label={i18('minAccelerationAxisY') + ` (${masterEventType?.settings?.minAccelerationAxisY.measure})`}
                          icon={FormNameIcon}
                          onChange={handleOnChanges}
                        ></InputMaskText>
                      </GridItem>
                    )}
                    {masterEventType?.settings?.maxAccelerationAxisX?.isEnabled && (
                      <GridItem item xs={12} md={3} lg={3}>
                        <InputMaskText
                          id="maxAccelerationAxisX"
                          name="maxAccelerationAxisX"
                          readOnly={!masterEventType?.settings?.maxAccelerationAxisX?.isEnabled}
                          // defaultValue={masterEventType?.settings?.maxAccelerationAxisX.value}
                          value={masterEventType?.settings?.maxAccelerationAxisX.value}
                          label={i18('maxAccelerationAxisX') + ` (${masterEventType?.settings?.maxAccelerationAxisX.measure})`}
                          icon={FormNameIcon}
                          onChange={handleOnChanges}
                        ></InputMaskText>
                      </GridItem>
                    )}
                    {masterEventType?.settings?.minAccelerationAxisX?.isEnabled && (
                      <GridItem item xs={12} md={3} lg={3}>
                        <InputMaskText
                          id="minAccelerationAxisX"
                          name="minAccelerationAxisX"
                          readOnly={!masterEventType?.settings?.minAccelerationAxisX?.isEnabled}
                          // defaultValue={masterEventType?.settings?.minAccelerationAxisX.value}
                          value={masterEventType?.settings?.minAccelerationAxisX.value}
                          label={i18('minAccelerationAxisX') + ` (${masterEventType?.settings?.minAccelerationAxisX.measure})`}
                          icon={FormNameIcon}
                          onChange={handleOnChanges}
                        ></InputMaskText>
                      </GridItem>
                    )}
                    {masterEventType?.settings?.delay?.isEnabled && (
                      <GridItem item xs={12} md={3} lg={3}>
                        <InputMaskText
                          id="delay"
                          name="delay"
                          disabled={!masterEventType}
                          readOnly={!masterEventType?.settings?.delay?.isEnabled}
                          // defaultValue={masterEventType?.settings?.delay.value}
                          value={masterEventType?.settings?.delay.value}
                          label={i18('delay') + ` (${masterEventType?.settings?.delay.measure})`}
                          icon={FormNameIcon}
                          onChange={handleOnChanges}
                        ></InputMaskText>
                      </GridItem>
                    )}
                    {masterEventType?.settings?.useGPS.isEnabled && (
                      <GridItem item xs={3} md={3}>
                        <ComboBox
                          label={i18('CAN_OR_CANGPS')}
                          icon={FormNameIcon}
                          id="useGPS"
                          name="useGPS"
                          value={masterEventType?.settings?.useGPS.value}
                          onChange={(e) => handleOnChanges(e)}
                          options={[
                            { value: false, text: 'CAN' },
                            { value: true, text: 'CAN/GPS' },
                          ]}
                          placeholder={i18('Event_TYPE')}
                        ></ComboBox>
                      </GridItem>
                    )}
                    {masterEventType?.settings?.input1Status.isEnabled && (
                      <GridItem item xs={3} md={3}>
                        <CustomCheck
                          disabled={!masterEventType}
                          id="input1Status"
                          name="input1Status"
                          label={i18('input1Status')}
                          checked={masterEventType?.settings?.input1Status.value}
                          onChange={handleOnChangesCheck}
                        />
                      </GridItem>
                    )}
                    {masterEventType?.settings?.input2Status.isEnabled && (
                      <GridItem item xs={3} md={3}>
                        <CustomCheck
                          disabled={!masterEventType}
                          name="input2Status"
                          label={i18('input2Status')}
                          checked={masterEventType?.settings?.input2Status.value}
                          onChange={handleOnChangesCheck}
                        />
                      </GridItem>
                    )}
                    {masterEventType?.settings?.SetOutput1.isEnabled && (
                      <GridItem item xs={3} md={3}>
                        <CustomCheck disabled={!masterEventType} name="SetOutput1" label={i18('SetOutput1')} checked={masterEventType?.settings?.SetOutput1.value} onChange={handleOnChangesCheck} />
                      </GridItem>
                    )}
                    {masterEventType?.settings?.SetOutput2.isEnabled && (
                      <GridItem item xs={3} md={3}>
                        <CustomCheck disabled={!masterEventType} name="SetOutput2" label={i18('SetOutput2')} checked={masterEventType?.settings?.SetOutput2.value} onChange={handleOnChangesCheck} />
                      </GridItem>
                    )}
                    {masterEventType?.settings?.eventReportFrequency?.isEnabled && (
                      <GridItem item xs={12} md={3} lg={3}>
                        <InputMaskText
                          id="eventReportFrequency"
                          name="eventReportFrequency"
                          readOnly={!masterEventType?.settings?.eventReportFrequency?.isEnabled}
                          value={masterEventType?.settings?.eventReportFrequency.value}
                          label={i18('eventReportFrequency')}
                          icon={FormNameIcon}
                          onChange={handleOnChanges}
                        ></InputMaskText>
                      </GridItem>
                    )}
                    {masterEventType?.settings?.reportEvent.isEnabled && (
                      <GridItem item xs={3} md={3}>
                        <CustomCheck name="reportEvent" label={i18('reportEvent')} checked={masterEventType?.settings?.reportEvent.value} onChange={handleOnChangesCheck} />
                      </GridItem>
                    )}
                    {masterEventType?.settings?.playAudio.isEnabled && (
                      <GridItem item xs={3} md={3}>
                        <CustomCheck name="playAudio" label={i18('playAudio')} checked={masterEventType?.settings?.playAudio.value} onChange={handleOnChangesCheck} />
                      </GridItem>
                    )}
                    {masterEventType?.settings?.playSound.isEnabled && (
                      <GridItem item xs={3} md={3}>
                        <CustomCheck name="playSound" label={i18('playSound')} checked={masterEventType?.settings?.playSound.value} onChange={handleOnChangesCheck} />
                      </GridItem>
                    )}
                    {masterEventType?.settings?.sendSummary.isEnabled && (
                      <GridItem item xs={3} md={3}>
                        <CustomCheck name="sendSummary" label={i18('sendSummary')} checked={masterEventType?.settings?.sendSummary.value} onChange={handleOnChangesCheck} />
                      </GridItem>
                    )}
                  </GridItem>
                  <GridItem container></GridItem>
                </GridContainer>
              </CardContent>
              <PortletActions>
                <div className={classes.div}>
                  <div className={classes.inDiv}>
                    <Button variant="contained" disabled={isLoading} color={'cancel'} onClick={() => history.push(`/mfis/events/management-library`)}>
                      {i18('Cancel')}
                    </Button>
                  </div>
                  <div className={classes.inDiv}>
                    <Button type="submit" disabled={isLoading || !masterEventType} variant="contained" color={'primary'}>
                      {modePage === 'NEW' ? i18('Create') : i18('Save')}
                    </Button>
                    {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </div>
                </div>
              </PortletActions>
            </Portlet>
          </GridItem>
        </GridContainer>
      </form>
      <CustomizedSnackbars open={openSnack.open} setOpen={setOpenSnack} title={openSnack.title} severity={openSnack.severity}></CustomizedSnackbars>
    </div>
  );
};
New.propTypes = {
  // title: PropTypes.string,
  // numSelected: PropTypes.number.isRequired,
  // children: PropTypes.object,
};

New.defaultProps = {
  //icon: null,
};
export default New;
