export const initSnack = {
  open: false,
  title: '',
  severity: 'error',
};

// Data for table
const patterHeaderStyle = {
  paddingLeft: '0px',
  paddingRight: '0px',
  paddingTop: '8px',
  paddingBottom: '8px',
  textAlign: 'left',
};
const patternCellStyle = {
  paddingLeft: '0px',
  paddingRight: '0px',
  paddingTop: '10px',
  paddingBottom: '10px',
  textAlign: 'left',
};

export const columnsDevice = [
  {
    title: 'SERIAL',
    field: 'serial',
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'ASSET',
    field: 'plates',
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'MODEL',
    field: 'deviceTypeName',
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'FUNCTION',
    field: 'functionName',
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'IMEI',
    field: 'imei',
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'ICCID',
    field: 'ICCID',
    filtering: false,
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: { ...patterHeaderStyle },
  },
  {
    title: 'COMPANY',
    field: 'clientName',
    filtering: false,
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
];
