import React, { useState, useEffect, useLayoutEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import Portlet from '../../../components/Portlet/Portlet';
import { useForm } from 'react-hook-form';
import { FormNameIcon, FormDescriptionIcon } from '../../../../assets/icons/formIcons';
import { Grid, CardContent, CircularProgress, Typography, InputLabel } from '@material-ui/core';
import { INPUT_BACKGROUND, whiteColor, blackColor } from '../../../components/styles/constValues';
import InputText from '../../../components/InputText/InputText';
import PortletActions from '../../../components/Portlet/PorletActions';
import Button from '../../../components/CustomButton/Button';
import { useIntl } from '../../../lang/context/intlContext';
import { SketchPicker } from 'react-color';
//ApiMedhots
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { Alert } from '@material-ui/lab';
import { Label } from '@material-ui/icons';
import ComboBox from '../../../components/ComboBox/ComboBox';
import Popover from '@material-ui/core/Popover';
//state
import { getRegionNameAvailability } from '../api/getMethods';
import { postCreateRegion } from '../api/postMethods';
import { useStoreActions } from 'easy-peasy';
import InputTimeDate from '../../../components/InputTimeDate/InputTimeDate';
import { initFilter } from '../../Reports/communication/positions/dataPositions';
import { compareAsc, differenceInMonths, parseISO } from 'date-fns';
import { evidenceStatus } from '../../mfis/evidence/helper';

const evidenceLevel = [
  {
    value: 0,
    text: '(N/A)',
  },
  {
    value: 1,
    text: 'Baixo',
  },
  {
    value: 2,
    text: 'Médio',
  },
  {
    value: 3,
    text: 'Alto',
  },
];

const evidenceType = [
  {
    value: 0,
    text: '(N/A)',
  },
  {
    value: 1,
    text: 'Alarmes IO',
  },
  {
    value: 2,
    text: 'Identificação de movimentação',
  },
  {
    value: 3,
    text: 'Mascaramento',
  },
  {
    value: 4,
    text: 'Perda de Vídeo',
  },
  {
    value: 9,
    text: 'Aceleração',
  },
  {
    value: 10,
    text: 'Alta Velocidade',
  },
  {
    value: 11,
    text: 'Baixa Velocidade',
  },
  {
    value: 12,
    text: 'Alta Temperatura',
  },
  {
    value: 13,
    text: 'Baixa Temperatura',
  },
  {
    value: 16,
    text: 'Acionamento Botão de Emergência',
  },
  {
    value: 18,
    text: 'Região',
  },
  {
    value: 29,
    text: 'Alarme de Temperatura',
  },

  {
    value: 58,
    text: 'Condutor em Fadiga',
  },
  {
    value: 59,
    text: 'Veículo sem condutor',
  },
  {
    value: 60,
    text: 'Condutor no Celular',
  },
  {
    value: 61,
    text: 'Condutor Fumando',
  },
  {
    value: 62,
    text: 'Condutor sob distração',
  },
  {
    value: 63,
    text: 'Mudança de Faixa sem sinalizar',
  },
  {
    value: 64,
    text: 'Possível Colisão frontal',
  },
  {
    value: 162,
    text: 'Distancia Muito Próxima',
  },
  {
    value: 163,
    text: 'Identificação de Pedestre',
  },
  {
    value: 164,
    text: 'Condutor Bocejando',
  },
  {
    value: 165,
    text: 'Ponto cego a esquerda',
  },
  {
    value: 166,
    text: 'Ponto cego a direta',
  },
];

const useStyle = makeStyles((theme) => ({
  root: {
    // margin: theme.spacing(4),
  },

  content: {
    display: 'flex',
    justifyContent: 'center',
    // height: theme.spacing(60),
    width: '100%',
  },
  div: {
    display: 'flex',
    // justifyContent: 'flex-end',
    flexDirection: 'row',
    justifyContent: 'end',
    // padding: theme.spacing(4),
  },
  formStyle: { width: '100%' },
  inDiv: {
    marginTop: '0px',
    padding: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: theme.palette.primary.dark[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  alert: { margin: theme.spacing(2) },
  label: {
    marginLeft: '4px',
    color: theme.palette.primary.dark,
    fontSize: '12px',
  },
}));
// const schema = yup.object().shape({
//   name: yup.string().required(),
//   // age: yup.number().positive().integer().required(),
// });
export function EvidenceFilter({ i18, onClearEvidences = undefined, onClickFilter = undefined }) {
  const classes = useStyle();
  const [color, setColor] = React.useState('#f01111');
  const [strokecolor, setStrokeColor] = React.useState('#f01111');
  const [isLoading, setisLoading] = useState(false);
  const [free, setfree] = useState(undefined);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const { register, handleSubmit, errors, setValue } = useForm();
  const [monthError, setMonthError] = useState(false);
  const [dateError, setDateError] = useState(false);

  useEffect(() => {
    register({ name: 'type' }, { required: false });
    register({ name: 'level' }, { required: false });
    register({ name: 'status' }, { required: false });
    setValue('type', 0);
    setValue('level', 0);
    setValue('status', 0);
  }, []);
  const [formValues, setFormValues] = useState(initFilter);
  const { startDateTime, endDateTime } = formValues;
  // useLayoutEffect(() => {
  //   if (!options) return;
  //   console.log('LEFFECT REGION OPTIONS');
  //   setValue('fillColor', options.fillColor);
  //   setValue('strokeColor', options.color);
  // }, [options]);

  //#region handlers
  const handleStatusChange = (e) => {
    setValue('status', e.target.value, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };
  const handleLevelChange = (e) => {
    setValue('level', e.target.value, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };
  const handleTypeChange = (e) => {
    setValue('type', e.target.value, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };
  const handleInputChange = ({ target }) => {
    switch (target.name) {
      case 'startDateTime':
        setFormValues({
          ...formValues,
          [target.name]: target.value,
        });
        const months = differenceInMonths(parseISO(endDateTime), parseISO(target.value));
        if (months > 3) {
          setMonthError(true);
        } else {
          setMonthError(false);
        }
        break;
      case 'endDateTime':
        setFormValues({
          ...formValues,
          [target.name]: target.value,
        });
        const result = compareAsc(parseISO(startDateTime), parseISO(target.value));
        if (result > 0) {
          setDateError(true);
        } else {
          setDateError(false);
        }
        const months1 = differenceInMonths(parseISO(target.value), parseISO(startDateTime));
        if (months1 > 3) {
          setMonthError(true);
        } else {
          setMonthError(false);
        }
        break;
      default:
        setFormValues({
          ...formValues,
          [target.name]: target.value,
        });
        break;
    }
  };
  const onSubmit = async (data) => {
    try {
      const { startDateTime, endDateTime } = formValues;
      onClickFilter({ ...data, startDateTime, endDateTime });
    } catch (error) {
      console.error(error);
      setisLoading(false);
    }
  };
  //#endregion

  //#region Popover Handlers

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  //#endregion
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const open2 = Boolean(anchorEl2);
  const id2 = open ? 'simple-popover' : undefined;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <GridContainer className={classes.content}>
        <GridItem item xs={12} md={6} lg={12} className={classes.content}>
          <Portlet className={classes.formStyle} title={i18('PARAMETERS')} subTitle="">
            <CardContent>
              <GridContainer direction="column" spacing={0}>
                <GridItem item xs={12} md={12}>
                  <ComboBox
                    label={i18('STATUS')}
                    icon={FormNameIcon}
                    name="evidenceStatus"
                    defaultValue={0}
                    onChange={handleStatusChange}
                    options={evidenceStatus}
                    placeholder={i18('Groups')}
                    className={{ fontSize: '10px' }}
                  ></ComboBox>
                </GridItem>
                {/* <GridItem item xs={12} md={12}>
                  <ComboBox
                    label={i18('NIVEL_EVIDENCE')}
                    icon={FormNameIcon}
                    name="evidenceLevel"
                    defaultValue={0}
                    onChange={handleLevelChange}
                    options={evidenceLevel}
                    placeholder={i18('Groups')}
                  ></ComboBox>
                  {free && <Typography color="secondary" style={{ maxWidth: '200px' }}>{`${free} ${i18('NOTAVAI2')}`}</Typography>}
                  {errors.name && <Typography color="secondary">{i18('REQ')}</Typography>}
                </GridItem> */}

                <GridItem item xs={12} md={12}>
                  <ComboBox
                    label={i18('SELECT_EVENT')}
                    icon={FormNameIcon}
                    name="evidenceType"
                    defaultValue={0}
                    onChange={handleTypeChange}
                    options={evidenceType.sort((a, b) => {
                      if (a.text > b.text) {
                        return 1;
                      }
                      if (a.text < b.text) {
                        return -1;
                      }
                      // a must be equal to b
                      return 0;
                    })}
                    placeholder={i18('Groups')}
                  ></ComboBox>
                </GridItem>
                <GridItem item xs={12} md={12}>
                  <InputText className={classes.label} id="plates" placeholder={i18('Plates')} name="plates" label={i18('Plates')} icon={FormNameIcon} inputRef={register()} fullWidth></InputText>
                </GridItem>
                <GridItem item container direction="row" xs={12} md={12}>
                  <InputText className={classes.label} id="driver" placeholder={i18('DRIVER')} name="driver" label={i18('DRIVER')} icon={FormNameIcon} inputRef={register()} fullWidth></InputText>
                </GridItem>
                <GridItem item xs={12} md={12}>
                  <InputTimeDate
                    id="startDateTime"
                    name="startDateTime"
                    label={i18('START_DATE_TIME')}
                    // style={{ paddingRight: '0px' }}
                    icon={FormNameIcon}
                    value={startDateTime}
                    onChange={handleInputChange}
                  />
                </GridItem>
                <GridItem item xs={12} md={12}>
                  <InputTimeDate id="endDateTime" name="endDateTime" label={i18('END_DATE_TIME')} icon={FormNameIcon} value={endDateTime} onChange={handleInputChange} />
                  {dateError && <Typography color="secondary">{i18('ERROR_DATE')}</Typography>}
                  {monthError && <Typography color="secondary">{i18('ERROR_MONTH')}</Typography>}
                </GridItem>
                <GridItem item xs={12} md={12}>
                  {/* <InputText
                    id="radius"
                    disabled={!isCircle}
                    readOnly={true}
                    // className={classes.meterStyle}
                    placeholder={'(' + i18('required') + ')'}
                    name="radius"
                    label={i18('radius')}
                    icon={FormNameIcon}
                    defaultValue={radius}
                    fullWidth
                    // inputRef={register({ required: true })}
                  ></InputText> */}
                </GridItem>
              </GridContainer>
            </CardContent>
            <PortletActions>
              <div className={classes.div}>
                <div className={classes.inDiv}>
                  <Button
                    variant="contained"
                    disabled={isLoading}
                    color={'cancel'}
                    onClick={() => {
                      onClearEvidences();
                    }}
                  >
                    {i18('Clear')}
                  </Button>
                </div>
                <div className={classes.inDiv}>
                  <Button type="submit" disabled={isLoading} variant="contained" color={'primary'}>
                    {i18('Filter')}
                  </Button>
                  {/* <CircularProgress size={24} className={classes.buttonProgress} /> */}
                  {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
              </div>
            </PortletActions>
          </Portlet>
        </GridItem>
      </GridContainer>
    </form>
  );
}
