//@ts-nocheck
import { action, computed } from 'easy-peasy';
//primary: {text,}
const initValues = {
  assets: [],
  toGridMap: [],
  checkedItems: [],
  selectedItem: { lat: undefined, lng: undefined },
  selectedAsset: undefined,
  focusedAsset: undefined,
  devicesOnMap: [],
  isViewAll: false,
};
const map = {
  ...initValues,
  //function to add assets coming from API
  addAssets: action((map, assets) => {
    // console.info(assets);
    // const assetsFields = assets.map((item) => ({ ...item, ...item.fields }));
    map.assets = [...assets];
  }),
  //function to add selected assets to map and grid
  loadToGridMap: action((map, { selectedIds, checkedItems }) => {
    const distinctRows = selectedIds.reduce((acc, item) => {
      if (acc.has(item.keyId)) return acc;

      // const { fields, ...rest } = item;
      // const itemFields = { ...fields, ...rest };
      // console.info(itemFields);
      acc.set(item.keyId, item);
      return acc;
    }, new Map());
    map.checkedItems = checkedItems.length ? checkedItems.map((item) => item.keyCellId) : [];
    map.toGridMap = Array.from(distinctRows.values());
    map.devicesOnMap = Array.from(distinctRows.keys());
    // map.selectedItem = { lat: undefined, lng: undefined };
    // map.selectedAsset = undefined;
    map.isViewAll = true;
  }),
  //funcion llamada al hacer click en el grid
  onRemove: action((map, keyCellId) => (map.toGridMap = map.toGridMap.filter((item) => item.keyCellId != keyCellId))),
  onView: action((map, selectedId) => {
    const item = map.toGridMap.find((rowId) => +rowId.keyId === +selectedId);
    if (!item) {
      map.selectedItem = { lat: undefined, lng: undefined };
      map.selectedAsset = undefined;
      return;
    }
    map.selectedItem = { lat: item.latitude, lng: item.longitude };
    map.selectedAsset = item;
  }),
  onViewAll: action((map) => {
    map.selectedItem = { lat: undefined, lng: undefined };
    map.selectedAsset = undefined;
    map.isViewAll = true;
  }),
  onFocus: action((map, focus) => {
    if (!focus) {
      map.focusedAsset = undefined;
    } else {
      if (map.selectedAsset) {
        map.focusedAsset = map.selectedAsset;
      }
    }
  }),
  //funciona llamada cuando se recibe un mensaje via socket
  onTxMessage: action((map, message) => {
    map.isViewAll = false;
    map.selectedItem = { lat: undefined, lng: undefined };
    map.toGridMap = map.toGridMap.map((asset) => {
      const newPosition = message.find((item) => item.device === asset.serial);
      if (!newPosition) return asset;
      return { ...asset, ...newPosition, fields: { ...asset.fields, ...newPosition } };
    });
    if (map.selectedAsset) {
      const selectedAsset = map.toGridMap.find((item) => item.deviceId === map.selectedAsset.deviceId);
      if (selectedAsset) map.selectedAsset = selectedAsset;
    }
    //si tenemos una unidad en foco
    if (map.focusedAsset && map.selectedAsset) {
      //verificamos si es la misma seleccionada para dar mayor rapidez
      if (map.focusedAsset.deviceId === map.selectedAsset.deviceId) map.focusedAsset = map.selectedAsset;
      else {
        //sino buscamos en toGridMap la cual contiene la data mas reciente
        const focusedAsset = map.toGridMap.find((item) => item.deviceId === map.focusedAsset.deviceId);
        if (focusedAsset) map.focusedAsset = focusedAsset;
      }
    }

    //buscar si los mensajes de actualizacion contiene el nuevo
  }),
  updateAssets: action((map) => (map.assets = [])),
  //devuelve los deviceIds de los assets cargados en el grid
  // devicesOnMap: computed((map) => {
  //   if (!map.toGridMap) return [];
  //   return map.toGridMap.map((rowId) => +rowId.keyId);
  // }),
  //   onLogin: action((global, payload) => {
  //     const { token, userName, email, clientName } = payload;
  //     global.token = token;
  //     global.userName = userName;
  //     global.email = email;
  //     global.clientName = clientName;
  //     global.isAuthorized = true;
  //   }),
  //   onFailLogin: action((global) => {
  //     global.token = undefined;
  //   }),
};

export default map;
