import { makeStyles } from '@material-ui/core/styles';
import { INPUT_BACKGROUND } from '../../components/styles/constValues';

export const useInputStyle = makeStyles((theme) => ({
  rootDiv: { width: '100%' },
  root: {
    marginTop: '4px',
    padding: '4px',
    display: 'flex',
    backgroundColor: INPUT_BACKGROUND,
    alignItems: 'center',
    width: 'auto',
    border: '1px solid',
    lineHeight: 1.5,
    height: '50px',
    borderColor: INPUT_BACKGROUND,
    '&:hover': {
      color: theme.palette.primary.contrastText,
      borderColor: theme.palette.primary.main,
    },
  },
  disabled: {
    backgroundColor: '#e2e9ff',
    '&:hover': {
      border: 'none',
    },
  },
  smallRoot: {
    height: 'auto',
    marginTop: '0px',
    paddingLeft: '0px',
  },
  rootOutline: {
    marginTop: '0px',
    color: theme.palette.primary.contrastText,
    borderColor: theme.palette.primary.main,
    height: '56px',
  },
  label: {
    marginLeft: '4px',
    color: theme.palette.primary.dark,
    fontSize: '16px',
  },
  required: {
    marginLeft: '2px',
    color: 'red',
    fontSize: '20px',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    color: theme.palette.text.secondary,
    backgroundColor: INPUT_BACKGROUND,
  },
  disablerInput: {
    backgroundColor: '#e2e9ff',
  },
  smallInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
    color: theme.palette.text.secondary,
    backgroundColor: INPUT_BACKGROUND,
    fontSize: '14px',
  },
  iconButton: {
    padding: theme.spacing(1),
  },
  multiline: {
    height: theme.spacing(12),
  },
}));
