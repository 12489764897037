import axios from 'axios';

export const GET_ALL_REGIONS = '/region/getAll';
export const GET_ALL_USERS_REGIONS = '/region/getAllUsersRegions';

export const GET_ALL_TABLETS = '/assets/getTablets';
export const GET_ALL_ASSETS = '/assets/getAll';
export const GET_ALL_ASSETS_LNP = '/assets/getAssets';

export const GET_SPOKEN_ROUTES_STATUS = '/rotograma/statusAll';
// export const get_spoken_routes = () => '/rotograma/getAll;
export const GET_SPOKEN_ROUTES_BYUSERID = '/rotograma/getAll';
export const GET_SPOKEN_ROUTES_NAME_AVAILABILITY = '/rotograma/checkNameAvailability';
export const GET_SPOKENROUTE_BYID = '/rotograma/getDefinition';

export function getSpokenRouteById(spokenRouteId) {
  return axios.get(`${GET_SPOKENROUTE_BYID}?spokenRouteId=${spokenRouteId}`);
}
export function getNameAvailability(name) {
  return axios.get(`${GET_SPOKEN_ROUTES_NAME_AVAILABILITY}?name=${name}`);
}
export function getAllAssetsLNP(spokenRouteId) {
  return axios.get(`${GET_ALL_ASSETS_LNP}`);
}

// export function getSpokenRoutes() {
//   return axios.get(get_spoken_routes());
// }
