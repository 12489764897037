import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
// import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
// import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
// import FavoriteIcon from '@material-ui/icons/Favorite';
// import ShareIcon from '@material-ui/icons/Share';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import MoreVertIcon from '@material-ui/icons/MoreVert';
import Button from '../CustomButton/Button';
import { WHITE, whiteColor } from '../styles/constValues';
import { CircularProgress } from '@material-ui/core';
// import { TemplateIcon } from '../../../assets/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: '0 10px 20px 0 rgba(92, 116, 129, 0.08)',
    borderRadius: '8px',
    minWidth: theme.spacing(28),
    borderTopStyle: 'solid',
    borderTopWidth: theme.spacing(1),
    borderTopColor: theme.palette.primary.main,
    paddingBottom: '3px',
    transition: '0.05s',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  rootSelected: { backgroundColor: theme.palette.primary.main },
  rootHover: {
    margin: '3px',
    marginTop: theme.spacing(0),
    marginBottom: '3px',
    backgroundColor: WHITE,
    borderRadius: '8px',
    height: '100%',
  },
  rootHoverSelected: {
    backgroundColor: theme.palette.primary.main,
  },
  rotogramaRoot: {
    minWidth: theme.spacing(33),
  },
  altHeaderStyle: {
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(4),
  },
  rotogramaHeaderStyle: {
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(4),
  },
  rotogramaHeaderSelectedStyle: {
    color: WHITE,
  },
  contentStyle: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(0),
  },
  rotogramaContentStyle: { paddingLeft: theme.spacing(4), alignItems: 'flex-start' },
  buttonProgress: {
    color: whiteColor, // theme.palette.primary.dark[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  contentTextStyle: {
    fontSize: '16px',
    display: 'flex',
    fontFamily: 'Roboto-Bold',
  },
  avatarStyle: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  iconStyle: {
    height: '108px',
    width: '108px',
  },
  actionStyle: {
    display: 'flex',
    paddingBottom: theme.spacing(4),
    justifyContent: 'center',
  },
  rotogramaActionStyle: {
    paddingLeft: theme.spacing(4),
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  buttonText: {
    fontSize: '12px',
  },
  left: {
    alignItems: 'flex-start',
    textAlign: 'left',
    '&.MuiCardHeader-content': { width: '100%' },
  },
  center: {
    alignItems: 'center',
    textAlign: 'center',

    '&.MuiCardHeader-content': { width: '100%' },
  },
  right: {
    alignItems: 'flex-end',
    textAlign: 'right',
    '&.MuiCardHeader-content': { width: '100%' },
  },
}));

const CustomCard = (props) => {
  const { className, onSelect, title, titleNoWrap = true, value, disabledButton, contentText, buttonText, onClick, hasAvatar, align, typeCard, selected, isLoading } = props;

  //#region JoinClasses
  const classes = useStyles();
  const cardClasses = clsx({
    [classes.root]: true,
    [className]: className,
    [classes[align]]: align,
    [classes.rootSelected]: selected,
  });
  const rootHoverClasses = clsx({
    [classes.rootHover]: true,
    [classes[align]]: align,
    [classes.rootHoverSelected]: selected,
  });

  const headerClasses = clsx({
    [classes.altHeaderStyle]: typeCard === 'alt',
    [classes.rotogramaHeaderStyle]: typeCard === 'rotograma',
    [classes[align]]: align,
  });
  const contentClasses = clsx({
    [classes.contentStyle]: true,
    [classes.rotogramaContentStyle]: typeCard === 'rotograma',
    [classes[align]]: align,
  });
  const actionClasses = clsx({
    [classes.actionStyle]: true,
    [classes.rotogramaActionStyle]: typeCard === 'rotograma',
  });
  //#endregion
  return (
    <Card className={cardClasses}>
      {/* <div className={classes.headerLine}></div> */}
      <div className={rootHoverClasses}>
        {onSelect ? (
          <CardActionArea onClick={(e) => onSelect(e, value)}>
            <CardHeader title={title} className={headerClasses} titleTypographyProps={selected ? { variant: 'subtitle2', noWrap: titleNoWrap } : { noWrap: titleNoWrap }} />
            <CardContent className={contentClasses}>
              {hasAvatar && (
                <div className={classes.avatarStyle}>
                  <Avatar src="/assets/icons/images/Icon_template-jornada.svg" variant="rounded" className={classes.iconStyle}></Avatar>
                </div>
              )}
              {contentText && (
                <Typography className={classes.contentTextStyle} variant={selected ? 'body2' : 'body1'}>
                  {contentText}
                </Typography>
              )}
            </CardContent>
          </CardActionArea>
        ) : (
          <>
            <CardHeader title={title} className={headerClasses} titleTypographyProps={selected ? { variant: 'subtitle2', noWrap: true } : { noWrap: true }} />
            <CardContent className={contentClasses}>
              {hasAvatar && (
                <div className={classes.avatarStyle}>
                  <Avatar src="/assets/icons/images/Icon_template-jornada.svg" variant="rounded" className={classes.iconStyle}>
                    {/* <TemplateIcon /> */}
                  </Avatar>
                </div>
              )}
              {contentText && (
                <Typography className={classes.contentTextStyle} variant={selected ? 'body2' : 'body1'}>
                  {contentText}
                </Typography>
              )}
            </CardContent>
          </>
        )}
        <CardActions disableSpacing className={actionClasses}>
          {buttonText && (
            <div className={classes.wrapper}>
              <Button disabled={disabledButton} className={classes.buttonText} color={selected ? 'focus' : 'primary'} onClick={(e) => onClick(e, value)}>
                {buttonText}
              </Button>
              {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
          )}

          {/* <IconButton aria-label="Add to favorites">
          <FavoriteIcon />
        </IconButton>
        <IconButton aria-label="Share">
          <ShareIcon />
        </IconButton> */}
          {/* <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="Show more"
        > */}
          {/* <ExpandMoreIcon />
        </IconButton> */}
        </CardActions>
      </div>
    </Card>
  );
};

CustomCard.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  titleNoWrap: PropTypes.bool,
  value: PropTypes.any,
  contentText: PropTypes.string,
  buttontText: PropTypes.string,
  onClick: PropTypes.func,
  onSelect: PropTypes.func,
  hasAvatar: PropTypes.bool,
  align: PropTypes.string,
  typeCard: PropTypes.oneOf(['normal', 'rotograma', 'alt']),
  selected: PropTypes.any,
  disabledButton: PropTypes.bool,
  isLoading: PropTypes.bool,
  // numSelected: PropTypes.number.isRequired,
  // children: PropTypes.object,
};

CustomCard.defaultProps = {
  buttonText: undefined,
  hasAvatar: true,
  align: 'center',
  typeCard: 'normal',
  selected: undefined,
  disabledButton: false,
  value: 0,
  isLoading: false,
};
export default CustomCard;
