import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

//#region components

import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import Portlet from '../Portlet/Portlet';
import InputText from '../InputText/InputText';
import PortletActions from '../Portlet/PorletActions';
import Button from '../CustomButton/Button';
import CustomizedSnackbars from '../CustomSnackBar/CustomSnackBar';
import { CardContent, CircularProgress, colors, Step, StepLabel, Stepper } from '@material-ui/core';
import { useIntl } from '../../lang/context/intlContext';
import { useFormStyle } from '../../common/styles/formStyle';
//#endregion

//Stepper helspers

const SuperStepper = (props) => {
  const { title = 'NFC SETUP', subTitle = 'FOLLOW_INSTRUCTIONS2', labelResetStep, labelFinalStep, steps, getStepContent, validators = [], onSubmit = undefined, onResetSubmit = undefined, stepperSize = 'md', actionButtons = [], returnFirstStep = undefined } = props;
  const classes = useFormStyle();
  const history = useHistory();
  const { i18 } = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const [openSnack, setOpenSnack] = React.useState({ open: false, title: 'INVALIDAR', severity: 'success' });
  const [activeStep, setActiveStep] = React.useState(0);

  const buttonsInStep = actionButtons.filter((action) => action.step === activeStep);

  const stepperSizeNumber = stepperSize === 'lg' ? 12 : 9;
  //#region handlers

  const onFinalStepSubmit = async () => {
    try {
      setIsLoading(true);
      const res = await onSubmit();
      const { data } = res;
      if (data.error !== 0) setOpenSnack((prev) => ({ ...prev, open: true, title: i18('ERROR_API'), severity: 'error' }));
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setOpenSnack((prev) => ({ ...prev, open: true, title: i18('ERROR_API'), severity: 'error' }));
    }
  };

  const handleNext = () => {
    const isUpdate = activeStep === steps.length - 1;
    if (isUpdate) onFinalStepSubmit();
    else setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 0) {
      history.goBack();
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = async () => {
    if (onResetSubmit) {
      onResetSubmit();
    } else {
      setIsLoading(false);
      setActiveStep(0);
    }
  };
  //#endregion

  return (
    <div>
      <GridContainer className={classes.content}>
        <GridItem item xs={12} md={stepperSizeNumber} lg={stepperSizeNumber} className={classes.content}>
          <Portlet className={classes.formStyle} title={i18(title)} subTitle={i18(subTitle)} slim>
            <CardContent>
              <Stepper style={{ padding: '0px', paddingBottom: '8px' }} activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <GridContainer justify="center" spacing={2} style={{ height: '400px' }}>
                {getStepContent(activeStep)}
              </GridContainer>
            </CardContent>
            <PortletActions>
              {activeStep === steps.length ? (
                <div>
                  <Button variant="contained" color="primary" onClick={handleReset}>
                    {i18(labelResetStep)}
                  </Button>
                </div>
              ) : (
                <div className={classes.div}>
                  <div className={classes.inDiv}>
                    <Button disabled={(activeStep === 0 && !returnFirstStep) || activeStep === steps.length} onClick={handleBack} color={'cancel'}>
                      {i18('BACK')}
                    </Button>
                  </div>
                  <div className={classes.inDiv}>
                    <Button variant="contained" disabled={isLoading || activeStep === steps.length || !validators[activeStep]} color="primary" onClick={handleNext}>
                      {activeStep === steps.length - 1 ? i18(labelFinalStep) : i18('Next')}
                    </Button>
                    {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </div>
                  {buttonsInStep.length
                    ? buttonsInStep.map((item, index) => {
                        return (
                          <div key={index} className={classes.inDiv}>
                            <Button onClick={item.onClick} color={'danger'}>
                              {i18(item.label)}
                            </Button>
                          </div>
                        );
                      })
                    : null}
                </div>
              )}
            </PortletActions>
          </Portlet>
        </GridItem>
      </GridContainer>
      <CustomizedSnackbars open={openSnack.open} setOpen={setOpenSnack} title={openSnack.title} severity={openSnack.severity}></CustomizedSnackbars>
    </div>
  );
};

SuperStepper.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  validators: PropTypes.array.isRequired,
  steps: PropTypes.array,
  actionButtons: PropTypes.array,
  getStepContent: PropTypes.func,
  labelFinalStep: PropTypes.string,
  labelResetStep: PropTypes.string,
  returnFirstStep: PropTypes.string,
  stepperSize: PropTypes.string,
  onSubmit: PropTypes.func,
  onResetSubmit: PropTypes.func,
};
SuperStepper.defaultProps = {
  labelFinalStep: 'Finish',
  labelResetStep: 'Reset',
};
export default SuperStepper;
