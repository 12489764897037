import { makeStyles } from '@material-ui/core/styles';

export const useModalWindowStyle = makeStyles((theme) => ({
  root: {},
  content: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(0),
    // height: theme.spacing(80),
  },
  div: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  formStyle: { width: '100%' },
  inDiv: {
    padding: theme.spacing(2),
    position: 'relative',
  },
}));
