//1 - 4, 8, 24

export const mfisSubMenu = [
  {
    parent: 'M',
    id: '1',
    level: 2,
    path: 'control',
    name: 'JourneyControl',
    label: 'H',
    icon: 'SettingsOutlined',
    color: '',
    hasCollapsed: true,
    status: 'normal',
    isVisible: true,
    subMenus: [
      {
        status: 'normal',
        id: '1.1',
        level: 3,
        path: '/mfis/control/management/all',
        name: 'Management',
        label: 'H',
        icon: 'ViewQuiltOutlinedIcon',
        prev: '1',
        next: '1.2',
        isVisible: true,
      },
      {
        status: 'normal',
        id: '1.2',
        level: 3,
        path: '/mfis/control/selecttemplate',
        name: 'Add',
        label: 'H',
        icon: 'AddToPhotosOutlinedIcon',
        prev: '1.1',
        next: '1.3',
        isVisible: true,
      },
      {
        status: 'normal',
        id: '1.3',
        level: 3,
        path: '/mfis/control/status',
        name: 'Status',
        label: 'H',
        icon: 'LinkOutlinedIcon',
        prev: '1.2',
        next: '1.4',
        isVisible: true,
      },
      {
        status: 'blind',
        id: '1.4',
        level: 3,
        path: '#',
        name: '',
        label: 'H',
        icon: undefined,
        isVisible: true,
      },
    ],
    selected: false,
  },
  {
    parent: 'M',
    id: '2',
    level: 2,
    path: 'macro',
    name: 'sequencymacro',
    label: 'H',
    icon: 'AccountTreeOutlinedIcon',
    color: '',
    hasCollapsed: true,
    status: 'normal',
    isVisible: false,
    subMenus: [
      {
        status: 'normal',
        id: '2.1',
        level: 3,
        path: '/mfis/macro/management',
        name: 'Management',
        label: 'H',
        icon: 'ViewQuiltOutlinedIcon',
        prev: '2',
        next: '2.2',
      },
      {
        status: 'normal',
        id: '2.2',
        level: 3,
        path: '/mfis/macro/add',
        name: 'New',
        label: 'H',
        icon: 'AddToPhotosOutlinedIcon',
        prev: '2.1',
        next: '2.3',
      },
      {
        status: 'normal',
        id: '2.3',
        level: 3,
        path: '/mfis/macro/link',
        name: 'Link',
        label: 'H',
        icon: 'LinkOutlinedIcon',
        prev: '2.2',
        next: '2.4',
      },
      {
        status: 'blind',
        id: '2.4',
        level: 3,
        path: '#',
        name: '',
        label: 'H',
        icon: undefined,
      },
    ],
    selected: false,
  },
  {
    parent: 'M',
    id: '3',
    level: 2,
    path: 'rotograma',
    name: 'SPOKENREGIONS',
    label: 'H',
    icon: 'ForumOutlinedIcon',
    color: '',
    hasCollapsed: false,
    status: 'normal',
    isVisible: true,
    subMenus: [
      {
        status: 'normal',
        id: '3.1',
        level: 3,
        path: '/mfis/rotograma/management/all',
        name: 'Management',
        label: 'H',
        icon: 'ViewQuiltOutlinedIcon',
        prev: '2',
        next: '3.2',
        isVisible: true,
      },
      {
        status: 'normal',
        id: '3.2',
        level: 3,
        path: '/mfis/rotograma/add',
        name: 'Add',
        label: 'H',
        icon: 'AddToPhotosOutlinedIcon',
        prev: '3.1',
        next: '3.4',
        isVisible: true,
      },
      {
        status: 'normal',
        id: '3.3',
        level: 3,
        path: '/mfis/rotograma/link',
        name: 'Link',
        label: 'H',
        icon: 'LinkOutlinedIcon',
        prev: '3.2',
        next: '3.4',
        isVisible: false,
      },
      {
        status: 'normal',
        id: '3.4',
        level: 3,
        path: '/mfis/rotograma/status/all',
        name: 'Status',
        label: 'H',
        icon: 'LinkOutlinedIcon',
        prev: '3.2',
        next: '3.5',
        isVisible: true,
      },
      {
        status: 'normal',
        id: '3.5',
        level: 3,
        path: '/mfis/rotograma/archive/all',
        name: 'Archive',
        label: 'H',
        icon: 'LinkOutlinedIcon',
        prev: '3.4',
        next: '3.6',
        isVisible: true,
      },
      {
        status: 'blind',
        id: '3.6',
        level: 3,
        path: '#',
        name: '',
        label: 'H',
        icon: undefined,
        isVisible: true,
      },
    ],
    selected: false,
  },
  {
    parent: 'M',
    id: '8',
    level: 2,
    path: 'events',
    name: 'EVE_TELEMETRIA',
    label: 'H',
    icon: 'TuneOutlinedIcon',
    color: '',
    hasCollapsed: false,
    isVisible: true,
    subMenus: [
      {
        status: 'normal',
        level: 3,
        path: '/mfis/events/management-configurations',
        name: 'Management',
        label: 'H',
        icon: 'ViewQuiltOutlinedIcon',
        prev: '8',
        id: '8.1',
        next: '8.2',
        isVisible: true,
      },
      {
        status: 'normal',
        level: 3,
        path: '/mfis/events/configuration',
        name: 'Configuration',
        label: 'H',
        icon: 'AddToPhotosOutlinedIcon',
        prev: '8.1',
        id: '8.2',
        next: '8.3',
        isVisible: true,
      },
      {
        status: 'normal',
        level: 3,
        path: '/mfis/events/management-library',
        name: 'Library',
        label: 'H',
        icon: 'AddToPhotosOutlinedIcon',
        prev: '8.2',
        id: '8.3',
        next: '8.4',
        isVisible: true,
      },
      {
        status: 'normal',
        level: 3,
        path: '/mfis/events/status/all',
        name: 'Status',
        label: 'H',
        icon: 'LinkOutlinedIcon',
        prev: '8.3',
        id: '8.4',
        next: '8.5',
        isVisible: true,
      },
      {
        status: 'blind',
        id: '8.5',
        level: 3,
        path: '#',
        name: '',
        label: 'H',
        icon: undefined,
        isVisible: true,
      },
    ],
    selected: false,
    status: 'normal',
  },
  {
    parent: 'M',
    id: '24',
    level: 2,
    path: 'reconcile',
    name: 'RECONCILE',
    label: 'E',
    icon: 'TuneOutlinedIcon',
    color: '',
    hasCollapsed: false,
    isVisible: true,
    subMenus: [
      {
        status: 'normal',
        level: 3,
        path: '/mfis/reconcile/management',
        name: 'Management',
        label: 'H',
        icon: 'ViewQuiltOutlinedIcon',
        prev: '24',
        id: '24.1',
        next: '24.2',
        isVisible: true,
      },
      {
        status: 'normal',
        level: 3,
        path: '/mfis/reconcile/configuration',
        name: 'Configuration',
        label: 'H',
        icon: 'AddToPhotosOutlinedIcon',
        prev: '24.1',
        id: '24.2',
        next: '24.3',
        isVisible: false,
      },
      {
        status: 'normal',
        level: 3,
        path: '/mfis/reconcile/management-library',
        name: 'Library',
        label: 'H',
        icon: 'AddToPhotosOutlinedIcon',
        prev: '24.2',
        id: '24.3',
        next: '24.4',
        isVisible: false,
      },
      {
        status: 'normal',
        level: 3,
        path: '/mfis/reconcile/status/all',
        name: 'Status',
        label: 'H',
        icon: 'LinkOutlinedIcon',
        prev: '24.3',
        id: '24.4',
        next: '24.5',
        isVisible: true,
      },
      {
        status: 'blind',
        id: '24.5',
        level: 3,
        path: '#',
        name: '',
        label: 'H',
        icon: undefined,
        isVisible: true,
      },
    ],
    selected: false,
    status: 'normal',
  },
  {
    parent: 'M',
    id: '4',
    level: 2,
    path: 'calendar',
    name: 'Calendar',
    label: 'H',
    icon: 'DateRangeOutlinedIcon',
    color: '',
    hasCollapsed: false,
    isVisible: false,
    subMenus: [
      {
        status: 'normal',
        id: '4.1',
        level: 3,
        path: '/mfis/calendar/management',
        name: 'Management',
        label: 'H',
        icon: 'ViewQuiltOutlinedIcon',
        prev: '2',
        next: '4.2',
      },
      {
        status: 'normal',
        id: '4.2',
        level: 3,
        path: '/mfis/calendar/add',
        name: 'New',
        label: 'H',
        icon: 'AddToPhotosOutlinedIcon',
        prev: '4.1',
        next: '4.3',
      },
      {
        status: 'normal',
        id: '4.3',
        level: 3,
        path: '/mfis/calendar/link',
        name: 'Link',
        label: 'H',
        icon: 'LinkOutlinedIcon',
        prev: '4.2',
        next: '4.4',
      },
      {
        status: 'blind',
        id: '4.4',
        level: 3,
        path: '#',
        name: '',
        label: 'H',
        icon: undefined,
      },
    ],
    selected: false,
    status: 'normal',
  },
];
