import axios from 'axios';

const ASSETS = '/assets';

// assets
export function postAssets(body) {
  return axios.post(ASSETS, body);
}
export function getAssets() {
  return axios.post(ASSETS, {});
}
