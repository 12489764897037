import { patterHeaderStyle, patternCellStyle } from '../../table/stylesReport';

export const columnsNfc = [
  {
    title: 'NFC',
    field: 'NFC',
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'NAME_CLIENT',
    field: 'clientName',
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'CREATE_FOR',
    field: 'userName',
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'STATUS',
    field: 'nfcStatus',
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'DRIVER_ID',
    field: 'driverId',
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'NAME',
    field: 'driverName',
    filtering: false,
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: { ...patterHeaderStyle },
  },
  {
    title: 'LASTNAME',
    field: 'lastname',
    filtering: false,
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'LAST_USED_AT',
    field: 'lastUsed_at',
    filtering: false,
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
];
