import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableSuperHeader from './TableSuperHeader';
import { useTableContext } from './TableContext';
import { warningColor, primaryColor, dangerColor, successColor, infoColor, roseColor, grayColor, defaultFont, ROW_BACK_ODD, CHECK_CELL_COLOR } from '../styles/constValues';
import TableSuperPagination from './TableSuperPagination';

import { StyleTableBody } from './TableBody';
//!Table Features
//V1: Sort By Column; Group rows (Expand/Collapse) DONE
//V1: Filter by Columns; Order Columns; Configure Columns: Name, justify, column's order, width
//V1: Multiple icons in column
//V1: Row background in alarm event
//V1: Actions
//V2: Grouping by column header; Apply multiple sorts
//V2: Row background in multiples events (optional)
//V2: Copy/paste
//V2: Export options

//todo: Refactor getComparator
//todo: move usestle to separated file. Remov unnecesary fields
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(4),
  },
  paper: {
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
    marginBottom: '0',
    maxWidth: '100%',
    backgroundColor: 'transparent',
    borderSpacing: '0',
  },
  tableContainer: {
    maxHeight: 250,
  },
  warningTableHeader: {
    color: warningColor[0],
  },
  primaryTableHeader: {
    color: primaryColor[0],
  },
  dangerTableHeader: {
    color: dangerColor[0],
  },
  successTableHeader: {
    color: successColor[0],
  },
  infoTableHeader: {
    color: infoColor[0],
  },
  roseTableHeader: {
    color: roseColor[0],
  },
  grayTableHeader: {
    color: grayColor[0],
  },

  tableHeadCell: {
    color: 'inherit',
    // ...defaultFont,
    '&, &$tableCell': {
      fontSize: '1em',
    },
  },
  tableCellHeader: {
    // ...defaultFont,
    height: theme.spacing(5),
    borderColor: 'transparent',
    letterSpacing: '0.36px',
    // lineHeight: '1.42857143',
    // padding: '12px 8px',
    verticalAlign: 'middle',
    fontSize: '0.8125rem',
  },
  tableCell: {
    // ...defaultFont,
    padding: 0,
    paddingLeft: theme.spacing(1),
    height: theme.spacing(5),
    borderColor: 'transparent',
    letterSpacing: '0.36px',
    // lineHeight: '1.42857143',
    // padding: '12px 8px',
    verticalAlign: 'middle',
    fontSize: '0.8125rem',
  },
  tableResponsive: {
    width: '100%',
    // marginTop: theme.spacing(3),
    // overflowX: 'auto',
  },
  tableHeadRow: {
    height: '56px',
    color: 'inherit',
    display: 'table-row',
    outline: 'none',
    verticalAlign: 'middle',
  },
  tableBodyRow: {
    height: theme.spacing(5),
    color: 'inherit',
    display: 'table-row',
    outline: 'none',
    verticalAlign: 'middle',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableCheckCell: {
    // backgroundColor: CHECK_CELL_COLOR,
    height: '24px',
    color: CHECK_CELL_COLOR,
  },
  tableGroupCell: {
    height: theme.spacing(5),
    borderColor: 'transparent',
    letterSpacing: '0.36px',
    // lineHeight: '1.42857143',
    // padding: '12px 8px',
    verticalAlign: 'middle',
    fontSize: '0.8125rem',
    width: '30px',
  },
  tableEmptyGroupCell: {
    height: theme.spacing(5),
    borderColor: 'transparent',
    letterSpacing: '0.36px',
    // lineHeight: '1.42857143',
    // padding: '12px 8px',
    verticalAlign: 'middle',
    fontSize: '0.8125rem',
    width: '46px',
  },
  tableGroupCellLeftPadding0: {
    paddingLeft: '0px',
  },
  tableGroupCellLeftPadding1: {
    paddingLeft: '16px',
  },
  groupCell: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: theme.spacing(40),
    // flexDirection: 'column',
  },
  groupCellLevel0: {
    justifyContent: 'flex-start',
  },
  groupCellLevel1: {
    justifyContent: 'center',
    marginLeft: theme.spacing(2),
    // paddingLeft: theme.spacing(2),
  },
  groupCellLevel2: {
    justifyContent: 'flex-end',
    marginLeft: theme.spacing(3),
    // paddingLeft: theme.spacing(2),
  },
  groupCell1: {
    width: theme.spacing(4),
  },
  groupCell2: {
    width: theme.spacing(4),
    // alignItems: 'center',
  },
  groupCell3: {
    width: theme.spacing(30),
  },
}));

export default function EnhancedTable(props) {
  const { classes, hasGroups, hasCheckBox, maxHeight, handleClickOnRow } = props;
  const {
    state: { datos, sort },
    columns,
    withPagination,
    pager,
    expand,
    checkItem,
    getEmptyRows,
    actions,
    setCheckedAssets,
  } = useTableContext();
  const contextProps = { expand, checkItem, getEmptyRows, actions, setCheckedAssets };
  const [selected, setSelected] = React.useState([]);
  const [dense, setDense] = React.useState(false);

  //#region handles

  // const handleSelectAllClick = (event) => {
  //   if (event.target.checked) {
  //     const newSelecteds = datos.map((n, index) => index);
  //     setSelected(newSelecteds);
  //     return;
  //   }
  //   setSelected([]);
  // };

  // const handleChangePage = (event, newPage) => {
  //   pager(newPage);
  // };

  // const handleChangeDense = (event) => {
  //   setDense(event.target.checked);
  // };

  //#endregion

  return (
    <>
      <TableContainer className={classes.tableContainer} style={maxHeight && { maxHeight: maxHeight }}>
        <Table stickyHeader className={classes.table} aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'} aria-label="enhanced table">
          <TableSuperHeader hasGroups={hasGroups} hasCheckBox={hasCheckBox} headCells={columns} tableHeaderColor="primary" numSelected={selected.length} order={sort.order} orderBy={sort.orderBy} rowCount={datos.length} />
          <StyleTableBody handleParentClickOnRow={handleClickOnRow} data={datos} hasCheckBox={hasCheckBox} classes={classes} {...contextProps} />
        </Table>
      </TableContainer>
      {/* //todo: falta hanlde pchang page */}
      {withPagination ? <TableSuperPagination /> : null}
    </>
  );
}

EnhancedTable.propTypes = {
  columns: PropTypes.array.isRequired,
  rootLevel: PropTypes.array,
  firstLevel: PropTypes.object,
  secondLevel: PropTypes.object,
  hasCheckBox: PropTypes.bool,
  hasGroups: PropTypes.bool,
  rowPerPage: PropTypes.number,
  handleClickOnRow: PropTypes.func,
};
EnhancedTable.defaultProps = {
  columns: [],
  rootLevel: [],
  firstLevel: {},
  secondLevel: {},
  hasCheckBox: false,
  hasGroups: false,
  rowPerPage: 10,
  handleClickOnRow: null,

  // seleccionado:false
};
