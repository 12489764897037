import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CardContent, Typography } from '@material-ui/core';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';

import { useIntl } from '../../../lang/context/intlContext';

import { Loading } from '../../../../Splash';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import { TableProvider } from '../../../components/Table/TableContext';
import TablePaper from '../../../components/Table/TablePaper';
import PortletIcon from '../../../components/Portlet/PortletIcon';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { ActionEdit, ActionNotification, ActionPDF, ActionRemove, ActionViewVideo } from '../../../../assets/icons';
import { getReconciles } from './services/getMethods';
import { ReconcilePDF } from './ReconcilePDF';
import { PDFViewer } from '@react-pdf/renderer';

import Filter from './Filter';
import ModalPortlet from '../../../components/ModalPortlet/ModalPortlet';
import ReactPlayer from 'react-player';
import { getReconcile } from './api/getMethods';
import InputText from '../../../components/InputText/InputText';
import ListEmail from './ListEmail';
import { FormNameIcon } from '../../../../assets/icons/formIcons';
import { postSendReconcileNotification } from './api/postMethods';
import { getBaseURL, getHeadCellsEvidence } from './helper';
import { getAllTableEvidences } from '../../administration/api/getMethods';
import ContentLoader from 'react-content-loader';
import ContentLineLoader from './ContentLineLoader';

const useStyleGrid = makeStyles((theme) => ({
  proto: { maxHeight: 'calc(100vh-400px)' },
  content: {
    display: 'flex',
    justifyContent: 'center',
    height: theme.spacing(45),
    // height: 'calc(100vh-800px)',
  },
  alert: { margin: theme.spacing(2) },
  div: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  formStyle: { width: '100%' },
  inDiv: {
    padding: theme.spacing(2),
    position: 'relative',
  },
}));

const validateEmail = (email) => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
};

const Management = (props) => {
  const { history } = props;
  const reload = new URLSearchParams(props.location.search).get('reload') || false;
  const { i18 } = useIntl();
  const classes = useStyleGrid();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingEvidences, setIsLoadingEvidences] = useState(true);
  const [tableReconciles, setTableReconciles] = useState([]);
  const [openView, setOpenView] = useState({ isOpen: false, urlVideoFile: null, name: null, driverName: null, asset: null });
  const [openPDF, setOpenPDF] = useState({ isOpen: false, name: null, asset: null, driverName: null, reconcile: undefined });
  const [openNotifier, setOpenNotifier] = useState({ isOpen: false });
  const [openEvidences, setOpenEvidences] = useState({ isOpen: false });
  const [evidences, setEvidences] = useState([]);
  const [emailList, setEmailList] = useState({ emails: [], errors: { email: undefined } });
  const [selectedEvidence, setSelectedEvidence] = useState(null);
  const addReconciles = useStoreActions((actions) => actions.evidences.addReconciles);
  const clearReconciles = useStoreActions((actions) => actions.evidences.clearReconciles);
  const loadToGridMap = useStoreActions((actions) => actions.evidences.loadToGridMap);

  const reconciles = useStoreState((state) => state.evidences.reconciles);
  const baseURL = getBaseURL(window);

  const headCellEvidences = getHeadCellsEvidence(i18);

  const fetchReconciles = async (params) => {
    try {
      const reconcilesData = await getReconciles({ params });
      addReconciles(reconcilesData);
    } catch {
      console.info('error');
    }
  };
  const fetchEvidences = async (params) => {
    try {
      const evidencesData = await getAllTableEvidences(params);
      loadToGridMap(evidencesData.data.evidences);
      setEvidences(evidencesData.data.evidences);
    } catch {
      console.info('error');
    }
    setIsLoadingEvidences(false);
  };

  const onTableReady = () => {};
  const headCellsTablets = [
    { id: 'reconcileId', numeric: true, align: 'left', disablePadding: false, label: i18('ID'), width: '30px', defaultOrder: true, order: 'desc' },
    { id: 'name', numeric: true, align: 'left', disablePadding: false, label: i18('evidence'), width: '110px' },
    { id: 'levelName', numeric: false, align: 'left', disablePadding: false, label: i18('level'), width: '40px' },
    { id: 'userName', numeric: false, align: 'left', disablePadding: false, label: i18('user'), width: '100px' },
    { id: 'created_at', numeric: false, align: 'left', disablePadding: false, label: i18('datetime'), width: '80px' },
    { id: 'asset', numeric: true, align: 'left', disablePadding: false, label: i18('asset'), width: '100px' },
    { id: 'driverName', numeric: false, align: 'left', disablePadding: false, label: i18('driver'), width: '50px' },
    { id: 'statusName', numeric: false, align: 'left', disablePadding: false, label: i18('status'), width: '40px' },
    { id: 'actions', numeric: true, align: 'left', disablePadding: false, label: i18('actions'), width: '80px' },
  ];
  const filterInputs = [];
  const ActionsRender = useCallback((keyCellId) => {
    return (
      <>
        <ActionEdit title={i18('EDIT')} onClick={() => history.push(`/mfis/evidence/reconcile/${keyCellId.split('|')[1]}/edit`)} />
        <ActionPDF title={'PDF'} onClick={(e) => onOpenPDF(e, keyCellId.split('|')[1])} />
        <ActionViewVideo title={i18('VIEW_VIDEO')} onClick={(e) => onOpenVideo(e, keyCellId.split('|')[1])} />
        <ActionNotification title={i18('SEND_NOTIFICATION')} onClick={(e) => onOpenNotifier(e, keyCellId.split('|')[1])} />
      </>
    );
  }, []);

  const onSubmit = async (values) => {
    setIsLoading(true);
    const { evidenceType, dvrs, startDateTime, endDateTime, evidenceLevel, drivers, evidenceStatus } = values;
    const type = evidenceType.map((item) => +item.value);
    const level = evidenceLevel.map((item) => +item.value);
    const status = evidenceStatus.map((item) => +item.value);
    const driver = drivers.map((item) => +item.driverId);

    const filterParams = {
      type,
      limit: 500,
      dvrs: dvrs.map((item) => item.deviceId),
      startDateTime,
      endDateTime,
      level,
      status,
      driver,
    };
    await fetchReconciles(filterParams);
    setIsLoading(false);
  };
  const handleClickOnRow = () => {};

  const handleClickOnEvidenceRow = (id) => {
    const evidence = evidences.find((item) => +item.trackingEvidenceId === +id);
    setSelectedEvidence(evidence);
  };
  const handelCancelEvidence = () => {
    setSelectedEvidence(null);
    setOpenEvidences({ isOpen: false });
  };

  const handleOnKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (e.target.value.length === 0) return;
      const email = e.target.value;
      if (!validateEmail(email)) {
        setEmailList((prev) => ({ ...prev, errors: { email: 'Email inválido' } }));
        return;
      }
      setEmailList((prev) => ({ ...prev, errors: { email: undefined }, emails: [...prev.emails, { title: email }] }));
      e.target.value = '';
    }
  };
  const handleSendNotification = async () => {
    try {
      const { emails, errors } = emailList;
      if (errors.email) return;
      const { reconcileId } = openNotifier;
      if (reconcileId === 0) return;
      const body = { reconcileId, emails, baseURL };
      await postSendReconcileNotification(body);
      setOpenNotifier({ reconcileId: 0, isOpen: false });
      setEmailList({ emails: [], errors: { email: undefined } });
    } catch (e) {}
    setOpenNotifier({ reconcileId: 0, isOpen: false });
  };
  const handleCancelNotification = () => {
    setOpenNotifier({ reconcileId: 0, isOpen: false });
    setEmailList({ emails: [], errors: { email: undefined } });
  };
  const handleCreateReconcile = () => {
    history.push(`/mfis/evidence/reconcile/${selectedEvidence.trackingEvidenceId}`);
  };

  const handleNewReconcile = async (e) => {
    e.preventDefault();
    try {
      setIsLoadingEvidences(true);
      setOpenEvidences({ isOpen: true });
      setSelectedEvidence(null);
      await fetchEvidences({ limit: 500 });
    } catch (e) {}
  };

  const onClickDeleteEmail = (email) => {
    setEmailList((prev) => ({
      ...prev,
      emails: prev.emails.filter((item) => item.title !== email),
    }));
  };
  const onOpenVideo = async (e, reconcileId) => {
    e.preventDefault();
    const res = await getReconcile(reconcileId);
    const reconcile = { ...res.data };
    const { urlVideoFile, evidence: name, asset, driverName } = reconcile;
    setOpenView((prev) => ({ ...prev, isOpen: true, urlVideoFile, name, asset, driverName }));
  };
  const onOpenPDF = async (e, reconcileId) => {
    e.preventDefault();
    const currentDate = new Date().toISOString().split('T')[0];
    const res = await getReconcile(reconcileId);
    const reconcile = { ...res.data, currentDate };
    const { evidence: name, driverName, asset } = reconcile;

    setOpenPDF((prev) => ({ ...prev, isOpen: true, name, asset, driverName, reconcile }));
  };
  const onOpenNotifier = async (e, reconcileId) => {
    e.preventDefault();
    setEmailList({ emails: [], errors: { email: undefined } });
    setOpenNotifier((prev) => ({ ...prev, isOpen: true, reconcileId }));
  };

  useEffect(() => {
    // if (reload) {
    //   clearReconciles();
    //   window.history.replaceState({}, '?reload=true', '');
    // }
    return () => {};
  }, []);

  return (
    <div>
      <GridContainer className={classes.content}>
        <GridItem item xs={12} md={12} lg={12}>
          <PortletIcon hasTitle={true} title={i18('reconciles')} subTitle={i18('LIST_OF_RECONCILES')} withIcon={false} hasCustomStartIcon={false} StartIcon={PermIdentityIcon} withButton buttonTitle={i18('NEW_RECONCILE')} onClickButton={handleNewReconcile}>
            <CardContent style={{ paddingTop: '8px' }}>
              <GridContainer direction="column" spacing={2}>
                <GridItem item xs={12} md={12}>
                  <Filter onSubmit={onSubmit}></Filter>{' '}
                </GridItem>

                <GridItem item xs={12} md={12}>
                  {isLoading ? (
                    <GridContainer className={classes.content}>
                      <GridItem item xs={12} md={12} lg={12}>
                        <Loading></Loading>
                      </GridItem>
                    </GridContainer>
                  ) : (
                    <TableProvider loadData={reload ? [] : reconciles} columns={headCellsTablets} checkedItems={[]} initRowsPerPage={50} withPagination={true} onContextReady={onTableReady} useV2={true} actions={ActionsRender}>
                      <TablePaper handleClickOnRow={handleClickOnRow} noFilter={true} maxHeight={'calc(100vh-300px)'} filterInputs={filterInputs} hasCheckBox={false} hasGroups={false} hasSearchAllFields={false}></TablePaper>
                    </TableProvider>
                  )}
                </GridItem>
              </GridContainer>
            </CardContent>
            <></>
          </PortletIcon>
        </GridItem>
      </GridContainer>
      <ModalPortlet title={openView.name + ' - ' + openView.asset} subTitle={openView.driverName} i18={i18} open={openView.isOpen} handleOk={() => setOpenView((prev) => ({ ...prev, isOpen: false }))} onlyOkName={i18('Close')}>
        <GridItem justify="center" container>
          <GridItem item xs={12} md={12} lg={12}>
            <GridContainer spacing={0}>
              <GridItem item xs={11} md={11} lg={11}>
                <ReactPlayer
                  url={openView.urlVideoFile} // local
                  width="600px"
                  height="400px"
                  controls
                  volumen={0.8}
                  className="react-player"
                />
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridItem>
      </ModalPortlet>
      <ModalPortlet fullWidth={true} maxWidth="lg" title={openPDF.name + ' - ' + openPDF.asset} subTitle={openPDF.driverName} i18={i18} open={openPDF.isOpen} onlyOkName={i18('Close')} handleOk={() => setOpenPDF((prev) => ({ ...prev, isOpen: false }))}>
        <GridItem justify="center" container>
          <GridItem item xs={12} md={12} lg={12}>
            <GridContainer spacing={0}>
              <GridItem item xs={12} md={12} lg={12}>
                <PDFViewer style={{ width: '100%', height: '60vh' }}>
                  <ReconcilePDF reconcile={openPDF.reconcile} />
                </PDFViewer>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridItem>
      </ModalPortlet>
      <ModalPortlet
        title={i18('SEND_NOTIFICATION')}
        subTitle={i18('INSERT_EMAILS')}
        open={openNotifier.isOpen}
        i18={i18}
        fullWidth={true}
        maxWidth="sm"
        typeButtons="CancelSave"
        onlySaveName={i18('SEND')}
        disabledSaveButton={emailList.emails.length === 0}
        onlyOkName={i18('Close')}
        handleCancel={handleCancelNotification}
        handleSave={handleSendNotification}
      >
        <GridItem justify="center" container spacing={2}>
          <GridItem item xs={11} md={11} lg={11}>
            <InputText id="email" smallInput placeholder={i18('INSIRA_EMAIL_NOTIF')} onKeyPress={handleOnKeyPress} name="email" icon={FormNameIcon}></InputText>
            {emailList.errors.email && <Typography color="secondary">{i18('INVALID_EMAIL')}</Typography>}
          </GridItem>
          <GridItem item xs={11} md={11} lg={11}>
            <ListEmail items={emailList.emails} hasDelete={true} onClickDelete={onClickDeleteEmail}></ListEmail>
          </GridItem>
        </GridItem>
      </ModalPortlet>
      <ModalPortlet
        title={i18('EVIDENCES')}
        subTitle={i18('EVIDENCES_SELECTION')}
        open={openEvidences.isOpen}
        i18={i18}
        fullWidth={true}
        maxWidth="lg"
        typeButtons="CancelSave"
        onlySaveName={i18('create')}
        disabledSaveButton={!selectedEvidence}
        onlyOkName={i18('Close')}
        handleCancel={handelCancelEvidence}
        handleSave={handleCreateReconcile}
      >
        <GridItem justify="center" container spacing={2}>
          {isLoadingEvidences ? (
            <ContentLineLoader lineNumber={12} height={350} />
          ) : (
            <TableProvider loadData={evidences} columns={headCellEvidences} withPagination={false} title="" checkedItems={[]} initRowsPerPage={10} onContextReady={onTableReady} useV2={true}>
              <TablePaper maxHeight={'350px'} handleClickOnRow={handleClickOnEvidenceRow} filterInputs={[]} hasCheckBox={false} hasGroups={true} hasSearchAllFields={true} noFilter></TablePaper>
            </TableProvider>
          )}
        </GridItem>
      </ModalPortlet>
    </div>
  );
};

export default Management;
