//@ts-nocheck
import { action } from 'easy-peasy';

const initValue = { regions: [], types: [] };

const region = {
  ...initValue,

  addRegions: action((region, data) => (region.regions = data)),
  clearRegions: action((map) => (map.regions = [])),
  addTypes: action((region, data) => (region.types = data)),
};

export default region;
