import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import { makeStyles } from '@material-ui/core/styles';
import { CardContent, Typography } from '@material-ui/core';
import TabletMacIcon from '@material-ui/icons/TabletMac';
import PortletIcon from '../../../components/Portlet/PortletIcon';
import PortletActions from '../../../components/Portlet/PorletActions';

import { useForm } from 'react-hook-form';
import { useIntl } from '../../../lang/context/intlContext';

import { postPublish } from './api/postMethods';
import { TableProvider } from '../../../components/Table/TableContext';
import TablePaper from '../../../components/Table/TablePaper';
import { getOptionGroups } from '../../../components/Table/helper';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { featureGroup } from 'leaflet';
import { getAllTablets, getGridTablets } from '../../administration/api/getMethods';
import { Loading } from '../../../../Splash';

const useStyleGrid = makeStyles((theme) => ({
  content: {
    display: 'flex',
    justifyContent: 'center',
    height: theme.spacing(45),
  },
  alert: { margin: theme.spacing(2) },
}));

function Publish({ history, match, location }) {
  const { i18 } = useIntl();
  const { setPrimaryButton, setCurrentRegions, setNoButtons } = useStoreActions((actions) => {
    const { rotograma, globalState } = actions;
    return { ...rotograma, ...globalState };
  });
  const [isLoading, setisLoading] = useState(true);

  const [tableApi, setTableApi] = useState(null);
  const { register, handleSubmit, errors } = useForm();
  const classes = useStyleGrid();
  const { name, toPublish } = useStoreState((state) => state.rotograma);
  // const { assets } = useStoreState((state) => ({ assets: state.map.assets }));
  const [tablets, setTablets] = useState([]);

  //#region Effects
  const execPublish = async () => {
    const { checkedRow } = tableApi.getCheckedRowsWithFieldEval();
    if (!checkedRow.length) {
      alert('No tablets selected');
      return;
    }
    const tablets = checkedRow.map((item) => ({ deviceId: item.keyId, serial: item.device }));
    const body = { tablets, toPublish };
    console.info(body);
    await postPublish(body);
    history.push(`/mfis/rotograma/status/all`);
  };
  const fetchData = async () => {
    try {
      const resTablets = await getGridTablets();
      setTablets(resTablets.data.data);
    } catch (error) {
      console.error(error);
    }
    setisLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    setNoButtons();
    setPrimaryButton({
      text: i18('PUBLISH'),
      isEnabled: true,
      fnc: () => execPublish(),
      disabled: false,
    });
  }, [tableApi]); //tener como dependencia tableApi comsume el doble de tiempo

  //#endregion

  //#region Error and Redirect

  //#endregion
  //#region TableDefinitions
  const headCellsTablets = [
    {
      id: 'rootField',
      numeric: false,
      align: 'left',
      disablePadding: true,
      label: i18('GROUP/SUBGROUP/TABLET'),
      width: '250px',
      defaultOrder: true,
    },
    { id: 'plates', numeric: true, align: 'left', disablePadding: false, label: i18('Plate'), width: '150px' },
    { id: 'vehicleModel', numeric: true, align: 'left', disablePadding: false, label: i18('vehicleModel'), width: '150px' },
    { id: 'device', numeric: true, align: 'left', disablePadding: false, label: i18('device'), width: '100px' },
    { id: 'deviceModel', numeric: true, align: 'left', disablePadding: false, label: i18('deviceModel'), width: '100px' },
    { id: 'client', numeric: true, align: 'left', disablePadding: false, label: i18('Client'), width: 'auto' },
  ];

  if (isLoading)
    return (
      <GridContainer className={classes.content}>
        <GridItem item xs={12} md={12} lg={12}>
          <Loading></Loading>
        </GridItem>{' '}
      </GridContainer>
    );
  const optionsGroup = getOptionGroups(tablets, true);

  const filterInputs = [{ label: 'name', field: 'rootField' }];

  function onTableReady(params) {
    const { api } = params;
    setTableApi(api);
  }

  //#endregion
  const onSubmit = () => {
    console.log('onsubmit');
  };
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <GridContainer className={classes.content}>
          <GridItem item xs={12} md={12} lg={12}>
            <PortletIcon title={i18('SELECT_TABLETS')} subTitle={i18('SELECT_TABLETS_SUBHEADER') + name} hasCustomStartIcon={true} StartIcon={TabletMacIcon}>
              <CardContent>
                <GridContainer direction="column" spacing={2} style={{ paddingTop: '0px' }}>
                  <GridItem item xs={12} md={12}>
                    <TableProvider
                      loadData={tablets}
                      columns={headCellsTablets}
                      withPagination={true}
                      title="Tablets"
                      checkedItems={[]}
                      initRowsPerPage={50}
                      onContextReady={onTableReady}
                      useV2={true}
                    >
                      <TablePaper filterInputs={filterInputs} hasCheckBox={true} hasGroups={true} optionsGroup={optionsGroup} hasSearchAllFields={true}></TablePaper>
                    </TableProvider>
                  </GridItem>
                </GridContainer>
              </CardContent>
              <PortletActions></PortletActions>
            </PortletIcon>
          </GridItem>
        </GridContainer>
      </form>
    </div>
  );
}

export default Publish;
