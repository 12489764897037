//#region sortFunctions

/**
 * Pure function
 * @param {array} columns
 */
export const getDefaultOrder = (columns) => {
  if (!columns.length) return undefined;
  const defaultOrder = columns.find((item) => item.defaultOrder);
  if (!defaultOrder) return columns[0].id;
  return { orderBy: defaultOrder.id, order: defaultOrder.order || 'asc' };
};
export function descendingComparator(a, b, orderBy) {
  if (a.fields) return descendingFieldComparator(a, b, orderBy);
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
export function descendingFieldComparator(a, b, orderBy) {
  if (b.fields[orderBy] < a.fields[orderBy]) {
    return -1;
  }
  if (b.fields[orderBy] > a.fields[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

export function setIsExpanded(array, groupId, state = true) {
  return array.map((item) => {
    if (item.isExpanded) {
      const subLevel = item.nextLevel.map((item2) => {
        if (item2.keyCellId === groupId) {
          item2.isExpanded = state;
        }
        return item2;
      });
      item.nextLevel = subLevel;
    }
    if (item.keyCellId === groupId) item.isExpanded = state;

    return item;
  });
}

export function setClassicIsExpanded(array, groupId, state = true) {
  for (let i = 0; i < array.length; i++) {
    if (array[i].keyCellId === groupId) {
      array[i].isExpanded = state;
      return array;
    }
  }
}

export function setIsChecked(array, groupId, state = true) {
  let isFound = false;
  return array.map((item) => {
    if (isFound) return item;
    //Caso 1: Click en Item root Nivel 0 => todos los hijos y nietos tienen que ser true
    if (item.keyCellId === groupId) {
      isFound = true;
      item.isChecked = state;
      item.nextLevel = item.nextLevel.map((item2) => {
        item2.isChecked = state;
        if (item2.hasChilds) item2.nextLevel = item2.nextLevel.map((subItem2) => ({ ...subItem2, isChecked: state }));
        return item2;
      });
    }

    //Caso 2: Click en un Item nivel 1 o 2. Debemos buscar dentro del nivel 2
    let updateParentRoot = false;
    if (item.hasChilds && item.keyCellId !== groupId) {
      item.nextLevel = item.nextLevel.map((item2) => {
        //Caso 2.1: Click en item nivel 1
        if (item2.keyCellId === groupId) {
          item2.isChecked = state;
          if (!state) updateParentRoot = true;
          if (item2.hasChilds) {
            //verificamos si tiene hijos y lo hacemos en el mismo stae
            item2.nextLevel = item2.nextLevel.map((subItem) => ({ ...subItem, isChecked: state }));
          }
        }
        //Caseo 2.2: Click en item nivel 2
        let updateParentLevel1 = false;
        if (item2.hasChilds && item2.keyCellId !== groupId) {
          item2.nextLevel = item2.nextLevel.map((item3) => {
            if (item3.keyCellId === groupId) {
              if (!state) updateParentLevel1 = true;
              item3.isChecked = state;
            }
            return item3;
          });
        }
        if (updateParentLevel1) {
          item2.isChecked = false;
          updateParentRoot = true;
        }
        return item2;
      });
    }
    if (updateParentRoot) item.isChecked = false;
    return item;
  });
}
export function setCheckAllItems(array, state = true) {
  return array.map((item) => {
    //Caso 1: Click en Item root Nivel 0 => todos los hijos y nietos tienen que ser true
    item.isChecked = state;
    item.nextLevel = item.nextLevel.map((item2) => {
      item2.isChecked = state;
      if (item2.hasChilds) item2.nextLevel = item2.nextLevel.map((subItem2) => ({ ...subItem2, isChecked: state }));
      return item2;
    });

    return item;
  });
}
export function setIsCheckedLoad(array, checkedItems, state = true) {
  if (!checkedItems.length) return array;
  return array.map((item) => {
    //Caso 1: Click en Item root Nivel 0 => todos los hijos y nietos tienen que ser true
    const exist = checkedItems.indexOf(item.keyCellId) >= 0;
    if (exist) {
      item.isChecked = state;
      item.nextLevel = item.nextLevel.map((item2) => {
        item2.isChecked = state;
        if (item2.hasChilds) item2.nextLevel = item2.nextLevel.map((subItem2) => ({ ...subItem2, isChecked: state }));
        return item2;
      });
    }

    //Caso 2: Click en un Item nivel 1 o 2. Debemos buscar dentro del nivel 2
    if (item.hasChilds && !exist) {
      item.nextLevel = item.nextLevel.map((item2) => {
        //Caso 2.1: Click en item nivel 1
        const exist1 = checkedItems.indexOf(item2.keyCellId) >= 0;
        if (exist1) {
          item2.isChecked = state;
          if (item2.hasChilds) {
            //verificamos si tiene hijos y lo hacemos en el mismo stae
            item2.nextLevel = item2.nextLevel.map((subItem) => ({ ...subItem, isChecked: state }));
          }
        }
        //Caseo 2.2: Click en item nivel 2
        if (item2.hasChilds && !exist1) {
          item2.nextLevel = item2.nextLevel.map((item3) => {
            const exist2 = checkedItems.indexOf(item3.keyCellId) >= 0;
            if (exist2) {
              item3.isChecked = state;
            }
            return item3;
          });
        }
        return item2;
      });
    }
    return item;
  });
}
export function createGridRows(array) {
  if (!array.length) return [];

  const arrayIndex = array.reduce((arr, item, index) => {
    if (item.isExpanded) {
      const subLevel = item.hasChilds ? createGridRows(item.nextLevel) : item.nextLevel;
      return [...arr, item, ...subLevel];
    }

    return [...arr, item];
  }, []);
  return arrayIndex;
}
export function stableSort(array, order, orderBy) {
  const comparator = getComparator(order, orderBy);
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  const sortedData = stabilizedThis.map((el) => {
    const item = el[0];
    if (item.nextLevel) {
      item.nextLevel = stableSort(item.nextLevel, order, orderBy);
    }
    return item;
  });

  return sortedData;
}

// /function stableSort

//#endregion

export const getSelectGroups = (row, hasChilds = true) => {
  if (!row || !row.length) return [];
  const withFilter = row.filter((item) => item.hasChilds === hasChilds && item.keyId > 0);
  if (!withFilter.length) return [];
  return withFilter.map((item) => {
    return { value: item.keyId, text: item.rootField };
  });
};
export const getOptionGroups = (row, hasChilds = true) => {
  return row
    .filter((item) => item.hasChilds === hasChilds)
    .reduce((acc, item) => {
      const { rootField } = item;
      acc.push(rootField);
      const deepOne = item.nextLevel.filter((item) => item.hasChilds === hasChilds).map((item) => item.rootField);
      return [...acc, ...deepOne];
    }, []);
};
/**
 * Get values to fill inputs filter box
 * @param {any} row
 * @param {any} field
 */
export const getOptionsField = (row, field) => {
  const getRows = row.filter((item) => {
    return item[field] !== undefined;
  });

  const toReturn = [...new Set(getAtomValues(getRows, field))];
  return toReturn;
};
const getAtomValues = (row, field) => {
  return row.reduce((acc, item) => {
    const hasNextLevel = item.nextLevel.length;
    if (hasNextLevel) {
      acc.push(...getAtomValues(item.nextLevel, field));
    } else {
      acc.push(item[field]);
    }
    return acc;
  }, []);
};
//#region FormatRegion
const formatSubGroups = (subGroups, assets) => {
  if (!subGroups.length) return [];
  return subGroups.map((item) => {
    const { subGroupId: keyId, groupId: keyParentId, subGroupName: rootField, devicesId } = item;
    // const hasChilds = item.devicesId ? true : false;
    const nextLevel = devicesId
      ? devicesId.map((deviceId) => {
          const asset = getAsset(deviceId, assets);
          return {
            ...asset,
            keyId: deviceId,
            keyParentId: item.groupId,
            rootField: asset.plates,
            hasChilds: false,
            isFinal: true,
            level: 2,
            nextLevel: [],
          };
        })
      : [];
    return {
      keyId,
      keyParentId,
      rootField,
      hasChilds: true,
      nextLevel,
      level: 1,
    };
  });
};
const formatSubRegionGroups = (subGroups, regions) => {
  if (!subGroups.length) return [];
  return subGroups.map((item) => {
    const { subGroupId: keyId, groupId: keyParentId, subGroupName: rootField, regionIds } = item;
    // const hasChilds = item.devicesId ? true : false;
    const nextLevel = regionIds
      ? regionIds.map((regionId) => {
          const asset = getAssetPar(regionId, regions);
          return {
            ...asset,
            keyId: regionId,
            keyParentId: item.subGroupId,
            rootField: asset.name,
            hasChilds: false,
            isFinal: true,
            level: 2,
            nextLevel: [],
          };
        })
      : [];
    return {
      keyId,
      keyParentId,
      rootField,
      hasChilds: true,
      nextLevel,
      level: 1,
    };
  });
};

const getAsset = (deviceId, assets) => {
  // console.info({ assets });
  const asset = assets.find((item) => parseInt(item.deviceId) === parseInt(deviceId));
  const { lnp } = asset;
  return { ...asset, ...lnp };
};
const getAssetPar = (regionId, assets) => {
  // console.info({ assets });
  const asset = assets.find((item) => parseInt(item.regionId) === parseInt(regionId));
  return { ...asset };
};
export const formatMasterDetail = (groupsData, assets) => {
  //level 0
  const groups = groupsData.map((item) => {
    const { groupId: keyId, groupName: rootField } = item;
    /* eslint-disable-next-line */
    const hasChilds = item.devicesId || item.subGroups ? true : false;
    const formattedSubGroup = formatSubGroups(item.subGroups || [], assets);

    const groupAssets = item.devicesId
      ? item.devicesId.map((deviceId) => {
          const asset = getAsset(deviceId, assets);
          return {
            ...asset,
            keyId: deviceId,
            keyParentId: item.groupId,
            rootField: asset.plates,
            hasChilds: false,
            isFinal: true,
            level: 1,
            nextLevel: [],
          };
        })
      : [];
    const nextLevel = [...formattedSubGroup, ...groupAssets];
    return {
      keyId,
      keyParentId: 0,
      rootField,
      hasChilds,
      level: 0,
      nextLevel,
    };
  });

  return groups;
};
export const formatMasterDetailRegions = (groupsData, assets) => {
  //level 0
  const groups = groupsData.map((item) => {
    const { groupId: keyId, groupName: rootField } = item;
    /* eslint-disable-next-line */
    const hasChilds = item.regionIds || item.subGroups ? true : false;
    const formattedSubGroup = formatSubRegionGroups(item.subGroups || [], assets);

    const groupAssets = item.regionIds
      ? item.regionIds.map((regionId) => {
          const asset = getAssetPar(regionId, assets);
          return {
            ...asset,
            keyId: regionId,
            keyParentId: item.groupId,
            rootField: asset.name,
            hasChilds: false,
            isFinal: true,
            level: 1,
            nextLevel: [],
          };
        })
      : [];
    const nextLevel = [...formattedSubGroup, ...groupAssets];
    return {
      keyId,
      keyParentId: 0,
      rootField,
      hasChilds,
      level: 0,
      nextLevel,
    };
  });

  return groups;
};

//pure
/**
 * Pure function format and link Columns and Data. 'Id' refers to FieldName
 * @param {array} columns
 * @param {array} data
 */
export const formatRootLevel = (columns, data) => {
  return data.map((item) => {
    const { nextLevel, fields, ...rest } = item;
    // const keyCellId = level + 'L' + keyParentId + '|' + keyId;
    const formatFields = columns.reduce((acc, item2) => {
      return { ...acc, [item2.id]: fields[item2.id] || '' }; //retorna el valor del campo or undefined
    }, {});

    // const fields = columns.reduce((acc, item2) => {
    //   const fieldValue = item[item2['id']];
    //   if (!fieldValue) return { ...acc, [item2['id']]: '' };
    //   return { ...acc, [item2['id']]: fieldValue };
    // }, {});
    // return { ...fields, keyId, rootField, hasChilds, level };
    const formatNextLevel = nextLevel.length ? formatRootLevel(columns, nextLevel) : [];
    return {
      ...rest,
      ...fields,
      fields: { ...formatFields },
      nextLevel: formatNextLevel,
    };
    // return { ...fields, address: 'Av. Rangel Pestana, 11 - Bras São Paulo - SP, 01017-000, Brasil' };
  });
};
/**
 * This functions prepara data by specified columns
 * @param {any} columns
 * @param {any} data
 */
export const formatRootLevel2 = (columns, data) => {
  return data.map((item) => {
    const { nextLevel, fields, ...prev } = item;
    // const keyCellId = level + 'L' + keyParentId + '|' + keyId;
    const formatFields = columns.reduce((acc, item2) => {
      const value = fields[item2.id];
      return { ...acc, [item2.id]: value === undefined ? '' : value }; //retorna el valor del campo or undefined
    }, {});

    // return { ...fields, keyId, rootField, hasChilds, level };
    const formatNextLevel = nextLevel.length ? formatRootLevel(columns, nextLevel) : [];
    return {
      ...prev,
      fields: { ...formatFields },
      ...fields,
      // isExpanded: false,
      // isChecked: false,
      nextLevel: formatNextLevel,
    };
    // return { ...fields, address: 'Av. Rangel Pestana, 11 - Bras São Paulo - SP, 01017-000, Brasil' };
  });
};
//#endregion
//#region paginationParenChild
/**
 * Receives a master/detail array structure
 * @param {array} data
 * @param {string} keyCellParentId
 * @returns {array} Returns a flat array with relation key/keyCellParentId in order to get item's child.
 */
export function parentChild(data, keyCellParentId = '') {
  if (!data.length) return [];
  return data
    .map((item) => {
      const { keyCellId, nextLevel, hasChilds } = item;
      const key = keyCellId;
      const childs = hasChilds ? nextLevel.map((child) => child.keyCellId) : [];
      const subChilds = hasChilds ? parentChild(nextLevel, keyCellId) : [];

      return [[key, { childs, keyCellParentId }], ...subChilds];
    })
    .flat();
}
export const getParents = (child, parentChilds) => {
  if (!parentChilds.has(child)) return [];
  const parentId = parentChilds.get(child).keyCellParentId;
  return [parentId, ...getParents(parentId, parentChilds)];
};
/**
 * Pure function. Returns number of page in pagination
 * @param {array} rows
 * @param {number} rowPerPage
 */
export const getPagination = (rows, rowPerPage) => {
  const calc = rows.length / rowPerPage;
  const exactCalc = Math.round(rows.length / rowPerPage);
  return calc - exactCalc > 0 ? exactCalc + 1 : exactCalc;
};

export const getChilds = (data) => {
  const childs = data.map((item) => {
    const { keyCellId } = item;
    return !item.hasChilds ? [keyCellId] : [keyCellId, ...getChilds(item.nextLevel)];
  });

  return childs.flat();
};
export const getCheckedRowsSize = (items) => {
  if (!items.length) return [];
  const checkedItems = items
    .reduce((acc, item) => {
      if (item.isChecked) acc.push(item);
      const deepOne = item.hasChilds ? getCheckedRowsSize(item.nextLevel) : [];
      return [...acc, ...deepOne];
    }, [])
    .flat();

  return [...new Set([...checkedItems])];
};
const getAllNextLevel = (array, noEval = true) => {
  if (!array.length) return { regionIds: [], checkedItems: [] };
  return array.reduce(
    (acc, item) => {
      const { keyId, keyCellId, isFinal, isChecked } = item;
      if (isChecked || noEval) {
        if (isFinal) acc.regionIds.push(keyId);
        acc.checkedItems.push(keyCellId);
      }

      const deepOne = item.hasChilds ? getAllNextLevel(item.nextLevel, noEval) : { regionIds: [], checkedItems: [] };
      acc.regionIds = [...acc.regionIds, ...deepOne.regionIds];
      acc.checkedItems = [...acc.checkedItems, ...deepOne.checkedItems];
      return acc;
    },
    { regionIds: [], checkedItems: [] },
  );
};
export const getCheckedRowsInItems = (items) => {
  if (!items.length) return { regionIds: [], checkedItems: [] };
  const checkedItems = items.reduce(
    (acc, item) => {
      const { keyId, keyCellId, isChecked } = item;
      if (isChecked) {
        if (!item.hasChilds) acc.regionIds.push(keyId);
        acc.checkedItems.push(keyCellId);
        const deepOne = item.hasChilds ? getAllNextLevel(item.nextLevel) : { regionIds: [], checkedItems: [] };
        acc.regionIds = [...acc.regionIds, ...deepOne.regionIds];
        acc.checkedItems = [...acc.checkedItems, ...deepOne.checkedItems];
      } else {
        const deepOne = item.hasChilds ? getAllNextLevel(item.nextLevel, false) : { regionIds: [], checkedItems: [] };
        acc.regionIds = [...acc.regionIds, ...deepOne.regionIds];
        acc.checkedItems = [...acc.checkedItems, ...deepOne.checkedItems];
      }

      return acc;
    },
    { regionIds: [], checkedItems: [] },
  );
  return checkedItems;
};
//checkRow=returns only pure rows
//checkItem=returns items including group
export const getCheckedRowsInItemsWidthFields = (items, noEval = true) => {
  if (!items.length) return { checkedRow: [], checkedItems: [] };
  const checkedItems = items.reduce(
    (acc, item) => {
      const { isChecked } = item;
      if (isChecked || noEval) {
        if (!item.hasChilds) acc.checkedRow.push({ ...item, ...item.fields });
        acc.checkedItems.push(item);
        const deepOne = item.hasChilds ? getCheckedRowsInItemsWidthFields(item.nextLevel) : { checkedRow: [], checkedItems: [] };
        acc.checkedRow = [...acc.checkedRow, ...deepOne.checkedRow];
        acc.checkedItems = [...acc.checkedItems, ...deepOne.checkedItems];
      } else {
        const deepOne = item.hasChilds ? getCheckedRowsInItemsWidthFields(item.nextLevel, false) : { checkedRow: [], checkedItems: [] };
        acc.checkedRow = [...acc.checkedRow, ...deepOne.checkedRow];
        acc.checkedItems = [...acc.checkedItems, ...deepOne.checkedItems];
      }

      return acc;
    },
    { checkedRow: [], checkedItems: [] },
  );
  return checkedItems;
};
export const getCheckedRowsInItemsWithField = (checked, items) => {
  if (!items.length) return [];
  const checkedItems = items
    .reduce((acc, item) => {
      const foundItem = checked.find((check) => check === item.keyCellId && !item.hasChilds);
      if (foundItem) acc.push({ keyId: item.keyId, ...item.fields }); //cambiar a foundItem.keyId
      const deepOne = item.hasChilds ? getCheckedRowsInItemsWithField(checked, item.nextLevel) : [];
      return [...acc, ...deepOne];
    }, [])
    .flat();
  return [...new Set([...checkedItems])];
};

//#endregion
