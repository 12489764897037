//@ts-nocheck
import { action, computed } from 'easy-peasy';
//primary: {text,}
const initValues = {
  regions: [],
  gjsonRegions: [],
  toGridMap: [],
  // checkedItems: [],
  selectedItem: { lat: undefined, lng: undefined },
  selectedAsset: undefined,
  focusedAsset: undefined,
  devicesOnMap: [],
  isViewAll: false,
  actionMap: 'default',
  selectedRegionIds: [],
};
const map = {
  ...initValues,
  //function to add regions coming from API
  addAssets: action((map, regions) => {
    // console.info(regions);
    // const regionsFields = regions.map((item) => ({ ...item, ...item.fields }));
    map.gjsonRegions = { type: 'FeatureCollection', features: [] };
    map.regions = [...regions];
    map.actionMap = 'default';
  }),
  //function to add selected regions to map and grid
  setSelectedRegions: action((map, selectedIds) => {
    console.log('store');
    console.info(selectedIds);
    map.selectedRegionIds = selectedIds;
  }),
  loadToGridMap: action((map, { selectedIds, gjsonRegions }) => {
    const distinctRows = selectedIds.reduce((acc, item) => {
      if (acc.has(item.keyId)) return acc;

      // const { fields, ...rest } = item;
      // const itemFields = { ...fields, ...rest };
      // console.info(itemFields);
      acc.set(item.keyId, item);
      return acc;
    }, new Map());
    // map.checkedItems = checkedItems.length ? checkedItems.map((item) => item.keyCellId) : [];
    map.toGridMap = Array.from(distinctRows.values());
    map.gjsonRegions = gjsonRegions;
    map.devicesOnMap = Array.from(distinctRows.keys());
    // map.selectedItem = { lat: undefined, lng: undefined };
    map.selectedAsset = undefined;
    map.actionMap = 'reload';
    map.isViewAll = true;
  }),
  clearGrid: action((map) => {
    map.toGridMap = [];
    map.devicesOnMap = [];
    map.selectedAsset = undefined;
    map.actionMap = 'remove';
    map.isViewAll = false;
  }),
  //funcion llamada al hacer click en el grid
  //analizar funcion de leaflet para remover un elemento del geojson
  onRemove: action((map, keyCellId) => {
    map.toGridMap = map.toGridMap.filter((item) => item.keyCellId != keyCellId);
    map.actionMap = 'default';
  }),
  onView: action((map, selectedId) => {
    const { features } = map.gjsonRegions;
    const item = features.find((rowId) => +rowId.id === +selectedId);
    if (!item) {
      map.selectedItem = { lat: undefined, lng: undefined };
      map.selectedAsset = undefined;
      return;
    }
    // console.info(item);
    const { flyTo } = item.properties;
    map.selectedItem = { lat: flyTo[1], lng: flyTo[0] };
    map.selectedAsset = item;
    map.actionMap = 'onView';
    map.isViewAll = false;
  }),
  onViewAll: action((map) => {
    map.selectedItem = { lat: undefined, lng: undefined };
    map.selectedAsset = undefined;
    map.isViewAll = true;
    map.actionMap = 'onViewAll';
  }),
  onFocus: action((map, focus) => {
    if (!focus) {
      map.focusedAsset = undefined;
    } else {
      if (map.selectedAsset) {
        map.focusedAsset = map.selectedAsset;
        map.actionMap = 'onFocus';
      }
    }
  }),
};

export default map;
