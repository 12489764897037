import { makeStyles } from '@material-ui/core/styles';
import { blackColor, INPUT_BACKGROUND } from '../../components/styles/constValues';

export const useFormStyle = makeStyles((theme) => ({
  root: {
    // margin: theme.spacing(4),
  },

  content: {
    display: 'flex',
    justifyContent: 'center',
    // height: theme.spacing(80),
  },
  portletStepperStyle: {
    width: '100%',
    paddingBottom: theme.spacing(1),
  },
  div: {
    display: 'flex',
    // justifyContent: 'flex-end',
    flexDirection: 'row',
    justifyContent: 'end',
    // padding: theme.spacing(4),
  },
  formStyle: { width: '100%' },
  inDiv: {
    padding: theme.spacing(4),
    position: 'relative',
  },
  inSmallDiv: { padding: theme.spacing(2), position: 'relative' },
  remover: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonProgress: {
    color: theme.palette.primary.dark[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  alert: { margin: theme.spacing(2) },
  listLabel: {
    color: theme.palette.primary.dark,
  },

  labelFilter: {
    position: 'absolute',
    top: '18.5%',
    marginLeft: '4px',
    color: theme.palette.primary.dark,
    fontSize: '16px',
  },
  contentSwitch: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  contentSwitch2: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  speedStyle: {
    display: 'flex',
    flexDirection: 'row',
    minWidth: theme.spacing(12),
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    // alignItems: 'center',
  },
  inputSpeedStyle: {
    minWidth: theme.spacing(10),
    width: theme.spacing(12),
  },
  paperStyle: { backgroundColor: blackColor },
  paperMula: {
    width: '100%',
    maxHeight: 650,
  },
  formBoxControl: {
    // margin: theme.spacing(1),
    minWidth: theme.spacing(12),
    maxWidth: 250,
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.primary.main,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.secondary.main,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.secondary.main,
      },
    },
  },
  formControlSituation: {
    // margin: theme.spacing(1),
    backgroundColor: INPUT_BACKGROUND,
    width: 250,
    // maxWidth: 'auto',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.primary.main,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.secondary.main,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.secondary.main,
      },
    },
  },
}));
