import axios from 'axios';

const ADD_JOURNEYCONTROL = '/journeyControl/add';
const ADD_MACRO_CONFIGURATION = '/journeyControl/addMacrosConfiguration';
const UPDATE_TAG_MACRO = '/journeyControl/updateTagMacro';
const UPDATE_RENAME_MACRO = '/journeyControl/renameMacro';
const UPDATE_MULA_MACRO = '/journeyControl/updateMulaMacro';
const UPD_CONFIGURATION = '/journeyControl/updateConfiguration';
const ROLL_OUT = '/journeyControl/rollOut';
const ENABLE_DISABLE = '/journeyControl/disableEnable';
const PUBLISH_JOURNEYCONTROL = '/journeyControl/publish';
const UPDATE_NEXT_MACROS = '/journeyControl/updateNextMacros';
const CREATE_MACRO = '/journeyControl/createMacro';

export function postJourneyControl(name, description, templateId) {
  return axios.post(ADD_JOURNEYCONTROL, { name, description, templateId });
}
export function postUpdateMulaMacro(data) {
  return axios.post(UPDATE_MULA_MACRO, { ...data });
}
export function postUpdateTagMacro(data) {
  return axios.post(UPDATE_TAG_MACRO, { ...data });
}
export function postUpdateRenameMacro(data) {
  return axios.post(UPDATE_RENAME_MACRO, { ...data });
}
export function postAddMacroConfiguration(data) {
  return axios.post(ADD_MACRO_CONFIGURATION, { ...data });
}
export function postRollOut(journeyControlId) {
  return axios.post(ROLL_OUT, { journeyControlId });
}
export function postPublish(body) {
  return axios.post(PUBLISH_JOURNEYCONTROL, { ...body });
}
export function postConfiguration(body) {
  return axios.post(UPD_CONFIGURATION, { ...body });
}
export function postUpdateNextMacros(body) {
  return axios.post(UPDATE_NEXT_MACROS, { ...body });
}
export function postCreateMacro(body) {
  return axios.post(CREATE_MACRO, { ...body });
}
export function postEnableDisable(journeyControlId, isDisabled) {
  return axios.put(ENABLE_DISABLE, { journeyControlId, isDisabled });
}
//  export const ADD_ROTOGRAMA_REGIONS = '/journeyControl/addRegions';
// export const PUBLISH_ROTOGRAMA = '/journeyControl/publish';
// export const DELETE_CONFIGURATION = '/journeyControl/deleteConfiguration';
// export const ROLL_OUT = '/journeyControl/rollOut';
// export const ENABLE_DISABLE = '/journeyControl/disableEnable';
// const ADD_CONFIGURATIONS_ROTOGRAMA = '/journeyControl/addConfigurations';
// const UPDATE_CONFIGURATIONS_ROTOGRAMA = '/journeyControl/updateConfigurations';

// const put_configuration = (url) => [{ url, method: 'POST' }, { manual: true }];

// export const addJourneyControl = put_configuration(ADD_ROTOGRAMA);

// export function postDeleteConfiguration(configurationId) {
//   return axios.post(DELETE_CONFIGURATION, { configurationId });
// }
// export function postRollOut(spokenRouteId) {
//   return axios.post(ROLL_OUT, { spokenRouteId });
// }

// export function postEnableDisable(spokenRouteId, isDisabled) {
//   return axios.post(ENABLE_DISABLE, { spokenRouteId, isDisabled });
// }
// export const postConfigurations = (configurations) => {
//   console.info(configurations);
//   const { configurationId } = configurations;
//   if (configurationId === 0) return axios.post(ADD_CONFIGURATIONS_ROTOGRAMA, { ...configurations });
//   return axios.post(UPDATE_CONFIGURATIONS_ROTOGRAMA, { ...configurations });
// };
// export function postPublish(body) {
//   return axios.post(PUBLISH_ROTOGRAMA, { ...body });
// }
