import axios from 'axios';

const ADD_ROLE_SECTIONS = '/security/addRoleSections';
const ADD_ROLE_USERS = '/security/addRoleUsers';
const ADD_NEW_ROLE = '/security/addRole';
const REMOVE_ROLE = '/security/removeRole';
const REMOVE_USER_FROM_ROLE = '/security/removeUserFromRole';

export function postAddRoleSections(body) {
  return axios.post(ADD_ROLE_SECTIONS, body);
}
export function postAddRoleUsers(body) {
  return axios.post(ADD_ROLE_USERS, body);
}
export function postAddNewRole(body) {
  return axios.post(ADD_NEW_ROLE, body);
}
export function postRemoveRole(body) {
  return axios.post(REMOVE_ROLE, body);
}
export function postRemoveUserFromRole(body) {
  return axios.post(REMOVE_USER_FROM_ROLE, body);
}
// export function postRollOut(journeyControlId) {
//   return axios.post(ROLL_OUT, { journeyControlId });
// }
// export function postPublish(body) {
//   return axios.post(PUBLISH_JOURNEYCONTROL, { ...body });
// }
// export function postConfiguration(body) {
//   return axios.post(UPD_CONFIGURATION, { ...body });
