export const initSnack = {
  open: false,
  title: '',
  severity: 'error',
};

// Data for table
export const patterHeaderStyle = {
  paddingLeft: '0px',
  paddingRight: '0px',
  paddingTop: '8px',
  paddingBottom: '8px',
  textAlign: 'left',
};
export const patternCellStyle = {
  paddingLeft: '0px',
  paddingRight: '0px',
  paddingTop: '10px',
  paddingBottom: '10px',
  textAlign: 'left',
};
