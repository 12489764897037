import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputText from '../../../../components/InputText/InputText';
import { FormNameIcon } from '../../../../../assets/icons/formIcons';
import { CircularProgress } from '@material-ui/core';
import { useFormStyle } from '../../../../common/styles/formStyle';

function TagMacro(props) {
  const { options, title, tag, i18, onClose, value: valueProp, open, ...other } = props;
  const [value, setValue] = React.useState(tag || '');
  const [isOkDisabled, setIsOkDisabled] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const radioGroupRef = React.useRef(null);
  const classes = useFormStyle();
  React.useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = async () => {
    if (value === valueProp) onClose();
    else {
      setIsLoading(true);
      await onClose(value);
      setIsLoading(false);
    }
  };

  const handleChange = (value) => {
    setIsOkDisabled(false);
    setValue((prev) => (value.length > 5 ? prev : value));
  };

  return (
    <Dialog disableBackdropClick disableEscapeKeyDown maxWidth="xs" onEntering={handleEntering} aria-labelledby="confirmation-dialog-title" open={open} {...other}>
      <DialogTitle id="confirmation-dialog-title">{i18('SET_TAG_MACRO')}</DialogTitle>
      <DialogContent dividers>
        {' '}
        <InputText
          id="name"
          placeholder={i18('INPUT_TAG')}
          name="name"
          onChange={(e) => handleChange(e.target.value)}
          value={value}
          // label={i18('name')}
          // icon={FormNameIcon}
          // inputRef={register({ required: true })}
          fullWidth
        ></InputText>
      </DialogContent>
      <DialogActions>
        <div className={classes.div}>
          <div className={classes.inSmallDiv}>
            <Button autoFocus onClick={handleCancel} color="primary">
              Cancel
            </Button>
          </div>
          <div className={classes.inSmallDiv}>
            <Button onClick={handleOk} disabled={isOkDisabled} color="primary">
              {i18('ok')}
            </Button>
            {/* <CircularProgress size={24} className={classes.buttonProgress} /> */}
            {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>
        </div>
      </DialogActions>
    </Dialog>
  );
}

TagMacro.propTypes = {
  id: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  i18: PropTypes.func.isRequired,
};

export default TagMacro;
