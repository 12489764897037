import React from 'react';
// nodejs library that concatenates classes
import clsx from 'clsx';
// nodejs library to set properties for components
import PropTypes from 'prop-types';

// material-ui components
import Checkbox from '@material-ui/core/Checkbox';
import { InputBase, Paper, InputLabel } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import patternStyle from '../styles/pattern';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  rootDiv: { width: '100%' },
  label: {
    marginLeft: '4px',
    color: theme.palette.primary.dark,
    fontSize: '14px',
  },
}));
const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const CustomCheck = ({ ...props }) => {
  const { className, label, ...rest } = props;

  const classes = useStyles();
  const patternClasses = clsx(
    {
      [classes.rootDiv]: true,
      // [classes[size]]: size,
      // [classes[color]]: color,
    },
    className
  );
  return (
    <div className={patternClasses}>
      {label && <InputLabel className={classes.label}>{label}</InputLabel>} <GreenCheckbox {...rest} />
    </div>
  );
};

CustomCheck.propTypes = {
  className: PropTypes.string,
  // color: PropTypes.oneOf(['neutral', 'primary', 'info', 'success', 'warning', 'danger']),
  // size: PropTypes.oneOf(['sm', 'md', 'lg']),
};

CustomCheck.defaultProps = {
  className: '',
  // size: 'md',
  // color: 'default',
};

export default CustomCheck;
