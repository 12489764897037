import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { warningColor, primaryColor, dangerColor, successColor, infoColor, roseColor, grayColor, defaultFont, CHECK_CELL_COLOR } from '../styles/constValues';
import { useTableContext } from './TableContext';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
    marginBottom: '0',
    maxWidth: '100%',
    backgroundColor: 'transparent',
    borderSpacing: '0',
  },

  warningTableHeader: {
    color: warningColor[0],
  },
  primaryTableHeader: {
    color: primaryColor[0],
  },
  dangerTableHeader: {
    color: dangerColor[0],
  },
  successTableHeader: {
    color: successColor[0],
  },
  infoTableHeader: {
    color: infoColor[0],
  },
  roseTableHeader: {
    color: roseColor[0],
  },
  grayTableHeader: {
    color: grayColor[0],
  },

  tableHeadCell: {
    borderColor: 'transparent',
    color: theme.palette.text.primary,
    padding: '8px',
  },
  groupCell2: {},
  tableHeadCellNoCheckBox: {
    borderColor: 'transparent',
    color: theme.palette.text.primary,
  },
  tableCell: {
    lineHeight: '1.42857143',
    padding: '8px 4px',
    verticalAlign: 'middle',
    fontSize: '0.8125rem',
  },
  tableResponsive: {
    width: '100%',
  },
  tableHeadRow: {
    borderColor: 'transparent',
    height: '56px',
    color: 'inherit',
    display: 'table-row',
    outline: 'none',
    verticalAlign: 'middle',
  },
  tableBodyRow: {
    height: '48px',
    color: 'inherit',
    display: 'table-row',
    outline: 'none',
    verticalAlign: 'middle',
  },
  tableCheckCell: {
    height: '24px',
    width: '24px',
    color: CHECK_CELL_COLOR,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

function TableSuperHeader(props) {
  const { hasCheckBox, headCells, tableHeaderColor } = props;
  const classes = useStyles();
  const [checkAll, setCheckAll] = useState(false);
  const { dispatch, state, checkAllItems } = useTableContext();
  const {
    sort: { order, orderBy },
  } = state;
  // this function check all items
  const handleCheckAll = () => {
    setCheckAll((prev) => !prev);
    checkAllItems(!checkAll);
  };

  // this is a function to sort the table by the selected column
  const createSortHandler = (property) => {
    dispatch({ type: 'SORT', property });
  };

  return (
    <TableHead className={classes[tableHeaderColor + 'TableHeader']}>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell key={headCell.id} className={classes.tableHeadCell} style={{ width: headCell.width }} align={headCell.align} padding={index === 0 && !hasCheckBox ? 'default' : headCell.disablePadding ? 'none' : 'default'} sortDirection={orderBy === headCell.id ? order : false}>
            {hasCheckBox && index === 0 && <Checkbox style={{ padding: 0 }} checked={checkAll} onChange={handleCheckAll} className={classes.tableCheckCell} />}
            <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : 'asc'} onClick={() => createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span> : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

TableSuperHeader.propTypes = {
  tableHeaderColor: PropTypes.string,
  headCells: PropTypes.arrayOf(PropTypes.object),
  numSelected: PropTypes.number.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  hasCheckBox: PropTypes.bool,
  hasGroups: PropTypes.bool,
};

export default TableSuperHeader;
