//#region imports
import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, CardContent, CircularProgress, Typography } from '@material-ui/core';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import Portlet from '../../../components/Portlet/Portlet';
import { useForm } from 'react-hook-form';
import { FormNameIcon, FormDescriptionIcon } from '../../../../assets/icons/formIcons';
import { INPUT_BACKGROUND, whiteColor, blackColor } from '../../../components/styles/constValues';
import InputText from '../../../components/InputText/InputText';
import PortletActions from '../../../components/Portlet/PorletActions';
import Button from '../../../components/CustomButton/Button';
//ApiMedhots
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import axios from 'axios';
import { makeUseAxios } from 'axios-hooks';
import { postRotograma } from './api/postMethods';
import { Alert } from '@material-ui/lab';
import { useIntl } from '../../../lang/context/intlContext';
//state
import { useStoreActions, useStoreState } from 'easy-peasy';
import { getNameAvailability } from './api/getMethods';
import { useFormStyle } from '../../../common/styles/formStyle';
//#endregion

const useAxios = makeUseAxios({
  axios,
});

const schema = yup.object().shape({
  name: yup.string().required(),
  // age: yup.number().positive().integer().required(),
});

function Add({ history }) {
  //subheader should this move to a higher level?

  const { createNew, putRotograma, setNoButtons, initRotograma, createdNew } = useStoreActions((actions) => {
    const { rotograma, globalState } = actions;
    return { ...rotograma, ...globalState };
  });
  useEffect(() => {
    setNoButtons();
    initRotograma();
  }, []);
  // setNoButton();
  // const [loading, setLoading] = useState(false);
  const { i18 } = useIntl();
  // const [apiError, setApiError] = useState(false);
  const { register, handleSubmit, errors } = useForm({ resolver: yupResolver(schema) });
  const [free, setfree] = useState(undefined);
  const [estaCargando, setEstaCargando] = useState(false);
  const { error, currentNew, isLoading, spokenRouteId } = useStoreState((state) => state.rotograma);
  const classes = useFormStyle();

  const onSubmit = async (data) => {
    try {
      // console.log('submitting');
      // updateData(data);
      // console.info('all ok');
      setEstaCargando(true);
      const { data: body } = await getNameAvailability(data.name);
      if (body.isFree) {
        const { data: rotograma } = await postRotograma(data.name, data.description);
        createdNew(rotograma);
      } else setfree(data.name);
      // history.push(`/mfis/rotograma/add/select-regions`);
    } catch (error) {
      console.error(error);
    }
    setEstaCargando(false);
  };
  // console.log('spokenRouteId', spokenRouteId);
  if (spokenRouteId > 0) {
    // //all ok, get RotogId, update state and redirect
    // const { error, spokenRouteId, name, description } = currentNew;
    return <Redirect to={`/mfis/rotograma/add/select-regions`} />;
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <GridContainer className={classes.content}>
          <GridItem item xs={12} md={4} lg={4} className={classes.content}>
            <Portlet className={classes.formStyle} title={i18('CREATE_SR')} subTitle={i18('CREATE_SUBHEADER_SR')}>
              <CardContent>
                <GridContainer direction="column" spacing={2}>
                  <GridItem item xs={12} md={12}>
                    <InputText
                      id="name"
                      placeholder={i18('name') + ' (' + i18('required') + ')'}
                      name="name"
                      label={i18('name')}
                      icon={FormNameIcon}
                      inputRef={register({ required: true })}
                      fullWidth
                    ></InputText>
                    {/* <p>{errors.name?.message}</p> */}
                    {free && <Typography color="secondary">{`${free} ${i18('NOTAVAI')}`}</Typography>}

                    {errors.name && <Typography color="secondary">{i18('Name_Req')}</Typography>}

                    {/* {errors.firstName && 'First name is required'} */}
                  </GridItem>
                  <GridItem item xs={12} md={12}>
                    <InputText
                      id="description"
                      name="description"
                      label={i18('Description')}
                      placeholder={i18('description') + ' (' + i18('optional') + ')'}
                      inputRef={register({ required: true })}
                      icon={FormDescriptionIcon}
                      multiline
                      rows={4}
                    ></InputText>

                    {/* {errors.firstName && 'First name is required'} */}
                  </GridItem>
                </GridContainer>
              </CardContent>
              <PortletActions>
                <div className={classes.div}>
                  <div className={classes.inDiv}>
                    <Button variant="contained" disabled={isLoading} color={'cancel'}>
                      {i18('Cancel')}
                    </Button>
                  </div>
                  <div className={classes.inDiv}>
                    <Button type="submit" disabled={estaCargando} variant="contained" color={'primary'}>
                      {i18('Create')}
                    </Button>
                    {/* <CircularProgress size={24} className={classes.buttonProgress} /> */}
                    {estaCargando && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </div>
                </div>
              </PortletActions>
              {error.length > 0 && (
                <Alert className={classes.alert} severity="error">
                  {i18('API_ERR')}
                </Alert>
              )}
            </Portlet>
          </GridItem>
        </GridContainer>
      </form>
    </div>
  );
}

export default Add;
