//#region IMPORTS
import React, { useState } from 'react';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import { MoreHoriz } from '@material-ui/icons';
import { Button, Chip } from '@material-ui/core';
import CustomSwitch from '../CustomSwitch/CustomSwitch';
import ListMacro from './ListMacro';
import { getIcon } from './helper';
import SelectMacro from '../../modules/mfis/journeyControl/Configuration/SelectMacro';
import { macroStyles } from '../../common/styles/macroStyle';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import SelectActions from '../../modules/mfis/journeyControl/Configuration/SelectActions';
import { useIntl } from '../../lang/context/intlContext';
import TagMacro from '../../modules/mfis/journeyControl/Configuration/TagMacro';
import RenameMacro from '../../modules/mfis/journeyControl/Configuration/RenameMacro';

//#endregion

const Macro = (props) => {
  const { i18 } = useIntl();
  const { itemMacro, selectMacroTitle, selectActionTitle, macros, actions, title, type, tag = '', mula, isReadOnly, prev = [], next = [], prevLabel = 'Prev', nextLabel = 'Next', addNextMacro, onClickDeleteNextMacro, addActions, updateRenameMacro, updateMacroTag, updateMacroMula } = props;
  const classes = macroStyles();
  const [expanded, setExpanded] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [stateCheck, setStateCheck] = useState({ isNext: false, label: 'Next' });
  const [openSelectMacro, setOpenSelectMacro] = React.useState(false);
  const [openSelectActions, setOpenSelectActions] = React.useState(false);
  const [openTagMacro, setOpenTagMacro] = React.useState(false);
  const [openRenameMacro, setOpenRenameMacro] = React.useState(false);
  const [openMulaMacro, setOpenMulaMacro] = React.useState(false);
  const cardClasses = clsx({
    [classes.root]: true,
    // [className]: className,
    [classes.defaultAlign]: true,
  });
  const rootHoverClasses = clsx({
    [classes.rootHover]: true,
    // [classes[align]]: align,
    [classes.defaultAlign]: true,
    // [classes.rootHoverSelected]: true,
  });
  const macrosToSelect = macros.filter((item) => item.macroJourneyControlId !== itemMacro.macroJourneyControlId && !next.find((nextItem) => nextItem.macroJourneyControlNextId === item.macroJourneyControlId));
  const actionsToSelect = []; // actions
  //#region handlers
  const handleChange = (event) => {
    setStateCheck((prev) => ({ isNext: !prev.isNext, label: !prev.isNext ? prevLabel : nextLabel }));
  };
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const handleClick = (event) => {
    // this.setState({ anchorEl: event.currentTarget });
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = async ({ action }) => {
    setAnchorEl(null);
    switch (action) {
      case 'openActions':
        setOpenSelectActions(true);
        break;
      case 'openRename':
        setOpenRenameMacro(true);
        break;

      default:
        break;
    }
  };
  const handleClose = (newValue) => {
    setOpenSelectMacro(false);

    if (newValue) {
      const { description, name: title, macroJourneyControlId } = newValue;
      //parentId is actual macro where adding/removing macros
      const newNextMacro = { description, title, macroJourneyControlId, parentId: itemMacro.macroJourneyControlId };
      addNextMacro(newNextMacro);
    }
  };
  const handleCloseActions = (newValue) => {
    //send to state management
    setOpenSelectActions(false);
    const toUpdate = { actions: newValue, macroId: itemMacro.macroId };
    addActions(toUpdate);
  };
  const handleTagMacro = async (newValue) => {
    //send to state management
    if (newValue) {
      const toUpdate = { tag: newValue, macroId: itemMacro.macroId };
      await updateMacroTag(toUpdate);
    }
    setOpenTagMacro(false);
    // const toUpdate = { actions: newValue, macroId: itemMacro.macroId };
    // addActions(toUpdate);
  };
  const handleRenameMacro = async (newValue) => {
    if (newValue) {
      const toUpdate = { name: newValue, macroId: itemMacro.macroId };
      await updateRenameMacro(toUpdate);
    }
    setOpenRenameMacro(false);
  };
  const handleMulaMacro = async (newValue) => {
    const toUpdate = { mula: newValue, macroId: itemMacro.macroId };
    await updateMacroMula(toUpdate);
    setOpenSelectActions(false);
  };
  const onClickDelete = (value) => {
    onClickDeleteNextMacro(value);
  };

  //#endregion
  return (
    <>
      <Card className={cardClasses}>
        <div className={rootHoverClasses}>
          <CardHeader
            classes={{ avatar: classes.avatar }}
            avatar={
              <Avatar className={classes.avatar}>
                {getIcon(type, 'primary', classes.listItemAvatarIconBlue)}
                {/* <InicioViagemIcon /> */}
              </Avatar>
            }
            action={
              <IconButton onClick={handleClick}>
                <MoreHoriz />
              </IconButton>
            }
            title={title}
            titleTypographyProps={{ variant: 'h4', noWrap: true }}
          ></CardHeader>
          <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
            <MenuItem onClick={(e) => handleCloseMenu({ action: 'openActions' })}>{i18('Settings')}</MenuItem>
            <MenuItem onClick={(e) => handleCloseMenu({ action: 'openRename' })}>{i18('Rename')}</MenuItem>
          </Menu>
          <CardContent className={classes.contentRoot}>
            <div className={classes.contentSwitch}>
              <CustomSwitch handleChange={handleChange} checked={stateCheck}></CustomSwitch>
              <Chip label={tag || 'TAG'} color="primary" size="small" clickable onClick={() => setOpenTagMacro(true)} />
            </div>
            <div className={classes.contentButtons}>
              <Button disabled={stateCheck.isNext || isReadOnly} onClick={() => setOpenSelectMacro(true)} startIcon={<AddIcon />} variant="outlined" size="small" color="primary">
                {i18('Add')}
              </Button>
              {/* <Button startIcon={<SortIcon />} variant="outlined" size="small" color="primary">
              Sort
            </Button> */}
            </div>
            <div>
              <Collapse in={!stateCheck.isNext}>
                <ListMacro classes={classes} items={next} hasDelete={true} onClickDelete={onClickDelete} />
              </Collapse>
              <Collapse in={stateCheck.isNext}>
                <ListMacro classes={classes} items={prev} />
              </Collapse>
            </div>
          </CardContent>
        </div>
        <SelectMacro
          id="select-macro"
          classes={{
            paper: classes.paper,
          }}
          i18={i18}
          keepMounted
          open={openSelectMacro}
          onClose={handleClose}
          value={''}
          options={macrosToSelect || []}
          title={selectMacroTitle}
        ></SelectMacro>

        <TagMacro id="tagMacro" classes={{ paper: classes.paper }} i18={i18} keepMounted value={tag} open={openTagMacro} onClose={handleTagMacro}></TagMacro>
        <RenameMacro id="renameMacro" classes={{ paper: classes.paper }} i18={i18} value={tag} open={openRenameMacro} onClose={handleRenameMacro} />
      </Card>
      <SelectActions
        id="select-actions"
        classes={{
          paper: classes.paperMula,
        }}
        i18={i18}
        actions={actions}
        keepMounted
        open={openSelectActions}
        onClose={handleCloseActions}
        onSave={handleMulaMacro}
        data={mula}
        value={''}
        options={actionsToSelect || []}
        title={selectActionTitle}
      ></SelectActions>
    </>
  );
};

export default Macro;
