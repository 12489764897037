import React, { useState } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Typography, IconButton, ListItemIcon, ListItemSecondaryAction, List, Slider, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { FormRol, FormAlarmShortTime } from '../../../assets/icons/formIcons';
import { ICON_PINK_COLOR } from '../styles/constValues';
import CustomSwitch from '../CustomSwitch/CustomSwitch';
import InputBoxText from '../InputText/InputBoxText';
import Canva from '../Canva/Canva';
import InputMaskText from '../InputMaskText/InputMaskText';
const defStyle = {
  marginTop: '4px',
  maxWidth: '100%',
  backgroundColor: '#ffff',
  maxHeight: '400px',
  overflow: 'auto',
};

export const MacroSettings = ({ mula, classes, i18, handlers }) => {
  const { onConditionClick, onConditionSwitchClick, onMessageChange, onActionClick, onActionSwitchClick } = handlers;
  const { conditions, actions } = mula;

  const handleOnChanges = (e) => {
    const value = e.target.value;
    const field = e.target.name;
    // setValues((values) => ({
    //   ...values,
    //   [field]: value,
    // }));
  };
  const handleSliders = (field, newValue) => {
    // setValues((prev) => ({ ...prev, [field]: +newValue }));
  };

  return (
    <div className={classes.content}>
      <Canva hasTitle={true} title={'Conditions'}>
        <List component="nav" dense={false} style={defStyle}>
          {conditions.map((item) => (
            <ListItem key={'C' + item.id} button={true} onClick={(event) => onConditionClick(event, item.id)}>
              <ListItemIcon>
                <FormRol color={item.isEnabled ? item.color : 'gray'} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="body2" style={{ color: '#181c32' }}>
                    {item.name}
                  </Typography>
                }
              />
              <ListItemSecondaryAction>
                <div className={classes.secondaryContent}>
                  <CustomSwitch disabled={!item.isEnabled} handleChange={(e) => onConditionSwitchClick(e, item.id)} checked={{ isNext: item.onValue, label: '' }}></CustomSwitch>
                  <InputBoxText id="messageInput1" name="messageInput1" placeholder={i18('message_when_fails')} disabled={!item.isEnabled} value={item.message} onChange={(e) => onMessageChange(e, item.id)} fullWidth></InputBoxText>
                </div>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Canva>
      <Canva hasTitle={true} title={'Actions'}>
        <List component="nav" dense={false} style={defStyle}>
          {actions.map((item) => (
            <ListItem key={'C' + item.id} button={true} onClick={(event) => onActionClick(event, item.id)}>
              <ListItemIcon>
                <FormRol color={item.isEnabled ? item.color : 'gray'} />
              </ListItemIcon>
              <ListItemText
                style={{ minWidth: '200px' }}
                primary={
                  <Typography variant="body2" style={{ color: '#181c32' }}>
                    {item.name}
                  </Typography>
                }
              />
              <ListItemSecondaryAction>
                <div className={classes.secondaryContent}>{item.typeComponent === 'switch' && <CustomSwitch disabled={!item.isEnabled} handleChange={(e) => onActionSwitchClick(e, item.id)} checked={{ isNext: item.onValue, label: '' }}></CustomSwitch>}</div>
                {/* <div className={classes.secondaryContentText}>{item.typeComponent === 'text' && <InputMaskText style={{ maxWidth: '100' }} id="maxContinousDriving" name="maxContinousDriving" icon={FormAlarmShortTime} value={'3000'} format={'##:##'} mask="_" onChange={handleOnChanges}></InputMaskText>}</div> */}
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Canva>
    </div>
  );
};
function ValueLabelComponent(props) {
  const { children, open, value } = props;

  return (
    <Tooltip title={<h1 style={{ fontSize: '14px' }}>{value}</h1>} open={true} enterTouchDelay={0} placement="top">
      {children}
    </Tooltip>
  );
}
