export const homeTitle = [
  { path: '/home/asset/map', title: 'Asset', subTitle: 'Map' },
  { path: '/home/asset/administration', title: 'Asset', subTitle: 'Administration' },
  { path: '/home/region/map', title: 'Region', subTitle: 'Map' },
  { path: '/home/region/administration', title: 'Region', subTitle: 'Administration' },
  { path: '/home/region/create', title: 'Region', subTitle: 'Create' },
  { path: '/home/mfis/map', title: 'MFIS', subTitle: 'Map' },
  { path: '/home/mfis/administration', title: 'MFIS', subTitle: 'Administration' },
  { path: '/home/mfis/evidence/reconcile', title: 'MFIS', subTitle: 'Reconcile' },
  { path: '/home/evidence/map', title: 'Evidences', subTitle: 'Map' },
  { path: '/home/evidence/administration', title: 'Evidences', subTitle: 'Administration' },
];
