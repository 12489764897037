export const mfisTitle = [
  { path: '/mfis/control/management', title: 'Control de Jornada', subTitle: 'Management' },
  { path: '/mfis/control/selecttemplate', title: 'Control de Jornada', subTitle: 'Select Template' },
  { path: '/mfis/control/add', title: 'Control de Jornada', subTitle: 'Add' },
  { path: '/mfis/control/link', title: 'Control de Jornada', subTitle: 'Link' },
  { path: '/mfis/control/configuration', title: 'Control de Jornada', subTitle: 'Macros Configuration' },
  { path: '/mfis/control/publish', title: 'Journey Control', subTitle: 'Publish' },
  { path: '/mfis/control/status', title: 'Journey Control', subTitle: 'Status' },
  { path: '/mfis/rotograma/management', title: 'Rotograma Falado', subTitle: 'Management' },
  { path: '/mfis/rotograma/add', title: 'Rotograma Falado', subTitle: 'Add' },
  { path: '/mfis/rotograma/link', title: 'Rotograma Falado', subTitle: 'Link' },
  { path: '/mfis/calendar/management', title: 'Calendar', subTitle: 'Management' },
  { path: '/mfis/rotograma/add/view-regions', title: 'Rotograma Falado', subTitle: 'Configurations' },
  { path: '/mfis/rotograma/add/select-regions', title: 'Rotograma Falado', subTitle: 'Regions' },
  { path: '/mfis/rotograma/region-configuration', title: 'Rotograma Falado', subTitle: 'Region Configuration' },
  { path: '/mfis/rotograma/view/region-configuration', title: 'Rotograma Falado', subTitle: 'region-configuration' },
  { path: '/mfis/rotograma/configurations', title: 'Rotograma Falado', subTitle: 'Configurated regions' },
  { path: '/mfis/rotograma/publish', title: 'Rotograma Falado', subTitle: 'Publish' },
  { path: '/mfis/rotograma/status', title: 'Rotograma Falado', subTitle: 'Status' },
  { path: '/mfis/rotograma/archive', title: 'Rotograma Falado', subTitle: 'Archive' },
  { path: '/mfis/macro/management', title: 'Macro Secuenciado', subTitle: 'Management' },
  { path: '/mfis/macro/selecttemplate', title: 'Macro Secuenciado', subTitle: 'Management' },
  { path: '/mfis/macro/add', title: 'Macro Secuenciado', subTitle: 'Add' },
  { path: '/mfis/macro/link', title: 'Macro Secuenciado', subTitle: 'Link' },
  { path: '/mfis/calendar/add', title: 'Calendar', subTitle: 'Add' },
  { path: '/mfis/calendar/link', title: 'Calendar', subTitle: 'Link' },
  { path: '/mfis/cpanel/management', title: 'Control Panel', subTitle: 'Management' },
  { path: '/mfis/cpanel/add', title: 'Control Panel', subTitle: 'Add' },
  { path: '/mfis/cpanel/link', title: 'Control Panel', subTitle: 'Link' },
  { path: '/mfis/events/management', title: 'MFIS_EVENTS', subTitle: 'Management' },
  { path: '/mfis/events/new', title: 'MFIS_EVENTS', subTitle: 'Add' },
  { path: '/mfis/events/selecttemplate', title: 'MFIS_EVENTS', subTitle: 'SELECT_MFIS_TEMPLATE' },
  { path: '/mfis/events/configuration', title: 'MFIS_EVENTS', subTitle: 'CONFIGURATION' },
  { path: '/mfis/events/status', title: 'MFIS_STATUS', subTitle: 'STATUS' },
  { path: '/mfis/events/publish', title: 'MFIS_publish', subTitle: 'publish' },
  { path: '/mfis/evidence/reconcile/edit', title: 'RECONCILE', subTitle: 'Edit' },
  { path: '/mfis/evidence/reconcile', title: 'EVIDENCE', subTitle: 'reconcile' },
  { path: '/mfis/reconcile/management', title: 'RECONCILE', subTitle: 'management' },
  { path: '/mfis/reconcile/status', title: 'RECONCILE', subTitle: 'status' },
];
