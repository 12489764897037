import axios from 'axios';
const GET_VEHICLES = '/vehicle/get';
const GET_DEVICES = '/devices/';
const GET_CLIENTS = '/clients/';
const GET_VEHICLES_WO_DEVICES = '/vehicle/getWODevices';
const GET_DRIVERS = '/driver/get';
const GET_ALL_DRIVERS = '/driver/getAll';
const GET_ALL_NFCS_LIST = '/nfc/getNFCSList';
const GET_ALL_CLIENT = 'auth/client';
const GET_ALL_ASSETS_LNP = '/assets/getAssets';
const GET_TABLETS = '/assets/getTablets';
const GET_ALL_TABLETS = '/assets/getAllTablets';
const GET_ALL_DVR = '/assets/getAllDVR';
const GET_GRID_TABLETS = '/assets/getGridTablets';
const GET_DEVICES_ASSETS = '/devices/devicesAssets';
const GET_ALL_EVIDENCES = '/evidences/getAll';
const GET_ALL_TABLE_EVIDENCES = '/evidences/getTableEvidences';
const GET_CHECK_IMEI = '/devices/checkImei';

export function getAllEvidences() {
  return axios.get(GET_ALL_EVIDENCES);
}

export function getAllTableEvidences(params) {
  const { type = 0, limit = 200, level = 0, plates = '', driver = '', status = 0, startDateTime = undefined, endDateTime = undefined } = params;
  return axios.get(GET_ALL_TABLE_EVIDENCES + '?limit=' + limit + '&type=' + type + '&level=' + level + '&status=' + status + '&plates=' + plates + '&driver=' + driver + '&startDateTime=' + startDateTime + '&endDateTime=' + endDateTime);
}

export function getChekcImei(params) {
  return axios.get(`${GET_CHECK_IMEI}?imei=${params}`);
}

export function getDevicesAssets() {
  return axios.get(`${GET_DEVICES_ASSETS}`);
}

export function getAllVehicles() {
  return axios.get(`${GET_VEHICLES}`);
}
export function getAllDevices() {
  return axios.get(`${GET_DEVICES}`);
}
export function getAllClients() {
  return axios.get(`${GET_CLIENTS}`);
}
export function getAllVehiclesWODevices() {
  return axios.get(`${GET_VEHICLES_WO_DEVICES}`);
}

export function getAllDrivers() {
  return axios.get(`${GET_DRIVERS}`);
}
export function getDriversNFC() {
  return axios.get(`${GET_ALL_DRIVERS}`);
}
export function getAllClient() {
  return axios.get(`${GET_ALL_CLIENT}`);
}
export function getTableNFCS() {
  return axios.get(`${GET_ALL_NFCS_LIST}`);
}

export function getAssets() {
  return axios.get(`${GET_ALL_ASSETS_LNP}`);
}
export function getTablets() {
  return axios.get(`${GET_TABLETS}`);
}
export function getAllTablets() {
  return axios.get(`${GET_ALL_TABLETS}`);
}
export function getAllDVR() {
  return axios.get(`${GET_ALL_DVR}`);
}
export function getGridTablets() {
  return axios.get(`${GET_GRID_TABLETS}`);
}
