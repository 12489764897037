import React, { useState, useEffect } from 'react';
import { useDebounce } from 'use-debounce';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputText from '../../components/InputText/InputText';
import AutoCompleteBox from '../../components/ComboBox/AutoCompleteBox';
import { v4 as uuidv4, v4 } from 'uuid';
import { warningColor, primaryColor, dangerColor, successColor, infoColor, roseColor, grayColor, defaultFont, ROW_BACK_ODD, CHECK_CELL_COLOR } from '../styles/constValues';
import SuperTable from './Table';
import TableSuperToolbar from './TableSuperToolbar';

//Context import
import { useTableContext } from './TableContext';
import { useIntl } from '../../lang/context/intlContext';
import { getOptionsField, getOptionGroups } from './helper';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(0),
    // padding: theme.spacing(4),
  },
  paper: {
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    // height: theme.spacing(100),
  },
  table: {
    minWidth: 750,
    marginBottom: '0',
    maxWidth: '100%',
    backgroundColor: 'transparent',
    borderSpacing: '0',
  },
  tableContainer: {
    maxHeight: theme.spacing(48),
    // maxHeight: '100vh',
  },

  warningTableHeader: {
    color: warningColor[0],
  },
  primaryTableHeader: {
    color: primaryColor[0],
  },
  dangerTableHeader: {
    color: dangerColor[0],
  },
  successTableHeader: {
    color: successColor[0],
  },
  infoTableHeader: {
    color: infoColor[0],
  },
  roseTableHeader: {
    color: roseColor[0],
  },
  grayTableHeader: {
    color: grayColor[0],
  },

  tableHeadCell: {
    display: 'flex',
    flexDirection: 'row',
    color: 'inherit',
    // ...defaultFont,
    '&, &$tableCell': {
      fontSize: '1em',
    },
  },
  tableCellHeader: {
    // ...defaultFont,
    height: theme.spacing(5),
    borderColor: 'transparent',
    letterSpacing: '0.36px',
    // lineHeight: '1.42857143',
    // padding: '12px 8px',
    verticalAlign: 'middle',
    fontSize: '0.8125rem',
  },
  tableCell: {
    // ...defaultFont,
    padding: 0,
    paddingLeft: theme.spacing(1),
    height: theme.spacing(5),
    borderColor: 'transparent',
    letterSpacing: '0.36px',
    // lineHeight: '1.42857143',
    // padding: '12px 8px',
    verticalAlign: 'middle',
    fontSize: '0.8125rem',
  },
  tableResponsive: {
    width: '100%',
    // marginTop: theme.spacing(3),
    // overflowX: 'auto',
  },
  tableHeadRow: {
    height: '56px',
    color: 'inherit',
    display: 'table-row',
    outline: 'none',
    verticalAlign: 'middle',
  },
  tableBodyRow: {
    height: theme.spacing(5),
    color: 'inherit',
    display: 'table-row',
    outline: 'none',
    verticalAlign: 'middle',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableCheckCell: {
    // backgroundColor: CHECK_CELL_COLOR,
    height: '24px',
    color: CHECK_CELL_COLOR,
  },
  tableGroupCell: {
    height: theme.spacing(5),
    borderColor: 'transparent',
    letterSpacing: '0.36px',
    // lineHeight: '1.42857143',
    // padding: '12px 8px',
    verticalAlign: 'middle',
    fontSize: '0.8125rem',
    width: '30px',
  },
  tableEmptyGroupCell: {
    height: theme.spacing(5),
    borderColor: 'transparent',
    letterSpacing: '0.36px',
    // lineHeight: '1.42857143',
    // padding: '12px 8px',
    verticalAlign: 'middle',
    fontSize: '0.8125rem',
    width: '46px',
  },
  tableGroupCellLeftPadding0: {
    paddingLeft: '0px',
  },
  tableGroupCellLeftPadding1: {
    paddingLeft: '16px',
  },
  groupCell: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: theme.spacing(40),
    // flexDirection: 'column',
  },
  groupCellLevel0: {
    justifyContent: 'flex-start',
  },
  groupCellLevel1: {
    justifyContent: 'center',
    marginLeft: theme.spacing(2),
    // paddingLeft: theme.spacing(2),
  },
  groupCellLevel2: {
    justifyContent: 'flex-end',
    marginLeft: theme.spacing(3),
    // paddingLeft: theme.spacing(2),
  },
  groupCell1: {
    width: theme.spacing(4),
  },
  groupCell2: {
    width: theme.spacing(4),
    // alignItems: 'center',
  },
  groupCell3: {
    width: theme.spacing(30),
  },
  groupCell1NoCheck: {
    // width: theme.spacing(20),
    paddingLeft: theme.spacing(1),
    justifySelf: 'flex-start',
    // justifyContent: 'flex-start',
  },
  filter: { display: 'flex', paddingTop: theme.spacing(2), paddingLeft: theme.spacing(0) },
  filterInput: { width: theme.spacing(20) },
  filterRegionInput: { width: theme.spacing(60) },
}));
//Aqui colorca filtros, y acciones tipo exportar
//esta seccion tiene acceso al contexto
export default function TablePaper(props) {
  const classes = useStyles();
  const { i18 } = useIntl();
  const { noFilter, filterInputs, optionsGroup, hasSearchAllFields } = props;
  const { filter, filterOptions, title, state } = useTableContext();
  const [execFilterParameters, setExecFilterParameters] = useState(undefined);
  const [valueFilter] = useDebounce(execFilterParameters, 700);

  const handleFilter = (event, field) => {
    const value = field === 'all' ? event.toString().toLowerCase() : event.target.textContent;
    const prevFilter = filterOptions[field];
    let newFilterParameters = {};
    if (field === 'all') {
      newFilterParameters = { ...filterOptions, all: { field: 'all', value } };
      setExecFilterParameters(newFilterParameters);
    } else {
      const newValues = value.length ? [value] : [];
      newFilterParameters = { ...filterOptions, [field]: { field: prevFilter.field, value: newValues } };
      filter(newFilterParameters);
    }
  };
  useEffect(() => {
    if (!valueFilter) {
      return;
    }
    filter(valueFilter);

  }, [valueFilter]);
  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={0}>
        {!noFilter && (
          <TableSuperToolbar title={title} numSelected={0}>
            <div className={classes.filter}>
              {optionsGroup && (
                <div>
                  <AutoCompleteBox
                    label={i18('Groups')}
                    style={{ width: 300 }}
                    options={optionsGroup}
                    onChange={(event) => handleFilter(event, 'group')}
                    // placeholder={i18('Groups')}
                    freeSolo
                  />
                </div>
              )}
              {filterInputs.map((item, index) => {
                const filterOptionValues = getOptionsField(state.originalDatos, item.field).sort();
                return (
                  <div key={'FILTER' + index.toString()} className={classes.filterRegionInput} style={index > 0 || optionsGroup ? { paddingLeft: '10px' } : {}}>
                    <AutoCompleteBox label={i18(item.label)} options={filterOptionValues} onChange={(event) => handleFilter(event, item.field)} freeSolo />
                  </div>
                );
              })}
              {hasSearchAllFields && (
                <div style={{ paddingLeft: '10px' }}>
                  <InputText
                    id="allFields"
                    placeholder={i18('SearchAllFields')}
                    name="allFields"
                    // value={filterOptions['all'].value[0]}
                    onChange={(event) => handleFilter(event.target.value, 'all')}
                    // label={i18('SearchAllFields')}
                    outLine
                    // icon={FormNameIcon}
                    // inputRef={register({ required: true })}
                    fullWidth
                  ></InputText>
                </div>
              )}
            </div>
          </TableSuperToolbar>
        )}
        <SuperTable {...props} classes={classes} />
      </Paper>
    </div>
  );
}
