import React, { Fragment, useRef, useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// nodejs library to set properties for components
import PropTypes from 'prop-types';

import L from 'leaflet';
import { MapContainer, TileLayer, ZoomControl, useMap, Marker, Popup } from 'react-leaflet';
import './custom.css';
import 'leaflet/dist/leaflet.css';
import { useStoreState, useStoreActions } from 'easy-peasy';
import createMarker, { createSimpleMarker } from '../modules/map/functions/marker';
import CustomControl from '../modules/map/CustomControl';

import Button from '../components/CustomButton/Button';
import { FiltroIcon } from '../../assets/icons';
import mapPositioning, { mapAttributes } from '../modules/map/functions/calcMapAttributes';

import { useIntl } from '../lang/context/intlContext';
//Functions needed
//todo focus
//todo fouces and view

const useStyle = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  mapMenu: { display: 'flex' },
  mapButtons: {
    paddingTop: theme.spacing(14),
    paddingRight: theme.spacing(8),
  },
  mapViewAll: {
    paddingTop: theme.spacing(14),
    paddingRight: theme.spacing(2),
  },
  content: {},
}));

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});
const validateAssetsPosition = (assets, focusedAsset) => {
  const validPositions = assets.filter((item) => {
    let isValid = true;
    if (isNaN(item.latitude) || isNaN(item.longitude) || isNaN(item.gpsSpeed)) {
      isValid = false;
    }
    return isValid;
  });
  if (!focusedAsset) return validPositions;

  return validPositions.map((item) => {
    const isFocussed = focusedAsset.deviceId === item.deviceId;
    if (!isFocussed) return { ...item, icon: 0 };
    return { ...item, icon: 1 };
  });
};
function MapSimple(props) {
  const { onClickFilter, item } = props;
  const classes = useStyle();
  const { i18 } = useIntl();
  //#region easy peasy
  const toGridMap = useStoreState((state) => state.map.toGridMap);
  const selectedItem = useStoreState((state) => state.map.selectedItem);
  const focusedAsset = useStoreState((state) => state.map.focusedAsset);
  const isViewAll = useStoreState((state) => state.map.isViewAll);

  //#endregion

  const validAssets = validateAssetsPosition(toGridMap, focusedAsset);
  const assetsOnMap = validAssets.map((item) => createMarker(item, false));
  const mapPos = mapAttributes(validAssets);

  return (
    <div id="container" className="contentmapSimple">
      <MapContainer center={[item.latitude, item.longitude]} zoom={17} zoomControl={false} maxZoom={18} scrollWheelZoom={true} style={{ height: '230px' }}>
        <ZoomControl position="bottomright" />
        <TileLayer attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' url="http://{s}.tile.osm.org/{z}/{x}/{y}.png" />
        <Fragment>{createSimpleMarker(item)}</Fragment>
      </MapContainer>
    </div>
  );
}
MapSimple.propTypes = {
  onClickFilter: PropTypes.func,
  // title: PropTypes.string,
  // numSelected: PropTypes.number.isRequired,
  item: PropTypes.object,
};

MapSimple.defaultProps = {
  //icon: null,
};
export default MapSimple;

function MyComponent(props) {
  const { bounds, selectedItem, isViewAll, focusedAsset, countItems } = props;

  const map = useMap();
  if (countItems === 0) {
    map.locate({ setView: true });
    return null;
  }
  const boundsOptions = { paddingBottomRight: L.point(0, 210) };
  // console.log('selectedItem', selectedItem);
  // console.log('isViewAll', isViewAll);
  let isInBound = true;
  if (focusedAsset) {
    console.info(focusedAsset);
    const latLong = { lat: focusedAsset.latitude, lng: focusedAsset.longitude };
    isInBound = map.getBounds().contains(latLong);
    // console.log('isInBound', isInBound);
    // console.log('bounds', map.getBounds().contains());
  }
  if (isViewAll) {
    map.fitBounds(bounds, boundsOptions);
  }
  if (selectedItem.lat) {
    map.flyTo(selectedItem, 18, { duration: 2 });
  }

  if (!isViewAll && !selectedItem.lat && isInBound === false) {
    console.log('bounding focused');
    const latLong = { lat: focusedAsset.latitude, lng: focusedAsset.longitude };
    map.setView(latLong, map.getZoom());
    // map.fitBounds(bounds, boundsOptions);
  }
  // console.log('bounds', bounds);

  // console.log('bound', map.getBounds());
  return null;
}
