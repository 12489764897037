//#region imports
import React from 'react';
import GridContainer from '../components/Grid/GridContainer';
import GridItem from '../components/Grid/GridItem';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CustomCard from '../components/CustomCard/Card';
import english from '../../app/lang/translations/en.json';
import { createIntl, createIntlCache } from 'react-intl';
import Table from '../components/Table/Table';
import rawData from '../mock/positions.json';

import { useIntl } from '../lang/context/intlContext';

//#endregion
import SplitterLayout from 'react-splitter-layout';
// import 'react-splitter-layout/lib/index.css';
// import './custom.css';
import { SplitPane } from 'react-collapse-pane';
import Button from '../components/CustomButton/Button';
import { FiltroIcon } from '../../assets/icons';
const ICON_COLOR = 'linear-gradient(to bottom, #c5b1f9, #5978e4)';

const useStyle = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  marginButton: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  nextButton: {},
  alarmIconStyle: {
    fill: '#5978e4',
    borderRadius: '4px',
  },
  alarmIconStyleSelected: {
    fill: theme.palette.text.primary,
    borderRadius: '4px',
  },
  doneIconStyle: {
    color: '#6be48d',
  },
  badgeStyle: {},
}));

const useStyleCard = makeStyles((theme) => ({
  card: {
    maxWidth: 345,
  },
  headerStyle: {
    backgroundColor: theme.palette.primary.main,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));
const useStyleGrid = makeStyles((theme) => ({
  content: {
    display: 'flex',
    justifyContent: 'center',
    height: theme.spacing(105),
  },
  splitterStyle: {
    position: 'inherit',
  },
}));
// "device": "020390",
//       "plates": "AVD-9A88",
//       "CPF": "028.383.239-89",
//       "longitude": -54.57683181,
//       "latitude": -20.60977745,
//       "vel": 0,
//       "odometer": 544248,
//       "dateTime": "2020-08-11T23:51:59.000Z",

function LabZone(props) {
  const { i18 } = useIntl();
  const data = rawData.stats;
  const headCells = [
    { id: 'plates', numeric: false, align: 'left', disablePadding: true, label: i18('Asset'), width: '90px' },
    { id: 'device', numeric: false, align: 'left', disablePadding: false, label: i18('Device'), width: '90px' },
    { id: 'driverId', numeric: true, align: 'left', disablePadding: false, label: i18('DriverId'), width: '50px' },
    {
      id: 'trackingId',
      numeric: true,
      align: 'left',
      disablePadding: false,
      label: i18('trackingId'),
      defaultOrder: 'true',
      width: '120px',
    },
    { id: 'latitude', numeric: true, align: 'left', disablePadding: false, label: i18('Latitude'), width: '150px' },
    { id: 'longitude', numeric: true, align: 'left', disablePadding: false, label: i18('Longitude'), width: '150px' },
    { id: 'vel', numeric: true, align: 'left', disablePadding: false, label: i18('Vel'), width: '50px' },
    // { id: 'address', numeric: true, align: 'left', disablePadding: false, label: i18('address'), width: 'auto' },
  ];
  const classes = useStyleGrid();
  // console.log('home');
  return (
    <div>
      <GridContainer>
        {' '}
        <GridItem item xs={12} md={12} lg={12} className={classes.content}>
          <Button color="map" size="sm" startIcon={<FiltroIcon />}>
            Filter
          </Button>
          {/* <div style={{ position: 'relative', width: '400px' }}>
            <SplitPane
              className={classes.splitterStyle}
              resizerOptions={{
                css: {
                  width: '1px',
                  position: 'inherit',
                  background: 'rgba(0, 0, 0, 0.1)',
                },
              }}
              split="horizontal"
              collapseOptions={{
                beforeToggleButton: <Button>⬅</Button>,
                afterToggleButton: <Button>➡</Button>,
                overlayCss: { backgroundColor: 'black' },
                buttonTransition: 'zoom',
                buttonPositionOffset: -20,
                collapsedSize: 50,
                collapseTransitionTimeout: 350,
              }}
            >
              <div style={{ padding: '16px' }}>
                {' '}
                <CustomCard
                  title={'Titualos gracnde de informacion para moestr'}
                  align={'center'}
                  contentText={'conetex text to use'}
                  hasAvatar
                ></CustomCard>
              </div>
              <div style={{ paddingTop: '16px' }}>
                {' '}
                <CustomCard
                  title={'Titualos gracnde de informacion para moestr'}
                  align={'center'}
                  contentText={'conetex text to use'}
                  hasAvatar
                ></CustomCard>
              </div>
            </SplitPane>
          </div> */}
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default LabZone;
