import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { LayoutUIProvider } from '../layout/context/layoutContext';
import { useStoreState } from 'easy-peasy';

function PrivateRoute({ layout: Layout, component: Component, ...rest }) {
  const user = useStoreState((state) => state.user);
  const { token, allSections } = user;
  if (token === '') {
    return <Redirect to="/login" />;
  }
  //Checar seccion y si tiene righs, checar rights
  const { sectionId = 0, rights = 2 } = rest;
  const validSectionId = sectionId === 100 || allSections.filter((item) => item.sectionId === sectionId && item.rights >= rights).length;

  if (!validSectionId) {
    return <Redirect to="/unauthorized" />;
  }

  return <Route {...rest} render={(props) => (token ? <LayoutUIProvider>{Layout ? <Layout {...props}>{Component && <Component {...props} />}</Layout> : <Component {...props} />}</LayoutUIProvider> : <Redirect to="/login" />)} />;
}

export default PrivateRoute;
