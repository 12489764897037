import ViewAssets from './pages/ViewAssets';
import { useAuth } from './auth/context/auth';

import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import Loader from './auth/Loader';
import PrivateRoute from './auth/PrivateRoute';
//#region Maps
import layoutMap from '../app/layout/LayoutMap';
import layoutMapRegion from '../app/layout/LayoutMapRegion';
import MapPage from './pages/MapPage';
import MapRegionPage from './pages/MapRegionPage';

//#endregion
import HomePage from './pages/HomePage';
import MyPage from './pages/MyPage';
import LabZone from './pages/LabZone';

//#region Import SpokenRoute

//#endregion

//#region MFIS

//#endregion
//#region Administration
import Drivers from './modules/administration/drivers/Drivers';
import LinkDriver from './modules/administration/drivers/LinkDriver';
import New from './modules/administration/drivers/New';
import NewNFC from './modules/administration/nfc/New';
import NFC from './modules/administration/nfc/NFC';
import ChangeOwner from './modules/administration/nfc/ChangeOwner';
import Vehicles from './modules/administration/vehicles/Vehicles';
import NewVehicle from './modules/administration/vehicles/New';
//#endregion

import Unauthorized from './auth/Unauthorized';

//#region Reports

//#endregion
import Lab from './pages/Lab';

import LayoutMapEvidence from './layout/LayoutMapEvidence';
import Reconcile from './modules/mfis/evidence/Reconcile';
import DownloadPDF from './pages/DownloadPDF';
import HomeRoutes from './routes/HomeRoutes';
import MFISRoutes, { mfisRoutes } from './routes/MSFISRoutes';
import JCManagement from './modules/mfis/journeyControl/Management';
import AdministrationRoutes from './routes/AdministrationRoutes';
import ReportRoutes from './routes/ReportRoutes';
const BasePage = ({ layout }) => {
  // console.log('basepage');

  // const { authTokens } = useAuth();

  // sectionId	name
  // 1	Assets - Map
  // 2	Regions - Map
  // 3	Regions - Create
  // 4	Journey Control
  // 5	Spoken Route
  // 6	Drivers - Administration
  // 7	NFC - Administration
  // 8	NFC Setup
  // 9	Security

  return (
    <div>
      <Switch>
        <PrivateRoute sectionId={100} exact path="/loader" layout={null} component={Loader} />
        <Redirect exact path="/" to="/home/asset/map" />
        {MFISRoutes.data.map((item, index) => (
          <PrivateRoute layout={layout} key={'rm' + index} {...item} />
        ))}
        {AdministrationRoutes.data.map((item, index) => (
          <PrivateRoute layout={layout} key={'ra' + index} {...item} />
        ))}
        {ReportRoutes.data.map((item, index) => (
          <PrivateRoute layout={layout} key={'rr' + index} {...item} />
        ))}
        {HomeRoutes().data.map((item, index) => (
          <PrivateRoute key={'rh' + index} {...item} />
        ))}
        <Route path="/unauthorized" component={Unauthorized} />
        <Route path="/downloadPDF" component={DownloadPDF} />
      </Switch>
    </div>
  );
};

export default BasePage;
