import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
// import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
// import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
// import FavoriteIcon from '@material-ui/icons/Favorite';
// import ShareIcon from '@material-ui/icons/Share';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import MoreVertIcon from '@material-ui/icons/MoreVert';
import Button from '../CustomButton/Button';
import { Policy } from '@material-ui/icons';
// import { TemplateIcon } from '../../../assets/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: '0 10px 20px 0 rgba(92, 116, 129, 0.08)',
    borderRadius: '8px',
    transition: '0.4s',
  },
  content: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  headerLine: {
    backgroundColor: theme.palette.primary.main,
    height: '9px',
  },
  headerStyle: {
    color: theme.palette.text.primary,
    letterSpacing: '0.66px',
  },
  headerSlimStyle: {
    color: theme.palette.text.primary,
    letterSpacing: '0.66px',
    paddingBottom: theme.spacing(1),
  },
  subHeaderStyle: {
    color: theme.typography.body1.color,
  },
  contentTypoStyle: {
    marginTop: theme.spacing(2),
    fontSize: '16px',
    display: 'flex',
    fontFamily: 'Roboto-Bold',
    justifyContent: 'space-around',
  },
  avatarStyle: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  iconStyle: {
    height: '108px',
    width: '108px',
  },
  actionStyle: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  fullWidth: {
    width: '100%',
  },
}));

const Portlet = (props) => {
  const { className, title, fullWidth, subTitle, children, hasBlueLine, slim = false } = props;

  const classes = useStyles();
  const cardClasses = clsx({
    [classes.root]: true,
    [classes.content]: false,
    [classes.fullWidth]: fullWidth,
    [className]: className,
  });
  const headerStyle = slim ? classes.headerSlimStyle : classes.headerStyle;
  return (
    <Card className={cardClasses}>
      {hasBlueLine && <div className={classes.headerLine}></div>}
      <CardHeader title={title} subheader={subTitle} className={headerStyle}></CardHeader>
      {children}
    </Card>
  );
};
Portlet.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  hasBlueLine: PropTypes.bool,
  slimn: PropTypes.bool,
  children: PropTypes.array,
  fullWidth: PropTypes.bool,
};
Portlet.defaultProps = {
  className: '',
  hasBlueLine: true,
  slim: false,
  fullWidth: false,
};
export default Portlet;
