import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useIntl } from '../../../lang/context/intlContext';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import CustomCard from '../../../components/CustomCard/Card';

const useStyle = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  text: { fontSize: '20px' },
  content: {},
  gridContainer: {
    marginTop: theme.spacing(3),
  },
}));

const AddViewRegions = (props) => {
  const classes = useStyle();
  const { i18 } = useIntl();
  const [add, setAdd] = useState(false);
  const onAddButton = (e) => {
    e.preventDefault();
    setAdd(true);
  };
  if (add) return <Redirect to={`/mfis/rotograma/add/select-regions`} />;

  return (
    <div className={classes.root}>
      <Typography component="h3" variant="h5" noWrap className={classes.text}>
        {i18('NO_REGION')}
      </Typography>
      <GridContainer className={classes.gridContainer}>
        <GridItem item xs={12} md={12} lg={12}>
          <CustomCard title={i18('TITTLE_ADD_RF_CONFIGURATION')} text={''} buttonText={i18('Add') + '...'} onClick={onAddButton}></CustomCard>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default AddViewRegions;
