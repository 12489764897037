import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';

//#region CustomComponents
import Header from './components/Header/Header';
import Navigator from './components/Navigator/Navigator';
import SubNavigator from './components/Navigator/SubNavigator';
import Content from './components/ContentMap/Content';
import { subMenuData } from './components/Navigator/MainMenu/subMenu';
import { routesTitle } from './components/Navigator/MainMenu/routesTitle';
import { sumDrawers, drawerWidth } from './constants';
import { TableProvider } from '../components/Table/TableContext';
import TablePaper from '../components/Table/TablePaper';
import SplitterLayout from 'react-splitter-layout';

import Button from '../components/CustomButton/Button';
import InputText from '../components/InputText/InputText';
import { FormNameIcon } from '../../assets/icons/formIcons';

import MapPage from '../pages/MapPage';
import { initiateSocket, disconnectSocket, subscribeToChat, sendMessage, registerDevices } from '../modules/map/communication/socket';
//#endregion

import { useLayoutUIContext } from './context/layoutContext';
import { useIntl } from '../lang/context/intlContext';
import { useStoreActions, useStoreState } from 'easy-peasy';

import 'react-splitter-layout/lib/index.css';
import './custom.css';

//Dialog
//#region dialog
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { whiteColor } from '../components/styles/constValues';
//#endregion
import Assets from '../modules/map/Assets';
import CustomCheck from '../components/CustomCheck/CustomCheck';
import ComboBox from '../components/ComboBox/ComboBox';
import { getSelectGroups } from '../components/Table/helper';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { postCreateAssetGroup, postCreateAssetSubGroup, postLNPs, postRemoveAssetGroup } from '../modules/map/api/postMethods';
import { ActionControlPanel, ActionRemove, ActionSettings } from '../../assets/icons';
import { getGMTtoUserTimeZone } from '../../utils';
import useLocalStorage from '../../hooks/useLocalStorage';

const useStyles = makeStyles((theme) => ({
  proto: { maxHeight: 'calc(100vh-600px)' },
  root: {
    display: 'flex',
    backgroundColor: '#f2f5f9',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    height: theme.spacing(45),
  },
  wraper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    // height: '100vh',
    overflow: 'auto',
    width: `calc(100% - ${drawerWidth}px)`,
  },
  wraperShift: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    // height: '100vh',
    overflow: 'auto',
    width: `calc(100% - ${sumDrawers}px)`,
    // width: `calc(100% - ${drawerWidth}px)`,
  },
  headerStyle: {
    height: theme.spacing(10),
  },
  contentStyle: {
    display: 'flex',
    flexDirection: 'column',
    // marginTop: theme.spacing(13),
    // height: `calc(100%-${theme.spacing(13)})`,
  },
  sideStyle: {
    display: 'flex',
    // height: '100vh',
    width: drawerWidth,
  },
  contentHeaderStyle: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    flexGrow: 1,
    alignSelf: 'flex-start',
  },
  appBar: {
    position: 'relative',
    backgroundColor: whiteColor,
  },
  titleFilter: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  assetButton: {
    marginRight: theme.spacing(1),
  },
  // sideStyleShift: {
  //   display: 'flex',
  //   width: `calc(100% - ${sumDrawers}px)`,
  //   // height: '100vh',
  //   // width: drawerWidth,
  // },
}));

const headerTitle = (location) => {
  const titles = routesTitle.find((item) => {
    const cleanLocation =
      '/' +
      location.pathname
        .split('/')
        .filter((item) => isNaN(item))
        .join('/');
    return cleanLocation.indexOf(item.path) >= 0;
  });
  // const titles = routesTitle.find((item) => location.pathname.indexOf(item.path) >= 0);
  if (!titles) return { title: '', subTitle: '' };
  return titles;
};
function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const schema = yup.object().shape({
  name: yup.string().required(),
});
const formatLNP = (lnps, user, i18) => {
  return lnps.map((lnp) => {
    const { gpsDateTime, eventName, rcvDateTime } = lnp;

    return {
      ...lnp,
      gpsDateTime: getGMTtoUserTimeZone(gpsDateTime, user.timeZone),
      rcvDateTime: getGMTtoUserTimeZone(rcvDateTime, user.timeZone),
      event: i18(eventName),
    };
  });
};
export default function LayoutMap({ ...props }) {
  //#region const
  const { children, location } = props;
  const { i18 } = useIntl();
  const classes = useStyles();
  const [tableApi, setTableApi] = useState(null);
  const [maxHeightDef, setmaxHeightDef] = useState(210);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [variant, setVariant] = useState('temporary');
  const { menuData, setMenuData, subMenu, setSubMenu } = useLayoutUIContext(); // useLocalStorage('menu', menu);
  const i18Menu = menuData.map((item) => ({ ...item, name: i18(item.name) }));

  const [openFilter, setOpenFilter] = useState(false);
  const [openGroup, setOpenGroup] = useState(false);
  const [openDeleteGroup, setOpenDeleteGroup] = useState(false);
  const [isSubGroup, setisSubGroup] = useState(false);

  const [assetsApi, setAssetsApi] = useState(null);
  //#endregion

  const [height, setHeight] = useState(0);
  const ref = useRef(null);
  const { register, handleSubmit, setValue, errors } = useForm({ resolver: yupResolver(schema) });
  const { register: registerDelete, handleSubmit: handleSubmitDelete, setValue: setValueDelete, errors: errorsDelete } = useForm();

  //#region easypeasy
  const toGridMap = useStoreState((state) => state.map.toGridMap);
  const devicesOnMap = useStoreState((state) => state.map.devicesOnMap);
  const assets = useStoreState((state) => state.map.assets);
  const user = useStoreState((state) => state.user);

  const loadToGridMap = useStoreActions((actions) => actions.map.loadToGridMap);
  const onView = useStoreActions((actions) => actions.map.onView);
  const onTxMessage = useStoreActions((actions) => actions.map.onTxMessage);
  const updateAssets = useStoreActions((actions) => actions.map.updateAssets);
  const onRemove = useStoreActions((actions) => actions.map.onRemove);
  // console.info(user);
  //#endregion

  const groups = getSelectGroups(assets);

  //#region UseEffect Socket
  useEffect(() => {
    if (!ref.current) return;
    const tableHeight = ref.current.clientHeight - 330;
    setHeight(tableHeight);
  });
  // }, [ref.current?.clientHeight]);para hacer un solo llamado
  useEffect(() => {
    initiateSocket([], devicesOnMap);
    register({ name: 'group' }, { required: false });
    registerDelete({ name: 'groupDelete' }, { required: true });
    return () => {
      disconnectSocket();
    };
  }, []);

  useEffect(() => {
    if (!devicesOnMap.length) return;
    if (!devicesOnMap) return;
    if (devicesOnMap.length) {
      registerDevices(devicesOnMap);
      subscribeToChat((err, data) => {
        if (err) return;
        console.info(data);

        //here I should format: GPSDateTime, RCVDateTime and Event
        onTxMessage(formatLNP(data, user, i18));
      });
    }
  }, [devicesOnMap]);

  //#endregion
  //#endregion

  //#region LayoutFunctions
  const handleSidebarOpen = () => {
    const status = variant === 'temporary' ? 'permanent' : 'temporary';
    setVariant(status);
    if (status === 'permanent') setOpenSidebar(true);
    else setOpenSidebar(false);
  };
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    if (variant === 'temporary') setOpenSidebar(open);
  };
  const handleMainClick = (optionSelected) => {
    setOpenSidebar(true);
    //aplicamos al menu ppal seleccionado, para marcarlo como seleccionado
    const applySelection = menuData.map((item) => ({
      ...item,
      selected: item.label === optionSelected,
    }));
    setMenuData(applySelection);

    //aqui saco el subMenu a mostrar
    const filterSubMenu = subMenuData.find((item) => item.label === optionSelected).subMenu;
    const i18SubMenu = filterSubMenu.map((item) => {
      const i18SubMenus = item.subMenus.map((item2) => ({ ...item2, name: item2.name ? i18(item2.name) : '' }));
      return { ...item, name: i18(item.name), subMenus: i18SubMenus };
    });
    setSubMenu(i18SubMenu);
  };

  const rawTitles = useMemo(() => {
    return headerTitle(location);
  }, [location]);
  const titles = { title: i18(rawTitles.title), subTitle: i18(rawTitles.subTitle) };

  const handleClickOpen = () => {
    setOpenFilter(true);
  };

  const onPaneReSize = (size) => {
    setmaxHeightDef(size - 35);
  };
  //#endregion

  //#region TableDefinitions
  const headCellsTablets = [
    {
      id: 'rootField',
      numeric: false,
      align: 'left',
      disablePadding: true,
      label: i18('Asset'),
      width: '150px',
      defaultOrder: true,
    },
    { id: 'event', numeric: true, align: 'left', disablePadding: false, label: i18('eventName'), width: '150px' },
    { id: 'gpsSpeed', numeric: true, align: 'left', disablePadding: false, label: i18('gpsSpeed'), width: '50px' },
    { id: 'serial', numeric: true, align: 'left', disablePadding: false, label: i18('device'), width: '100px' },
    { id: 'gpsDateTime', numeric: true, align: 'left', disablePadding: false, label: i18('gpsDateTime'), width: '200px' },
    { id: 'rcvDateTime', numeric: true, align: 'left', disablePadding: false, label: i18('rcvDateTime'), width: '200px' },
    // { id: 'radius', numeric: true, align: 'left', disablePadding: false, label: i18('radius'), width: '250px' },
    { id: 'address', numeric: true, align: 'left', disablePadding: false, label: i18('address'), width: 'auto' },
    { id: 'actions', numeric: true, align: 'left', disablePadding: false, label: i18('actions'), width: '100px' },

    // { id: 'address', numeric: true, align: 'left', disablePadding: false, label: i18('address'), width: 'auto' },
  ];
  //
  function onTableReady(params) {
    const { api } = params;
    // setTableApi(api);
  }

  //#endregion

  //#region MapEvents
  const onClickFilter = (e) => {
    // console.log('onfilter');
    setOpenFilter(true);
  };

  //#endregion

  //#region AssetsSelection
  const onSelectedItems = (api) => {
    setAssetsApi(api);
  };
  const handleCheckIsSubGroup = () => {
    setisSubGroup((prev) => !prev);
  };
  const handleClose = () => {
    setOpenFilter(false);
  };
  const handleCloseGroup = () => {
    setOpenGroup(false);
  };
  const handleCloseDeleteGroup = () => {
    setOpenDeleteGroup(false);
  };

  // const { checkedRow, checkedItems } = assetsApi.getCheckedRowsWithFieldEval();

  // const selectedIds = checkedRow; // checkedItems.filter((item) => item.isFinal === true).map((item) => ({ ...item, ...item.fields }));
  // setopenFilter(false);
  // loadToGridMap({ selectedIds, checkedItems });

  const handleLoadToGridMap = async () => {
    const { checkedRow, checkedItems } = assetsApi.getCheckedRowsWithFieldEval();

    const rawSelectedIds = checkedRow; // checkedItems.filter((item) => item.isFinal === true).map((item) => ({ ...item, ...item.fields }));
    // const serials = rawSelectedIds.map((item) => item.serial);
    // const { data } = await postLNPs(serials);
    // const lnps = data.lnp;
    // const selectedIds = rawSelectedIds.map((item) => {
    //   const lnp = lnps[item.serial] || {};

    //   return { ...item, ...lnp, fields: { ...item.fields } };
    // });
    setOpenFilter(false);
    loadToGridMap({ selectedIds: rawSelectedIds, checkedItems });
  };
  const handleDeleteGroup = () => {
    // if (!dataForm.group) {
    //   alert(i18('SELECT_GROuP'));
    //   return;
    // } else setOpenDeleteGroup(true);
    setOpenDeleteGroup(true);
  };
  const handleCreateGroup = () => {
    // const { checkedRow, checkedItems } = assetsApi.getCheckedRowsWithFieldEval();

    // const selectedIds = checkedRow.map((item) => item.deviceId);
    const selectedIds = assetsApi.getCheckedAssets();
    // checkedItems.filter((item) => item.isFinal === true).map((item) => ({ ...item, ...item.fields }));
    // console.info(selectedIds);
    if (!selectedIds.length) alert('no regiones selected');
    else setOpenGroup(true);
  };
  const handleComboDeleteGroupsChange = (e) => {
    setValueDelete('groupDelete', e.target.value, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };
  const handleComboGroupsChange = (e) => {
    setValue('group', e.target.value, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };
  //Pensar en hacer un reload sin el effect con asset de vairable
  const onSubmitDelete = async (dataForm) => {
    try {
      if (!dataForm.groupDelete || dataForm.groupDelete === 0) {
        alert(i18('SELECT_GROuP'));
        return;
      }
      const { data } = await postRemoveAssetGroup(dataForm.groupDelete);
      if (data.error === 0) {
        setValueDelete('groupDelete', 0);
        updateAssets();
      } else {
        alert('Group could not be deleted');
      }

      handleCloseDeleteGroup();
    } catch (error) {
      console.error(error);
    }
  };
  const onSubmit = async (dataForm) => {
    try {
      if (!dataForm.group && isSubGroup) {
        alert(i18('SELECT_GROuP'));
        return;
      }

      // const { checkedRow } = assetsApi.getCheckedRowsWithFieldEval();
      // const selectedIds = checkedRow.map((item) => item.deviceId); // checkedItems.filter((item) => item.isFinal === true).map((item) => ({ ...item, ...item.fields }));

      const selectedIds = assetsApi.getCheckedAssets();

      console.info({ selectedIds });
      if (isSubGroup) {
        const { data } = await postCreateAssetSubGroup(dataForm.name, selectedIds, dataForm.group);
      } else {
        const { data } = await postCreateAssetGroup(dataForm.name, selectedIds);
      }

      // updateData(data);
      // console.info('all ok');
      // const { data: body } = await getNameAvailability(data.name);
      // if (body.isFree) {
      //   const { data: rotograma } = await postRotograma(data.name, data.description);
      //   createdNew(rotograma);
      // } else setfree(data.name);
      updateAssets();
      assetsApi.clearCheckedAssets();
      handleCloseGroup();
    } catch (error) {
      console.error(error);
    }
  };

  //#endregion

  //#region Grid Events && Components
  const ActionsRender = useCallback((id) => {
    return (
      <>
        <ActionSettings />
        <ActionControlPanel />
        <ActionRemove onClick={() => removeAsset(id)} />
      </>
    );
  }, []);
  const handleClickOnRow = useCallback((itemId) => {
    console.log('itemId :>> ', itemId);
    onView(itemId);
  }, []);
  const removeAsset = useCallback((id) => {
    onRemove(id);
  }, []);
  //#endregion
  return (
    <div className={classes.root}>
      <div className={classes.sideStyle}>
        <Navigator handleMainClick={handleMainClick} menu={i18Menu} onSidebarOpen={handleSidebarOpen} />
        <SubNavigator
          // anchor={'left'}
          subMenu={subMenu}
          location={location}
          open={openSidebar}
          toggleDrawer={toggleDrawer}
          // variant={variant}
          // variant={isDesktop ? 'persistent' : 'temporary'}
          variant={variant}
        />
      </div>
      <div className={clsx(classes.wraper, openSidebar && classes.wraperShift)}>
        <SplitterLayout vertical primaryIndex={0} secondaryInitialSize={250} onSecondaryPaneSizeChange={onPaneReSize}>
          <div className={classes.headerStyle}>
            <ElevationScroll {...props}>
              <Header open={openSidebar} {...titles} {...props}></Header>
            </ElevationScroll>
            <Content open={openSidebar} subTitle={titles.subTitle}>
              <MapPage onClickFilter={onClickFilter} />
            </Content>
          </div>
          {toGridMap.length > 0 && (
            <div>
              <TableProvider loadData={toGridMap} columns={headCellsTablets} withPagination={false} title="" checkedItems={[]} initRowsPerPage={10} onContextReady={onTableReady} useV2={true} actions={ActionsRender}>
                <TablePaper handleClickOnRow={handleClickOnRow} maxHeight={maxHeightDef + 'px'} filterInputs={[]} hasCheckBox={false} hasGroups={true} hasSearchAllFields={true} noFilter></TablePaper>
              </TableProvider>
            </div>
          )}
        </SplitterLayout>
      </div>
      <div ref={ref}>
        <Dialog fullScreen open={openFilter} onClose={handleClose} TransitionComponent={Transition}>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton edge="start" color="primary" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.titleFilter}>
                {i18('SELECT_ASSETS')}
              </Typography>
              <Button autoFocus color="primary" className={classes.assetButton} onClick={handleDeleteGroup}>
                {i18('Delete_GROUP')}
              </Button>

              <Button autoFocus color="primary" className={classes.assetButton} onClick={handleCreateGroup}>
                {i18('CREATE_GROUP')}
              </Button>
              <Button autoFocus color="primary" onClick={handleLoadToGridMap}>
                {i18('LOAD_TO_MAP')}
              </Button>
            </Toolbar>
          </AppBar>
          <Assets maxHeight={height} onSelectedItems={onSelectedItems} />
        </Dialog>
        <Dialog open={openGroup} onClose={handleCloseGroup} aria-labelledby="form-dialog-title">
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogTitle id="form-dialog-title">{i18('CREATE_GROUP')}</DialogTitle>
            <DialogContent>
              <DialogContentText>Input group name. If you want to create a sub-group, check on Sub Group option</DialogContentText>
              <InputText id="name" placeholder={i18('name') + ' (' + i18('required') + ')'} name="name" label={i18('name')} icon={FormNameIcon} inputRef={register({ required: true })} fullWidth></InputText>
              {errors.name && <Typography color="secondary">{i18('Name_Req')}</Typography>}
              <CustomCheck
                // className={classes.poeStyle}
                label={i18('This_IS_SUBGROUP')}
                checked={isSubGroup}
                onChange={handleCheckIsSubGroup}
                name="playOnExit"
              />
              <ComboBox
                disabled={!isSubGroup}
                label={i18('GRoups')}
                icon={FormNameIcon}
                name="group"
                defaultValue={''}
                // defaultValue={isPreAlertEnabled ? preAlert.audio : ''}
                onChange={handleComboGroupsChange}
                options={groups}
                placeholder={i18('Groups')}
              ></ComboBox>
              {/* <TextField autoFocus margin="dense" id="name" label="Email Address" type="email" fullWidth /> */}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseGroup} color="primary">
                {i18('Cancel')}
              </Button>
              <Button type="submit" color="primary">
                {i18('Save')}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
        <Dialog open={openDeleteGroup} onClose={handleCloseDeleteGroup} aria-labelledby="form-dialog-title">
          <form onSubmit={handleSubmitDelete(onSubmitDelete)}>
            <DialogTitle id="form-dialog-title">{i18('Delete_GROUP')}</DialogTitle>
            <DialogContent>
              <DialogContentText>Select group to delete. Also, sub groups will be deleted</DialogContentText>
              <ComboBox
                label={i18('GRoups')}
                icon={FormNameIcon}
                name="groupDelete"
                defaultValue={''}
                // defaultValue={isPreAlertEnabled ? preAlert.audio : ''}
                onChange={handleComboDeleteGroupsChange}
                options={groups}
                placeholder={i18('Groups')}
              ></ComboBox>
              {/* <TextField autoFocus margin="dense" id="name" label="Email Address" type="email" fullWidth /> */}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDeleteGroup} color="primary">
                {i18('Cancel')}
              </Button>
              <Button type="submit" color="primary">
                {i18('Delete')}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </div>
    </div>
  );
}
