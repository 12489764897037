import React, { createContext, useContext, useState } from 'react';
import { menu } from '../components/Navigator/MainMenu/menu';
import useLocalStorage from '../../../hooks/useLocalStorage';
import useLocalStorageState from '../../../hooks/usePersistedLocalStorage';
// import { useIntl} from '../../lang/context/intlContext'

const initValues = { menuData: undefined, setMenuData: undefined };

//creamo el contexto
const LayoutUIContext = createContext(initValues);

//funcion para ser usada dentro de los hijos del Provider, y que va a devolver todo lo
//establecido en el value, es decir, obtiene las propiedades del contexto
export function useLayoutUIContext() {
  return useContext(LayoutUIContext);
}

//exporta un consumer para poder setear las funciones y valores.
//utilizado para darle 'vida' al contexto
export const LayoutUIConsumer = LayoutUIContext.Consumer;

//aqui preparamos las funciones que van a dar vida al contexto
//tambien se exporta el Componente que va a envolver al tree
//de componentes que van a consumer el contexto
//NO SEPARAR, a menos que crezca mucho
export function LayoutUIProvider({ children }) {
  const [subMenu, setSubMenu] = useLocalStorageState('subMenu', []);
  const [menuData, setMenuData] = useLocalStorageState('menu', menu);
  const [titles, setTitles] = useState({});
  //   const {i18} = useIntl();
  // const i18Menu = menu.map(item=>item.)

  const value = {
    menuData,
    setMenuData,
    subMenu,
    setSubMenu,
    titles,
    setTitles,
  };
  return <LayoutUIContext.Provider value={value}>{children}</LayoutUIContext.Provider>;
}
