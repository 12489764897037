//@ts-nocheck
import { action, computed } from 'easy-peasy';

const initValue = { drivers: [] };

const driver = {
  ...initValue,

  addDrivers: action((state, data) => {
    state.drivers = data.map((driver) => ({ ...driver, value: driver.driverId.toString(), text: driver.name + ' ' + driver.lastname + ' (' + driver.driverId + ')' }));
  }),

  clearDrivers: action((state) => (state.drivers = [])),
  getDriver: computed((state) => (id) => state.drivers.find((item) => item.driverId === id)),
  deleteDriver: action((state, driverId) => {
    state.drivers = state.drivers.filter((item) => item.driverId !== +driverId);
  }),
};

export default driver;
