//@ts-nocheck
import { action, computed } from 'easy-peasy';

// assets used in admin modules
// assets used in combo list

const initValue = { assets: [], tablets: [], DVRS: [] };

const asset = {
  ...initValue,

  addAssets: action((state, data) => (state.assets = data)),
  addTablets: action((state, data) => (state.tablets = data)),
  addDVRS: action((state, data) => (state.DVRS = data)),
  clearAssets: action((state) => (state.assets = [])),
  getAssets: computed((state) => (id) => state.assets.find((item) => item.nfcId === id)),
  deleteAssets: action((state, deviceAgentId) => {
    state.assets = state.assets.filter((item) => item.deviceAgentId !== +deviceAgentId);
  }),
};

export default asset;
