import React from 'react';
import {
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Autocomplete } from '@material-ui/lab';
import Button from '../../../components/CustomButton/Button';

const AddUsers = ({
  openUsers,
  handleCloseUsers,
  i18,
  users,
  selectedRoleUsers,
  setnewRoleUsers,
  onClickAddUsers,
  isLoading = false,
  classes,
}) => {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  return (
    <Dialog open={openUsers} onClose={handleCloseUsers} aria-labelledby="form-dialog-title" disableBackdropClick={true}>
      <DialogTitle id="form-dialog-title">{i18('Add_USERS')}</DialogTitle>
      <DialogContent style={{ minHeight: '300px' }}>
        <DialogContentText>{i18('Add_users_CONTENT')}</DialogContentText>
        <Autocomplete
          multiple
          id="checkboxes-tags-demo"
          options={(() => users.filter((item) => !selectedRoleUsers.find((item2) => item2.userId === item.userId)))()}
          size={'medium'}
          disableCloseOnSelect
          getOptionLabel={(option) => option.userName}
          onChange={(prev, newValue) => setnewRoleUsers(newValue)}
          renderOption={(option, { selected }) => (
            <React.Fragment>
              <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
              {option.userName + ' - ' + option.clientName}
            </React.Fragment>
          )}
          style={{ width: 500 }}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label={i18('users')} placeholder={i18('SELECTED_USERS')} />
          )}
        />
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-around' }}>
        <Button onClick={handleCloseUsers} color="primary">
          {i18('Cancel')}
        </Button>
        <div className={classes.inDiv}>
          <Button onClick={onClickAddUsers} color="primary" disabled={isLoading} variant="contained">
            {i18('Save')}
          </Button>
          {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default AddUsers;
