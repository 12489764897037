import axios from 'axios';
const GET_ALL_MASTER_MFIS_EVENT_TYPES = '/events/getMasterMFISEventTypes';
const GET_ALL_USER_MFIS_EVENT = '/events/getUsersMFISEvents';
const GET_ALL_USER_MFIS_EVENT_TABLE = '/events/getUsersMFISEventsTable';
const GET_ALL_USER_MFIS_EVENT_TABLE_STATUS = '/events/getMFISEventTabletStatusAll';
const GET_ALL_USER_MFIS_CONFIGURATION_ALL = '/events/getMFISConfigurationAll';
const GET_GRID_TABLETS = '/assets/getGridTablets';

export function getAllMasterMFISEvents() {
  return axios.get(`${GET_ALL_MASTER_MFIS_EVENT_TYPES}`);
}
export function getUsersMFISEvents() {
  return axios.get(`${GET_ALL_USER_MFIS_EVENT}`);
}
export function getUsersMFISEventsTable() {
  return axios.get(`${GET_ALL_USER_MFIS_EVENT_TABLE}`);
}

export function getUserMFISEventTabletStatusAll() {
  return axios.get(`${GET_ALL_USER_MFIS_EVENT_TABLE_STATUS}`);
}
export function getUserMFISConfigurationAll() {
  return axios.get(`${GET_ALL_USER_MFIS_CONFIGURATION_ALL}`);
}
export function getGridTablets() {
  return axios.get(`${GET_GRID_TABLETS}`);
}
