import { makeStyles } from '@material-ui/core/styles';
import { GRAY_COLOR, whiteColor } from '../../components/styles/constValues';

export const macroStyles = makeStyles((theme) => ({
  root: {
    boxShadow: '0 10px 20px 0 rgba(92, 116, 129, 0.08)',
    borderRadius: '8px',
    minWidth: theme.spacing(35),
    borderTopStyle: 'solid',
    borderTopColor: whiteColor,
    paddingBottom: '3px',
    transition: '0.05s',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      borderTopColor: theme.palette.primary.main,
    },
  },
  rootSelected: { backgroundColor: theme.palette.primary.main },
  rootHover: {
    margin: '3px',
    marginTop: theme.spacing(0),
    marginBottom: '3px',
    backgroundColor: whiteColor,
    borderRadius: '8px',
    height: '100%',
  },
  defaultAlign: {
    alignItems: 'flex-start',
    textAlign: 'left',
    '&.MuiCardHeader-content': { width: '100%' },
  },
  avatar: {
    borderRadius: '8px',
    backgroundColor: whiteColor,
    marginRight: '0px',
    marginTop: '1px',
  },
  contentRoot: {
    paddingTop: theme.spacing(0),
  },
  contentSwitch: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  contentButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    borderTopStyle: 'solid',
    // borderTopWidth: theme.spacing(1),
    borderTopColor: GRAY_COLOR,
    borderTopWidth: 'thin',
    // borderRadius: '0 8 0 0',
    borderTopLeftRadius: '0',
    borderTopRightRadius: '0',
    paddingTop: theme.spacing(2),
    // marginRight: '8px',
  },
  listItemAvatarIconBlue: { fill: theme.palette.primary.main },
  // listItemAvatarIconYellow: { fill: theme.palette.primary.main },
  listItemAvatarRoot: { minWidth: theme.spacing(0) },
  listItemAvatarWrap: { height: '20px', width: '20px', backgroundColor: whiteColor },
  listItemAvatarSize: { width: '18px', height: '18px' },
  listItemAvatarSizePrimary: { width: '18px', height: '18px', fill: theme.palette.primary.main },
  listItemAvatarSizeSecondary: { width: '18px', height: '18px', fill: theme.palette.secondary.main },
  listItemGutters: { paddingLeft: theme.spacing(0), paddingRight: theme.spacing(0) },
  listItemText: {
    fontSize: '14px',
    fontFamily: 'Roboto',
    marginLeft: theme.spacing(1),
    color: theme.palette.text.primary,
    letterSpacing: '0.42px',
  },

  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  paper: {
    width: '80%',
    maxHeight: 435,
  },
  paperMula: {
    width: '100%',
    maxHeight: 800,
  },
}));
