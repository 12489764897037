import React, { useState, useEffect } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import { makeStyles } from '@material-ui/core/styles';
import { CardContent, Typography } from '@material-ui/core';

import { useForm } from 'react-hook-form';
import { useIntl } from '../../lang/context/intlContext';

import useAxios from 'axios-hooks';
import { TableProvider } from '../../components/Table/TableContext';
import TablePaper from '../../components/Table/TablePaper';
import { getOptionGroups } from '../../components/Table/helper';
import { useStoreState, useStoreActions } from 'easy-peasy';
import Splash, { Loading } from '../../../Splash';
import { v4 as guid } from 'uuid';
import { GET_ALL_ASSETS_LNP } from '../mfis/spokenRoute/api/getMethods';

const useStyleGrid = makeStyles((theme) => ({
  content: {
    display: 'flex',
    justifyContent: 'center',
    height: theme.spacing(45),
  },
  alert: { margin: theme.spacing(2) },
}));

function Assets({ maxHeight, onSelectedItems }) {
  const { i18 } = useIntl();
  const [apiError, setApiError] = useState(false);
  const [tableApi, setTableApi] = useState(null);
  const { register, handleSubmit, errors } = useForm();
  const classes = useStyleGrid();
  const { addAssets } = useStoreActions((actions) => actions.map);
  const { assets, checkedItems } = useStoreState((state) => ({ assets: state.map.assets, checkedItems: state.map.checkedItems }));

  const [{ data: getData, loading: getLoading, error: getError }, getAssets] = useAxios(GET_ALL_ASSETS_LNP, { manual: true });

  //#region Effects
  useEffect(() => {
    if (!assets.length) {
      getAssets();
    } else console.log('there asses in cache');
  }, [assets]);

  useEffect(() => {
    if (getLoading) return;
    if (getData) {
      addAssets(getData.data);
    }
  }, [getData, getLoading]);

  //#endregion

  //#region Error and Redirect

  if (getLoading || !assets.length)
    return (
      <GridContainer className={classes.content}>
        <GridItem item xs={12} md={12} lg={12}>
          <Loading></Loading>
          {/* <Splash /> */}
        </GridItem>{' '}
      </GridContainer>
    );
  //#endregion

  //#region TableDefinitions
  // const { data: dataTablets } = getData;
  const headCellsTablets = [
    {
      id: 'rootField',
      numeric: false,
      align: 'left',
      disablePadding: true,
      label: i18('GROUP/SUBGROUP/ASSET'),
      width: '200px',
      defaultOrder: true,
    },
    // { id: 'plates', numeric: true, align: 'left', disablePadding: false, label: i18('Plate'), width: '130px' },
    { id: 'serial', numeric: true, align: 'left', disablePadding: false, label: i18('device'), width: '50px' },
    { id: 'vehicleName', numeric: true, align: 'left', disablePadding: false, label: i18('Name'), width: '120px' },
    { id: 'vehicleModel', numeric: true, align: 'left', disablePadding: false, label: i18('vehicleModel'), width: '130px' },
    { id: 'deviceModel', numeric: true, align: 'left', disablePadding: false, label: i18('model_GPS'), width: '100px' },
    { id: 'gpsDateTime', numeric: true, align: 'left', disablePadding: false, label: i18('gpsDateTime'), width: '180px' },
    { id: 'rcvDateTime', numeric: true, align: 'left', disablePadding: false, label: i18('rcvDateTime'), width: '180px' },
    { id: 'event', numeric: true, align: 'left', disablePadding: false, label: i18('eventName'), width: '100px' },
    { id: 'gpsSpeed', numeric: true, align: 'left', disablePadding: false, label: i18('gpsSpeed'), width: '50px' },
    { id: 'client', numeric: true, align: 'left', disablePadding: false, label: i18('Client'), width: '250px' },
    { id: 'address', numeric: true, align: 'left', disablePadding: false, label: i18('address'), width: 'auto' },
    // { id: 'address', numeric: true, align: 'left', disablePadding: false, label: i18('address'), width: 'auto' },
  ];
  const optionsGroup = getOptionGroups(assets, true);

  const filterInputs = [{ label: 'name', field: 'rootField' }];

  function onTableReady(params) {
    const { api } = params;
    onSelectedItems(api);
  }

  //#endregion
  const onSubmit = () => {};
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <GridContainer className={classes.content}>
          <GridItem item xs={12} md={12} lg={12}>
            <CardContent style={{ width: '100%' }}>
              <GridContainer direction="column" spacing={2} style={{ paddingTop: '0px' }}>
                <GridItem item xs={12} md={12}>
                  <TableProvider loadData={assets} columns={headCellsTablets} withPagination={true} title={i18('Assets')} checkedItems={checkedItems} initRowsPerPage={50} onContextReady={onTableReady} useV2={true}>
                    <TablePaper maxHeight={maxHeight} filterInputs={filterInputs} hasCheckBox={true} hasGroups={true} optionsGroup={optionsGroup} hasSearchAllFields={true}></TablePaper>
                  </TableProvider>
                </GridItem>
              </GridContainer>
            </CardContent>
          </GridItem>
        </GridContainer>
      </form>
    </div>
  );
}

export default Assets;
