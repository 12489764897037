import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';

import { useIntl } from '../../../lang/context/intlContext';
import { Loading } from '../../../../Splash';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import { TableProvider } from '../../../components/Table/TableContext';
import TablePaper from '../../../components/Table/TablePaper';
import PortletIcon from '../../../components/Portlet/PortletIcon';
import { getAllDevices } from '../api/getMethods';
import { postDetachedDevice } from '../api/postMethods';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { ActionEdit, ActionRemove } from '../../../../assets/icons';

const useStyleGrid = makeStyles((theme) => ({
  proto: { maxHeight: 'calc(100vh-600px)' },
  content: {
    display: 'flex',
    justifyContent: 'center',
    height: theme.spacing(45),
  },
  alert: { margin: theme.spacing(2) },
}));

const Devices = ({ history, match }) => {
  const { i18 } = useIntl();
  const classes = useStyleGrid();
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(true);
  const { deviceId = 0 } = match.params;
  const filterInputs = [{ label: 'serial', field: 'serial' }];
  //#region store
  const addDevices = useStoreActions((actions) => actions.device.addDevices);
  const deleteDevice = useStoreActions((actions) => actions.device.deleteDevice);
  const devices = useStoreState((state) => state.device.devices);
  //#endregion

  const handleClose = async (yesOption) => {
    try {
      if (yesOption) {
        await postDetachedDevice(deviceId);
        deleteDevice(deviceId);
      }
      setOpen(false);
    } catch (error) {}
  };

  const onTableReady = () => {};

  const fetchData = async () => {
    const res = await getAllDevices();
    addDevices(res.data.devices);

    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  //#region Table and Definitions
  const headVehicleTable = [
    {
      id: 'serial',
      numeric: true,
      align: 'left',
      disablePadding: false,
      label: i18('SERIAL'),
      width: '50px',
      defaultOrder: true,
    },
    { id: 'imei', numeric: true, align: 'left', disablePadding: false, label: i18('IMEI'), width: '50px' },
    { id: 'MAC', numeric: true, align: 'left', disablePadding: false, label: i18('MAC'), width: '50px' },
    { id: 'deviceTypeMake', numeric: true, align: 'left', disablePadding: false, label: i18('BRAND'), width: '70px' },
    { id: 'deviceTypeName', numeric: false, align: 'left', disablePadding: false, label: i18('MODEL'), width: '70px' },
    { id: 'clientName', numeric: true, align: 'left', disablePadding: false, label: i18('client'), width: '150px' },
    { id: 'tpsName', numeric: false, align: 'left', disablePadding: false, label: i18('TSP'), width: '70px' },
    { id: 'comments', numeric: false, align: 'left', disablePadding: false, label: i18('COMMENTS'), width: '150px' },
    { id: 'createdBy', numeric: false, align: 'left', disablePadding: false, label: i18('CREATED_BY'), width: '165px' },
    { id: 'created_at', numeric: false, align: 'left', disablePadding: false, label: i18('CREATED_AT'), width: '165px' },
    { id: 'actions', numeric: true, align: 'left', disablePadding: false, label: i18('actions'), width: '100px' },
  ];

  const ActionsRender = useCallback((keyCellId) => {
    return (
      <>
        <ActionEdit title={i18('EDIT')} onClick={() => history.push(`/administration/devices/${keyCellId.split('|')[1]}/edit`)} />
        <ActionRemove title={i18('DETACHED_DEVICE')} onClick={() => history.push(`/administration/devices/management/${keyCellId.split('|')[1]}/detached`)} />
      </>
    );
  }, []);

  if (isLoading)
    return (
      <GridContainer className={classes.content}>
        <GridItem item xs={12} md={12} lg={12}>
          <Loading></Loading>
        </GridItem>
      </GridContainer>
    );

  //#endregion
  return (
    <div>
      <GridContainer className={classes.content}>
        <GridItem item xs={12} md={12} lg={12}>
          <PortletIcon hasTitle={true} title={i18('DEVICES')} subTitle={i18('LIST_OF_DEVICES')} withIcon={false} hasCustomStartIcon={false} StartIcon={PermIdentityIcon} withButton buttonTitle={i18('NEW_DEVICE')} onClickButton={() => history.push('/administration/devices/new')}>
            <CardContent style={{ paddingTop: '0px' }}>
              <GridContainer direction="column" spacing={2}>
                <GridItem item xs={12} md={12}>
                  <TableProvider loadData={devices} columns={headVehicleTable} checkedItems={[]} initRowsPerPage={50} withPagination={true} onContextReady={onTableReady} useV2={true} actions={ActionsRender}>
                    <TablePaper maxHeight={'450px'} filterInputs={filterInputs} hasCheckBox={false} hasGroups={false} hasSearchAllFields={true}></TablePaper>
                  </TableProvider>
                </GridItem>
              </GridContainer>
            </CardContent>
            <></>
          </PortletIcon>
        </GridItem>
      </GridContainer>
      {deviceId > 0 && (
        <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" disableBackdropClick disableEscapeKeyDown>
          <DialogTitle id="alert-dialog-title">{i18('DETACHED_DEVICE')}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{i18('CONF_DELETE_DEVICE')}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleClose(false)} color="primary">
              {i18('NOCancel')}
            </Button>
            <Button onClick={() => handleClose(true)} color="primary" autoFocus>
              {i18('YesDELETE')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};
export default Devices;
