//#region Imports
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import InputText from '../../../../components/InputText/InputText';
import { FormDescriptionIcon, FormNameIcon } from '../../../../../assets/icons/formIcons';

import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { useIntl } from '../../../../lang/context/intlContext';
import CheckboxList from '../../../../components/CustomCheckList/CheckList';
//#endregion
const schema = yup.object().shape({
  name: yup.string().required(),
  tag: yup.string().min(0).max(5),
  // age: yup.number().positive().integer().required(),
});

function CreateMacro({ openGroup, setOpenGroup, macros, onOk }) {
  const { register, handleSubmit, setValue, errors } = useForm({ resolver: yupResolver(schema) });
  const [checked, setChecked] = React.useState([0]);
  const [isDisabledOk, setIsDisabledOk] = React.useState(true);

  const { i18 } = useIntl();
  const onSelectItem = (selectedItems) => {
    setChecked(selectedItems);
    setIsDisabledOk(!selectedItems.length);
  };
  const onSubmit = (data) => {
    console.info({ data });
    if (!data.name.length) {
      errors.name = 'NameReq';
      return;
    }
    setOpenGroup(false);
    onOk({ ...data, nextMacros: checked });
  };
  return (
    <Dialog open={openGroup} onClose={() => setOpenGroup(false)} aria-labelledby="form-dialog-title">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="form-dialog-title">{i18('CREATE_MACRO')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{i18('CREATE_MACRO_DETAILS')}</DialogContentText>
          <InputText
            id="name"
            placeholder={i18('name') + ' (' + i18('required') + ')'}
            name="name"
            label={i18('name')}
            icon={FormNameIcon}
            inputRef={register({ required: true })}
            fullWidth
          ></InputText>
          {errors.name && <Typography color="secondary">{i18('Name_Req')}</Typography>}
          <InputText
            id="description"
            name="description"
            label={i18('Description')}
            placeholder={i18('description') + ' (' + i18('optional') + ')'}
            inputRef={register({ required: false })}
            icon={FormDescriptionIcon}
            multiline
            rows={4}
          ></InputText>
          <InputText
            id="tag"
            name="tag"
            label={i18('tag')}
            placeholder={i18('tag') + ' (' + i18('optional') + ')'}
            inputRef={register({ required: false })}
            icon={FormNameIcon}
          ></InputText>
          {errors.tag && <Typography color="secondary">{i18('TAG_MAX')}</Typography>}
          <Typography style={{ color: '#32295e', paddingTop: '4px' }}>{i18('Select_MACROS')}</Typography>
          <CheckboxList items={macros} onSelectItem={onSelectItem}></CheckboxList>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenGroup(false)} color="primary">
            {i18('Cancel')}
          </Button>
          <Button type="submit" color="primary" disabled={isDisabledOk}>
            {i18('Save')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default CreateMacro;
