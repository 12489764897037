import React, { useState, useEffect } from 'react';
import { useStoreState } from 'easy-peasy';

import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { CardContent, Checkbox, CircularProgress, FormControl, Typography } from '@material-ui/core';
import { compareAsc, differenceInMonths, parseISO } from 'date-fns';

import { postPosition } from '../../api/postMethods';
import { useIntl } from '../../../../lang/context/intlContext';
import { FormNameIcon } from '../../../../../assets/icons/formIcons';
import { useFormStyle } from '../../../../common/styles/formStyle';

import Button from '../../../../components/CustomButton/Button';
import GridContainer from '../../../../components/Grid/GridContainer';
import GridItem from '../../../../components/Grid/GridItem';
import InputTimeDate from '../../../../components/InputTimeDate/InputTimeDate';
import Portlet from '../../../../components/Portlet/Portlet';
import PortletActions from '../../../../components/Portlet/PorletActions';
import AutoCompleteBox from '../../../../components/ComboBox/AutoCompleteBox';
import CustomSwitch from '../../../../components/CustomSwitch/CustomSwitch';
import { Loading } from '../../../../../Splash';
import InputBoxText from '../../../../components/InputText/InputBoxText';
import SelectComboBox from '../../../../components/ComboBox/SelectComboBox';
import CustomizedSnackbars from '../../../../components/CustomSnackBar/CustomSnackBar';
import TableReport from '../../table/TableReport';

import { columnsTable, initFilter, listEvents, optionsSpeedCondition } from './dataPositions';
import { initSnack } from '../../table/stylesReport';

const Positions = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [monthError, setMonthError] = useState(false);
  const [speedError, setSpeedError] = useState(false);
  const [openSnack, setOpenSnack] = React.useState(initSnack);
  const classes = useFormStyle();
  const { i18 } = useIntl();
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;


  // Groups
  const [selectedTabletsGroups, setSelectedTabletsGroups] = useState([]);
  // Plates
  const [selectedTabletsPlates, setSelectedTabletsPlates] = useState([]);
  const [TabletsPlates, setTabletsPlates] = useState([]);
  // Events
  const [selectedTabletsEvent, setSelectedTabletsEvent] = useState([]);
  const [TabletsEvents, setTabletsEvents] = useState([]);
  // Models
  const [selectedTabletsModel, setSelectedTabletsModel] = useState([]);
  const deviceModels = useStoreState((state) => state.user.deviceModels);
  // Function
  const [selectedFunctions, setSelectedFunctions] = useState([]);
  const functions = useStoreState((state) => state.user.functions);
  // Remove stop
  const [stateCheck, setStateCheck] = useState({
    isNext: true,
    label: i18('REMOVER_STOP'),
  });
  // Form
  const [formValues, setFormValues] = useState(initFilter);
  const { speed, speedCondition, startDateTime, endDateTime } = formValues;
  // Groups
  const [columnsPosition, setColumnsPosition] = useState([]);
  const [tablePosition, setTablePosition] = useState([]);
  const TabletsGroups = useStoreState((state) => state.user.groups);
  const companyid = useStoreState((state) => state.user.clientId);


  const [group, setGroup] = useState([]);
  const [plates, setPlates] = useState([]);
  const [eventType, seteventType] = useState([]);
  const [driverNames, setDriverNames] = useState([]);
  const [searchResults, setSearchResults] = useState([]);

  const [garage, setGarage] = useState('');
  const [plate, setPlate] = useState('');
  const [vehicles, setVehicles] = useState('');
  const [driverName, setDriverName] = useState('');
  const [events, setEvent] = useState('');

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const garagesResponse = await fetch('https://api3-ng.tracknow.com/reports/get.php?companyid=' + companyid);
        const garagesData = await garagesResponse.json();
        //console.log(garagesData);
        const group = garagesData.group;
        const eventtype = garagesData.event;
        const driver = garagesData.driver;
        const vehicles = group.vehicles;
        //console.log(vehicles);
        setGroup(group);
        setVehicles(vehicles);
        setDriverNames(driver);
        seteventType(eventtype);

      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchOptions();
  }, []);

  const eventsArray = eventType.map(({ description, eventtypeid }) => ({
    name: description,
    id: eventtypeid,
  }));

  const groupArray = group.map(({ description, vehiclegroupid, vehicles }) => ({
    fullName: description,
    id: vehiclegroupid,
    vehicles: vehicles,
  }));

  //const vehiclesArray = TabletsPlates.map(({ plate, vehicleid }) => ({
  //  name: plate,
  //  id: vehicleid,
  // }));



  const handleSelectChangeEvent = (event, newValue) => {
    setSelectedTabletsEvent(newValue);
  };

  const handleInputChange = ({ target }) => {
    switch (target.name) {
      case 'startDateTime':
        setFormValues({
          ...formValues,
          [target.name]: target.value,
        });
        const months = differenceInMonths(parseISO(endDateTime), parseISO(target.value));
        if (months > 0) {
          setMonthError(true);
        } else {
          setMonthError(false);
        }
        break;
      case 'endDateTime':
        setFormValues({
          ...formValues,
          [target.name]: target.value,
        });
        const result = compareAsc(parseISO(startDateTime), parseISO(target.value));
        if (result > 0) {
          setDateError(true);
        } else {
          setDateError(false);
        }
        const months1 = differenceInMonths(parseISO(target.value), parseISO(startDateTime));
        if (months1 > 0) {
          setMonthError(true);
        } else {
          setMonthError(false);
        }
        break;
      case 'speedCondition' && target.value === '0':
        setFormValues({
          ...formValues,
          speed: 0,
          [target.name]: target.value,
        });
        setSpeedError(false);
        break;
      default:
        setFormValues({
          ...formValues,
          [target.name]: target.value,
        });
        break;
    }
  };

  const handleOnchangeGroups = (selectGroup) => {
    if (selectGroup && selectGroup.length > 0) {
      setSelectedTabletsGroups(selectGroup);
      const listPlates = selectGroup.map((group) => group.vehicles).flat();
      setTabletsPlates(listPlates);
      setSelectedTabletsPlates(listPlates);
    } else {
      console.log("listPlates is null or empty");
    }
  };


  const handleSubmitForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      // validations
      if (!validation()) {
        setIsLoading(false);
        return;
      }
      setTablePosition([]);
      setSpeedError(false);
      setDateError(false);
      setColumnsPosition(
        columnsTable.map((data) => {
          return { ...data, title: i18(data.title) };
        })
      );
      const body = {
        speed: speed,
        speedCondition: speedCondition !== '0' ? speedCondition : 'gto',
        startDateTime: `${startDateTime}:00.000Z`,
        companyid: `${companyid}`,
        endDateTime: `${endDateTime}:00.000Z`,
        limit: 10,
        page: 0,
        //removeStops: stateCheck.isNext,
        vehiclesIds: selectedTabletsPlates.map((TabletsPlates) => TabletsPlates.vehicleid),
        events: selectedTabletsEvent.map((event) => event.eventtypeid),
        driverids: selectedTabletsModel.map((driverNames) => driverNames.driverid),
        groupids: selectedTabletsGroups.map((groupArray) => groupArray.id),
      };
      console.log("SUBMIT BODY; " + JSON.stringify(body));

      await loadPositions(body);
      setIsLoading(false);
    } catch (error) {
      setOpenSnack((prev) => ({
        ...prev,
        open: true,
        title: i18('ERROR_API'),
        severity: 'error',
      }));
    }
  };

  const handleReset = (e) => {
    setFormValues(initFilter);
    setDateError(false);
    setMonthError(false);
    setSpeedError(false);
    setTabletsPlates([]);
    setSelectedTabletsPlates([]);
    setSelectedTabletsGroups([]);
    setSelectedTabletsEvent([]);
    setSelectedTabletsModel([]);
    setSelectedFunctions([]);
    setStateCheck({ isNext: true, label: i18('REMOVER_STOP') });
  };

  const fetchData = async () => {
    setIsLoading(true);
    setTabletsEvents(events);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const validation = () => {
    let error = false;
    // speed
    if (speedCondition !== '0' && speed <= 0) {
      setSpeedError(true);
      error = true;
    }
    // Date
    const result = compareAsc(parseISO(startDateTime), parseISO(endDateTime));
    const months = differenceInMonths(parseISO(endDateTime), parseISO(startDateTime));
    if (result > 0) {
      setDateError(true);
      error = true;
    } else if (months > 0) {
      setMonthError(true);
      error = true;
    }
    if (error) return;
    return true;
  };

  const loadPositions = async (parameters) => {
    try {
      console.log("EVENTS-BODY; " + JSON.stringify(parameters));
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(parameters)
      };
      const response = await fetch("https://api3-ng.tracknow.com/reports/telemetryresult.php?companyid=" + parameters.companyid + "&eventid=" + parameters.events + "&driverid=" + parameters.driverids + "&vehicleid=" + parameters.vehiclesIds + "&vehiclegroupid=" + parameters.groupids + "&startdate=" + startDateTime + "&enddate=" + endDateTime);
      //const response = await fetch("http://api3-ng.tracknow.com/reports/telemetryresult.php?companyid=361&eventid=&driverid=&plateid=&vehiclegroupid=8&startdate=" + startDateTime + "&enddate=" + endDateTime, requestOptions);
      const data = await response.json();
      setTablePosition(data.positions);
    } catch (error) {
      setOpenSnack((prev) => ({
        ...prev,
        open: true,
        title: i18('ERROR_API'),
        severity: 'error',
      }));
    }
  };
  //console.log("TabletsPlates--->" + JSON.stringify(TabletsPlates));
  return (
    <div>
      <form onSubmit={handleSubmitForm}>
        <GridContainer className={classes.content}>
          <GridItem item xs={12} md={12} lg={12} className={classes.content}>
            <Portlet className={classes.formStyle}>
              <CardContent>
                <div>
                  <GridContainer justify="center" spacing={4}>
                    <GridItem justify="center" container>
                      <GridItem item xs={4} md={2} lg={2}>
                        <AutoCompleteBox
                          placeholder="Filter for Group"
                          multiple
                          label={i18('GROUPS')}
                          id="checkboxes-tags-demo"
                          //options={TabletsGroups}
                          options={groupArray}
                          value={selectedTabletsGroups}
                          disableCloseOnSelect
                          getOptionLabel={(option) => option.fullName}
                          //getOptionLabel={(option) => option.name}
                          onChange={(prev, newValue) => setSelectedTabletsGroups(newValue)}
                          limitTags={10}
                          renderOption={(option, { selected }) => (
                            <React.Fragment>
                              <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                              {option.fullName}
                            </React.Fragment>
                          )}
                          style={{ marginRight: 15 }}
                        />
                      </GridItem>
                      <GridItem item xs={4} md={5} lg={4}>
                        <AutoCompleteBox
                          label={i18('VEHICLES')}
                          multiple
                          id="checkboxes-tags-demo"
                          options={TabletsPlates}
                          disableCloseOnSelect
                          value={selectedTabletsPlates}
                          getOptionLabel={(option) => option.plate}
                          onChange={(prev, newValue) => setSelectedTabletsPlates(newValue)}
                          limitTags={10}
                          noOptionsText={i18('SELECT_A_GROUP')}
                          renderOption={(option, { selected }) => (
                            <React.Fragment>
                              <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                              {option.plate}
                            </React.Fragment>
                          )}
                          style={{
                            maxHeight: '230px',
                            marginRight: 15,
                          }}
                        />
                      </GridItem>
                      <GridItem item xs={4} md={2} lg={2}>
                        <AutoCompleteBox
                          label={i18('EVENTS')}
                          multiple
                          id="checkboxes-tags-demo"
                          options={eventType}
                          value={selectedTabletsEvent}
                          disableCloseOnSelect
                          getOptionLabel={(option) => option.description}
                          onChange={(prev, newValue) => setSelectedTabletsEvent(newValue)}
                          limitTags={10}
                          renderOption={(option, { selected }) => (
                            <React.Fragment>
                              <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                              {option.description}
                            </React.Fragment>
                          )}
                          style={{ marginRight: 15 }}
                        />
                      </GridItem>
                      <GridItem item xs={4} md={3} lg={2}>
                        <AutoCompleteBox
                          label={i18('DRIVERS')}
                          multiple
                          id="checkboxes-tags-demo"
                          options={driverNames}
                          value={selectedTabletsModel}
                          disableCloseOnSelect
                          getOptionLabel={(option) => option.fullname}
                          onChange={(prev, newValue) => setSelectedTabletsModel(newValue)}
                          limitTags={8}
                          renderOption={(option, { selected }) => (
                            <React.Fragment>
                              <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                              {option.fullname}
                            </React.Fragment>
                          )}
                          style={{
                            maxHeight: '230px',
                            marginRight: 15,
                          }}
                        />
                      </GridItem>
                    </GridItem>
                    <GridItem item md={3} lg={3}>
                      <InputTimeDate
                        id="startDateTime"
                        name="startDateTime"
                        label={i18('START_DATE_TIME')}
                        // style={{ paddingRight: '0px' }}
                        icon={FormNameIcon}
                        value={startDateTime}
                        onChange={handleInputChange}
                      />
                    </GridItem>
                    <GridItem item md={3} lg={3}>
                      <InputTimeDate id="endDateTime" name="endDateTime" label={i18('END_DATE_TIME')} icon={FormNameIcon} value={endDateTime} onChange={handleInputChange} />
                      {dateError && <Typography color="secondary">{i18('ERROR_DATE')}</Typography>}
                      {monthError && <Typography color="secondary">{i18('ERROR_MONTH')}</Typography>}
                    </GridItem>


                    <GridItem item md={3} lg={3}>
                      <div className={classes.div}>
                        <div className={classes.inDiv}>
                          <Button onClick={handleReset} type="button" variant="contained" color={'cancel'}>
                            {i18('CLEAR')}
                          </Button>
                        </div>
                        <div className={classes.inDiv}>
                          <Button type="submit" variant="contained" color={'primary'} disabled={isLoading}>
                            {i18('SEARCH')}
                          </Button>
                          {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                      </div>
                    </GridItem>
                  </GridContainer>

                  {isLoading ? <Loading /> : columnsPosition.length > 0 && <TableReport columns={columnsPosition} dataTable={tablePosition} />}
                  <CustomizedSnackbars open={openSnack.open} setOpen={setOpenSnack} title={openSnack.title} severity={openSnack.severity}></CustomizedSnackbars>
                </div>
              </CardContent>
              <PortletActions></PortletActions>
            </Portlet>
          </GridItem>
        </GridContainer>
      </form>
    </div>
  );
};
export default Positions;
