//#region imports
import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
//#region MUI
import { CardContent, CircularProgress, Typography } from '@material-ui/core';
//#endregion

//utils
import { useIntl } from '../../../lang/context/intlContext'; //addjust path reference
import { useForm } from 'react-hook-form';
//#region components

import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import Portlet from '../../../components/Portlet/Portlet';
import InputText from '../../../components/InputText/InputText';
import PortletActions from '../../../components/Portlet/PorletActions';
import Button from '../../../components/CustomButton/Button';
import { FormNameIcon, FormDescriptionIcon } from '../../../../assets/icons/formIcons';
//#endregion

//ApiMedhots
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';

//state
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useFormStyle } from '../../../common/styles/formStyle';
import ComboBox from '../../../components/ComboBox/ComboBox';
import { isValid } from './cpf';
import { Alert } from '@material-ui/lab';
import CustomizedSnackbars from '../../../components/CustomSnackBar/CustomSnackBar';

import { postAddDriver, postUpdateDriver } from '../api/postMethods';
import { driversType } from './helper';
//#endregion
//these styles are used in Add Rotograma Falado and Here

const schema = yup.object().shape({
  cpf: yup.string().required(),
  name: yup.string().required(),
  lastName: yup.string().required(),
  password: yup.string().required(),
  emergencyPassword: yup.string().required(),
});

const garages = [
  { value: '1', text: 'Principal' },
  { value: '2', text: 'Secundario' },
];

const driverValidation = (data) => {
  //   return {};
  const errors = {};
  const { cpf, password, confirmPassword, emergencyPassword, confirmEmergencyPassword } = data;
  //allow blank cpf
  if (!isValid(cpf)) errors.cpf = true;
  //password
  if (password !== confirmPassword) errors.validPassword = true;
  console.log('password', password);
  console.log('confirmPassword', confirmPassword);
  //emergencypassword
  if (emergencyPassword !== confirmEmergencyPassword) errors.validEmergencyPassword = true;

  return errors;
};
//todo:validate cpf DONE
//todo: validate rg
//todo: load clients
//todo: define drivers type DONE
//todo: show default garage DONE
//todo: format password
//todo: validate password

const New = ({ history, match }) => {
  const [isLoading, setisLoading] = useState(false);
  const [validErrors, setValidErros] = useState({});
  const [openSnack, setOpenSnack] = React.useState({ open: false, title: '', severity: 'success' });
  const classes = useFormStyle();
  const { i18 } = useIntl();
  const { driverId = 0 } = match.params;
  console.log('driverId', driverId);
  const modePage = driverId > 0 ? 'EDIT' : 'NEW';

  const { register, handleSubmit, errors, setValue } = useForm({ resolver: yupResolver(schema) });
  //#region easypeasy
  const setNoButtons = useStoreActions((actions) => actions.globalState.setNoButtons);
  const setPrimaryButton = useStoreActions((actions) => actions.globalState.setPrimaryButton);
  const createdNew = useStoreActions((actions) => actions.journeyControl.createdNew);
  const { clientId, clientName } = useStoreState((state) => state.user);
  const drivers = useStoreState((state) => state.driver.drivers);
  const driver = modePage === 'EDIT' ? drivers.find((item) => item.driverId === +driverId) : undefined;
  console.log('driver', driver);
  //#endregion

  useEffect(() => {
    register({ name: 'client' }, { required: false });
    setValue('client', driver ? driver.clientId.toString() : clientId);
    register({ name: 'garage' }, { required: false });
    setValue('garage', driver ? driver.garageId.toString() : '1');
    register({ name: 'type' }, { required: false });
    setValue('type', driver ? driver.typeDriver.toString() : '0');

    setPrimaryButton({
      text: i18('BACK'),
      isEnabled: true,
      fnc: () => {
        history.push(`/administration/drivers/management`);
      },
    });
    return () => setNoButtons();
  }, []);
  const handleComboChange = (id) => {
    return (e) =>
      setValue(id, e.target.value, {
        shouldValidate: true,
        shouldDirty: true,
      });
  };
  const onSubmit = async (datos) => {
    try {
      setisLoading(true);
      const validation = driverValidation(datos);
      setValidErros(validation);
      const { name, lastName, cpf, rg, cnh, funcNumber, celullar, garage, password, emergencyPassword, type } = datos;
      let res = {};
      if (modePage === 'NEW') {
        res = await postAddDriver({
          name,
          lastName,
          cpf,
          rg,
          cnh,
          funcNumber,
          celullar,
          garage,
          password,
          emergencyPassword,
          type,
        });
      }

      if (modePage === 'EDIT') {
        res = await postUpdateDriver({
          driverId,
          name,
          lastName,
          cpf,
          rg,
          cnh,
          funcNumber,
          celullar,
          garage,
          password,
          emergencyPassword,
          type,
        });
      }
      const { data } = res;
      if (data.error !== 0) setOpenSnack((prev) => ({ ...prev, open: true, title: i18('ERROR_API'), severity: 'error' }));
      setisLoading(false);
      history.push(`/administration/drivers/management`);
    } catch (error) {
      setisLoading(false);
      setOpenSnack((prev) => ({ ...prev, open: true, title: i18('ERROR_API'), severity: 'error' }));
    }
  };
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <GridContainer className={classes.content}>
          <GridItem item xs={12} md={9} lg={9} className={classes.content}>
            <Portlet className={classes.formStyle} title={modePage === 'NEW' ? i18('NEW_DRIVER') : i18('EDIT_DRIVER')} subTitle={i18('CREATE_SUBHEADER_JC')}>
              <CardContent>
                <GridContainer spacing={2}>
                  <GridItem container>
                    <GridItem item xs={12} md={4} lg={4}>
                      <InputText id="driverId" name="driverId" defaultValue={driver?.driverId} readOnly={true} label={i18('DRIVER_ID')} icon={FormNameIcon} fullWidth></InputText>
                    </GridItem>
                    <GridItem item xs={12} md={4} lg={4}>
                      <InputText
                        id="name"
                        placeholder={i18('name') + ' (' + i18('required') + ')'}
                        defaultValue={driver?.name}
                        name="name"
                        label={i18('name')}
                        icon={FormNameIcon}
                        inputRef={register({ required: true })}
                        fullWidth
                      ></InputText>
                      {errors.name && <Typography color="secondary">{i18('Name_Req')}</Typography>}
                    </GridItem>
                    <GridItem item md={4} lg={4}>
                      <InputText
                        id="lastName"
                        name="lastName"
                        placeholder={i18('lastName') + ' (' + i18('required') + ')'}
                        defaultValue={driver?.lastname}
                        label={i18('lastname')}
                        inputRef={register({ required: true })}
                        icon={FormNameIcon}
                        fullWidth
                      ></InputText>
                      {errors.lastName && <Typography color="secondary">{i18('lastName_Req')}</Typography>}
                    </GridItem>
                  </GridItem>
                  <GridItem item md={4} lg={4}>
                    <InputText
                      id="cpf"
                      name="cpf"
                      defaultValue={driver?.CPF}
                      //   defaultValue={'181.548.108-08'}
                      label={i18('cpf')}
                      inputRef={register({ required: true })}
                      icon={FormNameIcon}
                      fullWidth
                    ></InputText>
                    {(errors.cpf || validErrors.cpf) && <Typography color="secondary">{i18('Invalid_CPF')}</Typography>}
                  </GridItem>
                  <GridItem item xs={12} md={4} lg={4}>
                    <InputText
                      id="rg"
                      // placeholder={i18('rg') + ' (' + i18('required') + ')'}
                      name="rg"
                      defaultValue={driver?.RG}
                      label={i18('rg')}
                      icon={FormNameIcon}
                      inputRef={register({ required: false })}
                      fullWidth
                    ></InputText>
                    {/* {free && <Typography color="secondary">{`${free} ${i18('NOTAVAI')}`}</Typography>} */}
                    {/* {errors.name && <Typography color="secondary">{i18('Name_Req')}</Typography>} */}
                  </GridItem>
                  <GridItem item md={4} lg={4}>
                    <InputText
                      id="f uncNumber"
                      name="funcNumber"
                      defaultValue={driver?.funcNumber}
                      inputRef={register({ required: false })}
                      label={i18('funcNumber')}
                      icon={FormNameIcon}
                      fullWidth
                    ></InputText>
                  </GridItem>
                  <GridItem item md={4} lg={4}>
                    <InputText id="cnh" defaultValue={driver?.CNH} name="cnh" inputRef={register({ required: false })} label={i18('cnh_numero')} icon={FormNameIcon} fullWidth></InputText>
                  </GridItem>
                  <GridItem item md={4} lg={8}>
                    <InputText
                      id="celullar"
                      name="celullar"
                      inputRef={register({ required: false })}
                      defaultValue={driver?.phoneNumber}
                      label={i18('celullar')}
                      icon={FormNameIcon}
                      fullWidth
                    ></InputText>
                  </GridItem>
                  <GridItem item md={4} lg={4}>
                    <ComboBox
                      label={i18('Client')}
                      icon={FormNameIcon}
                      name="client"
                      defaultValue={driver ? driver?.clientId.toString() : clientId}
                      onChange={handleComboChange('client')}
                      options={[{ value: clientId, text: clientName }]}
                      placeholder={i18('Groups')}
                    ></ComboBox>
                  </GridItem>
                  <GridItem item xs={12} md={4} lg={4}>
                    <ComboBox
                      label={i18('Type')}
                      icon={FormNameIcon}
                      name="type"
                      defaultValue={driver ? driver?.typeDriver.toString() : '0'}
                      onChange={handleComboChange('type')}
                      options={driversType}
                      placeholder={i18('type')}
                    ></ComboBox>
                  </GridItem>
                  <GridItem item md={4} lg={4}>
                    <ComboBox
                      label={i18('garage')}
                      icon={FormNameIcon}
                      name="garage"
                      defaultValue={driver ? driver?.garageId.toString() : '1'}
                      onChange={handleComboChange('garage')}
                      options={garages}
                      placeholder={i18('Groups')}
                    ></ComboBox>
                  </GridItem>
                  <GridItem item xs={12} md={4} lg={4}>
                    <InputText
                      id="password"
                      placeholder={i18('password') + ' (' + i18('required') + ')'}
                      type="password"
                      name="password"
                      defaultValue={driver?.password}
                      label={i18('password')}
                      icon={FormNameIcon}
                      inputRef={register({ required: true })}
                      fullWidth
                    ></InputText>
                    {/* {free && <Typography color="secondary">{`${free} ${i18('NOTAVAI')}`}</Typography>} */}
                    {errors.password && <Typography color="secondary">{i18('PWD_REQ')}</Typography>}
                    {validErrors.validPassword && <Typography color="secondary">{i18('PWD_NOT_MATCH')}</Typography>}
                  </GridItem>
                  <GridItem item md={4} lg={4}>
                    <InputText
                      id="confirmPassword"
                      name="confirmPassword"
                      defaultValue={driver?.password}
                      type="password"
                      placeholder={i18('con_password') + ' (' + i18('required') + ')'}
                      inputRef={register({ required: true })}
                      label={i18('con_password')}
                      icon={FormNameIcon}
                      fullWidth
                    ></InputText>
                    {errors.emergencyPassword && <Typography color="secondary">{i18('EMER_PWD_REQ')}</Typography>}
                    {validErrors.validPassword && <Typography color="secondary">{i18('PWD_NOT_MATCH')}</Typography>}
                  </GridItem>
                  <GridItem item md={4} lg={4}></GridItem>
                  <GridItem item xs={12} md={4} lg={4}>
                    <InputText
                      id="emergencyPassword"
                      placeholder={i18('emergencyPassword') + ' (' + i18('required') + ')'}
                      type="password"
                      name="emergencyPassword"
                      defaultValue={driver?.emergencyPassword}
                      label={i18('emergencyPassword')}
                      icon={FormNameIcon}
                      inputRef={register({ required: true })}
                      fullWidth
                    ></InputText>
                    {/* {free && <Typography color="secondary">{`${free} ${i18('NOTAVAI')}`}</Typography>} */}
                    {errors.password && <Typography color="secondary">{i18('PWD_REQ')}</Typography>}
                    {validErrors.validEmergencyPassword && <Typography color="secondary">{i18('PWD_NOT_MATCH')}</Typography>}
                  </GridItem>
                  <GridItem item md={4} lg={4}>
                    <InputText
                      id="confirmEmergencyPassword"
                      name="confirmEmergencyPassword"
                      type="password"
                      defaultValue={driver?.emergencyPassword}
                      placeholder={i18('conf_emer_password') + ' (' + i18('required') + ')'}
                      inputRef={register({ required: true })}
                      label={i18('conf_emer_password')}
                      icon={FormNameIcon}
                      fullWidth
                    ></InputText>
                    {errors.emergencyPassword && <Typography color="secondary">{i18('EMER_PWD_REQ')}</Typography>}
                    {validErrors.validEmergencyPassword && <Typography color="secondary">{i18('PWD_NOT_MATCH')}</Typography>}
                  </GridItem>
                </GridContainer>
              </CardContent>
              <PortletActions>
                <div className={classes.div}>
                  <div className={classes.inDiv}>
                    <Button variant="contained" disabled={isLoading} color={'cancel'} onClick={() => history.push(`/administration/drivers/management`)}>
                      {i18('Cancel')}
                    </Button>
                  </div>
                  <div className={classes.inDiv}>
                    <Button type="submit" disabled={isLoading} variant="contained" color={'primary'}>
                      {modePage === 'NEW' ? i18('Create') : i18('Save')}
                    </Button>
                    {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </div>
                </div>
              </PortletActions>
            </Portlet>
          </GridItem>
        </GridContainer>
      </form>
      <CustomizedSnackbars open={openSnack.open} setOpen={setOpenSnack} title={openSnack.title} severity={openSnack.severity}></CustomizedSnackbars>
    </div>
  );
};
New.propTypes = {
  // title: PropTypes.string,
  // numSelected: PropTypes.number.isRequired,
  // children: PropTypes.object,
};

New.defaultProps = {
  //icon: null,
};
export default New;
