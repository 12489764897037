import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import CardActions from '@material-ui/core/CardActions';

const useStyles = makeStyles((theme) => ({
  actionStyle: {
    display: 'flex',
    justifyContent: 'space-around',
  },
}));
const PortletActions = (props) => {
  const { className, children, ...rest } = props;
  const classes = useStyles();
  const cardClasses = clsx({
    [classes.actionStyle]: true,
    [className]: className,
    // className,
  });
  return (
    <CardActions {...rest} disableSpacing className={cardClasses}>
      {children}
    </CardActions>
  );
};
export default PortletActions;
