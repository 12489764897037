import React from 'react';
import GridContainer from '../components/Grid/GridContainer';
import GridItem from '../components/Grid/GridItem';
import Button from '../components/CustomButton/Button';
import { makeStyles } from '@material-ui/core/styles';
// import { AlarmaIcon, AlarmaIconBlanco } from '../../assets/icons';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';
// import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
// import Badge from '@material-ui/core/Badge';

// const ICON_COLOR = 'linear-gradient(to bottom, #c5b1f9, #5978e4)';
const white = '#FFFFFF';
const useStyle = makeStyles((theme) => ({
  marginButton: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    color: theme.palette.text.primary,
    letterSpacing: '0.42px',
    fontSize: '14px',
    fontFamily: 'Roboto',
    fontWeight: 'Normal',
  },
  nextButton: {
    // marginBottom: '20px',
  },
  alarmIconStyle: {
    fill: '#5978e4',
    borderRadius: '4px',
  },
  alarmIconStyleSelected: {
    fill: white,
    borderRadius: '4px',
  },
  doneIconStyle: {
    color: '#6be48d',
  },
  badgeStyle: {},
}));

function Home(props) {
  const classes = useStyle();
  return (
    <div>
      <GridContainer direction="column">
        {' '}
        <GridItem item xs={12} md={3} lg={3} className={classes.nextButton}>
          <Button
            variant="contained"
            color="next"
            // seleccionado
            className={classes.marginButton}
            startIcon={<AccountTreeOutlinedIcon className={classes.alarmIconStyle} />}
            // endIcon={<CheckCircleOutlineIcon className={classes.doneIconStyle} />}
          >
            {' '}
            Asociacion de Macros
          </Button>
          {/* <CheckCircleOutlineIcon className={classes.doneIconStyle} /> */}
        </GridItem>
        <GridItem item xs={12} md={3} lg={3} className={classes.nextButton}>
          <Button
            variant="contained"
            color="focus"
            seleccionado
            className={classes.marginButton}
            startIcon={<AccessAlarmIcon className={classes.alarmIconStyleSelected} />}
          >
            Tiempo de Jornada
          </Button>
          {/* <CheckCircleOutlineIcon className={classes.doneIconStyle} /> */}
        </GridItem>
        <GridItem item xs={12} md={3} lg={3} className={classes.nextButton}>
          <Button
            variant="contained"
            color="next"
            className={classes.marginButton}
            startIcon={<AccessAlarmIcon className={classes.alarmIconStyle} />}
          >
            Tiempo Direcion Continua
          </Button>
        </GridItem>
        <GridItem item xs={12} md={3} lg={3} className={classes.nextButton}>
          <Button
            variant="contained"
            color="next"
            className={classes.marginButton}
            startIcon={<AccessAlarmIcon className={classes.alarmIconStyle} />}
          >
            Tiempo de Refeciao
          </Button>
        </GridItem>
        <GridItem item xs={12} md={3} lg={3} className={classes.nextButton}>
          <Button
            variant="contained"
            color="next"
            className={classes.marginButton}
            startIcon={<AccessAlarmIcon className={classes.alarmIconStyle} />}
          >
            Tiempo de Descanso
          </Button>
        </GridItem>
        <GridItem item xs={12} md={3} lg={3} className={classes.nextButton}>
          <Button
            variant="contained"
            color="next"
            className={classes.marginButton}
            startIcon={<AccountTreeOutlinedIcon className={classes.alarmIconStyle} />}
          >
            Flow Map
          </Button>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default Home;
