import dateRange from './getDateRange';
/**
 *
 * @param {string} param
 * @param {array} vehicles
 * @return {string} Returns vehicle selected or first vehicle in user
 */
export const getVehicleFromStore = (param, vehicles) => {
  if (!param) return vehicles[0];
  const vehicle = vehicles.find((vehicle) => vehicle.patente === param);
  /* eslint-disable-next-line*/
  return vehicle ? vehicle : vehicles[0];
};
export const viewPathOptions = ['ultima', 'hoy', 'ayer', '3dias', '7dias', '15dias', '30dias'];

const rangeEvent = (opt) => {
  switch (opt) {
    case 'ultima':
      return 1;
    case 'hoy':
      return 1;
    case 'ayer':
      return 2;
    case '3dias':
      return 6;
    case '7dias':
      return 3;
    case '15dias':
      return 4;
    case '30dias':
      return 5;
    default:
      return 1;
  }
};

export const getRangeEvent = (opt) => dateRange(rangeEvent(opt));
export const googleLink = (position) => {
  return `https://www.google.com/maps/search/?api=1&query=${position.latitude},${position.longitude}`;
};
function formatLatinDate(rawDate) {
  const date = new Date(rawDate);
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // months are zero indexed
  const day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();
  // hourFormatted = hour % 12 || 12, // hour returned in 24 hour format
  const monthFormatted = month < 10 ? '0' + month : month;
  const dayFormatted = day < 10 ? '0' + day : day;

  const hourFormatted = hour < 10 ? '0' + hour : hour;
  const minuteFormatted = minute < 10 ? '0' + minute : minute;
  const secondFormatted = second < 10 ? '0' + second : second;
  // morning = hour < 12 ? 'am' : 'pm';

  return dayFormatted + '/' + monthFormatted + '/' + year + ' ' + hourFormatted + ':' + minuteFormatted + ':' + secondFormatted;
}
export function convertToENVTimeZOne(datetime) {
  const options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: process.env.TIME_ZONE,
  };

  const date = new Date(datetime).toLocaleString('es-PT', options);
  // const rep = formatLatinDate(date);
  // console.log(rep);
  return date;
}
export function dbFormat(date) {
  return (
    date.getUTCFullYear() +
    '-' +
    pad(date.getUTCMonth() + 1) +
    '-' +
    pad(date.getUTCDate()) +
    'T' +
    pad(date.getUTCHours()) +
    ':' +
    pad(date.getUTCMinutes()) +
    ':' +
    pad(date.getUTCSeconds()) +
    '.' +
    (date.getUTCMilliseconds() / 1000).toFixed(3).slice(2, 5) +
    'Z'
  );
}

function pad(number) {
  if (number < 10) {
    return '0' + number;
  }
  return number;
}
