import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import GridItem from '../Grid/GridItem';
import { getIcon } from '../Macro/helper';
import { HOVER_COLOR, whiteColor } from '../styles/constValues';

import { motion } from 'framer-motion';

const nodeStyles = makeStyles((theme) => ({
  paperNextStyle: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    '& > *': {
      margin: theme.spacing(1),
      marginBottom: theme.spacing(0),
      width: theme.spacing(12),
      height: theme.spacing(10),
    },
  },
  cardContentStyle: {
    padding: theme.spacing(0),
  },
  verticalLine: {
    margin: theme.spacing(0),
    width: '1px' /* Line width */,
    backgroundColor: 'black' /* Line color */,
    height: theme.spacing(1) /* Override in-line if you want specific height. */,
    marginLeft: theme.spacing(6),
    float: 'left' /* Causes the line to float to left of content. 
        You can instead use position:absolute or display:inline-block
        if this fits better with your design */,
  },
  listItemAvatarIconBlue: { fill: theme.palette.primary.main },
  listItemAvatarIconWhite: { fill: whiteColor },
  fontNextStyle: {
    font: 'Roboto',
    fontSize: '12px',
    color: 'inherit',
    paddingRight: theme.spacing(4),
  },
}));

export default function NextNodeMacro(props) {
  const { item, showLine = true, selected = false } = props;
  const classes = nodeStyles();

  const iconStyle = clsx({
    [classes.listItemAvatarIconBlue]: true,
    [classes.listItemAvatarIconWhite]: selected,
  });

  const type = 0;
  return (
    <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
      <GridItem item xs={12} md={12} lg={12} className={classes.paperNextStyle}>
        <Card className={classes.paperNextStyle}>
          <CardContent className={classes.cardContentStyle}>
            {getIcon(type, 'primary', iconStyle)}
            <Typography className={classes.fontNextStyle} component="p">
              {item.title}
            </Typography>
          </CardContent>
        </Card>
        {showLine && <div className={classes.verticalLine}></div>}
      </GridItem>
    </motion.div>
  );
}
