import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ListActions from '../../../../components/Actions/ListActions';
import ListConditions from '../../../../components/Conditions/ListConditions';
import { useTheme } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useMacroSettingStyle } from '../../../../common/styles/macroSettingStyle';
import GridContainer from '../../../../components/Grid/GridContainer';
import GridItem from '../../../../components/Grid/GridItem';
import Portlet from '../../../../components/Portlet/Portlet';
import PortletActions from '../../../../components/Portlet/PorletActions';
import { MacroSettings } from '../../../../components/Macro/MacroSettings';
import { useFormStyle } from '../../../../common/styles/formStyle';
import { CircularProgress } from '@material-ui/core';
const items = [
  {
    id: 1,
    name: 'Input1',
    isEnabled: false,
    onValue: true,
    message: '',
    color: 'yellow',
  },
  {
    id: 2,
    name: 'Input2',
    isEnabled: false,
    onValue: true,
    message: '',
    color: 'yellow',
  },
  {
    id: 3,
    name: 'Ignition',
    isEnabled: false,
    onValue: true,
    message: '',
    color: 'green',
  },
];
const items2 = [
  {
    id: 1,
    name: 'Output1',
    label: 'Bloqueio Motor',
    isEnabled: false,
    onValue: true,
    message: 'Message_when_fails',
    color: 'red',
    typeComponent: 'switch',
  },
  {
    id: 2,
    name: 'Output2',
    label: 'Output2',
    isEnabled: false,
    onValue: true,
    message: 'Message_when_fails',
    color: 'red',
    typeComponent: 'switch',
  },
  {
    id: 3,
    name: 'Logout',
    label: 'Logout',
    isEnabled: false,
    onValue: true,
    message: 'Message_when_fails',
    color: 'pink',
    typeComponent: 'switch',
  },
];
const defaultMula = { conditions: items, actions: items2 };

function SelectActions(props) {
  const {
    i18,
    options,
    title,
    onClose,
    onSave,
    data,
    value: valueProp,
    open,

    ...other
  } = props;
  const [mula, setMula] = useState(data || defaultMula);
  const [isLoading, setIsLoading] = React.useState(false);

  const radioGroupRef = React.useRef(null);
  const classesFormStyle = useFormStyle();
  const classesMacroSetting = useMacroSettingStyle();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  console.log('mula ', mula);
  // React.useEffect(() => {
  //   if (!open) {
  //     setValue(valueProp);
  //   }
  // }, [valueProp, open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };
  const handleOk = async () => {
    // if (value === valueProp) onSave();
    // else {
    setIsLoading(true);
    await onSave(mula);
    setIsLoading(false);
    // }
  };
  // Macro Settings Handlers
  const onConditionClick = (e, id) => {
    const conditions = mula.conditions.map((item) => {
      if (item.id === id) {
        return { ...item, isEnabled: !item.isEnabled };
      }
      return item;
    });
    setMula((prev) => ({ ...prev, conditions }));
  };
  const onConditionSwitchClick = (e, id) => {
    const conditions = mula.conditions.map((item) => {
      if (item.id === id) {
        return { ...item, onValue: !item.onValue };
      }
      return item;
    });
    setMula((prev) => ({ ...prev, conditions }));
  };
  const onMessageChange = (e, id) => {
    const conditions = mula.conditions.map((item) => {
      if (item.id === id) {
        return { ...item, message: e.target.value };
      }
      return item;
    });
    setMula((prev) => ({ ...prev, conditions }));
  };
  const onActionClick = (e, id) => {
    const actions = mula.actions.map((item) => {
      if (item.id === id) {
        return { ...item, isEnabled: !item.isEnabled };
      }
      return item;
    });
    setMula((prev) => ({ ...prev, actions }));
  };
  const onActionSwitchClick = (e, id) => {
    const actions = mula.actions.map((item) => {
      if (item.id === id) {
        return { ...item, onValue: !item.onValue };
      }
      return item;
    });
    setMula((prev) => ({ ...prev, actions }));
  };
  const handlers = {
    onConditionClick,
    onConditionSwitchClick,
    onMessageChange,
    onActionClick,
    onActionSwitchClick,
  };
  return (
    <Dialog
      fullScreen={fullScreen}
      disableBackdropClick
      disableEscapeKeyDown
      height={'600px'}
      onEntering={handleEntering}
      aria-labelledby="confirmation-dialog-title"
      open={open}
      classes={{
        paper: classesFormStyle.paperMula,
      }}
      {...other}
    >
      <GridContainer className={classesFormStyle.content}>
        <GridItem item xs={12} md={12} lg={12} className={classesFormStyle.content}>
          <Portlet className={classesFormStyle.formStyle} title={'Settings'} subTitle={'Configure conditions/actions when macro is pressed...'}>
            <MacroSettings mula={mula} handlers={handlers} classes={classesMacroSetting} i18={i18}></MacroSettings>
            <PortletActions>
              <div className={classesFormStyle.div}>
                <div className={classesFormStyle.inDiv}>
                  <Button variant="contained" disabled={false} color={'secondary'} onClick={() => onClose()}>
                    {i18('Cancel')}
                  </Button>
                </div>
                <div className={classesFormStyle.inDiv}>
                  {/* <Button type="submit" disabled={isLoading || isReadOnly} variant="contained" color={'primary'}> */}
                  <Button onClick={handleOk} type="submit" disabled={false} variant="contained" color={'primary'}>
                    {i18('save')}
                  </Button>
                  {isLoading && <CircularProgress size={24} className={classesFormStyle.buttonProgress} />}
                </div>
              </div>
            </PortletActions>
          </Portlet>
        </GridItem>
      </GridContainer>
    </Dialog>
  );
}

SelectActions.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
};

export default SelectActions;
