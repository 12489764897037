import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { InputBase, Paper, InputLabel, TextField } from '@material-ui/core';
import { useInputStyle } from '../../common/styles/inputStyle';
import NumberFormat from 'react-number-format';

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      // format={'##:##'}
      {...other}
      isNumericString={true}
      allowEmptyFormatting={false}
      allowNegative={false}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function InputMaskText(props) {
  const { className, inputRef, label, icon: FormIcon, outLine, ...rest } = props;
  const classes = useInputStyle();
  const { multiline } = rest;

  const rootClasses = clsx({
    [classes.rootDiv]: true,
    [className]: className,
  });
  const paperClasses = clsx({
    [classes.root]: true,
    [classes.multiline]: multiline,
    [classes.rootOutline]: outLine,
  });
  const formClasses = clsx({
    [classes.input]: true,
  });

  return (
    <div className={rootClasses}>
      {label && <InputLabel className={classes.label}>{label}</InputLabel>}
      <Paper elevation={0} className={paperClasses}>
        {FormIcon ? !multiline && <FormIcon /> : null}
        <InputBase
          className={formClasses}
          {...rest}
          inputRef={inputRef}
          // InputProps={{
          //   inputComponent: NumberFormatCustom,
          // }}
          inputComponent={NumberFormatCustom}
          inputProps={rest}
          // inputProps={{
          //   inputComponent: NumberFormatCustom,
          // }}
        />
        {/* <NumberFormat className={formClasses} {...rest} getInputRef={inputRef}></NumberFormat> */}
      </Paper>
    </div>
  );
}
InputMaskText.defaultProps = {
  icon: null,
};
export default InputMaskText;
