//#region imports
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CardContent, CircularProgress, Tooltip, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Slider from '@material-ui/core/Slider';

import GridContainer from '../../../../components/Grid/GridContainer';
import GridItem from '../../../../components/Grid/GridItem';
import Button from '../../../../components/CustomButton/Button';
import Portlet from '../../../../components/Portlet/Portlet';
import PortletActions from '../../../../components/Portlet/PorletActions';
import { useFormStyle } from '../../../../common/styles/formStyle';
import { FormNameIcon, FormAlarmShortTime } from '../../../../../assets/icons/formIcons';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';

import { useIntl } from '../../../../lang/context/intlContext';
import CustomCheck from '../../../../components/CustomCheck/CustomCheck';
import { useStoreActions, useStoreState } from 'easy-peasy';
import InputMaskText from '../../../../components/InputMaskText/InputMaskText';
import { postConfiguration } from '../api/postMethods';
import { formatValue } from '../../../../../utils';

//#endregion
//TODO: Icons required:Time,Schedule/Day

const LunchTime = ({ error = [], handleNextChange, isVisible, isReadOnly = true }) => {
  const classes = useFormStyle();
  const { i18, getWeekDays } = useIntl();

  const [free, setfree] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const { handleSubmit, errors } = useForm();

  const [values, setValues] = useState({
    lunchingTime: undefined,
    journeTimeToAcceptLunch: undefined,
    minLunchTime: undefined,
    maxLunchTime: undefined,
    detectExcessLunch: 10,
    detectExcessLunchChecks: { sendPos: false, sendAlertCabina: false },
    detectAntExcessLunch: 10,
    detectAntExcessLunchChecks: { sendPos: false, sendAlertCabina: false, blockStartVehicle: false },
  });

  const journeyControlId = useStoreState((state) => state.journeyControl.journeyControlId);
  const lunchTime = useStoreState((state) => state.journeyControl.lunchTime);
  const setJourneyTime = useStoreActions((actions) => actions.journeyControl.setJourneyTime);
  useEffect(() => {
    if (!isVisible) return;
    setValues((values) => ({ ...values, ...lunchTime }));
  }, [lunchTime, isVisible]);
  const handleOnChanges = (e) => {
    const value = e.target.value;
    const field = e.target.name;
    setValues((values) => ({
      ...values,
      [field]: value,
    }));
  };
  const handleSliders = (field, newValue) => {
    setValues((prev) => ({ ...prev, [field]: +newValue }));
  };

  /**
   *
   * @param {number} id
   * @param {string} field
   */
  const handleChecks = (id, field) => {
    switch (id) {
      case 0:
        const { detectExcessLunchChecks } = values;
        setValues((prev) => ({
          ...prev,
          detectExcessLunchChecks: { ...detectExcessLunchChecks, [field]: !prev.detectExcessLunchChecks[field] },
        }));
        break;
      case 1:
        const { detectAntExcessLunchChecks } = values;
        setValues((prev) => ({
          ...prev,
          detectAntExcessLunchChecks: { ...detectAntExcessLunchChecks, [field]: !prev.detectAntExcessLunchChecks[field] },
        }));
        break;
      default:
        break;
    }
    // setDetectBeginJourneyChecks((values) => ({ ...values, [name]: !values[name] || false }));
  };

  const onSave = async (data) => {
    try {
      console.log('onSave');
      setIsLoading(true);
      const {
        lunchingTime,
        journeTimeToAcceptLunch,
        minLunchTime,
        maxLunchTime,
        // preAlertExcessDriving,
        // preAlertExcessDrivingChecks,
        // alertExcessDriving,
        // alertExcessDrivingChecks,
        // detectStoppedVehicle,
        // detectStoppedVehicleChecks,
      } = values;
      const lunchTime = {
        ...values,
        lunchingTime: formatValue(lunchingTime),
        journeTimeToAcceptLunch: formatValue(journeTimeToAcceptLunch),
        minLunchTime: formatValue(minLunchTime),
        maxLunchTime: formatValue(maxLunchTime),
        // preAlertExcessDriving: preAlertExcessDriving,
        // alertExcessDriving: alertExcessDriving,
        // detectStoppedVehicle: detectStoppedVehicle,
      };
      console.info(lunchTime);
      await postConfiguration({ journeyControlId: journeyControlId, lunchTime });
      setJourneyTime({ lunchTime });
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      console.log('error on save');
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSave)}>
      <GridContainer className={classes.content}>
        <GridItem item xs={12} md={12} lg={10} className={classes.content}>
          <Portlet className={classes.formStyle} title={i18('LUNCHTIME')} subTitle={i18('JOURNEY_TIME_DETAIL')}>
            <CardContent>
              <GridContainer direction="column" spacing={2}>
                <GridItem item xs={12} md={12} lg={12}>
                  <GridContainer direction="row" spacing={2}>
                    <GridItem item xs={6} md={6}>
                      <InputMaskText
                        id="lunchingTime"
                        name="lunchingTime"
                        label={i18('lunchingTime')}
                        icon={FormAlarmShortTime}
                        value={values.lunchingTime}
                        format={'##:##'}
                        mask="_"
                        onChange={handleOnChanges}
                      ></InputMaskText>
                      {free && <Typography color="secondary">{`${free} ${i18('NOTAVAI')}`}</Typography>}
                      {errors.name && <Typography color="secondary">{i18('Name_Req')}</Typography>}
                    </GridItem>
                    <GridItem item xs={6} md={6}>
                      <InputMaskText
                        id="journeTimeToAcceptLunch"
                        name="journeTimeToAcceptLunch"
                        label={i18('journeTimeToAcceptLunch')}
                        value={values.journeTimeToAcceptLunch}
                        format={'##:##'}
                        mask="_"
                        onChange={handleOnChanges}
                        icon={FormAlarmShortTime}
                      ></InputMaskText>
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem item xs={12} md={12} lg={12}>
                  <GridContainer direction="row" spacing={2}>
                    <GridItem item xs={6} md={6}>
                      <InputMaskText
                        id="minLunchTime"
                        name="minLunchTime"
                        label={i18('minLunchTime')}
                        icon={FormAlarmShortTime}
                        value={values.minLunchTime}
                        format={'##:##'}
                        mask="_"
                        onChange={handleOnChanges}
                      ></InputMaskText>
                    </GridItem>
                    <GridItem item xs={6} md={6}>
                      <InputMaskText
                        id="maxLunchTime"
                        name="maxLunchTime"
                        label={i18('maxLunchTime')}
                        icon={FormAlarmShortTime}
                        value={values.maxLunchTime}
                        format={'##:##'}
                        mask="_"
                        onChange={handleOnChanges}
                      ></InputMaskText>
                    </GridItem>
                    <GridItem item xs={6} md={6}></GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem item xs={12} md={12} lg={12}>
                  <GridContainer direction="row" spacing={2}>
                    <GridItem item xs={6} md={6}>
                      <Typography gutterBottom>{i18('PREALERTEXCESSLUNCH')}</Typography>
                      <Slider
                        id="detectExcessLunch"
                        name="detectExcessLunch"
                        ValueLabelComponent={ValueLabelComponent}
                        aria-label="custom thumb label"
                        value={values.detectExcessLunch}
                        onChange={(_, value) => {
                          handleSliders('detectExcessLunch', value);
                        }}
                        min={0}
                        max={60}
                      />
                    </GridItem>
                    <GridItem container direction="row" item xs={6} md={6}>
                      <GridItem item xs={3} md={3}>
                        <CustomCheck
                          label={i18('SEND_POS')}
                          checked={values.detectExcessLunchChecks.sendPos}
                          onChange={() => handleChecks(0, 'sendPos')}
                          name="sendPos"
                        />
                      </GridItem>
                      <GridItem item xs={3} md={3}>
                        <CustomCheck
                          label={i18('SEND_ALERT_CABINA')}
                          checked={values.detectExcessLunchChecks.sendAlertCabina}
                          onChange={() => handleChecks(0, 'sendAlertCabina')}
                          name="sendAlertCabina"
                        />
                      </GridItem>
                    </GridItem>
                  </GridContainer>
                </GridItem>{' '}
                <GridItem item xs={12} md={12} lg={12}>
                  <GridContainer direction="row" spacing={2}>
                    <GridItem item xs={6} md={6}>
                      <Typography gutterBottom>{i18('alertExcessLUNCH')}</Typography>
                      <Slider
                        id="detectAntExcessLunch"
                        name="detectAntExcessLunch"
                        ValueLabelComponent={ValueLabelComponent}
                        aria-label="custom thumb label"
                        value={values.detectAntExcessLunch}
                        onChange={(_, value) => {
                          handleSliders('detectAntExcessLunch', value);
                        }}
                        min={0}
                        max={60}
                      />
                    </GridItem>
                    <GridItem container direction="row" item xs={6} md={6}>
                      <GridItem item xs={3} md={3}>
                        <CustomCheck
                          label={i18('SEND_POS')}
                          checked={values.detectAntExcessLunchChecks.sendPos}
                          onChange={() => handleChecks(1, 'sendPos')}
                          name="sendPos"
                        />
                      </GridItem>
                      <GridItem item xs={3} md={3}>
                        <CustomCheck
                          label={i18('SEND_ALERT_CABINA')}
                          checked={values.detectAntExcessLunchChecks.sendAlertCabina}
                          onChange={() => handleChecks(1, 'sendAlertCabina')}
                          name="sendAlertCabina"
                        />
                      </GridItem>{' '}
                      <GridItem item xs={3} md={3}>
                        <CustomCheck
                          label={i18('blockStartVehicle')}
                          checked={values.detectAntExcessLunchChecks.blockStartVehicle}
                          onChange={() => handleChecks(1, 'blockStartVehicle')}
                          name="blockStartVehicle"
                        />
                      </GridItem>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </CardContent>
            <PortletActions>
              <div className={classes.div}>
                <div className={classes.inDiv}>
                  <Button
                    variant="contained"
                    disabled={isLoading || isReadOnly}
                    color={'cancel'}
                    onClick={() => setValues((values) => ({ ...values, ...lunchTime }))}
                  >
                    {i18('Cancel')}
                  </Button>
                </div>
                <div className={classes.inDiv}>
                  <Button type="submit" disabled={isLoading || isReadOnly} variant="contained" color={'primary'}>
                    {i18('save')}
                  </Button>
                  {/* <CircularProgress size={24} className={classes.buttonProgress} /> */}
                  {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
              </div>
            </PortletActions>
            {error.length > 0 && (
              <Alert className={classes.alert} severity="error">
                {i18('API_ERR')}
              </Alert>
            )}
          </Portlet>
        </GridItem>
      </GridContainer>
    </form>
  );
};

function ValueLabelComponent(props) {
  const { children, open, value } = props;

  return (
    <Tooltip title={<h1 style={{ fontSize: '14px' }}>{value}</h1>} open={true} enterTouchDelay={0} placement="top">
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
};
export default LunchTime;
