import React, { useCallback, useEffect } from 'react';
import TableBody from '@material-ui/core/TableBody';
import { useTableContext } from './TableContext';

//state
import { TableBodyRow } from './TableRow';
import { TableCell, TableRow } from '@material-ui/core';

export function StyleTableBody({ data, hasCheckBox, handleParentClickOnRow, classes, expand, checkItem, getEmptyRows, actions, setCheckedAssets }) {
  // const { expand, checkItem, getEmptyRows, actions, setCheckedAssets } = useTableContext();
  const handleExpand = (id, status) => {
    expand(id, status);
  };

  const emptyRows = getEmptyRows();

  // this function returns checked/unchecked assets
  const handleCheck = (id, status, Childs) => {
    setCheckedAssets((prev) => {
      if (status === true) {
        return Childs.length ? [...prev, ...Childs] : [...prev, id];
      }
      if (!Childs.length) return prev.filter((item) => item !== id);
      return prev.filter((item) => Childs.find((children) => children === item) === undefined);
    });
    checkItem(id, status, Childs);
  };
  // const handleClickOnRow = useCallback((id) => {
  //   handleParentClickOnRow(id);
  // }
  // , [handleParentClickOnRow]);

  const handleClickOnRow = (id) => {
    if (handleParentClickOnRow) handleParentClickOnRow(id.substr(id.lastIndexOf('|') + 1));
  };

  return (
    <TableBody >
      <TableBodyRow data={data} handleClickOnRow={handleClickOnRow} hasCheckBox={hasCheckBox} handleExpand={handleExpand} handleCheck={handleCheck} classes={classes} actions={actions}></TableBodyRow>
      {emptyRows > 0 && (
        <TableRow style={{ height: 40 * emptyRows }}>
          <TableCell colSpan={6} />
        </TableRow>
      )}
    </TableBody>
  );
}
