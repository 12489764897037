//#region imports
import React, { useState, useEffect } from 'react';
//#region MUI
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import TabletOutlinedIcon from '@material-ui/icons/TabletOutlined';
import AppsOutlinedIcon from '@material-ui/icons/AppsOutlined';
import { Checkbox, CardContent, CircularProgress, IconButton, List, ListItemIcon, ListItemSecondaryAction, Step, StepLabel, Stepper, ListItem, ListItemText } from '@material-ui/core';
import SuperStepper from '../../../components/CustomStepper/SuperStepper';
import { useIntl } from '../../../lang/context/intlContext';
import { useFormStyle } from '../../../common/styles/formStyle';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import AutoCompleteBox from '../../../components/ComboBox/AutoCompleteBox';

import { Loading } from '../../../../Splash';
import { getUsersMFISEvents, getUsersMFISEventsTable } from './api/getMethods';
import InputText from '../../../components/InputText/InputText';
import { FormDescriptionIcon, FormNameIcon } from '../../../../assets/icons/formIcons';
import Canva from '../../../components/Canva/Canva';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { getEventsList } from './helper';
import { ListWrap } from '../../../layout/components/ListWrap/ListWrap';
import CustomizedSnackbars from '../../../components/CustomSnackBar/CustomSnackBar';
import { postAddMFISEventConfiguration } from './api/postMethods';
//#endregion
//#endregion

// todo: this is the wizard to create a configuration
// todo: step 1: select the events done
// todo: step 2: select the tablets Done
// todo: step 2.1 filter group/subgroups that only contains tablets (deviceTypeId = 56) Done
// todo: step 2.2 filter only tablets DONE
// todo: step 3: confirm the configuration DONE
// todo: step 4: save the configuration

// Stepper guide: to define a stepper, we need to define the steps, the content of each step, and the current step
// getStepContent(step) returns the content of the step
// steps: an array of steps
// onSubmit function: the function to call when the user clicks on the submit button

//style applied to event list
const defStyle = {
  marginTop: '4px',
  maxWidth: '100%',
  height: '400px',
  backgroundColor: '#ffff',
  maxHeight: '400px',
  overflow: 'auto',
};

// steps definition
const getSteps = () => {
  return ['Set Name', 'Select Events', 'Select Tablets', 'Review and Update'];
};
const getSelectedTabletsList = (items) => {
  return items.map((item) => (
    <ListItem key={item.deviceId}>
      <ListItemIcon>
        <TabletOutlinedIcon />
      </ListItemIcon>
      <ListItemText primary={item.asset} />
    </ListItem>
  ));
};
const getSelectedEventsList = (items, selectedEvents) => {
  return items
    .filter((item) => selectedEvents[item.eventId])
    .map((item) => (
      <ListItem key={item.eventId}>
        <ListItemIcon>
          <AppsOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary={item.name} />
      </ListItem>
    ));
};
const Configuration = (props) => {
  const classes = useFormStyle();
  const { i18 } = useIntl();

  const [isLoading, setIsLoading] = useState(false);
  const [openSnack, setOpenSnack] = React.useState({ open: false, title: '', severity: 'success' });
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [selectedEvents, setSelectedEvents] = useState({});
  const [selectedTabletsAsset, setSelectedTabletsAsset] = useState([]);
  const [tabletsAssets, setTabletsAsset] = useState([]);
  const [selectedTabletsGroups, setSelectedTabletsGroups] = useState([]);

  const { addMFISEvents } = useStoreActions((actions) => actions.events);
  const { mfisEvents } = useStoreState((state) => state.events);
  const tabletGroups = useStoreState((state) => state.user.tabletGroups);
  // todo: refactor this. This must be included when creating a new event in library
  const formatEvents = (events, i18) => {
    if (!events.length) return [];
    return events.map((item) => {
      const { settings } = item;
      const validSettings = Object.entries(settings)
        .filter((item) => item[1].isEnabled && item[1].label)
        .map((item) => ({ ...item[1] }));
      const details = validSettings.reduce((final, item, index) => {
        const { value, label = 'ADD:LABEL', measure = '' } = item;
        if (label === 'messageType') return final;
        const newWord = `${i18(label)}: ${value} ${measure}`;
        if (index === 1) return newWord;
        return final + ', ' + newWord;
      }, '');
      return { ...item, validSettings, details };
    });
  };
  //#region handlers
  const handleListItemClick = (event, eventId) => {
    setSelectedEvents((prev) => {
      if (prev[eventId]) {
        return { ...prev, [eventId]: !prev[eventId] };
      } else return { ...prev, [eventId]: true };
    });
  };
  const handleOnchangeGroups = (selectedGroup) => {
    setSelectedTabletsGroups(selectedGroup);
    const listAssets = selectedGroup
      .map((group) => group.assets)
      .flat()
      .filter((item) => item.isTablet);
    setTabletsAsset(listAssets);
    setSelectedTabletsAsset(listAssets);
  };
  //#endregion
  const steps = getSteps().map((item) => i18(item));
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <GridItem container justify="center">
            <GridItem item xs={10} md={8} lg={8}>
              <GridContainer direction="column" spacing={2}>
                <GridItem item xs={12} md={12}>
                  <InputText
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder={i18('name') + ' (' + i18('required') + ')'}
                    name="name"
                    label={i18('name')}
                    icon={FormNameIcon}
                    fullWidth
                  ></InputText>
                </GridItem>
                <GridItem item xs={12} md={12}>
                  <InputText
                    id="description"
                    name="description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    label={i18('Description')}
                    placeholder={i18('description') + ' (' + i18('optional') + ')'}
                    icon={FormDescriptionIcon}
                    multiline
                    rows={4}
                  ></InputText>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridItem>
        );
      case 1:
        return (
          <GridItem justify="center" container>
            <GridItem item xs={10} md={8} lg={8}>
              <GridContainer spacing={2}>
                <GridItem item xs={12} md={12} lg={12}>
                  {/* <Canva hasTitle={true} title={''} subHeader={''} buttonTitle={i18('New')} onClickButton={() => {}} isLoading={isLoading}> */}
                  <List component="nav" dense={true} style={defStyle}>
                    {getEventsList(mfisEvents, { button: true }, handleListItemClick, -0, () => {}, '', selectedEvents)}
                  </List>
                  {/* </Canva> */}
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridItem>
        );
      case 2:
        return (
          <GridItem justify="center" container spacing={2}>
            <GridItem item xs={10} md={8} lg={8}>
              <GridContainer spacing={2}>
                <GridItem item xs={4} md={4} lg={4}>
                  <AutoCompleteBox
                    placeholder="Filter for Group"
                    multiple
                    label={i18('GROUPS')}
                    id="checkboxes-tags-demo"
                    options={tabletGroups}
                    value={selectedTabletsGroups}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.fullName}
                    onChange={(prev, newValue) => handleOnchangeGroups(newValue)}
                    limitTags={10}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                        {option.fullName}
                      </React.Fragment>
                    )}
                    style={{ marginRight: 15 }}
                  />
                </GridItem>
                <GridItem item xs={8} md={8} lg={8}>
                  <AutoCompleteBox
                    label={i18('ASSETS')}
                    multiple
                    id="checkboxes-tags-demo"
                    options={tabletsAssets}
                    disableCloseOnSelect
                    value={selectedTabletsAsset}
                    getOptionLabel={(option) => option.asset}
                    onChange={(prev, newValue) => setSelectedTabletsAsset(newValue)}
                    limitTags={15}
                    noOptionsText={i18('SELECT_A_GROUP')}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                        {option.asset}
                      </React.Fragment>
                    )}
                    style={{
                      maxHeight: '400px',
                      marginRight: 15,
                    }}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridItem>
        );
      case 3:
        return (
          <GridItem justify="center" container spacing={2}>
            <GridItem item xs={10} md={8} lg={8}>
              <GridContainer spacing={2}>
                <GridItem item xs={12} md={6} lg={6}>
                  <ListWrap
                    title={i18('SELECTED_EVENTS')}
                    classes={classes}
                    customStyle={{
                      marginTop: '4px',
                      maxWidth: '100%',
                      height: '400px',
                      backgroundColor: '#f8f9fd',
                      maxHeight: '400px',
                      overflow: 'auto',
                    }}
                  >
                    {getSelectedEventsList(mfisEvents, selectedEvents)}
                  </ListWrap>
                </GridItem>
                <GridItem item xs={12} md={6} lg={6}>
                  <ListWrap
                    title={i18('SELECTED_TABLETS')}
                    classes={classes}
                    customStyle={{
                      marginTop: '4px',
                      maxWidth: '100%',
                      height: '400px',
                      backgroundColor: '#f8f9fd',
                      maxHeight: '400px',
                      overflow: 'auto',
                    }}
                  >
                    {getSelectedTabletsList(selectedTabletsAsset)}
                  </ListWrap>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridItem>
        );
      default:
        return i18('UPDATE_SUCESSFUL');
    }
  };
  // data operations
  const fetchData = async () => {
    setIsLoading(true);
    if (!mfisEvents.length) {
      const { data } = await getUsersMFISEvents();
      const formattedEvents = formatEvents(data.mfisEvents, i18);
      console.info({ formattedEvents });
      addMFISEvents(formattedEvents);
    }
    setIsLoading(false);
  };
  const onSubmit = async () => {
    const events = mfisEvents.filter((item) => selectedEvents[item.eventId]);
    console.info({ selectedTabletsAsset });
    return await postAddMFISEventConfiguration({ name, description, events, tablets: selectedTabletsAsset });
  };
  useEffect(() => {
    fetchData();
  }, []);
  if (isLoading)
    return (
      <GridContainer className={classes.content}>
        <GridItem item xs={12} md={12} lg={12}>
          <Loading></Loading>
        </GridItem>
      </GridContainer>
    );

  return (
    <div>
      <SuperStepper
        title={'configuration'}
        subTitle={'CREATE_SUBHEADER_SR'}
        validators={[name, Object.values(selectedEvents).some((item) => item), tabletsAssets.length, name && Object.values(selectedEvents).some((item) => item) && tabletsAssets.length]}
        labelFinalStep={'Save'}
        steps={steps}
        getStepContent={getStepContent}
        onSubmit={onSubmit}
        onResetSubmit={() => window.location.reload()}
      ></SuperStepper>
      <CustomizedSnackbars open={openSnack.open} setOpen={setOpenSnack} title={openSnack.title} severity={openSnack.severity}></CustomizedSnackbars>
    </div>
  );
};
export default Configuration;
