import React from 'react';
import clsx from 'clsx';
import { Paper, InputLabel, TextField } from '@material-ui/core';
import { useInputStyle } from '../../common/styles/inputStyle';

function InputTimeDate(props) {
  const { className, inputRef, label, icon: FormIcon, outLine, value, onChange, ...rest } = props;
  const classes = useInputStyle();
  const { multiline } = rest;

  const rootClasses = clsx({
    [classes.rootDiv]: true,
    [className]: className,
  });
  const paperClasses = clsx({
    [classes.root]: true,
    [classes.multiline]: multiline,
    [classes.rootOutline]: outLine,
  });

  return (
    <div className={rootClasses}>
      {label && <InputLabel className={classes.label}>{label}</InputLabel>}
      <Paper elevation={0} className={paperClasses}>
        {FormIcon ? !multiline && <FormIcon /> : null}
        <TextField
          type="datetime-local"
          defaultValue={value}
          onChange={(date) => onChange(date)}
          {...rest}
          InputProps={{ disableUnderline: true }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Paper>
    </div>
  );
}
InputTimeDate.defaultProps = {
  icon: null,
  value: new Date(),
  onChange: () => {},
};
export default InputTimeDate;
