import { patterHeaderStyle, patternCellStyle } from '../../table/stylesReport';

export const columnsRole = [
  {
    title: 'ROLES',
    field: 'name',
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'LEVEL',
    field: 'level',
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'CLIENTNAME',
    field: 'clientName',
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
];
