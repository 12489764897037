import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

const useToolbarStyles = makeStyles((theme) => ({
  toolbar: {
    minHeight: 80,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(0),
    alignItems: 'start',
  },
  title: {},
  subToolbar: {
    justifyContent: 'center',
  },
  dividerStyle: {
    width: '100%',
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
}));

const TableSuperToolbar = (props) => {
  const classes = useToolbarStyles();
  const { children, title } = props;
  const className = clsx(classes.toolbar);
  return (
    <Toolbar className={className}>
      {title.length > 0 && (
        <div>
          <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
            {title}
          </Typography>
        </div>
      )}{' '}
      <div className={classes.subToolbar}>{children}</div>
    </Toolbar>
  );
};

TableSuperToolbar.propTypes = {
  title: PropTypes.string,
  numSelected: PropTypes.number.isRequired,
  children: PropTypes.object,
};

export default TableSuperToolbar;
