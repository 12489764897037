import React from 'react';
import {
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Autocomplete } from '@material-ui/lab';
import Button from '../../../components/CustomButton/Button';
import InputText from '../../../components/InputText/InputText';
import { FormNameIcon } from '../../../../assets/icons/formIcons';
import ComboBox from '../../../components/ComboBox/ComboBox';
import CustomCheck from '../../../components/CustomCheck/CustomCheck';

const rolLevelMaster = [
  {
    value: 1,
    text: 'IACS',
  },
  {
    value: 2,
    text: 'TSP',
  },
  { value: 3, text: 'Master Owner' },
  { value: 4, text: 'Security' },
  { value: 5, text: 'Standard' },
];
const AddRol = ({
  openRol,
  handleCloseRol,
  maxRolLevel,
  i18,
  onClickAddRol,
  rolName,
  setrolName,
  rolDescription,
  setrolDescription,
  handleComboLevelChange,
  handleCheckIsInherit,
  rolLevel,
  isInherit,
  isLoading = false,
  classes,
}) => {
  return (
    <Dialog open={openRol} onClose={handleCloseRol} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title" style={{ paddingBottom: '8px' }}>
        {i18('NEW_ROL')}
      </DialogTitle>
      <DialogContent style={{ minHeight: '300px', minWidth: '550px' }}>
        <DialogContentText style={{ paddingBottom: '16px' }}>{i18('NEW_ROL_CONTENT')}</DialogContentText>
        <div style={{ paddingBottom: '16px' }}>
          <InputText
            id="name"
            placeholder={i18('name') + ' (' + i18('required') + ')'}
            name="name"
            label={i18('name')}
            icon={FormNameIcon}
            value={rolName}
            onChange={(e) => setrolName(e.target.value)}
            fullWidth
          ></InputText>
        </div>
        <div style={{ paddingBottom: '16px' }}>
          <InputText
            id="description"
            placeholder={i18('description') + ' (' + i18('optional') + ')'}
            name="description"
            label={i18('description')}
            icon={FormNameIcon}
            value={rolDescription}
            onChange={(e) => setrolDescription(e.target.value)}
            fullWidth
          ></InputText>
        </div>
        <div style={{ paddingBottom: '16px' }}>
          <ComboBox
            label={i18('Level')}
            icon={FormNameIcon}
            name="groupDelete"
            defaultValue={''}
            onChange={handleComboLevelChange}
            options={rolLevelMaster.filter((item) => item.value >= maxRolLevel)}
            placeholder={i18('level')}
          ></ComboBox>
        </div>
        {/* <div style={{ paddingBottom: '16px' }}>
          <CustomCheck
            // className={classes.poeStyle}
            label={i18('INHERIT_PER')}
            // checked={isSubGroup}
            checked={isInherit}
            onChange={handleCheckIsInherit}
            name="isInherit"
          />
        </div> */}
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-around' }}>
        <Button onClick={handleCloseRol} color="primary">
          {i18('Cancel')}
        </Button>
        <div className={classes.inDiv}>
          <Button
            onClick={onClickAddRol}
            color="primary"
            disabled={!rolName.length || rolLevel === 0 || isLoading}
            variant="contained"
          >
            {i18('Save')}
          </Button>
          {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default AddRol;
