import React, { useState, useEffect, useCallback } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Button, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';

import { useIntl } from '../../../lang/context/intlContext';

import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import { TableProvider } from '../../../components/Table/TableContext';
import TablePaper from '../../../components/Table/TablePaper';
import PortletIcon from '../../../components/Portlet/PortletIcon';
import { Loading } from '../../../../Splash';
import { getAllDrivers, getTableNFCS } from '../api/getMethods';
import { postIsDeleteDriver } from '../api/postMethods';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { ActionControlPanel, ActionEdit, ActionRemove, ActionSettings } from '../../../../assets/icons';
import CustomizedSnackbars from '../../../components/CustomSnackBar/CustomSnackBar';

const useStyleGrid = makeStyles((theme) => ({
  proto: { maxHeight: 'calc(100vh-600px)' },
  content: {
    display: 'flex',
    justifyContent: 'center',
    height: theme.spacing(45),
  },
  alert: { margin: theme.spacing(2) },
}));

const filterInputs = [{ label: 'serial', field: 'serial' }];

const NFC = ({ history, match, location }) => {
  const { i18 } = useIntl();
  const classes = useStyleGrid();
  const [isLoading, setisLoading] = useState(true);
  const [openSnack, setOpenSnack] = React.useState({ open: false, title: '', severity: 'success' });

  const addNFCS = useStoreActions((actions) => actions.nfc.addNFCS);
  const nfcs = useStoreState((state) => state.nfc.nfcs);

  const fetchData = async () => {
    const res = await getTableNFCS();
    addNFCS(res.data.nfcs);
    setisLoading(false);
    const { search } = location;
    if (search.indexOf('r=true') > 0) {
      setOpenSnack((prev) => ({ ...prev, open: true, title: i18('NFC_CARD_HAS_ERG'), severity: 'success' }));
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  //?r=true
  const headCellsNFC = [
    // { id: 'nfcId', numeric: true, align: 'left', disablePadding: false, label: i18('nfcId'), width: '50px' },

    {
      id: 'NFC',
      numeric: false,
      align: 'left',
      disablePadding: true,
      label: i18('NFC'),
      width: '150px',
    },

    { id: 'serial', numeric: true, align: 'left', disablePadding: false, label: i18('serial'), width: '150px' },
    { id: 'ClientName', numeric: true, align: 'left', disablePadding: false, label: i18('ClientName'), width: '120px' },
    { id: 'UserName', numeric: false, align: 'left', disablePadding: false, label: i18('UserName'), width: '120px' },
    {
      id: 'created_at',
      defaultOrder: true,
      order: 'desc',
      numeric: false,
      align: 'left',
      disablePadding: false,
      label: i18('created_at'),
      width: '120px',
    },
    { id: 'nfcStatus', numeric: false, align: 'left', disablePadding: false, label: i18('Status'), width: '120px' },
    { id: 'lastUsed_at', numeric: false, align: 'left', disablePadding: false, label: i18('lastUsed_at'), width: '120px' },
    // { id: 'actions', numeric: true, align: 'left', disablePadding: false, label: i18('actions'), width: '100px' },
  ];

  if (isLoading)
    return (
      <GridContainer className={classes.content}>
        <GridItem item xs={12} md={12} lg={12}>
          <Loading></Loading>
        </GridItem>{' '}
      </GridContainer>
    );

  return (
    <div>
      <GridContainer className={classes.content}>
        <GridItem item xs={12} md={12} lg={12}>
          <PortletIcon
            hasTitle={true}
            title={i18('NFCS')}
            subTitle={i18('LIST_OF_NFC')}
            withIcon={false}
            hasCustomStartIcon={false}
            StartIcon={PermIdentityIcon}
            withButton
            buttonTitle={i18('REgister')}
            onClickButton={() => history.push('/administration/nfc/new')}
          >
            <CardContent style={{ paddingTop: '0px' }}>
              <GridContainer direction="column" spacing={2}>
                <GridItem item xs={12} md={12}>
                  <TableProvider
                    loadData={nfcs}
                    columns={headCellsNFC}
                    checkedItems={[]}
                    initRowsPerPage={50}
                    withPagination={true}
                    onContextReady={() => {}}
                    useV2={true}
                  >
                    <TablePaper
                      maxHeight={'450px'}
                      filterInputs={filterInputs}
                      hasCheckBox={false}
                      hasGroups={false}
                      hasSearchAllFields={true}
                    ></TablePaper>
                  </TableProvider>
                </GridItem>
              </GridContainer>
            </CardContent>
            <></>
          </PortletIcon>
        </GridItem>
      </GridContainer>
      <CustomizedSnackbars
        open={openSnack.open}
        setOpen={setOpenSnack}
        title={openSnack.title}
        severity={openSnack.severity}
      ></CustomizedSnackbars>
    </div>
  );
};

export default NFC;
