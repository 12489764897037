import React from 'react';
import clsx from 'clsx';
import { InputBase, Paper, InputLabel, TextField } from '@material-ui/core';
import { useInputBoxStyle } from '../../common/styles/inputBoxStyle';

function InputBoxText(props) {
  const { className, inputRef = undefined, label, icon: FormIcon, outLine, ...rest } = props;
  const classes = useInputBoxStyle();
  const { multiline } = rest;

  const rootClasses = clsx({
    [classes.rootDiv]: true,
    [className]: className,
  });
  const paperClasses = clsx({
    [classes.root]: true,
    [classes.multiline]: multiline,
    [classes.rootOutline]: outLine,
  });
  const formClasses = clsx({
    [classes.input]: true,
  });

  return (
    <div className={rootClasses}>
      <TextField className={formClasses} {...rest} inputRef={inputRef} />
    </div>
  );
}
InputBoxText.defaultProps = {
  icon: null,
};
export default InputBoxText;
