const calcCenter = (positions) => {
  // console.info(positions);
  const NewPosition = positions.reduce(
    (acc, item) => {
      const maxLatitude =
        acc.maxLatitude === 0 ? item.latitude : item.latitude > acc.maxLatitude ? item.latitude : acc.maxLatitude;
      const maxLongitude =
        acc.maxLongitude === 0 ? item.longitude : item.longitude > acc.maxLongitude ? item.longitude : acc.maxLongitude;
      const minLatitude =
        acc.minLatitude === 0 ? item.latitude : item.latitude < acc.minLatitude ? item.latitude : acc.minLatitude;
      const minLongitude =
        acc.minLongitude === 0 ? item.longitude : item.longitude < acc.minLongitude ? item.longitude : acc.minLongitude;
      return {
        maxLatitude,
        minLongitude,
        minLatitude,
        maxLongitude,
      };
    },
    {
      maxLatitude: 0,
      minLongitude: 0,
      minLatitude: 0,
      maxLongitude: 0,
    }
  );

  const latitude = (parseFloat(NewPosition.maxLatitude) + parseFloat(NewPosition.minLatitude)) / 2;
  const longitude = (parseFloat(NewPosition.maxLongitude) + parseFloat(NewPosition.minLongitude)) / 2;
  const bounds = [
    [NewPosition.maxLatitude, NewPosition.maxLongitude],
    [NewPosition.minLatitude, NewPosition.minLongitude],
  ];
  return [[latitude, longitude], bounds];
};

export default calcCenter;
