import React from 'react';
import PrivateRoute from '../auth/PrivateRoute';
import MyPage from '../pages/MyPage';

import AddSpokenRoute from '../modules/mfis/spokenRoute/Add';
import SelectRegions from '../modules/mfis/spokenRoute/SelectRegions';
import AddViewRegions from '../modules/mfis/spokenRoute/AddViewRegions';
import Management from '../modules/mfis/spokenRoute/Management';
import RegionConfiguration from '../modules/mfis/spokenRoute/RegionConfiguration';
import Configurations from '../modules/mfis/spokenRoute/Configurations';
import Publish from '../modules/mfis/spokenRoute/Publish';
import Status from '../modules/mfis/spokenRoute/Status';
import Archive from '../modules/mfis/spokenRoute/Archive';
import Configuration from '../modules/mfis/journeyControl/Configuration';
import SelectTemplate from '../modules/mfis/journeyControl/SelectTemplate';
import JCManagement from '../modules/mfis/journeyControl/Management';
import JCPublish from '../modules/mfis/journeyControl/Publish';
import JCStatus from '../modules/mfis/journeyControl/Status';
import Add from '../modules/mfis/journeyControl/Add';
import ConfigurationReview from '../modules/mfis/journeyControl/ConfigurationReview';
import ReconcileManagement from '../modules/mfis/evidence/Management';
import ReconcileStatus from '../modules/mfis/evidence/Status';

import Events from '../modules/mfis/events/Events';
import NewEvent from '../modules/mfis/events/New';
import SelectMFISTemplate from '../modules/mfis/events/SelectTemplate';
import MFISConfiguration from '../modules/mfis/events/Configuration';
import MFISTabletStatus from '../modules/mfis/events/Status';
import MFISManagementConfigurations from '../modules/mfis/events/Management';
import MFISPublish from '../modules/mfis/events/Publish';
import Reconcile from '../modules/mfis/evidence/Reconcile';
import DownloadPDF from '../pages/DownloadPDF';
import { Switch, Redirect, Route } from 'react-router-dom';

const MFISRoutes = {
  data: [
    { sectionId: 5, path: '/mfis/rotograma/management/all/:action', component: Management },
    { sectionId: 5, path: '/mfis/rotograma/management/:id', component: Management },
    { sectionId: 5, path: '/mfis/rotograma/archive/:id', component: Archive },
    { sectionId: 5, exact: true, path: '/mfis/rotograma/add', component: AddSpokenRoute },
    { sectionId: 5, path: '/mfis/rotograma/add/select-regions', component: SelectRegions },
    { sectionId: 5, exact: true, path: '/mfis/rotograma/add/view-regions', component: AddViewRegions },
    { sectionId: 5, path: '/mfis/rotograma/add/region-configuration', component: RegionConfiguration },
    { sectionId: 5, path: '/mfis/rotograma/view/region-configuration/:name', component: RegionConfiguration },
    { sectionId: 5, exact: true, path: '/mfis/rotograma/configurations', component: Configurations },
    { sectionId: 5, path: '/mfis/rotograma/publish', component: Publish },
    { sectionId: 5, path: '/mfis/rotograma/status/:id', component: Status },
    { sectionId: 5, path: '/mfis/rotograma/link', component: MyPage },
    { sectionId: 4, path: '/mfis/control/selecttemplate', component: SelectTemplate },
    { sectionId: 4, path: '/mfis/control/add/:token', component: Add },
    { sectionId: 4, path: '/mfis/control/configuration', component: Configuration },
    { sectionId: 4, path: '/mfis/control/configuration-review', component: ConfigurationReview },
    { sectionId: 5, path: '/mfis/control/management/all/:action', component: Management },
    { sectionId: 4, path: '/mfis/control/management/:id', component: JCManagement },
    { sectionId: 4, path: '/mfis/control/publish', component: JCPublish },
    { sectionId: 4, path: '/mfis/control/status', component: JCStatus },
    { sectionId: 4, path: '/mfis/control/archive', component: MyPage },
    { sectionId: 11, path: '/mfis/events/publish', component: MFISPublish },
    { sectionId: 11, path: '/mfis/events/management-configurations/:tabletEventSettingId/:action', component: MFISManagementConfigurations },
    { sectionId: 11, path: '/mfis/events/management-configurations', component: MFISManagementConfigurations },
    { sectionId: 11, path: '/mfis/events/configuration', component: MFISConfiguration },
    { sectionId: 11, path: '/mfis/events/status', component: MFISTabletStatus },
    { sectionId: 11, path: '/mfis/events/management-library/:eventId/delete', component: Events },
    { sectionId: 11, path: '/mfis/events/management-library', component: Events },
    { sectionId: 11, path: '/mfis/events/selecttemplate', component: SelectMFISTemplate },
    { sectionId: 11, path: '/mfis/events/new/:type', component: NewEvent },
    { sectionId: 8, path: '/mfis/evidence/reconcile/:reconcileId/edit', component: Reconcile },
    { sectionId: 8, path: '/mfis/evidence/reconcile/:trackingEvidenceId', component: Reconcile },
    { sectionId: 8, path: '/mfis/reconcile/management', component: ReconcileManagement },
    { sectionId: 8, path: '/mfis/reconcile/status', component: ReconcileStatus },
    { sectionId: 8, path: '/fis/reconcile/:reconcileId/pdf', component: DownloadPDF },
  ],
};

export default MFISRoutes;
// export const mfisRoutes = {
//   data: [
//     {
//       path: '/mfis/control/management',
//       ,component: JCManagement,
//       sectionId: 100,
//     },
//     {
//       path: '/two',
//       ,component: JCManagement,
//     },
//   ],
// };
