import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import BasePage from './BasePage';
import Login from './auth/Login';
import layout from './layout/Layout';
import Lab from './components/Lab2';
import setupAxios from './api/setupAxios';
import axios from 'axios';
import { useStoreState } from 'easy-peasy';

// import { IntlProvider } from './lang/context/intlContext';
//aca se va a hacer la validacion del login, no en base page
//cuando agregemos layot, importamos de base page
//Pages

function Router() {
  const { token: authToken } = useStoreState((state) => state.user);
  setupAxios(axios, authToken);
  // console.log('router');

  return (
    <div>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/lab" component={Lab} />
        {/* <Layout> */}

        <BasePage layout={layout} />

        {/* </Layout> */}
      </Switch>
    </div>
  );
}
export default Router;
