import React from 'react';
import { Document, Image, Page, Text, View } from '@react-pdf/renderer';
import { styles } from './pdfStyles';

const urlApp = process.env.REACT_APP_URL;
export const ReconcilePDF = ({ reconcile }) => {
  const { companyName, IMEI, vehicleModel, currentDate, plates, driverName, evidence, address, levelName, startDateTime, serial, tratativa, description } = reconcile;
  const [dateEvidence, timeEvidence] = startDateTime.split(' ');
  return (
    <Document style={{ boxSizing: 'border-box' }}>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.headerLogo}>
            <Image src="/assets/images/logos/logoMillMedium.jpg" alt="" />
          </View>
          <View style={styles.viewHeaderTitle}>
            <Text>NOTIFICAÇÃO EMITIDA CONFORME RESOLUÇÃO DO DEPARMENTO SSMA</Text>
          </View>
          <View style={styles.viewHeader}>
            <Text style={styles.label}>ID:</Text>
            <Text style={styles.labelData}>{serial}</Text>
          </View>
          <View style={styles.viewHeader}>
            <Text style={styles.label}>DATA DA EMISSÃO:</Text>
            <Text style={styles.labelData}>{currentDate}</Text>
          </View>
        </View>
        <View style={styles.section2}>
          <View style={styles.data}>
            <View style={styles.data2}>
              <Text style={styles.label}>COMPANHIA:</Text>
              <Text style={styles.labelData}>{companyName.toString().toUpperCase()}</Text>
            </View>
            <View style={styles.data1}>
              <View style={styles.panel}>
                <Text style={styles.label}>PLACA:</Text>
                <Text style={styles.labelData}>{plates}</Text>
              </View>
              <View style={styles.panel}>
                <Text style={styles.label}>MODELO:</Text>
                <Text style={styles.labelData}>{vehicleModel}</Text>
              </View>
            </View>
            <View style={styles.data2}>
              <Text style={styles.label}>NOME CONDUTOR / INFRATOR:</Text>
              <Text style={styles.labelData}>{driverName.toString().toUpperCase()}</Text>
            </View>
            <View style={styles.data2}>
              <Text style={styles.label}>EVIDENCIA:</Text>
              <Text style={styles.labelData}>{evidence.toString().toUpperCase()}</Text>
            </View>
            <View style={styles.data2}>
              <Text style={styles.label}>LOCAL:</Text>
              <Text style={styles.labelData}>{address.toString().toUpperCase()}</Text>
            </View>
            <View style={styles.data1}>
              <View style={styles.panel}>
                <Text style={styles.label}>VELOCIDADE (KM\H):</Text>
                <Text style={styles.labelData}>N/A</Text>
              </View>
              <View style={styles.panel}>
                <Text style={styles.label}>DATA INFRAÇÃO:</Text>
                <Text style={styles.labelData}>{dateEvidence}</Text>
              </View>
              <View style={styles.panel}>
                <Text style={styles.label}>HORA INFRAÇÃO:</Text>
                <Text style={styles.labelData}>{timeEvidence}</Text>
              </View>
              {/* <View style={styles.panel}>
                <Text style={styles.label}>DURAÇÃO (S):</Text>
                <Text style={styles.labelData}>N/A</Text>
              </View>
              <View style={styles.panel}>
                <Text style={styles.label}>HORA INFRAÇÃO:</Text>
                <Text style={styles.labelData}>{timeEvidence}</Text>
              </View> */}
            </View>
            <View style={styles.data1}>
              <View style={styles.panel}>
                <Text style={styles.label}>NÍVEL:</Text>
                <Text style={styles.labelData}>{levelName.toString().toUpperCase()}</Text>
              </View>
              <View style={styles.panel1}>
                <Text style={styles.label}>IMEI:</Text>
                <Text style={styles.labelData}>{IMEI}</Text>
              </View>
              <View style={styles.panel2}>
                <Text style={styles.label}>MODELO:</Text>
                <Text style={styles.labelData}>MVDR</Text>
              </View>
            </View>
            {/* <View style={styles.data3}>
              
            </View> */}
          </View>
          <View style={styles.foto}></View>
        </View>
        <View style={styles.section3}>
          <View style={styles.viewSection3}>
            <Text style={styles.label}>Tratativa</Text>
            <Text style={styles.labelValue}>{tratativa.toString().toUpperCase()}</Text>
          </View>
          <View style={styles.viewMessage}>
            <Text style={styles.label}>Descripcao</Text>
            <Text style={styles.labelValue}>{description.toString().toUpperCase()}</Text>
          </View>
        </View>
        <View style={styles.section4}>
          <View style={styles.text}>
            <Text>
              Considerando que Vossa Senhoria, na data {dateEvidence} {timeEvidence}, cometeu uma violação, que infringe as normas de segurança de condução de veículo da empresa, quando praticou{' '}
              {evidence.toString().toUpperCase()}, fica o Senhor comunicado de que tal ação não é permitida dentro desta empresa, informando que eventual reincidência poderá ocasionar a incidência de
              penalidades mais severas. Este comunicado tem como objetivo alertar e orientar o condutor, dos riscos que a infração praticada pode acarretar.
            </Text>
          </View>
        </View>
        <View style={styles.section5}>
          <View style={styles.date}>
            <Text>Local:_________________________, Dia____de__________________de 20______</Text>
          </View>
        </View>
        <View style={styles.section6}>
          <View style={styles.firm}>
            <Text>________________________________________</Text>
          </View>
          <View style={styles.firm}>
            <Text>________________________________________</Text>
          </View>
        </View>
        <View style={styles.section61}>
          <View style={styles.firm}>
            <Text>Conductor/Infractor: {driverName}</Text>
          </View>
          <View style={styles.firm}>
            <Text>Gestor</Text>
          </View>
        </View>
        <View style={styles.section7}>
          {/* <View style={styles.viewUser}>
            <Text style={styles.user}>Usuario:</Text>
            <Text style={styles.user}>denyslopez.externos@cosmopolita.dt.com</Text>
          </View> */}
          <View style={styles.viewContact}>
            <Text style={styles.labelValue}>Millennium Driver Assistance</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};
