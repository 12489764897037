import { action, debug } from 'easy-peasy';

//mode:DEFAULT,CREATE,EDIT,VIEW,CONTINUE

const initValues = {
  mode: 'DEFAULT',
  selectedTemplateId: 0,
  selectedTemplateName: '',
  selectedTemplateUUID: undefined,
  selectedTemplateAuthor: undefined,
  isValidTemplate: false,
  journeyControlId: 0,
  toPublish: 0,
  name: undefined,
  description: undefined,
  template: undefined,
  macros: undefined,
  status: 0,
  actions: [],
  nextMacrosToDelete: [],
  durationTime: {
    maxDailyJourney: '0000',
    breakingDay: 'MON',
    maxExtraHours: '0400',
    maxWeeklyJourney: '4400',
    beginNigthyHour: '2200',
    endNigthyHour: '0500',
    minExtraHours: '0200',
    detectBeginJourney: 30,
    detectBeginJourneyChecks: { sendPos: false, sendAlertCabina: false, sendBlockEngine: false },
  },
  continousDurationTime: {
    maxContinousDriving: '0530',
    maxDailyDriving: '1100',
    maxWeeklyDriving: '4400',
    preAlertExcessDriving: 30,
    preAlertExcessDrivingChecks: { sendPos: true, sendAlertCabina: true },
    alertExcessDriving: 10,
    alertExcessDrivingChecks: { sendPos: false, sendAlertCabina: false, blockStartVehicle: false },
    detectStoppedVehicle: 10,
    detectStoppedVehicleChecks: { sendPos: false, sendAlertCabina: false, blockRestMacro: false },
  },
  lunchTime: {
    lunchingTime: '0000',
    journeTimeToAcceptLunch: '0600',
    minLunchTime: '0000',
    maxLunchTime: '0000',
    detectExcessLunch: 10,
    detectExcessLunchChecks: { sendPos: false, sendAlertCabina: false },
    detectAntExcessLunch: 10,
    detectAntExcessLunchChecks: { sendPos: true, sendAlertCabina: true, blockStartVehicle: true },
  },
  restTime: {
    minRestAfterContDriving: '0000',
    minDailyRest: '0600',
    regularDailyRest: '1100',
    weeklyRest: '3000',
    regularWeeklyRest: '3500',
    detectExcessRestTime: 0,
    detectExcessRestTimeChecks: { sendPos: false, sendAlertCabina: false },
    detectAntRestTime: 0,
    detectAntRestTimeChecks: { sendPos: false, sendAlertCabina: false, blockStopVehicle: false },
    detectMoving: 0,
    detectMovingChecks: { sendPos: false, sendAlertCabina: false, blockStartVehicle: false },
  },
};

const journeyControl = {
  ...initValues,
  setMasterMacroActions: action((journeyControl, actions) => {
    journeyControl.actions = actions;
  }),
  setTemplate: action((journeyControl, payload) => {
    const { mode, templateId, name, UUID, author } = payload;
    journeyControl.mode = mode;
    journeyControl.selectedTemplateId = templateId;
    journeyControl.selectedTemplateName = name;
    journeyControl.selectedTemplateAuthor = author;
    journeyControl.selectedTemplateUUID = UUID;
    journeyControl.isValidTemplate = true;
  }),
  checkTemplate: action((journeyControl, payload) => {
    journeyControl.isValidTemplate = journeyControl.selectedTemplateUUID === payload.UUID;
  }),
  continueEdition: action((journeyControl, payload) => {
    const { journeyControlId, name, status, description, macros, durationTime, continousDurationTime, lunchTime, restTime } = payload;
    journeyControl.journeyControlId = journeyControlId;
    journeyControl.name = name;
    journeyControl.description = description;
    journeyControl.status = status;
    journeyControl.macros = macros;
    journeyControl.durationTime = durationTime;
    journeyControl.continousDurationTime = continousDurationTime;
    journeyControl.lunchTime = lunchTime;
    journeyControl.restTime = restTime;

    journeyControl.nextMacrosToDelete = [];
    journeyControl.toPublish = 0;
  }),
  createdNew: action((journeyControl, payload) => {
    const { defaultConfiguration } = payload;
    const { durationTime, continousDurationTime, lunchTime, restTime } = defaultConfiguration;
    journeyControl.journeyControlId = payload.journeyControlId;
    journeyControl.name = payload.name;
    journeyControl.description = payload.description;
    journeyControl.nextMacrosToDelete = [];
    //template definition
    const template = {
      id: journeyControl.selectedTemplateId,
      name: journeyControl.selectedTemplateName,
      macros: payload.template.macros,
    };
    journeyControl.macros = undefined;
    journeyControl.template = { ...template };
    journeyControl.durationTime = durationTime;
    journeyControl.continousDurationTime = continousDurationTime;
    journeyControl.lunchTime = lunchTime;
    journeyControl.restTime = restTime;
    journeyControl.status = -1;
  }),
  saveConfiguration: action((journeyControl, payload) => {
    const { macros } = payload;
    journeyControl.macros = macros.map((item) => {
      return {
        ...item,
        actions: item.actions || [],
      };
    });
  }),
  addNewMacro: action((journeyControl, newMacro) => {
    newMacro.next = newMacro.next.map((item) => {
      const { name: title } = journeyControl.macros.find((item2) => item2.macroJourneyControlId === item.macroJourneyControlNextId);
      return { ...item, title };
    });

    journeyControl.macros.push(newMacro);
  }),
  addPrevMacro: action((journeyControl, newMacro) => {
    // const macro = journeyControl.macros.find((macro) => macro.macroJourneyControlId === nextMacro.parentId);
    // console.log('macro target ', debug(macro));
    // const macroToAddPrev = journeyControl.macros.find((macro) => macro.macroJourneyControlId === nextMacro.macroJourneyControlId);
    // // console.log('macro prev', macroToAddPrev);
    // const { description, name: title } = macro;
    // const newPrevMacro = {
    //   description,
    //   title,
    //   macroJourneyControlId: macroToAddPrev.macroJourneyControlId,
    //   macroJourneyControlNextId: macro.macroJourneyControlId,
    //   position: macroToAddPrev.prev.length + 1,
    //   mustBeSentToDB: true,
    // };

    const currentMacros = journeyControl.macros;
    journeyControl.macros = currentMacros.map((macro) => {
      const targetMacro = newMacro.next.find((item) => item.macroJourneyControlNextId === macro.macroJourneyControlId);
      if (targetMacro) {
        // console.log('targetMacero');
        const newPrevMacro = {
          description: newMacro.description,
          title: newMacro.name,
          iconId: newMacro.iconId,
          macroJourneyControlId: macro.macroJourneyControlId,
          macroJourneyControlNextId: newMacro.macroJourneyControlId,
          position: macro.prev.length + 1,
          mustBeSentToDB: true,
        };
        // debug(targetMacro);
        // if (targetMacro.prev) targetMacro.prev.push(newMacro);
        // return targetMacro;
        macro.prev.push(newPrevMacro);
      }
      // if (macro.macroJourneyControlId === newPrevMacro.macroJourneyControlId) {
      //   macro.prev.push(newPrevMacro);
      // }
      return macro;
    });
  }),
  addActions: action((journeyControl, payload) => {
    const { macroId, actions } = payload;
    const macro = journeyControl.macros.find((item) => +item.macroId === +macroId);
    if (macro) macro.actions = actions;
  }),
  addTag: action((journeyControl, payload) => {
    const { macroId, tag } = payload;
    const macro = journeyControl.macros.find((item) => +item.macroId === +macroId);
    if (macro) macro.tag = tag;
  }),
  addRenameMacro: action((journeyControl, payload) => {
    const { macroId, name } = payload;
    const macro = journeyControl.macros.find((item) => +item.macroId === +macroId);
    if (macro) macro.name = name;
    const { macroJourneyControlId } = macro;
    const updatedMacros = journeyControl.macros.map((item) => {
      if (item.next.length) {
        const updateNext = item.next.map((item2) => {
          if (item2.macroJourneyControlNextId === macroJourneyControlId) item2.title = name;
          return item2;
        });
        item.next = updateNext;
      }
      if (item.prev.length) {
        const updatePrev = item.prev.map((item2) => {
          if (item2.macroJourneyControlId === macroJourneyControlId) item2.title = name;
          return item2;
        });
        item.prev = updatePrev;
      }
      return item;
    });
    journeyControl.macros = updatedMacros;
  }),
  addMula: action((journeyControl, payload) => {
    const { macroId, mula } = payload;
    const macro = journeyControl.macros.find((item) => +item.macroId === +macroId);
    if (macro) macro.mula = mula;
  }),
  addNextMacro: action((journeyControl, nextMacro) => {
    const macro = journeyControl.macros.find((macro) => macro.macroJourneyControlId === nextMacro.parentId);
    const macroToAddPrev = journeyControl.macros.find((macro) => macro.macroJourneyControlId === nextMacro.macroJourneyControlId);
    const newNextMacro = {
      ...nextMacro,
      macroJourneyControlId: macro.macroJourneyControlId,
      macroJourneyControlNextId: nextMacro.macroJourneyControlId,
      position: macro.next.length + 1,
      mustBeSentToDB: true,
    };
    const { description, name: title } = macro;
    const newPrevMacro = {
      description,
      title,
      macroJourneyControlId: macroToAddPrev.macroJourneyControlId,
      macroJourneyControlNextId: macro.macroJourneyControlId,
      position: macroToAddPrev.prev.length + 1,
      mustBeSentToDB: true,
    };
    const currentMacros = journeyControl.macros;
    journeyControl.macros = currentMacros.map((macro) => {
      if (macro.macroJourneyControlId === newNextMacro.macroJourneyControlId) {
        macro.next.push(newNextMacro);
      }
      if (macro.macroJourneyControlId === newPrevMacro.macroJourneyControlId) {
        macro.prev.push(newPrevMacro);
      }
      return macro;
    });
    journeyControl.nextMacrosToDelete = journeyControl.nextMacrosToDelete.filter((item) => !(item.macroJourneyControlId === newNextMacro.macroJourneyControlId && item.macroJourneyControlNextId === newNextMacro.macroJourneyControlNextId));
  }),
  deleteNextMacro: action((journeyControl, removeMacro) => {
    const state = journeyControl.macros;

    journeyControl.macros = state.map((item) => {
      if (item.macroJourneyControlId === removeMacro.macroJourneyControlId) {
        item.next = item.next.filter((itemNext) => itemNext.macroJourneyControlNextId !== removeMacro.macroJourneyControlNextId);
      }
      if (item.macroJourneyControlId === removeMacro.macroJourneyControlNextId) {
        item.prev = item.prev.filter((itemNext) => itemNext.macroJourneyControlNextId !== removeMacro.macroJourneyControlId);
      }
      return { ...item };
    });
    journeyControl.nextMacrosToDelete.push(removeMacro);
  }),
  updatedNextMacros: action((journeyControl, _) => {
    const macros = journeyControl.macros;
    journeyControl.nextMacrosToDelete = [];
    journeyControl.macros = macros.map((macro) => {
      const newNext = macro.next.map((item) => ({ ...item, mustBeSentToDB: false }));
      return {
        ...macro,
        next: newNext,
      };
    });
  }),
  setJourneyTime: action((journeyControl, payload) => {
    const { durationTime = undefined, continousDurationTime = undefined, lunchTime = undefined, restTime = undefined } = payload;
    if (durationTime) journeyControl.durationTime = durationTime;
    if (continousDurationTime) journeyControl.continousDurationTime = continousDurationTime;
    if (lunchTime) journeyControl.lunchTime = lunchTime;
    if (restTime) journeyControl.restTime = restTime;
  }),
  publish: action((journeyControl, payload) => {
    journeyControl.toPublish = payload.journeyControlId;
    journeyControl.name = payload.name;
    journeyControl.description = payload.description;
  }),
};

export default journeyControl;
