import { Typography } from '@material-ui/core';
import React from 'react';
import './splash.css';
import './splash2.css';

// const Splash = () => (
//   <div id="splash-screen" className="kt-splash-screen">
//     <img src="/assets/images/logosmall.png" alt="Metronic logo" />
//     <svg className="splash-spinner" viewBox="0 0 50 50">
//       <circle className="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
//     </svg>
//   </div>
// );

const Splash = () => (
  <div id="splash-screen" className="splash-screen">
    <img src="/assets/images/logosmall.png" alt="Millennium logo" />
    <div className="loading-dot">.</div>
  </div>
);

export const Loading = ({ message = '' }) => (
  <div className="splash-screen">
    <Typography style={{ fontSize: '20px' }}>{message}</Typography>
    <div className="loading-dot">.</div>
  </div>
);

export default Splash;
