import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SvgIcon, Tooltip } from '@material-ui/core';
//#region svgIcons
import { ReactComponent as IconUser } from './images/Icon_user.svg';
import { ReactComponent as IconOjo } from './images/Icon_ojo.svg';
import { ReactComponent as IconUserWhite } from './images/Icon_user_white.svg';
import { ReactComponent as IconSearch } from './images/Icon_buscar.svg';
import { ReactComponent as IconAlarma } from './images/Icon_alarma.svg';
import { ReactComponent as IconFiltro } from './images/Icon_filtro.svg';
import { ReactComponent as IconAlarmaBlanco } from './images/Icon_alarma_white.svg';
import { ReactComponent as IconTemplate } from './images/Icon_template-jornada.svg';
import { ReactComponent as IconInicioViagem } from './images/Icon_inicio.svg';

import { ReactComponent as IconBus } from './images/Icon_bus.svg';
import { ReactComponent as IconNOInicioViagem } from './images/Icon_inicio_nocolor.svg';
import { ReactComponent as IconDescanso } from './images/Icon_Descanso.svg';
import { ReactComponent as IconFim } from './images/Icon_fim.svg';
import { ReactComponent as IconParada } from './images/Icon_Parada.svg';
import { ReactComponent as IconRefrigerio } from './images/Icon_refrigerio.svg';
//#endregion

//#region MUI Icons
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined';
import SaveAltRoundedIcon from '@material-ui/icons/SaveAltRounded';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';
import SettingsInputAntennaOutlinedIcon from '@material-ui/icons/SettingsInputAntennaOutlined';
import PlayCircleOutlineOutlinedIcon from '@material-ui/icons/PlayCircleOutlineOutlined';
import CenterFocusStrongRoundedIcon from '@material-ui/icons/CenterFocusStrongRounded';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import { CompareArrowsOutlined } from '@material-ui/icons';
import SettingsBackupRestoreOutlinedIcon from '@material-ui/icons/SettingsBackupRestoreOutlined';
import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';

//#endregion

const useStyles = makeStyles((theme) => ({
  iconStyle: {
    // backgroundColor: theme.palette.primary.main,
    background: 'transparent',
    color: theme.palette.primary.contrastText,

    // borderRadius: '4px',
    // marginLeft: '4px',
  },
  noPadding: { paddingRight: 0, paddingLeft: 0, paddingBottom: 0, paddingTop: 0 },
  actionStyle: {
    // backgroundColor: theme.palette.primary.contrastText,
    // fill: '#5978e4',
    padding: '4px',

    fill: theme.palette.text.secondary,
    // borderRadius: '4px',
    // paddingRight: theme.spacing(1),
    '&:hover': {
      fill: theme.palette.primary.main,
    },
  },
  leftActionStyle: {
    // backgroundColor: theme.palette.primary.contrastText,
    // fill: '#5978e4',
    padding: '4px',

    fill: theme.palette.text.secondary,
    // borderRadius: '4px',
    // paddingRight: theme.spacing(1),
    '&:hover': {
      fill: theme.palette.primary.main,
    },
  },
}));

//#region ControlDeJornada

export function InicioViagemIcon({ className, color = 'primary' }) {
  return (
    <SvgIcon color={color} classes={{ root: className }}>
      <IconInicioViagem className={className} />
    </SvgIcon>
  );
}
export function InicioViagem2({ className, color = 'primary' }) {
  return (
    <SvgIcon color={color} classes={{ root: className }}>
      <IconBus className={className} />
    </SvgIcon>
  );
}
export function NoColorInicioViagemIcon({ className, color = 'primary' }) {
  return (
    <SvgIcon color={color} classes={{ root: className }}>
      <IconNOInicioViagem className={className} />
    </SvgIcon>
  );
}
export function FimViagemIcon({ className, color = 'primary' }) {
  return (
    <SvgIcon color={color} classes={{ root: className }}>
      <IconFim className={className} />
    </SvgIcon>
  );
}
export function DescansoViagemIcon({ className, color = 'primary' }) {
  return (
    <SvgIcon color={color} classes={{ root: className }}>
      <IconDescanso className={className} />
    </SvgIcon>
  );
}
export function ParadaViagemIcon({ className, color = 'primary' }) {
  return (
    <SvgIcon color={color} classes={{ root: className }}>
      <IconParada className={className} />
    </SvgIcon>
  );
}
export function RefrigerioViagemIcon({ className, color = 'primary' }) {
  return (
    <SvgIcon color={color} classes={{ root: className }}>
      <IconRefrigerio className={className} />
    </SvgIcon>
  );
}

export function EmailIcon({ className, color = 'primary' }) {
  return (
    <SvgIcon color={color} classes={{ root: className }}>
      <EmailOutlinedIcon className={className} />
    </SvgIcon>
  );
}
//#endregion

export function ActionSettings({ onClick = undefined }) {
  const classes = useStyles();
  return (
    <Tooltip title="Settings">
      <IconButton onClick={onClick}>
        <SettingsIcon className={classes.actionStyle} />
      </IconButton>
    </Tooltip>
  );
}
export function ActionChange({ onClick = undefined, title = 'Change' }) {
  const classes = useStyles();
  return (
    <Tooltip title={title}>
      <IconButton className={classes.noPadding} onClick={onClick}>
        <CompareArrowsOutlined className={classes.actionStyle} />
      </IconButton>
    </Tooltip>
  );
}
export function ActionAdd({ onClick = undefined, title = 'Add' }) {
  const classes = useStyles();
  return (
    <Tooltip title={title}>
      <IconButton className={classes.noPadding} onClick={onClick}>
        <AddIcon className={classes.actionStyle} />
      </IconButton>
    </Tooltip>
  );
}
export function ActionEdit({ onClick = undefined, title = 'Edit' }) {
  const classes = useStyles();
  return (
    <Tooltip title={title}>
      <IconButton className={classes.noPadding} onClick={onClick}>
        <EditOutlinedIcon className={classes.actionStyle} />
      </IconButton>
    </Tooltip>
  );
}
export function ActionRemove({ onClick = undefined, title = 'Remove' }) {
  const classes = useStyles();
  return (
    <Tooltip title={title}>
      <IconButton className={classes.noPadding} onClick={onClick}>
        <RemoveCircleOutlineOutlinedIcon className={classes.actionStyle} />
      </IconButton>
    </Tooltip>
  );
}
export function ActionDelete({ onClick = undefined, title = 'Delete' }) {
  const classes = useStyles();
  return (
    <Tooltip title={title}>
      <IconButton style={{ padding: '0px' }} onClick={onClick}>
        <DeleteIcon className={classes.leftActionStyle} />
      </IconButton>
    </Tooltip>
  );
}
export function ActionNotification({ onClick = undefined, title = 'Notification' }) {
  const classes = useStyles();
  return (
    <Tooltip title={title}>
      <IconButton onClick={onClick} className={classes.noPadding}>
        <NotificationsActiveOutlinedIcon className={classes.actionStyle} />
      </IconButton>
    </Tooltip>
  );
}
export function ActionControlPanel({ onClick = undefined }) {
  const classes = useStyles();
  return (
    <Tooltip title="Control Panel">
      <IconButton onClick={onClick}>
        <SettingsInputAntennaOutlinedIcon className={classes.actionStyle} />
      </IconButton>
    </Tooltip>
  );
}
export function ActionViewVideo({ onClick = undefined }) {
  const classes = useStyles();
  return (
    <Tooltip title="Ver Video">
      <IconButton onClick={onClick} className={classes.noPadding}>
        <PlayCircleOutlineOutlinedIcon className={classes.actionStyle} />
      </IconButton>
    </Tooltip>
  );
}
export function ActionPDF({ onClick = undefined, title = 'PDF' }) {
  const classes = useStyles();
  return (
    <Tooltip title={title}>
      <IconButton className={classes.noPadding} onClick={onClick}>
        <PictureAsPdfOutlinedIcon className={classes.actionStyle} />
      </IconButton>
    </Tooltip>
  );
}
export function ActionFocus({ onClick }) {
  const classes = useStyles();
  return (
    <Tooltip title="Control Panel">
      <IconButton onClick={onClick}>
        <CenterFocusStrongRoundedIcon className={classes.actionStyle} />
      </IconButton>
    </Tooltip>
  );
}
export function ActionReconcile({ onClick = undefined }) {
  const classes = useStyles();
  return (
    <Tooltip title="Tratativa">
      <IconButton onClick={onClick}>
        <SettingsBackupRestoreOutlinedIcon className={classes.actionStyle} />
      </IconButton>
    </Tooltip>
  );
}
export function UserIcon() {
  return (
    <SvgIcon>
      <IconUser />
    </SvgIcon>
  );
}
export function UserIconWhite() {
  return (
    <SvgIcon>
      <IconUserWhite />
    </SvgIcon>
  );
}
export function OjoIcon() {
  return (
    <SvgIcon>
      <IconOjo />
    </SvgIcon>
  );
}
export function BuscarIcon() {
  return (
    <SvgIcon>
      <IconSearch />
    </SvgIcon>
  );
}
export function TemplateIcon({ ...props }) {
  const { className } = props;
  return (
    <SvgIcon className={className}>
      <IconTemplate />
    </SvgIcon>
  );
}
export function AgregaIcon({ ...props }) {
  const { className } = props;
  return <AddIcon className={className} />;
}
export function SalvarIcon({ ...props }) {
  const { className } = props;
  return <SaveAltRoundedIcon className={className} />;
}
export function BackIcon({ ...props }) {
  const { className } = props;
  return <SettingsBackupRestoreIcon className={className} />;
}

export function AlarmaIcon({ ...props }) {
  // const { className } = props;
  return (
    <SvgIcon color="primary">
      <IconAlarma />
    </SvgIcon>
  );
}
export function FiltroIcon() {
  const classes = useStyles();
  return (
    <SvgIcon color="primary" className={classes.iconStyle}>
      <IconFiltro />
    </SvgIcon>
  );
}
export function AlarmaIconBlanco({ ...props }) {
  const { className } = props;
  return (
    <SvgIcon className={className}>
      <IconAlarmaBlanco />
    </SvgIcon>
  );
}
export function CustomIcon({ ...props }) {
  const { className } = props;
  return (
    <SvgIcon color="primary" className={className}>
      <IconAlarmaBlanco />
    </SvgIcon>
  );
}
