import { action } from 'easy-peasy';

// const typeMacro = {
//   name,
//   description,
//   type,
//   isPrimitiveType,
//   isMandatory,
//   hasAltName,
//   altName,
//   createdBy,
//   created_at,
// };

// const typeJourneyControl = {
//   name,
//   description,
//   createdBy,
//   created_at,
//   nextMacros: [],
// };
const templates = {
  journeyControl: [],
  sequencing: [],

  addJourneyControlTemplates: action((templates, journeyControl) => {
    templates.journeyControl = [...journeyControl];
  }),
};

export default templates;
