//#region IMPORTS
import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { styled } from '@material-ui/core/styles';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';

//#region CustomComponents
import Header from './components/Header/Header';
import Navigator from './components/Navigator/Navigator';
import SubNavigator from './components/Navigator/SubNavigator';
import Content from './components/ContentMap/Content';
import { subMenuData } from './components/Navigator/MainMenu/subMenu';
import { routesTitle } from './components/Navigator/MainMenu/routesTitle';
import { sumDrawers, drawerWidth } from './constants';
import { TableProvider } from '../components/Table/TableContext';
import TablePaper from '../components/Table/TablePaper';
import SplitterLayout from 'react-splitter-layout';

//#endregion

import { useLayoutUIContext } from './context/layoutContext';
import { useIntl } from '../lang/context/intlContext';
import { useStoreActions, useStoreState } from 'easy-peasy';

import 'react-splitter-layout/lib/index.css';
import './custom.css';

//Dialog
//#region dialog

import Slide from '@material-ui/core/Slide';
import { whiteColor } from '../components/styles/constValues';
//#endregion

import * as yup from 'yup';
import { postCreateAssetGroup, postCreateAssetSubGroup, postRemoveAssetGroup } from '../modules/map/api/postMethods';
import { ActionControlPanel, ActionReconcile, ActionRemove, ActionSettings, ActionViewVideo } from '../../assets/icons';
import { getGMTtoUserTimeZone } from '../../utils';
import { getAllTableEvidences } from '../modules/administration/api/getMethods';
import MapEvidencePage from '../pages/MapEvidencePage';
import ModalPortlet from '../components/ModalPortlet/ModalPortlet';
import GridItem from '../components/Grid/GridItem';
import GridContainer from '../components/Grid/GridContainer';
import ReactPlayer from 'react-player';
import ContentLoader from 'react-content-loader';
//#endregion
const PREFIX = 'LayoutMapEvidence';

const classes = {
  proto: `${PREFIX}-proto`,
  root: `${PREFIX}-root`,
  content: `${PREFIX}-content`,
  wraper: `${PREFIX}-wraper`,
  wraperShift: `${PREFIX}-wraperShift`,
  headerStyle: `${PREFIX}-headerStyle`,
  contentStyle: `${PREFIX}-contentStyle`,
  sideStyle: `${PREFIX}-sideStyle`,
  contentHeaderStyle: `${PREFIX}-contentHeaderStyle`,
  title: `${PREFIX}-title`,
  appBar: `${PREFIX}-appBar`,
  titleFilter: `${PREFIX}-titleFilter`,
  assetButton: `${PREFIX}-assetButton`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.proto}`]: { maxHeight: 'calc(100vh-600px)' },

  [`&.${classes.root}`]: {
    display: 'flex',
    backgroundColor: '#f2f5f9',
  },

  [`& .${classes.content}`]: {
    display: 'flex',
    justifyContent: 'center',
    height: theme.spacing(45),
  },

  [`& .${classes.wraper}`]: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    // height: '100vh',
    overflow: 'auto',
    width: `calc(100% - ${drawerWidth}px)`,
  },

  [`& .${classes.wraperShift}`]: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    // height: '100vh',
    overflow: 'auto',
    width: `calc(100% - ${sumDrawers}px)`,
    // width: `calc(100% - ${drawerWidth}px)`,
  },

  [`& .${classes.headerStyle}`]: {
    height: theme.spacing(10),
  },

  [`& .${classes.contentStyle}`]: {
    display: 'flex',
    flexDirection: 'column',
    // marginTop: theme.spacing(13),
    // height: `calc(100%-${theme.spacing(13)})`,
  },

  [`& .${classes.sideStyle}`]: {
    display: 'flex',
    // height: '100vh',
    width: drawerWidth,
  },

  [`& .${classes.contentHeaderStyle}`]: {
    display: 'flex',
    flexDirection: 'column',
  },

  [`& .${classes.title}`]: {
    flexGrow: 1,
    alignSelf: 'flex-start',
  },

  [`& .${classes.appBar}`]: {
    position: 'relative',
    backgroundColor: whiteColor,
  },

  [`& .${classes.titleFilter}`]: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },

  // sideStyleShift: {
  //   display: 'flex',
  //   width: `calc(100% - ${sumDrawers}px)`,
  //   // height: '100vh',
  //   // width: drawerWidth,
  // },
  [`& .${classes.assetButton}`]: {
    marginRight: theme.spacing(1),
  },
}));

const headerTitle = (location) => {
  const titles = routesTitle.find((item) => {
    const cleanLocation =
      '/' +
      location.pathname
        .split('/')
        .filter((item) => isNaN(item))
        .join('/');
    return cleanLocation.indexOf(item.path) >= 0;
  });
  if (!titles) return { title: '', subTitle: '' };
  return titles;
};
function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

const schema = yup.object().shape({
  name: yup.string().required(),
});

export default function LayoutMapEvidence(props) {
  //#region const
  const { location, history, match } = props;
  const { i18 } = useIntl();

  const [maxHeightDef, setmaxHeightDef] = useState(210);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [variant, setVariant] = useState('temporary');
  const { menuData, setMenuData, subMenu, setSubMenu } = useLayoutUIContext(); // useLocalStorage('menu', menu);
  const i18Menu = menuData.map((item) => ({ ...item, name: i18(item.name) }));

  const [openView, setOpenView] = useState({ isOpen: false, urlVideoFile: null, name: null, driverName: null, asset: null });
  //#endregion

  const [height, setHeight] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const ref = useRef(null);

  //#region easypeasy
  const evidences = useStoreState((state) => state.evidences.toGridMap);
  const user = useStoreState((state) => state.user);
  const onRemoveByTrackingEvidenceId = useStoreActions((actions) => actions.evidences.onRemoveByTrackingEvidenceId);

  const loadToGridMap = useStoreActions((actions) => actions.evidences.loadToGridMap);
  const onView = useStoreActions((actions) => actions.evidences.onView);
  const onRemove = useStoreActions((actions) => actions.evidences.onRemove);
  //#endregion
  const { search } = location;
  if (search.indexOf('remove') > 0) {
    const trackingEvidenceId = search.split('=')[1];
    onRemoveByTrackingEvidenceId(+trackingEvidenceId);
    window.location.assign('/home/evidence/map');
  }
  //#region UseEffect Socket
  useEffect(() => {
    if (!ref.current) return;
    const tableHeight = ref.current.clientHeight - 330;
    setHeight(tableHeight);
  });

  const fetchData = async (params) => {
    setIsLoading(true);
    const data = await getAllTableEvidences(params);
    loadToGridMap(data.data.evidences);
    setIsLoading(false);
  };

  //#endregion

  //#region LayoutFunctions
  const handleSidebarOpen = () => {
    const status = variant === 'temporary' ? 'permanent' : 'temporary';
    setVariant(status);
    if (status === 'permanent') setOpenSidebar(true);
    else setOpenSidebar(false);
  };
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    if (variant === 'temporary') setOpenSidebar(open);
  };
  const handleMainClick = (optionSelected) => {
    setOpenSidebar(true);
    //aplicamos al menu ppal seleccionado, para marcarlo como seleccionado
    const applySelection = menuData.map((item) => ({
      ...item,
      selected: item.label === optionSelected,
    }));
    setMenuData(applySelection);

    //aqui saco el subMenu a mostrar
    const filterSubMenu = subMenuData.find((item) => item.label === optionSelected).subMenu;
    const i18SubMenu = filterSubMenu.map((item) => {
      const i18SubMenus = item.subMenus.map((item2) => ({ ...item2, name: item2.name ? i18(item2.name) : '' }));
      return { ...item, name: i18(item.name), subMenus: i18SubMenus };
    });
    setSubMenu(i18SubMenu);
  };

  const rawTitles = useMemo(() => {
    return headerTitle(location);
  }, [location]);
  const titles = { title: i18(rawTitles.title), subTitle: i18(rawTitles.subTitle) };

  const onPaneReSize = (size) => {
    setmaxHeightDef(size - 35);
  };
  //#endregion

  //#region TableDefinitions
  const headCellsTablets = [
    {
      id: 'rootField',
      numeric: false,
      align: 'left',
      disablePadding: true,
      label: i18('ID'),
      width: '50px',
      defaultOrder: true,
    },
    { id: 'levelName', numeric: true, align: 'left', disablePadding: false, label: i18('level'), width: '70px' },
    { id: 'name', numeric: true, align: 'left', disablePadding: false, label: i18('evidence'), width: '250px' },
    { id: 'statusName', numeric: true, align: 'left', disablePadding: false, label: i18('status'), width: '70px' },
    { id: 'asset', numeric: true, align: 'left', disablePadding: false, label: i18('asset'), width: '250px' },
    { id: 'endDateTime', numeric: true, align: 'left', disablePadding: false, label: i18('datetime'), width: '200px' },
    { id: 'driverName', numeric: true, align: 'left', disablePadding: false, label: i18('Driver'), width: '300px' },
    { id: 'address', numeric: true, align: 'left', disablePadding: false, label: i18('address'), width: 'auto' },
    // { id: 'video', numeric: true, align: 'left', disablePadding: false, label: i18('rcvDateTime'), width: '200px' },
    { id: 'actions', numeric: true, align: 'left', disablePadding: false, label: i18('actions'), width: '100px' },

    // { id: 'address', numeric: true, align: 'left', disablePadding: false, label: i18('address'), width: 'auto' },
  ];
  //
  function onTableReady(params) {
    const { api } = params;
    // setTableApi(api);
  }

  //#endregion

  //#region MapEvents
  const onClickFilter = (params) => {
    fetchData(params);
  };

  const onClearEvidences = (e) => {
    loadToGridMap([]);
  };

  //#endregion

  const onOpenVideo = (e, evidence) => {
    const { urlVideoFile, name, asset, driverName } = evidence;
    e.preventDefault();
    setOpenView((prev) => ({ ...prev, isOpen: true, urlVideoFile, name, asset, driverName }));
  };

  const ActionsRender = useCallback((id) => {
    return (
      <>
        <ActionReconcile
          onClick={(e) => {
            e.preventDefault();
            let trackingEvidenceId = 0;
            if (id.split('|').length === 2) trackingEvidenceId = id.split('|')[1];
            else trackingEvidenceId = +id.trackingEvidenceId;
            history.push(`/mfis/evidence/reconcile/${trackingEvidenceId}`);
          }}
        />
        <ActionViewVideo onClick={(e) => handleViewVideo(e, id)} />
        <ActionRemove onClick={(e) => removeAsset(e, id)} />
      </>
    );
  }, []);
  const handleClickOnRow = (itemId) => {
    onView(itemId);
  };

  const removeAsset = (e, id) => {
    e.preventDefault();
    onRemove(id);
  };
  const handleReconcile = (e, id) => {
    e.preventDefault();
    const evidence = evidences.find((item) => +item.trackingEvidenceId === +id.split('|')[1]);
    if (!evidence) return;
    history.push(`/mfis/evidence/reconcile/${evidence.trackingEvidenceId}`);
  };
  const handleViewVideo = (e, id) => {
    const evidence = evidences.find((item) => +item.trackingEvidenceId === +id.split('|')[1]);
    if (!evidence) return;
    onOpenVideo(e, evidence);
  };

  return (
    <Root className={classes.root}>
      <div className={classes.sideStyle}>
        <Navigator handleMainClick={handleMainClick} menu={i18Menu} onSidebarOpen={handleSidebarOpen} />
        <SubNavigator
          subMenu={subMenu}
          location={location}
          open={openSidebar}
          toggleDrawer={toggleDrawer}
          variant={variant}
        />
      </div>
      <div className={clsx(classes.wraper, openSidebar && classes.wraperShift)}>
        <SplitterLayout vertical primaryIndex={0} secondaryInitialSize={250} onSecondaryPaneSizeChange={onPaneReSize}>
          <div className={classes.headerStyle}>
            <ElevationScroll {...props}>
              <Header open={openSidebar} {...titles} {...props}></Header>
            </ElevationScroll>
            <Content open={openSidebar} subTitle={titles.subTitle}>
              <MapEvidencePage onOpenVideo={onOpenVideo} onClickFilter={onClickFilter} onClearEvidences={onClearEvidences} />
            </Content>
          </div>
          {evidences.length > 0 && !isLoading && (
            <div>
              <TableProvider
                loadData={evidences}
                columns={headCellsTablets}
                withPagination={false}
                title=""
                checkedItems={[]}
                initRowsPerPage={10}
                onContextReady={onTableReady}
                useV2={true}
                actions={ActionsRender}
              >
                <TablePaper handleClickOnRow={handleClickOnRow} maxHeight={maxHeightDef + 'px'} filterInputs={[]} hasCheckBox={false} hasGroups={true} hasSearchAllFields={true} noFilter></TablePaper>
              </TableProvider>
            </div>
          )}
          {isLoading && (
            <ContentLoader speed={2} style={{ width: '100%' }} height={350} backgroundColor="#f3f3f3" foregroundColor="#7f7a7a">
              <rect x="25" y="15" rx="5" ry="5" width="100%" height="10" />
              <rect x="25" y="45" rx="5" ry="5" width="100%" height="10" />
              <rect x="25" y="75" rx="5" ry="5" width="100%" height="10" />
              <rect x="25" y="105" rx="5" ry="5" width="100%" height="10" />
              <rect x="25" y="135" rx="5" ry="5" width="100%" height="10" />
              <rect x="25" y="165" rx="5" ry="5" width="100%" height="10" />
              <rect x="25" y="195" rx="5" ry="5" width="100%" height="10" />
              <rect x="25" y="225" rx="5" ry="5" width="100%" height="10" />
            </ContentLoader>
          )}
        </SplitterLayout>
      </div>
      <ModalPortlet title={openView.name + ' - ' + openView.asset} subTitle={openView.driverName} i18={i18} open={openView.isOpen} handleOk={() => setOpenView((prev) => ({ ...prev, isOpen: false }))}>
        <GridItem justify="center" container>
          <GridItem item xs={12} md={12} lg={12}>
            <GridContainer spacing={2}>
              <GridItem item xs={11} md={11} lg={11}>
                <ReactPlayer
                  url={openView.urlVideoFile} // local
                  width="600px"
                  height="400px"
                  controls
                  volumen={0.8}
                  className="react-player"
                />
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridItem>
      </ModalPortlet>
    </Root>
  );
}
