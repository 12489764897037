import axios from 'axios';

export const GET_ALL_USERS_REGIONS = '/region/getAllUsersRegions';
export const GET_GJSON_REGIONS = '/region/getGJSONRegions';
export const GET_REGIONS_TYPES = '/region/getTypes';

const GET_ALL_ASSETS_LNP = '/assets/getAssets';
const GET_REGION_NAME_AVAILABILITY = '/region/checkNameAvailability';
const GET_JC_TEMPLATES = '/template/getAllJourneyControl';

export function getAllAssetsLNP() {
  return axios.get(GET_ALL_ASSETS_LNP);
}
export function getRegionTypes() {
  return axios.get(GET_REGIONS_TYPES);
}
export function getRegionNameAvailability(name) {
  return axios.get(`${GET_REGION_NAME_AVAILABILITY}?name=${name}`);
}
export function getAllUsersRegion() {
  return axios.get(GET_ALL_USERS_REGIONS);
}
export const getGJSONRegions = (regionIds) => {
  return axios.post(GET_GJSON_REGIONS, { regionIds });
};
export const getJCTemplates = () => axios.get(GET_JC_TEMPLATES);

export const GET_ALL_REGIONS = '/region/getAll';
export const GET_ALL_TABLETS = '/assets/getTablets';
export const GET_ALL_ASSETS = '/assets/getAll';
export const GET_SPOKEN_ROUTES_STATUS = '/rotograma/statusAll';
export const GET_SPOKEN_ROUTES_BYUSERID = '/rotograma/getAll';
export const GET_SPOKEN_ROUTES_NAME_AVAILABILITY = '/rotograma/checkNameAvailability';
export const GET_SPOKENROUTE_BYID = '/rotograma/getDefinition';

export function getSpokenRouteById(spokenRouteId) {
  return axios.get(`${GET_SPOKENROUTE_BYID}?spokenRouteId=${spokenRouteId}`);
}
export function getNameAvailability(name) {
  return axios.get(`${GET_SPOKEN_ROUTES_NAME_AVAILABILITY}?name=${name}`);
}
