import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Checkbox from '@material-ui/core/Checkbox';

// this component renders the cells contained in a row
export const StyledTableCells = ({ id, level, isExpanded, handleExpand, isChecked, handleCheck, hasChilds, childs, hasCheckBox, labelId, row, align, classes, actions }) => {
  const data = Object.values(row);
  const cssCell = !hasChilds && !hasCheckBox ? 'groupCellLevel0' : 'groupCellLevel' + level;
  const classNamesGroupCell = classes.groupCell + ' ' + classes[cssCell];
  const classNameMainCell = hasCheckBox ? classes.groupCell3 : classes.groupCell1NoCheck;
  const [checked, setChecked] = React.useState(false);
  const MemoizedCells = React.memo(getTableCell);

  return (
    <>
      {data.map((item, index) => {
        return index === 0 ? (
          <TableCell key={'mem' + index} className={classes.tableCellHeader} id={labelId} padding="none">
            <div className={classNamesGroupCell}>
              {hasChilds && (
                <div className={classes.groupCell1}>
                  <IconButton aria-label="expand row" size="small" onClick={() => handleExpand(id, !isExpanded)}>
                    {isExpanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                  </IconButton>
                </div>
              )}
              {hasCheckBox && (
                <div className={classes.groupCell2}>
                  <Checkbox style={{ padding: 0 }} checked={isChecked} onChange={() => handleCheck(id, !isChecked, childs)} className={classes.tableCheckCell} inputProps={{ 'aria-labelledby': labelId }} />
                  {/* <Checkbox
                    style={{ padding: 0 }}
                    checked={checked}
                    defaultChecked={checked}
                    onChange={() => {
                      setChecked((prev) => !prev);
                      handleCheck(id, true, childs);
                    }}
                    className={classes.tableCheckCell}
                    inputProps={{ 'aria-labelledby': labelId }}
                  /> */}
                </div>
              )}
              <div className={classNameMainCell}>{item}</div>
            </div>
          </TableCell>
        ) : actions && data.length - 1 === index ? (
          <TableCell key={'mem' + index} className={classes.tableCell} style={{ display: 'flex', paddingLeft: '0px' }} align={'left'}>
            {actions(id)}
          </TableCell>
        ) : (
          <MemoizedCells key={'mem' + index} classes={classes} align={align} item={item} />
        );
      })}
    </>
  );
};

/**
 * It returns a regular table cell with the given classes, alignment, and item
 * @returns A table cell with the item passed in as the content.
 */
function getTableCell({ classes, align, item }) {
  return (
    <TableCell className={classes.tableCell} align={align}>
      {item}
    </TableCell>
  );
}
