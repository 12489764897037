import React from 'react';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import MoveToInboxOutlined from '@material-ui/icons/MoveToInboxOutlined';
import DraftsIcon from '@material-ui/icons/Drafts';
import SettingsOutlined from '@material-ui/icons/SettingsOutlined';
import ReceiptOutlined from '@material-ui/icons/ReceiptOutlined';
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';
import ForumOutlinedIcon from '@material-ui/icons/ForumOutlined';
import TuneOutlinedIcon from '@material-ui/icons/TuneOutlined';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import CommuteOutlinedIcon from '@material-ui/icons/CommuteOutlined';
import StreetviewOutlinedIcon from '@material-ui/icons/StreetviewOutlined';
import TabletOutlinedIcon from '@material-ui/icons/TabletOutlined';
import AddToPhotosOutlinedIcon from '@material-ui/icons/AddToPhotosOutlined';
import ViewQuiltOutlinedIcon from '@material-ui/icons/ViewQuiltOutlined';
import RouterOutlinedIcon from '@material-ui/icons/RouterOutlined';
import LinkOutlinedIcon from '@material-ui/icons/LinkOutlined';
import AppsOutlinedIcon from '@material-ui/icons/AppsOutlined';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import FormatShapesOutlinedIcon from '@material-ui/icons/FormatShapesOutlined';
import SecurityIcon from '@material-ui/icons/Security';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AccountBoxRoundedIcon from '@material-ui/icons/AccountBoxRounded';
import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import PersonIcon from '@material-ui/icons/Person';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import CardTravelIcon from '@material-ui/icons/CardTravel';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import SwapCallsIcon from '@material-ui/icons/SwapCalls';
const iconos = {
  FormatShapesOutlinedIcon,
  RouterOutlinedIcon,
  SwapCallsIcon,
  ContactMailIcon,
  CardTravelIcon,
  DateRangeOutlinedIcon,
  CommuteOutlinedIcon,
  StreetviewOutlinedIcon,
  ListItemIcon,
  MoveToInboxOutlined,
  DraftsIcon,
  SettingsOutlined,
  AccountTreeOutlinedIcon,
  TuneOutlinedIcon,
  ForumOutlinedIcon,
  TabletOutlinedIcon,
  ReceiptOutlined,
  AddToPhotosOutlinedIcon,
  ViewQuiltOutlinedIcon,
  LinkOutlinedIcon,
  AppsOutlinedIcon,
  RoomOutlinedIcon,
  SecurityIcon,
  AssignmentIcon,
  AccountBoxIcon,
  AccountBoxRoundedIcon,
  AccountBoxOutlinedIcon,
  PersonOutlineOutlinedIcon,
  PersonIcon,
  PermContactCalendarIcon,
};

export const ItemIcon = (icon) => {
  const SelectedIcon = iconos[icon];
  return <SelectedIcon />;
};
export const ItemCustomIcon = ({ icon, classes }) => {
  const SelectedIcon = iconos[icon];
  return <SelectedIcon className={classes} />;
};
