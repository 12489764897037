import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Grid, Typography, IconButton, Avatar } from '@material-ui/core';
import { BuscarIcon } from '../../assets/icons/index';
import SearchIcon from '@material-ui/icons/Search';
import Searcher from './Searcher';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  title: {
    // flexGrow: 1,
    // color: theme.palette.text.secondary,
    // marginTop: '30px',
    fontWeight: 500,
  },
  profileIcon: {
    backgroundColor: theme.palette.primary.main,
    color: '#fffff',
    // paddingRight: theme.spacing(5),
    justifyContent: 'center',

    width: '34px',
    height: '34px',
  },
  sizeIcon: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));

const Lab = (props) => {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs={8}>
        <Typography component="h1" variant="h4" noWrap className={classes.title}>
          Labs
        </Typography>
      </Grid>
      <Grid xs={4}>
        {/* <Avatar variant="rounded" className={classes.profileIcon} color="inherit">
          <BuscarIcon />
        </Avatar> */}
        <Searcher label="Buscar" />
      </Grid>
    </Grid>
  );
};

export default Lab;
