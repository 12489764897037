import React, { useState, useCallback, useRef } from 'react';
import { StyledTableCells } from './formatters/StyledTableCells';
import { getChilds } from './helper';

const { makeStyles, TableRow, TableCell } = require('@material-ui/core');
const { ROW_BACK_ODD } = require('../styles/constValues');

const useStyles = makeStyles((theme) => ({
  root: {
    height: theme.spacing(5),
    '&:nth-of-type(odd)': {
      backgroundColor: ROW_BACK_ODD,
    },
    '&.Mui-selected': {
      backgroundColor: 'rgba(255, 177, 42, 0.08)',
    },
  },
  backColor: {
    width: '100%',
    backgroundColor: 'rgba(255, 177, 42, 0.08)',
  },
}));

function FinalRow(props) {
  const { index, row, onSelected, tableRowClasses, handleCheck, handleExpand, parentClasses, hasCheckBox, actions, isSelected } = props;
  const [localSelected, setLocalSelected] = React.useState(0);
  const { level, keyId, hasChilds, keyParentId, keyCellId, isExpanded, isChecked } = row;
  const labelId = `enhanced-table-checkbox-${index}`;
  const childs = hasChilds ? getChilds(row.nextLevel) : [];
  const onSelectedRow = useCallback((e, keyCellId) => {
    setLocalSelected((prev) => !prev);
    onSelected(e, keyCellId);
  }, []);
  // const isItemSelected = useCallback(isSelected(keyCellId), []);
  return (
    <TableRow id={keyCellId} key={'ROW' + keyCellId} hover className={tableRowClasses.root} role="checkbox" selected={isSelected} onClick={(e) => onSelectedRow(e, keyCellId)}>
      <StyledTableCells id={keyCellId} row={row.fields} align="left" level={level} handleCheck={handleCheck} handleExpand={handleExpand} childs={childs} hasChilds={hasChilds} hasCheckBox={hasCheckBox} labelId={labelId} isExpanded={isExpanded} classes={parentClasses} actions={actions} isChecked={isChecked} />
    </TableRow>
  );
}

export const TableBodyRow = (props) => {
  const tableRowClasses = useStyles();
  const [selected, setSelected] = useState(0);
  const { actions, data, handleExpand, handleCheck, hasCheckBox, classes: parentClasses, handleClickOnRow } = props;
  const onSelected = useCallback((e, keyCellId) => {
    e.preventDefault();
    setSelected(keyCellId);
    if (!handleClickOnRow) return;
    handleClickOnRow(keyCellId);
  }, []);
  // const isSelected = selected===keyCellId;

  if (!data) return null;
  if (!data.length) return null;

  const MemoRow = React.memo(getFinalRow);
  return data.map((row, index) => {
    const props = {
      index,
      row,
      onSelected,
      tableRowClasses,
      handleCheck,
      handleExpand,
      parentClasses,
      hasCheckBox,
      isSelected: selected === row.keyCellId,
      actions,
    };
    // return <FinalRow key={'FIN' + index} {...props} />;
    return <MemoRow key={index} {...props} />;
  });
};

function getFinalRow({ ...props }) {
  return <FinalRow {...props} />;
}
