import React, { useState, useEffect, useCallback } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Button, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';

import { useIntl } from '../../../lang/context/intlContext';

import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import { TableProvider } from '../../../components/Table/TableContext';
import TablePaper from '../../../components/Table/TablePaper';
import PortletIcon from '../../../components/Portlet/PortletIcon';
import { Loading } from '../../../../Splash';
import { getUsersMFISEventsTable } from './api/getMethods';
import { ActionRemove } from '../../../../assets/icons';
import CustomizedSnackbars from '../../../components/CustomSnackBar/CustomSnackBar';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { deleteMFISEventAPI } from './api/postMethods';

const useStyleGrid = makeStyles((theme) => ({
  proto: { maxHeight: 'calc(100vh-600px)' },
  content: {
    display: 'flex',
    justifyContent: 'center',
    height: theme.spacing(45),
  },
  alert: { margin: theme.spacing(2) },
}));

const filterInputs = [{ label: 'name', field: 'name' }];
const Events = ({ history, match, location }) => {
  const { i18 } = useIntl();
  const classes = useStyleGrid();
  const [isLoading, setIsLoading] = useState(false);
  const [openSnack, setOpenSnack] = React.useState({ open: false, title: '', severity: 'success' });

  const { mfisEventsTable } = useStoreState((state) => state.events);
  // const { configurations } = useStoreState((state) => state.configurations); // get configurations
  const { deleteMFISEventTable, clearMFISEventsTable, addMFISEventsTable } = useStoreActions((actions) => actions.events);

  const { eventId = 0 } = match.params;
  const handleClose = async (yesOption) => {
    try {
      if (yesOption) {
        await deleteMFISEventAPI(eventId);
        deleteMFISEventTable(eventId);
      }
      history.push('/mfis/events/management-library');
    } catch (error) {}
  };

  const fetchData = async () => {
    if (mfisEventsTable.length === 0) {
      console.log(`fetchData: ${mfisEventsTable.length}`);
      setIsLoading(true);
      const { data } = await getUsersMFISEventsTable();
      addMFISEventsTable(data.mfisEvents);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const headCellsEvents = [
    {
      id: 'name',
      numeric: false,
      align: 'left',
      disablePadding: true,
      defaultOrder: true,
      label: i18('Name'),
      width: '250px',
      order: 'asc',
    },
    {
      id: 'created_at',
      numeric: false,
      align: 'left',
      disablePadding: false,
      label: i18('created_at'),
      width: '120px',
    },
    { id: 'actions', numeric: true, align: 'left', disablePadding: false, label: i18('actions'), width: '100px' },
  ];
  const ActionsRender = useCallback((keyCellId) => {
    return (
      <>
        <ActionRemove onClick={() => history.push(`/mfis/events/management-library/${keyCellId.split('|')[1]}/delete`)} />
      </>
    );
  }, []);
  if (isLoading)
    return (
      <GridContainer className={classes.content}>
        <GridItem item xs={12} md={12} lg={12}>
          <Loading></Loading>
        </GridItem>{' '}
      </GridContainer>
    );

  return (
    <div>
      <GridContainer className={classes.content}>
        <GridItem item xs={12} md={12} lg={12}>
          <PortletIcon
            hasTitle={true}
            title={i18('EVENTS')}
            subTitle={i18('LIST_OF_MFIS_EVENTS')}
            withIcon={false}
            hasCustomStartIcon={false}
            StartIcon={PermIdentityIcon}
            withButton
            buttonTitle={i18('NEW_EVENT')}
            onClickButton={() => history.push('/mfis/events/selecttemplate')}
          >
            <CardContent style={{ paddingTop: '0px' }}>
              <GridContainer direction="column" spacing={2}>
                <GridItem item xs={12} md={12}>
                  <TableProvider
                    loadData={mfisEventsTable}
                    columns={headCellsEvents}
                    checkedItems={[]}
                    initRowsPerPage={50}
                    withPagination={true}
                    onContextReady={() => {}}
                    useV2={true}
                    actions={ActionsRender}
                  >
                    <TablePaper maxHeight={'450px'} filterInputs={filterInputs} hasCheckBox={false} hasGroups={false} hasSearchAllFields={true}></TablePaper>
                  </TableProvider>
                </GridItem>
              </GridContainer>
            </CardContent>
            <></>
          </PortletIcon>
        </GridItem>
      </GridContainer>
      {eventId > 0 && (
        <Dialog open={true} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" disableBackdropClick disableEscapeKeyDown>
          <DialogTitle id="alert-dialog-title">{i18('DELETE_MFIS_EVENT')}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{i18('DELETE_MFIS_EVENT_SUBTITLE')}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleClose(false)} color="primary">
              {i18('NOCancel')}
            </Button>
            <Button onClick={() => handleClose(true)} color="primary" autoFocus>
              {i18('YesDELETE')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <CustomizedSnackbars open={openSnack.open} setOpen={setOpenSnack} title={openSnack.title} severity={openSnack.severity}></CustomizedSnackbars>
    </div>
  );
};

export default Events;
