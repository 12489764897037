import { patterHeaderStyle, patternCellStyle } from '../../table/stylesReport';

export const columnsUsersPerCompany = [
  {
    title: 'CLIENTNAME',
    field: 'clientName',
    width: null,
    defaultGroupOrder: 0,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'USERS',
    field: 'userName',
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'EMAIL',
    field: 'email',
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
];
