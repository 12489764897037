import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
//utils
import { useIntl } from '../../../lang/context/intlContext'; //addjust path reference
import { v4 as uuidv4 } from 'uuid';

import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import CustomCard from '../../../components/CustomCard/Card';

import { useStoreActions, useStoreState } from 'easy-peasy';
const useStyle = makeStyles((theme) => ({
  root: {
    display: 'flex',
    // justifyContent: 'center',
    // alignContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  text: {
    // justifySelf: 'center',
  },
  grid: {
    paddingTop: theme.spacing(4),
  },
  cardStyle: { maxWidth: theme.spacing(30) },
  content: {},
}));

// Tasks
const SelectTemplate = (props) => {
  const { i18 } = useIntl();
  const classes = useStyle();
  const { history } = props;
  useEffect(() => {
    setNoButtons();
  }, []);

  const journeyControl = useStoreState((state) => state.templates.journeyControl);
  const setTemplate = useStoreActions((actions) => actions.journeyControl.setTemplate);
  const setNoButtons = useStoreActions((actions) => actions.globalState.setNoButtons);

  // const handleChange = (event) => {
  //   setState((prev) => !prev);
  // };

  const title = i18('Blank');
  // const finalText = i18('Create_BLANK_JC');
  const buttonText = i18('Select');
  const onClickCard = (type) => {
    history.push('/mfis/events/new/' + type);
  };

  return (
    <div className={classes.root}>
      <Typography component="h3" variant="h3" noWrap className={classes.text}>
        {i18('SELECT_MFIS_TEMPLATE_HEADER')}
      </Typography>
      <GridContainer spacing={4} className={classes.grid}>
        <GridItem item xs={12} md={6} lg={6}>
          <CustomCard
            className={classes.cardStyle}
            key={uuidv4()}
            title={i18('PARAM_EVENTS')}
            // onSelect={onSelectCard}
            // selected={selected}
            // value={item}
            align={'center'}
            contentText={i18('CREATE_MFIS_TEMPLATE')}
            hasAvatar
            onClick={() => onClickCard('template')}
            buttonText={buttonText}
            // isLoading={eloading === item.spokenRouteId}
          ></CustomCard>
        </GridItem>
        <GridItem item xs={12} md={6} lg={6}>
          <CustomCard
            className={classes.cardStyle}
            key={uuidv4()}
            title={i18('CREATE_MFIS_EVENT_TITLE')}
            // onSelect={onSelectCard}
            // selected={selected}
            // value={item}
            align={'center'}
            contentText={i18('CREATE_MFIS_EVENT')}
            hasAvatar
            onClick={() => onClickCard('event')}
            buttonText={buttonText}
            // isLoading={eloading === item.spokenRouteId}
          ></CustomCard>
        </GridItem>{' '}
      </GridContainer>
    </div>
  );
};

export default SelectTemplate;
