import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { useIntl } from '../../../lang/context/intlContext';
import 'jspdf-autotable';
import { useStoreState } from 'easy-peasy';

const TableReport = ({ columns, dataTable, customExportPDF = undefined, customExportLabel = false }) => {
  const { i18 } = useIntl();
  const { subHeaderText } = useStoreState((state) => state.globalState);
  const memo = useMemo(() => dataTable, [dataTable]);
  const customExport = customExportPDF ? customExportPDF(subHeaderText) : undefined;
  const localizationTableReport = {
    body: {
      emptyDataSourceMessage: i18('empty_Data_Table'),
    },
    pagination: {
      labelDisplayedRows: '{from}-{to} de {count}',
      labelRowsSelect: i18('label_Rows_Select'),
      labelRowsPerPage: i18('label_Rows_Per_Page'),
      firstAriaLabel: i18('first_Aria_Label'),
      firstTooltip: i18('first_Tooltip'),
      previousAriaLabel: i18('previous_Aria_Label'),
      previousTooltip: i18('previous_Tooltip'),
      nextAriaLabel: i18('next_Aria_Label'),
      nextTooltip: i18('next_Tooltip'),
      lastAriaLabel: i18('last_Aria_Label'),
      lastTooltip: i18('last_Tooltip'),
    },
    toolbar: {
      exportTitle: i18('export_Title'),
      exportAriaLabel: i18('export_Aria_Label'),
      searchTooltip: i18('search_Tooltip'),
      searchPlaceholder: i18('search_Placeholder'),
      exportCSVName: customExport ? i18('export_PDF_Name') : i18('export_CSV_Name'),
      // exportCSVName: "custom",
      exportPDFName: i18('export_PDF_Name'),
    },
  };
  const defaultOptions = {
    exportButton: {
      pdf: !customExport,
      csv: true,
    },
    exportCsv: customExport,
    exportAllData: true,
    pageSizeOptions: [5, 10, 20, 50, 100],
    exportFileName: subHeaderText || 'Report',
    filtering: false,
    headerStyle: {
      fontSize: '16px',
      fontFamily: 'Roboto',
      padding: '0px',

      textAlign: 'center',
      backgroundColor: '#fafafa',
    },
    rowStyle: {
      fontSize: '13px',
      fontFamily: 'Roboto',
      padding: '0px',
      backgroundColor: '#f7f9fd',
    },
  };

  return (
    <div>
      <MaterialTable
        style={{
          tableLayout: 'fixed',
          boxShadow: '0 0 0 0 ',
          padding: '0px',
        }}
        title=""
        columns={columns}
        data={memo}
        options={{ ...defaultOptions }}
        localization={localizationTableReport}
      />
    </div>
  );
};

TableReport.propTypes = {
  columns: PropTypes.array,
  dataTable: PropTypes.array,
  customExportPDF: PropTypes.func,
  customExportLabel: PropTypes.bool,
};
TableReport.defaultProps = {
  columns: [],
  dataTable: [],
  customExportPDF: undefined,
  customExportLabel: false,
};
export default TableReport;
