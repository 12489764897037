import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import { red } from '@material-ui/core/colors';
import StreetviewOutlinedIcon from '@material-ui/icons/StreetviewOutlined';
import EditLocationOutlinedIcon from '@material-ui/icons/EditLocationOutlined';
import { Divider, Input } from '@material-ui/core';

import Button from '../CustomButton/Button';
import { ROW_BACK_ODD } from '../styles/constValues';
const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: '0 10px 20px 0 rgba(92, 116, 129, 0.08)',
    borderRadius: '8px',
    width: '100%',
    transition: '0.4s',
  },
  content: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  contentChildren: {
    paddingTop: theme.spacing(0),
  },

  headerLine: {
    backgroundColor: theme.palette.primary.main,
    height: '9px',
  },
  headerStyle: {
    color: theme.palette.text.primary,
    letterSpacing: '0.42px',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),

    width: '100%',
    // letterSpacing: '0.66px',
  },
  subHeaderStyle: {
    color: theme.typography.body1.color,
  },
  contentTypoStyle: {
    marginTop: theme.spacing(2),
    fontSize: '16px',
    display: 'flex',
    fontFamily: 'Roboto-Bold',
    justifyContent: 'space-around',
  },
  avatarStyle: {
    display: 'flex',
    justifyContent: 'space-around',
  },

  actionStyle: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: 'inherit',
    alignItems: 'center',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  iconStyle: {
    height: theme.spacing(7),
    width: theme.spacing(7),
    // marginLeft: theme.spacing(4),

    backgroundColor: ROW_BACK_ODD,
    color: theme.palette.primary.main,
  },
  dividerStyle: {
    width: '100%',
    marginTop: theme.spacing(1),
    // maxWidth: 360,
    // padding: theme.spacing(0),
    // margin: theme.spacing(0),

    backgroundColor: theme.palette.background.paper,
  },
  marginButton: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

const PortletIcon = (props) => {
  const {
    className,
    title,
    subTitle,
    children,
    hasBlueLine,
    hasEndIcon,
    withIcon = true,
    hasCustomStartIcon,
    StartIcon,
    hasTitle = true,
    withButton = false,
    buttonTitle = '',
    onClickButton = undefined,
    isButtonDisabled = false,
  } = props;

  const classes = useStyles();
  //   const theme = useTheme();
  const cardClasses = clsx({
    [classes.root]: true,
    [classes.content]: false,
    [className]: className,
    // className,
  });
  return (
    <Card className={cardClasses}>
      {hasBlueLine && <div className={classes.headerLine}></div>}
      {hasTitle && (
        <div className={classes.cardHeader}>
          {withIcon && (
            <div>
              {hasCustomStartIcon ? (
                <StartIcon className={classes.iconStyle} />
              ) : (
                <StreetviewOutlinedIcon className={classes.iconStyle} />
              )}
            </div>
          )}
          <CardHeader
            title={title}
            subheader={subTitle}
            className={classes.headerStyle}
            action={
              withButton && (
                <Button onClick={onClickButton} color="primary" className={classes.marginButton} disabled={isButtonDisabled}>
                  {buttonTitle}
                </Button>
              )
            }
          ></CardHeader>
          <div>{hasEndIcon && <EditLocationOutlinedIcon className={classes.iconStyle} />}</div>
        </div>
      )}
      <div className={classes.dividerStyle}>
        <Divider variant="fullWidth" />
      </div>
      <div className={classes.contentChildren}>{children}</div>
    </Card>
  );
};
PortletIcon.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  hasBlueLine: PropTypes.bool,
  hasEndIcon: PropTypes.bool,
  withIcon: PropTypes.bool,
  hasCustomStartIcon: PropTypes.bool,
  children: PropTypes.array,
  StartIcon: PropTypes.any,
  hasTitle: PropTypes.bool,
  withButton: PropTypes.bool,
  buttonTitle: PropTypes.string,
  onClickButton: PropTypes.func,
  isButtonDisabled: PropTypes.bool,
};
PortletIcon.defaultProps = {
  hasBlueLine: true,
  withIcon: true,
  hasEndIcon: false,
  hasCustomStartIcon: false,
  hasTitle: true,
  withButton: false,
  buttonTitle: '',
  onClickButton: undefined,
  isButtonDisabled: false,
};
export default PortletIcon;
