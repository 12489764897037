import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import { Typography } from '@material-ui/core';
import { ItemIcon } from './ItemIcon';
import { Link as RouterLink } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
// import { withTheme } from '@material-ui/styles';

//borderRadius 36 balnc
const white = '#FFFFFF';

const useStyles = makeStyles((theme) => ({
  collapseStyle: {
    marginLeft: theme.spacing(1),
    backgroundColor: '#ffffff',
  },
  firstcollapseStyle: {
    // marginTop: theme.spacing(20),
    // backgroundColor: theme.palette.primary.dark,

    backgroundColor: theme.palette.primary.dark,
  },
  nested: {
    backgroundColor: theme.palette.primary.dark,
  },
  normal: {
    backgroundColor: theme.palette.primary.dark,
    '&:hover': {
      backgroundColor: '#1b1344',
      fontWeight: '500',
    },
  },
  blind: {
    backgroundColor: theme.palette.primary.dark,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      fontWeight: '500',
    },
  },
  nesteprevdSelected: {
    backgroundColor: theme.palette.primary.dark,
    borderBottomRightRadius: theme.spacing(3),
    '&:hover': {
      backgroundColor: '#1b1344',
      fontWeight: '500',
    },
  },
  nestedSelectedBack: {
    backgroundColor: theme.palette.primary.dark,
    '&:hover': {},
  },
  nestedSelected: {
    backgroundColor: '#ffffff',
    borderBottomLeftRadius: theme.spacing(3),
    borderTopLeftRadius: theme.spacing(3),
    color: '#32295e',
    '&.Mui-selected': {
      color: '#32295e',
    },
    '&:hover': {
      backgroundColor: '#ffffff',
      borderBottomLeftRadius: theme.spacing(3),
      borderTopLeftRadius: theme.spacing(3),
    },
    '&:focus': {
      // backgroundColor: '#ffffff',
      borderBottomLeftRadius: theme.spacing(3),
      borderTopLeftRadius: theme.spacing(3),
    },
  },
  nestednextSelected: {
    backgroundColor: theme.palette.primary.dark,
    borderTopRightRadius: theme.spacing(3),
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },

  listSubItemStyle: {
    fontSize: '14px',
    marginLeft: theme.spacing(1),
    color: white,
    letterSpacing: '0.42px',
  },
  listSubItemSelectedStyle: {
    fontSize: '14px',
    marginLeft: theme.spacing(1),
    color: '#32295e',
    letterSpacing: '0.42px',

    '&:hover': {
      borderBottomLeftRadius: theme.spacing(3),
      borderTopLeftRadius: theme.spacing(3),
    },
    '&:focus': {
      borderBottomLeftRadius: theme.spacing(3),
      borderTopLeftRadius: theme.spacing(3),
    },
  },
  listSubIconStyle: {
    // fontSize: '14px',
    minWidth: '0px',
    color: theme.palette.primary.main,
  },
}));

const getItemsStyles = (status, classes) => {
  const prevClasses = {
    divClass: '',
    itemClass: classes.nesteprevdSelected,
    itemTextClass: classes.listSubItemStyle,
  };
  const selectedClasses = {
    divClass: classes.nestedSelectedBack,
    itemClass: classes.nestedSelected,
    itemTextClass: classes.listSubItemSelectedStyle,
  };
  const nextClasses = {
    divClass: '',
    itemClass: classes.nestednextSelected,
    itemTextClass: classes.listSubItemStyle,
  };
  const normalClasses = {
    divClass: classes.nested,
    itemClass: classes.normal,
    itemTextClass: classes.listSubItemStyle,
  };
  const blindClasses = {
    divClass: classes.blind,
    itemClass: classes.nestednextSelected,
    itemTextClass: classes.listSubItemStyle,
  };
  let classToReturn = normalClasses;
  if (status === 'prev') classToReturn = prevClasses;
  if (status === 'selected') classToReturn = selectedClasses;
  if (status === 'next') classToReturn = nextClasses;
  if (status === 'blind') classToReturn = blindClasses;

  return classToReturn;
};

const CollapseItem = ({ status, classes, name, icon, id, path, currentPath, onToggleClick }) => {
  const { divClass, itemClass, itemTextClass } = getItemsStyles(status, classes);
  return (
    <div key={uuidv4()} className={divClass} onClick={onToggleClick(false)}>
      <ListItem key={uuidv4()} button className={itemClass} component={RouterLink} to={path}>
        {icon && <ListItemIcon className={classes.listSubIconStyle}>{ItemIcon(icon)}</ListItemIcon>}
        {status === 'selected' ? (
          <ListItemText
            className={itemTextClass}
            primary={
              <Typography variant="body2" style={{ color: '#32295e' }}>
                {name}
              </Typography>
            }
          />
        ) : (
          <ListItemText className={itemTextClass} primary={name} />
        )}
      </ListItem>
    </div>
  );
};

const CollapseMenu = ({ subMenus, opened, key, currentPath, onToggleClick }) => {
  const classes = useStyles();
  if (!subMenus) return null;
  if (!subMenus.length) return null;
  return (
    <Collapse key={uuidv4()} in={opened} timeout="auto" unmountOnExit className={classes.firstcollapseStyle}>
      <List dense component="div" disablePadding className={classes.collapseStyle}>
        {subMenus.filter((item) => item.isVisible).map((item) => CollapseItem({ ...item, classes, currentPath, onToggleClick }))}
      </List>
    </Collapse>
  );
};

export default CollapseMenu;
