import React from 'react';
import { motion, AnimateSharedLayout } from 'framer-motion';
import { v4 as uuidv4 } from 'uuid';

import GridItem from '../../../components/Grid/GridItem';

import Macro from '../../../components/Macro/Macro';

export default function getMacros(
  { macros, classes, actions: masterActions },
  i18,
  addNextMacro,
  onClickDeleteNextMacro,
  isReadOnly = false,
  addActions,
  updateRenameMacro,
  updateMacroTag,
  updateMacroMula
) {
  if (!macros) return null;
  const macroTitle = i18('SELECT_MACRO');
  const actionTitle = i18('configuration');

  return macros.map((item) => {
    const { macroId, name, type, next, prev, tag, mula } = item;
    const actions = masterActions.map((master) => {
      if (!item.action) return { macroId, isChecked: false, statusValue: false, ...master };
      const action = item.actions.find((item2) => master.actionId === item2.actionId);
      if (!action) {
        return { macroId, isChecked: false, statusValue: false, ...master };
      }

      return { ...master, isChecked: action.isChecked, statusValue: action.statusValue };
    });
    return (
      <GridItem className={classes} key={uuidv4()}>
        {/* <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.9 }}> */}
        <Macro
          selectMacroTitle={macroTitle}
          selectActionTitle={actionTitle}
          title={name}
          tag={tag}
          type={type}
          next={next}
          prev={prev}
          macros={macros}
          actions={actions}
          mula={mula}
          itemMacro={item}
          isReadOnly={isReadOnly}
          addNextMacro={addNextMacro}
          onClickDeleteNextMacro={onClickDeleteNextMacro}
          addActions={addActions}
          updateRenameMacro={updateRenameMacro}
          updateMacroTag={updateMacroTag}
          updateMacroMula={updateMacroMula}
        ></Macro>
        {/* </motion.div> */}
      </GridItem>
    );
  });
}
