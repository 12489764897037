import React from 'react';
// nodejs library that concatenates classes
import clsx from 'clsx';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';

// material-ui components

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { purple } from '@material-ui/core/colors';

const CustomSwitch = ({ ...props }) => {
  const { className, name, handleChange, checked, alternativeColor, ...rest } = props;
  const { isNext, label } = checked;
  const AlternativeSwitch = withStyles({
    switchBase: {
      // color: purple[300],
      '&$checked': {
        color: purple[500],
      },
      '&$checked + $track': {
        backgroundColor: purple[500],
      },
    },
    checked: {},
    track: {},
  })(Switch);
  return (
    <FormControlLabel
      control={
        alternativeColor ? (
          <AlternativeSwitch checked={isNext} onChange={handleChange} name={name} {...rest} />
        ) : (
          <Switch checked={isNext} onChange={handleChange} name={name} {...rest} />
        )
      }
      label={label}
    />
  );
};

CustomSwitch.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  // color: PropTypes.oneOf(['neutral', 'primary', 'info', 'success', 'warning', 'danger']),
  // size: PropTypes.oneOf(['sm', 'md', 'lg']),
};

CustomSwitch.defaultProps = {
  className: '',
  label: '',
  // size: 'md',
  // color: 'default',
};

export default CustomSwitch;
