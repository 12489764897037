import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { INPUT_BACKGROUND } from '../styles/constValues';
import { KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { Paper, InputLabel } from '@material-ui/core';
import { useInputStyle } from '../../common/styles/inputStyle';

function InputTime(props) {
  const { className, inputRef, label, icon: FormIcon, outLine, value, onChange, ...rest } = props;
  const classes = useInputStyle();
  const { multiline } = rest;

  const rootClasses = clsx({
    [classes.rootDiv]: true,
    [className]: className,
  });
  const paperClasses = clsx({
    [classes.root]: true,
    [classes.multiline]: multiline,
    [classes.rootOutline]: outLine,
    // [className]: className,
  });
  const formClasses = clsx({
    [classes.input]: true,
  });

  // const [value, onChange] = useState(new Date());
  return (
    <div className={rootClasses}>
      {label && <InputLabel className={classes.label}>{label}</InputLabel>}
      <Paper elevation={0} className={paperClasses}>
        {FormIcon ? !multiline && <FormIcon /> : null}
        {/* <InputBase className={formClasses} {...rest} inputRef={inputRef} /> */}
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardTimePicker
            ampm={false}
            inputRef={inputRef}
            // label="Masked timepicker"
            placeholder="08:00 AM"
            mask="__:__"
            value={value}
            onChange={(date) => onChange(date)}
            {...rest}
          />
        </MuiPickersUtilsProvider>
      </Paper>
    </div>
  );
}
InputTime.defaultProps = {
  icon: null,
  value: new Date(),
  onChange: () => {},
};
export default InputTime;
