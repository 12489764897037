//@ts-nocheck
import { action, computed } from 'easy-peasy';

const initValue = { configurations: [], masterMFISEventTypes: [], mfisEvents: [], mfisEventsTable: [], selectedMFISConfiguration: undefined };

const events = {
  ...initValue,

  addMasterMFISEventTypes: action((state, data) => (state.masterMFISEventTypes = data)),
  clearMasterMFISEventTypes: action((state) => (state.masterMFISEventTypes = [])),
  getMFISMasterEventType: computed((state) => (id) => state.masterMFISEventTypes.find((item) => item.messageTypeId === id)),
  deleteMFISMasterEventType: action((state, messageTypeId) => {
    state.masterMFISEventTypes = state.masterMFISEventTypes.filter((item) => item.messageTypeId !== +messageTypeId);
  }),

  addMFISEvents: action((state, data) => (state.mfisEvents = data)),
  clearMFISEvents: action((state) => (state.mfisEvents = [])),
  getMFISEvent: computed((state) => (id) => state.mfisEvents.find((item) => item.messageTypeId === id)),
  deleteMFISEvent: action((state, messageTypeId) => {
    state.mfisEvents = state.mfisEvents.filter((item) => item.messageTypeId !== +messageTypeId);
  }),

  addMFISEventsTable: action((state, data) => (state.mfisEventsTable = data)),
  clearMFISEventsTable: action((state) => {
    state.mfisEventsTable = [];
    state.mfisEvents = [];
  }),
  getMFISEventTable: computed((state) => (id) => state.mfisEventsTable.find((item) => item.messageTypeId === id)),
  deleteMFISEventTable: action((state, eventId) => {
    state.mfisEventsTable = state.mfisEventsTable.filter((item) => item.eventId !== +eventId);
    state.mfisEvents = state.mfisEvents.filter((item) => item.eventId !== +eventId);
  }),

  setSelectedMFISConfiguration: action((state, data) => (state.selectedMFISConfiguration = data)),
};

export default events;
