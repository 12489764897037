import React from 'react';
import L from 'leaflet';
import { Marker, Popup } from 'react-leaflet';
import { googleLink } from './helper';
// import { markerIcon } from './markerIcon';
// const myIcon = L.icon({
//   iconUrl: toAbsoluteUrl('/media/svg/icons/Map/Marker1.svg'),
//   iconSize: [48, 48],
// });
export const toAbsoluteUrl = (pathname) => process.env.PUBLIC_URL + pathname;
const iconMarker = (key = 0) => {
  switch (key) {
    case 0:
      // return L.Icon.Default;
      return new L.Icon({
        iconRetinaUrl: toAbsoluteUrl('/assets/images/markers/marker-icon-2x.png'),
        iconUrl: toAbsoluteUrl('/assets/images/markers/marker-icon.png'),
        shadowUrl: toAbsoluteUrl('/assets/images/markers/marker-shadow.png'),
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
      });
    case 1:
      // return L.Icon.Default;
      return new L.Icon({
        iconRetinaUrl: toAbsoluteUrl('/assets/images/markers/marker-icon-2x-green.png'),
        iconUrl: toAbsoluteUrl('/assets/images/markers/marker-icon-2x-green.png'),
        shadowUrl: toAbsoluteUrl('/assets/images/markers/marker-shadow.png'),
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
      });
    default:
      return new L.Icon({
        iconRetinaUrl: 'marker-icon-2x.png',
        iconUrl: 'marker-icon.png',
        shadowUrl: 'marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
      });
  }
};

const bubble = (lnp) => (
  <span>
    <b>
      {lnp.plates} - {lnp.device || lnp.serial}
    </b>
    <br />
    {lnp.gpsDateTime} (GPS)
    <br />
    <b> {lnp.address} </b>
    <br />
    {lnp.gpsSpeed} Km/h <br />
    {lnp.rcvDateTime} (Servidor) <br />
    <b>{lnp.eventName?.toString().toUpperCase()}</b>
    <br />
    <b>
      {/*  eslint-disable-next-line  */}
      <a href={lnp.google} target="_blank">
        Ver en Google Maps
      </a>
    </b>
  </span>
);
const bubbleEvidence = (evidence, onOpenVideo) => (
  <span>
    <b>{evidence.asset}</b>
    <br />
    <br />
    {evidence.endDateTime} (GPS)
    <br />
    <b> {evidence.address} </b>
    <br />
    <b>Chofer: </b>
    {evidence.driverName.toString().toUpperCase()}
    <br />
    <b>Evidencia: </b>
    {evidence.name} <br />
    <b>Risco: </b>
    {evidence.levelName.toString().toUpperCase()}
    <br />
    <b>
      {/*  eslint-disable-next-line  */}
      <a onClick={(e) => onOpenVideo(e, evidence)} style={{ display: 'table-cell' }} href="">
        {evidence.urlVideoFile ? 'Ver Vídeo' : 'Vídeo não disponível'}
      </a>
    </b>
    <br />
    <br />
    <b>
      {/*  eslint-disable-next-line  */}
      <a href={evidence.google} target="_blank">
        Ver en Google Maps
      </a>
    </b>
  </span>
);
const bubbleSimple = ({ address }) => (
  <span>
    <b> {address} </b>
  </span>
);
export default function createMarker(asset, withRef = false) {
  // console.info(asset);
  const google = googleLink(asset);
  const initMarker = (ref) => {
    if (ref) {
      ref.leafletElement.openPopup();
    }
  };
  return (
    <Marker icon={iconMarker(asset.icon)} ref={withRef ? initMarker : undefined} key={asset.deviceId} position={[asset.latitude, asset.longitude]}>
      <Popup autoClose={true}>{bubble({ ...asset, google })}</Popup>
    </Marker>
  );
}
export function createSimpleMarker({ latitude, longitude, address }) {
  // const google = googleLink(asset);
  // const initMarker = (ref) => {
  //   if (ref) {
  //     ref.leafletElement.openPopup();
  //   }
  // };
  return (
    <Marker icon={iconMarker(0)} ref={undefined} position={[latitude, longitude]}>
      <Popup>{bubble({ address })}</Popup>
    </Marker>
  );
}
export function createEvidenceMarker(asset, withRef = false, onOpenVideo = undefined) {
  // console.info(asset);
  const google = googleLink(asset);
  const initMarker = (ref) => {
    if (ref) {
      ref.leafletElement.openPopup();
    }
  };
  return (
    <Marker icon={iconMarker(asset.icon)} ref={withRef ? initMarker : undefined} key={asset.trackingEvidenceId} position={[asset.latitude, asset.longitude]}>
      <Popup>{bubbleEvidence({ ...asset, google }, onOpenVideo)}</Popup>
    </Marker>
  );
}
