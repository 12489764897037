import React, { Fragment, useRef, useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import L from 'leaflet';
import { MapContainer, TileLayer, ZoomControl, useMap, GeoJSON } from 'react-leaflet';
import './custom.css';
import 'leaflet/dist/leaflet.css';
import { useStoreState, useStoreActions } from 'easy-peasy';
import createMarker from '../modules/map/functions/marker';
import CustomControl from '../modules/map/CustomControl';
import { useIntl } from '../lang/context/intlContext';
import Button from '../components/CustomButton/Button';
import { FiltroIcon } from '../../assets/icons';
import mapPositioning, { mapAttributes } from '../modules/map/functions/calcMapAttributes';

import shapes from '../modules/map/functions/response.json';

//draw shapes
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import { blueDrawStyle, customDrawStyle, defaultDrawStyle } from '../modules/map/functions/defaultDrawStyle';
import { RegionOptions } from '../modules/map/components/RegionOptions';
//Functions needed
//todo focus
//todo fouces and view

const useStyle = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  mapMenu: { display: 'flex' },
  mapButtons: {
    paddingTop: theme.spacing(14),
    paddingRight: theme.spacing(8),
  },
  mapViewAll: {
    paddingTop: theme.spacing(14),
    paddingRight: theme.spacing(2),
  },
  mapRegion: {
    paddingTop: theme.spacing(14),
    paddingLeft: theme.spacing(1),
    width: '100%',
  },
  content: {},
}));

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});
const validateRegionsPosition = (gjsonRegions) => {
  if (!gjsonRegions.features) return [];
  const regions = gjsonRegions.features;
  return regions
    .filter((item) => {
      const [longitude, latitude] = item.properties.flyTo;
      let isValid = true;
      if (isNaN(latitude) || isNaN(longitude)) {
        isValid = false;
      }
      return isValid;
    })
    .map((item) => ({ latitude: item.properties.flyTo[1], longitude: item.properties.flyTo[0] }));
};
function MapRegionPage(props) {
  const { onClickFilter, modeMap } = props;
  const { i18 } = useIntl();
  const classes = useStyle();
  // const [viewMode, setViewMode] = useState(false);
  const [actionDraw, setActionDraw] = useState({ action: 'default', payload: null });
  //#region easy peasy
  // const toGridMap = useStoreState((state) => state.regionmap.toGridMap);
  const selectedItem = useStoreState((state) => state.regionmap.selectedItem);
  const focusedAsset = useStoreState((state) => state.regionmap.focusedAsset);
  const isViewAll = useStoreState((state) => state.regionmap.isViewAll);
  const gjsonRegions = useStoreState((state) => state.regionmap.gjsonRegions);
  const actionMap = useStoreState((state) => state.regionmap.actionMap);

  const onViewAll = useStoreActions((actions) => actions.regionmap.onViewAll);
  const onFocus = useStoreActions((actions) => actions.regionmap.onFocus);
  const history = useHistory();
  //#endregion
  const validRegions = validateRegionsPosition(gjsonRegions);
  const mapPos = mapAttributes(validRegions);

  return (
    <div id="container" className="contentmap">
      <MapContainer center={[-33.441809, -70.649966]} zoom={15} zoomControl={false} maxZoom={18} scrollWheelZoom={true}>
        <ZoomControl position="bottomright" />
        <TileLayer attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' url="http://{s}.tile.osm.org/{z}/{x}/{y}.png" />
        <MyComponent
          {...mapPos}
          actionMap={actionMap}
          // shapes={shapes}
          gjsonRegions={gjsonRegions}
          countItems={validRegions.length > 0 ? 1 : 0}
          selectedItem={selectedItem}
          isViewAll={isViewAll}
          focusedAsset={focusedAsset}
        />
        <CustomControl modeMap={modeMap}>
          <div className={classes.mapMenu}>
            <div className={classes.mapViewAll}>
              <Button
                // disabled={selectedAsset === undefined}
                color="map"
                size="sm"
                startIcon={<FiltroIcon />}
                onClick={() => onFocus(true)}
              >
                {i18('Focus')}
              </Button>
            </div>
            <div className={classes.mapViewAll}>
              <Button color="map" size="sm" startIcon={<FiltroIcon />} onClick={() => onViewAll()}>
                {i18('ViewAll')}
              </Button>
            </div>
            <div className={classes.mapViewAll}>
              <Button color="map" size="sm" startIcon={<FiltroIcon />} onClick={onClickFilter}>
                {i18('Filter')}
              </Button>
            </div>
            <div className={classes.mapButtons}>
              <Button
                color="map"
                size="sm"
                startIcon={<FiltroIcon />}
                onClick={() => {
                  // setViewMode(false);
                  history.push('/home/region/create');
                }}
              >
                {i18('Create')}
              </Button>
            </div>
          </div>
        </CustomControl>
      </MapContainer>
    </div>
  );
}
MapRegionPage.propTypes = {
  onClickFilter: PropTypes.func,
  modeMap: PropTypes.string,
};

MapRegionPage.defaultProps = {
  //icon: null,
};
export default MapRegionPage;
const onEachFeature = (feature, layer) => {
  // does this feature have a property named popupContent?
  if (feature.properties && feature.properties.name) {
    layer.bindPopup(feature.properties.name + ' ' + feature.properties.description);
  }
};

const setStyles = (feature) => {
  const { fillColor, strokeColor, transparency } = feature.properties;
  return {
    fillColor: fillColor === 'default' ? strokeColor : fillColor, //color relleno
    color: strokeColor, //color de la linea
    opacity: transparency === 'default' ? 0.7 : transparency, //opacidad del color interno (fillColor)
  };
};
const createGeoJsonLayer = (gjsonRegions, map) => {
  return L.geoJSON(gjsonRegions, {
    style: setStyles,
    pointToLayer: (feature, latlng) => {
      if (feature.properties.shape === 'CircleMarker') {
        return new L.Circle(latlng, feature.properties.radius);
      } else return new L.Marker(latlng);
    },
    onEachFeature: onEachFeature,
  }).addTo(map);
};
function MyComponent(props) {
  const [gjsonLayer, setGJSonLayer] = useState(null);
  const { bounds, selectedItem, isViewAll, focusedAsset, countItems, gjsonRegions, shapes, actionMap } = props;
  const map = useMap();
  //map.fitBounds(polygon.getBounds());
  useEffect(() => {
    if (actionMap === 'reload') {
      const newLayer = createGeoJsonLayer(gjsonRegions, map);
      const boundsOptions = { paddingBottomRight: L.point(0, 210) };
      map.fitBounds(bounds, boundsOptions);
      setGJSonLayer(newLayer);
    }
  }, [actionMap, gjsonRegions]);

  if (actionMap === 'remove') {
    if (gjsonLayer) gjsonLayer.remove();
    setGJSonLayer(null);
    return null;
  }
  if (countItems === 0) {
    map.locate({ setView: true });
    return null;
  }
  const boundsOptions = { paddingBottomRight: L.point(0, 210) };
  // console.log('selectedItem', selectedItem);
  // console.log('isViewAll', isViewAll);
  const isInBound = true;
  // if (focusedAsset) {
  //   console.info(focusedAsset);
  //   const latLong = { lat: focusedAsset.latitude, lng: focusedAsset.longitude };
  //   isInBound = map.getBounds().contains(latLong);
  //   // console.log('isInBound', isInBound);
  //   // console.log('bounds', map.getBounds().contains());
  // }
  if (isViewAll) {
    map.fitBounds(bounds, boundsOptions);
    // console.log('bound', map.getBounds());
  }
  if (selectedItem.lat) {
    map.setView(selectedItem, 16);
    // map.flyTo(selectedItem, 16);
    // map.panTo(selectedItem);
  }
  if (!isViewAll && !selectedItem.lat && isInBound === false) {
    const latLong = { lat: focusedAsset.latitude, lng: focusedAsset.longitude };
    map.setView(latLong, map.getZoom());
    // map.fitBounds(bounds, boundsOptions);
  }

  return null;
}
