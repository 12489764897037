import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

function SelectMacro(props) {
  const { options, title, onClose, i18, value: valueProp, open, ...other } = props;
  const [value, setValue] = React.useState(0);
  const [isOkDisabled, setIsOkDisabled] = React.useState(true);
  const radioGroupRef = React.useRef(null);

  React.useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    const selected = options.find((option) => option.macroId === value);
    onClose(selected);
  };

  const handleChange = (event) => {
    setIsOkDisabled(false);
    setValue(+event.target.value);
  };

  return (
    <Dialog disableBackdropClick disableEscapeKeyDown maxWidth="xs" onEntering={handleEntering} aria-labelledby="confirmation-dialog-title" open={open} {...other}>
      <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
      <DialogContent dividers>
        <RadioGroup ref={radioGroupRef} aria-label="ringtone" name="ringtone" value={value} onChange={handleChange}>
          {options.map((option) => (
            <FormControlLabel value={option.macroId} key={option.macroId} control={<Radio />} label={option.name} />
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
          {i18('Cancel')}
        </Button>
        <Button onClick={handleOk} disabled={isOkDisabled} color="primary">
          {i18('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SelectMacro.propTypes = {
  id: PropTypes.string,
  classes: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
};

export default SelectMacro;
