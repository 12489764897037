import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
// import Container from '@material-ui/core/Container';
// import Grid from '@material-ui/core/Grid';
import SubHeader from './SubHeader/SubHeader';
import WorkSpace from './WorkSpace/WorkSpace';
// import Button from '../../../components/CustomButton/Button';
import { drawerWidth, sumDrawers, innerDrawerWidth } from '../../constants';
const useStyles = makeStyles((theme) => ({
  root: {
    // display: 'flex',
    // flexDirection: 'column',
    // width: '100%',
    // width: `calc(100% - ${drawerWidth}px)`,
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#ffffff',
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

    // height: theme.spacing(7),
  },
  rootShift: {
    display: 'flex',
    flexDirection: 'column',

    // width: '100%',
    width: `calc(100% - ${innerDrawerWidth}px)`,
    marginLeft: innerDrawerWidth,
    // width: `calc(100%)`,
    backgroundColor: '#ffffff',
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),

    // height: theme.spacing(7),
  },
  subHeaderStyle: {
    // width: '100%',
    // left: '60px',
    height: theme.spacing(10),
  },
  workSpace: {
    backgroundColor: '#f2f5f9',
    // height: '100%',
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(0),
  },

  marginButton: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
}));

export default function Content(props) {
  const classes = useStyles();
  const { open, children, subTitle, withSubHeader } = props;
  return (
    <div className={clsx(classes.root, open && classes.rootShift)}>
      {withSubHeader && (
        <div className={classes.subHeaderStyle}>
          <SubHeader subTitle={subTitle} />
        </div>
      )}
      <div className={classes.workSpace}>
        <WorkSpace>{children}</WorkSpace>
      </div>
    </div>
  );
}
