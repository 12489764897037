import React from 'react';
import PrivateRoute from '../auth/PrivateRoute';

import Drivers from '../modules/administration/drivers/Drivers';
import LinkDriver from '../modules/administration/drivers/LinkDriver';
import New from '../modules/administration/drivers/New';
import NewNFC from '../modules/administration/nfc/New';
import NFC from '../modules/administration/nfc/NFC';
import ChangeOwner from '../modules/administration/nfc/ChangeOwner';
import Vehicles from '../modules/administration/vehicles/Vehicles';
import NewVehicle from '../modules/administration/vehicles/New';
import VehiclesWODevices from '../modules/administration/vehicles/VehiclesWODevices';
import Roles from '../modules/security/roles/Roles';
import Devices from '../modules/administration/devices/Devices';
import NewDevice from '../modules/administration/devices/NewDevice';
import NewClient from '../modules/administration/clients/NewClient';
import Clients from '../modules/administration/clients/Clients';

const AdministrationRoutes = {
  data: [
    { sectionId: 6, path: '/administration/drivers/management/:driverId/delete', component: Drivers },
    { sectionId: 6, path: '/administration/drivers/management', component: Drivers },
    { sectionId: 6, path: '/administration/drivers/new', component: New },
    { sectionId: 6, path: '/administration/drivers/:driverId/edit', component: New },
    { sectionId: 8, path: '/administration/drivers/nfc-setup', component: LinkDriver },
    { sectionId: 7, path: '/administration/nfc/management', component: NFC },
    { sectionId: 7, path: '/administration/nfc/new', component: NewNFC },
    { sectionId: 7, path: '/administration/nfc/:driverId/edit', component: NewNFC },
    { sectionId: 7, path: '/administration/nfc/change-owner', component: ChangeOwner },
    { sectionId: 7, exact: true, path: '/administration/vehicles/management', component: Vehicles },
    { sectionId: 7, exact: true, path: '/administration/vehicles/recovery', component: VehiclesWODevices },
    { sectionId: 7, exact: true, path: '/administration/vehicles/management/reload', component: Vehicles },
    { sectionId: 7, exact: true, path: '/administration/vehicles/management/:deviceAgentId/detached', component: Vehicles },
    { sectionId: 100, path: '/administration/vehicles/new', component: NewVehicle },
    { sectionId: 7, path: '/administration/vehicles/:deviceAgentId/edit', component: NewVehicle },
    { sectionId: 7, path: '/administration/vehicles/:deviceAgentId/add-device', component: NewVehicle },
    { sectionId: 7, exact: true, path: '/administration/devices/management', component: Devices },
    { sectionId: 7, exact: true, path: '/administration/devices/management/reload', component: Devices },
    // { sectionId: 7, exact: true, path: '/administration/devices/management/:deviceId/detached', component: Devices },
    // { sectionId: 100, path: '/administration/devices/new', component: NewDevice },
    // { sectionId: 7, path: '/administration/devices/:deviceId/edit', component: NewDevice },

    // { sectionId: 7, exact: true, path: '/administration/clients/management', component: Clients },
    // { sectionId: 7, exact: true, path: '/administration/clients/management/reload', component: Clients },
    // { sectionId: 7, exact: true, path: '/administration/clients/management/:clientId/detached', component: Clients },
    // { sectionId: 100, path: '/administration/clients/new', component: NewClient },
    // { sectionId: 7, path: '/administration/clients/:clientId/edit', component: NewClient },

    { sectionId: 9, path: '/administration/security/roles', component: Roles },
  ],
};

export default AdministrationRoutes;
