//@ts-nocheck
import { action } from 'easy-peasy';

const initValue = { clients: [] };

const clientModule = {
  ...initValue,

  addClient: action((state, data) => {
    state.clients = data.map((client) => ({ ...client, value: client.clientId.toString(), text: client.nam }));
  }),

  clearClient: action((state) => (state.clients = [])),

  deleteClient: action((state, clientId) => {
    state.clients = state.clients.filter((item) => item.clientId !== +clientId);
  }),
};

export default clientModule;
