import { patterHeaderStyle, patternCellStyle } from '../../table/stylesReport';
export const initFilter = {
  user: '0',
};

export const optionsUsers = [{ userId: '0', text: 'SELECTED' }];

export const columnsUsersAssets = [
  {
    title: 'USER',
    field: 'user',
    cellStyle: {
      width: null,
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'DEVICE',
    field: 'serial',
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
      paddingLeft: '8px',
    },
  },
  {
    title: 'PLATES',
    field: 'plates',
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'MODEL',
    field: 'deviceTypeName',
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'FUNCTION',
    field: 'functionName',
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'CLIENTNAME',
    field: 'clientName',
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
];
