//#region IMPORT
import React, { useState, useEffect } from 'react';

import { motion } from 'framer-motion';

import { useIntl } from '../../../lang/context/intlContext';

import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, List, Typography } from '@material-ui/core';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import CustomCard from '../../../components/CustomCard/Card';
import { v4 as uuidv4 } from 'uuid';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Loading } from '../../../../Splash';
import { getUserMFISConfigurationAll } from './api/getMethods';
import { getEventsListReadOnly } from './helper';
import ModalPortlet from '../../../components/ModalPortlet/ModalPortlet';
import { deleteMFISEventConfigurationAPI } from './api/postMethods';

//#endregion
const defStyle = {
  marginTop: '4px',
  maxWidth: '100%',
  height: '400px',
  backgroundColor: '#ffff',
  maxHeight: '400px',
  overflow: 'auto',
};
const useStyle = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    // marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  text: { fontSize: '20px', fontWeight: 'normal' },
  cardStyle: { maxWidth: theme.spacing(30) },
  content: {},
  gridContainer: {
    marginTop: theme.spacing(3),
  },
}));

const buttonSelectText = (status) => {
  if (status === 0) return 'Continue';
  if (status === 1) return 'View';
  if (status === 2) return 'locked';
  return 'Disabled';
};
const Management = ({ history, match }) => {
  const classes = useStyle();
  const { i18 } = useIntl();
  const [eloading, setEloading] = useState(0);

  const [isLoading, setIsLoading] = useState(true);
  const [openView, setOpenView] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedCard, setSelectedCard] = useState(undefined);
  const [mfisConfigurations, setMFISConfiguration] = useState([]);
  const [mfisConfigurationEvents, setMFISConfigurationEvents] = useState([]);
  const [selectedMFISEvents, setSelectedMFISEvents] = useState([]);
  const [selectedMFISConfiguration, setSelectedViewMFISConfiguration] = useState({});
  //#region store
  const setButtons = useStoreActions((actions) => actions.globalState.setButtons);
  const cleanUpSubHeader = useStoreActions((actions) => actions.globalState.cleanUpSubHeader);
  const { setSelectedMFISConfiguration } = useStoreActions((actions) => actions.events);
  const { tabletEventSettingId = 0 } = match.params;
  //#endregion

  const headerMenuWithCard = (selectedCard) => {
    if (!selectedCard) return [];
    return [
      {
        name: 'Archive',
        push: '/mfis/events/management-configurations/' + selectedCard.tabletEventSettingId + '/archive',
        action: 'dialogConfirm',
      },
    ];
  };
  const buttonText = i18('RELEASE');

  const fetchData = async () => {
    setIsLoading(true);
    const res = await getUserMFISConfigurationAll();
    setMFISConfiguration(res.data.mfisConfigurations);
    setMFISConfigurationEvents(res.data.mfisEvents);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let isDisabled = true;
    if (selectedCard) isDisabled = selectedCard.status < 1;
    const headerMenu = headerMenuWithCard(selectedCard);
    const primaryButton = { text: buttonText, isEnabled: true, fnc: () => execPublish(selectedCard), disabled: isDisabled };
    const secondaryButton = {
      text: i18('ADD_MFIS'),
      isEnabled: true,
      disabled: false,
      fnc: () => history.push(`/mfis/events/configuration`),
    };
    const payload = { headerMenu, primaryButton, secondaryButton };
    setButtons(payload);

    return () => {
      cleanUpSubHeader();
    };
  }, [selectedCard]);

  const onSelectCard = (e, selectedCard) => {
    e.preventDefault();
    setSelectedCard(selectedCard);
  };
  const handleClose = async (yesOption) => {
    try {
      if (yesOption) {
        await deleteMFISEventConfigurationAPI(tabletEventSettingId);
        setMFISConfiguration((prev) => prev.filter((item) => +item.tabletEventSettingId !== +tabletEventSettingId));
        setOpenDialog(false);
      }
      history.push('/mfis/events/management-configurations');
    } catch (error) {}
  };
  /**
   * This methods get details about JC and redirects to configuration page, to either save or roll out
   * @param {any} e
   * @param {any} journeyControl
   */
  const onClickMFIS = async (e, tabletEventSettingId) => {
    setSelectedViewMFISConfiguration(mfisConfigurations.find((mfis) => mfis.tabletEventSettingId === tabletEventSettingId));
    setSelectedMFISEvents(mfisConfigurationEvents.filter((item) => item.tabletEventSettingId === tabletEventSettingId));
    setOpenView(true);
  };

  const execPublish = (selectedMFISConfiguration) => {
    const { tabletEventSettingId } = selectedMFISConfiguration;
    if (tabletEventSettingId > 0) {
      setSelectedMFISConfiguration(selectedCard);

      history.push(`/mfis/events/publish`);
    }
  };

  if (isLoading)
    return (
      <GridContainer className={classes.content}>
        <GridItem item xs={12} md={12} lg={12}>
          <Loading></Loading>
        </GridItem>{' '}
      </GridContainer>
    );

  const subTitle = i18('VIEW_MIFS_EVENTS_SUBTITLES');
  return (
    <div className={classes.root}>
      <Typography component="h3" variant="h5" noWrap className={classes.text}>
        {i18('MFIS_LIST')}
      </Typography>
      <GridContainer className={classes.gridContainer} spacing={2}>
        {mfisConfigurations.map((item) => {
          const finalText = ``;
          const texto = item.name;
          const selected = item.tabletEventSettingId === selectedCard?.tabletEventSettingId;
          const buttonText = i18(buttonSelectText(1));
          return (
            <GridItem key={uuidv4()} item>
              <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.9 }}>
                <CustomCard
                  className={classes.cardStyle}
                  key={uuidv4()}
                  title={texto}
                  titleNoWrap={false}
                  typeCard={'alt'}
                  onSelect={onSelectCard}
                  selected={selected}
                  value={item}
                  align={'center'}
                  contentText={finalText}
                  hasAvatar
                  onClick={(e) => onClickMFIS(e, item.tabletEventSettingId)}
                  buttonText={buttonText}
                  isLoading={eloading === item.tabletEventSettingId}
                  disabledButton={false}
                ></CustomCard>
              </motion.div>
            </GridItem>
          );
        })}
      </GridContainer>
      <ModalPortlet title={selectedMFISConfiguration.name} subTitle={subTitle} i18={i18} open={openView} handleOk={() => setOpenView(false)}>
        <GridItem justify="center" container>
          <GridItem item xs={12} md={12} lg={12}>
            <GridContainer spacing={2}>
              <GridItem item xs={11} md={11} lg={11}>
                <List component="nav" dense={true} style={defStyle}>
                  {getEventsListReadOnly(selectedMFISEvents, { button: true })}
                </List>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridItem>
      </ModalPortlet>
      <Dialog open={tabletEventSettingId > 0} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" disableBackdropClick disableEscapeKeyDown>
        <DialogTitle id="alert-dialog-title">{i18('DELETE_MFIS_EVENT_CONFIGURATION')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{i18('DELETE_MFIS_EVENT_CONFIGURATION_SUBTITLE')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)} color="primary">
            {i18('NOCancel')}
          </Button>
          <Button onClick={() => handleClose(true)} color="primary" autoFocus>
            {i18('YesDELETE')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Management;
