import React, { useState, useEffect } from 'react';
//context
import { useIntl } from '../../../lang/context/intlContext';
//material-ui
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
//custom componentes
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import CustomCard from '../../../components/CustomCard/Card';
import { v4 as uuidv4 } from 'uuid';
//state
import { useStoreActions, useStoreState } from 'easy-peasy';
import { postDeleteConfiguration, postRollOut } from './api/postMethods';
import { FormatBold } from '@material-ui/icons';

const useStyle = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  text: { fontSize: '20px', fontWeight: 'normal' },
  textBold: { fontSize: '20px' },
  cardStyle: { maxWidth: theme.spacing(30) },
  content: {},
  gridContainer: {
    marginTop: theme.spacing(3),
  },
}));
const Configurations = ({ history, match }) => {
  const classes = useStyle();
  const { i18 } = useIntl();
  const [eloading, setEloading] = useState(0);
  const [open, setOpen] = useState(false);

  //#region Store
  const { spokenRouteId, name, status } = useStoreState((state) => state.rotograma);
  const configurations = useStoreState((state) => {
    const raw = state.rotograma.configurations;
    return raw.sort((a, b) => a.configurationId - b.configurationId);
  });
  const { deleteConfiguration, compile, editConfiuration } = useStoreActions((actions) => actions.rotograma);

  const setNoButtons = useStoreActions((actions) => actions.globalState.setNoButtons);
  const setPrimaryButton = useStoreActions((actions) => actions.globalState.setPrimaryButton);
  const setSecondaryButton = useStoreActions((actions) => actions.globalState.setSecondaryButton);

  //#endregion

  const onDialog = (e, { configurationId }) => {
    e.preventDefault();
    handleClickOpen(configurationId);
  };

  const handleClickOpen = (configurationId) => {
    setEloading(configurationId);
    setOpen(true);
  };

  const handleClose = (exec) => {
    setOpen(false);
    if (exec) {
      onDelete(eloading);
    } else {
      setEloading(0);
    }
  };
  const onSelected = (e, selectedCard, title) => {
    editConfiuration(selectedCard.configurationId);
    history.push(`/mfis/rotograma/view/region-configuration/${title}`);
  };
  const onDelete = async (configurationId) => {
    setEloading(configurationId);
    const res = await postDeleteConfiguration(configurationId);
    setEloading(0);
    const deletedConfigurationId = res.data.configurationId;
    deleteConfiguration(deletedConfigurationId);
  };
  const execPublish = async (spokenRouteId) => {
    const toPublish = spokenRouteId === 0;
    if (toPublish) {
      history.push(`/mfis/rotograma/publish`);
      return;
    }
    await postRollOut(spokenRouteId);
    compile(spokenRouteId);
  };

  useEffect(() => {
    setNoButtons();
    const buttonText = spokenRouteId === 0 ? i18('RELEASE') : i18('ROLLOUT');
    setPrimaryButton({ text: buttonText, isEnabled: true, fnc: () => execPublish(spokenRouteId) });
    spokenRouteId > 0 &&
      setSecondaryButton({
        text: i18('ADD_Regions'),
        isEnabled: true,
        fnc: () => history.push(`/mfis/rotograma/add/select-regions`),
        disabled: status > 1,
      });
    return () => setNoButtons();
  }, [spokenRouteId, status]);

  return (
    <div className={classes.root}>
      <Typography component="h3" variant="h5" noWrap className={classes.text}>
        {name.toUpperCase() + ': '}
        {spokenRouteId > 0 ? (configurations.length ? i18('SELECT_CONFIG_COMPILE') : i18('SELECT_CONFIG_NO_COMPILE')) : i18('SELECT_CONFIG_RELEASE')}
      </Typography>
      <GridContainer className={classes.gridContainer} spacing={1}>
        {configurations
          .filter((item) => item)
          .map((item, index) => {
            const { preAlert, alert, violation, regionIds } = item;
            // const texto =`${preAlert.isEnabled ? i18('pre_Alert') : ''} ${alert.isEnabled ? i18('Alert') : ''} ${ violation.isEnabled ? i18('violation') : ''
            // }`;
            const position = index + 1;
            const texto = i18('RegIN_Group') + ': ' + regionIds.length;
            const title = `${i18('group')} ${position}`;
            return (
              <GridItem key={uuidv4()} item xs={12} md={4} lg={2}>
                <CustomCard
                  className={classes.cardStyle}
                  key={uuidv4()}
                  title={title}
                  align={'center'}
                  onSelect={(e, value) => onSelected(e, value, title)}
                  contentText={texto}
                  value={item}
                  hasAvatar
                  disabledButton={status > 1}
                  buttonText={i18('Delete')}
                  onClick={onDialog}
                  isLoading={eloading === item.configurationId}
                ></CustomCard>
              </GridItem>
            );
          })}
      </GridContainer>

      {/* <Typography component="h3" variant="h5" noWrap className={classes.textNormal}></Typography> */}
      {/* <Typography color="secondary" style={{ marginTop: '8px' }}>
        {i18('DUPLICATE_REGIONS')}
      </Typography> */}
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" disableBackdropClick disableEscapeKeyDown>
        <DialogTitle id="alert-dialog-title">{i18('DELETECONFIGURATION')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{i18('CONF_DELET_CONF')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)} color="primary">
            {i18('NOCancel')}
          </Button>
          <Button onClick={() => handleClose(true)} color="primary" autoFocus>
            {i18('YesDELETE')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Configurations;
