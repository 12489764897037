//#region imports
import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
// import { useHistory } from 'react-router-dom';

import { useIntl } from '../../../lang/context/intlContext';
import { makeStyles } from '@material-ui/core/styles';
import { CardContent, Typography } from '@material-ui/core';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import CustomCard from '../../../components/CustomCard/Card';
import PortletIcon from '../../../components/Portlet/PortletIcon';
import Portlet from '../../../components/Portlet/Portlet';
import InputText from '../../../components/InputText/InputText';
import PortletActions from '../../../components/Portlet/PorletActions';
import Button from '../../../components/CustomButton/Button';
import ComboBox from '../../../components/ComboBox/ComboBox';
import CustomCheck from '../../../components/CustomCheck/CustomCheck';
import { FormNameIcon, FormDescriptionIcon } from '../../../../assets/icons/formIcons';
// import * as api from '../services/index';
import { Alert } from '@material-ui/lab';

//state
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { postConfigurations } from './api/postMethods';
import { regionConfigurationStyles } from './styles/regionConfigurations';
//#endregion
// const useStyle = regionConfigurationStyles(theme);
const options = [{ text: 'ATENÇÃO VELOCIDADE EXCESSIVA', value: 'a6308' }];
const defaultValues = {
  audio: '',
  meters: 200,
  alarmAudio: '',
  playOnExit: false,
  speed: 0,
  violationAudio: '',
  sendAlarm: false,
};
const RegionConfiguration = ({ history, match, location }) => {
  const classes = regionConfigurationStyles();
  const { i18 } = useIntl();
  const { error, isLoading, status, currentNew, audios, speedsToShow, axiosState, spokenRouteId } = useStoreState((state) => state.rotograma);
  const { setCurrentEditing, setPreAlert, setAlert, setViolation, setCancel, removeConfiguration, postAddConfigurations, createdNewConfigurations } = useStoreActions((actions) => actions.rotograma);

  const setNoButtons = useStoreActions((actions) => actions.globalState.setNoButtons);
  const setPrimaryButton = useStoreActions((actions) => actions.globalState.setPrimaryButton);
  const setSecondaryButton = useStoreActions((actions) => actions.globalState.setSecondaryButton);

  const { register, handleSubmit, control, errors, setValue, getValues, formState } = useForm();
  const [statePOE, setStatePOE] = React.useState(false);
  const [posting, setPosting] = useState(false);
  const [stateSA, setStateSA] = React.useState(false);
  const [stateSpeed, setStateSpeed] = React.useState(0);
  const [stateAudio, setStateAudio] = React.useState({ audio: '', alarmAudio: '', violationAudio: '' });
  const viewRegions = () => history.push(`/mfis/rotograma/add/select-regions`);
  const execSaveConfigurations = async () => {
    try {
      setPosting(true);
      //call api
      const { data } = await postConfigurations({ spokenRouteId, ...currentNew });
      console.info(data);
      //update state
      createdNewConfigurations(data.body.configurations);
      history.push(`/mfis/rotograma/configurations`);
    } catch (error) {
      console.error(error);
    }
  };
  const name = match?.params?.name || i18('NEW_GROUP');

  //#region onClickFunctions

  const onAddButton = (e, id) => {
    e.preventDefault();
    if (currentNew.inEdition > -1) setCurrentEditing(-1);
    if (currentNew.inEdition === -1) setCurrentEditing(id);
  };
  const onSave = async (data, e) => {
    switch (currentNew.inEdition) {
      case 0:
        const { meters, audio } = data;
        const preAlert = { isEnabled: true, meters, audio };
        setPreAlert(preAlert);
        break;
      case 1:
        const playOnExit = statePOE;
        const { alarmAudio, speed } = data;
        const alert = { isEnabled: true, playOnExit, alarmAudio, speed };
        setAlert(alert);
        break;
      case 2:
        const sendAlarm = stateSA;
        const { violationAudio } = data;
        const violation = { isEnabled: true, sendAlarm, violationAudio };
        setViolation(violation);
        break;
      default:
        break;
    }
  };
  const onError = (errors, e) => console.log(errors, e);
  const onCancelButton = (e, mustRemove = false, toRemove = 0) => {
    if (mustRemove) {
      removeConfiguration(toRemove);
    } else setCancel();
  };

  //#endregion
  //#region handlers
  const handlePlayOnExit = (event) => {
    setStatePOE((prev) => !prev);
  };
  const handleSendAlarm = (event) => {
    setStateSA((prev) => !prev);
  };
  const handleAudioState = (event) => {
    event.preventDefault();
    setStateAudio({ ...stateAudio, [event.target.name]: event.target.value });
  };
  const handleComboChange = (e) => {
    setValue('audio', e.target.value, {
      shouldValidate: true,
      shouldDirty: true,
    });
    handleAudioState(e);
  };
  const handleComboChangeAlarm = (e) => {
    handleAudioState(e);

    setValue('alarmAudio', e.target.value, {
      shouldValidate: true,
      shouldDirty: true,
    });
    // console.log('e.target.value', e.target.value);
    // console.info(audios.alertAudios);
    const { speedToShow } = audios.alertAudios.find((item) => item.value === e.target.value);
    // console.log('speedToShow', speedToShow);
    setValue('speed', +speedToShow, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setStateSpeed(+speedToShow);
  };
  const handleComboChangeSpeed = (e) => {
    setValue('speed', e.target.value, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setStateSpeed(+e.target.value);
  };
  const handleComboChangeViolation = (e) => {
    setValue('violationAudio', e.target.value, {
      shouldValidate: true,
      shouldDirty: true,
    });
    handleAudioState(e);
  };
  //#endregion

  // if (add) return <Redirect to={`/mfis/rotograma/add/select-regions`} />;
  const { preAlert, alert, violation } = currentNew.configurations;
  const isPreAlertEnabled = preAlert.isEnabled;
  const isAlertEnabled = alert.isEnabled;
  const isViolationEnabled = violation.isEnabled;
  const anyEnabled = isPreAlertEnabled || isAlertEnabled || isViolationEnabled;
  // console.log('alert.speed', alert.speed);
  //#region useEffects

  useEffect(() => {
    setPrimaryButton({
      text: i18('Save_CONF'),
      isEnabled: true,
      fnc: execSaveConfigurations,
      disabled: !anyEnabled || status > 1,
    });
    setSecondaryButton({ text: i18('VIEW_REGIONS'), isEnabled: true, fnc: viewRegions, disabled: !anyEnabled });
    return () => setNoButtons();
  }, [currentNew, anyEnabled, status]);
  useEffect(() => {
    console.log('audio effect');
    register({ name: 'audio' }, { required: false });
    setValue('audio', preAlert.audio);

    register({ name: 'speed' }, { required: false });
    setValue('speed', alert.speed);
    setStateSpeed(alert.speed);
    register({ name: 'alarmAudio' }, { required: false });
    setValue('alarmAudio', alert.alarmAudio);
    setStatePOE(alert.playOnExit);

    register({ name: 'violationAudio' }, { required: false });
    setValue('violationAudio', violation.violationAudio);
    setStateSA(violation.sendAlarm);
  }, [register, currentNew]);

  useEffect(() => {
    // console.log('location effect ');
    // console.log('location.pathname :>> ', location.pathname);
    if (location.pathname === '/mfis/rotograma/add/region-configuration/post') {
      if (axiosState === 'INIT') {
        postAddConfigurations({});
        return;
      }

      if (error.length) {
        window.alert('there was an error');
        //this shoud render to an error page
        // setError({ message: '' });
      }
    }
  }, [location]);

  //#endregion

  if (axiosState === 'DONE') return <Redirect to={`/mfis/rotograma/configurations`} />;
  return (
    <div className={classes.root}>
      <form onSubmit={handleSubmit(onSave, onError)}>
        <GridContainer direction="column" className={classes.gridContainer} spacing={2}>
          <GridItem item xs={12} md={12} lg={12} className={classes.centerContent}>
            {/* <PortletIcon
              className={classes.portletStyle}
              title={i18('SELECTED_REGIONS')}
              subTitle={i18('SELECTED_REGIONS_SUBHEADER')}
              hasBlueLine={false}
              hasEndIcon={false}
            ></PortletIcon> */}
            <Typography component="h3" variant="h5" noWrap className={classes.text}>
              {name.toUpperCase() + ': '} {currentNew.tempRegionIds.length > 0 ? i18('SELECTED_REGIONS_SUBHEADER') : i18('SELECTED_NO_REGIONS_SUBHEADER')}
            </Typography>
          </GridItem>
          <GridItem item xs={12} md={12} lg={12} className={classes.centerContent}>
            <Typography component="h3" variant="h5" noWrap className={classes.text}>
              {i18('PROCEED_CONFIGURE')}
            </Typography>
          </GridItem>
          <GridItem container item xs={12} md={12} lg={12} spacing={1}>
            <GridItem item xs={4} md={4} lg={3}>
              <CustomCard
                className={classes.rotogramaCard}
                title={i18('PRE_ALERT')}
                typeCard={'rotograma'}
                selected={currentNew.inEdition === 0 || isPreAlertEnabled}
                contentText={i18('PRE_ALERT_CONTENT')}
                align={'left'}
                hasAvatar={false}
                disabledButton={currentNew.inEdition >= 0 && currentNew.inEdition != 0}
                buttonText={isPreAlertEnabled ? (currentNew.inEdition === 0 ? i18('Close') : i18('Edit')) : i18('Configure')}
                onClick={(e) => onAddButton(e, 0)}
              />
            </GridItem>
            <GridItem item xs={4} md={4} lg={3}>
              <CustomCard
                className={classes.rotogramaCard}
                title={i18('ALERT')}
                selected={currentNew.inEdition === 1 || isAlertEnabled}
                contentText={i18('ALERT_CONTENT')}
                typeCard={'rotograma'}
                align={'left'}
                hasAvatar={false}
                disabledButton={currentNew.inEdition >= 0 && currentNew.inEdition != 1}
                buttonText={isAlertEnabled ? (currentNew.inEdition === 1 ? i18('Close') : i18('Edit')) : i18('Configure')}
                onClick={(e) => onAddButton(e, 1)}
              />
            </GridItem>
            <GridItem item xs={4} md={4} lg={3}>
              <CustomCard
                className={classes.rotogramaCard}
                selected={currentNew.inEdition === 2 || isViolationEnabled}
                title={i18('VIOLATION')}
                contentText={i18('VIOLATION_CONTENT')}
                typeCard={'rotograma'}
                align={'left'}
                hasAvatar={false}
                buttonText={isViolationEnabled ? (currentNew.inEdition === 2 ? i18('Close') : i18('Edit')) : i18('Configure')}
                disabledButton={currentNew.inEdition >= 0 && currentNew.inEdition != 2}
                onClick={(e) => onAddButton(e, 2)}
              />
            </GridItem>
          </GridItem>{' '}
          <GridItem item xs={12} md={9} lg={9} className={classes.content}>
            {(() => {
              switch (currentNew.inEdition) {
                case 0:
                  return (
                    <Portlet className={classes.detailsStyle} title={i18('CONF_PREALERT')} subTitle={i18('CONF_PREALERT_SUB')}>
                      <CardContent className={classes.detailsCardContent}>
                        <InputText
                          id="meters"
                          className={classes.meterStyle}
                          placeholder={'(' + i18('required') + ')'}
                          name="meters"
                          label={i18('meters')}
                          icon={FormNameIcon}
                          defaultValue={isPreAlertEnabled ? preAlert.meters : defaultValues.meters}
                          inputRef={register({ required: true })}
                        ></InputText>
                        <ComboBox
                          label={i18('CONF_PREALERT_SELECT')}
                          icon={FormNameIcon}
                          name="audio"
                          defaultValue={isPreAlertEnabled ? preAlert.audio : ''}
                          onChange={handleComboChange}
                          options={audios.preAlertAudios || []}
                          placeholder={i18('Groups')}
                        ></ComboBox>

                        {/* {errors.name && <Typography color="secondary">{i18('Name_Req')}</Typography>} */}
                        {/* {errors.firstName && 'First name is required'} */}
                      </CardContent>
                      <PortletActions className={classes.detailsAction}>
                        <Button variant="contained" color={'cancel'} disabled={status > 1} onClick={(e) => onCancelButton(e, isPreAlertEnabled, currentNew.inEdition)}>
                          {isPreAlertEnabled ? i18('Remove') : i18('Cancel')}
                        </Button>
                        <Button type="submit" variant="contained" color={'primary'} disabled={status > 1 || !stateAudio.audio}>
                          {i18('Save')}
                        </Button>
                      </PortletActions>
                      {/* {error.length > 0 && (
              <Alert className={classes.alert} severity="error">
                {i18('API_ERR')}
              </Alert>
            )} */}
                    </Portlet>
                  );
                case 1:
                  return (
                    <Portlet className={classes.detailsStyle} title={i18('CONF_ALERT')} subTitle={i18('CONF_ALERT_SUB')}>
                      <CardContent className={classes.detailsCardContent}>
                        <ComboBox
                          label={i18('CONF_PREALERT_SELECT')}
                          icon={FormNameIcon}
                          name="alarmAudio"
                          defaultValue={isAlertEnabled ? alert.alarmAudio : defaultValues.alarmAudio}
                          onChange={handleComboChangeAlarm}
                          options={audios.alertAudios || []}
                          placeholder={i18('Groups')}
                        ></ComboBox>{' '}
                        <ComboBox
                          label={i18('speed')}
                          icon={FormNameIcon}
                          className={classes.meterStyle}
                          name="speed"
                          value={stateSpeed}
                          defaultValue={isAlertEnabled ? alert.speed : 0}
                          onChange={handleComboChangeSpeed}
                          options={speedsToShow || []}
                          placeholder={i18('speeds')}
                        ></ComboBox>{' '}
                        {/* <InputText
                          id="speed"
                          className={classes.meterStyle}
                          placeholder={'(' + i18('required') + ')'}
                          name="speed"
                          label={i18('speed')}
                          icon={FormNameIcon}
                          defaultValue={isAlertEnabled ? alert.speed : defaultValues.speed}
                          inputRef={register({ required: true })}
                        ></InputText> */}
                        <CustomCheck className={classes.poeStyle} label={i18('POE')} checked={statePOE} onChange={handlePlayOnExit} name="playOnExit" />
                        {/* {errors.name && <Typography color="secondary">{i18('Name_Req')}</Typography>} */}
                        {/* {errors.firstName && 'First name is required'} */}
                      </CardContent>
                      <PortletActions className={classes.detailsAction}>
                        <Button variant="contained" color={'cancel'} disabled={status > 1} onClick={(e) => onCancelButton(e, isAlertEnabled, currentNew.inEdition)}>
                          {isAlertEnabled ? i18('Remove') : i18('Cancel')}
                        </Button>
                        <Button type="submit" variant="contained" color={'primary'} disabled={status > 1 || !stateAudio.alarmAudio}>
                          {i18('Save')}
                        </Button>
                      </PortletActions>
                      {/* {error.length > 0 && (
              <Alert className={classes.alert} severity="error">
                {i18('API_ERR')}
              </Alert>
            )} */}
                    </Portlet>
                  );
                case 2:
                  return (
                    <Portlet className={classes.detailsStyle} title={i18('CONF_VIOLATION')} subTitle={i18('CONF_VIOLATION_SUB')}>
                      <CardContent className={classes.detailsCardContent}>
                        <ComboBox
                          label={i18('CONF_VIOLATION_SELECT')}
                          icon={FormNameIcon}
                          name="violationAudio"
                          defaultValue={isViolationEnabled ? violation.violationAudio : defaultValues.violationAudio}
                          onChange={handleComboChangeViolation}
                          options={options}
                          placeholder={i18('Groups')}
                        ></ComboBox>
                        <CustomCheck className={classes.poeStyle} label={i18('Send_Alarm')} checked={stateSA} onChange={handleSendAlarm} name="sendAlarm" />

                        {/* {errors.name && <Typography color="secondary">{i18('Name_Req')}</Typography>} */}
                        {/* {errors.firstName && 'First name is required'} */}
                      </CardContent>
                      <PortletActions className={classes.detailsAction}>
                        <Button variant="contained" color={'cancel'} disabled={status > 1} onClick={(e) => onCancelButton(e, isViolationEnabled, currentNew.inEdition)}>
                          {isViolationEnabled ? i18('Remove') : i18('Cancel')}
                        </Button>
                        <Button type="submit" disabled={status > 1 || !stateAudio.violationAudio} variant="contained" color={'primary'}>
                          {i18('Save')}
                        </Button>
                      </PortletActions>
                      {/* {error.length > 0 && (
              <Alert className={classes.alert} severity="error">
                {i18('API_ERR')}
              </Alert>
            )} */}
                    </Portlet>
                  );

                default:
                  return null;
              }
            })()}
          </GridItem>{' '}
        </GridContainer>
      </form>
    </div>
  );
};

export default RegionConfiguration;
