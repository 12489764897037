import React from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/styles';
import { Avatar } from '@material-ui/core';

//Icons
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import EventNoteOutlinedIcon from '@material-ui/icons/EventNoteOutlined';
import TimerRoundedIcon from '@material-ui/icons/TimerRounded'; //coutne timer
import AssignmentIcon from '@material-ui/icons/Assignment';
import AccessTimeRoundedIcon from '@material-ui/icons/AccessTimeRounded'; //timestpan

import TimelapseIcon from '@material-ui/icons/Timelapse'; //tiempo semanal

import TimerIcon from '@material-ui/icons/Timer'; //tiempo maximo alarma. tiempo corot

import TodayIcon from '@material-ui/icons/Today'; //dia para quebrada

import {
  ICON_BLUE_BACKGROUND,
  ICON_BLUE_COLOR,
  ICON_YELLOW_BACKGROUND,
  ICON_YELLOW_COLOR,
  ICON_PINK_BACKGROUND,
  ICON_PINK_COLOR,
  ICON_PURPLE_BACKGROUND,
  ICON_PURPLE_COLOR,
  ICON_GREEN_BACKGROUND,
  ICON_GREEN_COLOR,
  ICON_BACKGROUND,
  ICON_COLOR,
  ICON_GRAY_BACKGROUND,
  ICON_GRAY_COLOR,
} from '../../app/components/styles/constValues';
import { ItemCustomIcon } from '../../app/layout/components/Navigator/SubMenu/ItemIcon';
const useStyles = makeStyles((theme) => ({
  root: { padding: 4 },
  rootSmall: { padding: 0 },
  content: {
    backgroundColor: ICON_BACKGROUND,
    color: ICON_COLOR,
  },
  BLUEContent: {
    backgroundColor: ICON_BLUE_BACKGROUND,
    color: ICON_BLUE_COLOR,
  },
  GRAYContent: {
    backgroundColor: ICON_GRAY_BACKGROUND,
    color: ICON_GRAY_COLOR,
  },
  YELLOWContent: {
    backgroundColor: ICON_YELLOW_BACKGROUND,
    color: ICON_YELLOW_COLOR,
  },
  PINKContent: {
    backgroundColor: ICON_PINK_BACKGROUND,
    color: ICON_PINK_COLOR,
  },
  GREENContent: {
    backgroundColor: ICON_GREEN_BACKGROUND,
    color: ICON_GREEN_COLOR,
  },
  PURPLEContent: {
    backgroundColor: ICON_PURPLE_BACKGROUND,
    color: ICON_PURPLE_COLOR,
  },

  rounded: {
    color: '#fff',
    backgroundColor: ICON_BACKGROUND,
  },
}));
const getColorIcon = (color, classes) => {
  // return classes.content;
  const colorMAY = color.toUpperCase();
  switch (colorMAY) {
    case 'BLUE':
      return classes.BLUEContent;
    case 'YELLOW':
      return classes.YELLOWContent;
    case 'PINK':
      return classes.PINKContent;
    case 'PURPLE':
      return classes.PURPLEContent;
    case 'GREEN':
      return classes.GREENContent;
    case 'GRAY':
      return classes.GRAYContent;

    default:
      return classes.content;
  }
};
export function FormRol({ color = '', small = false }) {
  const classes = useStyles();
  return (
    <InputAdornment position="start" className={small ? classes.rootSmall : classes.root}>
      <Avatar variant="rounded" className={getColorIcon(color, classes)}>
        <AssignmentIcon className={getColorIcon(color, classes)} />
      </Avatar>
    </InputAdornment>
  );
}
export function CustomListIcon({ TheIcon, color = '', small = false }) {
  const classes = useStyles();
  const classname = getColorIcon(color, classes);
  return (
    <InputAdornment position="start" className={small ? classes.rootSmall : classes.root}>
      <Avatar variant="rounded" className={getColorIcon(color, classes)}>
        {/* <AssignmentIcon className={alt ? classes.contentAlt : classes.content} /> */}
        {/* <TheIcon className={getColorIcon(color, classes)} /> */}
        {ItemCustomIcon({ icon: TheIcon, classes: classname })}
        {/* <TheIcon className={classes.content} /> */}
      </Avatar>
    </InputAdornment>
  );
}

export function FormNameIcon({ small = false }) {
  const classes = useStyles();
  return (
    <InputAdornment position="start" className={small ? classes.rootSmall : classes.root}>
      <Avatar variant="rounded" className={classes.rounded}>
        <EventNoteOutlinedIcon className={classes.content} />
      </Avatar>
    </InputAdornment>
  );
}

export function FormDescriptionIcon({ small = false }) {
  const classes = useStyles();
  return (
    <InputAdornment position="start" className={small ? classes.rootSmall : classes.root}>
      <Avatar variant="rounded" className={classes.rounded}>
        <DescriptionOutlinedIcon className={classes.content} />
      </Avatar>
    </InputAdornment>
  );
}
/**
 * Para ser usado en contador de hora. Ejemplo 1:10 (1hora 10 min)
 */
export function FormTimeCounter({ small = false }) {
  const classes = useStyles();
  return (
    <InputAdornment position="start" className={small ? classes.rootSmall : classes.root}>
      <Avatar variant="rounded" className={classes.rounded}>
        <TimerRoundedIcon className={classes.content} />
      </Avatar>
    </InputAdornment>
  );
}
/**
 * Para ser usado en definicion de hora HH:MM. Ejemplo: 07:00 todos los dias a las 7am
 */
export function FormTimeSpan({ small = false }) {
  const classes = useStyles();
  return (
    <InputAdornment position="start" className={small ? classes.rootSmall : classes.root}>
      <Avatar variant="rounded" className={classes.rounded}>
        <AccessTimeRoundedIcon className={classes.content} />
      </Avatar>
    </InputAdornment>
  );
}
/**
 * Para ser usado en definicion long time
 */
export function FormLongTime({ small = false }) {
  const classes = useStyles();
  return (
    <InputAdornment position="start" className={small ? classes.rootSmall : classes.root}>
      <Avatar variant="rounded" className={classes.rounded}>
        <TimelapseIcon className={classes.content} />
      </Avatar>
    </InputAdornment>
  );
}
/**
 * Para ser usado en definicion of maximum short time
 */
export function FormAlarmShortTime({ small = false }) {
  const classes = useStyles();
  return (
    <InputAdornment position="start" className={small ? classes.rootSmall : classes.root}>
      <Avatar variant="rounded" className={classes.rounded}>
        <TimerIcon className={classes.content} />
      </Avatar>
    </InputAdornment>
  );
}
/**
 * Para ser usado en Breaking Day
 */
export function FormBreakDay({ small = false }) {
  const classes = useStyles();
  return (
    <InputAdornment position="start" className={small ? classes.rootSmall : classes.root}>
      <Avatar variant="rounded" className={classes.rounded}>
        <TodayIcon className={classes.content} />
      </Avatar>
    </InputAdornment>
  );
}
