import { InputLabel, List, Paper } from '@material-ui/core';
import { render } from '@testing-library/react';
import React from 'react';

export function ListWrap({ children, title, classes = undefined, customStyle = undefined }) {
  const defStyle = customStyle || {
    marginTop: '4px',
    maxWidth: '100%',
    height: '400px',
    backgroundColor: '#f8f9fd',
    maxHeight: '400px',
    overflow: 'auto',
  };
  return (
    <Paper elevation={0}>
      <InputLabel className={classes && classes.listLabel}>
        {title}
        <List style={defStyle}>{children}</List>
      </InputLabel>
    </Paper>
  );
}
