//#region IMPORTS
import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
//#region MUI
import { CardContent, CircularProgress, Typography } from '@material-ui/core';
//#endregion

//utils
import { useIntl } from '../../../lang/context/intlContext'; //addjust path reference
import { useForm } from 'react-hook-form';
//#region components

import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import Portlet from '../../../components/Portlet/Portlet';
import InputText from '../../../components/InputText/InputText';
import PortletActions from '../../../components/Portlet/PorletActions';
import Button from '../../../components/CustomButton/Button';
import { FormNameIcon, FormDescriptionIcon } from '../../../../assets/icons/formIcons';
//#endregion

//ApiMedhots
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';

//state
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useFormStyle } from '../../../common/styles/formStyle';
import { postJourneyControl } from './api/postMethods';
import { getMasterMacroActions } from './api/getMethods';

//#endregion
//these styles are used in Add Rotograma Falado and Here

const schema = yup.object().shape({
  name: yup.string().required(),
  // age: yup.number().positive().integer().required(),
});

const Add = ({ history, match }) => {
  // const { i18 } = useIntl();

  const [free, setfree] = useState(undefined);
  const [isLoading, setisLoading] = useState(false);
  const classes = useFormStyle();
  const { i18 } = useIntl();
  const { token } = match.params;

  const fetchData = async () => {
    const result = await getMasterMacroActions();
    setMasterMacroActions(result.data.actions);
  };

  useEffect(() => {
    // setNoButtons();
    setPrimaryButton({
      text: i18('BACK'),
      isEnabled: true,
      fnc: () => {
        history.push(`/mfis/control/selecttemplate`);
      },
    });
    fetchData();
    return () => setNoButtons();
    // initRotograma();
  }, []);

  //check UUID

  const { register, handleSubmit, errors } = useForm({ resolver: yupResolver(schema) });
  //#region easypeasy
  const setNoButtons = useStoreActions((actions) => actions.globalState.setNoButtons);
  const setPrimaryButton = useStoreActions((actions) => actions.globalState.setPrimaryButton);
  const setMasterMacroActions = useStoreActions((actions) => actions.journeyControl.setMasterMacroActions);
  const createdNew = useStoreActions((actions) => actions.journeyControl.createdNew);
  const journeyControl = useStoreState((state) => state.journeyControl);

  //#endregion

  //in the future use function checkTemplate to verify template and CJ creation
  if (!token) return <Redirect to={'/mfis/control/selecttemplate'} />;

  const onSubmit = async (data) => {
    try {
      setisLoading(true);
      const { name, description } = data;
      const { data: newJourneyControl } = await postJourneyControl(name, description, journeyControl.selectedTemplateId);
      createdNew(newJourneyControl);
      setisLoading(false);
      history.push(`/mfis/control/configuration`);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <GridContainer className={classes.content}>
          <GridItem item xs={12} md={4} lg={4} className={classes.content}>
            <Portlet className={classes.formStyle} title={i18('CREATE_JC')} subTitle={i18('CREATE_SUBHEADER_JC')}>
              <CardContent>
                <GridContainer direction="row" spacing={2}>
                  <GridItem item xs={12} md={6} lg={6}>
                    <GridContainer direction="column" spacing={2}>
                      <GridItem item xs={12} md={12}>
                        <InputText
                          id="name"
                          placeholder={i18('name') + ' (' + i18('required') + ')'}
                          name="name"
                          label={i18('name')}
                          icon={FormNameIcon}
                          inputRef={register({ required: true })}
                          fullWidth
                        ></InputText>
                        {free && <Typography color="secondary">{`${free} ${i18('NOTAVAI')}`}</Typography>}
                        {errors.name && <Typography color="secondary">{i18('Name_Req')}</Typography>}
                      </GridItem>
                      <GridItem item xs={12} md={12}>
                        <InputText
                          id="description"
                          name="description"
                          label={i18('Description')}
                          placeholder={i18('description') + ' (' + i18('optional') + ')'}
                          inputRef={register({ required: false })}
                          icon={FormDescriptionIcon}
                          multiline
                          rows={4}
                        ></InputText>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  <GridItem item xs={12} md={6} lg={6}>
                    <GridContainer direction="column" spacing={2}>
                      <GridItem item xs={12} md={12}>
                        <InputText id="template" name="template" readOnly={true} defaultValue={journeyControl.selectedTemplateName} label={i18('template')} icon={FormNameIcon} fullWidth></InputText>
                      </GridItem>
                      <GridItem item xs={12} md={12}>
                        <InputText id="author" name="author" label={i18('author')} defaultValue={journeyControl.selectedTemplateAuthor} icon={FormNameIcon} readOnly={true} fullWidth></InputText>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </CardContent>
              <PortletActions>
                <div className={classes.div}>
                  <div className={classes.inDiv}>
                    <Button variant="contained" disabled={isLoading} color={'cancel'}>
                      {i18('Cancel')}
                    </Button>
                  </div>
                  <div className={classes.inDiv}>
                    <Button type="submit" disabled={isLoading} variant="contained" color={'primary'}>
                      {i18('Create')}
                    </Button>
                    {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </div>
                </div>
              </PortletActions>
            </Portlet>
          </GridItem>
        </GridContainer>
      </form>
    </div>
  );
};
Add.propTypes = {
  // title: PropTypes.string,
  // numSelected: PropTypes.number.isRequired,
  // children: PropTypes.object,
};

Add.defaultProps = {
  //icon: null,
};
export default Add;
