import { format, addMonths } from 'date-fns';
import { patterHeaderStyle, patternCellStyle } from '../../table/stylesReport';

const date = new Date();
const now1Month = format(addMonths(date, -1), "yyyy-MM-dd'T'HH:MM");
const now24 = format(date, "yyyy-MM-dd'T'HH:MM");

export const initFilterMacros = {
  startDateTime: now1Month,
  endDateTime: now24,
};

export const columnsTableEvent = [
  {
    title: 'DRIVER_ID',
    defaultGroupOrder: 0,
    field: 'driverIdDriver',
    cellStyle: {
      minWidth: '8%',
      maxWidth: '10%',
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'ASSETS',
    field: 'plates',
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
      textAlign: 'center',
      // width: '80px',
    },
  },
  {
    title: 'EVENT',
    field: 'event',
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'REGION',
    field: 'regionName',
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'FECHA_HORA_GPS',
    field: 'gpsDateTime',
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'DATE_HOUR_DEV',
    field: 'rcvDateTime',
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'VALID_GPS',
    field: 'isValidGPS',
    filtering: false,
    width: null,
    cellStyle: {
      ...patternCellStyle,
      // paddingLeft: '10px',
    },
    headerStyle: { ...patterHeaderStyle },
  },
  {
    title: 'ODOMETER',
    field: 'odometer',
    filtering: false,
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'IGNITION',
    field: 'ignition',
    width: null,
    // type: 'numeric',
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'ADDRESS',
    field: 'address',
    filtering: false,
    width: null,
    cellStyle: {
      width: 300,
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
];
