import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, IconButton } from '@material-ui/core';
import { UserIconWhite } from '../../../../../assets/icons/index';
import { useStoreState } from 'easy-peasy';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    // flexDirection: 'column',
    alignItems: 'center',
    // minHeight: 'fit-content',
    // marginTop: theme.spacing(3),
    paddingLeft: '45px',
    // paddingRight: theme.spacing(4),
  },
  subRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content',
  },
  profileIcon: {
    backgroundColor: theme.palette.primary.main,
    color: '#fffff',
    // paddingRight: theme.spacing(5),
    width: 50,
    height: 50,
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      // fontWeight: '500',
    },
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    width: 60,
    height: 60,
  },
  name: {
    paddingLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  bio: {
    color: '#6e7f8c',
  },
}));

const Profile = ({ userName, clientName }) => {
  const classes = useStyles();
  // const user = {
  //   name: 'Juninho Pernambucano',
  //   avatar: '/assets/images/appBar/Frame@3x.png',
  //   bio: 'Millennium Tele',
  // };
  // console.log('profile', userName, clientName);
  return (
    <div className={classes.root}>
      {/* <Avatar alt="Usuario" className={classes.avatar} component={RouterLink} src={user.avatar} to="/settings" /> */}
      <IconButton className={classes.profileIcon} color="inherit">
        {/* variable con el numer de notificaciones badgeContent={notifications.length} */}
        {/* <Badge color="primary" variant="dot"> */}
        <UserIconWhite />
        {/* </Badge> */}
      </IconButton>
      <div className={classes.subRoot}>
        <Typography className={classes.name} variant="h5">
          {userName}
        </Typography>
        <Typography variant="body2" className={classes.bio}>
          {clientName}
        </Typography>
      </div>
    </div>
    //  </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string,
};

export default Profile;
