export default function setupAxios(axios, authToken) {
  const { REACT_APP_API } = process.env;
  //   console.log(REACT_APP_API_LOCAL);

  axios.defaults.headers.post['Content-Type'] = 'application/json; charset=utf-8';
  axios.defaults.baseURL = REACT_APP_API;
  axios.interceptors.request.use(
    (config) => {
      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      return config;
    },
    (err) => Promise.reject(err)
  );
}
