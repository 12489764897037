import ReactDOM from 'react-dom';
import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import PropTypes from 'prop-types';
import L from 'leaflet';

const CustomControl = (props) => {
  const { children, modeMap, position = 'topright' } = props;
  const map = useMap();

  useEffect(() => {
    const legend = L.control({ position });

    legend.onAdd = () => {
      const div = L.DomUtil.create('div', 'legend');
      ReactDOM.render(children, div);
      //   div.innerHTML = legendHtmlFor(title, description);
      return div;
    };

    legend.addTo(map);

    return () => {
      console.log('removing');
      legend.remove();
    };
  }, [map, modeMap]);
  return null;
};

CustomControl.propTypes = {
  children: PropTypes.element,
  modeMap: PropTypes.string,
  position: PropTypes.string,
};

export default CustomControl;
