import axios from 'axios';
const GET_ALL_ROLES = '/security/getAllRoles';
const GET_ALL_SECTIONS = '/security/getAllSections';
const GET_USER_ENTITIES = '/security/getUserEntities';

const GET_ALL_TABLETS = '/assets/getTablets';
const GET_ALL_ASSETS = '/assets/getAll';
const GET_ALL_ASSETS_LNP = '/assets/getAssets';

const GET_ROLES_NAME_AVAILABILITY = '/ROLES/checkNameAvailability';
const GET_JOURNEYCONTROL_BYID = '/journeyControl/getDefinition';

export function getAllRoles() {
  return axios.get(`${GET_ALL_ROLES}`);
}
export function getAllSections() {
  return axios.get(GET_ALL_SECTIONS);
}
export function getUserEntities() {
  return axios.get(GET_USER_ENTITIES);
}
// export function getJourneyControlById(journeyControlId) {
//   return axios.get(`${GET_JOURNEYCONTROL_BYID}?journeyControlId=${journeyControlId}`);
// }
// export function getNameAvailability(name) {
//   return axios.get(`${GET_JOURNEYCONTROL_NAME_AVAILABILITY}?name=${name}`);
// }
