import { patterHeaderStyle, patternCellStyle } from '../../table/stylesReport';
export const initFilter = {
  situation: '0',
};
export const optionsSituations = [
  { value: '0', text: 'SELECTED' },
  { value: false, text: 'ACTIVED' },
  { value: true, text: 'ARCHIVED' },
];

export const columnsStatusSpoken = [
  {
    title: 'SPOKENREGIONS',
    field: 'name',
    cellStyle: {
      width: null,
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'STATUS',
    field: 'status',
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
      paddingLeft: '8px',
    },
  },
  {
    title: 'SITUATION',
    field: 'situation',
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'CREATE_FOR',
    field: 'userName',
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
];
