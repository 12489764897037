import React, { useState } from 'react';
import { useStoreState } from 'easy-peasy';

import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { CardContent, Checkbox, CircularProgress, Typography } from '@material-ui/core';
import { compareAsc, differenceInMonths, parseISO } from 'date-fns';

import { postCtJornada } from '../../api/postMethods';
import { useIntl } from '../../../../lang/context/intlContext';
import { FormNameIcon } from '../../../../../assets/icons/formIcons';
import { useFormStyle } from '../../../../common/styles/formStyle';

import Button from '../../../../components/CustomButton/Button';
import GridContainer from '../../../../components/Grid/GridContainer';
import GridItem from '../../../../components/Grid/GridItem';
import InputTimeDate from '../../../../components/InputTimeDate/InputTimeDate';
import Portlet from '../../../../components/Portlet/Portlet';
import PortletActions from '../../../../components/Portlet/PorletActions';
import AutoCompleteBox from '../../../../components/ComboBox/AutoCompleteBox';
import { Loading } from '../../../../../Splash';
import CustomizedSnackbars from '../../../../components/CustomSnackBar/CustomSnackBar';
import TableReport from '../../table/TableReport';

import { columnsJourneyControl, initFilterJourneyControl } from './dataSituationJourneyControl';
import { initSnack } from '../../table/stylesReport';

const SituationJourneyControl = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [monthError, setMonthError] = useState(false);
  const [openSnack, setOpenSnack] = React.useState(initSnack);
  const classes = useFormStyle();
  const { i18 } = useIntl();
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  // Form
  const [formValues, setFormValues] = useState(initFilterJourneyControl);
  const { startDateTime, endDateTime } = formValues;
  // Table
  const [columns, setColumns] = useState([]);
  const [table, setTable] = useState([]);
  // Groups
  const [selectedTabletsGroups, setSelectedTabletsGroups] = useState([]);
  const TabletsGroups = useStoreState((state) => state.user.groups);
  // Assets
  const [selectedTabletsAsset, setSelectedTabletsAsset] = useState([]);
  const [TabletsAsset, setTabletsAsset] = useState([]);
  // Control de jornada
  const [selectedCtJornada, setSelectedCtJornada] = useState([]);
  const ctJornada = useStoreState((state) => state.user.journeyControl);
  // Status
  const [selectedStatus, setSelectedStatus] = useState([]);
  const status = useStoreState((state) => state.user.journeyControlStatus);
  // Company
  const [selectedTabletsCompany, setSelectedTabletsCompany] = useState([]);
  const clients = useStoreState((state) => state.user.clients);
  // Tsp user
  const isTSP = useStoreState((state) => state.user.isTSP);

  const handleOnchangeGroups = (selectGroup) => {
    setSelectedTabletsGroups(selectGroup);
    const listAssets = selectGroup.map((group) => group.assets).flat();
    setTabletsAsset(listAssets);
    setSelectedTabletsAsset(listAssets);
  };

  const handleInputChange = ({ target }) => {
    switch (target.name) {
      case 'startDateTime':
        setFormValues({
          ...formValues,
          [target.name]: target.value,
        });
        const months = differenceInMonths(parseISO(endDateTime), parseISO(target.value));
        if (months > 2) {
          setMonthError(true);
        } else {
          setMonthError(false);
        }
        break;
      case 'endDateTime':
        setFormValues({
          ...formValues,
          [target.name]: target.value,
        });
        const result = compareAsc(parseISO(startDateTime), parseISO(target.value));
        if (result > 0) {
          setDateError(true);
        } else {
          setDateError(false);
        }
        const months1 = differenceInMonths(parseISO(target.value), parseISO(startDateTime));
        if (months1 > 2) {
          setMonthError(true);
        } else {
          setMonthError(false);
        }
        break;
      default:
        setFormValues({
          ...formValues,
          [target.name]: target.value,
        });
        break;
    }
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      // validations
      if (!validation()) {
        setIsLoading(false);
        return;
      }
      setTable([]);
      setDateError(false);
      setColumns(columnsJourneyControl.map((data) => ({ ...data, title: i18(data.title) })));
      const body = {
        clients: selectedTabletsCompany.map((company) => company.value),
        deviceIds: selectedTabletsAsset.map((asset) => asset.deviceId),
        journeyControlIds: selectedCtJornada.map((ctJornada) => ctJornada.journeyControlId),
        status: selectedStatus.map((status) => status.statusId),
        startDateTime: `${startDateTime}:00.000Z`,
        endDateTime: `${endDateTime}:00.000Z`,
      };
      await loadSituationJourneyControl(body);
      setIsLoading(false);
    } catch (error) {
      setOpenSnack((prev) => ({
        ...prev,
        open: true,
        title: i18('ERROR_API'),
        severity: 'error',
      }));
    }
  };

  const handleReset = (e) => {
    setFormValues(initFilterJourneyControl);
    setDateError(false);
    setMonthError(false);
    setTabletsAsset([]);
    setSelectedTabletsAsset([]);
    setSelectedTabletsGroups([]);
    setSelectedTabletsCompany([]);
    setSelectedCtJornada([]);
    setSelectedStatus([]);
  };

  const validation = () => {
    let error = false;
    // Date
    const result = compareAsc(parseISO(startDateTime), parseISO(endDateTime));
    const months = differenceInMonths(parseISO(endDateTime), parseISO(startDateTime));
    if (result > 0) {
      setDateError(true);
      error = true;
    } else if (months > 2) {
      setMonthError(true);
      error = true;
    }
    if (error) return;
    return true;
  };

  const loadSituationJourneyControl = async (url) => {
    try {
      const { data: restSituationJourneyControl } = await postCtJornada(url);
      setTable(restSituationJourneyControl.tabletsStatus);
    } catch (error) {
      setOpenSnack((prev) => ({
        ...prev,
        open: true,
        title: i18('ERROR_API'),
        severity: 'error',
      }));
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmitForm}>
        <GridContainer className={classes.content}>
          <GridItem item xs={12} md={12} lg={12} className={classes.content}>
            <Portlet className={classes.formStyle}>
              <CardContent>
                <div>
                  <GridContainer spacing={4}>
                    <GridItem justify="center" container>
                      <GridItem item xs={3} md={3} lg={3}>
                        <AutoCompleteBox
                          placeholder="Filter for Group"
                          multiple
                          label={i18('GROUPS')}
                          id="checkboxes-tags-demo"
                          options={TabletsGroups}
                          value={selectedTabletsGroups}
                          disableCloseOnSelect
                          getOptionLabel={(option) => option.fullName}
                          onChange={(prev, newValue) => handleOnchangeGroups(newValue)}
                          limitTags={10}
                          renderOption={(option, { selected }) => (
                            <React.Fragment>
                              <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                              {option.fullName}
                            </React.Fragment>
                          )}
                          style={{ marginRight: 15 }}
                        />
                      </GridItem>
                      <GridItem item xs={3} md={3} lg={3}>
                        <AutoCompleteBox
                          label={i18('ASSETS')}
                          multiple
                          id="checkboxes-tags-demo"
                          options={TabletsAsset}
                          disableCloseOnSelect
                          value={selectedTabletsAsset}
                          getOptionLabel={(option) => option.asset}
                          onChange={(prev, newValue) => setSelectedTabletsAsset(newValue)}
                          limitTags={10}
                          noOptionsText={i18('SELECT_A_GROUP')}
                          renderOption={(option, { selected }) => (
                            <React.Fragment>
                              <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                              {option.asset}
                            </React.Fragment>
                          )}
                          style={{
                            maxHeight: '230px',
                            marginRight: 15,
                          }}
                        />
                      </GridItem>
                      <GridItem item xs={2} md={2} lg={2}>
                        <AutoCompleteBox
                          label={i18('CONTROL_DE_JORNADA')}
                          multiple
                          id="checkboxes-tags-demo"
                          options={ctJornada}
                          value={selectedCtJornada}
                          disableCloseOnSelect
                          getOptionLabel={(option) => option.name}
                          onChange={(prev, newValue) => setSelectedCtJornada(newValue)}
                          limitTags={10}
                          renderOption={(option, { selected }) => (
                            <React.Fragment>
                              <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                              {option.name}
                            </React.Fragment>
                          )}
                          style={{ marginRight: 15 }}
                        />
                      </GridItem>
                      <GridItem item xs={2} md={2} lg={2}>
                        <AutoCompleteBox
                          label={i18('STATUS')}
                          multiple
                          id="checkboxes-tags-demo"
                          options={status}
                          value={selectedStatus}
                          disableCloseOnSelect
                          getOptionLabel={(option) => option.name}
                          onChange={(prev, newValue) => setSelectedStatus(newValue)}
                          limitTags={10}
                          renderOption={(option, { selected }) => (
                            <React.Fragment>
                              <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                              {option.name}
                            </React.Fragment>
                          )}
                          style={{ marginRight: 15 }}
                        />
                      </GridItem>
                      {isTSP && (
                        <GridItem item xs={2} md={2} lg={2}>
                          <AutoCompleteBox
                            label={i18('COMPANY')}
                            multiple
                            id="checkboxes-tags-demo"
                            options={clients}
                            value={selectedTabletsCompany}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option.text}
                            onChange={(prev, newValue) => setSelectedTabletsCompany(newValue)}
                            limitTags={5}
                            renderOption={(option, { selected }) => (
                              <React.Fragment>
                                <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                                {option.text}
                              </React.Fragment>
                            )}
                            style={{
                              maxHeight: '230px',
                              marginRight: 15,
                            }}
                          />
                        </GridItem>
                      )}
                    </GridItem>
                    <GridItem item md={3} lg={3}>
                      <InputTimeDate
                        id="startDateTime"
                        name="startDateTime"
                        label={i18('START_DATE_TIME')}
                        // style={{ paddingRight: '0px' }}
                        icon={FormNameIcon}
                        value={startDateTime}
                        onChange={handleInputChange}
                      />
                    </GridItem>
                    <GridItem item md={3} lg={3}>
                      <InputTimeDate id="endDateTime" name="endDateTime" label={i18('END_DATE_TIME')} icon={FormNameIcon} value={endDateTime} onChange={handleInputChange} />
                      {dateError && <Typography color="secondary">{i18('ERROR_DATE')}</Typography>}
                      {monthError && <Typography color="secondary">{i18('ERROR_MONTH2')}</Typography>}
                    </GridItem>
                    <GridItem item md={2} lg={2}></GridItem>
                    <GridItem item md={3} lg={3}>
                      <div className={classes.div}>
                        <div className={classes.inDiv}>
                          <Button onClick={handleReset} type="button" variant="contained" color={'cancel'}>
                            {i18('CLEAR')}
                          </Button>
                        </div>
                        <div className={classes.inDiv}>
                          <Button type="submit" variant="contained" color={'primary'} disabled={isLoading}>
                            {i18('SEARCH')}
                          </Button>
                          {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                      </div>
                    </GridItem>
                  </GridContainer>

                  {isLoading ? <Loading /> : columns.length > 0 && <TableReport columns={columns} dataTable={table} />}
                  <CustomizedSnackbars open={openSnack.open} setOpen={setOpenSnack} title={openSnack.title} severity={openSnack.severity}></CustomizedSnackbars>
                </div>
              </CardContent>
              <PortletActions></PortletActions>
            </Portlet>
          </GridItem>
        </GridContainer>
      </form>
    </div>
  );
};
export default SituationJourneyControl;
