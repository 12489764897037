import axios from 'axios';
const INVALID_EVIDENCE = '/evidences/invalidEvidence?trackingEvidenceId=';
const GET_RECONCILE = '/evidences/getReconcile';
const GET_TABLE_RECONCILES = '/evidences/getTableReconciles';

export function getInvalidEvidence(trackingEvidenceId) {
  return axios.get(`${INVALID_EVIDENCE}${trackingEvidenceId}`);
}

export function getReconcile(token) {
  const query = `?token=${token}`;
  return axios.get(`${GET_RECONCILE}${query}`);
}

export function getReconciles({ limit }) {
  const query = `?limit=${limit}`;
  return axios.get(`${GET_TABLE_RECONCILES}${query}`);
}
