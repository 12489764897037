import React, { useState, useEffect, useCallback } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { makeStyles } from '@material-ui/core/styles';
import { Button, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';

import { ActionEdit, ActionRemove } from '../../../../assets/icons';
import { useIntl } from '../../../lang/context/intlContext';
import { Loading } from '../../../../Splash';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import { TableProvider } from '../../../components/Table/TableContext';
import TablePaper from '../../../components/Table/TablePaper';
import PortletIcon from '../../../components/Portlet/PortletIcon';
import { getAllClients } from '../api/getMethods';
import { postDetachedClient } from '../api/postMethods';

const useStyleGrid = makeStyles((theme) => ({
  proto: { maxHeight: 'calc(100vh-600px)' },
  content: {
    display: 'flex',
    justifyContent: 'center',
    height: theme.spacing(45),
  },
  alert: { margin: theme.spacing(2) },
}));

const Clients = ({ history, match }) => {
  const { i18 } = useIntl();
  const classes = useStyleGrid();
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(true);
  const { clientId = 0 } = match.params;
  const filterInputs = [{ label: 'name', field: 'name' }];
  //#region store
  const addClient = useStoreActions((actions) => actions.client.addClient);
  const deleteClient = useStoreActions((actions) => actions.client.deleteClient);
  const clients = useStoreState((state) => state.client.clients);

  const handleClose = async (yesOption) => {
    try {
      if (yesOption) {
        await postDetachedClient(clientId);
        deleteClient(clientId);
      }
      setOpen(false);
    } catch (error) {}
  };

  const onTableReady = () => {};

  const fetchData = async () => {
    const res = await getAllClients();
    addClient(res.data.clients);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  //#region Table and Definitions
  const headClientTable = [
    {
      id: 'name',
      numeric: true,
      align: 'left',
      disablePadding: false,
      label: i18('NAME'),
      width: '100px',
      defaultOrder: true,
    },
    { id: 'clientIdentity', numeric: true, align: 'left', disablePadding: false, label: i18('IDENTITY'), width: '30px' },
    { id: 'typeName', numeric: true, align: 'left', disablePadding: false, label: i18('TYPE'), width: '20px' },
    { id: 'email', numeric: true, align: 'left', disablePadding: false, label: i18('EMAIL'), width: '70px' },
    { id: 'phone', numeric: false, align: 'left', disablePadding: false, label: i18('PHONE'), width: '70px' },
    { id: 'clientNameManager', numeric: false, align: 'left', disablePadding: false, label: i18('MANAGER'), width: '200px' },
    { id: 'contactName', numeric: true, align: 'left', disablePadding: false, label: i18('CONTACT'), width: '50px' },
    { id: 'contactEmail', numeric: false, align: 'left', disablePadding: false, label: i18('CONTACT_EMAIL'), width: '70px' },
    { id: 'contactPhone', numeric: false, align: 'left', disablePadding: false, label: i18('CONTACT_PHONE'), width: '50px' },
    { id: 'contactMobile', numeric: false, align: 'left', disablePadding: false, label: i18('CONTACT_MOBILE'), width: '50px' },
    { id: 'actions', numeric: true, align: 'left', disablePadding: false, label: i18('actions'), width: '100px' },
  ];

  const ActionsRender = useCallback((keyCellId) => {
    return (
      <>
        <ActionEdit title={i18('EDIT')} onClick={() => history.push(`/administration/clients/${keyCellId.split('|')[1]}/edit`)} />
        <ActionRemove title={i18('DETACHED_CLIENT')} onClick={() => history.push(`/administration/clients/management/${keyCellId.split('|')[1]}/detached`)} />
      </>
    );
  }, []);

  if (isLoading)
    return (
      <GridContainer className={classes.content}>
        <GridItem item xs={12} md={12} lg={12}>
          <Loading></Loading>
        </GridItem>
      </GridContainer>
    );

  //#endregion
  return (
    <div>
      <GridContainer className={classes.content}>
        <GridItem item xs={12} md={12} lg={12}>
          <PortletIcon hasTitle={true} title={i18('CLIENTS')} subTitle={i18('LIST_OF_CLIENTS')} withIcon={false} hasCustomStartIcon={false} StartIcon={PermIdentityIcon} withButton buttonTitle={i18('NEW_CLIENT')} onClickButton={() => history.push('/administration/clients/new')}>
            <CardContent style={{ paddingTop: '0px' }}>
              <GridContainer direction="column" spacing={2}>
                <GridItem item xs={12} md={12}>
                  <TableProvider loadData={clients} columns={headClientTable} checkedItems={[]} initRowsPerPage={50} withPagination={true} onContextReady={onTableReady} useV2={true} actions={ActionsRender}>
                    <TablePaper maxHeight={'800px'} filterInputs={filterInputs} hasCheckBox={false} hasGroups={false} hasSearchAllFields={true}></TablePaper>
                  </TableProvider>
                </GridItem>
              </GridContainer>
            </CardContent>
            <></>
          </PortletIcon>
        </GridItem>
      </GridContainer>
      {clientId > 0 && (
        <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" disableBackdropClick disableEscapeKeyDown>
          <DialogTitle id="alert-dialog-title">{i18('DETACHED_CLIENT')}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{i18('CONF_DELETE_CLIENT')}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleClose(false)} color="primary">
              {i18('NOCancel')}
            </Button>
            <Button onClick={() => handleClose(true)} color="primary" autoFocus>
              {i18('YesDELETE')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};
export default Clients;
