import React from 'react';
import { NoColorInicioViagemIcon, DescansoViagemIcon, RefrigerioViagemIcon, ParadaViagemIcon, FimViagemIcon, InicioViagem2, EmailIcon } from '../../../assets/icons';

export const getIcon = (type, color = 'primary', className) => {
  switch (type) {
    case 1:
      return <NoColorInicioViagemIcon color={color} className={className} />;
    case 2:
      return <InicioViagem2 color={color} className={className} />;
    case 3:
      return <NoColorInicioViagemIcon color={color} className={className} />;
    case 4:
      return <ParadaViagemIcon color={color} className={className} />;
    case 5:
      return <RefrigerioViagemIcon color={color} className={className} />;
    case 6:
      return <DescansoViagemIcon color={color} className={className} />;
    case 7:
      return <FimViagemIcon color={color} className={className} />;
    case 8:
      return <FimViagemIcon color={color} className={className} />;
    case 9:
      return <EmailIcon color={color} className={className} />;

    default:
      return <NoColorInicioViagemIcon color={color} className={className} />;
  }
};
