export const defaultDrawStyle = () => {
  return {
    stroke: true,
    color: '#f01111',
    opacity: 1,
    fillColor: '#f01111',
    fillOpacity: 0.4,
  };
};

export const blueDrawStyle = () => {
  return {
    stroke: true,
    color: 'blue',
    opacity: 1,
    fillOpacity: 0.4,
  };
};

export const customDrawStyle = (custom) => {
  return {
    ...defaultDrawStyle,
    ...custom,
  };
};
