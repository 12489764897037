import axios from 'axios';

const ADD_DRIVER = '/driver/add';
const UPDATE_DRIVER = '/driver/update';
const IS_DELETED_DRIVER = '/driver/delete';
const SETUP_NFC = '/driver/setupNFC';
const ADD_NFCS = '/nfc/add';
const CHANGE_OWNER_NFCS = '/nfc/changeOwner';
const ADD_VEHICLE = '/vehicle/add';
const UPDATE_VEHICLE = '/vehicle/update';
const UPDATE_DEVICE = '/devices/update';
const DETACHED_DEVICE = '/vehicle/detachedDevice';
const DELETE_DEVICE = '/device/detachedDevice';
const DETACHED_CLIENT = '/device/detachedDevice';
const ADD_DEVICE = '/devices/add';
const ADD_CLIENT = '/clients';
const UPDATE_CLIENT = '/clients';

export function postAddDriver(driver) {
  return axios.post(ADD_DRIVER, { ...driver });
}
export function postUpdateDriver(driver) {
  return axios.post(UPDATE_DRIVER, { ...driver });
}
export function postIsDeleteDriver(driverId, isDeleted) {
  return axios.post(IS_DELETED_DRIVER, { driverId, isDeleted });
}
export function postInsertNFCA(nfcs) {
  return axios.post(ADD_NFCS, { nfcs });
}
export function postUpdateNFCAChangeOwner(nfcs, newClientId) {
  return axios.post(CHANGE_OWNER_NFCS, { nfcs, newClientId });
}
export function postSetupNFC(nfcId, agentIds, driverId) {
  return axios.post(SETUP_NFC, { nfcId, agentIds, driverId });
}

// vehicle
export function postAddVehicle(vehicle) {
  return axios.post(ADD_VEHICLE, vehicle);
}
// device
export function postAddDevice(device) {
  return axios.post(ADD_DEVICE, device);
}
export function postUpdateVehicle(vehicle) {
  return axios.post(UPDATE_VEHICLE, vehicle);
}
export function putUpdateDevice(device) {
  return axios.put(UPDATE_DEVICE, device);
}
export function postDetachedDevice(deviceAgentId) {
  return axios.post(DETACHED_DEVICE, { deviceAgentId });
}
export function postDetachedClient(clientId) {
  return axios.post(DETACHED_CLIENT, { clientId });
}
export function postClient(client) {
  return axios.post(ADD_CLIENT, client);
}
export function putClient(client) {
  return axios.put(UPDATE_CLIENT, client);
}
export function postDeleteDevice(deviceId) {
  return axios.post(DELETE_DEVICE, { deviceId });
}
