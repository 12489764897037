//easy peasy state managemente setup fil
import { createStore, persist } from 'easy-peasy';
// import storeModel from './store/index';
import asset from './store/asset';
import driver from './store/driver';
import events from './store/events';
import evidences from './store/evidences';
import globalState from './store/global';
import gridState from './store/gridState';
import journeyControl from './store/journeyControl';
import map from './store/map';
import nfc from './store/nfc';
import region from './store/region';
import regionmap from './store/regionmap';
import rotograma from './store/rotograma';
import spokenRoutes from './store/spokenRoutes';
import templates from './store/templates';
import user from './store/user';
import vehicle from './store/vehicle';
import device from './store/device';
import client from './store/client';

const store = createStore(
  persist(
    {
      asset,
      driver,
      events,
      evidences,
      globalState,
      gridState,
      journeyControl,
      map,
      nfc,
      region,
      regionmap,
      rotograma,
      spokenRoutes,
      templates,
      user,
      vehicle,
      device,
      client,
    },
    {
      allow: ['nfc', 'driver', 'rotograma', 'globalState', 'spokenRoutes', 'user', 'templates', 'journeyControl', 'vehicle', 'events', 'evidences', 'asset', 'client'],
      blacklist: ['device', 'region', 'map'],
    },
  ),
);

export default store;
