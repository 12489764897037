import { format, addDays } from 'date-fns';
import { patterHeaderStyle, patternCellStyle } from '../../table/stylesReport';

const date = new Date();
const now1Month = format(addDays(date, -7), "yyyy-MM-dd'T'HH:MM");
const now24 = format(date, "yyyy-MM-dd'T'HH:MM");
export const maxMonth = 3;

export const initFilterGroupByAssets = {
  startDateTime: now1Month,
  endDateTime: now24,
};

export const columnsTableGroupByAssets = [
  {
    title: 'ASSETS',
    defaultGroupOrder: 0,
    field: 'asset',
    cellStyle: {
      minWidth: '8%',
      maxWidth: '10%',
      ...patternCellStyle,
      paddingLeft: '8px',
    },
    headerStyle: {
      ...patterHeaderStyle,
      paddingLeft: '8px',
    },
  },
  {
    title: 'TYPE_EVIDENCE',
    field: 'evidence',
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'QUANTI_DATE',
    field: 'total',
    width: null,
    cellStyle: {
      minWidth: '30px',
      maxWidth: '35px',
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
];
