import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import { useTableContext } from './TableContext';

const useStyles = makeStyles((theme) => ({
  pagination: {
    paddingTop: '8px',
  },
}));

function TableSuperPagination() {
  const classes = useStyles();
  const {
    state: { pagination },
    pager,
  } = useTableContext();
  const onChangePage = (e, page) => {
    pager(page - 1);
  };
  return (
    <div className={classes.pagination}>
      <Pagination
        count={pagination.count}
        color="primary"
        defaultPage={1}
        boundaryCount={2}
        shape="rounded"
        showFirstButton
        showLastButton
        page={pagination.page + 1}
        onChange={onChangePage}
        // rowsPerPage={rowsPerPage}
        // onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
}

TableSuperPagination.propTypes = {
  onChange: PropTypes.func,
};

export default TableSuperPagination;
