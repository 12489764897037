import React from 'react';
// nodejs library that concatenates classes
import clsx from 'clsx';
// nodejs library to set properties for components
import PropTypes from 'prop-types';

// material-ui components
import Button from '@material-ui/core/Button';

import { makeStyles } from '@material-ui/core/styles';
import { WHITE, CANCEL_COLOR, HOVER_COLOR, whiteColor } from '../styles/constValues';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '10px',
    textTransform: 'none',
    fontSize: 12,
    letterSpacing: '0.42px',
    lineHeight: '1.5',
    color: theme.palette.primary.contrastText,
    backgroundColor: WHITE,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: WHITE,
    },
    '&:focus': {},
  },
  sm: {
    height: theme.spacing(4.8),
    width: theme.spacing(11),
  },
  md: {
    height: theme.spacing(5),
    width: theme.spacing(15),
  },
  lg: {
    height: theme.spacing(6),
    width: theme.spacing(18),
  },
  add: {
    backgroundColor: '#dee5ff',
    color: '#5c7ae5',
  },
  seleccionado: {
    background: HOVER_COLOR, //
    color: theme.palette.primary.contrastText,
    fontSize: '14px',
    fontColor: whiteColor,
    justifyContent: 'left',
    letterSpacing: '0.42px',
    height: theme.spacing(8),
    width: theme.spacing(34),
    borderColor: WHITE,
    paddingLeft: '20px',
    boxShadow: 'none',
    border: '1px solid',
    lineHeight: 1.5,
  },
  next: {
    paddingLeft: '20px',
    backgroundColor: WHITE,
    color: theme.palette.text.secondary,
    fontSize: '16px',
    justifyContent: 'left',
    textTransform: 'none',
    letterSpacing: '0.42px',
    // fontFamily: 'Roboto',
    height: theme.spacing(8),
    width: theme.spacing(34),
    boxShadow: '0 10px 20px 0 rgba(92, 116, 129, 0.08)',

    // boxShadow: 'none',
    '&:hover': {
      // background: ' linear-gradient(to bottom, #c5b1f9, #5978e4)', //
      background: HOVER_COLOR,
      color: theme.palette.primary.contrastText,
      //   borderColor: WHITE,
      boxShadow: 'none',
      //   border: '1px solid',
      //   lineHeight: 1.5,
    },
    '&:active': {
      background: HOVER_COLOR, //
      color: theme.palette.primary.contrastText,
      borderColor: WHITE,
      boxShadow: 'none',
      border: '1px solid',
      lineHeight: 1.5,
    },
    '&:focus': {
      // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
      background: HOVER_COLOR, //
      color: theme.palette.primary.contrastText,
    },
  },
  focus: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.contrastText,
    },
  },
  primary: {
    backgroundColor: theme.palette.primary.main,
    // color:?
  },
  secondary: {
    backgroundColor: '#dee5ff',
    color: theme.palette.primary.main,
    width: theme.spacing(16),
    lineHeight: 1.5,

    '&:hover': { color: WHITE, backgroundColor: theme.palette.secondary.main },
  },
  map: {
    borderRadius: '10px',
    // background: 'transparent',
    backgroundColor: whiteColor,
    color: theme.palette.text.primary,
    border: 'solid 1px #5c7ae5',
    '&:hover': { color: WHITE },
  },
  empty: {},
  cancel: {
    backgroundColor: CANCEL_COLOR,
  },
  info: {
    backgroundColor: theme.palette.info.main,
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
  },
  danger: {
    backgroundColor: theme.palette.error.main,
  },
  success: {
    backgroundColor: theme.palette.success.main,
  },
}));

function Boton({ ...props }) {
  const { className, seleccionado, cancel, size, color, children, ...rest } = props;

  const classes = useStyles();
  //   const theme = useTheme();
  const buttonClasses = clsx({
    [classes.root]: true,
    [className]: className,
    [classes[size]]: size,
    [classes[color]]: color,
    [classes.seleccionado]: seleccionado,
    // [classes[cancel]]: cancel,
    // className,
  });
  return (
    <Button {...rest} className={buttonClasses}>
      {children}
    </Button>
  );
}

Boton.propTypes = {
  color: PropTypes.oneOf([
    'map',
    'next',
    'add',
    'primary',
    'cancel',
    'secondary',
    'info',
    'success',
    'warning',
    'danger',
    'focus',
    'empty',
  ]),
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  tipo: PropTypes.oneOf(['next']),
  seleccionado: PropTypes.bool,
};

Boton.defaultProps = {
  size: 'md',
  color: ['primary', 'cancel', 'next', 'empty'],
  // seleccionado:false
};

export default Boton;
