import React, { createContext, useContext, useState, useEffect, useReducer, useMemo, useCallback } from 'react';
import {
  getComparator,
  stableSort,
  formatRootLevel,
  formatRootLevel2,
  parentChild,
  getDefaultOrder,
  getPagination,
  getOptionGroups,
  getCheckedRowsInItems,
  getCheckedRowsInItemsWithField,
  createGridRows,
  setIsExpanded,
  setIsChecked,
  getCheckedRowsSize,
  setIsCheckedLoad,
  getCheckedRowsInItemsWidthFields,
  setCheckAllItems,
  setClassicIsExpanded,
} from './helper';
import { applyFilter, getFilterOptions } from './filters/TableFilter';
import useLocalStorage from '../../../hooks/useLocalStorage';
const initValues = {};

const TableContext = createContext(initValues);

//export to table
export function useTableContext() {
  return useContext(TableContext);
}

export const TableConsumer = TableContext.Consumer;

/**
 * This function is the reducer. It's dispatched by actions like pagination
 * sort, expand, check a row, filter data and init load
 * @param {object} state
 * @param {object} action
 */
function reducer(state, action) {
  const { order, orderBy } = state.sort;
  const { page, rowsPerPage, count, isEnabled } = state.pagination;
  const fromRow = page * rowsPerPage;
  const toRow = fromRow + rowsPerPage;

  switch (action.type) {
    case 'INIT_LOAD':
      const initDatos = state.useV2 ? formatRootLevel2(action.columns, action.loadData) : action.loadData;
      const initLoadData = stableSort(initDatos, order, orderBy);
      const initLoadWithChecked = setIsCheckedLoad(initLoadData, state.checkedItems);
      const newCount = getPagination(initDatos, state.pagination.rowsPerPage);
      const newPagination = { page, rowsPerPage, count: newCount, isEnabled };
      const parentChilds = new Map(parentChild(initLoadWithChecked));
      return {
        ...state,
        originalDatos: initDatos,
        datos: isEnabled ? initLoadWithChecked.slice(fromRow, toRow) : initLoadWithChecked,

        filteredDatos: initLoadWithChecked,
        pagination: { ...newPagination },
        parentChilds,
      };
    case 'PAGER':
      const localfromRow = action.page * rowsPerPage;
      const localtoRow = localfromRow + rowsPerPage;
      const nextPagination = { page: action.page, rowsPerPage, count, isEnabled };
      // const pagefilterDatos = action.filter(state.filterOptions, action.initialState.datos);
      const pagerDatosWithSort = createGridRows(stableSort(state.filteredDatos, order, orderBy));
      return {
        ...state,
        datos: pagerDatosWithSort.slice(localfromRow, localtoRow),
        pagination: { ...nextPagination, count: getPagination(pagerDatosWithSort, state.pagination.rowsPerPage) },
      };
    case 'SORT':
      const { property } = action;
      const isAsc = orderBy === property && order === 'asc';
      const newOrder = isAsc ? 'desc' : 'asc';
      const sortDatosWithSort = createGridRows(stableSort(state.filteredDatos, newOrder, property));
      return {
        ...state,
        datos: isEnabled ? sortDatosWithSort.slice(fromRow, toRow) : sortDatosWithSort,
        sort: { order: newOrder, orderBy: property },
      };
    case 'FILTER':
      const filterDatos = action.filter(action.filterOptions, state.originalDatos);
      const filteredDatosWithSort = createGridRows(stableSort(filterDatos, order, orderBy));
      return {
        ...state,
        datos: isEnabled ? filteredDatosWithSort.slice(fromRow, toRow) : filteredDatosWithSort,
        filterOptions: action.filterOptions,
        filteredDatos: filterDatos,
        pagination: { ...state.pagination, count: getPagination(filteredDatosWithSort, state.pagination.rowsPerPage) },
      };
    case 'EXPAND':
      const { groupId } = action;
      const isExpanded = action.isExpanded;
      const withOrderData = stableSort(state.filteredDatos, order, orderBy); //isEnabled
      const withExpandedState = setClassicIsExpanded(withOrderData, groupId, isExpanded);
      const showExpand = createGridRows(withExpandedState);

      return {
        ...state,
        filteredDatos: withExpandedState,
        datos: isEnabled ? showExpand.slice(fromRow, toRow) : showExpand,
        pagination: { ...state.pagination, count: getPagination(showExpand, state.pagination.rowsPerPage) },
      };

    case 'CHECK':
      const isChecked = action.isChecked;
      const withCheckedData = stableSort(state.filteredDatos, order, orderBy); //isEnabled
      const withCheckedState = setIsChecked(withCheckedData, action.groupId, isChecked);
      const showCheck = createGridRows(withCheckedState);

      return {
        ...state,
        filteredDatos: withCheckedState,
        datos: isEnabled ? showCheck.slice(fromRow, toRow) : showCheck,
        pagination: {
          ...state.pagination,
          count: getPagination(showCheck, state.pagination.rowsPerPage),
        },
      };
    case 'CHECK_ALL':
      const withCheckedAllData = stableSort(state.filteredDatos, order, orderBy); //isEnabled
      const withCheckedAllState = setCheckAllItems(withCheckedAllData, action.isChecked);
      const showCheckAll = createGridRows(withCheckedAllState);
      return {
        ...state,
        filteredDatos: withCheckedAllState,
        datos: isEnabled ? showCheckAll.slice(fromRow, toRow) : showCheckAll,
      };
    case 'CLEAR':
      return {
        ...state,
      };
    default:
      break;
  }
}
/**
 * If the item is checked, add it to the array of checked items, otherwise filter it out
 * @param checkedItems - the array of checked items
 * @param id - The id of the item that was checked/unchecked
 * @param isChecked - This is a boolean value that tells us whether the checkbox is checked or not.
 * @returns a new array with the id added or removed depending on the value of isChecked.
 */
function evalCheck(checkedItems, id, isChecked) {
  if (isChecked) {
    return [...checkedItems, id];
  }
  return checkedItems.filter((item) => item !== id);
}

export function TableProvider({ children, loadData, columns, initRowsPerPage, withPagination, checkedItems, title = '', onContextReady, useV2, actions = undefined }) {
  const datos = [];
  const initialState = {
    originalDatos: datos,
    filteredDatos: datos,
    datos,
    sort: { ...getDefaultOrder(columns) },
    pagination: { page: 0, rowsPerPage: initRowsPerPage, count: 0, isEnabled: withPagination },
    parentChild: [],
    filterOptions: getFilterOptions(columns),
    checkedItems,

    emptyRows: 0,
    useV2,
    actions,
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const [checkedAssets, setCheckedAssets] = useLocalStorage('checkedAsset', []);
  // const [expanded, setExpanded] = useState([]);

  const [checked, setChecked] = useState([]);
  // const theCheckeds = state.getCheckedItems || [];

  const getCheckedSize = () => {
    const sizeItems = getCheckedRowsSize(state.filteredDatos);
    return sizeItems.length;
  };
  const getCheckedRows = () => {
    return getCheckedRowsInItems(state.filteredDatos);
  };
  const getCheckedRowsWithField = () => {
    return getCheckedRowsInItemsWidthFields(state.filteredDatos);
  };
  const clearCheckedAssets = () => {
    setCheckedAssets([]);
  };
  const getCheckedRowsWithFieldEval = () => {
    // return checkedAssets.map((item) => +item.split('|')[1]);
    return getCheckedRowsInItemsWidthFields(state.filteredDatos, false);
  };
  const getCheckedAssets = () => {
    return checkedAssets.map((item) => +item.split('|')[1]);
  };
  const filter = (filterOptions) => dispatch({ type: 'FILTER', filterOptions, filter: applyFilter });
  const pager = (page) => dispatch({ type: 'PAGER', filter: applyFilter, page });
  const expand = (groupId, isExpanded = true) => {
    dispatch({ type: 'EXPAND', groupId, isExpanded });
  };
  const checkItem = (groupId, isChecked = true, children = []) => {
    setChecked(evalCheck(checked, groupId, isChecked));
    dispatch({ type: 'CHECK', groupId, isChecked, children });
  };
  const checkAllItems = (isChecked) => {
    setChecked(evalCheck(checked, '0', isChecked));
    dispatch({ type: 'CHECK_ALL', isChecked });
  };
  const getEmptyRows = () => {
    return state.pagination.rowsPerPage - Math.min(state.pagination.rowsPerPage, state.datos.length - state.pagination.page * state.pagination.rowsPerPage);
  };
  useEffect(() => {
    dispatch({ type: 'INIT_LOAD', checkedItems, columns, loadData });
    // return () => {
    //   dispatch({ type: 'CLEAR', initialState });
    // };
  }, [dispatch, loadData]);

  useEffect(() => {
    onContextReady({
      api: {
        getCheckedSize,
        getCheckedRows,
        getCheckedRowsWithField,
        getCheckedRowsWithFieldEval,
        getCheckedAssets,
        clearCheckedAssets,
      },
    });
    return () => {};
  }, [checked, state.filteredDatos]); //no necesitamos en este moment

  const value = {
    state,
    withPagination,
    columns,
    filterOptions: state.filterOptions,
    filter,
    pager,
    dispatch,
    expand,
    checkItem,
    title,
    checkAllItems,
    setCheckedAssets,
    getCheckedAssets,
    getEmptyRows,
    actions,
  };

  return <TableContext.Provider value={value}>{children}</TableContext.Provider>;
}
