import React, { useState } from 'react';
import { useStoreState } from 'easy-peasy';

import { CardContent, CircularProgress, FormControl } from '@material-ui/core';
import { postUserAssets } from '../../api/postMethods';
import { useIntl } from '../../../../lang/context/intlContext';
import { useFormStyle } from '../../../../common/styles/formStyle';

import Button from '../../../../components/CustomButton/Button';
import GridContainer from '../../../../components/Grid/GridContainer';
import GridItem from '../../../../components/Grid/GridItem';
import Portlet from '../../../../components/Portlet/Portlet';
import PortletActions from '../../../../components/Portlet/PorletActions';
import { Loading } from '../../../../../Splash';
import CustomizedSnackbars from '../../../../components/CustomSnackBar/CustomSnackBar';
import TableReport from '../../table/TableReport';

import { columnsUsersAssets, initFilter } from './dataAssets';
import { initSnack } from '../../table/stylesReport';
import SelectComboBox from '../../../../components/ComboBox/SelectComboBox';

const Assets = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [openSnack, setOpenSnack] = React.useState(initSnack);
  const classes = useFormStyle();
  const { i18 } = useIntl();
  // Form
  const [formValues, setFormValues] = useState(initFilter);
  const { user } = formValues;

  // Table
  const [columns, setColumns] = useState([]);
  const [table, setTable] = useState([]);
  // Users
  const users = useStoreState((state) => state.user.usersAvailable).map((user) => ({
    value: user.userId,
    text: user.userName,
  }));

  const handleInputChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      setTable([]);
      setColumns(columnsUsersAssets.map((data) => ({ ...data, title: i18(data.title) })));
      const body = {
        userId: user,
      };
      await loadAssets(body);
      setIsLoading(false);
    } catch (error) {
      setOpenSnack((prev) => ({
        ...prev,
        open: true,
        title: i18('ERROR_API'),
        severity: 'error',
      }));
    }
  };

  const handleReset = (e) => {
    setFormValues(initFilter);
  };

  const loadAssets = async (url) => {
    try {
      const { data: restAssets } = await postUserAssets(url);
      setTable(restAssets.assets);
    } catch (error) {
      setOpenSnack((prev) => ({
        ...prev,
        open: true,
        title: i18('ERROR_API'),
        severity: 'error',
      }));
    }
  };
  return (
    <div>
      <form onSubmit={handleSubmitForm}>
        <GridContainer className={classes.content}>
          <GridItem item xs={12} md={12} lg={12} className={classes.content}>
            <Portlet className={classes.formStyle}>
              <CardContent>
                <div>
                  <GridContainer justify="center" spacing={4}>
                    <GridItem justify="center" container>
                      <GridItem item xs={4} md={4} lg={4}>
                        <FormControl variant="outlined" className={classes.formSelectUsers}>
                          <SelectComboBox name="user" value={user} onChange={handleInputChange} label={i18('USERS')} options={[{ value: '0', text: i18('SELECT') }, ...users]}></SelectComboBox>
                        </FormControl>
                      </GridItem>
                    </GridItem>
                    <GridItem item xs={4} md={4} lg={4}>
                      <div className={classes.div}>
                        <div className={classes.inDiv}>
                          <Button onClick={handleReset} type="button" variant="contained" color={'cancel'}>
                            {i18('CLEAR')}
                          </Button>
                        </div>
                        <div className={classes.inDiv}>
                          <Button type="submit" variant="contained" color={'primary'} disabled={isLoading || user === '0'}>
                            {i18('SEARCH')}
                          </Button>
                          {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                      </div>
                    </GridItem>
                  </GridContainer>
                  {isLoading ? <Loading /> : columns.length > 0 && <TableReport columns={columns} dataTable={table} />}
                  <CustomizedSnackbars open={openSnack.open} setOpen={setOpenSnack} title={openSnack.title} severity={openSnack.severity}></CustomizedSnackbars>
                </div>
              </CardContent>
              <PortletActions></PortletActions>
            </Portlet>
          </GridItem>
        </GridContainer>
      </form>
    </div>
  );
};
export default Assets;
