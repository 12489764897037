import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { AgregaIcon } from '../../../../../assets/icons/index';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { drawerWidth, sumDrawers } from '../../../constants';
import Button from '../../../../components/CustomButton/Button';

//State
import { useStoreState } from 'easy-peasy';
import { CircularProgress } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#fffff',
    height: theme.spacing(11),
  },
  rootShift: {
    display: 'flex',
    width: `calc(100% - ${sumDrawers}px)`,
    backgroundColor: '#fffff',
    height: theme.spacing(11),
  },
  subH: {
    width: '100%',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  subHShift: {
    width: '100%',
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  content: {
    padding: theme.spacing(1),

    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    // width: '100%',
  },
  toolbar: {
    // paddingRight: 24,
    width: '100%',
  },
  subTitle: {
    paddingLeft: '4px',
  },
  spacer: {
    flexGrow: 1,
  },
  marginButton: {
    marginRight: theme.spacing(2),
  },
  secondaryButton: { marginRight: theme.spacing(2), justifyContent: 'flex-start', paddingLeft: theme.spacing(1) },
  addIconStyle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: '4px',
    marginLeft: '4px',
  },
}));
export default function SubHeader(props) {
  const classes = useStyles();
  const { open, subTitle } = props;
  const [isLoading, setIsLoading] = React.useState(false);
  const { globalState } = useStoreState((state) => state);
  const {
    subHeader: { primaryButton, secondaryButton },
  } = globalState;
  return (
    <div className={clsx(classes.root, open && classes.rootShift)}>
      <Toolbar className={classes.toolbar}>
        <Typography component="h1" variant="h5" noWrap className={classes.subTitle}>
          {subTitle}
        </Typography>
        <span className={classes.spacer} />
        {secondaryButton.isEnabled && (
          <div>
            <Button
              // variant="contained"
              color="secondary"
              className={classes.secondaryButton}
              startIcon={<AgregaIcon className={classes.addIconStyle} />}
              onClick={secondaryButton.fnc}
              disabled={secondaryButton.disabled || false}
            >
              {secondaryButton.text}
            </Button>{' '}
          </div>
        )}
        {primaryButton.isEnabled && (
          <Button
            onClick={primaryButton.fnc}
            color="primary"
            className={classes.marginButton}
            disabled={primaryButton.disabled || false}
          >
            {primaryButton.text}
          </Button>
        )}
      </Toolbar>
    </div>
  );
}
