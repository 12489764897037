import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { Redirect } from 'react-router';
import ReactPDF, { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { ReconcilePDF } from '../modules/mfis/evidence/ReconcilePDF';
import { getReconcile } from '../modules/mfis/evidence/api/getMethods';

const urlApp = process.env.REACT_APP_URL;
const useTabStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    height: '100vh',
    flexDirection: 'column',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: theme.spacing(4),
    alignItems: 'center',
    // justifyContent: 'center',
  },
  text: { fontSize: '20px', fontWeight: 'normal' },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

// get url token
// call api method to get reconcile data
// render pdf

const DownloadPDF = ({ match }) => {
  const classes = useTabStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [reconcile, setReconcile] = useState(null);
  const currentDate = new Date().toISOString().split('T')[0];
  const { reconcileId = 0 } = match.params;
  const fetchData = async (token) => {
    setIsLoading(true);
    try {
      const res = await getReconcile(token);
      setReconcile({ ...res.data, currentDate });
    } catch (error) {
      return <Redirect to="/unauthorized" />;
    }

    setIsLoading(false);
  };
  useEffect(() => {
    const url = new URL(window.location.href);
    const token = reconcileId > 0 ? reconcileId : url.searchParams.get('token');
    fetchData(token);
  }, []);
  return (
    <div className={classes.root}>
      <Typography component="h3" variant="h5" noWrap className={classes.text}>
        Clique no botão abaixo para baixar o arquivo PDF
      </Typography>
      {reconcileId && reconcile && (
        <PDFViewer style={{ width: '100%', height: '90vh' }}>
          <ReconcilePDF reconcile={reconcile} />
        </PDFViewer>
      )}
      {reconcile && reconcileId === 0 && (
        <PDFDownloadLink document={<ReconcilePDF reconcile={reconcile} />} fileName={reconcile.tratativa}>
          Descarregar
        </PDFDownloadLink>
      )}
    </div>
  );
};

export default DownloadPDF;
