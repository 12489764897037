export const initSnack = {
  open: false,
  title: '',
  severity: 'error',
};

// Data for table
const patterHeaderStyle = {
  paddingLeft: '0px',
  paddingRight: '0px',
  paddingTop: '8px',
  paddingBottom: '8px',
  textAlign: 'left',
};
const patternCellStyle = {
  paddingLeft: '0px',
  paddingRight: '0px',
  paddingTop: '10px',
  paddingBottom: '10px',
  textAlign: 'left',
};

export const columnsAsset = [
  {
    title: 'ASSET',
    field: 'asset',
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'BRAND',
    field: 'brand',
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'MODEL',
    field: 'modelVehicle',
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'YEAR',
    field: 'yearVehicle',
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'VIN',
    field: 'vin',
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'CAN_TYPE',
    field: 'canBusType',
    filtering: false,
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: { ...patterHeaderStyle },
  },
  {
    title: 'POWER',
    field: 'powerSupply',
    filtering: false,
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'COMBUSTIVE',
    field: 'gas',
    filtering: false,
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
  {
    title: 'COLOR',
    field: 'color',
    filtering: false,
    width: null,
    cellStyle: {
      ...patternCellStyle,
    },
    headerStyle: {
      ...patterHeaderStyle,
    },
  },
];
