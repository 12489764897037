//#region Import
import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { UserIcon, OjoIcon } from '../../assets/icons/index';
import { login } from '../api/auth/index';
import { useAuth } from './context/auth';
import { useIntl } from '../lang/context/intlContext';
import { whiteColor, dangerColor } from '../components/styles/constValues';
import { CircularProgress } from '@material-ui/core';
//state
import { useStoreActions, useStore } from 'easy-peasy';
//#endregion
function Copyright({ classes }) {
  return (
    <Typography variant="body2" className={classes.copyRight} align="center">
      {'Copyright © Millennium Telecom Brasil. Ver. 1.0.5-81107a9'}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
//#region Styles
const urlApp = process.env.REACT_APP_URL;
const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    width: '100%',
  },
  copyRight: { color: whiteColor },
  title: {
    // fontWeightBold: 1500,
    color: whiteColor,
    fontWeight: 700,
  },
  image: {
    backgroundImage: `url(${urlApp}/assets/images/loginbg.jpg)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: theme.spacing(4),
    // paddingLeft: theme.spacing(8),
  },
  ceiling: {
    // borderRadius: "25px",
    // backgroundColor: "#ffb12a",
    width: '100%',
    height: '14px',
  },
  inputError: { color: whiteColor, fontSize: theme.spacing(2) },
  inputLbl: {
    color: whiteColor,
  },
  input: {
    color: theme.palette.text.secondary,
    backgroundColor: 'white',
  },
  loginForm: {
    // margin: theme.spacing(4),
    height: '80%',
    width: '100%',
    margin: theme.spacing(4),

    // paddingBlockEnd: theme.spacing(4),
    // paddingBlockStart: theme.spacing(4),
    // marginBottom: theme.spacing(12),
    backgroundColor: fade(theme.palette.primary.dark, 0.6),
  },
  paper: {
    // marginLeft: theme.spacing(6),
    // margin: theme.spacing(6),
    // margin: `${theme.spacing(1)}px auto`,
    // width: "350px",
    width: '100%',
    height: '80%',
    padding: theme.spacing(4),

    display: 'flex',
    flexDirection: 'column',
    justify: 'center',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(4),
    //backgroundColor: theme.palette.secondary.main,
    // backgroundImage
    width: '214px',
    height: '118px',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    // width: "400px",
    marginTop: theme.spacing(5),
    alighItems: 'center',
  },

  rememberme: {
    // backgroundColor: theme.palette.primary.main,
    color: 'white',
    padding: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  buttonProgress: {
    color: whiteColor, // theme.palette.primary.dark[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
}));
//#endregion
function Login({ history }) {
  const { intl, i18, setLang } = useIntl();
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();
  const [loading, setLoading] = useState(false);

  const [isAuthorized, setAuthorized] = useState(false);
  // const { isAuthorized } = useStoreState((state) => state.globalState);
  const store = useStore();
  const { onLogin, onFailLogin } = useStoreActions((actions) => {
    const theActions = actions.user;
    return { ...theActions };
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };
  const [error, setError] = useState(false);

  const onSubmit = async (data) => {
    try {
      enableLoading();
      const result = await login(data.email, data.password);

      // disableLoading();
      if (result.status === 200) {
        const { token, user } = result.data;
        onLogin({ token, ...user });
        await store.persist.flush();
        setAuthorized(true);
        setLang(user.languageId);
        // history.push('/');
      } else {
        onFailLogin();
        disableLoading();
        setError(true);
        // console.log('err');
      }
    } catch (error) {
      console.error('errror');
      onFailLogin();
      disableLoading();
      setError(true);
    }
  };
  if (isAuthorized) return <Redirect to="/loader" />;
  return (
    <Grid container component="main" className={classes.root}>
      {/* Image */}
      <Grid container direction="row" justify="flex-start" alignItems="center" className={classes.image}>
        <Grid item xs={12} sm={10} md={3} component={Paper} className={classes.loginForm}>
          <div className={classes.ceiling}>
            {' '}
            <img width="100%" alt="" src="/assets/images/yellowRectangle.svg"></img>
          </div>
          <div className={classes.paper}>
            <Avatar className={classes.avatar} variant="square" src="/assets/images/logos/MILLENIUM_T 1@2x.png">
              <LockOutlinedIcon />
            </Avatar>
            <Grid container>
              <Grid item xs>
                <Typography variant="h5" className={classes.title}>
                  {i18('login.heading')}
                </Typography>
              </Grid>
              <div className={classes.inputError}>{error && <Typography color="secondary">{intl.formatMessage({ id: 'INVALIDCRED' })}</Typography>}</div>

              <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                <Grid container direction="column">
                  <Grid item>
                    <Typography className={classes.inputLbl}>{intl.formatMessage({ id: 'LOGIN.USERNAME' })}</Typography>
                  </Grid>
                  <Grid item>
                    <TextField
                      InputProps={{
                        className: classes.input,
                        endAdornment: (
                          <InputAdornment position="end">
                            <UserIcon />
                          </InputAdornment>
                        ),
                      }}
                      // component={TextField}

                      // className={classes.input}

                      variant="outlined"
                      margin="normal"
                      autoComplete="email"
                      fullWidth
                      id="email"
                      name="email"
                      autoFocus
                      inputRef={register({ required: true })}
                    />
                  </Grid>
                  <Grid item>
                    <Typography className={classes.inputLbl}>Senha</Typography>
                  </Grid>

                  <Grid item>
                    <TextField
                      InputProps={{
                        className: classes.input,
                        endAdornment: (
                          <InputAdornment position="end">
                            <OjoIcon />
                          </InputAdornment>
                        ),
                      }}
                      inputRef={register({ required: true })}
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      type="password"
                      id="password"
                      autoComplete="senha"
                    />
                  </Grid>
                  <Grid container direction="row" item xs justify={'flex-end'}>
                    <Grid>
                      <Link href="#" color="secondary">
                        {/* Nao lembra senha? */}
                      </Link>
                    </Grid>
                  </Grid>
                  <Grid>
                    {/* <FormControlLabel
                      control={<Checkbox value="remember" color="primary" className={classes.rememberme} />}
                      label="Lembreme me"
                    /> */}
                  </Grid>
                  <Grid container direction="row" justify={'flex-end'}>
                    <Grid item>
                      <div className={classes.wrapper}>
                        <Button type="submit" disabled={loading} variant="contained" color="primary" className={classes.submit}>
                          Inicio de Sesao
                        </Button>
                        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        {/* <CircularProgress size={24} className={classes.buttonProgress} /> */}
                      </div>
                    </Grid>
                  </Grid>
                  <Grid>
                    <Box mt={5}>
                      <Copyright classes={classes} />
                    </Box>
                  </Grid>
                </Grid>
              </form>
              {/* )} */}
              {/* </Formik> */}
            </Grid>
          </div>
        </Grid>
        {/* <Grid container xs={12} sm={8} md={4} direction="row">
          <Grid item xs={12} sm={8} md={4} component={Paper} className={classes.loginForm}></Grid>
        </Grid> */}
      </Grid>
    </Grid>
  );
}
export default Login;
