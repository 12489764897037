import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { Redirect } from 'react-router';
const urlApp = process.env.REACT_APP_URL;
const useTabStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    height: '100vh',
    backgroundImage: `url(${urlApp}/assets/images/bg1.jpg)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: theme.spacing(4),
  },
  text: { fontSize: '20px', fontWeight: 'normal' },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));
const Unauthorized = (props) => {
  const classes = useTabStyles();
  const [isLoading, setisLoading] = useState(false);
  setTimeout(() => {
    setisLoading(true);
  }, 5000);
  if (isLoading) return <Redirect to="/" />;
  return (
    <div className={classes.root}>
      {/* <h1 style={{ fontSize: '150px', color: '#3f4254' }}>432</h1> */}
      <Typography component="h3" variant="h5" noWrap className={classes.text}>
        You are not authorized to access this page or function. Redirecting to home...
      </Typography>
    </div>
  );

  // return <Redirect to="/" />;
};

export default Unauthorized;
