import axios from 'axios';
const GET_ALL_JOURNEYCONTROL = '/journeyControl/getAll';
const GET_JOURNEY_CONTROL_STATUS = '/journeyControl/statusAll';
const GET_ALL_MACRO_ACTIONS = '/journeyControl/masterMacroActions';

const GET_JOURNEYCONTROL_NAME_AVAILABILITY = '/journeyControl/checkNameAvailability';
const GET_JOURNEYCONTROL_BYID = '/journeyControl/getDefinition';

export function getAllJourneyControl() {
  return axios.get(`${GET_ALL_JOURNEYCONTROL}`);
}

export function getJourneyControlById(journeyControlId) {
  return axios.get(`${GET_JOURNEYCONTROL_BYID}?journeyControlId=${journeyControlId}`);
}
export function getNameAvailability(name) {
  return axios.get(`${GET_JOURNEYCONTROL_NAME_AVAILABILITY}?name=${name}`);
}
export function getAllJourneyControlStatus() {
  return axios.get(`${GET_JOURNEY_CONTROL_STATUS}`);
}
export function getMasterMacroActions() {
  return axios.get(`${GET_ALL_MACRO_ACTIONS}`);
}

// export function getSpokenRoutes() {
//   return axios.get(get_spoken_routes());
// }
