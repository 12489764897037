export const getEvidenceStatus = {
  1: 'Pendente',
  2: 'Procesada',
  3: 'Invalida',
  4: 'Alarme',
  5: 'Notificado',
};
export const evidenceStatus = [
  {
    value: 0,
    text: 'Todos',
  },
  {
    value: 1,
    text: 'Pendentes',
  },
  {
    value: 2,
    text: 'Procesadas',
  },
  {
    value: 4,
    text: 'Alarmes',
  },
  {
    value: 3,
    text: 'Invalidas',
  },
  // {
  //   value: 5,
  //   text: 'Notificadas',
  // },
];

export const evidenceType = [
  {
    value: '0',
    text: 'Selecione o tipo de evidência',
    idLevel: 0,
    levelName: '',
  },
  {
    value: '1',
    text: 'Alarmes IO',
    idLevel: 2,
    levelName: 'Médio',
  },
  {
    value: '2',
    text: 'Identificação de movimentação',
    idLevel: 2,
    levelName: 'Médio',
  },
  {
    value: '3',
    text: 'Mascaramento',
    idLevel: 3,
    levelName: 'Alto',
  },
  {
    value: '4',
    text: 'Perda de Vídeo',
    idLevel: 2,
    levelName: 'Médio',
  },
  {
    value: '9',
    text: 'Aceleração',

    idLevel: 1,
    levelName: 'Baixo',
  },
  {
    value: '10',
    text: 'Alta Velocidade',
    idLevel: 3,
    levelName: 'Alto',
  },
  {
    value: '11',
    text: 'Baixa Velocidade',
    idLevel: 1,
    levelName: 'Baixo',
  },
  {
    value: '12',
    text: 'Alta Temperatura',
    idLevel: 1,
    levelName: 'Baixo',
  },
  {
    value: '13',
    text: 'Baixa Temperatura',
    idLevel: 1,
    levelName: 'Baixo',
  },
  {
    value: '15',
    text: 'Alarme de velocidade alta',
    idLevel: 3,
    levelName: 'Alto',
  },
  {
    value: '16',
    text: 'Acionamento Botão de Emergência',
    idLevel: 3,
    levelName: 'Alto',
  },
  {
    value: '18',
    text: 'Região',
    idLevel: 1,
    levelName: 'Baixo',
  },
  {
    value: '29',
    text: 'Alarme de Temperatura',
    idLevel: 1,
    levelName: 'Baixo',
  },

  {
    value: '58',
    text: 'Condutor em Fadiga',
    idLevel: 3,
    levelName: 'Alto',
  },
  {
    value: '59',
    text: 'Veículo sem condutor',
    idLevel: 2,
    levelName: 'Médio',
  },
  {
    value: '60',
    text: 'Condutor no Celular',
    idLevel: 3,
    levelName: 'Alto',
  },
  {
    value: '61',
    text: 'Condutor Fumando',
    idLevel: 3,
    levelName: 'Alto',
  },
  {
    value: '62',
    text: 'Condutor sob distração',
    idLevel: 3,
    levelName: 'Alto',
  },
  {
    value: '63',
    text: 'Mudança de Faixa sem sinalizar',
    idLevel: 3,
    levelName: 'Alto',
  },
  {
    value: '64',
    text: 'Possível Colisão frontal',
    idLevel: 3,
    levelName: 'Alto',
  },
  {
    value: '162',
    text: 'Distancia Muito Próxima',
    idLevel: 3,
    levelName: 'Alto',
  },
  {
    value: '163',
    text: 'Identificação de Pedestre',
    idLevel: 2,
    levelName: 'Médio',
  },
  {
    value: '164',
    text: 'Condutor Bocejando',
    idLevel: 3,
    levelName: 'Alto',
  },
  {
    value: '165',
    text: 'Ponto cego a esquerda',
    idLevel: 3,
    levelName: 'Médio',
  },
  {
    value: '166',
    text: 'Ponto cego a direta',
    idLevel: 3,
    levelName: 'Médio',
  },
];

export const reconcileTypes = [
  { value: '0', text: 'Selecione a tratativa' },
  { value: '1', text: 'Advertência Verbal' },
  { value: '2', text: 'Advertência por mensagem de alerta de Atenção' },
  { value: '3', text: 'Alerta de Atenção' },
  { value: '4', text: 'Alerta indevido - Reflexo solar' },
  { value: '5', text: 'Alerta indevido - Posicionamento da Câmera' },
  { value: '6', text: 'Alerta indevido - Câmera com defeito' },
  { value: '7', text: 'Alerta indevido - Evento incorreto' },
  { value: '8', text: 'Alerta indevido - Não identificado Anormalidade' },
  { value: '9', text: 'Comunicação realizada via Terminal C21' },
  { value: '10', text: 'Transportadora Notificada' },
  { value: '11', text: 'Motorista orientado a descansar 60 min' },
  { value: '12', text: 'Motorista orientado a descansar 30 min' },
  { value: '13', text: 'Motorista orientado a realizar pernoite' },
  { value: '15', text: 'Motorista orientado a entrar em contato' },
  { value: '16', text: 'Motorista Notificado' },
  { value: '17', text: 'Motorista orientado a parar o veículo em local seguro' },
];

export const getBaseURL = (window) => {
  const baseURLINIT = window.location.href.split(':')[0];
  return baseURLINIT + '://' + window.location.href.split('//')[1].split('/')[0];
};

export const getHeadCellsEvidence = (i18) => [
  {
    id: 'trackingEvidenceId',
    numeric: false,
    align: 'left',
    disablePadding: true,
    label: i18('ID'),
    width: '50px',
    defaultOrder: true,
  },
  { id: 'levelName', numeric: true, align: 'left', disablePadding: false, label: i18('level'), width: '70px' },
  { id: 'name', numeric: true, align: 'left', disablePadding: false, label: i18('evidence'), width: '250px' },
  // { id: 'statusName', numeric: true, align: 'left', disablePadding: false, label: i18('status'), width: '70px' },
  { id: 'asset', numeric: true, align: 'left', disablePadding: false, label: i18('asset'), width: '250px' },
  { id: 'endDateTime', numeric: true, align: 'left', disablePadding: false, label: i18('datetime'), width: '200px' },
  { id: 'driverName', numeric: true, align: 'left', disablePadding: false, label: i18('Driver'), width: '300px' },
  { id: 'address', numeric: true, align: 'left', disablePadding: false, label: i18('address'), width: 'auto' },
];
