import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Checkbox,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core';
import CustomSwitch from '../CustomSwitch/CustomSwitch';
import { whiteColor } from '../styles/constValues';
import InputText from '../InputText/InputText';
import { FormNameIcon } from '../../../assets/icons/formIcons';
import InputBoxText from '../InputText/InputBoxText';
const useStyles = makeStyles((theme) => ({
  root: {
    // boxShadow: '0 10px 20px 0 rgba(92, 116, 129, 0.08)',
    // borderRadius: '8px',
    minWidth: theme.spacing(35),
    // borderTopStyle: 'solid',
    // borderTopColor: whiteColor,
    paddingBottom: '3px',
    transition: '0.05s',
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  inputSpeedStyle: {
    minWidth: theme.spacing(40),
    width: theme.spacing(12),
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
  listItemText: {
    fontSize: '16px',
    fontFamily: 'Roboto',
    marginLeft: theme.spacing(1),
    color: theme.palette.text.primary,
    letterSpacing: '0.42px',
  },
  listItemCheckRoot: { minWidth: theme.spacing(0) },
  contentSwitch: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  listItemGutters: { paddingLeft: theme.spacing(0), paddingRight: theme.spacing(0) },
}));

export default function ListActions({ actions, handleStatusSwitch, handleCheckAction, i18 }) {
  const classes = useStyles();

  return (
    <div>
      <List className={classes.root} dense={true}>
        {actions.map((item, index) => (
          <ListItem style={{ marginBottom: '8px' }} button key={index} onClick={(e) => handleCheckAction(e, item.actionId)}>
            <ListItemIcon className={classes.listItemCheckRoot}>
              <Checkbox
                edge="start"
                checked={item.isChecked || false}
                tabIndex={-1}
                disableRipple
                // inputProps={{ 'aria-labelledby': labelactionId }}
              />
            </ListItemIcon>
            <ListItemText primary={<Typography className={classes.listItemText}>{item.name}</Typography>} />
            {/* {chipLabel && <Chip label={chipLabel} color={chipColor} size="small" />} */}
            <ListItemSecondaryAction>
              <div className={classes.contentSwitch}>
                <CustomSwitch
                  // alternativeColor
                  disabled={!item.hasStatus || !item.isChecked}
                  handleChange={(e) => handleStatusSwitch(e, item.actionId)}
                  checked={{ isNext: item.statusValue || false, label: '' }}
                ></CustomSwitch>
                <InputBoxText
                  id="messageInput1"
                  name="messageInput1"
                  placeholder={i18('MESSAGE_CONDITION_FAIL')}
                  // variant="outlined"
                  className={classes.inputSpeedStyle}
                  // defaultValue={nfcKey}
                  // value={nfcKey}
                  // label={i18('messageInput1')}
                  // onChange={(e) => setNfcKey(e.target.value)}
                  // onKeyPress={handleOnKeyPress}
                  // icon={FormNameIcon}
                  fullWidth
                ></InputBoxText>
              </div>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </div>
  );
}
