//@ts-nocheck
import { action, computed } from 'easy-peasy';

const initValue = { devices: [] };

const device = {
  ...initValue,

  addDevices: action((state, data) => {
    state.devices = data.map((device) => ({ ...device, value: device.deviceId.toString(), text: device.name + ' ' + device.lastname + ' (' + device.deviceId + ')' }));
  }),

  clearDevices: action((state) => (state.devices = [])),
  getDriver: computed((state) => (id) => state.devices.find((item) => item.deviceId === id)),
  deleteDriver: action((state, deviceId) => {
    state.devices = state.devices.filter((item) => item.deviceId !== +deviceId);
  }),
};

export default device;
