// 5 - 7, 7.1

export const homeSubMenu = [
  {
    parent: 'H',
    id: '5',
    level: 2,
    path: 'asset',
    name: 'Assets',
    label: 'H',
    icon: 'CommuteOutlinedIcon',
    color: '',
    hasCollapsed: true,
    isVisible: true,
    subMenus: [
      {
        status: 'normal',
        id: '5.1',
        level: 3,
        path: '/home/asset/map',
        name: 'Map',
        label: 'H',
        icon: 'RoomOutlinedIcon',
        prev: '5',
        next: '5.2',
        isVisible: true,
      },
      {
        status: 'normal',
        id: '5.2',
        level: 3,
        path: '/home/asset/administration',
        name: 'Administration',
        label: 'H',
        icon: 'AppsOutlinedIcon',
        prev: '5.1',
        next: '5.3',
      },
      {
        status: 'normal',
        id: '5.3',
        level: 3,
        path: '/home/asset/supergrid',
        name: 'SuperGrid',
        label: 'H',
        icon: 'AppsOutlinedIcon',
        prev: '5.2',
        next: '5.4',
      },
      {
        status: 'blind',
        id: '5.4',
        level: 3,
        path: '#',
        name: '',
        label: 'H',
        icon: undefined,
        isVisible: true,
      },
    ],
    selected: false,
    status: 'normal',
  },
  {
    parent: 'H',
    id: '6',
    level: 2,
    path: 'region',
    name: 'Regions',
    label: 'H',
    icon: 'StreetviewOutlinedIcon',
    color: '',
    hasCollapsed: false,
    isVisible: true,
    subMenus: [
      {
        status: 'normal',
        id: '6.1',
        level: 3,
        path: '/home/region/map',
        name: 'Map',
        label: 'H',
        icon: 'RoomOutlinedIcon',
        prev: '6',
        next: '6.2',
        isVisible: true,
      },
      {
        status: 'normal',
        id: '6.2',
        level: 3,
        path: '/home/region/create',
        name: 'Create',
        label: 'H',
        icon: 'AppsOutlinedIcon',
        prev: '6.1',
        next: '6.3',
        isVisible: true,
      },
      {
        status: 'normal',
        id: '6.3',
        level: 3,
        path: '/home/region/administration',
        name: 'Administratrio',
        label: 'H',
        icon: 'AppsOutlinedIcon',
        prev: '6.2',
        next: '6.4',
      },
      {
        status: 'blind',
        id: '6.4',
        level: 3,
        path: '#',
        name: '',
        label: 'H',
        icon: undefined,
        isVisible: true,
      },
    ],
    selected: false,
    status: 'normal',
  },
  {
    parent: 'H',
    id: '7',
    level: 2,
    path: 'mfis',
    name: 'MFIS',
    label: 'H',
    icon: 'TabletOutlinedIcon',
    color: '',
    hasCollapsed: false,
    isVisible: true,
    subMenus: [
      {
        status: 'normal',
        id: '7.1',
        level: 3,
        path: '/home/mfis/map',
        name: 'Map',
        label: 'H',
        icon: 'RoomOutlinedIcon',
        prev: '7',
        next: '7.2',
      },
      {
        status: 'normal',
        id: '7.2',
        level: 3,
        path: '/home/mfis/administration',
        name: 'Administration',
        label: 'H',
        icon: 'AppsOutlinedIcon',
        prev: '7.1',
        next: '7.3',
      },
      {
        status: 'blind',
        id: '7.3',
        level: 3,
        path: '#',
        name: '',
        label: 'H',
        icon: undefined,
      },
    ],
    selected: false,
    status: 'normal',
  },
  {
    parent: 'H',
    id: '7.1',
    level: 2,
    path: 'evidence',
    name: 'Evidences',
    label: 'E',
    icon: 'TabletOutlinedIcon',
    color: '',
    hasCollapsed: false,
    isVisible: true,
    subMenus: [
      {
        status: 'normal',
        id: '7.1.1',
        level: 3,
        path: '/home/evidence/map',
        name: 'Map',
        label: 'H',
        icon: 'RoomOutlinedIcon',
        prev: '7.1',
        next: '7.1.2',
        isVisible: true,
      },
      {
        status: 'normal',
        id: '7.1.2',
        level: 3,
        path: '/home/evidence/administration',
        name: 'Administration',
        label: 'H',
        icon: 'AppsOutlinedIcon',
        prev: '7.1.1',
        next: '7.1.3',
        isVisible: false,
      },
      {
        status: 'blind',
        id: '7.1.3',
        level: 3,
        path: '#',
        name: '',
        label: 'H',
        icon: undefined,
        isVisible: true,
      },
    ],
    selected: false,
    status: 'normal',
  },
];
